import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { history } from '../../../_helpers/history.js';
import { pluginService, sharedService, stripeService } from '../../../_services';
import { Loader as Loading } from '../../Shared/Loader';
import { SP_Payout_Account } from '../index.js';
import { serviceProviderActions } from '../../../_actions/serviceprovider.actions.js';

let accentColor = pluginService.getIFrameColor()

class SP_Onboarding_Success extends React.Component {
    state = {
        loaded: false,
        sp_loaded: false,
        displayForm: false,
    }

    componentDidUpdate = async () => {
        const { user, service_Provider, user_loading, sp_loading, sv_loading } = this.props;

        if (!(user_loading || sp_loading || sv_loading)) {
            if (!service_Provider) {
                history.push('/service-provider-onboarding');
            }
        }
    }

    // stripe_Connect_Onboarding_Link = async () => {
    //     const { user, service_Provider } = this.props;
    //     if (!service_Provider.stripe_Account_Id) {
    //         service_Provider.created_By_User_Id = user.id;
    //         var stripe_IDs = await stripeService.createStripeConnectAccount(service_Provider, 'individual');

    //         if (stripe_IDs) {
    //             service_Provider.stripe_Account_Id = stripe_IDs.stripe_Account_Id;
    //         } else {
    //             this.setState({ displayForm: true });
    //             return;
    //         }
    //     }

    //     let return_Link = await stripeService.onboardConnectedStripeAccount(service_Provider.stripe_Account_Id, window.location.origin + '/service-provider-success');
    //     window.location.href = return_Link.url
    // }

    showCompleteButtons = () => {
        const redirect_url = sessionStorage.getItem("redirect");
        sessionStorage.removeItem("redirect");
        return (redirect_url ?
            <>
                <br />
                {this.redirectPage(5000, redirect_url)}
                <p>
                    You will automatically be redirected. If you are not redirected within 10 seconds, please <a onClick={() => history.push(redirect_url)}>click here</a>
                </p>
            </>
            :
            <button className='defaultGreenButton' style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { history.push('/dashboard') }}>
                Return to Dashboard
            </button>
        )
    }

    // showIncompleteButtons = () => {
    //     return (
    //         <div style={{ display: "flex", justifyContent: "center" }}>
    //             <button className="defaultGreenButton" onClick={this.stripe_Connect_Onboarding_Link} style={{ marginRight: 10, padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
    //                 Continue to Stripe <i className='icon-arrow-right' />
    //             </button>

    //             {!this.props.modalView &&
    //                 <button className='defaultWhiteButton' onClick={() => { history.push('/dashboard') }} style={{ marginLeft: 10, padding: "10px 20px", color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
    //                     Return to Dashboard
    //                 </button>
    //             }
    //         </div>
    //     )
    // }

    redirectPage = (timeout, url) => {
        setTimeout(() => {
            history.push(url);
        }, timeout);
    }

    render() {
        const { displayForm } = this.state;
        const { stripe_Verified, service_Provider, user, user_loading, sp_loading, sv_loading } = this.props;

        return (
            <div style={{ width: "80%", margin: "5% auto" }}>
                {(user_loading || sp_loading || sv_loading) &&
                    <Loading />
                }
                {!(user_loading || sp_loading || sv_loading) && <>
                    {/* {sharedService.completedStripe(stripe_Verified) && //completed stripe
                        !sharedService.hasPayoutMethod(stripe_Verified) && //no payout method
                        service_Provider.organization.owner === user.id && //is org owner
                        <SP_Payout_Account
                            new_payment_method={true}
                            handleClosing={this.props.handleClosing}
                        />
                    } */}
                    <div style={{ textAlign: "center" }}>

                        {/* APPLICATION COMPLETE (NOT APPROVED YET)*/}
                        {
                            // ((sharedService.completedStripe(stripe_Verified) //completed stripe
                            //     && sharedService.hasPayoutMethod(stripe_Verified) //has payout method
                            //     && !sharedService.isSPVerified(service_Provider, stripe_Verified)) //not opengrants verified
                            //     || (service_Provider.organization && service_Provider.organization.owner !== user.id //is not org owner 
                            //         && !(sharedService.completedStripe(stripe_Verified) //not completed stripe
                            //             || sharedService.hasPayoutMethod(stripe_Verified) //no payout method
                            //             || sharedService.isSPVerified(service_Provider, stripe_Verified))) //not opengrants verified
                            // ) &&
                            !sharedService.isSPVerified(service_Provider, stripe_Verified) &&
                            <div >
                                <h1 className={`onboarding-success-heading ${this.props.modalView ? "modalView" : "pageView"}`}>
                                    Service Provider Application Submitted
                                </h1>
                                <p className="onboarding-success-text">
                                    Thank you for applying to be a grant consultant on OpenGrants.
                                    Please allow 1-3 business days for us to review your application.
                                    If you're approved, you'll receive an email.
                                    Once approved, users will be able to find you in the marketplace and message you about work.
                                </p>
                                <button className='defaultGreenButton' style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { history.push('/dashboard') }}>
                                    Return to Dashboard
                                </button>
                            </div>
                        }

                        {/* APPLICATION COMPLETE (APPROVED)*/}
                        {
                            /* {(sharedService.isSPVerified(service_Provider, stripe_Verified) //verified
                                && sharedService.completedStripe(stripe_Verified) //completed stripe
                                && sharedService.hasPayoutMethod(stripe_Verified) //has payout method
                            ) && */
                            sharedService.isSPVerified(service_Provider, stripe_Verified) &&
                            <div>
                                <h1 className={`onboarding-success-heading ${this.props.modalView ? "modalView" : "pageView"}`}>
                                    Service Provider Application Completed
                                </h1>
                                <p className="onboarding-success-text">
                                    Thank you for applying to be a grant consultant on OpenGrants.
                                    Your application has been reviewed and has been accepted by our team.
                                    Users are able to find you in the marketplace and message you about work.
                                </p>
                                <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { history.push('/dashboard') }}>
                                    Return to Dashboard
                                </button>
                            </div>
                        }

                        {/* APPLICATION INCOMPLETE */}
                        {/* {(service_Provider && service_Provider.organization.owner === user.id && //is org owner
                            !sharedService.completedStripe(stripe_Verified)) && //has not completed stripe
                            <div>
                                <h1 className={`onboarding-success-heading ${this.props.modalView ? "modalView" : "pageView"}`}>
                                    Service Provider Application Incomplete
                                </h1>
                                <p className="onboarding-success-text">
                                    Thank you for applying to be a grant consultant on OpenGrants.
                                    In order to complete your application, please complete your stripe account.
                                    Once approved, users will be able to find you in the marketplace and message you about work.
                                </p>
                                {this.showIncompleteButtons()}
                            </div>
                        } */}
                    </div>

                    {/* POP UP FORMS TO CORRECT ERRORS */}
                    {displayForm &&
                        <Modal
                            id="listingPreviewModal"
                            isOpen={displayForm}
                            fade={false}
                        >
                            <ModalHeader />

                            <ModalBody>
                                <p className="onboarding-success-text">
                                    We're sorry, an error occurred while continuing to Stripe.
                                    The OpenGrants team have been notified,
                                    and they will contact you within 24 hours.
                                </p>
                            </ModalBody>

                            <ModalFooter className="centerFlex flexColumn">
                                <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => history.push('/dashboard')}>
                                    Return to Dashboard
                                </button>
                            </ModalFooter>
                        </Modal>
                    }
                </>}
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { user } = state.setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;
    const { stripe_Verified } = state.stripeVerified;

    const user_loading = state.setUser.loading;
    const sp_loading = state.serviceprovider_getserviceprovider.loading;
    const sv_loading = state.stripeVerified.loading;

    return {
        user,
        service_Provider,
        stripe_Verified,

        user_loading,
        sp_loading,
        sv_loading
    };
}
const connectedSP_Onboarding_Success = connect(mapStateToProps)(SP_Onboarding_Success);
export { connectedSP_Onboarding_Success as SP_Onboarding_Success };

