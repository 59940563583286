import { upgradeConstants } from "../_constants";

export const upgradeActions = {
    openUpgradeModal,
    closeUpgradeModal
};

function openUpgradeModal() {
    return {
        type: upgradeConstants.MODAL_OPEN,
        status: true,
    };
};

function closeUpgradeModal() {
    return {
        type: upgradeConstants.MODAL_CLOSE,
        status: false,
    };
};