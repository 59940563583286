export const authenticateConstants = {
    LOGINUSER_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGINUSER_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGINUSER_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USER_LOGOUT',

    UPDATEPASSWORD_REQUEST: 'USERS_UPDATEPASSWORD_REQUEST',
    UPDATEPASSWORD_SUCCESS: 'USERS_UPDATEPASSWORD_SUCCESS',
    UPDATEPASSWORD_FAILURE: 'USERS_UPDATEPASSWORD_FAILURE',

    FORGOTPASSWORD_REQUEST: 'USERS_FORGOTPASSWORD_REQUEST',
    FORGOTPASSWORD_SUCCESS: 'USERS_FORGOTPASSWORD_SUCCESS',
    FORGOTPASSWORD_FAILURE: 'USERS_FORGOTPASSWORD_FAILURE',

    RESETPASSWORD_REQUEST: 'USERS_RESETPASSWORD_REQUEST',
    RESETPASSWORD_SUCCESS: 'USERS_RESETPASSWORD_SUCCESS',
    RESETPASSWORD_FAILURE: 'USERS_RESETPASSWORD_FAILURE',

    SETUSER_REQUEST: 'USERS_SETUSER_REQUEST',
    SETUSER_SUCCESS: 'USERS_SETUSER_SUCCESS',
    SETUSER_FAILURE: 'USERS_SETUSER_FAILURE',

    CONFIRMEMAIL_REGISTRATIONCOMPLETE: 'USERS_CONFIRMEMAIL_REGISTRATIONCOMPLETE',
    CONFIRMEMAIL_REQUEST: 'USERS_CONFIRMEMAIL_REQUEST',
    CONFIRMEMAIL_SUCCESS: 'USERS_CONFIRMEMAIL_SUCCESS',
    CONFIRMEMAIL_FAILURE: 'USERS_CONFIRMEMAIL_FAILURE',
    CONFIRMEMAIL_SENTSUCCESS: 'CONFIRMEMAIL_SENTSUCCESS',
    CONFIRMEMAIL_SENTFAILURE: 'USERS_CONFIRMEMAIL_SENTFAILURE',
};
