import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import '../../../../Styles/ServiceProvider_v2/Projects_v2.css';
import { RejectedChatBox_v2 } from "./RejectedChatBox_v2";
import { RejectedMessenger_v2 } from "./RejectedMessenger_v2";

export function RejectedModal(props) {
  const { isRejectedOpen, organization_Id, toggleRejection, handleRejectFunction, service_Provider } = props;

  return (
    <Modal
      isOpen={isRejectedOpen}
      centered={true}
      backdrop={true}
      fade={false}
      size="xl"
    >
      <ModalBody>
        <div className="row" style={{ padding: '30px' }}>
          <div className="row center">
            <p className="rejectCard-header">Are you sure you want to reject this proposal?</p>
          </div>
          <br />
          <div className="row center">
            <p className="rejectCard-p">Would you like the Grant Consultant to edit the proposal and resubmit? If so, please add your feedback here:</p>
          </div>
          <br />
          <RejectedMessenger_v2
            channel_type={"project"}
            organization_Id={organization_Id}
            service_Provider={service_Provider}
          >
            <RejectedChatBox_v2
              toggleRejection={toggleRejection}
              handleRejectFunction={handleRejectFunction}
            />
          </RejectedMessenger_v2>
          {/* <RejectedMessenger
            channel_type={"project"}
            organization_Id={organization_Id}
            toggleRejection={toggleRejection}
            handleRejectFunction={handleRejectFunction}
            service_Provider={service_Provider}
          /> */}
        </div>
        <br />
      </ModalBody>
    </Modal>
  );
}