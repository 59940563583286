import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { organizationstripeActions, stripeActions } from '../../../../../_actions';
import { pluginService, sharedService } from '../../../../../_services';

export const Verify_ACH_Debit = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.setUser);
  const { bank_Account, loading_bankRequest } = props.billingInfo;

  const [deposit_1, setDeposit1] = useState('');
  const [deposit_2, setDeposit2] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  let accentColor = pluginService.getIFrameColor()

  function handleChange(e) {
    const { name, value } = e.target;

    const setDeposits = {
      "deposit_1": setDeposit1,
      "deposit_2": setDeposit2
    }

    setDeposits[name](value);
    setIsTyping(true);
  }

  function verifyBankAccount(e) {
    e.preventDefault();

    if (props.setStopSubmit) {
      props.setStopSubmit(true)
    }

    setIsTyping(false);

    if (deposit_1 && deposit_2 && sharedService.isFloat(deposit_1) && sharedService.isFloat(deposit_2)) {
      if (props.org_Id) {
        dispatch(organizationstripeActions.verifyBankAccount(false, props.org_Id, deposit_1, deposit_2));
      } else {
        dispatch(stripeActions.verifyBankAccount(false, user.id, deposit_1, deposit_2));
      }
    }

    setSubmitted(true);
  }

  function validDeposit(deposit) {
    return (sharedService.isFloat(deposit) || sharedService.isTypingFloat(deposit))
  }

  return (
    <>
      {/* Regular styling */}

      {props.regularStyling &&

        <form onSubmit={verifyBankAccount} className="billingAddress">
          <div className="checkoutVerificationText" style={{ margin: "30px 0" }}>
            <p>To verify your bank account, please enter the two small deposits sent by Stripe.
              For example, if the microdeposit amount is eighteen cents, enter the amount here as 0.18.</p>
            <p>
              Note: For security reasons, you have three attempts to complete this verification.
              After three attempts, your account will no longer be able to be verified.
            </p>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <div>$ <input name="deposit_1" value={deposit_1} onChange={handleChange} placeholder="Deposit 1" /> (USD)
              {deposit_1 && !validDeposit(deposit_1) && <p style={{ color: "red", margin: 0 }}>You may only enter numbers</p>}
              {submitted && !isTyping && deposit_1 && !sharedService.isFloat(deposit_1) && <p style={{ color: "red", margin: 0 }}>Invalid input</p>}
              {submitted && !isTyping && !deposit_1 && <p style={{ color: "red", marginTop: 5 }}>This is required</p>}
            </div>
            <div>$ <input name="deposit_2" value={deposit_2} onChange={handleChange} placeholder="Deposit 2" /> (USD)
              {deposit_2 && !validDeposit(deposit_2) && <p style={{ color: "red", margin: 0 }}>You may only enter numbers</p>}
              {submitted && !isTyping && deposit_2 && !sharedService.isFloat(deposit_2) && <p style={{ color: "red", margin: 0 }}>Invalid input</p>}
              {submitted && !isTyping && !deposit_2 && <p style={{ color: "red", marginTop: 5 }}>This is required</p>}
            </div>
          </div>

          {submitted && !loading_bankRequest && deposit_1 && deposit_2 && !sharedService.bankIsVerified(props.billingInfo.bank_Account) &&
            <p style={{ marginTop: 10, color: "red" }}>
              Unable to verify account. Please check your microdeposit amounts and try again.
              For security reasons, you get three total attempts, so if you're not sure why there's an error, please contact OpenGrants support in the lower left of your screen, and we will help you troubleshoot.
            </p>
          }

          <div style={{ margin: "30px 0" }}>
            <button
              className="billingButton"
              onClick={verifyBankAccount}
              style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Verify
            </button>
            <button
              className="billingButton secondary"
              onClick={props.setVerificationForm}
              style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Cancel
            </button>
          </div >

        </form>

      }

      {/* Billing page specific styling */}

      {!props.regularStyling &&

        <form onSubmit={verifyBankAccount}>
          <hr />
          <div className="row paymentRow">
            <div className="col-md-3 billingInfoLeftCol">
              <p>Bank Verification</p>
            </div>
            <div className="col-md-5 billingInfoRightCol">
              <p>To verify your bank account, please enter the two small deposits sent by Stripe.
                For example, if the microdeposit amount is eighteen cents, enter the amount here as 0.18.</p>
              <p>
                Note: For security reasons, you have three attempts to complete this verification.
                After three attempts, your account will no longer be able to be verified.
              </p>
            </div >
          </div>

          <div className="row paymentRow" style={{ display: "flex", alignItems: "center" }}>
            <div className="col-md-3 billingInfoLeftCol">
              <p>Deposit 1</p>
            </div>
            <div className="col-md-5 billingInfoRightCol" style={{ marginBottom: 10 }}>
              $ <input name="deposit_1" value={deposit_1} onChange={handleChange} /> (USD)
              {deposit_1 && !validDeposit(deposit_1) && <p style={{ color: "red", margin: 0 }}>You may only enter numbers</p>}
              {submitted && !isTyping && deposit_1 && !sharedService.isFloat(deposit_1) && <p style={{ color: "red", margin: 0 }}>Invalid input</p>}
              {submitted && !isTyping && !deposit_1 && <p style={{ color: "red", margin: 0 }}>This is required</p>}
            </div >
          </div>
          <div className="row paymentRow" style={{ display: "flex", alignItems: "center" }}>
            <div className="col-md-3 billingInfoLeftCol">
              <p>Deposit 2</p>
            </div>
            <div className="col-md-5 billingInfoRightCol" style={{ marginBottom: 10 }}>
              $ <input name="deposit_2" value={deposit_2} onChange={handleChange} /> (USD)
              {deposit_2 && !validDeposit(deposit_2) && <p style={{ color: "red", margin: 0 }}>You may only enter numbers</p>}
              {submitted && !isTyping && deposit_2 && !sharedService.isFloat(deposit_2) && <p style={{ color: "red", margin: 0 }}>Invalid input</p>}
              {submitted && !isTyping && !deposit_2 && <p style={{ color: "red", margin: 0 }}>This is required</p>}
            </div >
          </div>
          <div className="row paymentRow">
            <div className="col-md-3 billingInfoLeftCol"></div>
            <div className="col-md-5 billingInfoRightCol">
              {submitted && !loading_bankRequest && deposit_1 && deposit_2 && !sharedService.bankIsVerified(bank_Account) &&
                <p style={{ color: "red", margin: 0 }}>
                  Unable to verify account. Please check your microdeposit amounts and try again.
                  For security reasons, you get three total attempts, so if you're not sure why there's an error, please contact OpenGrants support in the lower left of your screen, and we will help you troubleshoot.
                </p>
              }
            </div >
          </div>
          <div className="row paymentRow">
            <div className="col-md-3 billingInfoLeftCol"></div>
            <div className="col-md-5 billingInfoRightCol">
              <button
                className="billingButton"
                onClick={verifyBankAccount}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Verify
              </button>
              <button
                className="billingButton secondary"
                onClick={props.setVerificationForm}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Cancel
              </button>
            </div >
          </div>
        </form>
      }
    </>
  )
}