import React from 'react'

export default function SP_Payout_Account_Display({ stripe_Payout_Account }) {
    return (
        <div>
            <div className="row paymentRow">
                <div className="col-md-3 billingInfoLeftCol">
                    <p>Bank Name</p>
                </div>
                <div className="col-md-5 billingInfoRightCol">
                    <p>{stripe_Payout_Account.bank_name}</p>
                </div >
            </div>
            <div className="row paymentRow">
                <div className="col-md-3 billingInfoLeftCol">
                    <p>Account Holder</p>
                </div>
                <div className="col-md-5 billingInfoRightCol">
                    <p>{stripe_Payout_Account.account_holder_name}</p>
                </div >
            </div>
            <div className="row paymentRow">
                <div className="col-md-3 billingInfoLeftCol">
                    <p>Account Type</p>
                </div>
                <div className="col-md-5 billingInfoRightCol">
                    <p>{stripe_Payout_Account.account_holder_type}</p>
                </div >
            </div>
            <div className="row paymentRow">
                <div className="col-md-3 billingInfoLeftCol">
                    <p>Account Number</p>
                </div>
                <div className="col-md-5 billingInfoRightCol">
                    <p>****-***-{stripe_Payout_Account.last4}</p>
                </div >
            </div>
            <div className="row paymentRow">
                <div className="col-md-3 billingInfoLeftCol">
                    <p>Routing Number</p>
                </div>
                <div className="col-md-5 billingInfoRightCol">
                    <p>{stripe_Payout_Account.routing_number}</p>
                </div >
            </div>
        </div>
    )
}
