import React from 'react';
import { faCopy, faHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import he from "../../../../node_modules/he";
import "../../../Styles/GrantListingPage.css";
import { upgradeActions } from '../../../_actions';
import { listingNotesService, listingService, pluginService, sharedService } from '../../../_services';
import { Loader as Loading } from '../../Shared/Loader';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { Row } from 'react-bootstrap';

export function ResultModal_v2({
  isOpen,
  isFavorite,
  listing,
  viewListing,
  listingId,
  saveListing,
  normalized_due_date,
  checkHTML,
  applyNow,
  note,
  editNote,
  deleteNotes,
  saveNote,
  iFrameConfig,
  noNotes,
  noSaving,
}) {

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.setUser);

  const [updatedAlertLinkCopy, setUpdatedAlertLinkCopy] = useState([]);
  const [dbListing, setDbListing] = useState({});
  const [loading, setLoading] = useState(true)

  let freeClient = (user.userRoles.includes("Client_Free") ? true : false)
  let accentColor = pluginService.getIFrameColor()

  // Get listing notes and save them in notes state
  useEffect(() => {

    //if modal is open, get notes and listing data
    if (isOpen) {
      if (!noNotes) {
        listingNotesService.getListingNotes(user.id, listingId).then(res => {
          editNote(false, res.user_notes)
        })
      }
      listingService.getListing(listingId).then(res => {
        if (res) {
          setDbListing(res)
        }
        setLoading(false)
      })
    }
  }, [isOpen])

  // Show an alert if grant link was copied
  const linkCopied = () => {
    setUpdatedAlertLinkCopy(true)
    setTimeout(() => {
      setUpdatedAlertLinkCopy(false)
    }, 3000);
  }

  // Open intercom chat
  // const openChat = (type) => {
  //   window.Intercom('trackEvent', type);
  //   window.Intercom('showNewMessage');
  // }

  // Replace HTML codes with string values for ', ", &, <, >
  const convertHTMLText = (str) => {
    if (str) {
      let newStr = str
        .replace(/&apos;/gi, "'")
        .replace(/&#039;/gi, "'")
        .replace(/&#8217;/gi, "'")
        .replace(/&quot;/gi, '"')
        .replace(/&amp;/gi, "&")
        .replace(/&lt;/gi, "<")
        .replace(/&gt;/gi, ">")
      return newStr;
    }
    else {
      return str
    }
  }

  const viewOriginal = () => {
    const source_link = dbListing.listing_URL;

    if (source_link) {
      window.open(source_link, "_blank");
    }
  }

  const autoSave = () => {
    // Save the notes
    saveNote()
  }

  return (
    <>
      {loading ? <Loading />
        :
        <>
          {dbListing && Object.keys(dbListing).length !== 0 ?
            <Modal
              isOpen={isOpen}
              centered={true}
              backdrop={true}
              toggle={(listingId) => viewListing(listingId)}
              fade={false}
              className="ModalDiv"
              size="lg"
            >
              <ModalBody style={{ padding: "15px 30px 30px 30px" }}>
                <Row className='mb-48'>
                  <button className='fs-3 text-end bg-transparent' onClick={(listingId) => viewListing(listingId)}>x</button>
                </Row>
                <Row>

                  {/* GRANT TITLE */}
                  <div className="col-md-8 noPadding">
                    <p><strong>{convertHTMLText(listing.title)}</strong></p>
                    <p className="noMargin">{listing.agency}</p>
                  </div>

                  <div className="col-md-3 iconsWithText justify-content-start fs-3">

                    {/* ICONS */}
                    <div className="iconsRow row">

                      {/* SAVE */}
                      <div className="col-md-6 iconColumn noPadding">
                        {!iFrameConfig?.url && !noSaving &&
                          <button
                            className={"iconButton " + (isFavorite ? "red " : "blue")}
                            onClick={() => { saveListing(listingId, user) }}
                          >
                            {isFavorite ?
                              <FontAwesomeIcon icon={faHeartSolid} />
                              :
                              <FontAwesomeIcon icon={faHeart} />
                            }
                          </button>
                        }
                        {/* {(freeClient && !iFrameConfig?.url) && !noSaving &&
                        <button
                          data-tip
                          data-for="saveIcon"
                          className={"iconButton " + (isFavorite ? "red " : "blue")}
                          onClick={() => { dispatch(upgradeActions.openUpgradeModal()) }}
                        >
                          <FontAwesomeIcon icon={faHeart} />
                          <ReactTooltip id="saveIcon" place="top" effect="solid">
                            Upgrade your account to save listings.
                          </ReactTooltip>
                        </button>
                      } */}
                      </div>

                      {/* COPY LINK */}
                      {!iFrameConfig?.url && !noSaving &&
                        <div className="col-md-6 iconColumn noPadding">
                          <button
                            className="iconButton blue"
                            onClick={() => { linkCopied() }}
                          >
                            <CopyToClipboard text={sharedService.generate_copy_link(listing)}>
                              <FontAwesomeIcon icon={faCopy} />
                            </CopyToClipboard>
                          </button>
                        </div>
                      }
                    </div>

                    {/* TEXT UNDER ICONS */}
                    <div className="row iconsTextRow">
                      {!iFrameConfig?.url && !noSaving &&
                        <>
                          <div className="col-md-6 iconColumn">
                            <p style={{ fontFamily: "Lato", fontSize: 14, margin: 0 }}>{isFavorite ? "Saved" : "Save"}</p>
                          </div>
                          <div className="col-md-6 iconColumn" style={{ padding: 0 }}>
                            <p style={{ fontFamily: "Lato", fontSize: 14, margin: 0 }}>
                              {updatedAlertLinkCopy === true ? "Link Copied!" : "Copy Link"}
                            </p>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </Row>
                <hr />
                <br />
                {/* HIRE A GRANT WRITER AND VIEW ORIGINAL */}
                <div className="row">
                  <div className="col-md-6 noPadding dflex hireViewOriginalButtons">
                    <button
                      className="billingButton resultModal"
                      onClick={() => { applyNow() }}
                      style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069', padding: "10px 20px" }}
                    >
                      Hire a Grant Writer
                    </button>
                    {dbListing.listing_URL &&
                      <button
                        className="billingButton secondary resultModal"
                        style={{ padding: "10px 20px", color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                        onClick={() => { viewOriginal() }}
                      >
                        View Original
                      </button>
                    }
                    {!dbListing.listing_URL && dbListing.grantor_Contact_Email &&
                      <a href={dbListing.grantor_Contact_Email}
                        className="billingButton secondary resultModal"
                        style={{ textAlign: "center", padding: "10px 20px", color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>Email Grantor</a>
                    }
                  </div>
                </div>

                <br />

                {/* -------------- Keep these for future use when we have more details! -------------- */}

                {/* DETAILS ROW 1 
        
                <div className="row">
                  FUNDING AMOUNT 
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.funding_Amount != null && listing.funding_Amount > 0 && (
                        <p><strong>Funding: </strong>${listing.funding_Amount.toLocaleString()}</p>
                      )}
                      {listing.funding_Amount === 0 && (
                        <p><strong>Funding: </strong> Varies.</p>
                      )}
                    </div>
                  </div>
                  MATCH REQUIRED
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Match Required: </strong>Yes</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Match Required: </strong>No</p>
                      )}
                    </div>
                  </div>
                  GRANTOR TYPE NAME
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Grantor Type Name: </strong>N/A</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Grantor Type Name: </strong>N/A</p>
                      )}
                    </div>
                  </div>
                  AWARD FLOOR
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Award Floor: </strong>N/A</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Award Floor: </strong>N/A</p>
                      )}
                    </div>
                  </div>
                </div>

                DETAILS ROW 2

                <div className="row">
                  DUE
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.application_Due_Date && (
                        <p><strong>Due: </strong>{normalized_due_date}</p>
                      )}
                      {!listing.application_Due_Date && (
                        <p><strong>Due: </strong>Applications ongoing.</p>
                      )}
                    </div>
                  </div>
                  MATCH AMOUNT
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Match Amount: </strong>N/A</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Match Amount: </strong>N/A</p>
                      )}
                    </div>
                  </div>
                  NUMBER OF AWARDS
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Number of Awards: </strong>N/A</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Number of Awards: </strong>N/A</p>
                      )}
                    </div>
                  </div>
                  AWARD CEILING
                  <div className="col-md-3" style={{ padding: "0px" }}>
                    <div className="hit-description" style={{ color: "#333", marginTop: 5 }}>
                      {listing.match_Required && listing.match_Required == true && (
                        <p><strong>Award Ceiling: </strong>N/A</p>
                      )}
                      {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) && (
                        <p><strong>Award Ceiling: </strong>N/A</p>
                      )}
                    </div>
                  </div>
                </div> */}

                {/* -------------- Temporary details until we have more info -------------- */}

                <div className="row detailsRow">
                  <div className="col-md-3 noPadding">
                    <p>
                      {listing.funding_Amount && listing.funding_Amount > 0 ? "$" + listing.funding_Amount.toLocaleString() : "Funding varies."}
                    </p>
                  </div>
                  <div className="col-md-3 noPadding">
                    <p><strong>Due: </strong>
                      {listing.application_Due_Date ? normalized_due_date : "Applications ongoing"}
                    </p>
                  </div>
                  <div className="col-md-3 noPadding">
                    <p><strong>Match Required: </strong>
                      {listing.match_Required && listing.match_Required === true ? "Yes" : "No"}
                    </p>
                  </div>
                </div>


                {
                  !iFrameConfig?.url && !noNotes &&
                  <>
                    <hr />

                    {/* NOTES */}
                    <div className="flexCol">
                      <h1 style={{ color: "#666", fontSize: 20, marginTop: 0 }}><strong>Notes</strong></h1>

                      {/* PRO PLAN USER */}
                      {!freeClient &&
                        <div>
                          {/* NOT EDITING */}
                          {!note.edit &&
                            <div>
                              {/* OLD NOTES */}
                              {note.user_notes &&
                                <div className="row">
                                  <div className="col-md-9 noPadding">
                                    <p>{note.user_notes}</p>
                                  </div>
                                  <div className="col-md-3 noPadding editDeleteButtons">
                                    <button className="billingButton" onClick={() => { editNote(true) }}>Edit</button>
                                    <button className="billingButton secondary" onClick={() => { deleteNotes() }}>Delete</button>
                                  </div>
                                </div>
                              }
                              {/* NEW NOTES */}
                              {!note.user_notes &&
                                <div className="col-md-2 noPadding">
                                  <button className="billingButton" style={{ padding: "10px 20px" }} onClick={() => { editNote(true) }}> Add Notes </button>
                                </div>
                              }
                            </div>
                          }

                          {/* EDITING */}
                          {note.edit &&
                            <div>
                              {/* INPUT */}
                              <textarea rows={3} id="user_notes" className="notesTextArea" placeholder="Add your notes here...">
                                {note.user_notes}
                              </textarea>
                              {/* SAVE AND CANCEL */}
                              <div className="col-md-2 noPadding dflex">
                                <button
                                  id="save_notes"
                                  className="billingButton smallMarginRight"
                                  onClick={() => { autoSave() }}
                                >
                                  Save
                                </button>
                                <button
                                  className="billingButton secondary"
                                  onClick={() => { editNote(false) }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          }
                        </div>
                      }

                      {/* FREE PLAN USER */}
                      {freeClient &&
                        <div className="col-md-2 noPadding">
                          <button
                            data-tip
                            data-for="addNotes"
                            className="billingButton"
                            style={{ padding: "10px 20px" }}
                            onClick={() => { dispatch(upgradeActions.openUpgradeModal()) }}
                          >
                            Add Notes
                          </button>
                          <ReactTooltip id="addNotes" place="top" effect="solid">
                            Upgrade your account to add notes to listings.
                          </ReactTooltip>
                        </div>
                      }
                    </div>
                  </>
                }

                <hr />

                <h1 style={{ color: "#666", fontSize: 20 }}><strong>Overview</strong></h1>

                {/* GRANT DESCRIPTION */}
                <div className="row">
                  <div className="col-md-12 noPadding">
                    {listing.description && checkHTML(listing.description) && (
                      <div dangerouslySetInnerHTML={{ __html: he.decode(listing.description.trim()), }}></div>
                    )}
                    {listing.description && !checkHTML(listing.description) && (
                      <p style={{ color: "#444", fontWeight: 400, lineHeight: 1.75 }}>
                        {he.decode(listing.description.trim())}
                      </p>
                    )}
                    {!listing.description && (
                      <p>
                        No description has been provided for this listing. Please refer
                        to the documentation (View Original) or contact us to learn more
                        about this program.
                      </p>
                    )}
                  </div>
                </div>

                {/* GRANT CATEGORY */}
                {/* <div className="center">
                  {listing.listing_Tags && listing.listing_Tags.map(function (tag) {
                    return (
                      <h6 id="hitCategoryDash" className="listingTag" >
                        {tag.tag != null ? tag.tag : tag.category_Tag}
                      </h6>
                    );
                  })}
                  {(listing.listing_Tags || !listing.listing_Tags) && (
                    <h6 id="hitCategoryDash" className="listingTag">
                      General Funding Opportunities
                    </h6>
                  )}
                </div> */}

                {/* SUGGESTIONS */}
                {/* {!iFrameConfig?.url &&
                  <div className="center">
                    <a className="suggestionsLink" onClick={() => window.HubSpotConversations.widget.open()} style={{ color: accentColor || '#0C7069' }}>
                      Have suggestions? Send us a message!
                    </a>
                  </div>
                } */}

                <br />
              </ModalBody>
            </Modal>
            :
            <Modal
              isOpen={isOpen}
              centered={true}
              backdrop={true}
              toggle={(listingId) => viewListing(listingId)}
              fade={false}
              className="ModalDiv"
              size="lg"
              style={{ top: 150 }}
            >
              <ModalHeader
                className="row resultModalHeader"
                toggle={(listingId) => viewListing(listingId)}
                style={{ padding: "30px 30px 0px 30px", borderBottom: "none" }}
              />
              <ModalBody style={{ padding: "15px 30px 30px 30px" }}>
                <div>
                  <div className="row center">
                    <h1 className="congratsHeader" style={{ marginTop: 0, fontSize: 36 }}>
                      The grant is no longer available
                    </h1>
                  </div>
                  <div className="row center">
                    <p className="congratsDescription" style={{ margin: 0 }}>
                      This grant has expired or the application deadline has passed.
                    </p>
                  </div>
                  <div className="row center" style={{ position: 'relative' }}>
                    <button
                      onClick={(listingId) => viewListing(listingId)}
                      className="defaultGreenButton"
                      style={{ padding: "10px 20px", marginTop: 20, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                    >
                      Back To Grant Finder <i className="icon-arrow-right" />
                    </button>
                  </div>
                </div>
                <br />
              </ModalBody>
            </Modal>
          }
        </>
      }
    </>
  );
}
