import React from 'react';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { Loader } from '../../../../Shared/Loader';
import { Item } from './Item';

export const AddOns = (props) => {
    const billingHandler = useSelector(state => state.billingHandler);
    const [loading, setLoading] = useState(true);


    function displayAddOns() {
        const selectedPriceIds = billingHandler.priceIds?.selectedPriceIds;
        const selectedRecurringPriceIds = billingHandler.priceIds?.selectedRecurringPriceIds;

        if (!selectedPriceIds && !selectedRecurringPriceIds) { return null; }
        if (!props.consolidatedProductsAddOns) { return null; }

        let productIds = selectedPriceIds.map((price) => price.product.id);
        let recurringProductIds = selectedRecurringPriceIds.map((price) => price.product.id);

        const product_ids = Object.keys(props.consolidatedProductsAddOns);

        const add_ons = product_ids.map(product_id => {
            if (!(productIds.includes(product_id) || recurringProductIds.includes(product_id))) {
                return <Item
                    prices={props.consolidatedProductsAddOns[product_id]}
                />
            }
        });

        if (loading) {
            setLoading(false);
        }

        return add_ons;
    }

    return (
        <div>
            {loading && <Loader />}
            {displayAddOns()}
        </div>
    )

}