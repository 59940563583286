import { authenticateConstants, stripeConstants } from '../_constants';
import { authenticateService, stripeService } from '../_services';

export const stripeActions = {
    requestUpdatePaymentMethod,
    verifyBankAccount,
    displayBankVerificationNotification,

    getBillingInfo,
    setBillingInfo,
    updateUserDetails,

    updateBillingAddress,
    getBillingAddress,
    setBillingAddress,

    createStripeAccount,
    handleStripeToken,

    purchaseProduct,
    getPlanSubscription,
    renewPlanSubscription,
    cancelSubscription,
    updateSubscription,
    cancelSubscriptionDowngrade,
    updateTermsAndConditions,
    updateCheckoutMessage,

    checkStripeConnectVerification,

    setHandlePaymentSubmission,
    setPaymentSubmission,
    setIsPaymentValid,
    setHandleAddressSubmission,
    setAddressSubmission,
    setIsAddressValid,
    setPaymentHandler,
    setPaymentSubmissionHandler,

    setPriceIds,
    setCoupon,
    setAgreements,
    // setPlan
}

function requestUpdatePaymentMethod(updatePaymentMethod, paymentSelection) {
    return dispatch => {
        if (updatePaymentMethod) {
            dispatch(request(paymentSelection));
        } else {
            dispatch(reset());
        }
    }

    function request(paymentSelection) { return { type: stripeConstants.UPDATE_BILLING_INFO_REQUEST, paymentSelection } }
    function reset() { return { type: stripeConstants.UPDATE_BILLING_INFO_RESET } }
}

function verifyBankAccount(reset, user_id, deposit_1, deposit_2) {
    return dispatch => {
        if (reset) {
            dispatch(failure())
        }
        else {
            dispatch(request());
            stripeService.verifyBankAccount(user_id, deposit_1 * 100, deposit_2 * 100)
                .then(
                    (result) => {
                        if (result) {
                            dispatch(success(result.billingInfo));
                        } else {
                            dispatch(failure());
                        }
                    },
                    error => {
                        dispatch(failure(error));
                    }
                )
        }
    }

    function request() { return { type: stripeConstants.BANK_VERIFICATION_REQUEST } }
    function success(billingInfo) { return { type: stripeConstants.BANK_VERIFICATION_SUCCESS, billingInfo } }
    function failure() { return { type: stripeConstants.BANK_VERIFICATION_RESET } }
}

function displayBankVerificationNotification(bank_verification_notification) {
    return dispatch => {
        dispatch(notification(bank_verification_notification))
    }
    function notification(bank_verification_notification) { return { type: stripeConstants.BANK_VERIFICATION_NOTIFICATION, bank_verification_notification } }
}

function getBillingInfo(user_id) {
    return dispatch => {
        dispatch(request());
        stripeService.getBillingInfo(user_id)
            .then(
                (result) => {
                    dispatch(success(result.billingInfo))
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.BILLING_REQUEST } }
    function success(billingInfo) { return { type: stripeConstants.BILLING_SUCCESS, billingInfo } }
    function failure(error) { return { type: stripeConstants.BILLING_FAILURE, error } }
}

function setBillingInfo(billingInfo) {
    return dispatch => {
        dispatch(success(billingInfo));
    }

    function success(billingInfo) { return { type: stripeConstants.BILLING_SUCCESS, billingInfo } }
}

function createStripeAccount(code, state) {
    return dispatch => {
        dispatch(request());
        stripeService.createStripeAccount(code, state)
            .then(
                () => {
                    dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.CONNECT_REQUEST } }
    function success() { return { type: stripeConstants.CONNECT_SUCCESS } }
    function failure(error) { return { type: stripeConstants.CONNECT_FAILURE, error } }
}

function handleStripeToken(billingAddress) {
    return dispatch => {
        dispatch(request());
        stripeService.handleStripeToken(billingAddress)
            .then(
                billingAddress => {
                    dispatch(success(billingAddress));
                    dispatch(returnBilling(billingAddress));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.TOKEN_REQUEST } }
    function success(billingAddress) { return { type: stripeConstants.TOKEN_SUCCESS, billingAddress } }
    function failure(error) { return { type: stripeConstants.TOKEN_FAILURE, error } }
    function returnBilling(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
}

function getBillingAddress(userId) {
    return dispatch => {
        dispatch(request());
        stripeService.getBillingAddress(userId)
            .then(
                billingAddress => {
                    dispatch(success(billingAddress));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.BILLING_ADDRESS_REQUEST } }
    function success(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
    function failure(error) { return { type: stripeConstants.BILLING_ADDRESS_FAILURE, error } }
}

function setBillingAddress(billingAddress) {
    return dispatch => {
        dispatch(success(billingAddress));
    }

    function success(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
}

function updateBillingAddress(billings) {
    return dispatch => {
        dispatch(request());
        stripeService.updateBillingAddress(billings).then(
            billingAddress => {
                dispatch(success(billingAddress));
            },
            error => {
                dispatch(failure(error));
            }
        )
    }

    function request() { return { type: stripeConstants.BILLING_ADDRESS_REQUEST } }
    function success(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
    function failure(error) { return { type: stripeConstants.BILLING_ADDRESS_FAILURE, error } }
}

function purchaseProduct(productDetails, user) {
    return dispatch => {
        dispatch(request());
        stripeService.purchaseProduct(productDetails)
            .then(
                async result => {
                    let message = result.message;
                    if (message == "Ok") {
                        result.message = "";
                        if (result.userRoles) {
                            //update user role
                            user.userRoles = result.userRoles;
                            await authenticateService.refreshToken(user);
                            dispatch(updateUser(user));

                            //update plan
                            dispatch(getPlanSubscription(user.id))
                        }
                        dispatch(success(result));
                        // dispatch(reset());
                    } else {
                        dispatch(failure(result));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.PURCHASE_PRODUCT_REQUEST } }
    function success(result) { return { type: stripeConstants.PURCHASE_PRODUCT_SUCCESS, result } }
    function failure(result) { return { type: stripeConstants.PURCHASE_PRODUCT_FAILURE, result } }
    // function reset() { return { type: stripeConstants.PURCHASE_PRODUCT_RESET } }
    function updateUser(user) { return { type: authenticateConstants.SETUSER_REQUEST, user } }
}

function updateUserDetails(productDetails, additional_action) {
    return dispatch => {
        dispatch(request());
        dispatch(update_request());
        stripeService.updateUserDetails(productDetails)
            .then(
                result => {
                    dispatch(success());
                    dispatch(update_success(result.billingInfo));
                    if (additional_action) additional_action()
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: stripeConstants.UPDATE_BILLING_REQUEST } }
    function success() { return { type: stripeConstants.UPDATE_BILLING_SUCCESS } }
    function failure() { return { type: stripeConstants.UPDATE_BILLING_FAILURE } }
    function update_request() { return { type: stripeConstants.BILLING_REQUEST } }
    function update_success(billingInfo) { return { type: stripeConstants.BILLING_SUCCESS, billingInfo } }
}

function getPlanSubscription(user_id) {
    return dispatch => {
        dispatch(request());
        stripeService.getPlanSubscription(user_id)
            .then(
                plan => {
                    dispatch(setPlan(plan));
                }
            )
    }

    function request() { return { type: stripeConstants.SET_PLAN_REQUEST } }
    function setPlan(plan) { return { type: stripeConstants.SET_PLAN, plan } }
}

function renewPlanSubscription(user_id) {
    return dispatch => {
        dispatch(request());
        stripeService.renewPlanSubscription(user_id)
            .then(
                plan => {
                    dispatch(setPlan(plan));
                }
            )
    }

    function request() { return { type: stripeConstants.SET_PLAN_REQUEST } }
    function setPlan(plan) { return { type: stripeConstants.SET_PLAN, plan } }
}

function cancelSubscription(subscriptionId, isFree) {
    return dispatch => {
        dispatch(request());
        stripeService.cancelSubscription(subscriptionId, isFree)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_REQUEST } }
    function success() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS } }
    function failure() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_FAILURE } }
    function reset() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_RESET } }
}

function cancelSubscriptionDowngrade(userId, subscriptionId) {
    return dispatch => {
        dispatch(request());
        stripeService.cancelSubscriptionDowngrade(userId, subscriptionId)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_REQUEST } }
    function success() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS } }
    function failure() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_FAILURE } }
    function reset() { return { type: stripeConstants.CANCEL_SUBSCRIPTION_RESET } }
}

function updateSubscription(subscriptionId) {
    return dispatch => {
        dispatch(request());
        stripeService.updateSubscription(subscriptionId)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: stripeConstants.UPDATE_SUBSCRIPTION_REQUEST } }
    function success() { return { type: stripeConstants.UPDATE_SUBSCRIPTION_SUCCESS } }
    function failure() { return { type: stripeConstants.UPDATE_SUBSCRIPTION_FAILURE } }
    function reset() { return { type: stripeConstants.UPDATE_SUBSCRIPTION_RESET } }
}

function updateTermsAndConditions(agreement) {
    return dispatch => {
        dispatch(update(agreement));
    }

    function update(agreement) { return { type: stripeConstants.UPDATE_TERMS_AND_CONDITIONS, termsAndConditions: agreement } }
}

function updateCheckoutMessage(message) {
    return dispatch => {
        dispatch(update(message));
    }

    function update(message) { return { type: stripeConstants.UPDATE_CHECKOUT_MESSAGE, message } }
}

function checkStripeConnectVerification(account_id) {
    return dispatch => {
        dispatch(request());
        stripeService.checkStripeConnectVerification(account_id)
            .then(
                stripe_Verified => {
                    dispatch(success(stripe_Verified));
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_REQUEST } }
    function success(stripe_Verified) { return { type: stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_SUCCESS, stripe_Verified } }
    function failure() { return { type: stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_FAILURE } }
}

function setHandlePaymentSubmission(handlePaymentSubmission) {
    return dispatch => {
        dispatch(setPaymentSubmission(handlePaymentSubmission));
    }

    function setPaymentSubmission(handlePaymentSubmission) { return { type: stripeConstants.SET_HANDLE_PAYMENT_SUBMISSION, handlePaymentSubmission } }
}

function setPaymentSubmission(submitPayment) {
    return dispatch => {
        dispatch(setPaymentSubmission(submitPayment));
    }

    function setPaymentSubmission(submitPayment) { return { type: stripeConstants.SET_PAYMENT_SUBMISSION, submitPayment } }
}

function setIsPaymentValid(isPaymentValid) {
    return dispatch => {
        dispatch(setIsPaymentValid(isPaymentValid));
    }

    function setIsPaymentValid(isPaymentValid) { return { type: stripeConstants.SET_IS_PAYMENT_VALID, isPaymentValid } }
}

function setHandleAddressSubmission(handleAddressSubmission) {
    return dispatch => {
        dispatch(setHandleAddressSubmission(handleAddressSubmission));
    }

    function setHandleAddressSubmission(handleAddressSubmission) { return { type: stripeConstants.SET_HANDLE_ADDRESS_SUBMISSION, handleAddressSubmission } }
}

function setAddressSubmission(submitAddress) {
    return dispatch => {
        dispatch(setAddressSubmission(submitAddress));
    }

    function setAddressSubmission(submitAddress) { return { type: stripeConstants.SET_ADDRESS_SUBMISSION, submitAddress } }
}

function setIsAddressValid(isAddressValid) {
    return dispatch => {
        dispatch(setIsAddressValid(isAddressValid));
    }

    function setIsAddressValid(isAddressValid) { return { type: stripeConstants.SET_IS_ADDRESS_VALID, isAddressValid } }
}

function setPaymentHandler(paymentHandler) {
    return dispatch => {
        dispatch(setPaymentHandler(paymentHandler));
    }

    function setPaymentHandler(paymentHandler) { return { type: stripeConstants.SET_PAYMENT_HANDLER, paymentHandler } }
}

function setPaymentSubmissionHandler(handler) {
    return dispatch => {
        dispatch(setPaymentSubmissionHandler(handler));
    }

    function setPaymentSubmissionHandler(handler) { return { type: stripeConstants.SET_PAYMENT_SUBMISSION_HANDLER, handler } }
}

function setPriceIds(priceIds) {
    return dispatch => {
        dispatch(setPriceIds(priceIds));
    }

    function setPriceIds(priceIds) { return { type: stripeConstants.SET_PRICE_IDS, priceIds } }
}

function setCoupon(coupon) {
    return dispatch => {
        dispatch(setCoupon(coupon))
    }

    function setCoupon(coupon) { return { type: stripeConstants.SET_COUPON, coupon } }
}

function setAgreements(agreements) {
    return dispatch => {
        dispatch(setCoupon(agreements))
    }

    function setCoupon(agreements) { return { type: stripeConstants.SET_AGREEMENTS, agreements } }
}

// function setPlan(plans) {
//     return dispatch => {
//         dispatch(setPlan(plans))
//     }

//     function setPlan(plans) {return {type: stripeConstants.SET_PLAN, plans}}
// }
