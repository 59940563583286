export const stripeConstants = {
    BILLING_REQUEST: 'STRIPE_BILLING_REQUEST',
    BILLING_SUCCESS: 'STRIPE_BILLING_SUCCESS',
    BILLING_FAILURE: 'STRIPE_BILLING_FAILURE',

    CONNECT_REQUEST: 'STRIPE_CONNECT_REQUEST',
    CONNECT_SUCCESS: 'STRIPE_CONNECT_SUCCESS',
    CONNECT_FAILURE: 'STRIPE_CONNECT_FAILURE',

    TOKEN_REQUEST: 'STRIPE_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'STRIPE_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'STRIPE_TOKEN_FAILURE',

    BILLING_ADDRESS_REQUEST: 'STRIPE_BILLING_ADDRESS_REQUEST',
    BILLING_ADDRESS_SUCCESS: 'STRIPE_BILLING_ADDRESS_SUCCESS',
    BILLING_ADDRESS_FAILURE: 'STRIPE_BILLING_ADDRESS_FAILURE',

    PURCHASE_PRODUCT_REQUEST: 'STRIPE_PURCHASE_PRODUCT_REQUEST',
    PURCHASE_PRODUCT_SUCCESS: 'STRIPE_PURCHASE_PRODUCT_SUCCESS',
    PURCHASE_PRODUCT_FAILURE: 'STRIPE_PURCHASE_PRODUCT_FAILURE',
    PURCHASE_PRODUCT_RESET: 'STRIPE_PURCHASE_PRODUCT_RESET',

    UPDATE_BILLING_INFO_REQUEST: 'STRIPE_UPDATE_BILLING_INFO_REQUEST',
    UPDATE_BILLING_INFO_SUCCESS: 'STRIPE_UPDATE_BILLING_INFO_SUCCESS',
    UPDATE_BILLING_INFO_RESET: 'STRIPE_UPDATE_BILLING_INFO_RESET',

    UPDATE_BILLING_REQUEST: 'STRIPE_UPDATE_BILLING_REQUEST',
    UPDATE_BILLING_SUCCESS: 'STRIPE_UPDATE_BILLING_SUCCESS',
    UPDATE_BILLING_FAILURE: 'STRIPE_UPDATE_BILLING_FAILURE',
    UPDATE_BILLING_RESET: 'STRIPE_UPDATE_BILLING_RESET',

    CANCEL_SUBSCRIPTION_REQUEST: 'STRIPE_CANCEL_SUBSCRIPTION_REQUEST',
    CANCEL_SUBSCRIPTION_SUCCESS: 'STRIPE_CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAILURE: 'STRIPE_CANCEL_SUBSCRIPTION_FAILURE',
    CANCEL_SUBSCRIPTION_RESET: 'STRIPE_CANCEL_SUBSCRIPTION_RESET',

    UPDATE_SUBSCRIPTION_REQUEST: 'STRIPE_UPDATE_SUBSCRIPTION_REQUEST',
    UPDATE_SUBSCRIPTION_SUCCESS: 'STRIPE_UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'STRIPE_UPDATE_SUBSCRIPTION_FAILURE',
    UPDATE_SUBSCRIPTION_RESET: 'STRIPE_UPDATE_SUBSCRIPTION_RESET',

    PAYWALL_ALERT: 'STRIPE_PAYWALL_ALERT',

    BANK_VERIFICATION_REQUEST: 'BANK_VERIFICATION_REQUEST',
    BANK_VERIFICATION_SUCCESS: 'BANK_VERIFICATION_SUCCESS',
    BANK_VERIFICATION_RESET: 'BANK_VERIFICATION_RESET',
    BANK_VERIFICATION_NOTIFICATION: 'BANK_VERIFICATION_NOTIFICATION',

    UPDATE_TERMS_AND_CONDITIONS: 'UPDATE_TERMS_AND_CONDITIONS',
    UPDATE_CHECKOUT_MESSAGE: 'UPDATE_CHECKOUT_MESSAGE',

    CHECK_STRIPE_CONNECT_VERIFICATION_REQUEST: 'CHECK_STRIPE_CONNECT_VERIFICATION_REQUEST',
    CHECK_STRIPE_CONNECT_VERIFICATION_SUCCESS: 'CHECK_STRIPE_CONNECT_VERIFICATION_SUCCESS',
    CHECK_STRIPE_CONNECT_VERIFICATION_FAILURE: 'CHECK_STRIPE_CONNECT_VERIFICATION_FAILURE',

    SET_PAYMENT_SUBMISSION_HANDLER: 'SET_PAYMENT_SUBMISSION_HANDLER',
    SET_HANDLE_PAYMENT_SUBMISSION: 'SET_HANDLE_PAYMENT_SUBMISSION',
    SET_PAYMENT_SUBMISSION: 'SET_PAYMENT_SUBMISSION',
    SET_IS_PAYMENT_VALID: 'SET_IS_PAYMENT_VALID',

    SET_PRICE_IDS: 'SET_PRICE_IDS',

    SET_COUPON: 'SET_COUPON',

    SET_AGREEMENTS: 'SET_AGREEMENTS',

    SET_PLAN_REQUEST: 'SET_PLAN_REQUEST',
    SET_PLAN: 'SET_PLAN',

    SET_BILLING_HANDLER_LOADING: 'SET_BILLING_HANDLER_LOADING',

    SET_HANDLE_ADDRESS_SUBMISSION: 'SET_HANDLE_ADDRESS_SUBMISSION',
    SET_ADDRESS_SUBMISSION: 'SET_ADDRESS_SUBMISSION',
    SET_IS_ADDRESS_VALID: 'SET_IS_ADDRESS_VALID',

    SET_PAYMENT_HANDLER: 'SET_PAYMENT_HANDLER'
}