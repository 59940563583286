import { layoutConstants } from '../_constants';

export function layout(state = {}, action) {
  switch (action.type) {
    case layoutConstants.GETSTATS_REQUEST:
      return {
        loading: true
      };
    case layoutConstants.GETSTATS_SUCCESS:
      return {
        items: action.layout
      };
    case layoutConstants.GETSTATS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}