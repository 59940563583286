import { perksConstants } from '../_constants';

export function update_perks(state = { perks: [], loading: true }, action) {
  switch (action.type) {
    case perksConstants.GET_ALL_PERKS_SUCCESS:
      return {
        loading: false,
        perks: action.perks
      };
    case perksConstants.GET_ALL_PERKS_ERROR:
      return {
        loading: false,
        perks: action.perks
      };
    case perksConstants.GET_ALL_PERKS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case perksConstants.ADD_PERKS_SUCCESS:
      return {
        loading: false,
        perks: [action.perk, ...state.perks]
      };

    case perksConstants.ADD_PERKS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case perksConstants.EDIT_PERKS_SUCCESS:
      {
        let { perks } = state;
        perks = perks.filter((item) => {
          if (item.perk_Id !== action.perk.perk_Id) {
            return item;
          } else {
            let keys = Object.keys(item);

            keys.map((key) => {
              item[key] = action.perk[key];
            });

            return item;
          }
        });

        return {
          loading: false,
          perks
        };
      }
    case perksConstants.EDIT_PERKS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case perksConstants.DELETE_PERKS_SUCCESS:
      {
        let { perks } = state;
        perks = perks.filter((item) => {
          if (item.perk_Id !== action.perk_id) {
            return item;
          }
        });

        return {
          loading: false,
          perks
        }
      }
    case perksConstants.DELETE_PERKS_REQUEST:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
}