import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { settingsActions } from '../../_actions';
import { pluginService, sharedService } from '../../_services';

export default function PhoneNumberModal(props) {

    const { user } = useSelector(state => state.setUser);
    const dispatch = useDispatch();

    const [phoneNumber, setPhoneNumber] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    let accentColor = pluginService.getIFrameColor()

    //Update phone number state on change
    function handleChange(e) {
        setPhoneNumber(e.target.value)
    }

    //Submit phone number
    function handleSubmit(e) {

        e.preventDefault();

        setSubmitted(true);

        if (phoneNumber && sharedService.isInteger(phoneNumber)) {

            user.phoneNumber = phoneNumber;

            //update phone number
            dispatch(settingsActions.updateProfile({ user }));

            //close modal
            props.togglePhoneNumberModal()
        }
        else {
            return;
        }
    }

    //Cancel phone number submission
    function handleCancel(e) {
        e.preventDefault();

        //go back to original notification selection
        props.cancelNotificationSwitch();

        //close modal
        props.togglePhoneNumberModal()
    }

    return (

        <Modal
            isOpen={props.isOpen}
            centered={true}
            backdrop={true}
            fade={false}
            className="ModalDiv"
            size='xl'
        >
            <br />
            <ModalHeader className='invoiceModalHeader' style={{ textAlign: 'center', borderBottom: 'none', fontWeight: 'bold', fontSize: 18 }}>
                Add a phone number to receive SMS notifications
            </ModalHeader>
            <ModalBody style={{ textAlign: 'center', borderTop: 'none' }}>

                <form style={{ display: "flex", flexDirection: "column" }}>
                    <input
                        id="phoneNumber"
                        className='font-input-default email-text-field'
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        style={{ width: '80%', margin: "auto", fontSize: 14 }}
                    />

                    {submitted && (!phoneNumber || !sharedService.isInteger(phoneNumber)) &&
                        <p className='login-error' style={{ margin: "auto", marginTop: 5 }}>Invalid phone number</p>
                    }
                    <div style={{ marginTop: 20 }}>
                        <button
                            className="defaultWhiteButton fs-3"
                            onClick={handleCancel}
                            style={{ marginRight: 10, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                        >
                            Cancel
                        </button>
                        <button
                            className="defaultGreenButton fs-3"
                            onClick={handleSubmit}
                            style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                        >
                            Save phone number
                        </button>
                    </div>
                </form>
            </ModalBody>
            <br />
        </Modal>
    )
}
