import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GS_Onboarding_v2 } from '../../ServiceProviders/Onboarding/GS_Onboarding_v2';

export default function CreateOrganizationModal({
    isOpen, openModal, handleSubmittedOrg, noClosing
}) {
    return (
        <Modal
            isOpen={isOpen}
            centered={true}
            backdrop={true}
            fade={false}
            size='xl'
            toggle={noClosing ? null : () => openModal(false)}
        >
            <ModalHeader toggle={noClosing ? null : () => openModal(false)} style={{ borderBottom: 0, justifyContent: 'end' }}></ModalHeader>

            <ModalBody style={{ textAlign: 'center', borderTop: 'none', display: "flex", justifyContent: "center" }}>
                <div className="createOrgModal">
                    <GS_Onboarding_v2
                        creatingOrg={true}
                        handleSubmittedOrg={handleSubmittedOrg}
                        noCongrats={true}
                        openModal={openModal}
                    />
                </div>
            </ModalBody>
        </Modal >
    )
}
