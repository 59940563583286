import { recommendationService } from '../../../_services/recommendation.service';
import GradeIcon from '@mui/icons-material/Grade';
import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import { Component } from 'react';
import { connect } from 'react-redux';
import '../../../Styles/ServiceProvider_v2/Projects_v2.css';
import messageIcon from '../../../_assets/img/marketPlaceMessageIcon.png';
import { pluginService, serviceProviderService } from '../../../_services';
import { Messenger_v2 } from '../../Marketplace';
import { EditSPProjectDetails_v2, ServiceProvidersAcceptOrRejectProject_v2 } from './Components';
import RecommendationModal from "../../Recommendations/RecommendationModal";
import DeleteRecommendationModal from '../../Recommendations/DeleteRecommendationModal';
import Recommendation from '../../Recommendations/Recommendation';

class ServiceProvidersProjectDetails_v2 extends Component {
  state = {
    proposal: {},
    client_Org: this.props.project?.client_Organization?.organization_Id,
    errors: {},
    submitted: false,
    modify_Proposal: this.props.proposals[0].project_Status === "Created" || this.props.proposals[0].project_Status === "Pending" ? true : false,
    display_payment_form: false,
    loading: false,
    requested_sp: false,
    openModal: false,
    openDeleteModal: false,
    recommendationExists: false,
    service_Provider_Id: null,
    currentRecommendation: null
  }

  handle_input = (e) => {
    const { project_Hourly_Rate, project_Total_Hours } = this.state.proposal;
    let { project_Total_Cost } = this.state.proposal;

    let name = e.target.name;
    let value = e.target.value;

    if ((name === "project_Hourly_Rate" && project_Total_Hours)
      || (name === "project_Total_Hours" && project_Hourly_Rate)
    ) {
      if (name === "project_Hourly_Rate")
        project_Total_Cost = value * project_Total_Hours;
      else
        project_Total_Cost = project_Hourly_Rate * value;
    }

    this.setState({
      proposal: {
        ...this.state.proposal,
        [name]: value,
        project_Total_Cost
      }
    });
  }

  handle_submit = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();


    const {
      proposal_Title,
      proposal_Scope,
    } = this.state.proposal;

    let errors = {
      title_error: false,
      scope_error: false,
    };

    var form_is_valid = true;
    if (!proposal_Title) {
      errors.title_error = true;
      form_is_valid = false;
    }
    if (!proposal_Scope) {
      errors.scope_error = true;
      form_is_valid = false;
    }

    if (form_is_valid) {
      var user = JSON.parse(localStorage.getItem('user'));

      let proposal = Object.assign({}, this.state.proposal);
      proposal["service_Provider_User_ID"] = user.id;
      proposal["project_ID"] = this.props.project.project.project_Id;

      await serviceProviderService.createProposal(proposal);
      window.location.href = pluginService.redirect("/sp/projects/" + this.props.project.project.project_Id + "?val=proposals");
    }

    this.setState({
      submitted: true,
      errors,
      view: 'list_view'
    });
  }

  viewProposal = (proposal, client_Org) => {
    // alert(JSON.stringify(proposal))
    this.setState({
      proposal,
      client_Org: client_Org,
      modify_Proposal: proposal.project_Status === "Created" || proposal.project_Status === "Pending" ? true : false,
    });
  }

  open_chat = (organization_Id) => {
    let open_chat = this.state.open_chat;
    if (open_chat) {
      organization_Id = null;
    }

    this.setState({
      open_chat: !open_chat,
      organization_Id
    });
  }

  open_recommendationForm = (sp_Id) => {

    const { openModal } = this.state

    this.setState({
      openModal: !openModal,
      service_Provider_Id: sp_Id
    })
  }

  createRecommendation = async (recommendation) => {

    let returnedRecommendation = await recommendationService.createRecommendation(recommendation);

    //Add recommendation to end of SP.recommendation Array
    this.props.service_Provider.recommendations[this.props.service_Provider.recommendations.length] = returnedRecommendation;

    this.setState({
      recommendationExists: true,
      currentRecommendation: returnedRecommendation,
      openModal: !this.state.openModal
    });
  }

  updateRecommendation = async (recommendation) => {

    let returnedRecommendation = await recommendationService.updateRecommendation(recommendation);

    returnedRecommendation.client_Organization = this.props.project?.client_Organization;

    //index of recommendation to update in SP
    let previousRecommendation = this.props.service_Provider.recommendations.indexOf(this.props.service_Provider.recommendations.find(obj => {
      return obj.recommendation_Id === returnedRecommendation.recommendation_Id
    }))

    //update SP.recommendations
    this.props.service_Provider.recommendations[previousRecommendation] = returnedRecommendation;

    this.setState({
      currentRecommendation: returnedRecommendation,
      openModal: !this.state.openModal
    })
  }

  toggle_deleteRecommendationModal = (isOpen) => {

    this.setState({
      openDeleteModal: isOpen
    })
  }

  deleteRecommendation = () => {

    recommendationService.deleteRecommendation(this.state.currentRecommendation.recommendation_Id);

    //index of recommendation to remove in SP
    let deletedRecommendation = this.props.service_Provider.recommendations.indexOf(this.props.service_Provider.recommendations.find(obj => {
      return obj.recommendation_Id === this.state.currentRecommendation.recommendation_Id
    }))

    //removes recommendation from SP
    this.props.service_Provider.recommendations.splice(deletedRecommendation, 1);

    this.toggle_deleteRecommendationModal(false);

    this.setState({
      recommendationExists: false,
      currentRecommendation: null,
    })
  }

  updateProposalStatus = async (status) => {
    let proposal = Object.assign({}, this.props.proposals[0]);
    const user_id = this.props.user.id;
    proposal.project_Status = status;
    proposal.client_Decision_Maker_User_Id = user_id;
    await serviceProviderService.updateProposalStatus(proposal);

    if (status === "Rejected") {
      window.location.href = pluginService.redirect("/cl/projects");
    }
  }

  display_payment_form = (display) => {
    this.setState({
      display_payment_form: display
    })
  }

  cvtDateTimeString = (datetime) => {
    if (!datetime) {
      return "";
    }
    var datetime_split = datetime.split("T");

    var date_split = datetime_split[0].split("-");
    if (date_split[1][0] == "0") {
      date_split[1] = date_split[1].substr(1);
    }
    if (date_split[2][0] == "0") {
      date_split[2] = date_split[2].substr(1);
    }
    var date = date_split[1] + "/" + date_split[2] + "/" + date_split[0];

    return date;
  }

  recommendationExistsCheck = () => {
    if (!this.state.requested_sp) {

      const service_Provider = this.props.service_Provider

      if (service_Provider) {
        let recommendations = service_Provider.recommendations;

        if (recommendations) {
          recommendations.map(recommendation => {
            if (recommendation.client_Organization_Id === this.state.client_Org) {
              this.setState({
                recommendationExists: true,
                currentRecommendation: recommendation,
                requested_sp: true
              });
            }
          })
        }
      }

    }
  }

  componentDidUpdate() {
    this.recommendationExistsCheck();
  }

  componentDidMount() {
    this.recommendationExistsCheck();
  }


  render() {

    const {
      proposal,
      client_Org,
      organization_Id,
    } = this.state;

    const { proposals, project, type, service_Provider } = this.props;

    let recommendationPrint;

    return (
      <>

        <div>
          {proposals && proposals.length > 0 &&
            <div className="acceptedProjectContainer">
              {/* MOST RECENTLY CREATED PROPOSAL */}
              <div>
                <br />
                <div className="row viewProjectHeader">
                  <p className="acceptingProjectHeader">Client</p>
                </div>

                <div className="row paymentRow projectDetailsBottom" style={{ display: 'flex' }}>
                  <div className="col-md-5" style={{ display: 'flex' }}>
                    <div className="col-md-6 flexColEnd" style={{ marginTop: "10px" }}>
                      <img className="circlePicture" alt="organization logo" src={project.client_Organization?.logo_URL ? project.client_Organization?.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"} style={{ borderRadius: "50%" }} />
                    </div>
                    <div className="col-md-5 flexColStart" style={{ alignItems: "center", marginTop: "10px" }}>
                      <p className="removePadding">{project.client_Organization?.name || 'N/A'}</p>
                    </div>
                  </div>

                  {type === 'service_Provider' &&
                    <div className="col-md-6 flex-center flexColStart viewProjectNoP spBlueTextContainer" style={{ justifyContent: "space-evenly" }}>
                      <button
                        className="backToProjects"
                        onClick={() => this.open_chat(project.project.client_Organization_Id)}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px" }}
                      >
                        <img src={messageIcon} alt="Message Icon" width="30px" height='22px' style={{ marginRight: "10px" }} />
                        <p className='noMargin spPageBlueText'>Message</p>
                      </button>

                      <a className="backToProjects" href={pluginService.redirect("/organizations/" + project.project.client_Organization_Id)} target={pluginService.isIFrame() ? "_self" : "_blank"} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px", color: "#0565AA" }}>

                        <PeopleAltIcon style={{ marginRight: "8px", fontSize: "30px" }} />
                        <p className='noMargin spPageBlueText'>View Organization</p>
                      </a>
                    </div>
                  }
                </div>

                <EditSPProjectDetails_v2
                  type={type}
                  proposals={proposals}
                  proposal={proposal} //for updating project status
                  project={project}
                  service_Provider={service_Provider}
                />

                {/* Recommendation */}
                {(project.project.project_Status === "completed" || project.project.project_Status === "active") &&
                  <div className="row paymentRow">
                    <div className="col-md-3 flexColEnd">
                      Recommendation
                    </div>
                    <div className="col-md-8">
                      {this.state.recommendationExists ?
                        <Recommendation
                          recommendation={this.state.currentRecommendation}
                          location="project"
                          open_recommendationForm={this.open_recommendationForm}
                          toggle_deleteRecommendationModal={this.toggle_deleteRecommendationModal}
                          type={type}
                        />
                        :
                        <>
                          {window.location.pathname.includes("/sp/projects") ?
                            <p style={{ fontSize: 15, fontWeight: "normal" }}>This client has not left a recommendation for you yet.</p>
                            :
                            <div>
                              <p style={{ fontSize: 15, fontWeight: "normal" }}>You have not left a recommendation for the service provider assigned to this project yet.</p>
                              <button
                                className="backToProjects"
                                onClick={() => { this.open_recommendationForm(service_Provider.service_Provider_Id) }}
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2px 8px", marginTop: "10px", border: "2px solid lightgrey", borderRadius: "5px" }}
                              >
                                <GradeIcon style={{ marginRight: "3px", fontSize: "27px" }} />
                                <p className='noMargin spPageBlueText' style={{ lineHeight: "1.4", fontSize: 15, fontWeight: "normal" }}>Write a Recommendation</p>
                              </button>
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                }

                <div className="row viewProjectHeader">
                  <p className="acceptingProjectHeader">Service Provider</p>
                </div>
                <div className="row paymentRow projectDetailsBottom" style={{ display: 'flex' }}>
                  <div className='col-md-5' style={{ display: 'flex' }}>
                    <div className="col-md-6 flexColEnd" style={{ marginTop: "10px" }} >
                      <img className="circlePicture" alt="organization logo" src={service_Provider?.organization?.logo_URL ? service_Provider.organization.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"} style={{ borderRadius: "50%" }} />
                    </div>
                    <div className="col-md-6 flexColStart" style={{ alignItems: "center", marginTop: "10px" }}>
                      <p className="removePadding" style={{ lineHeight: "1.4" }}>{service_Provider?.organization?.name || 'N/A'}</p>
                    </div>
                  </div>

                  {type === "client" &&
                    <div className="col-md-7 flex-center flexColStart viewProjectNoP spBlueTextContainer" style={{ justifyContent: "space-evenly" }}>
                      <button
                        className="backToProjects"
                        onClick={() => this.open_chat(project.project.service_Provider_Id)}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 5px" }}
                      >
                        <img src={messageIcon} alt="Message Icon" width="30px" height='22px' style={{ marginRight: "10px" }} />
                        <p className='noMargin spPageBlueText' style={{ lineHeight: "1.4" }}>Message Provider</p>
                      </button>

                      <a className="backToProjects" href={pluginService.redirect("/service-provider/" + service_Provider.service_Provider_Id)} target={pluginService.isIFrame() ? "_self" : "_blank"} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 10px" }}>
                        <PersonIcon style={{ marginRight: "3px", fontSize: "33px" }} />
                        <p className='noMargin spPageBlueText' style={{ lineHeight: "1.4" }}>View Provider</p>
                      </a>
                    </div>
                  }
                </div>

                {type === "client" /*&& !project_accepted*/ && proposals[0].project_Status === "Created" &&
                  <div>
                    <ServiceProvidersAcceptOrRejectProject_v2
                      type={type}
                      organization_Id={this.props.organization_Id}
                      updateProposalStatus={this.updateProposalStatus}
                      additional_action={this.updateProposalStatus}
                      is_accepting_proposal={true}
                      display_payment_form={this.display_payment_form}
                      client_Org={client_Org}
                      showForm={true}
                      allowProposalStatusUpdate={this.allowProposalStatusUpdate}
                      service_Provider={service_Provider}
                      project={project}
                      proposal={proposal}
                    />
                  </div>
                }
              </div>
            </div>
          }
          {proposals.length === 0 &&
            <div>
              <hr />
              <br />
              <img
                alt=""
                src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/project_proposals_icon.png"
                className="icon-img"
              />
              <br />
              <h4>
                There are no proposals for this project.<br />Create a new one for your client to review to begin this project.
              </h4>
            </div>
          }
        </div>

        {/* pop up chat box */}
        {this.state.open_chat &&
          <Messenger_v2
            channel_type={type}
            hide_channels={true}
            organization_Id={organization_Id}
            open_chat={this.state.open_chat}
            close_chat={this.open_chat}
          />
        }

        {/* Modal for Recommendation */}
        {this.state.openModal &&
          <RecommendationModal
            openModal={this.state.openModal}
            open_recommendationForm={this.open_recommendationForm}
            service_Provider_Id={this.state.service_Provider_Id}
            recommendationExists={this.state.recommendationExists}
            createRecommendation={this.createRecommendation}
            updateRecommendation={this.updateRecommendation}
            currentRecommendation={this.state.currentRecommendation}
          />
        }

        {/* Modal for Recommendation */}
        {this.state.openDeleteModal &&
          <DeleteRecommendationModal
            modalOpen={this.state.openDeleteModal}
            toggle_deleteRecommendationModal={this.toggle_deleteRecommendationModal}
            deleteRecommendation={this.deleteRecommendation}
          />
        }
      </ >
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { billingInfo } = state;

  return ({
    user,
    billingInfo
  })
}

const connectedServiceProvidersProjectDetails_v2 = connect(mapStateToProps)(ServiceProvidersProjectDetails_v2);
export { connectedServiceProvidersProjectDetails_v2 as ServiceProvidersProjectDetails_v2 };
