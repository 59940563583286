import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/ServiceProvider.css';
import { Messenger_v2 } from '../Marketplace';

class Messages extends React.Component {
    state = {
        channel_type: "all"
    }

    componentDidMount = async () => {
        const channel_type = this.props.service_Provider ? "service_Provider" : "client";

        this.setState({
            channel_type
        });
    }

    render() {

        return (
            <div class="row" id="messagesDiv">
                <h1 id="yourMessages">Your Messages</h1>
                <Messenger_v2
                    channel_type={this.state.channel_type}
                    message_Group="group"
                    displayHelperText={true}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { channel } = state.chatalert_setchannel;
    const { conversation } = state.chatalert_setconversation;
    const { service_Provider } = state.serviceprovider_getserviceprovider;
    const { stripe_Verified } = state.stripeVerified;
    const sp_is_loading = state.serviceprovider_getserviceprovider.loading;
    const sv_is_loading = state.stripeVerified.loading;

    return {
        channel, conversation,
        service_Provider, stripe_Verified,
        sp_is_loading, sv_is_loading
    };
}

const connectedMessages = connect(mapStateToProps)(Messages);
export { connectedMessages as Messages };
