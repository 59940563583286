import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { connect } from 'react-redux';
import { pluginService } from '../../../_services/shared.service.js';

let accentColor = pluginService.getIFrameColor()

class AcceptedProfileList_v2 extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showActions: false
        }
    }

    render() {
        const { user, profile, transfer, org } = this.props;

        return (
            <div key={profile.email} className={`dFlex spaceBtw flexWrap orgPageUserContainer ${profile.hasOwnProperty("owner") ? "memberListForm" : "member"}`} >
                <div className="dFlex">
                    {!profile.headshot ?
                        <div className="pfpCircle centerFlex" id="noPfpCircle" style={{ backgroundColor: accentColor || '#0C7069' }}>{profile.surname[0]} </div>
                        :
                        <img src={profile.headshot} className="pfpCircle" alt="profile circle" />
                    }
                    <a href={pluginService.redirect("/profiles/" + profile.username)} target={pluginService.isIFrame() ? "_self" : "_blank"} rel="noopener noreferrer" className="centerFlex">
                        <div className="memberText ">
                            <h4 className="boxSubtitle noMargin orgPageUserName">{profile.name} {profile.surname} {profile.owner ? "(owner)" : ""} </h4>
                            <p className="boxText noMargin orgPageUserTitle">{profile.title}</p>
                        </div>
                    </a>
                </div>

                {!transfer && !profile.owner && profile.hasOwnProperty("owner") &&
                    <>

                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <MoreHorizIcon fontSize="large" onClick={() => { this.setState({ showActions: !this.state.showActions }) }} />
                        </div>
                        {this.state.showActions &&
                            <div className="dFlex memberActionButtons">
                                <button
                                    className="defaultGreenButton"
                                    onClick={() => { this.props.updateOrganizationOwner(user.id, profile.username, org.organization.organization_Id) }}
                                    style={{ marginRight: 10, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                >
                                    Transfer Ownership
                                </button>
                                <button
                                    className="defaultWhiteButton"
                                    onClick={() => { this.props.confirmOrganizationRequest(org.organization.organization_Id, user.id, profile.username, "Rejected") }}
                                    style={{ marginLeft: 10, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                >
                                    Remove
                                </button>
                            </div>
                        }
                    </>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedAcceptedProfileList = connect(mapStateToProps)(AcceptedProfileList_v2);
export { connectedAcceptedProfileList as AcceptedProfileList_v2 };
