export const alertConstants = {
    LOGIN_SUCCESS: 'LOGIN_ALERT_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ALERT_ERROR',
    LOGIN_CLEAR: 'LOGIN_ALERT_CLEAR',

    UPDATEPASSWORD_SUCCESS: 'UPDATEPASSWORD_ALERT_SUCCESS',
    UPDATEPASSWORD_ERROR: 'UPDATEPASSWORD_ALERT_ERROR',
    UPDATEPASSWORD_CLEAR: 'UPDATEPASSWORD_ALERT_CLEAR',

    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_ALERT_SUCCESS',
    SUBSCRIBE_ERROR: 'SUBSCRIBE_ALERT_ERROR',
    SUBSCRIBE_CLEAR: 'SUBSCRIBE_ALERT_CLEAR',

    REGISTRATION_SUCCESS: 'REGISTRATION_ALERT_SUCCESS',
    REGISTRATION_ERROR: 'REGISTRATION_ALERT_ERROR',

    SETTINGS_SUCCESS: 'SETTINGS_ALERT_SUCCESS',
    SETTINGS_ERROR: 'SETTINGS_ALERT_ERROR',
    SETTINGS_CLEAR: 'SETTINGS_ALERT_CLEAR',
};
