import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { organizationService } from '../../../../_services';
import { Registration_AutoSuggestion } from "../../../Account/RegisterPage/Registration_AutoSuggestion";
import { Onboarding_Technologies } from "../Onboarding_Technologies";

class GC_Onboarding_AdvancedSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new_Organization: this.props.new_Organization,
      industries: [],
      categoryInputs: {
        naicS_CodesInput: "",
        industriesInput: "",
        technologies_UsedInput: "",
        social_Impact_CategoriesInput: "",
      },
      invalidTags: false,
    }
  }

  componentDidMount() {
    this.getNAICSCodes();
    this.getIndustries();
    this.getTechnologies();
    this.getSocialImpactCategories();
  }

  getNAICSCodes = async () => {
    const { user } = this.props;
    var naicS_Codes = await organizationService.getNAICSCodes(user.id);

    this.setState({
      naicS_Codes
    });
  }

  getIndustries = async () => {
    const { user } = this.props;
    var industries = await organizationService.getIndustries(user.id);

    this.setState({
      industries
    });
  }

  getTechnologies = async () => {
    const { user } = this.props;
    var technologies_Used = await organizationService.getTechnologies(user.id);

    this.setState({
      technologies_Used
    });
  }

  getSocialImpactCategories = async () => {
    const { user } = this.props;
    var social_Impact_Categories = await organizationService.getSocialImpactCategories(user.id);

    this.setState({
      social_Impact_Categories
    });
  }


  render() {
    const { updateNav, submitted, handleChange, handleCategorySelection, new_Organization } = this.props;

    const { naicS_Codes, industries, social_Impact_Categories, categoryInputs, invalidTags } = this.state;

    let naicS_Codes_Cat = { "naicS_Codes": naicS_Codes };
    let industries_Cat = { "industries": industries };
    let social_Impact_Categories_Cat = { "social_Impact_Categories": social_Impact_Categories };

    const handleTagInput = (categoryInputs) => {

      let key = Object.keys(categoryInputs)[0];
      let value = Object.values(categoryInputs)[0];

      this.setState({
        categoryInputs: {
          ...this.state.categoryInputs,
          [key]: value
        }
      })
    }

    const nextStep = () => {

      if ((categoryInputs.naicS_CodesInput || categoryInputs.industriesInput || categoryInputs.technologies_UsedInput || categoryInputs.social_Impact_CategoriesInput) === "") {
        updateNav("onboard_members")
      }
      else {
        this.setState({
          invalidTags: true,
        })
      }
    }

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              7 of 8
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10" >
            <div className="col-md-9 progress-color" />
            <div className="col-md-3 gray-progress-color" />
          </div>
        </div>
        <form onSubmit={e => { e.preventDefault() }} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
          <div className="gc_onboarding-form-card">
            <h1 className="onboarding-form-header" >Advanced Settings</h1>
            <p>These details are optional, but will help us better understand your organization.</p>
            <hr />

            <div className="row">

              {/* LEFT COL */}
              <div className="col-md-6 gc_onboarding-form-col-left">

                {/* 8A */}
                <div>
                  <p className="gc_onboarding-form-input-name-top">
                    Are You an 8A Business Development?
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    The 8(a) Business Development (BD) Program offers a broad scope of assistance to firms that are owned and controlled at least 51% by socially and economically disadvantaged individual(s). See if you’re eligible and apply for certification
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="businiess_8a_Development"
                      id="organization-standard-basic"
                      value={new_Organization.organization.businiess_8a_Development}
                      onChange={handleChange}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                        maxLength: 450,
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                {/* WOMEN OWNED */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Are You a Women Owned Business?
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    The Women-Owned Small Business (WOSB) Federal Contract Program allows set-asides for WOSBs in industries where firms are underrepresented. WOSBs must be at least 51% owned and controlled by women. Apply for certification
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="woman_Owned"
                      id="organization-standard-basic"
                      value={new_Organization.organization.woman_Owned}
                      onChange={handleChange}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                        maxLength: 450,
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                {/* VETERAN OWNED */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Are You a Certified Veteran Owned Business?
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    If you have a Veteran-Owned Small Business, you may qualify for advantages when bidding on government contracts—along with access to other resources and support—through the Vets First Verification Program. Learn more and apply for certification
                    <a href="https://www.va.gov/careers-employment/veteran-owned-business-support/" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="veteran_Owned"
                      id="organization-standard-basic"
                      value={new_Organization.organization.veteran_Owned}
                      onChange={handleChange}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                        maxLength: 450,
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                {/* HUBZONE */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Are You Located in a Hubzone?
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    The Historically Underutilized Business Zone (HUBzone) Program allows federal contract set-asides for small businesses in economically depressed communities. Learn more
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="hubZone_Owned"
                      id="organization-standard-basic"
                      value={new_Organization.organization.hubZone_Owned}
                      onChange={handleChange}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                        maxLength: 450,
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                {/* SMALL BUSINESS */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Are You a Small Business?
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    Not sure? Read the SBA’s standards for small business
                    <a href="https://www.sba.gov/federal-contracting/contracting-guide/size-standards" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="small_Business"
                      id="organization-standard-basic"
                      value={new_Organization.organization.small_Business}
                      onChange={handleChange}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                        maxLength: 450,
                      }}
                    >
                      <option value="Select">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* RIGHT COL */}
              <div className="col-md-6 gc_onboarding-form-col-right">

                {/* UEI or DUNS */}
                <div><p className="gc_onboarding-form-input-name-top mobile-margin">
                  Unique Entity Identifier (previously known as DUNS number)
                </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    On April 4, 2022, the unique entity identifier used across the federal government changed from the DUNS Number to the Unique Entity ID.
                    This is a 12-character alphanumeric ID assigned by <a href="https://sam.gov/content/home" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">SAM.gov</a>.
                    The DUNS Number has been retired.
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    Existing registered entities can find their Unique Entity ID by following the steps here.
                    New entities can get their Unique Entity ID at <a href="https://sam.gov/content/home" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">SAM.gov</a>.
                  </p>
                  <div>
                    <input
                      className="gc_onboarding-form-input"
                      name="dunS_Number"
                      id="organization-standard-basic"
                      value={new_Organization.organization.dunS_Number}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* NAICS CODES */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    NAICS Code(s)
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    A NAICS (pronounced NAKES) Code is a classification within the North American Industry Classification System. The NAICS System was developed for use by Federal Statistical Agencies for the collection, analysis and publication of statistical data related to the US Economy. Find your NAICS code(s)
                    <a href="https://www.naics.com/search/" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                  </p>
                  <div>
                    <Registration_AutoSuggestion
                      categories={naicS_Codes_Cat}
                      categoryName="naicS_Codes"
                      currentSelectedCategories={new_Organization.naicS_Codes}
                      multiple={true}
                      addTags={true}
                      submitted={submitted}
                      //errorInput={category_TagsError}
                      handleCategorySelection={handleCategorySelection}
                      handleCategoryInput={handleTagInput}
                      inputProps={{ maxLength: 50 }}
                    />
                    {invalidTags && categoryInputs.naicS_CodesInput !== "" && (
                      <p style={{ color: "red" }}>
                        The NAICS Code entered is not in the list.
                      </p>
                    )}
                  </div>
                </div>

                {/* INDUSTRY */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Industry
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    Please select the industries that best fit your organization. This information helps OpenGrants match you to new grant funding opportunities as they become available.
                  </p>
                  <div>
                    <Registration_AutoSuggestion
                      categories={industries_Cat}
                      categoryName="industries"
                      currentSelectedCategories={new_Organization.industries}
                      multiple={true}
                      addTags={true}
                      submitted={submitted}
                      //errorInput={category_TagsError}
                      handleCategorySelection={handleCategorySelection}
                      handleCategoryInput={handleTagInput}
                    />
                    {invalidTags && categoryInputs.industriesInput !== "" && (
                      <p style={{ color: "red" }}>
                        The industry entered is not in the list.
                      </p>
                    )}
                  </div>
                </div>

                {/* TECHNOLOGIES */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Technologies
                  </p>
                  <div>
                    <Onboarding_Technologies
                      org={new_Organization}
                      handleCreatableSelection={this.props.handleCreatableSelection}
                      handleCreatableRemoval={this.props.handleCreatableRemoval}
                    />
                  </div>
                </div>

                {/* SOCIAL IMPACT */}
                <div>
                  <p className="gc_onboarding-form-input-name">
                    Social Impact Areas
                  </p>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    Please select the social impact areas that best fit your organization. This information helps OpenGrants match you to new grant funding opportunities as they become available.
                  </p>
                  <div>
                    <Registration_AutoSuggestion
                      categories={social_Impact_Categories_Cat}
                      categoryName="social_Impact_Categories"
                      currentSelectedCategories={new_Organization.social_Impact_Categories}
                      multiple={true}
                      addTags={true}
                      submitted={submitted}
                      // errorInput={category_TagsError}
                      handleCategorySelection={handleCategorySelection}
                      handleCategoryInput={handleTagInput}
                    />
                    {invalidTags && categoryInputs.social_Impact_CategoriesInput !== "" && (
                      <p style={{ color: "red" }}>
                        The social impact areas entered are not in the list.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <hr />
            <div className="row mobileBtnRow">
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-back"
                  onClick={() => updateNav("onboard_contactTerms")}
                >
                  Back <i class="icon-arrow-left" />
                </button>
              </div>
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-next"
                  onClick={() => nextStep()}
                >
                  Next <i class="icon-arrow-right" />
                </button>
              </div>
            </div>
            <div className="row center">
              <button
                className="gc_started-button-fillLater"
                onClick={() => updateNav("onboard_members")}
              >
                Fill Out Later
              </button>
              <br />
            </div>
            {invalidTags && (
              <p style={{ marginTop: 30, color: "red" }}>
                There are errors in your form. Please correct all errors shown in
                red above, then click Next again.
              </p>
            )}
          </div>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { setUser, organization } = state;
  const { user } = setUser;

  return {
    user,
    organization,
  };
}

const connectedGC_Onboarding_AdvancedSettings =
  connect(mapStateToProps)(GC_Onboarding_AdvancedSettings);
export { connectedGC_Onboarding_AdvancedSettings as GC_Onboarding_AdvancedSettings };

