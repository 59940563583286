import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { pluginService } from '../../../_services/shared.service.js';

class OrganizationExistsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { user, organization_namesInput, total_organizations, org } = this.props;

        var organizationClass = "";

        if (org.organization.organization_Id) {
            organizationClass = "linkListing";
        }

        if (org && org.organization && org.organization.name && org.organization.name.includes(organization_namesInput)) {
            return (
                <div key={org.organization.organization_Id} id="existingOrgDiv">
                    <a
                        className={organizationClass}
                        style={{ display: "inline-block", padding: "20px", width: "300px", color: "black", verticalAlign: "top" }}
                        href={pluginService.redirect(org.organization.organization_Id && org.organization.organization_Id != 0 ? "/organizations/" + org.organization.organization_Id : "/organizations")}
                        target={pluginService.isIFrame() ? "_self" : "_blank"}
                    >
                        <div>
                            {org.organization.logo_URL &&
                                <img src={org.organization.logo_URL} alt="organization logo" style={{ height: "60px", width: "60px", borderRadius: "50%", verticalAlign: "bottom", marginBottom: 10 }} />
                            }
                            {!org.organization.logo_URL &&
                                <i className="material-icons" style={{ float: "none", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%", marginBottom: 10 }}>
                                    business
                                </i>
                            }
                            <div>
                                <p><strong>{org.organization.name}</strong></p>
                            </div>
                        </div>
                    </a>
                    {total_organizations.length > 0 &&
                        (!org.users.includes(user.id)) &&
                        (!org.pending.includes(user.id)) &&
                        (!org.invitations.includes(user.email)) &&
                        <Button
                            color="info"
                            style={{ margin: 20, background: "#29a" }}
                            onClick={() => { this.props.newOrganizationFunc(org.organization.organization_Id) }}
                        >
                            Request To Join
                        </Button>}
                    {total_organizations.length > 0 &&
                        org.users.includes(user.id) &&
                        (!org.pending.includes(user.id)) &&
                        (!org.invitations.includes(user.email)) &&
                        <Button
                            style={{ margin: 20, background: "#29a" }}
                        >
                            {org.organization.owner == user.id ? "Owner" : "Member"}
                        </Button>
                    }
                    {total_organizations.length > 0 &&
                        (!org.users.includes(user.id)) &&
                        org.pending.includes(user.id) &&
                        (!org.invitations.includes(user.email)) &&
                        <Button
                            style={{ margin: 20, background: "#29a" }}
                        >
                            Pending Request
                        </Button>}
                    {total_organizations.length > 0 &&
                        (!org.users.includes(user.id)) &&
                        (!org.pending.includes(user.id)) &&
                        org.invitations.includes(user.email) &&
                        <Button
                            color="info"
                            style={{ margin: 20, background: "#29a" }}
                        >
                            <a href={pluginService.redirect("/organization_invitations")} target={pluginService.isIFrame() ? "_self" : "_blank"} style={{ color: "white" }}>
                                Accept Pending Invitation
                            </a>
                        </Button>
                    }
                </div>
            )
        }
        return null;
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedExistsList = connect(mapStateToProps)(OrganizationExistsList);
export { connectedExistsList as OrganizationExistsList };
