import { upgradeConstants } from "../_constants";

export const upgradeModal = (state = false, action) => {
    switch (action.type) {
        case upgradeConstants.MODAL_OPEN:
            return {
                isOpen: action.status,
            };
        case upgradeConstants.MODAL_CLOSE:
            return {
                isOpen: action.status,
            };
        default:
            return state;
    }
};