import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import '../../../Styles/SettingsStyles/Profile.css';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { organizationService, pluginService } from '../../../_services';

class OrganizationRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        var results = await organizationService.getAllOrganizationRequests(user.id);

        this.setState({
            ...results
        });
    }

    confirmOrganizationRequest = async (organization_id, user_id, username, confirmation) => {
        let profile = await organizationService.confirmOrganizationRequest(organization_id, user_id, username, confirmation);

        if (profile) {
            const { requests, totalRequests } = this.state;
            var all_requests = requests.filter(request => request.organization.id !== organization_id);

            var new_org_requests = requests.filter(request => request.organization.id === organization_id);
            var new_profiles = [];
            if (new_org_requests.length > 0) {
                new_profiles = new_org_requests[0].profiles.filter(profile => profile.username !== username);
            }
            new_profiles.push(profile);

            var request = {
                organization: new_org_requests[0].organization,
                profiles: [...new_profiles]
            };
            all_requests.push(request);

            this.setState({
                requests: [...all_requests],
                totalRequests: totalRequests - 1
            });
        }
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const { requests, totalRequests } = this.state;
        var request_list = null;
        if (requests) {
            request_list = requests.map((request) => {
                var profile_list = request.profiles.map((profile) => {
                    return (
                        <div key={profile.email} style={{ marginBottom: 20, marginLeft: 30, marginRight: 30, overflow: "auto" }}>
                            {!profile.headshot && <div className="profile-circle" style={{ position: "relative", height: "60px", width: "60px", lineHeight: "60px", fontSize: "30px", marginRight: 30, verticalAlign: "top" }}>{profile.surname[0]}</div>}
                            {profile.headshot &&
                                <img src={profile.headshot} alt="profile circle" style={{ height: 60, width: 60, marginRight: 30, borderRadius: "50%", verticalAlign: "top" }} />
                            }
                            <a href={pluginService.redirect("/profiles/" + profile.username)} target={pluginService.isIFrame() ? "_self" : "_blank"} className="linkListing" style={{ display: "inline-block", color: "black" }}>
                                <h3 style={{ margin: 0 }}>{profile.name} {profile.surname}</h3>
                                <p>{profile.email}</p>
                            </a>
                            {!profile.confirmation && <div style={{ float: "right" }}>
                                <Button color="info" style={{ marginRight: 20 }} onClick={() => { this.confirmOrganizationRequest(request.organization.id, user.id, profile.username, "Accepted") }}>Accept</Button>
                                <Button onClick={() => { this.confirmOrganizationRequest(request.organization.id, user.id, profile.username, "Rejected") }}>Reject</Button>
                            </div>}
                            {profile.confirmation && <div style={{ float: "right" }}>
                                <Button>{profile.confirmation}</Button>
                            </div>}
                        </div>
                    )
                });

                return (
                    <div key={request.organization.name}>
                        <div style={{ marginBottom: 20, marginTop: 40 }}>
                            <a href={pluginService.redirect("/organizations/" + request.organization.id)} target={pluginService.isIFrame() ? "_self" : "_blank"} className="linkListing" style={{ color: "black" }}>
                                {request.organization.logo &&
                                    <img src={request.organization.logo} alt="organization logo" style={{ height: "60px", width: "60px", borderRadius: "50%", margin: "15px" }} />
                                }
                                {!request.organization.logo &&
                                    <i className="material-icons" style={{ float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%" }}>
                                        business
                                    </i>
                                }
                                <h2>{request.organization.name}</h2>
                            </a>
                        </div>
                        <hr style={{ borderColor: "#888", marginTop: 0 }} />
                        <div>
                            {profile_list}
                        </div>
                    </div>
                );
            });
        }

        return (
            <div>
                <h1 style={{ textAlign: "center" }}>Confirm Organization Requests</h1>
                <p style={{ textAlign: "center" }}>The following user(s) requested to join your organization(s). Accepting the request will let them connect their personal profile to your organization. They will also be able to edit your organization. As the owner, you are the only one who can delete your organization.</p>
                <p style={{ marginTop: 40 }}><strong>{totalRequests ? totalRequests : 0}</strong> Pending Request(s)</p>
                {request_list}
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedOrganizationRequest = connect(mapStateToProps)(OrganizationRequest);
export { connectedOrganizationRequest as OrganizationRequest };

