import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const marketplaceService = {
    getSPOnboardingLists,
    getAllServiceProviders,
    getAllProposals,
    getServiceProviderProposals,
    updateToS,
};

function getSPOnboardingLists() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/marketplace/get_service_provider_onboarding_lists', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllServiceProviders() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/marketplace/get_all_service_providers', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllProposals(user_id, organization_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/marketplace/get_all_proposals?user_id=' + user_id + 'organization_Id=' + organization_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProviderProposals(user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/marketplace/get_service_provider_proposals?service_provider_user_id=' + user_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

async function updateToS(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };
    return fetch(API_Route + '/api/marketplace/accept_marketplace_terms_service', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}