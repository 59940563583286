import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';

class EditOrganizationImpact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getSocialImpactCategories();
    }

    getSocialImpactCategories = async () => {
        const { user } = this.props;
        var social_Impact_Categories = await organizationService.getSocialImpactCategories(user.id);

        this.setState({
            social_Impact_Categories
        });
    }

    render() {
        const { social_Impact_Categories } = this.state;
        const { org } = this.props;

        let social_Impact_Categories_Cat = { "social_Impact_Categories": social_Impact_Categories };

        return (
            <>
                <p className="inputFieldSubLabel">
                    Please select the social impact areas that best fit your organization. This information helps OpenGrants match you to new grant funding opportunities as they become available.
                </p>
                <Registration_AutoSuggestion
                    categories={social_Impact_Categories_Cat}
                    categoryName="social_Impact_Categories"
                    currentSelectedCategories={org.social_Impact_Categories}
                    // submitted={submitted}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationImpact = connect(mapStateToProps)(EditOrganizationImpact);
export { connectedEditOrganizationImpact as EditOrganizationImpact };
