import React from 'react'
import bannerImage from "../../_assets/img/gf-payout-logo.png"
import { sharedService, pluginService } from '../../_services';

export default function VendorBanner(props) {

    let accentColor = pluginService.getIFrameColor()

    return (
        <div className="notificationCard" style={{ marginBottom: 50, position: "relative" }} >
            <div className="notificationCardLeft" style={{ padding: 0, display: "inline" }} >
                <img className="notificationImg" src={bannerImage} alt="" />
            </div>
            <div className="notificationCardRight">
                <div>
                    <p className="p-header">Does your organization or government require W-9 vendor setup?</p>
                </div>
                <button className="notificationBtn" style={{ marginRight: 50, background: accentColor || '#0C7069' }} onClick={() => { sharedService.getVendorDoc() }}>
                    Download <span className='vendorButtonText' style={{ fontWeight: "bold" }}> Our Vendor Documents</span>
                </button>
                <div
                    style={{ position: "absolute", top: 10, right: 10, cursor: "pointer", fontSize: 21, fontWeight: "bold", width: 20, height: 20, display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={() => { props.closeBanner() }}
                >
                    ×
                </div>
            </div>
        </div>
    )
}
