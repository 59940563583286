export const perksConstants = {
    GET_ALL_PERKS_SUCCESS: 'GET_ALL_PERKS_SUCCESS',
    GET_ALL_PERKS_ERROR: 'GET_ALL_PERKS_ERROR',
    GET_ALL_PERKS_REQUEST: 'GET_ALL_PERKS_REQUEST',

    ADD_PERKS_SUCCESS: 'ADD_PERKS_SUCCESS',
    ADD_PERKS_ERROR: 'ADD_PERKS_ERROR',
    ADD_PERKS_REQUEST: 'ADD_PERKS_REQUEST',

    EDIT_PERKS_SUCCESS: 'EDIT_PERKS_SUCCESS',
    EDIT_PERKS_ERROR: 'EDIT_PERKS_ERROR',
    EDIT_PERKS_REQUEST: 'EDIT_PERKS_REQUEST',

    DELETE_PERKS_SUCCESS: 'DELETE_PERKS_SUCCESS',
    DELETE_PERKS_ERROR: 'DELETE_PERKS_ERROR',
    DELETE_PERKS_REQUEST: 'DELETE_PERKS_REQUEST',
};
