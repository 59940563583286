import React from 'react';
import { Timer } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openFullWidget, openMinimizedWidget } from "../../../_actions";
import { history } from '../../../_helpers';
import { sharedService, stripeService } from "../../../_services";

const StartTrackingTimeButton = () => {
  const dispatch = useDispatch();

  const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);

  const [stripe_Verified, setStripe_Verified] = useState("");

  useEffect(() => {

    if (service_Provider) {

      const setVerified = async () => {
        const stripe_Verified = await stripeService.checkStripeConnectVerification(service_Provider.stripe_Account_Id);
        setStripe_Verified(stripe_Verified);
      }

      setVerified();
    }

  }, [service_Provider])

  // Open full widget (minimized and full)
  const openWidget = () => {
    dispatch(openMinimizedWidget());
    dispatch(openFullWidget());
  };

  return (
    <button
      className="addMoreHoursBtn startTrackingTimeButton"
      onClick={sharedService.isSPVerified(service_Provider, stripe_Verified) ? () => openWidget() : () => { history.push("/service-provider-success") }}
    >
      <Timer style={{ marginRight: 10, fontSize: 22 }} />
      Start Tracking Time
    </button>
  );
};

export default StartTrackingTimeButton;