import React from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingBase from '../../Components/OnboardingBase'
import { Loader_v2 } from '../../../Shared/Loader/Loader_v2';
import { Form, FormSelect } from 'react-bootstrap';
import usStates from '../../../../utils/usStates';

const Onboarding_Description = ({ nextStep, prevStep, loading, organization, updateOrganization }) => {
  const history = useHistory();

  const chatQuoteOptions = [
    'Let start with some details on your work. The more details the better! Try to include things like location, industry, technology, and impact.',
    'Let start with some details on your startup / project?'
  ]

  const chatQuoteOptions2 = [
    'The more details the better! Try to include things like location, industry, technology, and impact.',
    ''
  ]

  return (
    <OnboardingBase
      guyPosition={'guy-position-2'}
      girlPosition={'girl-position-2'}
      chatQuoteOptions={chatQuoteOptions}
      chatQuoteOptions2={chatQuoteOptions2}>
      {loading && <div className='px-168'><Loader_v2 /></div>}
      {!loading && <Form onSubmit={(e) => { e.preventDefault(); nextStep(); }} className='text-start'>
        <div>
          <label className='fs-3 d-block'>Organization Name <span className='text-danger'>*</span></label>
          <input className='w-100 fs-3 border-1 rounded-10' name='name' value={organization.name} onChange={updateOrganization} />
        </div>
        <div>
          <label className='fs-3 d-block'>Description <span className='text-danger'>*</span></label>
          <textarea
            className='mx-auto org-description text-start fs-3 rounded-10'
            name='description'
            value={organization.description}
            onChange={updateOrganization}
            placeholder="Example: I'm working on a partnership project in Ohio that includes nonprofit and for-profit organizations. We're helping up-skill and re-skill underserved groups to help them earn lasting, meaningful jobs that will result in better economic health for the region. We're working with the city of Akron and are looking for federal, state, and private grants to fund our work..."
          ></textarea>
        </div>
        <div>
          <label className='fs-3 d-block'>State</label>
          <FormSelect
            className='fs-3 mb-48'
            onChange={updateOrganization}
            name='state'
            value={organization.state}
          >
            <option name='state' value=''>None</option>
            {usStates.map(state => <option name='state' value={state[0]}>{state[0]}</option>)}
          </FormSelect>
        </div>
        <button
          type="submit"
          disabled={!organization.name || !organization.description}
          className={"mx-auto fw-bold bg-primary-dark text-white rounded-10 py-16 px-64 mb-32 fs-4 d-block" + ((!organization.name || !organization.description) ? " button-disabled" : "")}
        >
          Find Matched Grants
        </button>
        <div className='text-center'>
          <a className="text-decoration-underline fw-bold text-secondary fs-4"
            onClick={() => history.replace('/dashboard')}>
            I know this is an important step, but I want to skip this for now!
          </a>
        </div>
      </Form>}
    </OnboardingBase>
  )
}

export default Onboarding_Description