import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { pluginService } from "../../../../_services";

let accentColor = pluginService.getIFrameColor()

class EditRoles extends React.Component {

    componentDidMount() {
        this.checkIfChecked()
    }

    checkIfChecked = () => {
        if (this.props.service_Provider.sP_Roles) {
            let roles = this.props.service_Provider.sP_Roles
            roles.map((role) => {
                this.props.handleDefaultCheckbox(role.role_Type, "roles")
            })
        }
    }

    render() {
        const { checkboxes, handleCheckbox } = this.props;

        return (
            <FormGroup className='orgPageRoles'>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.grant_Writer}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="grant_Writer"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Grant Writer</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.startup_Advisor}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="startup_Advisor"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Startup Advisor</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.technical_Consultant}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="technical_Consultant"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Technical Consultant</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.accounting_Professional}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="accounting_Professional"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Accounting Professional</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.attorney}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="attorney"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Attorney</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.generalist}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="generalist"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Generalist</Typography>}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.roles.non_Profit_Advisor}
                            onChange={(e) => handleCheckbox(e, "roles")}
                            name="service_Provider_Roles"
                            id="non_Profit_Advisor"
                            style={{ color: accentColor || '#0C7069' }}
                        />
                    }
                    label={<Typography>Non-Profit Advisor</Typography>}
                />
            </FormGroup>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return { user };
}

const connectedEditRoles = connect(mapStateToProps)(EditRoles);
export { connectedEditRoles as EditRoles };

