import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const marketplaceProjectService = {
    ///// projects /////
    get_all_projects,
    get_project,
    create_project,
    update_project,
    update_project_status,
    upload_mp_documents,
    delete_mp_documents,
    download_mp_document,

    ///// applications /////
    get_all_applications,
    get_application,
    create_application,
    update_application,
    update_application_status,
    upload_mp_application_documents,
    delete_mp_application_documents,
    download_mp_application_document,

    ///// segments /////
    create_work_segment,
    update_work_segment,
    delete_work_segment,
    submit_time_entry
};

//expand = list with possible values -> applications, service provider, user, client organization, documents, segments
function get_all_projects(user_id, expand) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(expand)
    };

    let route = '/api/marketplaceproject/get_all_projects';

    //include optional user_id parameter
    if (user_id) {
        route += '/' + user_id;
    }

    return fetch(API_Route + route, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//expand = list with possible values -> applications, service provider, user, client organization, documents, segments
function get_project(project_id, expand) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(expand)
    };

    return fetch(API_Route + '/api/marketplaceproject/get_project/' + project_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//project = marketplaceProjectObjects.mp_project
function create_project(project) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/marketplaceproject/create_project', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//project = marketplaceProjectObjects.mp_project
function update_project(project) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/marketplaceproject/update_project', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function update_project_status(project) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/marketplaceproject/update_project_status', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//documents = list of marketplaceProjectObjects.mp_project_document
function upload_mp_documents(documents) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(documents)
    };

    return fetch(API_Route + '/api/marketplaceproject/upload_mp_documents', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//documents = list of marketplaceProjectObjects.mp_project_document
function delete_mp_documents(documents) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(documents)
    };

    return fetch(API_Route + '/api/marketplaceproject/delete_mp_documents', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//document = marketplaceProjectObjects.mp_project_document
function download_mp_document(documents) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(documents)
    };

    return fetch(API_Route + '/api/marketplaceproject/download_mp_document', requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function get_all_applications() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/marketplaceproject/get_all_applications', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//expand = does nothing at the moment
function get_application(application_id, expand) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(expand)
    };

    return fetch(API_Route + '/api/marketplaceproject/get_application/' + application_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcation = marketplaceProjectObjects.mp_project_application
function create_application(application) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(application)
    };

    return fetch(API_Route + '/api/marketplaceproject/create_application', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcation = marketplaceProjectObjects.mp_project_application
function update_application(application) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(application)
    };

    return fetch(API_Route + '/api/marketplaceproject/update_application', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcation = marketplaceProjectObjects.mp_project_application
function update_application_status(application) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(application)
    };

    return fetch(API_Route + '/api/marketplaceproject/update_application_status', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcations = list of marketplaceProjectObjects.mp_project_application_document 
function upload_mp_application_documents(applications) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(applications)
    };

    return fetch(API_Route + '/api/marketplaceproject/upload_mp_application_documents', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcations = list of marketplaceProjectObjects.mp_project_application_document 
function delete_mp_application_documents(applications) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(applications)
    };

    return fetch(API_Route + '/api/marketplaceproject/delete_mp_application_documents', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//applcation = marketplaceProjectObjects.mp_project_application_document 
function download_mp_application_document(application) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(application)
    };

    return fetch(API_Route + '/api/marketplaceproject/download_mp_application_document', requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

//segment = marketplaceProjectObjects.mp_project_segment
function create_work_segment(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/marketplaceproject/create_work_segment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//segment = marketplaceProjectObjects.mp_project_segment
function update_work_segment(segment) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/marketplaceproject/update_work_segment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//segment = marketplaceProjectObjects.mp_project_segment
function delete_work_segment(segment) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/marketplaceproject/delete_work_segment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

//segment = marketplaceProjectObjects.mp_project_segment
function submit_time_entry(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/marketplaceproject/submit_time_entry', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}