import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Login.css";
import "../../../Styles/Marketplace.css";
import { authenticateActions, serviceProviderActions, settingsActions } from '../../../_actions';
import { grantMatchesService, marketplaceService, organizationService, pluginService, serviceProviderService, stripeService } from "../../../_services";
import { Loader as Loading } from "../../Shared/Loader";
import { GC_Onboarding_Confirmation } from "./GC_Onboarding_Confirmation";
import { GC_Onboarding_AdvancedSettings } from "./Onboarding_Forms/GC_Onboarding_AdvancedSettings";
import { GC_Onboarding_Basics } from "./Onboarding_Forms/GC_Onboarding_Basics";
import { GC_Onboarding_BusinessAddress } from "./Onboarding_Forms/GC_Onboarding_BusinessAddress";
import { GC_Onboarding_ContactTerms } from "./Onboarding_Forms/GC_Onboarding_ContactTerms";
import { GC_Onboarding_Form } from "./Onboarding_Forms/GC_Onboarding_Form";
import GC_Onboarding_Intro from "./Onboarding_Forms/GC_Onboarding_Intro";
import { GC_Onboarding_Members } from "./Onboarding_Forms/GC_Onboarding_Members";
import { GC_Onboarding_OrganizationAttributes1 } from "./Onboarding_Forms/GC_Onboarding_OrganizationAttributes1";
import { GC_Onboarding_OrganizationAttributes2 } from "./Onboarding_Forms/GC_Onboarding_OrganizationAttributes2";
import { GC_Onboarding_ProfessionalInfo } from "./Onboarding_Forms/GC_Onboarding_ProfessionalInfo";
import { GC_Stripe_Onboarding } from "./Stripe_Onboarding/GC_Stripe_Onboarding";
const striptags = require('striptags');

let genderOptions = ["Male", "Female", "Non-binary", "I prefer not to say", null];
let ethnicityOptions = ["White/Caucasian", "Asian-Eastern", "Asian-Indian", "Hispanic", "African-American", "Native-American", "Mixed race", "I prefer not to say", null]

class GC_Onboarding_v2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      service_Provider: {
        Organization_Id: 0,
        Contact_Email: "",
        Created_By_User_Id: "",
        Opengrants_Verified: false,
        Stripe_Connect_Verified: false,
        Base_Hourly_Rate: "0",
        Currently_Available: true,
        Booking_Link: "",
        Service_Provider_Link: "",
        Opengrants_Terms_Conditions: false,
        Office_Hours: false,
        Foundational_Expertise_Description: "",
        Agency_Expertise_Description: "",
        Program_Expertise_Description: "",
        References: "",
        SP_Role_Tags: [],
        GW_GrantType_Tags: [],
        SP_Industry_Focus_Tags: [],
        SP_Industry_Services_Tags: [],
        GW_Foundation_Tags: [],
        GW_Agency_Tags: [],
        GW_Program_Tags: [],
        Referral: "",
      },
      submitted: false,
      categories: {
        category: [],
        category_Tags: [],
      },
      categoryInput: [],
      category_TagsInput: "",
      categoryError: false,
      category_TagsError: false,
      primary_Org: null,
      new_Organization: null,
      is_New_Org: false,

      loading: true,
      form_errors: false,
      invite_emailInput: '',
      accepted_profiles: '',
      accepted_profile_list: [],
      pending_profiles: [],
      pending_profile_list: [],
      pending_invitation_profiles: [],
      pending_invitation_list: [],
      transfer: false,
      show_Logo: false,

      user: this.props.user,
      other_referral: "",
      sp_loaded: false,

      showGenderInput: false,
      showEthnicityInput: false,
      registrationFailed: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateNav = this.updateNav.bind(this);
    this.setIndividualOrg = this.setIndividualOrg.bind(this);
    this.setOrgLogo = this.setOrgLogo.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("fileBase64", "")

    const { sp_loading } = this.props;

    if (!sp_loading) {
      this.setState({ sp_loaded: true });
      this.initialize_page();
    }

    let otherGender = this.checkOther("gender", genderOptions)
    let otherEthnicity = this.checkOther("ethnicity", ethnicityOptions)

    this.setState({
      showGenderInput: !otherGender ? true : false,
      showEthnicityInput: !otherEthnicity ? true : false,
    })
  }

  componentDidUpdate() {
    const { sp_loaded } = this.state;
    const { sp_loading } = this.props;
    if (!sp_loaded && !sp_loading) {
      this.setState({ sp_loaded: true });

      this.initialize_page();
    }
  }

  async initialize_page() {
    document.body.scrollTo(0, 0);

    const { user } = this.props;
    const { service_Provider } = this.props;

    if (service_Provider) {
      window.location.href = pluginService.redirect("/service-provider-success");
      return;
    }

    //Check if user has an existing org
    //If user has one org, populate org details
    const user_Orgs = await organizationService.getAllOrganizationsByUser(
      user.id
    );
    const org_Keys = Object.keys(user_Orgs.organizations);
    const num_Orgs = org_Keys.length;

    //Set org as user's first org in list
    var primary_Org = null;
    if (num_Orgs > 0) {
      primary_Org = user_Orgs.organizations[org_Keys[0]].organization;
    }

    //Retrieve all the relevant tag lists from the DB
    const SP_Onboarding_Lists = await marketplaceService.getSPOnboardingLists();

    let formattedUsed = primary_Org?.technologies_Used?.map((tech) => {
      return tech.technology
    })

    //Set state details for Service Provider and tag lists
    this.setState({
      nav: "onboard_basics",
      service_Provider: {
        ...this.state.service_Provider,
        SP_Role_Tags: {
          ...this.state.SP_Role_Tags,
          grant_Writer: false,
          startup_Advisor: false,
          technical_Consultant: false,
          accounting_Professional: false,
          attorney: false,
          generalist: false,
          non_Profit_Advisor: false,
        },
        GW_GrantType_Tags: {
          ...this.state.GW_GrantType_Tags,
          foundation_Grants: false,
          government_Grants: false,
        },
        Contact_Email: user.email,
        Created_By_User_Id: user.id,
        Organization_Id: primary_Org ? primary_Org.organization_Id : 0,
        ...service_Provider
      },
      industry_Focus_List: SP_Onboarding_Lists.industry_Focus,
      industry_Service_List: SP_Onboarding_Lists.industry_Service,
      foundation_Expertise_List: SP_Onboarding_Lists.foundation_Expertise,
      agency_Expertise_List: SP_Onboarding_Lists.gov_Agency_Expertise,
      program_Expertise_List: SP_Onboarding_Lists.program_Office_Expertise,
      is_New_Org: (num_Orgs === 0 || primary_Org.owner === user.id) ? true : false,
      new_Organization: {
        organization: num_Orgs === 0 ? {
          naicS_Codes: [],
          industries: [],
          technologies_Used: [],
          social_Impact_Categories: [],
        } : primary_Org,
        technologies_Used: num_Orgs === 0 ? [] : formattedUsed,
      },
      primary_Org: primary_Org,
      loading: false,
    });
  }

  async updateNav(location) {
    const el = document.getElementsByClassName('modal show');
    if (el && el.length > 0) {
      el[0].scrollTo(0, 0);
    }

    //route user to individual signup
    if (location === "onboard") this.setState({ nav: "onboard" }); //bad nav..........
    if (location === "onboard_intro") this.setState({ nav: "onboard_intro" });
    if (location === "onboard_basics") this.setState({ nav: "onboard_basics" });
    if (location === "onboard_organizationAttributes1") this.setState({ nav: "onboard_organizationAttributes1" });
    if (location === "onboard_organizationAttributes2") this.setState({ nav: "onboard_organizationAttributes2" });
    if (location === "onboard_businessAddress") this.setState({ nav: "onboard_businessAddress" });
    if (location === "onboard_professionalInfo") this.setState({ nav: "onboard_professionalInfo" });
    if (location === "onboard_contactTerms") this.setState({ nav: "onboard_contactTerms" });
    if (location === "onboard_advancedSettings") this.setState({ nav: "onboard_advancedSettings" });
    if (location === "onboard_members") this.setState({ nav: "onboard_members" });
    // if (location === "onboard_paymentInfo") this.setState({ nav: "onboard_paymentInfo" });
    if (location === "onboard_confirmation") this.setState({ nav: "onboard_confirmation" });

    // old 
    if (location === "onboard_connect") this.setState({ nav: "onboard_connect" });
    //route user to organization signup
    else if (location === "onboard_organization")
      this.setState({ nav: "onboard_organization" });
    //route user to verify / create Stripe connect account
    // else this.setState({ nav: "onboard_connect" });
    document.body.scrollTo(0, 0);
  }

  imageUploadFailed = (msg) => {
    this.setState({
      imageError: msg,
      show_Logo: false,
    })

    setTimeout(() => {
      this.setState({
        imageError: "",
      })
    }, 6000);
  }
  removePreview = () => {
    this.setState({
      imageError: "",
      show_Logo: false,
    })
  }
  //Passed down to save cropped image data
  setCroppedPixels = (pixels) => {
    this.setState({
      croppedAreaPixels: pixels
    })
  }

  inviteToJoinOrganization = async (e, user_id, organization_id, email) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    const { new_Organization } = this.state;
    if (!new_Organization) {
      var invited_user = await organizationService.inviteToJoinOrganization(organization_id, user_id, email);

      var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
      if (invited_user) {
        new_pending_invitation_profiles.push(invited_user);
      }
      this.setState({
        pending_invitation_profiles: [...new_pending_invitation_profiles],
        invite_emailInput: ''
      });
    } else {
      var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
      new_pending_invitation_profiles.push({ email: email });
      var emailInvites = Object.assign([], this.state.emailInvites);
      emailInvites.push(email);
      this.setState({
        emailInvites: [...emailInvites],
        pending_invitation_profiles: [...new_pending_invitation_profiles],
        invite_emailInput: ''
      });
    }
  }

  handleNewOrganization = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  async handleChange(e) {
    const { id, name, value } = e.target;
    const { service_Provider, new_Organization } = this.state;

    if (id === "service-provider-standard-basic" || id.includes("Mentor_Program")) {
      //save other referrer in a separate state
      if (name === "Other_Referral") {
        this.setState({
          service_Provider: {
            ...service_Provider,
            [name]: value,
          },
          other_referral: value,
        })
      }
      else if (name === "Mentor_Program_Option" && value === 'false') {
        this.setState({
          service_Provider: {
            ...service_Provider,
            [name]: value,
            Mentor_Program: ''
          },
        })
      }
      else {
        this.setState({
          service_Provider: {
            ...service_Provider,
            [name]: value,
          },
        });
      }
    } else if (id === "organization-standard-basic") {
      this.setState({
        new_Organization: {
          ...new_Organization,
          organization: {
            ...new_Organization.organization,
            [name]: value,
          },
        },
      });
    } else {
      this.setState({
        user: {
          ...this.state.user,
          [name]: value
        }
      })
    }
  }

  handleRadioChange = (event) => {
    const { id, name, value } = event.target;
    const { user } = this.state;

    if (name === "gender" && id !== "gender_Other") {
      this.setState({
        showGenderInput: false,
      })
    }

    if (name === "ethnicity" && id !== "ethnicity_Other") {
      this.setState({
        showEthnicityInput: false,
      })
    }

    if (id === "gender_Other") {
      this.setState({
        showGenderInput: true,
      })

    }
    else if (id === "ethnicity_Other") {
      this.setState({
        showEthnicityInput: true,
      })
    }

    this.setState({
      user: {
        ...user,
        [name]: value,
      }
    })
  }

  handleSelectionChange = (event) => {
    const attribute = [event.target.id];
    const parent = event.target.name;
    var service_Provider = { ...this.state.service_Provider };

    if (parent === "service_Provider_Roles") {
      this.setState({
        service_Provider: {
          ...service_Provider,
          SP_Role_Tags: {
            ...service_Provider.SP_Role_Tags,
            [attribute]: event.target.checked,
          },
        },
      });
    } else if (parent === "service_Provider_GW_GrantType_Tags") {
      this.setState({
        service_Provider: {
          ...service_Provider,
          GW_GrantType_Tags: {
            ...service_Provider.GW_GrantType_Tags,
            [attribute]: event.target.checked,
          },
        },
      });
    } else {
      this.setState({
        service_Provider: {
          ...service_Provider,
          [attribute]: event.target.checked,
        },
      });
    }
  };

  handleAdvancedSelection = (categories) => {
    const { new_Organization } = this.state;
    this.setState({
      new_Organization: {
        ...new_Organization,
        ...categories
      }
    })
  }

  handleAdvancedInput = (categoryInputs) => {
    var key = Object.keys(categoryInputs)[0].split("Input")[0];
    var value = Object.values(categoryInputs)[0];

    this.setState({
      new_Organization: {
        ...this.state.new_Organization,
        [key]: value,
      },
    });
  };

  handleCategorySelection = (categories) => {
    const { service_Provider, new_Organization } = this.state;
    this.setState({
      service_Provider: {
        ...service_Provider,
        ...categories,
      },
      new_Organization: {
        ...new_Organization,
        organization: {
          ...new_Organization.organization,
          ...categories,
        }
      }
    });
  };

  handleCategoryInput = (categoryInputs) => {
    this.setState(categoryInputs);
  };

  handleLocationInput = (categoryInputs) => {
    var key = Object.keys(categoryInputs)[0].split("Input")[0];
    var value = Object.values(categoryInputs)[0];

    this.setState({
      new_Organization: {
        ...this.state.new_Organization,
        organization: {
          ...this.state.new_Organization.organization,
          [key]: value,
        },
      },
    });
  };

  handleLocationSelection = (categories) => {
    const { new_Organization } = this.state;

    this.setState({
      new_Organization: {
        ...new_Organization,
        organization: {
          ...new_Organization.organization,
          ...categories,
        },
      },
    });
  };

  setIndividualOrg() {
    const { user } = this.props;

    this.setState({
      new_Organization: {
        organization: {
          name: user.name + " " + user.surname,
          website_URL: "",
        },
      },
    });
  }

  setOrgLogo(file) {
    this.getBase64(file).then(base64 => {
      file.preview = "";
      this.setState({
        new_Organization: {
          ...this.state.new_Organization,
          organization: {
            ...this.state.new_Organization.organization,
            logo_URL: base64,
            logo_file: file
          },
        },
      });
    })
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  setProfileImage_file = (file) => {
    this.getBase64(file).then(base64 => {
      file.preview = "";
      this.setState({
        user: {
          ...this.state.user,
          headshot_file: file,
          headshot: base64
        }
      })
    })
  }

  //go through organization attributes, if one has "Select" value, change it to false
  handleSelectOption = (new_Organization) => {
    for (const [key, value] of Object.entries(new_Organization.organization)) {
      if (value === "Select") {
        new_Organization.organization[key] = false;
      }
    }
  }

  //add selection
  handleCreatableSelection = (input, categoryType) => {
    const { new_Organization } = this.state;

    if (!new_Organization[categoryType] || !new_Organization[categoryType].includes(input.value)) {

      let techArray = [];

      if (!new_Organization[categoryType]) {
        techArray = [...techArray, input.value]
      }
      else {
        techArray = [...new_Organization[categoryType], input.value]
      }

      this.setState({
        new_Organization: {
          ...new_Organization,
          [categoryType]: techArray,
        }
      })
    }
  }

  handleCreatableRemoval = (input, clearAll, categoryType) => {
    const { new_Organization } = this.state

    let tempArray;

    if (clearAll) {
      //set empty array
      tempArray = []
    }
    else {
      //remove from array
      tempArray = new_Organization[categoryType].filter(item => item !== input)
    }
    this.setState({
      new_Organization: {
        ...new_Organization,
        [categoryType]: tempArray,
      }
    })
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      form_errors: false,
    });

    let form_errors = false;
    const { service_Provider, is_New_Org, new_Organization, primary_Org } = this.state;
    let org = new_Organization.organization;
    if (!is_New_Org) {
      org = primary_Org
    }
    // const  { user } = this.props;
    const Role_Tags = this.state.service_Provider.SP_Role_Tags;
    const SP_Role_Tags = [];
    const GrantType_Tags = this.state.service_Provider.GW_GrantType_Tags;
    const GW_GrantType_Tags = [];
    const entity = (is_New_Org && org && org.org_Type && org.org_Type.toLowerCase().includes("individual")) ? "individual" : "organization";
    const user_input = this.state.user;
    const user = this.props.user;

    // save other referrer value in sp if selected option "Others"
    if (service_Provider.Referral === "Others") {
      service_Provider.Referral = this.state.other_referral;
    }

    {
      Object.entries(Role_Tags).map(([key, value]) => {
        if (value === true) {
          SP_Role_Tags.push(key);
        }
      });
    }
    {
      Object.entries(GrantType_Tags).map(([key, value]) => {
        if (value === true) {
          GW_GrantType_Tags.push(key);
        }
      });
    }

    await this.setState({
      submit_Service_Provider: {
        ...this.state.service_Provider,
        SP_Role_Tags: SP_Role_Tags,
        GW_GrantType_Tags: GW_GrantType_Tags,
      },
    });
    let service_provider = Object.assign(
      {},
      this.state.submit_Service_Provider
    );
    let is_Valid = true;

    // check for numeric hourly rate
    if (isNaN(parseInt(service_provider.Base_Hourly_Rate))) {
      service_provider.Base_Hourly_Rate = 0;
    }

    //change "Select" options to false
    this.handleSelectOption(new_Organization);

    if (is_Valid === true) {
      if (is_New_Org) {

        //set email invites
        new_Organization["emailInvites"] = this.state.emailInvites;
        let sp = await organizationService.createOrganization(user.id, new_Organization);

        if (sp) {
          this.setState({
            is_New_Org: false,
          })
          service_provider.organization_Id = sp.organization.organization_Id;
          service_provider.organization = sp.organization;
        }
      }

      service_provider = await serviceProviderService.registerServiceProvider(service_provider);

      //Successful service provider submission
      if (service_provider) {
        user_input.name = striptags(user_input.name);
        user_input.surname = striptags(user_input.surname);

        let updateUser = {
          user: {
            ...user,
            ...user_input,
            onboard_Complete: true
          }
        }

        if (user.headshot_file) {
          updateUser["file_string"] = user.headshot;
        }

        this.props.dispatch(settingsActions.updateProfile(updateUser));
        this.props.dispatch(authenticateActions.setUser(updateUser.user));
        this.props.dispatch(serviceProviderActions.getServiceProvidersByUser(user.id))

        this.setState({
          loading: false,
          service_Provider: { ...service_Provider, ...service_provider }
        });

        // if (is_New_Org) {
        //   this.updateNav("onboard_connect")
        // } else {
        //   window.location.href = pluginService.redirect("/service-provider-success")
        // }
        window.location.href = pluginService.redirect("/service-provider-success")
      }
      //Failed service provider submission
      else {
        this.setState({
          registrationFailed: true,
          submitted: true,
          loading: false,
        })
      }

    } else {
      this.setState({
        submitted: true,
        loading: false,
        form_errors,
      });
    }
  }

  checkOther = (optionType, optionArray) => {
    return optionArray.some((option) => this.state.user[optionType] === option)
  }

  render() {
    const {
      loading,
      nav,
      service_Provider,
      industry_Focus_List,
      industry_Service_List,
      foundation_Expertise_List,
      agency_Expertise_List,
      program_Expertise_List,
      is_New_Org,
      new_Organization,
      primary_Org,
      submitted,
      form_errors,
      user,
      show_Logo,
      emailInvites
    } = this.state;
    let industry_Focus_List_Cat = {
      SP_Industry_Focus_Tags: industry_Focus_List,
    };
    let industry_Service_List_Cat = {
      SP_Industry_Services_Tags: industry_Service_List,
    };
    let foundation_Expertise_List_Cat = {
      GW_Foundation_Tags: foundation_Expertise_List,
    };
    let agency_Expertise_List_Cat = { GW_Agency_Tags: agency_Expertise_List };
    let program_Expertise_List_Cat = {
      GW_Program_Tags: program_Expertise_List,
    };

    return (
      <div>
        {loading && <Loading />}
        {nav === "onboard_intro" && (
          <div>
            <GC_Onboarding_Intro
              updateNav={this.updateNav}
              service_Provider={service_Provider}
            />
          </div>
        )}
        {nav === "onboard_basics" && (
          <div>
            <GC_Onboarding_Basics
              updateNav={this.updateNav}
              service_Provider={service_Provider}
              user={user}
              handleChange={this.handleChange}
              imageUploadFailed={this.imageUploadFailed}
              removePreview={this.removePreview}
              setCroppedPixels={this.setCroppedPixels}
              setProfileImage_file={this.setProfileImage_file}
              show_Logo={show_Logo}
              is_New_Org={is_New_Org}
              handleRadioChange={this.handleRadioChange}
              showEthnicityInput={this.state.showEthnicityInput}
              showGenderInput={this.state.showGenderInput}
              genderOptions={genderOptions}
              ethnicityOptions={ethnicityOptions}
              checkOther={this.checkOther}
            />
          </div>
        )}
        {nav === "onboard_organizationAttributes1" && (
          <div>
            <GC_Onboarding_OrganizationAttributes1
              user={user}
              is_New_Org={true}
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              handleChange={this.handleChange}
            />
          </div>
        )}
        {nav === "onboard_organizationAttributes2" && (
          <div>
            <GC_Onboarding_OrganizationAttributes2
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              setOrgLogo={this.setOrgLogo}
              handleChange={this.handleChange}
            />
          </div>
        )}
        {nav === "onboard_businessAddress" && (
          <div>
            <GC_Onboarding_BusinessAddress
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              handleChange={this.handleChange}
              handleLocationSelection={this.handleLocationSelection}
              handleLocationInput={this.handleLocationInput}
            />
          </div>
        )}
        {nav === "onboard_professionalInfo" && (
          <div>
            <GC_Onboarding_ProfessionalInfo
              updateNav={this.updateNav}
              service_Provider={service_Provider}
              new_Organization={new_Organization}
              // category_TagsError={category_TagsError}
              submitted={submitted}
              handleSelectionChange={this.handleSelectionChange}
              industry_Focus_List_Cat={industry_Focus_List_Cat}
              industry_Service_List_Cat={industry_Service_List_Cat}
              foundation_Expertise_List_Cat={foundation_Expertise_List_Cat}
              agency_Expertise_List_Cat={agency_Expertise_List_Cat}
              handleCategorySelection={this.handleCategorySelection}
              handleCategoryInput={this.handleCategoryInput}
              handleChange={this.handleChange}
              is_New_Org={is_New_Org}
            />
          </div>
        )}
        {nav === "onboard_contactTerms" && (
          <div>
            <GC_Onboarding_ContactTerms
              updateNav={this.updateNav}
              service_Provider={service_Provider}
              submitted={submitted}
              handleSelectionChange={this.handleSelectionChange}
              handleChange={this.handleChange}
              new_Organization={new_Organization}
              handleSubmit={this.handleSubmit}
              is_New_Org={is_New_Org}
              other_referral={this.state.other_referral}
              registrationFailed={this.state.registrationFailed}
            />
          </div>
        )}
        {nav === "onboard_advancedSettings" && (
          <div>
            <GC_Onboarding_AdvancedSettings
              updateNav={this.updateNav}
              submitted={submitted}
              handleChange={this.handleChange}
              service_Provider={service_Provider}
              industry_Focus_List_Cat={industry_Focus_List_Cat} // get rid of when we get the values
              // category_TagsError={category_TagsError}
              new_Organization={new_Organization}
              handleCategorySelection={this.handleAdvancedSelection}
              handleCategoryInput={this.handleCategoryInput}
              handleCreatableSelection={this.handleCreatableSelection}
              handleCreatableRemoval={this.handleCreatableRemoval}
              onboardingStyling={true}
            />
          </div>
        )}
        {nav === "onboard_members" && (
          <div>
            <GC_Onboarding_Members
              updateNav={this.updateNav}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              form_errors={form_errors}
              new_Organization={new_Organization}
              invite_emailInput={this.state.invite_emailInput}
              accepted_profiles={this.state.accepted_profiles}
              accepted_profile_list={this.state.accepted_profile_list}
              pending_profiles={this.state.pending_profiles}
              pending_profile_list={this.state.pending_profile_list}
              pending_invitation_profiles={this.state.pending_invitation_profiles}
              pending_invitation_list={this.state.pending_invitation_list}
              transfer={this.state.transfer}
              inviteToJoinOrganization={this.inviteToJoinOrganization}
              handleNewOrganization={this.handleNewOrganization}
              emailInvites={emailInvites}
            />
          </div>
        )}
        {/* {nav === "onboard_paymentInfo" && (
          <div>
            <GC_Onboarding_PaymentInfo
              updateNav={this.updateNav}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              form_errors={form_errors}
              new_Organization={new_Organization}
            />
          </div>
        )} */}
        {nav === "onboard_confirmation" && (
          <div>
            <GC_Onboarding_Confirmation />
          </div>
        )}

        {/* working above */}
        {nav === "onboard" && (
          <div>
            <GC_Onboarding_Form
              service_Provider={service_Provider}
              updateNav={this.updateNav}
              industry_Focus_List_Cat={industry_Focus_List_Cat}
              industry_Service_List_Cat={industry_Service_List_Cat}
              foundation_Expertise_List_Cat={foundation_Expertise_List_Cat}
              agency_Expertise_List_Cat={agency_Expertise_List_Cat}
              program_Expertise_List_Cat={program_Expertise_List_Cat}
              is_New_Org={true}
              new_Organization={new_Organization}
              primary_Org={primary_Org}
              handleChange={this.handleChange}
              handleSelectionChange={this.handleSelectionChange}
              handleCategoryInput={this.handleCategoryInput}
              handleCategorySelection={this.handleCategorySelection}
              handleLocationInput={this.handleLocationInput}
              handleLocationSelection={this.handleLocationSelection}
              handleSubmit={this.handleSubmit}
              setIndividualOrg={this.setIndividualOrg}
              setOrgLogo={this.setOrgLogo}
              submitted={submitted}
              form_errors={form_errors}
              user={user}
            />
          </div>
        )}
        {nav === "onboard_connect" && (
          <GC_Stripe_Onboarding
            service_Provider={service_Provider}
            updateNav={this.updateNav}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;
  const { stripe_Verified } = state.stripeVerified;

  const sp_loading = state.serviceprovider_getserviceprovider.loading;

  return {
    user,
    service_Provider,
    stripe_Verified,
    sp_loading
  };
}
const connectedGC_Onboarding_v2 = connect(mapStateToProps)(GC_Onboarding_v2);
export { connectedGC_Onboarding_v2 as GC_Onboarding_v2 };

