import React from 'react';
import { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import connectClearRefinements from './connectClearRefinement';

class CustomClearAllRefinments extends Component {

  onClear = () => {
    this.props.refine({});
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to');
  }

  render() {
    return (
      <div>
        <button id="clearFilters" onClick={this.onClear}>
          Clear Filters
        </button>
      </div>
    );
  }
}

export default connectClearRefinements(CustomClearAllRefinments);