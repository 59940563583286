export const checkoutService = {
    addToCart: (priceIds_new, price_add) => {
        let { selectedPriceIds, selectedRecurringPriceIds } = priceIds_new;

        if (!price_add.recurring) {
            if (selectedPriceIds.filter((price) => { return price.id == price_add.id }).length === 0) {
                selectedPriceIds.push(price_add);
            }
        } else if (price_add.recurring) {
            if (selectedRecurringPriceIds.filter((price) => { return price.id == price_add.id }).length === 0) {
                selectedRecurringPriceIds.push(price_add);
            }
        }

        return { selectedPriceIds, selectedRecurringPriceIds };
    },
    removeFromCart: (priceIds_new, price_remove) => {
        let { selectedPriceIds, selectedRecurringPriceIds } = priceIds_new;

        if (!price_remove.recurring) {
            selectedPriceIds = selectedPriceIds.filter((price) => { return price.id != price_remove.id });
        } else if (price_remove.recurring) {
            selectedRecurringPriceIds = selectedRecurringPriceIds.filter((price) => { return price.id != price_remove.id });
        }

        return { selectedPriceIds, selectedRecurringPriceIds };
    }
}