import React, { useEffect, useState } from "react";
import { Carousel, Row } from 'react-bootstrap';
import ActionList from "./ActionList";
import { dashboardService } from "../../../../_services";

const ActionNotification = ({ user, actions }) => {
  // const [actions, setActions] = useState([]);

  // useEffect(() => {
  //   getActions()
  // }, [])

  // const getActions = async () => {
  //   const dashboard = await dashboardService.loadDashboard(user);
  //   setActions(dashboard.action_Items);
  // }

  const validActions = [
    'invoice-new',
    'proposal-new',
    'proposal',
    'message-new',
    'payout-create',
    'organization-create',
    'organization'
  ]

  return (<>
    {
      actions?.length > 0 && actions.some((action) => validActions.includes(action.action_Type)) &&
      <Row className={actions.length > 1 ? "bg-white border border-primary rounded-10 shadow py-32 py-sm-56 mb-48" : "bg-white border border-primary rounded-10 shadow py-32 py-sm-56 mb-48 px-80 px-md-136"}>
        {actions.length === 1 && actions.map(action => <ActionList action={action} />)}
        {actions.length > 1 && <Carousel className="slide px-80 px-md-136" data-bs-ride="carousel">
          {actions.filter(action => validActions.includes(action.action_Type)).map((action, idx) =>
            <Carousel.Item data-bs-interval="3000">
              <ActionList action={action} />
            </Carousel.Item>
          )}
        </Carousel>}
      </Row >
    }
  </>)
}

export default ActionNotification
