import React from "react";
import { Card, Col, Row } from 'react-bootstrap';
import { pluginService } from "../../../../_services";
import 'react-slideshow-image/dist/styles.css';
import '../../../../Styles/Algolia.css';
import "../../../../_assets/css/material-dashboard-react.css";
import CompleteProfileDashboard_v2 from "../../../../_assets/img/CompleteProfileDashboard_v2.png";
import CreateOrganizationDashboard_v2 from "../../../../_assets/img/CreateOrganizationDashboard_v2.png";
import GotInvoiceDashboard_v2 from "../../../../_assets/img/GotInvoiceDashboard_v2.png";
import GotMessageDashboard_v2 from "../../../../_assets/img/GotMessageDashboard_v2.png";
import GotProposalDashboard_v2 from "../../../../_assets/img/GotProposalDashboard_v2.png";
import "../../../../_assets/img/icons/sideNavIcons/css/fontello.css";
import spPayoutLogo from "../../../../_assets/img/spPayoutLogo.png";
import { history } from "../../../../_helpers/history.js";

const ActionList = ({ action }) => {
  let actionImage;
  let actionButtonText;
  let actionHelperText;
  let actionLink;
  let multipleActions = action.action_Count > 1;

  if (action.action_Type === "invoice-new") {
    actionImage = GotInvoiceDashboard_v2
    actionHelperText = multipleActions ?
      "Please review these invoices and contact your service providers directly if there are issues. You will be billed for these invoices end-of-day Thursday."
      :
      "Please review this invoice and contact your service provider directly if there are issues. You will be billed for this invoice end-of-day Thursday."
    actionButtonText = "View Invoice" + (multipleActions ? "s" : "")
  }
  else if (action.action_Type === "proposal-new" || action.action_Type === "proposal") {
    actionImage = GotProposalDashboard_v2
    actionHelperText = "Take a look at the details and let them know if you accept."
    actionButtonText = "View Proposal" + (multipleActions ? "s" : "")
  }
  else if (action.action_Type === "message-new") {
    actionImage = GotMessageDashboard_v2
    actionHelperText = "Let's go to your messages and check out what they have to say!"
    actionButtonText = "View Message" + (multipleActions ? "s" : "")
  }
  else if (action.action_Type === "payout-create") {
    actionImage = spPayoutLogo;
    actionHelperText = "Your payout method is required before starting a project.";
    actionButtonText = "Create Payout Method"
  }
  else if (action.action_Type === "organization-create" || action.action_Type === "organization") {
    actionImage = CreateOrganizationDashboard_v2;
    actionHelperText = "Create an organization to unlock your grant matches.";
    actionButtonText = "Create Organization"
    actionLink = '/grant-seeker/onboarding';
  } else {
    return null;
  }

  return (
    <Card className="bg-transparent border-0 text-secondary">
      <Card.Header className="bg-transparent border-0"><h4 className="fw-bold">{action.action_Text}</h4></Card.Header>
      <Card.Body className="w-auto text-start">
        <Row>
          <Col className="d-flex align-items-center">
            <p className="m-0 pb-0 pb-md-48">{actionHelperText}</p>
          </Col>
          <Col className="col-12 col-lg-auto align-self-center">
            <button className="bg-primary-dark text-white rounded-10 p-16" onClick={() => {
              history.push((pluginService.isIFrame() && action.action_Link.includes('register')) ?
                pluginService.redirect("/grant-seeker/onboarding")
                : actionLink ? actionLink : pluginService.redirect(action.action_Link))
            }}>
              {actionButtonText}
            </button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ActionList
