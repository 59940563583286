import React, { useCallback, useMemo, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate'
import { withHistory } from 'slate-history'
import { FormatBold, FormatItalic, FormatUnderlined, LooksOne, LooksTwo, Looks3, FormatListNumbered, FormatListBulleted, FormatIndentIncrease } from "@material-ui/icons"
import "../../../Styles/SlateText.css"

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const SlateTextEditor = ({ handleFormattedInput, savedText, name }) => {

    const [value, setValue] = useState(initialValue)

    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    // Turn on/off heading, list, or indent buttons
    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format)
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type),
            split: true,
        })
        const newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
        Transforms.setNodes(editor, newProperties)

        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }

    // Turn on/off bold, italic, or underline buttons
    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)
        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    // Check if heading, list, or indent buttons are active
    const isBlockActive = (editor, format) => {
        const { selection } = editor
        if (!selection) return false
        const [match] = Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        })

        return !!match
    }

    //Check if bold, italic, or underline buttons are active
    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    //Apply heading, list, or indent styles
    const Element = ({ attributes, children, element }) => {
        switch (element.type) {
            case 'heading-one':
                return <h1 {...attributes}>{children}</h1>
            case 'heading-two':
                return <h2 {...attributes}>{children}</h2>
            case 'heading-three':
                return <h3 {...attributes}>{children}</h3>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'indent':
                return <p style={{ marginLeft: "40px" }}{...attributes}>{children}</p>
            default:
                return <p {...attributes}>{children}</p>
        }
    }

    //Apply bold, italic, or underline styles
    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <b className="boldText">{children}</b>
        }

        if (leaf.italic) {
            children = <i className="italicText">{children}</i>
        }

        if (leaf.underline) {
            children = <u className="underlineText">{children}</u>
        }

        return <span {...attributes}>{children}</span>
    }

    //Heading, list, or indent button
    const BlockButton = ({ format, icon }) => {
        const editor = useSlate()

        return (
            <button
                type="button"
                className={"editorButton " + (isBlockActive(editor, format) ? "darkGray" : "lightGray")}
                active={isBlockActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                {icon}
            </button>
        )
    }

    //Bold, italic, or underline button
    const MarkButton = ({ format, icon }) => {
        const editor = useSlate()

        return (
            <button
                type="button"
                className={"editorButton " + (isMarkActive(editor, format) ? "darkGray" : "lightGray")}
                active={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                {icon}
            </button>
        )
    }

    //Save typed text into the state
    const handleText = (name, value) => {
        setValue(value);
        handleFormattedInput(name, value);
    }

    return (
        <>
            <Slate name={name} editor={editor} value={savedText ? savedText : value} onChange={value => handleText(name, value)}>

                <div className="editorBox">
                    <div className="editorToolbar">
                        <MarkButton format="bold" icon={<FormatBold className="editorIcon" size="large" />} />
                        <MarkButton format="italic" icon={<FormatItalic className="editorIcon" />} />
                        <MarkButton format="underline" icon={<FormatUnderlined className="editorIcon" />} />
                        <BlockButton format="heading-one" icon={<LooksOne className="editorIcon" />} />
                        <BlockButton format="heading-two" icon={<LooksTwo className="editorIcon" />} />
                        <BlockButton format="heading-three" icon={<Looks3 className="editorIcon" />} />
                        <BlockButton format="numbered-list" icon={<FormatListNumbered className="editorIcon" />} />
                        <BlockButton format="bulleted-list" icon={<FormatListBulleted className="editorIcon" />} />
                        <BlockButton format="indent" icon={<FormatIndentIncrease className="editorIcon" />} />
                    </div>

                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        className="editorTextInput spFormattedTextContainer"
                        style={{ height: 300 }}
                        autoFocus={false}
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                            if (event.key === "Tab") {
                                event.preventDefault();
                                toggleBlock(editor, "indent")
                            }
                        }}
                    />
                </div>
            </Slate>
        </>

    )
}

//Initially empty input field
const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]

export default SlateTextEditor