//this file is no longer in use ?


import React from 'react';
import { connect } from 'react-redux';
import { Loader } from '../../Shared/Loader';

import '../../../Styles/SettingsStyles/OrganizationViewEdit.css';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import EditIcon from '@material-ui/icons/Edit';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { MainOrganizationForm } from "./PopupForms/MainOrganizationForm"
import { OrganizationInfoForm } from "./PopupForms/OrganizationInfoForm"
import { AdditionalAttributesForm } from "./PopupForms/AdditionalAttributesForm"
import { AdvancedAttributesForm } from "./PopupForms/AdvancedAttributesForm"
import { MembersForm } from "./PopupForms/MembersForm"

import { PendingProfileList } from './PendingProfileList';
import { PendingInvitationList } from './PendingInvitationList';
import { AcceptedProfileList } from './AcceptedProfileList.jsx';

class OrganizationViewEdit extends React.Component {
    constructor(props) {
        super(props);

        let organization_id = window.location.pathname.split('/').pop();

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),

            organization_id: organization_id != "organizations" && organization_id != "settings" ?
                organization_id :
                this.props.organization_id ? this.props.organization_id : "",

            currentSection: "",
            loading: false,
            openForm: false,
        }
    }

    //Add HTTPS to URLs
    withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

    //Set sections for pop up menu
    setSection = (e) => {
        let section = e.currentTarget.getAttribute("name");

        this.setState({
            currentSection: section,
            openForm: true
        })
    }

    //Add confirmation notification
    cancel = async () => {
        await this.props.resetOrgState();
        this.setState({
            openForm: false,
            currentSection: ""
        })
    }

    //Handle submit for this page
    handleSubmit = async (e) => {

        this.setState({
            loading: true
        })

        if (this.state.currentSection === "Organization" || this.state.currentSection === "Organization Information" || this.state.currentSection === "Advanced Attributes") {
            await this.props.handleOrgSubmit(e);
        }
        else {
            await this.props.handleAdditionalSubmit(e);
        }

        //SetTimeout used for success or failure message in popup form
        if (this.props.submitSuccess) {
            this.setState({
                openForm: false,
                currentSection: "",
            })
            this.props.changeSuccess();
        }//if error with db send error message
        else if (this.props.updatedErrorAlert === true) {
            await this.props.resetOrgState();
            this.setState({
                openForm: false,
            })
        }

        this.setState({
            loading: false,
        })

        window.location.href = '#modalTop'
    }

    render() {
        const { user, loading, organization_id, currentSection, openForm } = this.state;
        const { service_Provider, currentlyAvailable, handleSwitch, org, organization_namesInput, accepted_profiles, pending_profiles, transfer, pending_invitation_profiles, cancelOrganizationInvitation } = this.props

        let naicS_CodesTags = null;
        let industryTags = null;
        let socialImpactTags = null;
        let technologyTags = null;
        let accepted_profile_list = null;
        let edit_accepted_profile_list = null;
        let organization = org.organization;
        let editingAllowed = false;

        if (org.naicS_Codes) {
            naicS_CodesTags = org.naicS_Codes.map(naicS_Code => {
                return <span className="tag">{naicS_Code}</span>
            });
        }

        if (org.industries) {
            industryTags = org.industries.map(industry => {
                return <span className="tag">{industry}</span>
            });
        }

        if (org.social_Impact_Categories) {
            socialImpactTags = org.social_Impact_Categories.map(socialImpact => {
                return <span className="tag">{socialImpact}</span>
            });
        }

        if (org.technologies_Used) {
            technologyTags = org.technologies_Used.map(technology => {
                return <span className="tag">{technology}</span>
            });
        }

        //For general member display and to display members of other organizations
        if (org && org.organizationMembers) {

            accepted_profile_list = org.organizationMembers.map((profile) => {
                return (
                    <AcceptedProfileList
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        updateOrganizationOwner={this.props.updateOrganizationOwner}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //For member display in editing form
        if (accepted_profiles) {
            edit_accepted_profile_list = accepted_profiles.map((profile) => {
                return (
                    <AcceptedProfileList
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        updateOrganizationOwner={this.props.updateOrganizationOwner}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //for pending profiles requesting to join
        var pending_profile_list = null;
        if (pending_profiles) {
            pending_profile_list = pending_profiles.map((profile) => {
                return (
                    <PendingProfileList
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //for pending invitations
        var pending_invitation_list = null;
        if (pending_invitation_profiles) {
            pending_invitation_list = pending_invitation_profiles.map((profile) => {
                return (
                    <PendingInvitationList
                        user={user}
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        cancelOrganizationInvitation={cancelOrganizationInvitation}
                    />
                )
            });
        }


        //Check is current user is allowed to edit organization page
        if (org && org.organizationMembers && user) {
            org.organizationMembers.forEach(user => {
                if (user.username === this.state.user.userName) {
                    editingAllowed = true;
                }
            })
        }

        return (
            <div>
                <div id="mainContainer">
                    {this.props.updatedAlert &&
                        <div className={'settingsForm'}>
                            <br /><br />
                            <div className={`alert-success`}>Organization settings have been updated.</div>
                        </div>
                    }
                    {this.props.updatedErrorAlert &&
                        <div className={'settingsForm'}>
                            <br /><br />
                            <div className={`alert-error`}>There was an error updating your settings.</div>
                        </div>
                    }

                    {/* ---------------------------- ORGANIZATION ATTRIBUTES ---------------------------- */}

                    <div className="row" id="organizationTop" >

                        {/* MAIN ORGANIZATION */}

                        <div className="boxContainer" id="organizationMain">
                            {editingAllowed &&
                                <div className="endFlex">
                                    <EditIcon name="Organization" className="icon editIcon" onClick={(e) => this.setSection(e)} />
                                </div>
                            }
                            <div id="logoAndText">
                                <div className="centerFlex" >
                                    {organization.logo_URL && <img src={organization.logo_URL} alt="organization logo" className="companyLogo" />}
                                    {!organization.logo_URL && <i className="material-icons defaultLogo">business</i>}
                                </div>

                                <div className="centerFlex">
                                    <div id="detailsContainer">
                                        <h2 className="boxTitle">{organization_namesInput}</h2>
                                        <p className="boxText">{organization.description}</p>

                                    </div>
                                </div>
                            </div>
                            {editingAllowed && (service_Provider && service_Provider.organization && (service_Provider.organization.organization_Id == organization_id)) &&
                                <div className="centerFlex" id="toggle">
                                    <FormControlLabel className="margin-vertical"
                                        control={
                                            <Switch
                                                checked={currentlyAvailable}
                                                name="currentlyAvailable"
                                                onChange={(e) => handleSwitch(e)}
                                                color=""
                                                style={currentlyAvailable ? { color: "limegreen" } : { color: "grey" }}
                                            />
                                        }
                                        label={currentlyAvailable ? <span className="green boxSubtitle noMargin noBold">Currently Available</span> : <span className="red boxSubtitle noMargin noBold"> Not Currently Available</span>}
                                        labelPlacement="start"
                                    />
                                </div>
                            }
                        </div>

                        {/* ORGANIZATION INFORMATION */}

                        <div className="boxContainer" id="organizationInfo">
                            {editingAllowed &&
                                <div className="endFlex">
                                    <EditIcon name="Organization Information" className="icon editIcon" onClick={(e) => this.setSection(e)} />
                                </div>
                            }
                            <h2 className="boxTitle">Organization Information </h2>

                            <div className="centerFlex flexColumn" id="organizationInformationContainer">

                                {organization.org_Type && !organization.org_Type.toLowerCase().includes("individual") &&
                                    <div className="centerFlex mb-5" id="address">
                                        <HomeWorkIcon className="icon" />
                                        {(organization.country) ?
                                            <p className="boxText centerText dFlex flexWrap">
                                                {organization.country && <span>{organization.address}&nbsp;</span>}
                                                {organization.city && organization.state && organization.zipCode &&
                                                    <span>{organization.city}, {organization.state} {organization.zipCode}&nbsp;</span>}
                                                <span>{organization.country} </span>
                                            </p>
                                            :
                                            <p className="boxText">N/A</p>
                                        }
                                    </div>}

                                <div className="centerFlex">
                                    <PhoneIcon className="icon" />
                                    <p className="boxText">{organization.phone_Number ? organization.phone_Number : "N/A"}</p>
                                </div>

                                <div className="centerFlex">
                                    <LanguageIcon className="icon" />
                                    <p className="boxText">{organization.website_URL ? <a href={this.withHttp(organization.website_URL)} target="_blank" rel="noopener noreferrer">{organization.website_URL}</a> : "N/A"}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* ---------------------------- ADDITIONAL ATTRIBUTES ---------------------------- */}
                    {(service_Provider && (service_Provider.organization_Id == organization_id)) &&

                        <div className="boxContainer">
                            {editingAllowed &&
                                <div className="endFlex">
                                    <EditIcon name="Additional Attributes" className="icon editIcon" onClick={(e) => this.setSection(e)} />
                                </div>
                            }
                            <h2 className="boxTitle">Additional Attributes</h2>

                            {/* EXTRA FOR MOBILE */}

                            <hr />
                            <div className="dFlex hiddenOnDesktop">
                                <div className="customIcon tealWithWhite icon centerFlex" style={{ marginBottom: 0 }}>
                                    <AttachMoneyIcon className="innerIcon" />
                                </div>
                                <p className="boxText" style={{ marginBottom: 0 }}>Hourly Rate: ${service_Provider.base_Hourly_Rate} USD</p>
                            </div>
                            <hr />

                            <div id="additional">
                                <div id="rolesColumn">
                                    {/* ROLES */}
                                    <div>
                                        <h4 className="boxSubtitle">Role(s)</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.sP_Roles && service_Provider.sP_Roles.map(role => {
                                                return <span className="tag">{(role.role_Type.charAt(0).toUpperCase() + role.role_Type.slice(1)).replaceAll("_", " ")}</span>
                                            })}
                                        </div>
                                    </div>
                                    <br />

                                    {/* Grant Type */}

                                    <div>
                                        <h4 className="boxSubtitle">Grant Type(s)</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.grant_Types && service_Provider.grant_Types.length !== 0 ? service_Provider.grant_Types.map(grant => {
                                                return <span className="tag">{(grant.grant_Type.charAt(0).toUpperCase() + grant.grant_Type.slice(1)).replaceAll("_", " ")}</span>
                                            }) : <span className="boxText noMargin">N/A</span>}
                                        </div>
                                    </div>
                                    <hr />

                                    {/* FOUNDATION */}

                                    <div>
                                        <h4 className="boxSubtitle">Foundation Expertise</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.foundation_Types && service_Provider.foundation_Types.length !== 0 ? service_Provider.foundation_Types.map(foundation => {
                                                return <span className="tag">{foundation.foundation_Type}</span>
                                            }) : <span className="boxText noMargin">N/A</span>}
                                        </div>
                                    </div>
                                    <br />

                                    <div>
                                        <h4 className="boxSubtitle">Additional Foundation Expertise</h4>
                                        <p className="boxText">
                                            {service_Provider.foundational_Expertise_Description ? service_Provider.foundational_Expertise_Description : <span className="noMargin">N/A</span>}
                                        </p>
                                    </div>
                                    <hr />

                                    {/* GOVERNMENT */}

                                    <div>
                                        <h4 className="boxSubtitle">Government Agency Expertise</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.agency_Types && service_Provider.agency_Types.length !== 0 ? service_Provider.agency_Types.map(agency => {
                                                return <span className="tag">{agency.agency_Type}</span>
                                            }) : <span className="boxText noMargin">N/A</span>}
                                        </div>
                                    </div>
                                    <br />

                                    <div>
                                        <h4 className="boxSubtitle">Agencies You Work With</h4>
                                        <p className="boxText">
                                            {service_Provider.agency_Expertise_Description ? service_Provider.agency_Expertise_Description : <span className="noMargin">N/A</span>}
                                        </p>
                                    </div>
                                    <hr />

                                    <h4 className="boxSubtitle">Government Program of Office Expertise</h4>
                                    <div className="dFlex flexWrap">
                                        {service_Provider.program_Types && service_Provider.program_Types.length !== 0 ? service_Provider.program_Types.map(program => {
                                            return <span className="tag">{program.program_Type}</span>
                                        }) : <span className="boxText noMargin">N/A</span>}
                                    </div>
                                    <br />

                                    <div>
                                        <h4 className="boxSubtitle">Specific Funding Programs or Offices You Work With</h4>
                                        <p className="boxText">{service_Provider.program_Expertise_Description ? service_Provider.program_Expertise_Description : <span className="noMargin">N/A</span>}</p>
                                    </div>
                                    <hr id="lastHrRoles" />

                                </div>

                                <div className="verticalHr "></div>

                                <div id="focusColumn">

                                    {/* EXTRA FOR DESKTOP */}
                                    <div className="centerFlex spaceBtw hiddenOnMobile" id="extra">
                                        <div className="centerFlex" >
                                            <div className="customIcon tealWithWhite icon centerFlex"><AttachMoneyIcon className="innerIcon" /></div>
                                            <p className="boxText noMargin">Hourly Rate: ${service_Provider.base_Hourly_Rate} USD</p>
                                        </div>
                                        {/* <div className="centerFlex">
                                                    <div className="customIcon tealWithWhite icon centerFlex"><CallMadeIcon className="innerIcon" /></div>
                                                    <p className="boxText noMargin">
                                                        {service_Provider && service_Provider.booking_Link !== "" ?
                                                            (<a href={this.withHttp(service_Provider.booking_Link)} target="_blank" rel="noopener noreferrer">Booking Link</a>) :
                                                            ("Booking Link: N/A")
                                                        }
                                                    </p>
                                                </div>
                                                <div className="centerFlex">
                                                    <div className={`customIcon icon centerFlex ${service_Provider.office_Hours ? "tealWithWhite" : "blackWithWhite"}`}>
                                                        {service_Provider.office_Hours ? <CheckIcon className="innerIcon" /> : <CloseIcon className="innerIcon" />}
                                                    </div>
                                                    <p className="boxText noMargin">{service_Provider.office_Hours ? "" : "Not "}Open to Office Hours</p>
                                                </div> */}
                                    </div>
                                    <hr className="hiddenOnMobile" />

                                    {/* INDUSTRIES */}

                                    <div>
                                        <h4 className="boxSubtitle">Industry Focus</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.sP_Focus && service_Provider.sP_Focus.map(focus => {
                                                return <span className="tag">{focus.focus_Type}</span>
                                            })}
                                        </div>
                                    </div>
                                    <hr />

                                    {/* SERVICES */}

                                    <div>
                                        <h4 className="boxSubtitle">Services Provided</h4>
                                        <div className="dFlex flexWrap">
                                            {service_Provider.sP_Services && service_Provider.sP_Services.map(service => {
                                                return <span className="tag">{service.service_Type}</span>
                                            })}
                                        </div>
                                    </div>
                                    <hr />

                                    {/* TESTIMONIALS */}

                                    <div>
                                        <h4 className="boxSubtitle">Testimonials/References</h4>
                                        <p className="boxText">{service_Provider.references ? service_Provider.references : <span className="noMargin">N/A</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* ---------------------------- ADVANCED ATTRIBUTES ---------------------------- */}

                    <div className="row boxContainer">
                        {editingAllowed &&
                            <div className="endFlex">
                                <EditIcon name="Advanced Attributes" className="icon editIcon" onClick={(e) => this.setSection(e)} />

                            </div>
                        }
                        <h2 className="boxTitle">Advanced Attributes</h2>
                        <hr />

                        <div id="advanced" >

                            {/* CHECKBOXES */}

                            <div id="allCheckboxesColumn" className="centerFlex">
                                <div className=" dunsNumber">
                                    <h4 className="boxSubtitle hiddenOnMobile">Unique Entity Identifier (previously known as DUNS number): {organization.dunS_Number ? organization.dunS_Number : "N/A"}</h4>
                                </div>
                                <div className="dFlex flexWrap spaceBtw checkBoxContainer">
                                    <div className="dFlex checkboxesWidth flexColumn">
                                        {/* <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.saM_Number ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.saM_Number ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">Registered in SAM</p>
                                        </div> */}
                                        <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.businiess_8a_Development ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.businiess_8a_Development ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">8A Business Development</p>
                                        </div>
                                        <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.woman_Owned ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.woman_Owned ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">Woman Owned Business</p>
                                        </div>
                                    </div>
                                    <div className="dFlex checkboxesWidth flexColumn">
                                        <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.veteran_Owned ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.veteran_Owned ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">Certified Veteran Owned Business</p>
                                        </div>
                                        <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.hubZone_Owned ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.hubZone_Owned ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">Located in a Hubzone</p>
                                        </div>
                                        <div className="dFlex">
                                            <div className={`customCheckbox centerFlex ${organization.small_Business ? "tealWithWhite" : "blackWithWhite"}`}>
                                                {organization.small_Business ? <CheckIcon className="innerCheckbox" /> : <CloseIcon className="innerCheckbox" />}
                                            </div>
                                            <p className="boxText">Small Business</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr id="lastHrCheckboxes" />
                            <div className="verticalHr"></div>
                            <div className="centerFlex hiddenOnDesktop">
                                <h4 className="boxSubtitle ">Unique Entity Identifier (previously known as DUNS number): {organization.dunS_Number !== "" ? organization.dunS_Number : "N/A"}</h4>
                            </div>

                            {/* TAGS */}
                            <div id="allTagsColumn">
                                <hr className="hiddenOnDesktop" />

                                <div>
                                    <h4 className="boxSubtitle">NAICS Code(s)</h4>
                                    <div className="dFlex flexWrap">
                                        {naicS_CodesTags && naicS_CodesTags.length !== 0 ? naicS_CodesTags : <span className="noMargin">N/A</span>}
                                    </div>
                                </div>

                                <div>
                                    <h4 className="boxSubtitle">Industry Focus</h4>
                                    <div className="dFlex flexWrap">
                                        {industryTags && industryTags.length !== 0 ? industryTags : <span className="noMargin">N/A</span>}
                                    </div>
                                </div>

                                <div>
                                    <h4 className="boxSubtitle">Technologies Used</h4>
                                    <div className="dFlex flexWrap">
                                        {technologyTags && technologyTags.length !== 0 ? technologyTags : <span className="noMargin">N/A</span>}
                                    </div>
                                </div>

                                <div>
                                    <h4 className="boxSubtitle">Social Impact Categories</h4>
                                    <div className="dFlex flexWrap">
                                        {socialImpactTags && socialImpactTags.length !== 0 ? socialImpactTags : <span className="noMargin">N/A</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ---------------------------- MEMBERS ---------------------------- */}

                    <div className="row boxContainer">
                        {editingAllowed && org.organization.owner === user.id &&
                            <div className="endFlex">
                                <EditIcon name="Members" className="icon editIcon" onClick={(e) => this.setSection(e)} />
                            </div>
                        }
                        <h1 className="boxTitle memberTitle">Members</h1>
                        <div className="dFlex flexWrap">
                            {accepted_profile_list}
                        </div>
                    </div>
                </div>

                {/* ---------------------------- POPUP FORMS ---------------------------- */}
                <div>
                    <Modal
                        id="listingPreviewModal"
                        isOpen={openForm}
                        fade={false}
                    >
                        <ModalHeader id="modalTop" style={{ padding: "20px 30px" }}>
                            <div className="centerFlex spaceBtw">
                                <h4 className="boxTitle noMargin">Edit {currentSection}</h4>
                                <Button className="backButton" onClick={() => this.cancel()}>
                                    Back
                                </Button>
                            </div>
                        </ModalHeader>

                        <ModalBody style={{ padding: "20px 30px" }} className='orgViewEditModal'>
                            {loading && <Loader />}

                            {this.props.submitted && !this.props.submitSuccess &&

                                <p className="boxText red redBackground">{this.props.submitSuccess ? "" : "Error: Please Review Invalid Fields"}</p>
                            }
                            {currentSection === "Organization" &&
                                <MainOrganizationForm
                                    submitted={this.props.submitted}
                                    organizationPassed={organization}
                                    handleChange={this.props.handleChange}
                                    updateOrganizationName={this.props.updateOrganizationName}
                                    organization_namesInput={this.props.organization_namesInput}
                                    organizationNameError={this.props.organizationNameError}
                                    service_Provider={service_Provider}
                                    logo_url_is_required={this.props.logo_url_is_required}
                                    description_is_required={this.props.description_is_required}
                                />
                            }
                            {currentSection === "Organization Information" &&
                                <OrganizationInfoForm
                                    submitted={this.props.submitted}
                                    organizationPassed={organization}
                                    city={this.props.city}
                                    state={this.props.state}
                                    country={this.props.country}
                                    organizationCityError={this.props.organizationCityError}
                                    organizationStateError={this.props.organizationStateError}
                                    organizationCountryError={this.props.organizationCountryError}
                                    handleChange={this.props.handleChange}
                                    handleLocationSelection={this.props.handleLocationSelection}
                                    handleLocationInput={this.props.handleLocationInput}
                                    service_Provider={service_Provider}
                                    website_is_required={this.props.website_is_required}
                                    address_is_required={this.props.address_is_required}
                                />
                            }
                            {currentSection === "Additional Attributes" &&
                                <AdditionalAttributesForm
                                    //Common Form
                                    service_Provider={this.props.service_Provider}
                                    handleChange={this.props.handleChange}
                                    handleSelectionChange={this.props.handleSelectionChange}
                                    submitted={this.props.submitted}
                                    editOrganization={this.props.editOrganization}
                                    handleEditCategorySelection={this.props.handleEditCategorySelection}
                                    handleEditCategoryInput={this.props.handleEditCategoryInput}
                                    checkboxes={this.props.checkboxes}
                                    handleCheckbox={this.props.handleCheckbox}
                                    handleDefaultCheckbox={this.props.handleDefaultCheckbox}
                                    editErrors={this.props.editErrors}
                                    category_TagsError={this.props.category_TagsError}
                                    handleEditChange={this.props.handleEditChange}
                                    editTextInput={this.props.editTextInput}
                                    //Roles Form
                                    isEmptyCheckbox={this.props.isEmptyCheckbox}
                                    //Expertise Form
                                    foundation_Expertise_List={this.props.foundation_Expertise_List}
                                    agency_Expertise_List={this.props.agency_Expertise_List}
                                    program_Expertise_List={this.props.program_Expertise_List}
                                    stringFoundationTypes={this.props.stringFoundationTypes}
                                    stringAgencyTypes={this.props.stringAgencyTypes}
                                    stringProgramTypes={this.props.stringProgramTypes}
                                    //Focus Form
                                    industry_Focus_List={this.props.industry_Focus_List}
                                    industry_Service_List={this.props.industry_Service_List}
                                    stringFocusTypes={this.props.stringFocusTypes}
                                    stringServiceTypes={this.props.stringServiceTypes}
                                />
                            }
                            {currentSection === "Advanced Attributes" &&
                                <AdvancedAttributesForm
                                    org={org}
                                    handleChange={this.props.handleChange}
                                    handleSelectionChange={this.props.handleSelectionChange}
                                    handleCategoryInput={this.props.handleCategoryInput}
                                    handleCategorySelection={this.props.handleCategorySelection}
                                />
                            }
                            {currentSection === "Members" &&
                                <MembersForm
                                    user={this.state.user}
                                    invite_emailInput={this.props.invite_emailInput}
                                    accepted_profiles={this.props.accepted_profiles}
                                    accepted_profile_list={edit_accepted_profile_list}
                                    pending_profiles={this.props.pending_profiles}
                                    pending_profile_list={pending_profile_list}
                                    pending_invitation_profiles={this.props.pending_invitation_profiles}
                                    pending_invitation_list={pending_invitation_list}
                                    transfer={this.props.transfer}
                                    handleNewOrganization={this.props.handleNewOrganization}
                                    inviteToJoinOrganization={this.props.inviteToJoinOrganization}
                                    org={org}
                                />
                            }
                        </ModalBody>

                        <ModalFooter className="centerFlex flexColumn" style={{ padding: "20px 30px" }}>
                            {currentSection !== "Members" &&
                                <Button className="submitButton" onClick={(e) => this.handleSubmit(e)}>
                                    Save Changes
                                </Button>
                            }
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedOrganizationViewEdit = connect(mapStateToProps)(OrganizationViewEdit);
export { connectedOrganizationViewEdit as OrganizationViewEdit };