import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { sharedService } from './shared.service';

export const locationsService = {
    getLocations
};

function getLocations(location_type) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(location_type)
    };

    return fetch(API_Route + '/api/locations/get-locations', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}