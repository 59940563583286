import React from 'react'
import OnboardingBase from '../../Components/OnboardingBase'

const Onboarding_Welcome = () => {
  return (
    <OnboardingBase hideGuyCharacter={true} hideGirlCharacter={true}>
      <div className='align-items-center text-center'>
        <h1 className='fw-bold'>Hello and welcome to <span className='text-primary fw-bold'>OpenGrants</span></h1>
      </div>
    </OnboardingBase>
  )
}

export default Onboarding_Welcome