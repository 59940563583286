import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import AlluraParty from '../../_assets/img/AlluraParty.png';
import { history } from '../../_helpers';
import { pluginService } from '../../_services';

export function CongratulationsModal({
  isOpen,
  toggleCongratsModal,
}) {
  const hrefToDashboard = () => {
    history.push(pluginService.redirect('/dashboard'));
  }

  let accentColor = pluginService.getIFrameColor()

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => toggleCongratsModal()}
      fade={false}
      className="ModalDiv"
      size='lg'
    >
      <ModalHeader className="row" toggle={() => toggleCongratsModal()} style={{ borderBottom: "none" }} />
      <ModalBody className="congratsBody">
        <img className="congratsBackground" style={{ paddingTop: 0 }} src={AlluraParty} alt="" />
        <div className="congratsText">
          <div className="row center">
            <h1 className="congratsHeader">
              Nice Work!
            </h1>
          </div>
          <div className="row center">
            <p className="congratsDescription">
              The project owner will review your application and profile, and you will be notified if selected. Keep an eye on your messages because they might ask for more information.
            </p>
          </div>
          <div className="row-auto center" style={{ position: 'relative' }}>
            <button
              onClick={() => hrefToDashboard()}
              className="defaultGreenButton"
              style={{ padding: "10px 20px", marginTop: "2.5%", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Back To Dashboard <i className="icon-arrow-right" />
            </button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter style={{ borderTop: "none" }} />
    </Modal>
  )
}