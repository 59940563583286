import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/GrantWriters.css';
import "../../Styles/MarketplaceProject.css";
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import { history } from '../../_helpers';
import { asyncLocalStorage, pluginService } from '../../_services';
import { ChangeProfilePlan } from "../Stripe/Stripe_Copy/Plans";
import { Account_v2 } from "./Account_v2";
import { Billing_v2 } from "./Billing_v2";
import Perks from "./Perks";
import { ProfileViewEdit_v2 } from './ProfileViewEdit_v2';
import { Col, Row } from 'reactstrap';
import BannerGraphic from "../../_assets/img/banner-graphic.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

let accentColor = pluginService.getIFrameColor()

class ProfileViewNav_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_Tab_Val: null,
      billingPage: "billingPage",
      username: window.location.pathname.split('/').pop(),
      iFrameUrl: null,
    }

    this.MarketplaceProjectFunc = this.MarketplaceProjectFunc.bind(this);
  }

  conversationsClient = "";

  componentDidMount = async () => {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameUrl: value?.url })
    });
    //is current user
    if ((this.state.username == encodeURI(this.props.user.userName)) || this.state.username === "profiles") {

      const active_Tab = window.location.href.split("tab=")[1];

      let active_Tab_Val = 0;

      if (active_Tab) {

        if (active_Tab === "billing") {
          active_Tab_Val = 1;
        }
        if (active_Tab === "account") {
          active_Tab_Val = 2;
        }
        if (active_Tab === "perks") {
          active_Tab_Val = 3;
        }
      }

      this.setState({
        active_Tab_Val
      });
    }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  changeView = (view) => {
    this.setState({
      view
    });
  }

  open_chat = (organization_Id) => {
    let open_chat = this.state.open_chat;
    if (open_chat) {
      organization_Id = null;
    }

    this.setState({
      open_chat: !open_chat,
      organization_Id
    });
  }

  updatePage = (page) => {
    this.setState({
      active_Tab_Val: 1,
      billingPage: page,
    });
  }

  MarketplaceProjectFunc() {
    const { active_Tab_Val, billingPage } = this.state;

    const classes = useStyles();
    const [value, setValue] = React.useState(active_Tab_Val);

    const handleChange = (event, newValue) => {
      // window.location.href = user.userName + this.state.iFrameUrl;
      setValue(newValue);

      if (newValue === 0) {
        history.push(pluginService.redirect(window.location.pathname + "?tab=profile"))
      }
      else if (newValue === 1) {
        history.push(pluginService.redirect(window.location.pathname + "?tab=billing"))
      }
      else if (newValue === 2) {
        history.push(pluginService.redirect(window.location.pathname + "?tab=account"))
      }
      else {
        history.push(pluginService.redirect(window.location.pathname + "?tab=perks"))
      }
    };

    return (
      <div className={classes.root} style={{ minHeight: 500 }}>
        <div style={{ marginTop: '1%' }}>
          <div className="row">
            {/* back to billing btn */}
            <div className="col-md-8 payment-form-col">
              <p className="acceptingProjectHeader">User Profile</p>
            </div>
          </div>
          <div className="projectNavigation" style={{ padding: '0px' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
              TabIndicatorProps={{ style: { backgroundColor: accentColor ? accentColor : '#00c58e' } }}
            >
              <LinkTab label="Profile" href="/profile-info" {...a11yProps(1)} />
              <LinkTab label="Billing" href="/profile-billing" {...a11yProps(2)} />
              <LinkTab label="Account" href="/profile-account" {...a11yProps(3)} />
              <LinkTab label="Perks" href="/profile-perks" {...a11yProps(4)} />
            </Tabs>
          </div>
          <br />
        </div>

        <TabPanel value={value} index={0}>
          <br />
          <ProfileViewEdit_v2 />

          <br />
          <br />
          {!this.props.service_Provider && !this.state.iFrameUrl &&
            <Row className="bg-white border border-primary rounded-10 shadow p-56 mb-48 text-secondary">
              <Col>
                <h1>Are you a Grant Consultant?</h1>
                <p> If you're an expert grant writer or grant-related consultant, and want to be listed in our Talent Finder, get started here. </p>
                <a className="banner-cta text-secondary" href={pluginService.redirect("/service-provider-onboarding")}>
                  Apply Now <i class="icon-arrow-right" />
                </a>
              </Col>
              <Col className="col-auto text-end">
                <img src={BannerGraphic} alt="" style={{ maxHeight: 150 }} />
              </Col>
            </Row>
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {value === 1 && this.state.billingPage === "choosePlan" ?
            <strong><div onClick={() => this.updatePage("billingPage")} className="backToBillingBtn"><i class="icon-arrow-left" />Back to Billing</div></strong>
            :
            null
          }
          {billingPage === "billingPage" &&
            <Billing_v2
              updatePage={(page) => this.updatePage(page)}
            />
          }
          {billingPage === "choosePlan" &&
            <ChangeProfilePlan
              updatePage={(page) => this.updatePage(page)}
            />
          }

        </TabPanel>
        <TabPanel value={value} index={2}>
          <Account_v2 />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Perks />
        </TabPanel>

      </div >
    );
  }

  render() {
    const { active_Tab_Val } = this.state;
    return (
      <div>
        {(active_Tab_Val || active_Tab_Val === 0) &&
          <this.MarketplaceProjectFunc
          />
        }
        {!active_Tab_Val && active_Tab_Val !== 0 &&
          <ProfileViewEdit_v2 />
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;

  return {
    user,
    service_Provider
  };
}

const connectedProfileViewNav_v2 = connect(mapStateToProps)(ProfileViewNav_v2);
export { connectedProfileViewNav_v2 as ProfileViewNav_v2 };
