import { marketplaceConstants } from '../_constants';
import { marketplaceService } from '../_services';

export const marketplaceActions = {
    updateToS,
};

function updateToS(user) {
    return async dispatch => {
        dispatch(request());
        await marketplaceService.updateToS(user)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: marketplaceConstants.UPDATEPROFILE_REQUEST } }
    function success(user) { return { type: marketplaceConstants.UPDATEPROFILE_SUCCESS, user } }
    function failure(error) { return { type: marketplaceConstants.UPDATEPROFILE_FAILURE, error } }
}
