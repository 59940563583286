import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import { pluginService } from '../../../_services/shared.service.js';

let accentColor = pluginService.getIFrameColor()

class PendingInvitationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { user,
            profile, transfer,
        } = this.props;
        const { organization } = this.props.org;

        return (
            <div key={profile.email} className={`dFlex spaceBtw flexWrap memberListForm`} >
                {profile.name ?
                    <div className="dFlex">
                        {!profile.headshot ?
                            <div className="pfpCircle centerFlex" id="noPfpCircle" style={{ backgroundColor: accentColor || '#0C7069' }}>{profile.surname[0]}</div>
                            :
                            <img src={profile.headshot} className="pfpCircle" alt="profile circle"/>
                        }
                        <a href={pluginService.redirect("/profiles/" + profile.username)} target={pluginService.isIFrame() ? "_self" : "_blank"} rel="noopener noreferrer" className="centerFlex">
                            <div className="memberText">
                                <h4 className="boxSubtitle noMargin orgPageUserName">{profile.name} {profile.surname} {profile.owner ? "(owner)" : ""} </h4>
                                <p className="boxText noMargin orgPageUserTitle">{profile.title}</p>
                            </div>
                        </a>
                    </div>
                    :
                    <div className="dFlex">
                        <div className="pfpCircle centerFlex" id="noPfpCircle" style={{ backgroundColor: accentColor || '#0C7069' }}>{profile.email[0]}</div>
                        <div className="memberText centerFlex">
                            <h4 className="boxSubtitle noMargin orgPageUserName">{profile.email}</h4>
                        </div>
                    </div>
                }

                {!transfer &&
                    <div className="dFlex memberButtons">
                        <button
                            className="defaultWhiteButton"
                            onClick={() => { this.props.cancelOrganizationInvitation(organization.organization_Id, user.id, profile.email) }}
                            style={{ height: 36, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                        >
                            Cancel
                        </button>
                    </div>
                }

            </div>

        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedPendingInvitationList = connect(mapStateToProps)(PendingInvitationList);
export { connectedPendingInvitationList as PendingInvitationList };
