import React from 'react'
import { Row } from 'react-bootstrap'
import OnboardingBase from '../../Components/OnboardingBase'
import { Loader_v2 } from '../../../Shared/Loader/Loader_v2'

const Onboarding_Loading = () => {
  const chatQuoteOptions = [
    'Great, we have started the Search Process.',
    'It’s getting close, we’re almost there!',
    '',
  ]

  const chatQuoteOptions2 = [
    'Here we go!',
    'This should take around 30 second to find your matches',
    'Just a little longer...',
  ]

  return (
    <OnboardingBase
      guyPosition={'guy-position-3'}
      girlPosition={'girl-position-3'}
      chatQuoteOptions={chatQuoteOptions}
      chatQuoteOptions2={chatQuoteOptions2}
    >
      <Row className='align-items-center text-center row mx-auto border border-light-gray bg-white p-32'>
        <h1 className='fw-bold text-secondary mb-40'>Searching the database</h1>
        <Loader_v2 />
      </Row>
    </OnboardingBase >
  )
}

export default Onboarding_Loading