import React from 'react'
import OnboardingBase from '../../Components/OnboardingBase'
import { useHistory } from 'react-router-dom'

const Onboarding_Complete = ({ grantMatchesLength, backToDescription }) => {
  const history = useHistory();

  const chatQuoteOptions = grantMatchesLength && ['IT’S DONE!']

  const chatQuoteOptions2 = grantMatchesLength && ['YAY!']

  return (
    <OnboardingBase
      guyPosition={'guy-position-3'}
      girlPosition={'girl-position-3'}
      chatQuoteOptions={chatQuoteOptions}
      chatQuoteOptions2={chatQuoteOptions2}
    >
      <div className='align-items-center text-center mx-auto border border-light-gray bg-white py-64 px-128'>
        {grantMatchesLength
          ? (<>
            <h1 className='fw-bold text-secondary mb-8'>Our search is complete!</h1>
            <i className='mb-24 text-secondary d-block fs-3'>We have found {grantMatchesLength} matching grants</i>
            <button
              className="fw-bold bg-primary-dark text-white rounded-10 py-16 px-40 mx-auto fs-4"
              onClick={() => history.replace('/dashboard')}>
              Go To My Matches
            </button>
          </>)
          : (<>
            <h1 className='fw-bold text-secondary mb-8'>We have found 0 matches.</h1>
            <i className='mb-24 text-secondary d-block fs-3'>Try again with a different description.</i>
            <button
              className="fw-bold bg-primary-dark text-white rounded-10 py-16 px-40 mx-auto fs-4"
              onClick={backToDescription}>
              Try again
            </button>
            <div className='text-center mt-32'>
              <a className="text-decoration-underline fw-bold text-secondary fs-4"
                onClick={() => history.replace('/dashboard')}>
                I know this is an important step, but I want to skip this for now!
              </a>
            </div>
          </>)
        }
      </div>
    </OnboardingBase >
  )
}

export default Onboarding_Complete