import React from "react";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import "../../../Styles/Login_v2.css";
import googleIcon from "../../../_assets/img/icons/google-icon.svg";

class GoogleLoginPage extends React.Component {

  render() {
    return (
      <div>
        <GoogleLogin
          clientId="1034864638994-1vgens45tqsu9h85uh7db9kiec1hpmi7.apps.googleusercontent.com"
          render={(renderProps) => (
            <div>
              <button
                className="google-login-button"
                onClick={renderProps.onClick}
                disabled={this.props.disabled}
                style={{ background: "none" }}
              >
                <img src={googleIcon} alt="" style={{ paddingRight: "10px" }} />
                Google
              </button>
              {this.props.disabled === true && (
                <p className="login-error" style={{ textAlign: "center" }} >
                  Google login is not available in Incognito mode.
                </p>
              )}
            </div>
          )}
          buttonText="Login"
          onSuccess={this.props.handleSubmit}
          onFailure={this.props.handleSubmit}
          cookiePolicy={"single_host_origin"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedGoogleLoginPage = connect(mapStateToProps)(GoogleLoginPage);
export { connectedGoogleLoginPage as GoogleLoginPage };

