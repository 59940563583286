import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from '../../../_helpers';
import { asyncLocalStorage, pluginService } from '../../../_services';

class ForgotPasswordConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            iFrameConfig: null
        }
    }

    componentDidMount() {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
    }

    redirectToLogin() {
        history.push("/login")
    }

    render() {

        const { iFrameConfig } = this.state

        return (

            <div id="forgotPasswordConfirmation">
                <div id="forgotPassowrdRightLogin">
                    <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="logo" className="login-logo forgot-pswd-logo" style={iFrameConfig?.public ? { width: "40%", marginBottom: 40 } : { width: "70%", marginBottom: 40 }} />
                    <hr />
                    <br />
                    {this.props.isGoogleAcc ?
                        <div>
                            <h3 className="forgot-pswd-heading">Thank you. This email exists with a Google account, please sign in using the "Google" button. </h3>
                        </div>
                        :
                        <h3 className="forgot-pswd-heading">Thank you. If the email you entered exists, a password reset link has been emailed to you. If you do not receive an email, please check your entry and try again.</h3>
                    }
                    <br />
                    <strong><a href={pluginService.redirect("/login")}><i class="icon-arrow-left" />Back to Login</a></strong>
                </div>

                {!iFrameConfig?.public &&
                    <div className="forgotPasswordBottomLinks">
                        <div>
                            <div className="forgotPasswordLinks">
                                <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                                <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                                <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                                <a href={pluginService.redirect("/login")}>Login</a>
                            </div>
                            <div className="forgotPasswordCopyFooter">
                                opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const {forgotPassword} = state;
    return {
        // forgotPassword
    };
}

const connectedForgotPasswordConfirmation = connect(mapStateToProps)(ForgotPasswordConfirmation);
export { connectedForgotPasswordConfirmation as ForgotPasswordConfirmation };

