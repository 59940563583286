import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/SettingsStyles/Billings.css';
import '../../../../Styles/SettingsStyles/OrganizationViewEdit.css';
import '../../../../Styles/SettingsStyles/ProfileViewEdit.css';
import SlateTextDisplay from '../../../../_components/dashboardComponents/CustomInput/SlateTextDisplay';
import SlateTextEditor from "../../../../_components/dashboardComponents/CustomInput/SlateTextEditor";
import { pluginService, serviceProviderService, sharedService } from '../../../../_services';
import Select from 'react-select';

const customSelectStyle = {
  control: (styles) => ({
    ...styles,
    border: "2px ridge",
    backgroundColor: "none",
    boxShadow: "none",
    borderRadius: 5,
    height: 44,
    '&:hover': {
      border: "2px ridge"
    },
  })
};

let accentColor = pluginService.getIFrameColor()

class EditSPProjectDetails_v2 extends React.Component {
  state = {
    proposal: {
      ...this.props.proposals[0],
      proposal_Scope: this.props.proposals[0]?.proposal_Scope.includes('[{') ? JSON.parse(this.props.proposals[0].proposal_Scope) : this.props.proposals[0].proposal_Scope,
      proposal_Additional_Information: this.props.proposals[0].proposal_Additional_Information.includes('[{') ? JSON.parse(this.props.proposals[0].proposal_Additional_Information) : this.props.proposals[0].proposal_Additional_Information
    },
    proposal_view: 'list_view',
    client_Org: this.props.project?.client_Organization?.organization_Id,
    errors: {},
    submitted: false,
    can_update_proposal_status: false,
    // modify_Proposal: this.props.proposals[0].project_Status == "Created" || this.props.proposals[0].project_Status == "Pending" ? true : false,
    display_payment_form: false,
    loading: false,
    selectedRate: { value: "hourlyRate", label: "Hourly Rate" },
  }

  async componentDidMount() {
    await this.formatDeliverables();

    this.setState({
      originalProposal: this.state.proposal,
      selectedRate: this.state.proposal.project_Flat_Rate ? { value: "flatRate", label: "Flat Rate" } : { value: "hourlyRate", label: "Hourly Rate" }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.proposal.project_Status && this.props.proposal.project_Status !== this.state.proposal.project_Status) {
      this.setState({
        proposal: {
          ...this.state.proposal,
          project_Status: this.props.proposal.project_Status
        }
      })
    }
  }

  formatDeliverables = () => {
    const { proposal } = this.state

    if (typeof (proposal.proposal_Additional_Information) === "string") {

      let childrenArray = [{ text: proposal.proposal_Additional_Information }]
      let proposalArray = [{ type: "paragraph", children: childrenArray }]

      this.setState({
        proposal: {
          ...this.state.proposal,
          proposal_Additional_Information: proposalArray
        }
      })
    }
  }

  handle_input = (e) => {
    const { project_Hourly_Rate, project_Total_Hours } = this.state.proposal;
    let { project_Total_Cost } = this.state.proposal;

    let name = e.target.name;
    let value = e.target.value;

    if ((name === "project_Hourly_Rate" && project_Total_Hours) || (name === "project_Total_Hours" && project_Hourly_Rate)) {
      if (name === "project_Hourly_Rate") {
        project_Total_Cost = value * project_Total_Hours;
      }
      else {
        project_Total_Cost = project_Hourly_Rate * value;
      }
    }
    if (name === "project_Flat_Rate") {
      project_Total_Cost = Number(value);
    }

    this.setState({
      proposal: {
        ...this.state.proposal,
        [name]: value,
        project_Total_Cost
      }
    });
  }

  handleFormattedInput = (name, value) => {
    this.setState({
      proposal: {
        ...this.state.proposal,
        [name]: value,
      }
    });
  }

  handle_submit = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();

    const { proposal_Title, proposal_Scope } = this.state.proposal;

    let errors = {
      title_error: false,
      scope_error: false,
    };

    var form_is_valid = true;
    if (!proposal_Title) {
      errors.title_error = true;
      form_is_valid = false;
    }

    if (!proposal_Scope || proposal_Scope.length === 0 || proposal_Scope[0].children[0].text === "") {
      errors.scope_error = true;
      form_is_valid = false;
    }

    if (form_is_valid) {
      var user = JSON.parse(localStorage.getItem('user'));

      let proposal = Object.assign({}, this.state.proposal);
      proposal.proposal_Scope = JSON.stringify(this.state.proposal.proposal_Scope);
      proposal.proposal_Additional_Information = JSON.stringify(this.state.proposal.proposal_Additional_Information)
      proposal["service_Provider_User_ID"] = user.id;
      proposal["project_ID"] = this.props.project.project.project_Id;

      await serviceProviderService.updateProposal(proposal);
      // window.location.href = "/sp/projects/" + this.props.project.project.project_Id + "?val=proposals";
      // this.changeView('list_view');

      this.setState({
        originalProposal: this.state.proposal,
        proposal_view: "list_view"
      })
    }

    this.setState({
      submitted: true,
      errors,
      // proposal_view: 'list_view'
    });
  }

  changeView = (proposal_view) => {
    this.setState({
      proposal: {
        ...this.state.proposal,
        // proposal_Scope: this.props.proposals[0].proposal_Scope.includes('[{') ? JSON.parse(this.props.proposals[0].proposal_Scope) : this.props.proposals[0].proposal_Scope,
        // proposal_Additional_Information: this.props.proposals[0].proposal_Additional_Information.includes('[{') ? JSON.parse(this.props.proposals[0].proposal_Additional_Information) : this.props.proposals[0].proposal_Additional_Information
      },
      proposal_view
    }, () => {
      this.formatDeliverables();
    });
  }

  //format date for date input field 
  formatDate = (date) => {
    let newDate = new Date(date);
    return newDate.toISOString().split('T')[0]
  }

  handleSelectedRate = (e) => {

    if (e.value === "hourlyRate") {
      this.setState({
        proposal: {
          ...this.state.proposal,
          project_Flat_Rate: 0,
          project_Total_Cost: 0.0,
        }
      })

    }
    else {
      this.setState({
        proposal: {
          ...this.state.proposal,
          project_Hourly_Rate: 0,
          project_Total_Hours: 0,
          project_Total_Cost: 0.0,
        }
      })
    }

    this.setState({
      selectedRate: e
    })
  }

  render() {
    const { proposal, errors, proposal_view, selectedRate } = this.state;
    const { type } = this.props;

    // EDIT
    if (proposal_view === 'edit') {
      return (
        <form>
          <div className="viewProjectHeader" style={{ display: "flex", justifyContent: 'space-between' }}>
            <p className="acceptingProjectHeader">Project Details</p>
            <div style={{ display: 'flex', color: '#0565AA', alignItems: 'center', cursor: 'pointer', marginRight: '20%' }} onClick={this.handle_submit}>
              <i className="icon-pencil-icon" />
              <div style={{ paddingLeft: 5, fontSize: 16 }}>Update</div>
            </div>
          </div>

          {/* SCOPE */}
          <div className="projectProposalField">
            <h3>Scope of Project <strong><span style={{ color: "red" }}>*</span></strong></h3>
            <p>
              Thoroughly describe the work that will be done during this project.
              Include why the work is necessary and how it will support the desired outcomes,
              especially if it’s work that does not include a deliverable.
              This includes research, writing, editing, outreach, etc.
            </p>
            <SlateTextEditor
              name="proposal_Scope"
              handleFormattedInput={this.handleFormattedInput}
              savedText={proposal.proposal_Scope}
            />
            {errors.scope_error &&
              <p className="input_error">This is required</p>
            }
          </div>

          {/* DELIVERABLES */}
          <div className="projectProposalField">
            <h3>Deliverables</h3>
            <p>
              Explain the milestones and/or deliverables your client can expect from you.
              This may be a shortlist of grants, drafts of applications, research reports, etc.
              If possible, include rough dates of when your client can expect to receive each deliverable.
            </p>
            <SlateTextEditor
              name="proposal_Additional_Information"
              handleFormattedInput={this.handleFormattedInput}
              savedText={proposal.proposal_Additional_Information}
            />
          </div>

          {/* COMPLETION DATE */}
          <div className="projectProposalField">
            <h3>Project Completion Date</h3>
            <p>Not required if your project is ongoing</p>
            <input
              className="proposalInput"
              name="project_Completion_Date"
              value={proposal.project_Completion_Date ? this.formatDate(proposal.project_Completion_Date) : ""}
              onChange={this.handle_input}
              type="date"
            />
            {errors.completion_date_error &&
              <p class="input_error">This is required</p>
            }
          </div>

          <div className='projectProposalWrapper'>

            {/* RATE SELECTION */}
            <div className="projectProposalField" style={{ flex: 1 }}>
              <h3>Project Rate</h3>
              <p>Please select hourly or flat project rate.</p>
              <Select
                value={selectedRate}
                onChange={this.handleSelectedRate}
                options={[{ value: "hourlyRate", label: "Hourly Rate" }, { value: "flatRate", label: "Flat Rate" }]}
                styles={customSelectStyle}
              />
            </div>

            {selectedRate.value === "hourlyRate" &&
              <>
                {/* HOURLY RATE */}
                <div className="projectProposalField" style={{ flex: 1 }}>
                  <h3>Project Hourly Rate</h3>
                  <p>This will be used to calculate the total project cost.</p>
                  <input
                    className="proposalInput"
                    name="project_Hourly_Rate"
                    value={proposal.project_Hourly_Rate}
                    onChange={this.handle_input}
                    type="number"
                  />
                  {errors.hourly_rate_error &&
                    <p class="input_error">This is required</p>
                  }
                </div>

                {/* TOTAL HOURS */}
                <div className="projectProposalField" style={{ flex: 1 }}>
                  <h3>Project Total Hours</h3>
                  <p>If relevant estimate the total hours to complete your project</p>
                  <input
                    className="proposalInput"
                    name="project_Total_Hours"
                    value={proposal.project_Total_Hours}
                    onChange={this.handle_input}
                    type="number"
                  />
                  {errors.total_hours_error &&
                    <p class="input_error">This is required</p>
                  }
                </div>
              </>
            }

            {/* FLAT RATE */}
            {selectedRate.value === "flatRate" &&
              <div className="projectProposalField" style={{ flex: 1 }}>
                <h3>Project Flat Rate</h3>
                <p>Flat rate projects are billed immediately and held in escrow. You get paid when the project is complete.</p>
                <input
                  className="proposalInput"
                  name="project_Flat_Rate"
                  value={proposal.project_Flat_Rate}
                  onChange={this.handle_input}
                />
                {errors.flat_rate_error &&
                  <p className="red-message">You may only enter positive integers.</p>
                }
              </div>
            }

          </div>

          {/* TOTAL COST */}
          <div className="projectProposalField">
            <h3>Project Total Cost</h3>
            <p>
              <strong>
                $ {proposal && proposal.project_Total_Cost ? proposal.project_Total_Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"} USD
              </strong>
            </p>
          </div>

          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                type="submit"
                onClick={this.handle_submit}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                <i className="icon-ok" />
                Update
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                type="button"
                className="gc_started-button-next"
                onClick={() => { this.setState({ errors: {}, proposal: this.state.originalProposal }, () => { this.changeView('list_view') }) }}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                <i className="icon-cancel" />
                Cancel
              </button>
            </div>
          </div>
        </form>

      );
    }
    return (
      //DISPLAY
      <>
        <div className="viewProjectHeader" style={{ display: "flex", justifyContent: 'space-between', marginBottom: 15 }}>
          <p className="acceptingProjectHeader">Project Details</p>
          {type !== "client" && this.state.proposal.project_Status !== "Accepted" &&
            <div style={{ display: 'flex', color: '#0565AA', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.setState({ proposal_view: 'edit' })}>
              <i className="icon-pencil-icon" />
              <div style={{ fontSize: 16 }}>Edit</div>
            </div>
          }
        </div>

        {/* SCOPE */}
        <div className="row paymentRow">
          <div className="col-md-3 flexColEnd">
            Scope of Project
          </div>
          <div className="col-md-6 spFormattedTextContainer">
            <SlateTextDisplay text={JSON.stringify(proposal.proposal_Scope) || 'N/A'} />
          </div>
        </div>

        {/* DELIVERABLES */}
        <div className="row paymentRow">
          <div className="col-md-3 flexColEnd">
            Deliverables
          </div>
          <div className="col-md-6">
            {proposal.proposal_Additional_Information && typeof (proposal.proposal_Additional_Information) !== "string" && proposal.proposal_Additional_Information.length !== 0 && proposal.proposal_Additional_Information[0].children[0].text !== "" ?
              <SlateTextDisplay text={JSON.stringify(proposal.proposal_Additional_Information)} />
              :
              "N/A"
            }
          </div>
        </div>

        {/* COMPLETION DATE */}
        <div className="row paymentRow">
          <div className="col-md-3 flexColEnd">
            Completion Date
          </div>
          <div className="col-md-5">
            {sharedService.trimDate(proposal.project_Completion_Date)}
          </div>
        </div>

        {(!proposal.project_Flat_Rate || proposal.project_Flat_Rate === 0) &&
          <>
            {/* HOURLY RATE */}
            <div className="row paymentRow">
              <div className="col-md-3 flexColEnd">
                Hourly Rate
              </div>
              <div className="col-md-5">
                {proposal.project_Hourly_Rate ? '$' + proposal.project_Hourly_Rate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD per Hour' : 'N/A'}
              </div>
            </div>

            {/* TOTAL HOURS */}
            <div className="row paymentRow">
              <div className="col-md-3 flexColEnd">
                Total Hours
              </div>
              <div className="col-md-5">
                {proposal.project_Total_Hours ? proposal.project_Total_Hours.toLocaleString(undefined, { maximumFractionDigits: 10 }) : 'N/A'}
              </div>
            </div>
          </>
        }

        {/* FLAT RATE */}
        {proposal.project_Flat_Rate &&
          <div className="row paymentRow">
            <div className="col-md-3 flexColEnd">
              Flat Rate
            </div>
            <div className="col-md-5">
              {proposal.project_Flat_Rate ? '$' + proposal.project_Flat_Rate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD' : 'N/A'}
            </div>
          </div>
        }

        {/* TOTAL COST */}
        <div className="row paymentRow">
          <div className="col-md-3 flexColEnd">
            Total Project Cost
          </div>
          <div className="col-md-5">
            {proposal.project_Total_Cost ? "$" + proposal.project_Total_Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " USD" : 'N/A'}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { billingInfo } = state;

  return ({
    billingInfo
  })
}

const connectedEditSPProjectDetails = connect(mapStateToProps)(EditSPProjectDetails_v2);
export { connectedEditSPProjectDetails as EditSPProjectDetails_v2 };

