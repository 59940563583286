//this file is no longer in use ?

import { listingConstants } from '../_constants';

export function listing(state = {}, action) {
  switch (action.type) {
    case listingConstants.GETLISTING_REQUEST:
      return {
        loading: true
      };
    case listingConstants.GETLISTING_SUCCESS:
      return {
        listing: action.listing
      };
    case listingConstants.GETLISTING_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}