import React from 'react';
import { connect } from 'react-redux';
import { ServiceProvidersMessages } from '.';
import '../../Styles/ServiceProvider.css';
import { authenticateActions, notificationsActions, serviceProviderActions } from '../../_actions';
import { asyncLocalStorage, chatService, serviceProviderService } from '../../_services';

class ServiceProvidersChatBox_v2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: 'initializing',
            prevPage: "",

            conversationProxy: this.props.conversationProxy,
            boundConversations: new Set(),

            newMessage: '',

            messages: [],
            messageInfo: {
                channel: null,
                last_Message: "",
                last_Message_User_Id: "",
            },

            messageError: false,

            tempMessages: [],
            sendClicked: false,
            resendClicked: false,

            show_dropdown: false,
            canSend: true, //used to mark if the first message was already sent

            disableSend: false,

            loadedMessageCount: 0,
            iFrameConfig: null,
        };
    }

    //load existing messages
    loadMessagesFor = (thisConversation) => {
        if (this.state.conversationProxy === thisConversation) {
            thisConversation.getMessages()
                .then(messagePaginator => {
                    if (this.state.conversationProxy === thisConversation) {
                        let prevPage = "";
                        if (messagePaginator.hasPrevPage) {
                            prevPage = messagePaginator.prevPage;
                        }
                        this.setState({
                            messages: messagePaginator.items,
                            loadingState: 'ready',
                            prevPage
                        });
                    }
                })
                .catch(err => {
                    console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    this.setState({
                        loadingState: "failed"
                    });
                });
        }
    };

    //load more messages
    loadMore = async () => {
        let { prevPage } = this.state;

        const messagePaginator = await prevPage();
        prevPage = "";
        if (messagePaginator.hasPrevPage) {
            prevPage = messagePaginator.prevPage;
        }
        this.setState({
            messages: [...messagePaginator.items, ...this.state.messages],
            loadingState: 'ready',
            prevPage,
            loadedMessageCount: messagePaginator.items.length
        });
    }

    componentDidMount = async () => {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
        if (this.state.conversationProxy) {
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {

                let newConversation = this.state.conversationProxy;

                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));

                this.setState({
                    boundConversations: new Set([...this.state.boundConversations,
                        newConversation])
                });
            }
        }

        if (!this.props.email_notification_settings) {
            this.props.dispatch(notificationsActions.get_email_notification_settings(this.props.user.id));
        }

        const objDiv = document.getElementById('messageWindowBody');
        objDiv.scrollTop = objDiv.scrollHeight;

        //get message identity
        if (!this.props.user.message_Identity) {
            let result = await serviceProviderService.getMessageIdentity();

            if (!result.message_identity) {
                return;
            }

            let message_identity = result.message_identity;
            let new_user = Object.assign({}, this.props.user);
            new_user.message_Identity = message_identity;
            this.props.dispatch(authenticateActions.setUser(new_user));
        }
    }

    componentDidUpdate = (oldProps, oldState) => {
        if (this.state.conversationProxy !== oldState.conversationProxy) {
            this.setState({
                messages: []
            });

            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {

                let newConversation = this.state.conversationProxy;

                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));

                this.setState({
                    boundConversations: new Set([...this.state.boundConversations, newConversation])
                });
            }
        }

        const objDiv = document.getElementById('messageWindowBody');
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    static getDerivedStateFromProps(newProps, oldState) {
        if ((oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy) {
            return {
                loadingState: 'loading messages',
                conversationProxy: newProps.conversationProxy
            };
        }
        else {
            return null;
        }
    }

    //add message to the chat box
    messageAdded = (message, targetConversation) => {

        const { user } = this.props

        if (targetConversation === this.state.conversationProxy) {

            this.setState((prevState, props) => ({
                messages: [...prevState.messages, message]
            }));
        }

        if (user.message_Identity && message.author != user.message_Identity) {
            const messageInfo = {
                channel: this.props.client.channel,
                last_Message_Read: new Date()
            }

            chatService.updateChatInfo(messageInfo);

        }
    };

    onMessageChanged = event => {
        this.setState({
            newMessage: event.target.value
        });
    };

    //after you click "send" in the chatbox
    onMessageSent = async () => {

        const { connectionStatus } = this.props
        let temp = ""
        if (this.state.newMessage && this.state.newMessage !== "") {
            this.setState({
                tempMessages: [...this.state.tempMessages, this.state.newMessage],
            })
            temp = this.state.newMessage
        }

        //message history does not exist
        if (!this.props.client.channel) {
            this.setState({
                disableSend: true
            })
            try {
                await this.props.prepareNewChat();
            }
            catch (error) {
                this.setState({
                    newMessage: "",
                    sendClicked: false,
                    disableSend: false
                })
            }
        }
        //message history exists
        else {
            if (connectionStatus === "success") {
                this.sendMessage(temp);
            }
            else {
                this.setState({
                    newMessage: "",
                    messageError: true,
                    sendClicked: false,
                })
            }
        }
    }

    //send a new message
    sendMessage = (messageToSend) => {

        const { user } = this.props

        let messages = [];

        if (messageToSend) {
            messages.push(messageToSend)
        }
        else {
            messages = this.state.tempMessages
        }

        messages.forEach((message) => {

            // const message = [{}];

            if (!message || message === "") {
                this.setState({
                    sendClicked: false
                })
                return
            }

            this.setState({
                disableSend: true
            })

            //send message in Twilio
            this.state.conversationProxy.sendMessage(message)

                //if successfully sent in Twilio, send to db
                .then(() => {

                    let filteredTemp = this.state.tempMessages.filter((msg) => (msg !== message))

                    this.setState({
                        newMessage: "",
                        disableSend: false,
                        messageInfo: {
                            channel: this.props.client.channel,
                            last_Message: message,
                            last_Message_User_Id: user.id,
                            user_Notified: false
                        },
                        sendClicked: false,
                        tempMessages: filteredTemp,
                        messageError: filteredTemp.length !== 0 ? true : false
                    })

                    chatService.updateChatInfo(this.state.messageInfo);

                    let client = Object.assign({}, this.props.client);
                    client.last_Message_User_Id = user.id;
                    client.last_Message = message;
                    client.last_Message_Sent = (new Date()).toISOString();

                    this.props.dispatch(serviceProviderActions.alertChannelUpdate(client));

                    // const { email_notification_settings } = this.props;
                    // if (email_notification_settings && email_notification_settings.all_Direct_Messages) {

                    //     // establish correct username
                    //     let user_name = client.user_Info ? client.user_Info.username : client.username;
                    //     chatService.send_email_notification_about_dm(user.id, user_name);
                    // }
                })

                //if didn't send message in Twilio, show error adn don't send message to db
                .catch(() => {
                    this.setState({
                        newMessage: "",
                        disableSend: false,
                        messageError: true,
                        sendClicked: false
                    })
                });

            this.setState({
                canSend: false,
                // disableSend: false,
            })
        })
    };

    handleInput = (e) => {
        let value = e.target.value;

        this.setState({
            [this.state.messageSwitch ? "newMessage2" : "newMessage"]: value
        });
    }

    show_dropdown = (e) => {
        if ((e.nativeEvent && e.nativeEvent.path[0].id === "chatboxDropdownButton") || (e.path && e.path[0].id === "chatboxDropdownButton")) {
            e.stopPropagation();
        }

        let show_dropdown = this.state.show_dropdown;

        if (!show_dropdown) {
            document.getElementById("chatboxDropdownButton").classList.add("dropdownButton_open");
            document.getElementById("messageWindow").addEventListener("click", this.show_dropdown);
        }
        else {
            document.getElementById("chatboxDropdownButton").classList.remove("dropdownButton_open");
            document.getElementById("messageWindow").removeEventListener("click", this.show_dropdown);
        }

        this.setState({
            show_dropdown: !show_dropdown
        });
    }

    handleInputFocus = (e) => {
        let el = e.target;
        el.classList.add("message_input_focus");
        el.classList.remove("message_input");

        const messageContainer = document.getElementById("messageWindowBody");
        messageContainer.classList.add("chatContainer_input_focus");
        messageContainer.classList.remove("chatContainer_input");
        messageContainer.scrollTo(0, messageContainer.scrollHeight);
    }

    render() {


        const { newMessage, messages, prevPage, conversationProxy, canSend, iFrameConfig } = this.state;
        const { client, newConversation } = this.props;

        return (
            <div>
                {/* Send a message after the new conversation connection has been established */}
                {newConversation && conversationProxy && canSend &&
                    <>
                        {this.sendMessage()}
                    </>
                }
                <div id="messageWindow" style={{ bottom: iFrameConfig?.url ? '100px' : '10px', lineHeight: 1.5, fontSize: 16, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >

                    {/* HEADER */}
                    <div id="messageWindowHeader" style={{ position: "relative" }}>

                        <div className="messageWindowHeaderContent">

                            <div style={{ display: "flex" }}>
                                <img
                                    src={(client.user_Info && client.user_Info.logo_URL) ? client.user_Info.logo_URL : "https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/alternate.png"}
                                    style={{ width: 50, height: 50 }}
                                    alt="organization logo"
                                />
                                {client &&
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p className='messengerWindowName'>{client.user_Info ? client.user_Info.name : client.name} {client.user_Info ? client.user_Info.surname : client.surname}</p>
                                        <p className='messengerWindowOrgName'> {client.user_Info ? client.user_Info.organization_Name : client.organization_Name}</p>
                                    </div>
                                }
                            </div>

                            {/* {this.props.service_Provider &&
                                <div className="messengerDropdownButton">
                                    <button
                                        style={{ padding: 10, backgroundColor: "#4161BB", border: "1px solid hsl(0,0%,80%)", borderRadius: 5, width: "fit-content", color: "white" }}
                                        onClick={() => { history.push(client.user_Info ? "/create-project?project_Client=" + client.user_Info.organization_Id : "/create-project"); }}
                                    >
                                        Start Project
                                    </button>
                                    {/* <ServiceProvidersChatBoxDropDown
                                    organization_Id={client.user_Info ? client.user_Info.organization_Id : ""}
                                    channel_type={this.props.channel_type}
                                    channel_id={this.props.client.channel}
                                    /> */}
                            {/* </div> */}
                            {/* }  */}

                            {/* <div style={{ marginTop: 5, marginLeft: 60 }}>
                                {this.props.service_Provider &&
                                    <a style={{ color: "white" }} onClick={() => { history.push(client.user_Info ? "/create-project?project_Client=" + client.user_Info.organization_Id : "/create-project"); }}>
                                        Ready to start getting paid? Start a project with this person!
                                    </a>
                                }
                            </div> */}
                        </div>

                        <button
                            id="closeChatButton"
                            onClick={this.props.closeChat}
                            style={{ width: 25, position: "absolute", top: "15px", right: "15px" }}
                        >
                            X
                        </button>

                    </div>

                    {/* BODY */}
                    <div id="messageWindowBody" style={{ height: iFrameConfig?.url ? '35vh' : '50vh' }}>
                        {(client && messages.length !== 0) ?
                            //already has message history
                            <ServiceProvidersMessages
                                messages={messages}
                                loadMore={prevPage ? this.loadMore : null}
                                channel={client}
                                tempMessages={this.state.tempMessages}
                                messageError={this.state.messageError}
                                sendMessage={this.sendMessage}
                                loadedMessageCount={this.state.loadedMessageCount}
                            />
                            :
                            //messaging for the first time
                            <ServiceProvidersMessages
                                tempMessages={this.state.tempMessages}
                                messageError={this.state.messageError}
                                prepareNewChat={this.props.prepareNewChat}
                                sendMessage={this.sendMessage}
                            />
                        }
                    </div>

                    {/* FOOTER */}
                    <div id="messageWindowFooter">
                        <textarea
                            class="chatboxMessageInputField"
                            placeholder="Get familiar first, then start a project and get to work..."
                            value={newMessage}
                            onChange={this.handleInput}
                            disabled={this.state.disableSend}
                            required
                            style={{ display: "inline-block", resize: "vertical", minHeight: 50, maxHeight: 200, fontSize: 14 }}
                        />
                        <input
                            disabled={this.state.disableSend || newMessage === ""}
                            className={`message_send ${(this.state.disableSend || newMessage === "") ? "message_send_disabled" : ""}`}
                            id="sendMessageButton"
                            type="button"
                            value={this.state.disableSend ? "Sending..." : "Send"}
                            onClick={() => { this.setState({ sendClicked: true }, () => { this.onMessageSent() }); }}
                        />
                        {/* {this.state.messageError &&
                            <p style={{ color: "red" }}>Failed to send message. Please try again. </p>
                        }
                        {!window.navigator.onLine &&
                            <p style={{ color: "red" }}>You are offline. Please check your internet connection. </p>
                        } */}
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { email_notification_settings } = state.get_email_notification_settings;
    let new_email_notification_settings = state.update_email_notification_settings.email_notification_settings;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    const { setUser } = state;
    const { user } = setUser;

    if (new_email_notification_settings) {
        email_notification_settings = new_email_notification_settings;
    }

    return {
        email_notification_settings,
        user,
        service_Provider
    }
}

const connectedServiceProvidersChatBox_v2 = connect(mapStateToProps)(ServiceProvidersChatBox_v2);
export { connectedServiceProvidersChatBox_v2 as ServiceProvidersChatBox_v2 };
