import { authHeader } from '../_helpers';
import { API_Route } from '../App/API_Route';
import { pluginService, sharedService } from './shared.service';

export const grantMatchesService = {
    getMatch,
    getAllMatches,
    regenerateEmbeddings,
    regenerateGrantMatches,
    updateMatchStatus,
};

function getMatch() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/grantmatching/get_matched_grant', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllMatches() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/grantmatching', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function regenerateEmbeddings() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/grantmatching/embeddings', requestOptions)
        .then(sharedService.handleResponse)
        .catch(() => { });
}

function regenerateGrantMatches() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/grantmatching', requestOptions)
        .then(sharedService.handleResponse)
        .catch(() => { });
}

function updateMatchStatus(grant_id, status) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(status)
    };

    return fetch(API_Route + "/api/grantmatching/update_matched_grant_status/" + grant_id + "?status=" + status, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}