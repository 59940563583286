import algoliasearch from 'algoliasearch';
import React from 'react';
import { connectStateResults, Hits, InstantSearch, Pagination, Panel, RefinementList, SearchBox, Stats } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import '../../Styles/Algolia.css';
import '../../Styles/MarketplaceSearch_v2/MarketplaceSearch_v2.css';
import '../../Styles/Pagination.css';
import '../../Styles/SearchListings.css';
import BannerGraphic2 from "../../_assets/img/Character1.svg";
import { asyncLocalStorage, pluginService, serviceProviderService, sharedService } from '../../_services';
import CustomCurrentRefinements from "../SearchListings/CurrentRefinements";
import CustomRangeInput from "../SearchListings/CustomRangeInput";
import { Loader as Loading } from '../Shared/Loader';
import { MarketplaceResult } from './MarketplaceResult';
import { upgradeActions } from '../../_actions';

const searchClient = algoliasearch('MWF97BUAPS', '943f0970ab9c9143012e9172983aacdc');

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class MarketplaceSearch_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Service_Providers: null,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
      currentSearchState: {},
      mobileMarketplaceSearch: false,
      width: window.innerWidth,
      clearMinMax: false,
      iFrameConfig: null,
      hideBanner: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.MarketplaceResult = this.MarketplaceResult.bind(this);
  }

  async componentDidMount() {
    const total_Service_Providers = await serviceProviderService.getServiceProviderCount();
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `.searchBoxCustom .ais-SearchBox-submit { background-color: ${value.primary || '#0C7069'}; border: 1px solid ${value.primary || '#0C7069'} }`;

      document.getElementsByClassName('searchBoxCustom')[0].appendChild(style);
    });

    this.setState({
      total_Service_Providers: total_Service_Providers,
      loading: false,
    });

    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async toggle() {
    this.setState({
      mobileMarketplaceSearch: !this.state.mobileMarketplaceSearch
    });
  }

  // async goToUpgrade() {
  //   window.location.href="/settings?val=billing";
  // }

  async updateCurrentSearch(searchState) {
    this.setState({
      currentSearchState: searchState
    });
  };

  handleMinMax = (boolValue) => {
    this.setState({
      clearMinMax: boolValue
    })
  }

  MarketplaceResult(service_Provider) {
    document.body.scrollTo(0, 0);
    // alert(JSON.stringify(service_Provider));
    return <MarketplaceResult service_Provider={service_Provider} />
  };

  //Fix label format
  formatLabel = (item) => {

    //replace underscores with spaces
    let label = item.label.replaceAll("_", " ")

    //capitalize first letter of each word
    label = label.toLowerCase().split(" ").map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(" ");

    return label
  }

  render() {
    const { user } = this.props;
    const { width, mobileMarketplaceSearch, clearMinMax } = this.state;

    const MarketplaceResults = connectStateResults(
      ({ searchState, searchResults, children }) =>
        searchResults && searchResults.nbHits !== 0 ? (children) : (
          this.state.loading == false &&
          <div id="noSearchResults">
            <h4>No results found for <strong>{searchState.query}</strong>.</h4>
            <hr align="left" style={{ width: "10%" }} />
            <p>Please enter another query for a program, category, location or agency.</p>
          </div>
        )
    );

    if (this.state.loading) {
      return (
        <Loading />
      )
    }
    return (
      <div id="searchListingsSearchBar">
        {/* <MarketplaceToS user={user} /> */}

        <h1 id="TalentFinderHeader" className='mb-40'>Search</h1>
        <InstantSearch
          searchClient={searchClient}
          indexName="OpenGrants_ServiceProviders"
          searchState={this.state.currentSearchState}
          searchAsYouType={true}
          onSearchStateChange={searchState => {
            this.updateCurrentSearch(searchState);
          }}
        >
          <div className="row  flex-xl-row-reverse">
            <div className="col-12 col-xl-4 mb-24">
              {width < 1199 &&
                <div
                  id="MarketplaceSearchMobileToggle"
                  onClick={() => this.toggle()}
                  style={{ margin: "15px 0", width: "100%", marginLeft: 0 }}
                >
                  <h4 id="mobileSearchFilters">
                    ADVANCED FILTERS
                  </h4>
                  {mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9650;</h4>}
                  {!mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9660;</h4>}
                </div>
              }
              {(width > 1199 || mobileMarketplaceSearch) &&
                <div
                  id="filterBox"
                  style={{ padding: 15 }}
                >
                  <h1 id="searchHeader">Filters</h1>
                  <br />
                  <br />
                  <Panel header="Applied Filters">
                    <CustomCurrentRefinements handleMinMax={this.handleMinMax} />
                  </Panel>
                  <br /><br />
                  <Panel header="Hourly Rate ($)">
                    <CustomRangeInput
                      min={0}
                      max={100000000000}
                      attribute="base_Hourly_Rate"
                      clearMinMax={clearMinMax}
                      handleMinMax={this.handleMinMax}
                    />
                    {/* <RangeInput min={0} max={100000000000} attribute="base_Hourly_Rate" /> */}
                  </Panel>
                  <br /><br />
                  <Panel header="Industries">
                    <RefinementList
                      attribute="sP_Industry_Focus_Tags"
                      searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search industry types...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Services">
                    <RefinementList
                      attribute="sP_Industry_Services_Tags"
                      searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search service types...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Roles">
                    <RefinementList
                      attribute="sP_Role_Tags"
                      // searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search service types...',
                      }}
                      transformItems={items =>
                        items.map(item => ({
                          ...item,
                          label: this.formatLabel(item),
                        }))
                      }
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Grant Types">
                    <RefinementList
                      attribute="gW_GrantType_Tags"
                      // searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search grant types...',
                      }}
                      transformItems={items =>
                        items.map(item => ({
                          ...item,
                          label: this.formatLabel(item),
                        }))
                      }
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="US States">
                    <RefinementList
                      attribute="state"
                      searchable={true}
                      limit={3}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search States...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                </div>
              }
            </div>
            <div className="col-12 col-xl-8">
              <div className='grant-search'>
                <div className='grant-search-bar-div'>
                  <SearchBox
                    autoFocus={true}
                    searchAsYouType={false}
                    showLoadingIndicator={true}
                    translations={{ placeholder: 'Search by name, focus/service areas or grant types...' }}
                    className="searchBoxCustom"
                  />
                </div>
                {/* <div className='voice-search-div'>
                <VoiceSearch />
              </div> */}
              </div>
              <Stats />
              <br />
              <MarketplaceResults>
                <Hits
                  hitComponent={this.MarketplaceResult}
                  style={{ width: "100%!important" }}
                  id="searchHits"
                />
              </MarketplaceResults>
              <Pagination
                // Optional parameters
                defaultRefinement={1}
                showFirst={true}
                showPrevious={true}
                showNext={true}
                showLast={true}
                padding={width > 350 ? (width > 450 ? (width > 550 ? (width > 650 ? 5 : 3) : 2) : 1) : 0}
              />
            </div>
          </div>
        </InstantSearch>
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;


  return {
    user,
    service_Provider
  };
}

const connectedAlgoliaPage = connect(mapStateToProps)(MarketplaceSearch_v2);
export { connectedAlgoliaPage as MarketplaceSearch_v2 };

