import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationWOB extends React.Component {

    render() {
        const { org } = this.props;

        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel">Are You a Woman Owned Business?</h4>
                <p className="inputFieldSubLabel">
                    The Women-Owned Small Business (WOSB) Federal Contract Program allows set-asides for WOSBs in industries where firms are underrepresented. WOSBs must be at least 51% owned and controlled by women. Apply for certification
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        name="woman_Owned"
                        defaultValue={org.organization.woman_Owned === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "woman_Owned")}
                        className="orgPageTextField"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationWOB = connect(mapStateToProps)(EditOrganizationWOB);
export { connectedEditOrganizationWOB as EditOrganizationWOB };
