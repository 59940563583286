import React from 'react';
import { connect } from 'react-redux';
import { pluginService } from '../../../../_services';
import { Loader as Loading } from '../../../Shared/Loader';
import * as Payout_Accounts from './SP_Payout_Account_Currencies';

let accentColor = pluginService.getIFrameColor()

class SP_Payout_Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit_Payout_Method: false,
            loading: true,
        };

        this.handleChange = this.handleChange.bind(this);
        // this.updatePaymentAccount = this.updatePaymentAccount.bind(this);
        this.editPayoutMethod = this.editPayoutMethod.bind(this);
    }

    async componentDidMount() { }

    editPayoutMethod() {
        this.setState({
            edit_Payout_Method: true,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { bank_Account } = this.state;

        this.setState({
            bank_Account: {
                ...bank_Account,
                [name]: value
            }
        });
    }

    // async updatePaymentAccount() {
    //     this.setState({ loading: true });

    //     // const stripe = await loadStripe(window.stripeKey);
    //     const stripe = await loadStripe(window.stripeKey);
    //     const { service_Provider, stripe_Verified } = this.props;
    //     const { bank_Account } = this.state;

    //     await stripe.createToken('bank_account', {
    //             country: stripe_Verified.country,
    //             currency: stripe_Verified.currency,
    //             routing_number: bank_Account.routing_Number,
    //             account_number: bank_Account.account_Number,
    //             account_holder_name: bank_Account.account_Holder_Name,
    //             account_holder_type: bank_Account.account_Holder_Type,
    //         })
    //         .then(async (result) => {
    //             await stripeService.updateStripeConnectPaymentAccount(service_Provider.stripe_Account_Id, result.token.id);
    //             window.location.reload();
    //         });      
    // }

    display_payout_account = () => {
        if (this.props.stripe_Verified) {
            var currency = this.props.stripe_Verified.currency;
            if (currency !== "usd" && currency !== "cad") {
                currency = "usd";
            }

            var Payout_Account = Payout_Accounts["SP_Payout_Account_" + currency]
            return (
                <Payout_Account
                    handleChange={this.handleChange}
                    handleClosing={this.props.handleClosing}
                // updatePaymentAccount={this.updatePaymentAccount}
                />
            )
        }
    }

    render() {
        const { stripe_Verified, loading } = this.props;
        const { edit_Payout_Method } = this.state;
        let stripe_Payout_Account = null;
        if (stripe_Verified && stripe_Verified.external_Accounts) {
            stripe_Payout_Account = stripe_Verified.external_Accounts.data[0];
        }

        return (
            <div className="payout-methods-card">
                {loading &&
                    <Loading />
                }
                {!stripe_Payout_Account && edit_Payout_Method === false &&
                    <div>
                        <h1>Payout Methods</h1>
                        <hr />
                        <p>You do not have any payout methods on file.</p>
                        <br />
                        <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={this.editPayoutMethod}>New Payout Method</button>
                    </div>
                }
                {(stripe_Payout_Account || edit_Payout_Method) && stripe_Verified && this.display_payout_account()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;
    const { stripe_Verified, loading } = state.stripeVerified;
    return {
        user,
        service_Provider,
        stripe_Verified,
        loading
    };
}

const connectedSP_Payout_Account = connect(mapStateToProps)(SP_Payout_Account);
export { connectedSP_Payout_Account as SP_Payout_Account };

