import React from 'react';
import { connect } from 'react-redux';
import { setTimeout } from 'timers';
import "../../../Styles/editAdditionalSettings.css";
import '../../../Styles/Organization.css';
import { organizationActions } from '../../../_actions';
import { locationsService, marketplaceService, organizationService, serviceProviderService, settingsService } from '../../../_services';
import { Loader as Loading } from '../../Shared/Loader';
import { OrganizationSettings } from './OrganizationSettings';
import { OrganizationViewEdit } from "./OrganizationViewEdit";

class Organization extends React.Component {
    constructor(props) {
        super(props);

        const { org_id } = this.props;

        this.state = {
            submitted: false,
            submitting: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user')),

            org_id: org_id,

            org: {
                organization: {
                    industries: [],
                    social_Impact_Categories: [],
                    technologies_Used: [],
                    naicS_Codes: []
                },
                organizationMembers: []
            },
            originalOrg: {},
            originalName: "",

            organizations: {},
            owned_organizations: 0,
            addOrganization: false,
            editOrganization: false,
            viewOrganization: 0,
            organizationNameError: false,

            organization_namesInput: "",
            newOrganization: false,
            invite_emailInput: "",
            transfer: false,
            loading: true,

            service_Provider: {},
            industry_Focus_List: "",
            industry_Service_List: "",
            foundation_Expertise_List: "",
            agency_Expertise_List: "",
            program_Expertise_List: "",

            checkboxes: {
                roles: {
                    grant_Writer: false,
                    startup_Advisor: false,
                    technical_Consultant: false,
                    accounting_Professional: false,
                    attorney: false,
                    generalist: false,
                    non_Profit_Advisor: false,
                },
                grants: {
                    foundation_Grants: false,
                    government_Grants: false,
                },
                additional: {
                    office_Hours: false,
                }
            },

            stringFoundationTypes: [],
            stringAgencyTypes: [],
            stringProgramTypes: [],
            stringFocusTypes: [],
            stringServiceTypes: [],

            editErrors: {
                emptyRoles: false,
                emptyGrantType: false,
            },

            editTextInput: {
                Foundational_Expertise_Description: "",
                Agency_Expertise_Description: "",
                Program_Expertise_Description: "",
                Base_Hourly_Rate: "",
                References: "",
                Booking_Link: ""
            },

            service_ProviderForm: {
                checkboxes: {},
                editTextInput: {},
                stringFoundationTypes: [],
                stringAgencyTypes: [],
                stringProgramTypes: [],
                stringFocusTypes: [],
                stringServiceTypes: [],
            },

            currentlyAvailable: true,

            submitSuccess: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateOrganizationName = this.updateOrganizationName.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getAllOrganizationsByUser();
        this.getAllOrganizationInvitations();
        this.getList();
        this.getServiceProvider();
    }

    resetOrgState = () => {
        const { service_ProviderForm } = this.state

        this.setState({
            organization_namesInput: this.state.originalName,
            org: this.state.originalOrg,
            checkboxes: service_ProviderForm.checkboxes,
            editTextInput: service_ProviderForm.editTextInput,
            stringFoundationTypes: service_ProviderForm.stringFocusTypes,
            stringAgencyTypes: service_ProviderForm.stringAgencyTypes,
            stringProgramTypes: service_ProviderForm.stringProgramTypes,
            stringFocusTypes: service_ProviderForm.stringFocusTypes,
            stringServiceTypes: service_ProviderForm.stringServiceTypes,
            editErrors: {
                emptyRoles: false,
                emptyGrantType: false,
            },
            submitted: false,
            submitSuccess: false,
        })
    }

    getAllOrganizationsByUser = async () => {
        const { user } = this.state;
        let organization_id = window.location.pathname.split('/').pop()
        var organizations = await organizationService.getAllOrganizationsByUser(user.id);
        this.setState({
            ...organizations,
            loading: false
        }, () => {
            if (organization_id > 0) {
                this.editOrganization(organization_id);
            }
        });
    }

    getAllOrganizationInvitations = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        var pending_invitations = await organizationService.getUserOrganizationPendingInvitations(user.id);

        this.setState({
            pending_invitations
        });
    }

    getAddresses = async () => {
        var city = await locationsService.getLocations('City');
        var state = await locationsService.getLocations('US_State');
        // var country = await locationsService.getLocations('Country_Stripe');
        var country = ["United States", "Canada"];

        this.setState({
            city,
            state,
            country
        })
    }

    getIndustries = async () => {
        const { user } = this.state;
        var industries = await organizationService.getIndustries(user.id);

        this.setState({
            industries
        });
    }

    getSocialImpactCategories = async () => {
        const { user } = this.state;
        var social_Impact_Categories = await organizationService.getSocialImpactCategories(user.id);

        this.setState({
            social_Impact_Categories
        });
    }

    getTechnologies = async () => {
        const { user } = this.state;
        var technologies_Used = await organizationService.getTechnologies(user.id);

        this.setState({
            technologies_Used
        });
    }

    getNAICSCodes = async () => {
        const { user } = this.state;
        var naicS_Codes = await organizationService.getNAICSCodes(user.id);

        this.setState({
            naicS_Codes
        });
    }

    getOrganizationMembers = async (organization_id) => {
        const { user } = this.state;
        var members = await organizationService.getOrganizationMembers(user.id, organization_id);
        this.setState({
            ...members
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { org } = this.state;
        const { organization } = org;

        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    [name]: value
                }
            }
        });
    }

    handleNewOrganization = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    async updateOrganizationName(event) {
        const { value } = event.target;
        this.setState({
            organization_namesInput: value,
        });

        const organizationNameError = await organizationService.checkOrganizationName(value);

        if (organizationNameError && this.state.organization_namesInput !== this.state.originalName) {
            this.setState({
                organizationNameError: true
            });
        }
        else {
            this.setState({
                organizationNameError: false
            })
        }
    }

    handleSelectionChange(e, name) {
        let temp = (e.target.value == "true")

        const { org } = this.state;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...org.organization,
                    [name]: temp
                }
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({
            submitting: true,
            // organizationNameError: false, 
            organizationCityError: false,
            organizationStateError: false,
            organizationCountryError: false,
            updatedErrorAlert: false,
            loading: true,
        });

        let organizationStateError = false;
        let organizationCountryError = false;

        const { user, organizations, organization_namesInput, organizationNameError, editOrganization, newOrganization,
            state, country, originalOrg,
            website_is_required, logo_url_is_required, address_is_required, description_is_required
        } = this.state;
        let { org, owned_organizations } = this.state;

        //Update org name if different and valid
        if (!organizationNameError) {
            org.organization.name = organization_namesInput;
        }

        let state_input = org.organization.state;
        if (state_input) {

            let result = state.filter((s) => s.toLowerCase() === state_input.toLowerCase());
            if (result.length > 0) {
                org.organization.state = result[0];
            }
            else {
                organizationStateError = true;
            }
        }

        let country_input = org.organization.country;
        if (country_input) {
            let result = country.filter((c) => c.toLowerCase() === country_input.toLowerCase());
            if (result.length > 0) {
                org.organization.country = result[0];

                if (org.organization.country !== "United States") {
                    org.organization.address = '';
                    org.organization.city = '';
                    org.organization.state = '';
                    org.organization.zipCode = '';
                }
            }
            else {
                organizationCountryError = true;
            }
        }

        // //fix for accounts where website and description are not required
        // if(!org.organization.website_URL){
        //     org.organization.website_URL = "N/A"
        // }

        // set org logo URL
        org.organization.logo_URL = localStorage.getItem('organizationLogo');


        if ((organization_namesInput === org.organization.name || !organizationNameError) &&
            org.organization.name !== "" &&
            ((!address_is_required && !org.organization.country) ||
                (!organizationStateError &&
                    !organizationCountryError &&
                    org.organization.country &&
                    (org.organization.country !== "United States" ||
                        (org.organization.country === "United States" &&
                            (org.organization.address && !(org.organization.address.toLowerCase().includes("p.o. box")
                                || org.organization.address.toLowerCase().includes("po box"))) &&
                            org.organization.city &&
                            org.organization.state &&
                            org.organization.zipCode
                            // &&
                            // sharedService.is5Integers(org.organization.zipCode)
                        )))) &&
            (!website_is_required || org.organization.website_URL) &&
            (!description_is_required || org.organization.description) &&
            (!logo_url_is_required || org.organization.logo_URL)
        ) {

            //set org logo
            org.organization.logo_URL = localStorage.getItem('organizationLogo');
            //set email invites
            org["emailInvites"] = this.state.emailInvites;

            //reset org values for backend
            org.organization.industries = [];
            org.organization.social_Impact_Categories = [];
            org.organization.technologies_Used = [];
            org.organization.naicS_Codes = [];

            //add/edit organization

            try {
                org = await organizationService.createOrganization(user.id, org)

                //edit organization
                if (editOrganization) {
                    this.setState({
                        organizations: {
                            ...organizations,
                            [org.organization.organization_Id]: {
                                ...org
                            }
                        },
                        originalOrg: {
                            ...originalOrg,
                            organization: org.organization
                        },
                        submitted: false,
                        submitting: false,
                        updatedAlert: true,
                        updatedErrorAlert: false,
                        submitSuccess: true,
                        originalName: org.organization.name
                    });
                }
                //new organization
                else if (newOrganization) {
                    this.props.dispatch(organizationActions.organizationAlert("created_organization"));

                    owned_organizations = owned_organizations + 1;
                    this.setState({
                        organizations: {
                            ...organizations,
                            [org.organization.organization_Id]: {
                                ...org
                            }
                        },
                        owned_organizations,
                        submitted: true,
                        submitting: false,
                        updatedAlert: true,
                        addOrganization: false,
                        editOrganization: false,
                        newOrganization: false,
                        organization_namesInput: '',
                        invite_emailInput: '',
                        updatedErrorAlert: false
                    });
                }
            }
            catch {
                this.setState({
                    updatedErrorAlert: true,
                })
            }
        }
        else {

            this.setState({
                submitting: false,
                organizationNameError,
                organizationStateError,
                organizationCountryError,
                submitted: true,
            });
        }

        this.setState({
            loading: false,
            //Reset original org to new
        })

        setTimeout(() => {
            this.setState({
                updatedAlert: false,
                updatedErrorAlert: false
            });
        }, 4000);

        document.body.scrollTo(0, 0);
    }

    changeSuccess = () => {
        this.setState({
            submitSuccess: false,
        })
    }

    addOrganization = async () => {
        this.setState({ loading: true });
        const { user } = this.state;

        // var organization_names = await organizationService.getAllOrganizationNames();
        var all_organizations = await organizationService.getAllOrganizations(user.id);
        var organization_names = Object.values(all_organizations).map((org) => {
            return (org.organization.name)
        });

        localStorage.setItem('organizationLogo', '');

        this.setState({
            addOrganization: true,
            editOrganization: false,
            viewOrganization: 0,
            all_organizations,
            organization_names,
            accepted_profiles: '',
            pending_profiles: '',
            pending_invitation_profiles: '',
            loading: false,
        });
        document.body.scrollTo(0, 0);
    }

    editOrganization = async (organization_id) => {
        const { org } = this.state

        this.getAddresses();

        //Lists of possible selections
        this.getIndustries();
        this.getSocialImpactCategories();
        this.getTechnologies();
        this.getNAICSCodes();
        this.getOrganizationMembers(organization_id);

        const { organizations } = this.state;
        org.organization["industries"] = org.industries;
        org.organization["social_Impact_Categories"] = org.social_Impact_Categories;
        org.organization["technologies_Used"] = org.technologies_Used;
        org.organization["naicS_Codes"] = org.naicS_Codes;

        //This call has the accepted profiles array
        let results = await organizationService.getOrganization(this.props.user.id, organization_id);
        //Your Organization
        if (organizations[organization_id]) {

            localStorage.setItem('organizationLogo', organizations[organization_id].organization.logo_URL);

            this.setState({
                org: organizations[organization_id],

                editOrganization: true,
                addOrganization: false,
                viewOrganization: 0,

            });
        }
        //Not Your Organization
        else {
            this.setState({
                org: results.org,
            })
        }

        this.setState({
            org: {
                ...this.state.org,
                organizationMembers: results.accepted_profiles
            },
            organization_namesInput: results.org.organization.name,

            website_is_required:
                this.props.service_Provider && org.organization.org_Type !== "Individual/Sole Proprietor"
                    ? true : false,
            logo_url_is_required: this.props.service_Provider ? true : false,
            description_is_required: this.props.service_Provider ? true : false,
            address_is_required:
                this.props.service_Provider || //is service provider
                    (org.org_Type && org.organization.org_Type !== "Individual/Sole Proprietor") //is not service provider and is not individual
                    ? true : false
        })

        this.setState({
            originalOrg: this.state.org,
            originalName: this.state.org.organization.name,
            service_ProviderForm: {
                checkboxes: this.state.checkboxes,
                editTextInput: this.state.editTextInput,
                stringFoundationTypes: this.state.stringFocusTypes,
                stringAgencyTypes: this.state.stringAgencyTypes,
                stringProgramTypes: this.state.stringProgramTypes,
                stringFocusTypes: this.state.stringFocusTypes,
                stringServiceTypes: this.state.stringServiceTypes,
            }
        })

        document.body.scrollTo(0, 0);
    }

    viewOrganization = (organization_id) => {
        this.setState({
            viewOrganization: organization_id
        });
        document.body.scrollTo(0, 0);
    }

    hideAddEditOrganization = () => {
        this.setState({
            addOrganization: false,
            editOrganization: false,
            viewOrganization: 0,
            newOrganization: false,
            organization_namesInput: "",
            invite_emailInput: ''
        });
        document.body.scrollTo(0, 0);
    }

    handleLocationInput = (categoryInputs) => {
        var key = Object.keys(categoryInputs)[0].split("Input")[0];
        var value = Object.values(categoryInputs)[0];

        this.setState({
            org: {
                ...this.state.org,
                organization: {
                    ...this.state.org.organization,
                    [key]: value
                }
            }
        });
    }

    handleLocationSelection = (categories) => {
        const { org } = this.state;
        const { organization } = org;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    ...categories
                }
            }
        })
    }

    newOrg = (org_id) => {
        this.setState({ loading: true });

        const { user, all_organizations, organization_namesInput } = this.state;
        var orgs = Object.values(all_organizations).filter(org => org.organization.name == organization_namesInput);
        if (!org_id) {
            this.getAddresses();
            this.getIndustries();
            this.getSocialImpactCategories();
            this.getTechnologies();
            this.getNAICSCodes();

            this.setState({
                org: {
                    organization: {
                        name: organization_namesInput,
                        owner: user.id,
                        industries: [],
                        social_Impact_Categories: [],
                        technologies_Used: [],
                        naicS_Codes: []
                    },
                    industries: [],
                    social_Impact_Categories: [],
                    technologies_Used: [],
                    naicS_Codes: []
                },
                emailInvites: [],
                newOrganization: true,
            });
        }
        else {
            const { user } = this.state;

            let organization_Id = org_id;
            if (!org_id) {
                organization_Id = orgs[0].organization.organization_Id;
            }

            let new_org_requests = Object.assign([], this.state.organization_requests);
            new_org_requests.push(all_organizations[org_id].organization);

            let all_orgs = Object.assign({}, this.state.all_organizations);
            all_orgs[organization_Id].pending.push(user.id);

            this.setState({
                all_organizations: {
                    ...all_orgs
                },
                organization_requests: [
                    ...new_org_requests
                ]
            });

            organizationService.requestToJoinOrganization(user.id, all_orgs[organization_Id].organization.name);

            document.body.scrollTo(0, 0);
        }
        this.setState({ loading: false });
    }

    removeSelfFromOrg = (organizations) => {
        this.setState({ organizations });
    }

    confirmOrganizationRequest = async (organization, user_id, username, confirmation) => {
        await organizationService.confirmOrganizationRequest(organization, user_id, username, confirmation);

        const { pending_profiles } = this.state;
        var new_pending_profiles = pending_profiles.filter(profile => profile.username != username);
        //add profile to members if accepted
        if (confirmation === "Accepted") {
            let new_accepted_profiles = Object.assign([], this.state.accepted_profiles);
            var new_accepted_profile = pending_profiles.filter(profile => profile.username == username);
            new_accepted_profiles.push(new_accepted_profile[0]);

            this.setState({
                accepted_profiles:
                    [...new_accepted_profiles],
                pending_profiles:
                    [...new_pending_profiles]
            });
        }
        //remove profile if rejected
        else if (confirmation === "Pending") {
            this.setState({
                pending_profiles:
                    [...new_pending_profiles]
            });
        } else if (confirmation === "Rejected") {
            var new_accepted_profiles = this.state.accepted_profiles.filter(profile => profile.username != username);

            this.setState({
                accepted_profiles:
                    [...new_accepted_profiles],
                pending_profiles:
                    [...new_pending_profiles]
            });
        }
    }

    cancelOrganizationInvitation = async (organization_id, user_id, email) => {
        const { pending_invitation_profiles, newOrganization } = this.state;

        if (!newOrganization)
            await organizationService.cancelOrganizationInvitation(organization_id, user_id, email);

        var new_pending_invitations = pending_invitation_profiles.filter(invitation => (invitation.email !== email));

        this.setState({
            pending_invitation_profiles: [...new_pending_invitations]
        });
    }

    updateOrganizationOwner = async (user_id, username, organization) => {
        await organizationService.updateOrganizationOwner(user_id, username, organization);

        const { user, accepted_profiles } = this.state;
        var new_accepted_profiles = accepted_profiles.filter(profile => profile.owner != true && profile.username != username);
        var old_owner = accepted_profiles.filter(profile => profile.username == user.userName);
        var new_owner = accepted_profiles.filter(profile => profile.username == username);

        old_owner[0].owner = false;
        new_owner[0].owner = true;

        new_accepted_profiles.push(old_owner[0]);
        new_accepted_profiles.push(new_owner[0]);

        this.setState({
            accepted_profiles: [...new_accepted_profiles],
            transfer: true
        });
    }

    inviteToJoinOrganization = async (user_id, organization_id, email) => {
        if (!email) {
            return;
        }
        const { newOrganization } = this.state;
        if (!newOrganization) {
            var invited_user = await organizationService.inviteToJoinOrganization(organization_id, user_id, email);

            var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
            if (invited_user) {
                new_pending_invitation_profiles.push(invited_user);
            }
            this.setState({
                pending_invitation_profiles: [...new_pending_invitation_profiles],
                invite_emailInput: ''
            });
        } else {
            var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
            new_pending_invitation_profiles.push({ email: email });
            var emailInvites = Object.assign([], this.state.emailInvites);
            emailInvites.push(email);
            this.setState({
                emailInvites: [...emailInvites],
                pending_invitation_profiles: [...new_pending_invitation_profiles],
                invite_emailInput: ''
            });
        }
    }

    updateOrganizations = (organizations) => {
        const { user } = this.state;
        var owned_organizations = Object.values(organizations).filter((org) => org.organization.owner === user.id).length;

        this.setState({
            organizations,
            owned_organizations
        });
    }

    updateInvitations = (pending_invitations) => {
        this.setState({
            pending_invitations
        });
    }

    updateState = (newState) => {
        this.setState({
            ...newState
        });
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleCategorySelection = (categories) => {
        const { org } = this.state;
        const { organization } = org;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    ...categories
                },
                ...categories
            }
        })
    }

    //----------------------------------------------Edit Additional Functions---------------------------------------------

    //Implement a QOL - If user makes changes and tries to leave page ask if they want to save changes

    //------------------------------------------------------Handle Submit-------------------------------------------------

    handleAdditionalSubmit = async (event) => {
        event.preventDefault();

        const {
            service_Provider,
            stringFoundationTypes,
            stringAgencyTypes,
            stringProgramTypes,
            stringFocusTypes,
            stringServiceTypes,
            editErrors,
            editTextInput,
            checkboxes,
            currentlyAvailable,
            user
        } = this.state


        this.setState({
            updatedErrorAlert: false,
            submitting: true,
            loading: true,
        })

        let updatedServiceProvider = {};
        let error = false;

        //check for errors
        this.isEmptyCheckbox("roles", "emptyRoles")
        if (checkboxes.roles.grant_Writer) {
            this.isEmptyCheckbox("grants", "emptyGrantType")
        }

        for (let i in editErrors) {
            if (editErrors[i] === true) {
                error = true;
                break;
            }
        }

        //Check for errors
        if (error === false &&
            editTextInput.Base_Hourly_Rate !== "" &&
            // editTextInput.Booking_Link !== "" &&
            stringFocusTypes.length !== 0 &&
            stringServiceTypes.length !== 0
        ) {
            if (editTextInput.Booking_Link == "") {
                updatedServiceProvider.Booking_Link = "N/A"
            }
            else {
                updatedServiceProvider.Booking_Link = editTextInput.Booking_Link;
            }
            //setting fields to match modal in db
            updatedServiceProvider.Service_Provider_Id = service_Provider.service_Provider_Id
            updatedServiceProvider.Organization_Id = service_Provider.organization_Id
            updatedServiceProvider.Contact_Email = service_Provider.contact_Email
            updatedServiceProvider.Created_By_User_Id = user.id
            updatedServiceProvider.Opengrants_Verified = service_Provider.opengrants_Verified
            updatedServiceProvider.Stripe_Connect_Verified = service_Provider.stripe_Connect_Verified
            updatedServiceProvider.Date_Approved = service_Provider.date_Approved
            updatedServiceProvider.Base_Hourly_Rate = editTextInput.Base_Hourly_Rate;
            updatedServiceProvider.Currently_Available = service_Provider.currently_Available
            updatedServiceProvider.Service_Provider_Link = service_Provider.service_Provider_Link
            updatedServiceProvider.Opengrants_Terms_Conditions = service_Provider.opengrants_Terms_Conditions
            updatedServiceProvider.Office_Hours = checkboxes.additional.office_Hours;
            updatedServiceProvider.Date_Created = service_Provider.date_Created
            updatedServiceProvider.SP_Industry_Focus_Tags = stringFocusTypes;
            updatedServiceProvider.SP_Industry_Services_Tags = stringServiceTypes;
            updatedServiceProvider.References = editTextInput.References;
            updatedServiceProvider.Organization = service_Provider.organization
            updatedServiceProvider.currently_Available = currentlyAvailable

            updatedServiceProvider.SP_Role_Tags = [];
            updatedServiceProvider.GW_GrantType_Tags = [];

            Object.entries(checkboxes.roles).map(([key, value]) => {
                if (value == true) {
                    updatedServiceProvider.SP_Role_Tags.push(key);
                }
            })

            //if grant writer is not selected send empty fields and clear state
            if (checkboxes.roles.grant_Writer === false) {
                updatedServiceProvider.GW_GrantType_Tags = [];
                updatedServiceProvider.GW_Foundation_Tags = [];
                updatedServiceProvider.GW_Agency_Tags = [];
                updatedServiceProvider.GW_Program_Tags = [];
                updatedServiceProvider.Foundational_Expertise_Description = "";
                updatedServiceProvider.Agency_Expertise_Description = "";
                updatedServiceProvider.Program_Expertise_Description = "";
                this.setState({
                    stringFoundationTypes: [],
                    stringAgencyTypes: [],
                    stringProgramTypes: [],
                    editTextInput: {
                        ...editTextInput,
                        Foundational_Expertise_Description: "",
                        Agency_Expertise_Description: "",
                        Program_Expertise_Description: "",
                    },
                    checkboxes: {
                        ...checkboxes,
                        grants: {
                            foundation_Grants: false,
                            government_Grants: false,
                        },
                    }
                })
            }   //if grant writer is selected
            else {

                Object.entries(checkboxes.grants).map(([key, value]) => {
                    if (value === true) {
                        updatedServiceProvider.GW_GrantType_Tags.push(key);
                    }
                })
                //if foundation is selected
                if (updatedServiceProvider.GW_GrantType_Tags.includes("foundation_Grants")) {
                    updatedServiceProvider.Foundational_Expertise_Description = editTextInput.Foundational_Expertise_Description;
                    updatedServiceProvider.GW_Foundation_Tags = stringFoundationTypes
                }
                else {
                    updatedServiceProvider.Foundational_Expertise_Description = ""
                    updatedServiceProvider.GW_Foundation_Tags = []
                    this.setState({
                        stringFoundationTypes: [],
                        editTextInput: {
                            ...editTextInput,
                            Foundational_Expertise_Description: "",
                        },
                    })
                }
                //if government is selected
                if (updatedServiceProvider.GW_GrantType_Tags.includes("government_Grants")) {
                    updatedServiceProvider.GW_Agency_Tags = stringAgencyTypes;
                    updatedServiceProvider.Agency_Expertise_Description = editTextInput.Agency_Expertise_Description;
                    updatedServiceProvider.GW_Program_Tags = stringProgramTypes;
                    updatedServiceProvider.Program_Expertise_Description = editTextInput.Program_Expertise_Description;
                }
                else {
                    updatedServiceProvider.GW_Agency_Tags = [];
                    updatedServiceProvider.Agency_Expertise_Description = "";
                    updatedServiceProvider.GW_Program_Tags = [];
                    updatedServiceProvider.Program_Expertise_Description = "";
                    this.setState({
                        stringAgencyTypes: [],
                        stringProgramTypes: [],
                        editTextInput: {
                            ...editTextInput,
                            Agency_Expertise_Description: "",
                            Program_Expertise_Description: "",
                        },
                    })
                }
            }

            //service call
            let tempSp = await settingsService.updateServiceProvider(updatedServiceProvider);

            this.setState({
                submitted: false,
                submitting: false,
                updatedAlert: true,
                updatedErrorAlert: false,
                submitSuccess: true,
                service_Provider: tempSp
            })
        }
        else {
            this.setState({
                submitting: false,
                submitted: true
            });
        }

        this.setState({
            loading: false,
        })

        setTimeout(() => {
            this.setState({
                updatedAlert: false,
                updatedErrorAlert: false
            });
        }, 4000);

        document.body.scrollTo(0, 0);
    }

    //----------------------------------------------Getting Data and Setting State----------------------------------------

    // Get service provider here in the state and pass it down 
    getServiceProvider = async () => {
        const { user } = this.state;
        let data = await serviceProviderService.getServiceProvidersByUser(user.id);
        this.setState({
            service_Provider: data
        });

        this.addToState();
    }

    //Retrieve all the relevant tag lists for selection
    getList = async () => {
        const SP_Onboarding_Lists = await marketplaceService.getSPOnboardingLists();

        this.setState({
            industry_Focus_List: SP_Onboarding_Lists.industry_Focus,
            industry_Service_List: SP_Onboarding_Lists.industry_Service,
            foundation_Expertise_List: SP_Onboarding_Lists.foundation_Expertise,
            agency_Expertise_List: SP_Onboarding_Lists.gov_Agency_Expertise,
            program_Expertise_List: SP_Onboarding_Lists.program_Office_Expertise,
        })
    }

    // Converting data from api from array of objects to array of strings
    addToState = () => {
        let tempFoundation = [];
        let tempAgency = [];
        let tempProgram = [];
        let tempFocus = [];
        let tempService = [];

        if (this.state.service_Provider) {
            this.state.service_Provider.foundation_Types.forEach((item) => {
                tempFoundation.push(item.foundation_Type);
            })

            this.state.service_Provider.agency_Types.forEach((item) => {
                tempAgency.push(item.agency_Type);
            })

            this.state.service_Provider.program_Types.forEach((item) => {
                tempProgram.push(item.program_Type);
            })

            this.state.service_Provider.sP_Focus.forEach((item) => {
                tempFocus.push(item.focus_Type);
            })

            this.state.service_Provider.sP_Services.forEach((item) => {
                tempService.push(item.service_Type);
            })
        }

        this.setState({
            stringFoundationTypes: tempFoundation,
            stringAgencyTypes: tempAgency,
            stringProgramTypes: tempProgram,
            stringFocusTypes: tempFocus,
            stringServiceTypes: tempService,
            currentlyAvailable: this.state.service_Provider.currently_Available,

            editTextInput: {
                Foundational_Expertise_Description: this.state.service_Provider.foundational_Expertise_Description,
                Agency_Expertise_Description: this.state.service_Provider.agency_Expertise_Description,
                Program_Expertise_Description: this.state.service_Provider.program_Expertise_Description,
                Base_Hourly_Rate: this.state.service_Provider.base_Hourly_Rate,
                References: this.state.service_Provider.references,
                Booking_Link: this.state.service_Provider.booking_Link,
            }
        })
    }

    //----------------------------------------------Handling Checkboxes----------------------------------------------

    // Handle default role / grant type / office hour selection 
    handleDefaultCheckbox = (checkboxName, type) => {
        setTimeout(() => {
            this.setState({
                checkboxes: {
                    ...this.state.checkboxes,
                    [type]: {
                        ...this.state.checkboxes[type],
                        [checkboxName]: true
                    }
                }
            })
        }, 100)
    }

    // Handle checkbox selection on click 
    handleCheckbox = (e, type) => {
        let attribute = [e.target.id];
        this.setState({
            checkboxes: {
                ...this.state.checkboxes,
                [type]: {
                    ...this.state.checkboxes[type],
                    [attribute]: e.target.checked
                }
            }
        })
    }

    // Check if any of the checkboxes are empty
    isEmptyCheckbox = (type, errorName) => {

        let tempObject = this.state.checkboxes[type];
        let tempError = this.state.editErrors;

        for (let i in tempObject) {
            if (tempObject[i] === true) { //Not Empty
                tempError[errorName] = false;
                break;
            }
            else { //Is Empty   
                tempError[errorName] = true;
            }
        }

        this.setState({
            editErrors: {
                ...this.state.editErrors,
                [errorName]: tempError[errorName]
            }
        })
    }

    //----------------------------------------------Handling Selections----------------------------------------------

    // Handle selection change on click
    handleEditCategorySelection = (categories, stateName, tagName) => {
        this.setState({
            [stateName]: categories[tagName]
        })
    }

    //Function for auto-registration to work / No functionality
    handleEditCategoryInput = (categoryInputs) => {
    }

    //----------------------------------------------Handling Text Input----------------------------------------------

    // Handle text input fields on click 
    handleEditChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            editTextInput: {
                ...this.state.editTextInput,
                [name]: value
            }
        });
    }

    //----------------------------------------------Handling Switch----------------------------------------------

    handleSwitch = async (e) => {
        const { user } = this.state;
        let check = e.target.checked

        this.setState({
            [e.target.name]: check
        })

        //Service call to directly update availability, no need to hit submit to update status
        await settingsService.updateAvailability(user.id, check)
    }

    render() {
        const { loading } = this.state;

        return (
            <div>
                {loading &&
                    <Loading />
                }
                {window.location.pathname.includes("/organizations/") ?
                    <OrganizationViewEdit
                        // View Edit 
                        org={this.state.org}
                        resetOrgState={this.resetOrgState}
                        handleOrgSubmit={this.handleSubmit}
                        handleAdditionalSubmit={this.handleAdditionalSubmit}
                        service_Provider={this.state.service_Provider}
                        submitSuccess={this.state.submitSuccess}
                        changeSuccess={this.changeSuccess}
                        updatedAlert={this.state.updatedAlert}
                        updatedErrorAlert={this.state.updatedErrorAlert}
                        //Organization Form
                        submitted={this.state.submitted}
                        handleChange={this.handleChange}
                        // Main Organization Form
                        organizationNameError={this.state.organizationNameError}
                        organization_namesInput={this.state.organization_namesInput}
                        updateOrganizationName={this.updateOrganizationName}
                        currentlyAvailable={this.state.currentlyAvailable}
                        handleSwitch={this.handleSwitch}
                        // Info Organization Form
                        city={this.state.city}
                        state={this.state.state}
                        country={this.state.country}
                        organizationCityError={this.state.organizationCityError}
                        organizationStateError={this.state.organizationStateError}
                        organizationCountryError={this.state.organizationCountryError}
                        handleLocationSelection={this.handleLocationSelection}
                        handleLocationInput={this.handleLocationInput}
                        //Additional Attributes Form
                        handleSelectionChange={this.handleSelectionChange}
                        editOrganization={this.state.editOrganization}
                        handleEditCategorySelection={this.handleEditCategorySelection}
                        handleEditCategoryInput={this.handleEditCategoryInput}
                        checkboxes={this.state.checkboxes}
                        handleCheckbox={this.handleCheckbox}
                        handleDefaultCheckbox={this.handleDefaultCheckbox}
                        editErrors={this.state.editErrors}
                        category_TagsError={this.state.category_TagsError}
                        handleEditChange={this.handleEditChange}
                        editTextInput={this.state.editTextInput}
                        //Roles Form
                        isEmptyCheckbox={this.state.isEmptyCheckbox}
                        //Expertise Form
                        foundation_Expertise_List={this.state.foundation_Expertise_List}
                        agency_Expertise_List={this.state.agency_Expertise_List}
                        program_Expertise_List={this.state.program_Expertise_List}
                        stringFoundationTypes={this.state.stringFoundationTypes}
                        stringAgencyTypes={this.state.stringAgencyTypes}
                        stringProgramTypes={this.state.stringProgramTypes}
                        //Focus Form
                        industry_Focus_List={this.state.industry_Focus_List}
                        industry_Service_List={this.state.industry_Service_List}
                        stringFocusTypes={this.state.stringFocusTypes}
                        stringServiceTypes={this.state.stringServiceTypes}
                        //Advanced Attributes Form
                        handleCategoryInput={this.handleCategoryInput}
                        handleCategorySelection={this.handleCategorySelection}
                        //Members
                        invite_emailInput={this.state.invite_emailInput}
                        accepted_profiles={this.state.accepted_profiles}
                        pending_invitation_profiles={this.state.pending_invitation_profiles}
                        pending_profiles={this.state.pending_profiles}
                        transfer={this.state.transfer}
                        cancelOrganizationInvitation={this.cancelOrganizationInvitation}
                        handleNewOrganization={this.handleNewOrganization}
                        inviteToJoinOrganization={this.inviteToJoinOrganization}
                        updateOrganizationOwner={this.updateOrganizationOwner}
                        confirmOrganizationRequest={this.confirmOrganizationRequest}
                        website_is_required={this.state.website_is_required}
                        logo_url_is_required={this.state.logo_url_is_required}
                        address_is_required={this.state.address_is_required}
                        description_is_required={this.state.description_is_required}
                    />
                    :
                    <OrganizationSettings
                        user={this.state.user}
                        submitting={this.state.submitting}
                        submitted={this.state.submitted}
                        accepted_profiles={this.state.accepted_profiles}
                        updatedAlert={this.state.updatedAlert}
                        org={this.state.org}
                        organizations={this.state.organizations}
                        organization_requests={this.state.organization_requests}
                        owned_organizations={this.state.owned_organizations}
                        pending_invitations={this.state.pending_invitations}
                        addOrganization={this.state.addOrganization}
                        editOrganization={this.state.editOrganization}
                        viewOrganization={this.state.viewOrganization}
                        organization_names={this.state.organization_names}
                        newOrganization={this.state.newOrganization}
                        organization_namesInput={this.state.organization_namesInput}
                        all_organizations={this.state.all_organizations}
                        organizationNameError={this.state.organizationNameError}
                        organizationCityError={this.state.organizationCityError}
                        organizationStateError={this.state.organizationStateError}
                        organizationCountryError={this.state.organizationCountryError}
                        invite_emailInput={this.state.invite_emailInput}
                        pending_profiles={this.state.pending_profiles}
                        pending_invitation_profiles={this.state.pending_invitation_profiles}
                        transfer={this.state.transfer}
                        loading={this.state.loading}
                        city={this.state.city}
                        state={this.state.state}
                        country={this.state.country}
                        newOrganizationFunc={this.newOrg}
                        addOrganizationFunc={this.addOrganization}
                        editOrganizationFunc={this.editOrganization}
                        updateInvitations={this.updateInvitations}
                        updateOrganizationsFunc={this.updateOrganizations}
                        updateOrganizationName={this.updateOrganizationName}
                        updateOrganizationOwner={this.updateOrganizationOwner}
                        confirmOrganizationRequest={this.confirmOrganizationRequest}
                        cancelOrganizationInvitation={this.cancelOrganizationInvitation}
                        inviteToJoinOrganization={this.inviteToJoinOrganization}
                        removeSelfFromOrg={this.removeSelfFromOrg}
                        handleSubmit={this.handleSubmit}
                        handleNewOrganization={this.handleNewOrganization}
                        hideAddEditOrganization={this.hideAddEditOrganization}
                        handleChange={this.handleChange}
                        handleCategoryInput={this.handleCategoryInput}
                        handleCategorySelection={this.handleCategorySelection}
                        handleEditCategoryInput={this.handleEditCategoryInput}
                        handleEditCategorySelection={this.handleEditCategorySelection}
                        handleSelectionChange={this.handleSelectionChange}
                        handleLocationSelection={this.handleLocationSelection}
                        handleLocationInput={this.handleLocationInput}
                        updateState={this.updateState}
                        service_Provider={this.state.service_Provider}
                        industry_Focus_List={this.state.industry_Focus_List}
                        industry_Service_List={this.state.industry_Service_List}
                        foundation_Expertise_List={this.state.foundation_Expertise_List}
                        agency_Expertise_List={this.state.agency_Expertise_List}
                        program_Expertise_List={this.state.program_Expertise_List}
                        checkboxes={this.state.checkboxes}
                        handleCheckbox={this.handleCheckbox}
                        handleDefaultCheckbox={this.handleDefaultCheckbox}
                        stringFoundationTypes={this.state.stringFoundationTypes}
                        stringAgencyTypes={this.state.stringAgencyTypes}
                        stringProgramTypes={this.state.stringProgramTypes}
                        stringFocusTypes={this.state.stringFocusTypes}
                        stringServiceTypes={this.state.stringServiceTypes}
                        editErrors={this.state.editErrors}
                        isEmptyCheckbox={this.isEmptyCheckbox}
                        handleEditChange={this.handleEditChange}
                        editTextInput={this.state.editTextInput}
                        handleAdditionalSubmit={this.handleAdditionalSubmit}
                        handleSwitch={this.handleSwitch}
                        currentlyAvailable={this.state.currentlyAvailable}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    return {
        user,
        organization,
        service_Provider,
    };
}

const connectedOrganizationProfilePage = connect(mapStateToProps)(Organization);
export { connectedOrganizationProfilePage as Organization };
