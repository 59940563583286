import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../Styles/ServiceProvider_v2/Projects_v2.css';
import '../../../../Styles/SettingsStyles/Billings.css';
import { sharedService } from '../../../../_services';
import { PaymentHandler } from '../../../Stripe/Stripe_Copy/PaymentMethod';

class AcceptingModal extends Component {

  constructor() {
    super();
    this.state = {
      payment_submitted: false,
      deposits_submitted: false,
    };
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(window.stripeKey) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(window.stripeKey) });
      });
      document.querySelector('#stripe-js').addEventListener('error', () => {
        this.props.updateStripeError(true);
      });
    }
  }

  componentDidUpdate() {
    const { billingInfo_org } = this.props;

    //payment/address submitting 
    if (!this.state.payment_submitted && (billingInfo_org.loading_getbilling || billingInfo_org.loading_billingAddress)) {
      this.setState({
        payment_submitted: true
      })
    }
    //payment/address submission success
    else if (this.state.payment_submitted
      && !billingInfo_org.loading_getbilling
      && !billingInfo_org.loading_billingAddress) {
      //update success
      if (sharedService.hasValidPaymentMethod(billingInfo_org) //billing info successfully updated
      ) {
        this.openCongratulations();
      }

      this.setState({
        payment_submitted: false
      });
    }
  }

  openCongratulations = () => {
    this.props.handleSubmitFunction();
    this.props.openCongratulations();
    this.props.toggleAcceptModal();
  }

  toggleAcceptModal = () => {
    this.props.toggleAcceptModal();
  }

  render() {
    const { billingInfo_org, isAcceptOpen } = this.props;

    return (
      <div>
        <Modal
          isOpen={isAcceptOpen}
          centered={true}
          backdrop={true}
          toggle={() => this.toggleAcceptModal()}
          fade={false}
          size="lg"
        >
          <ModalHeader
            toggle={() => this.toggleAcceptModal()}
            style={{ border: 0, justifyContent: 'end' }}
          />
          <ModalBody className='modal-padding'>
            <form onSubmit={this.handleSubmit} style={{ padding: "0 20px", minHeight: this.props.proposal && this.props.project.project_Proposals[0].project_Flat_Rate ? 550 : 275 }}>
              {this.props.project.project_Proposals[0] && !this.props.project.project_Proposals[0].project_Flat_Rate &&
                <div>
                  <p>Please enter your payment information to accept the project. You will NOT be charged at this time. Actual costs may vary from the project estimate.</p>
                  <p>You'll receive an invoice on the Saturday of every week in which your consultant completed work. You will be charged for that invoice the following Thursday. If you have questions or concerns about the work being done, want to change the scope, or your budget has changed, please contact your grant consultant directly.</p>
                  <p style={{ color: '#0565AA' }}>It's your responsibility to communicate your needs with your grant consultant to avoid over-billing.</p>
                </div>}

              {isAcceptOpen &&
                <PaymentHandler
                  type={'org'}
                  org_Id={this.props.client_Org}
                  billingInfo={billingInfo_org}
                  cancelButton={this.toggleAcceptModal}
                  submitButton={(e) => { e.preventDefault(); this.openCongratulations() }}
                />}
            </form >
          </ModalBody >
        </Modal >
      </div >
    )
  }
}


function mapStateToProps(state) {
  const { user } = state.setUser;
  const { billingHandler } = state;

  return {
    user,
    billingHandler
  };
}

const connectedBilling = connect(mapStateToProps)(AcceptingModal);
export { connectedBilling as AcceptingModal };
