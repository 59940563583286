import React from 'react';

export const sharedService = {
    isInteger: (value) => {
        return value.toString().match("^[0-9]*$");
    },

    is5Integers: (value) => {
        return value.toString().match("^[0-9]{5}$");
    },

    isFloat: (value) => {
        return value.toString().match("^\\d*(\\.\\d*)?$");
    },

    isTypingFloat: (value) => {
        return value.toString().match("^\\d*(\\.\\d*)?$");
    },

    slateTextIsEmpty: (value) => {
        let isEmpty = true;
        for (let i = 0; i < value.length; i++) {
            for (let j = 0; j < value[i].children.length; j++) {
                if (value[i].children[j].children && value[i].children[j].children.length > 0) {
                    for (let k = 0; k < value[i].children[j].children.length; k++) {
                        if (value[i].children[j].children[k].text) {
                            isEmpty = false;
                            break;
                        }
                    }
                }
                else if (value[i].children[j].text) {
                    isEmpty = false;
                    break;
                }
            }

            if (!isEmpty) {
                break;
            }
        }

        return isEmpty;
    },

    hasPayoutMethod: (stripe_Verified) => {
        return (stripe_Verified
            && stripe_Verified.external_Accounts
            && stripe_Verified.external_Accounts.data.length > 0)
    },

    getPaymentSelection: (billingInfo) => {
        return ((!billingInfo || billingInfo.card?.cardNumber) ? 'card' :
            billingInfo.bank_Account?.account_Number ? 'ach_direct_debit' :
                'card')
    },

    hasPaymentMethod: (billingInfo) => {
        return (billingInfo?.card?.cardNumber
            || billingInfo?.bank_Account?.account_Number)
    },

    hasValidPaymentMethod: (billingInfo) => {
        return ((billingInfo.card?.cardNumber //is card
            // && hasBillingAddress(billingInfo) //has billing address
        )
            || (billingInfo.bank_Account?.account_Number //is bank
                && bankIsVerified(billingInfo.bank_Account))) //bank is verified
    },

    hasBank: (billingInfo) => {
        return (billingInfo.bank_Account?.account_Number)
    },

    hasCard: (billingInfo) => {
        return (billingInfo.card?.cardNumber)
    },

    bankIsVerified,

    hasBillingAddress,

    isSPVerified: (service_Provider, stripe_Verified) => {
        return (service_Provider && //is verified
            (service_Provider.opengrants_Verified //opengrants verified
                || isTestWebsite()
            )
            // && stripe_Verified
            // && stripe_Verified.status === "verified" //verified
            // && !stripe_Verified.account_Requirements.disabled_Reason) //requirement completed
        )
    },

    completedStripe: (stripe_Verified) => {
        return (stripe_Verified
            && stripe_Verified.status === "verified" //verifed
        )
    },

    isTestWebsite,

    getProjectStatus: (status, isClient) => {

        let project_Status = "";

        if (status === "active") {
            project_Status = <span className="proposalStatus green">{"Active"}</span>
        }
        else if (status === "pending_proposal_creation") {
            project_Status = <span className="proposalStatus blue">{"Pending proposal"}</span>
        }
        else if (status === "pending_proposal_review") {
            project_Status = <span className="proposalStatus blue" >{isClient ? "Pending your review" : "Pending review"}</span>
        }
        else if (status === "proposal_rejected") {
            project_Status = <span className="proposalStatus red">{"Proposal rejected"}</span>
        }
        else if (status === "proposal_pending_verification") {
            project_Status = <span className="proposalStatus blue">{"Pending verification"}</span>
        }
        else if (status === "completed" || status === "complete") {
            project_Status = <span className="proposalStatus green">{"Project completed"}</span>
        }
        else if (status === "cancel") {
            project_Status = <span className="proposalStatus red">{"Project canceled"}</span>
        }
        else if (status === "open" && isClient) {
            project_Status = <span className="proposalStatus" style={{ color: "rgb(12, 112, 105)" }}>{"Open"}</span>
        }
        else if (status === "open" && !isClient) {
            project_Status = <span className="proposalStatus" style={{ color: "rgb(12, 112, 105)" }}>{"Applied"}</span>
        }
        else if (status === "rejected") {
            project_Status = <span className="proposalStatus red">{"Application rejected"}</span>
        }

        return project_Status;
    },

    getProposalStatus: (status) => {
        let proposal_Status = "";

        if (status === "Created") {
            proposal_Status = <span className="proposalStatus blue">{"Waiting for review."}</span>
        }
        if (status === "Accepted") {
            proposal_Status = <span className="proposalStatus green">{"Project accepted."}</span>
        }
        if (status === "Rejected") {
            proposal_Status = <span className="proposalStatus red" >{"Project rejected."}</span>
        }
        if (status === "Pending") {
            proposal_Status = <span className="proposalStatus red" >{"Waiting for verification."}</span>
        }

        return proposal_Status;
    },

    trimDate: (date) => {
        if (!date) { return 'N/A' }

        return (date.split("T")[0])
    },

    getRedirect: (pathSplit) => {
        let isPath = false;
        let redirect = '';
        for (let i = 0; i < pathSplit.length; i++) {
            if (!isPath) {
                if (pathSplit[i] === "login" || pathSplit[i] === "register") {
                    isPath = true;
                }
                continue;
            }

            redirect += "/" + pathSplit[i];
        }

        return redirect;
    },

    generate_copy_link: (type, listing) => {
        if (!listing || !Object.keys(listing).length) {
            return;
        }

        let title = listing.title || listing.name;

        title = title.replaceAll(' ', '-');
        title = title.replaceAll(/&[0-9\@\.\;\&\#\+\(\)\,\" "]+;/g, '');
        title = title.replaceAll('|', '-');
        title = title.replaceAll('--', '-');

        const url_link = `https://www.opengrants.io/${type}-overview/` + title + "_" + (listing.listing_Id || listing.id || listing.objectID);
        return url_link;
    },

    generate_copy_link_sp: (service_Provider) => {

        if (!service_Provider) {
            return;
        }

        let name = service_Provider.organization.name;

        name = name.replaceAll(' ', '-');

        const url_link = "https://www.opengrants.io/grant-expert/" + name + "_" + service_Provider.service_Provider_Id;
        return url_link;
    },

    convertHTMLText,

    getVendorDoc: () => {
        window.open('https://opengrants-documents.s3.us-west-1.amazonaws.com/OpenGrants_Vendor_Set_Up.pdf', '_blank');
    },

    passwordSecure: (password, email) => {

        let emailName = email.split("@")[0]; //email without @
        let number = password.match(/\d+/g) //find numbers in password
        let charLength = 4; //number of characters to match for email name substring
        let numberLength = 2; //min number of integers to match for consecutive numbers 
        let secure = true;

        function checkConsecutive(numArr) {
            const differenceArray = numArr.slice(1).map(function (n, i) { return n - numArr[i]; });
            const isConsecutive = differenceArray.every(value => value == 1);

            return isConsecutive;
        }

        if (password.length > 128) return false;

        //check if password is the same as email
        if (email === password) {
            return false
        }

        //check for email name in password
        if (emailName.includes(password)) {
            return false
        }

        //check for part of email name in password
        for (let i = 0; i < emailName.length - (charLength - 1); i++) {
            if (password.includes(emailName.substring(i, i + charLength))) {
                return false;
            }
        }

        //check for consecutive numbers in password
        if (number) {
            number.every((num) => {
                if (num.length >= numberLength) {
                    let numArr = num.split('').map(str => Number(str)); // "1234" -> [1,2,3,4]"

                    if (checkConsecutive(numArr)) {
                        secure = false;
                        return false;
                    }
                }
            })
        }

        return secure;
    },

    handleResponse: (response) => {
        if (!response.ok) {
            if (response.status === "401" || response.status === "403") {
                sessionStorage.setItem('redirect', window.location.href);
                window.location.href = "/login";
            }
            return null;
        }

        return response.text().then(text => {
            const data = text && JSON.parse(text);

            return data;
        });
    },

    handleFetchErrorResponse: (e) => {
        sessionStorage.setItem('redirect', window.location.href);
        window.location.href = "/login";
        return null;
    },

    isFreeUser: (user) => !user?.userRoles?.includes('Client_Premium')
}

function bankIsVerified(bank_Account) {
    return (bank_Account?.status === 'validated'
        || bank_Account?.status === 'verified')
}

function hasBillingAddress(billingInfo) {
    return (billingInfo?.billingAddress && Object.keys(billingInfo.billingAddress).length > 0)
}

function isTestWebsite() {
    const testWebsites = [
        "beta.opengrants.io",
        "test.opengrants.io",
        "localhost"
    ]

    for (var i = 0; i < testWebsites.length; i++) {
        if (window.location.hostname.includes(testWebsites[i])) {
            return true;
        }
    }
    return false;
}

function convertHTMLText(str) {
    if (str) {
        let newStr = str
            .replace(/&apos;/gi, "'")
            .replace(/&#039;/gi, "'")
            .replace(/&#8217;/gi, "'")
            .replace(/&quot;/gi, '"')
            .replace(/&amp;/gi, "&")
            .replace(/&lt;/gi, "<")
            .replace(/&gt;/gi, ">")
        return newStr;
    }
    else {
        return str
    }
}

export const sharedCouponService = {
    calculateDiscount: (price, coupon) => {
        return (price && coupon ?
            coupon.amount_off ?
                price.unit_amount > coupon.amount_off ? price.unit_amount - coupon.amount_off : null
                : price.unit_amount - price.unit_amount * coupon.percent_off / 100
            : null);
    },
    product_is_applicable: (price, coupon) => {
        if (!price || !coupon) {
            return null;
        }
        const duration = (coupon.duration_in_months && coupon.duration_in_months % 12); //coupon duration
        return ((!coupon.applied_to || coupon.applies_to.includes(price.id)) //coupon applicable to product
            && (!coupon.amount_off || price.unit_amount > coupon.amount_off) //coupon amount off is less than price amount
            && ((!price.recurring && coupon.duration === "once") //coupon applicable to one time purchase
                || (price.recurring && coupon.duration === "forever") //coupon applicable for repeating purchases
                || (duration //coupon is repeating
                    && (duration > 0 && price.recurring.interval === "month") //coupon applicable to monthly discount
                    || (duration === 0 && price.recurring.interval !== "month")))); //coupon applicable to yearly discount
    },
    product_is_applicable_monthly: (price, coupon) => {
        if (!price || !coupon) {
            return null;
        }
        const duration = (coupon.duration_in_months && coupon.duration_in_months % 12) > 0; //coupon duration
        return (duration //coupon is repeating
            && (!coupon.applied_to || coupon.applies_to.includes(price.id)) //coupon applicable to product
            && (!coupon.amount_off || price.unit_amount > coupon.amount_off) //coupon amount off is less than price amount
            && (price.recurring.interval === "month")); //coupon applicable to monthly discount
    },
    product_is_applicable_annually: (price, coupon) => {
        if (!price || !coupon) {
            return null;
        }
        const duration = (coupon.duration_in_months && coupon.duration_in_months % 12) == 0; //coupon duration
        return (duration //coupon is repeating
            && (!coupon.applied_to || coupon.applies_to.includes(price.id)) //coupon applicable to product
            && (!coupon.amount_off || price.unit_amount > coupon.amount_off) //coupon amount off is less than price amount
            && (price.recurring.interval !== "month")); //coupon applicable to monthly discount
    },
    get_duration_monthly: (coupon) => {
        if (!coupon || !coupon.duration_in_months) {
            return null;
        }

        const duration_repeat = coupon.duration_in_months > 1 ? "months" : "month"
        return (
            coupon.duration_in_months + " " + duration_repeat
        );
    },
    get_duration_annually: (coupon) => {
        if (!coupon || !coupon.duration_in_months) {
            return null;
        }

        const duration = coupon.duration_in_months / 12;
        const duration_repeat = duration > 1 ? "years" : "year"
        return (
            duration + " " + duration_repeat
        );
    },
    get_duration_annually_int: (coupon) => {
        if (!coupon || !coupon.duration_in_months) {
            return null;
        }

        const duration = coupon.duration_in_months / 12;
        return duration;
    }
}

export const pluginService = {
    redirect: (redirectUrl, queryParams) => {
        var idx = redirectUrl.indexOf("?");
        if (idx > 0) {
            const param = redirectUrl.substring(idx + 1);
            if (!queryParams) {
                queryParams = param;
            } else {
                queryParams += "&" + param;
            }

            redirectUrl = redirectUrl.substring(0, idx);
        }

        const iFrameConfig = JSON.parse(localStorage.getItem('iFrameConfig'));
        let new_redirect = "";

        if (iFrameConfig && iFrameConfig.url) {
            new_redirect = redirectUrl + iFrameConfig.url;
            if (queryParams) {
                new_redirect += "&" + queryParams
            }
        } else {
            new_redirect = redirectUrl;
            if (queryParams) {
                new_redirect += "?" + queryParams
            }
        }

        return new_redirect;
    },

    isIFrame: () => {
        const iFrameConfig = getIframeConfig();

        if (iFrameConfig && iFrameConfig.url) {
            return true;
        }

        return false;
    },

    getIFrameColor: (isSecondary) => {
        const iFrameConfig = getIframeConfig();

        if (iFrameConfig && iFrameConfig.url) {
            if (isSecondary) {
                return iFrameConfig.secondary;
            }
            else {
                return iFrameConfig.primary;
            }
        }
        else {
            return null
        }
    }
}

function getIframeConfig() {
    // return await asyncLocalStorage.getItem('iFrameConfig').then((value) => {
    //     return value;
    // });

    return JSON.parse(localStorage.getItem('iFrameConfig'));
}