import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import ImageCrop from "./ImageCrop";
import { Previews } from './ImageUpload.jsx';

class ProfileInfoFormPopUp_v2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imageError: "",
        }
    }

    //Remove Error
    removePreview = () => {
        this.setState({
            imageError: "",
        })
    }

    //Sets image size/type errors to be displayed on the form
    imageUploadFailed = (msg) => {
        this.setState({
            imageError: msg,
        })

        setTimeout(() => {
            this.setState({
                imageError: "",
            })
        }, 6000);
    }

    render() {
        const { user } = this.props
        const { imageError } = this.state

        return (
            <div>
                <div >
                    <h4 className="boxSubtitle">Upload Headshot (Max 5Mb)</h4>
                    <p className="boxText">For best display, choose a square aspect image less than 500px in size.</p>
                    <p className="boxText red">{imageError}</p>

                    <input type="hidden" name="headshot" value={user.headshot} />

                    <Previews
                        removePreview={this.removePreview}
                        imageUploadFailed={this.imageUploadFailed}
                        setCroppedPixels={this.props.setCroppedPixels}
                    />
                    <div className="imageUpload">
                        {localStorage.getItem("fileBase64") === null && user && user.headshot &&
                            <ImageCrop
                                image={user.headshot}
                                setCroppedPixels={this.props.setCroppedPixels}
                            />
                        }
                    </div>
                    {this.props.organization && <div>
                        <input id='same_as_org_logo' type='checkbox' checked={this.props.same_as_org_logo} onChange={this.props.toggleSameFlag} />
                        <label for="same_as_org_logo" style={{ display: "inline-block" }}>Use this profile image for my marketplace listing, instead of a company logo.</label>
                    </div>}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedProfileInfoFormPopUp_v2 = connect(mapStateToProps)(ProfileInfoFormPopUp_v2);
export { connectedProfileInfoFormPopUp_v2 as ProfileInfoFormPopUp_v2 };
