import React from 'react';
import Coffee from "../../../../_assets/img/coffee-cup.svg";
import Airplane from "../../../../_assets/img/paper-airplane.svg";
import Dollar from "../../../../_assets/img/usd-icon.svg";

const GC_Onboarding_Intro = ({ updateNav, service_Provider }) => {
  return (
    <div className="onboarding-intro">
      <div className="onboarding-container d-flex flex-column">
        <div className="onboarding-header">
          <h1 className="onboarding-form-header">Join the Talent Marketplace!</h1>
        </div>
        <div className="onboarding-header">
          <p>
            Please complete this application to be considered
            for the OpenGrants Talent Marketplace.
            We accept grant writers and grant-related professionals
            with proven experience. If accepted, you'll be a resource
            for thousands of organizations that use OpenGrants to
            find grants and seek help.
          </p>
        </div>
        <div className="onboarding-header">
          <h1 className="onboarding-form-header">How it works</h1>
        </div>
        <div className="onboarding-content">
          <div className="onboarding-card-container center">
            <div className="onboarding-intro-card">
              <img src={Airplane} alt="Paper Airplane Icon" />
              <h1>1.Get Hired Easier</h1>
              <p>
                Users can message you asking for help. Based on messages, you
                draft a proposal for your new client
              </p>
            </div>
          </div>
          <div className="onboarding-card-container center">
            <div className="onboarding-intro-card">
              <img src={Coffee} alt="Coffee Cup Icon" />
              <h1>2. Get to Work Happier</h1>
              <p>
                After they accept the proposal, you start work. Work as needed
                and log hours as you go
              </p>
            </div>
          </div>
          <div className="onboarding-card-container center">
            <div className="onboarding-intro-card">
              <img src={Dollar} alt="US Dollar Sign Icon" />
              <h1>3. Get Paid Faster</h1>
              <p>
                We'll create an invoice at the end of each week. Your client
                will have 5 days to review the invoice . We collect 10%, you get
                90%
              </p>
            </div>
          </div>
        </div>
        <div className="onboarding-header started-button-container">
          <div className="col-md-6 gc_onboarding-form-col">
            <button
              className="gc_started-button-back"
              onClick={() => window.history.back()}
            >
              Back <i class="icon-arrow-left" />
            </button>
          </div>
          <div className="col-md-6 gc_onboarding-form-col">
            <button
              className="gc_started-button-next"
              onClick={() => updateNav("onboard_basics")}
            >
              Get Started <i class="icon-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GC_Onboarding_Intro;
