import React from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ServiceProvider_v2 } from "./ServiceProvider_v2";

export default function ServiceProviderModal({
    openModal,
    viewservice_Provider,
    service_Provider,
    applicantId
}) {
    return (
        <div>
            <Modal
                isOpen={openModal}
                centered={true}
                backdrop={true}
                toggle={() => viewservice_Provider()}
                fade={false}
                className="ModalDiv"
                size="lg"
            >
                <ModalHeader toggle={() => viewservice_Provider()} style={{ borderBottom: 0 }}></ModalHeader>
                <ModalBody>
                    {applicantId ?
                        <ServiceProvider_v2
                            spId={applicantId}
                            applicant={true}
                            viewservice_Provider={viewservice_Provider}
                        />
                        :
                        <ServiceProvider_v2 spId={parseInt(service_Provider.objectID)} />
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}
