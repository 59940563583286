import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { stripeActions } from '../../../../../_actions';
import { sharedCouponService } from '../../../../../_services';
import { checkoutService } from '../../PaymentMethod';

export const Item = (props) => {
    const dispatch = useDispatch();
    const { priceIds, coupon } = useSelector(state => state.billingHandler);

    function handlePriceChange(e) {
        const { value } = e.target;

        let selectedPriceIds = priceIds.selectedPriceIds;
        let selectedRecurringPriceIds = priceIds.selectedRecurringPriceIds;
        let priceIds_new = { selectedPriceIds, selectedRecurringPriceIds }

        props.prices.forEach((price) => {
            if (value === price.id) {
                priceIds_new = checkoutService.addToCart(priceIds_new, price);
            } else if (value !== price.id) {
                priceIds_new = checkoutService.removeFromCart(priceIds_new, price);
            }
        });


        dispatch(stripeActions.setPriceIds(priceIds_new));
    }

    function displayItemPrice() {
        let selectedRecurringPriceIds = priceIds?.selectedRecurringPriceIds.map(price => price.id);

        if (props.prices.length > 0) {
            return (props.prices.map((price) => {
                const coupon_is_applicable_monthly = sharedCouponService.product_is_applicable_monthly(price, coupon);
                const coupon_is_applicable_annually = sharedCouponService.product_is_applicable_annually(price, coupon);
                const coupon_is_applicable = sharedCouponService.product_is_applicable(price, coupon);
                // const coupon_duration_monthly = sharedCouponService.get_duration_monthly(coupon);
                // const coupon_duration_annually = sharedCouponService.get_duration_annually(coupon);

                return (
                    <>
                        <div className="checkoutPriceBox mobileCheckoutPriceBox" onClick={() => { handlePriceChange({ target: { value: price.id } }) }} style={{ cursor: 'pointer' }}>
                            {price.recurring.interval === "month" &&
                                <div>
                                    <input name={price.product.id} value={price.id} checked={selectedRecurringPriceIds.includes(price.id)} onChange={handlePriceChange} type="radio" />
                                    <h3>Monthly</h3>
                                </div>
                            }
                            {price.recurring.interval === "year" &&
                                <div>
                                    <input name={price.product.id} value={price.id} checked={selectedRecurringPriceIds.includes(price.id)} onChange={handlePriceChange} type="radio" />
                                    <h3>Yearly</h3>
                                </div>
                            }
                            {/* Price */}
                            <p style={{ textDecoration: coupon_is_applicable ? "line-through" : "none" }}>
                                ${!price.recurring || price.recurring.interval === "month" ?
                                    (price.unit_amount / 100).toFixed(2) : (price.unit_amount / 12 / 100).toFixed(2)} USD {price.recurring ? "/ month" : ""}
                            </p>
                            {/* Discounted Price */}
                            {coupon_is_applicable && <>
                                {(!price.recurring) && <>
                                    <p>${(sharedCouponService.calculateDiscount(price, coupon) / 100).toFixed(2)} USD </p>
                                </>}
                                {coupon_is_applicable_monthly && <>
                                    <p>${(sharedCouponService.calculateDiscount(price, coupon) / 100).toFixed(2)} USD {price.recurring ? "/ month" : ""} </p>
                                </>}
                                {coupon_is_applicable_annually && <>
                                    <p>${(sharedCouponService.calculateDiscount(price, coupon) / 12 / 100).toFixed(2)} USD {price.recurring ? "/ month" : ""} </p>
                                </>}
                            </>}
                            {/* Cost Summary */}
                            {!coupon_is_applicable && <p>Billed {price.recurring ? price.recurring.interval === "month" ? "monthly" : "annually" : "once"} for ${((price.unit_amount) / 100).toFixed(2)} USD</p>}
                            {coupon_is_applicable && <p>Billed {price.recurring ? price.recurring.interval === "month" ? "monthly" : "annually" : "once"} for ${(sharedCouponService.calculateDiscount(price, coupon) / 100).toFixed(2)} USD</p>}

                            {/* Cost After Discount Duration */}
                            {/* {coupon_is_applicable_monthly && <>
                                <p>After {coupon_duration_monthly}: ${(price.unit_amount / 100).toFixed(2)} USD / month</p>
                            </>}
                            {coupon_is_applicable_annually && <>
                                <p>After {coupon_duration_annually}: ${(price.unit_amount / 12 / 100).toFixed(2)} USD / month</p>
                            </>} */}
                        </div>
                    </>
                )
            }))
        }
    }

    function removeProduct() {
        let selectedPriceIds = priceIds?.selectedPriceIds.filter(price => price.id);
        let selectedRecurringPriceIds = priceIds?.selectedRecurringPriceIds.map(price => price.id);
        const price = props.prices.filter(price => {
            return (selectedPriceIds.includes(price.id) || selectedRecurringPriceIds.includes(price.id))
        });

        if (price.length > 0) {
            const priceIds_new = checkoutService.removeFromCart(priceIds, price[0]);
            dispatch(stripeActions.setPriceIds(priceIds_new));
        }
    }

    return (
        <>
            {props.prices &&
                <div className="checkoutProduct mt-24">
                    <h3 className="checkoutSubHeading">Please subscribe to continue using OpenGrants</h3>
                    {props.prices.length > 0 && <p>Select a Billing Cycle:</p>}
                    <div className="dflex">
                        {displayItemPrice()}
                    </div>
                    {props.removeable && <button className="removeAddOnsButton" onClick={removeProduct}>Remove Add-On</button>}
                </div>}
        </>
    )

}