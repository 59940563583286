import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationHubzone extends React.Component {

    render() {
        const { org } = this.props;

        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel">Are You Located in a Hubzone?</h4>
                <p className="inputFieldSubLabel">
                    The Historically Underutilized Business Zone (HUBzone) Program allows federal contract set-asides for small businesses in economically depressed communities. Learn more
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        name="hubzone_Owned"
                        defaultValue={org.organization.hubZone_Owned === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "hubZone_Owned")}
                        className="orgPageTextField"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationHubzone = connect(mapStateToProps)(EditOrganizationHubzone);
export { connectedEditOrganizationHubzone as EditOrganizationHubzone };
