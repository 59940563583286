import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../Styles/ServiceProvider_v2/Projects_v2.css';
import { serviceProviderActions } from "../../../../_actions";
import AlluraParty from '../../../../_assets/img/AlluraParty.png';
import { pluginService } from "../../../../_services";

export function CongratsModal(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.setUser);
  const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);

  let accentColor = pluginService.getIFrameColor()

  const {
    isCongratulationsOpen, openCongratulations,
  } = props;

  const hrefFunction = () => {
    window.location.href = pluginService.redirect('/dashboard');
  }
  const hrefProjectFunction = () => {
    window.location.href = pluginService.redirect(service_Provider && service_Provider.created_By_User_Id ? '/sp/projects' : '/cl/projects');
  }

  function getProject() {
    dispatch(serviceProviderActions.getProject(props.project.project.project_Id, user.id));
  }
  return (
    <Modal
      isOpen={isCongratulationsOpen}
      centered={true}
      backdrop={true}
      toggle={() => getProject()}
      fade={false}
      size='xl'
    >
      <ModalHeader
        toggle={() => getProject()}
        style={{ border: 0, justifyContent: 'end' }}
      />
      <ModalBody className="congratsBody">
        <img className="congratsBackground" src={AlluraParty} alt="" />
        <div className="congratsText">
          <div className="row center">
            <h1 className="congratsHeader">
              Congratulations!
            </h1>
          </div>
          <div className="row center">
            <p className="congratsDescription">
              Your project is now active! We've emailed your consultant and told them to begin work. If you have specific instructions for them, or want to check on their work, send them a message. Remember, it's always better to over-communicate!
            </p>
          </div>
          <div className="row-auto center" style={{ position: 'relative' }}>
            <button
              className="congratsProjects"
              onClick={() => hrefProjectFunction()}
              style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Projects
            </button>
            <button
              className="congratsDashboard"
              onClick={() => hrefFunction()}
              style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Dashboard
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}