import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { asyncLocalStorage, authenticateService, pluginService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import { ForgotPasswordConfirmation } from './ForgotPasswordConfirmation';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            confirm: false,
            isGoogleAcc: false,
            // resetError: false,
            iFrameConfig: null,
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({ submitted: true, loading: true });
        const { email } = this.state;

        if (email) {
            let sanitizedEmail = encodeURIComponent(email.trim())

            try {
                let response = await authenticateService.forgotPassword(sanitizedEmail)

                if (response[0] === "google") {
                    this.setState({
                        isGoogleAcc: true,
                    })
                }
            }
            catch {
                // this.setState({ resetError: true })
            }
            this.setState({ confirm: true, loading: false });
        }

    }

    render() {
        const { submitted, confirm, email, resetError, iFrameConfig, loading } = this.state;

        return (
            <div>
                {loading && <Loader />}
                {!confirm &&
                    <div id="forgotPassword">
                        <div id="forgotPassowrdRightLogin">
                            <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="logo" className="login-logo forgot-pswd-logo" style={iFrameConfig?.public ? { width: "40%", marginBottom: 40 } : { width: "70%", marginBottom: 40 }} />
                            <hr />
                            <br />
                            <h3 className="forgot-pswd-heading">Enter your email, and we will send you a password reset link.</h3>
                            <form onSubmit={this.handleSubmit}>
                                <div className="forgot-pswd-form">
                                    <input
                                        className="email-text-field font-input-default forgot-pswd-email-input"
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.handleChange}
                                    />

                                    <button
                                        className="sign-in-button forgot-pswd-send-button"
                                        type="submit"
                                        style={{ backgroundColor: iFrameConfig?.public ? iFrameConfig?.primary : '#0c7069' }}
                                    >
                                        Send
                                    </button>

                                </div>
                            </form>
                            {submitted && !email && !resetError &&
                                <p className="login-error forgot-pswd-error">Email address is required</p>
                            }
                            {/* {submitted && resetError &&
                                <p className="login-error forgot-pswd-error">
                                    We're sorry, there was an issue processing your request. Please double check entered information and try again.
                                </p>
                            } */}
                            {!iFrameConfig?.public &&
                                <div className='register-text'>
                                    <br />
                                    <p className="register-text-medium">
                                        If you continue to experience any issues, please contact us for support at <a href="mailto:support@opengrants.io">support@opengrants.io.</a>
                                    </p>
                                </div>
                            }
                        </div>
                        {!iFrameConfig?.public &&
                            <div className="forgotPasswordBottomLinks">
                                <div className="forgotPasswordLinks">
                                    <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                                    <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                                    <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                                    <a href={pluginService.redirect("/login")}>Login</a>
                                </div>
                                <div className="forgotPasswordCopyFooter">
                                    opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                                </div>
                            </div>
                        }
                    </div>
                }
                {confirm &&
                    <ForgotPasswordConfirmation isGoogleAcc={this.state.isGoogleAcc} />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedForgotPassword = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };

