import { perksConstants } from '../_constants';
import { perksService } from '../_services';

export const perksActions = {
    get_all_perks,
    add_perk,
    update_perk,
    delete_perk
};

function get_all_perks() {
    return dispatch => {
        dispatch(request());
        perksService.get_all_perks()
            .then(
                perks => dispatch(success(perks)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: perksConstants.GET_ALL_PERKS_REQUEST } }
    function success(perks) { return { type: perksConstants.GET_ALL_PERKS_SUCCESS, perks } }
    function failure(error) { return { type: perksConstants.GET_ALL_PERKS_ERROR, error } }
}

function add_perk(perk) {
    return dispatch => {
        dispatch(request());
        perksService.add_perk(perk)
            .then(
                perk => { dispatch(success(perk)) },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: perksConstants.ADD_PERKS_REQUEST } }
    function success(perk) { return { type: perksConstants.ADD_PERKS_SUCCESS, perk } }
    function failure(error) { return { type: perksConstants.ADD_PERKS_ERROR, error } }
}

function update_perk(perk) {
    return dispatch => {
        dispatch(request());
        perksService.update_perk(perk)
            .then(
                perk => { dispatch(success(perk)) },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: perksConstants.EDIT_PERKS_REQUEST } }
    function success(perk) { return { type: perksConstants.EDIT_PERKS_SUCCESS, perk } }
    function failure(error) { return { type: perksConstants.EDIT_PERKS_ERROR, error } }
}

function delete_perk(perk_id) {
    return dispatch => {
        dispatch(request());
        perksService.delete_perk(perk_id)
            .then(
                perk_id => { dispatch(success(perk_id)) },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: perksConstants.DELETE_PERKS_REQUEST } }
    function success(perk_id) { return { type: perksConstants.DELETE_PERKS_SUCCESS, perk_id } }
    function failure(error) { return { type: perksConstants.DELETE_PERKS_ERROR, error } }
}