import React, { useEffect, useState } from "react";
import { Card, Col, Row } from 'react-bootstrap';
import GrantList from "./GrantList";
import { upgradeActions } from "../../../../_actions";
import ReactTooltip from "react-tooltip";
import { Loader_v2 } from "../../../Shared/Loader/Loader_v2";
import { sharedService } from "../../../../_services";
import FunderList from "./FunderList";
import { Modal, ModalBody } from "reactstrap";
import { GrantDetails } from "../../../Grants/GrantDetails";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const GrantNotification = ({
    org,
    iFrameConfig,

    grantMatches,
    grantMatchesLength,
    regenerateGrantMatches,
    updateGrantMatches,

    favorites,
    updateFavorites,

    loading
}) => {
    const dispatch = useDispatch();
    const [grant, setGrant] = useState({});
    const [isGrantOpen, setIsGrantOpen] = useState(false);
    const history = useHistory()

    const openGrantDetails = (grant) => {
        setIsGrantOpen(!isGrantOpen);
        setGrant(grant);
    }

    return (<>
        <Row className="bg-white border border-primary rounded-10 shadow py-48 py-sm-56 px-80 px-md-96 mb-48">
            <Card className="border-0">
                {loading && <>
                    <Card.Header className="border-0 bg-transparent">
                        <Row>
                            {loading === 'Retrieving' &&
                                <h1 className="text-secondary fw-bold mt-48 mt-xl-0">Retrieving Matches</h1>}
                            {loading === 'Regenerating' &&
                                <h1 className="text-secondary fw-bold mt-48 mt-xl-0">Regenerating Matches</h1>}
                        </Row>
                    </Card.Header>
                    <Card.Body className="m-0 w-100 mb-32">
                        <Loader_v2 />
                    </Card.Body>
                </>}

                {!loading && !grantMatches && <>
                    <h1 className="text-secondary fw-bold mt-48 mt-xl-0">We have found 0 matches</h1>
                    <p>We're sorry, an error occurred while retrieving your matches.
                        The OpenGrants team have been notified of this issue.
                    </p>
                </>}

                {!loading && grantMatches && <>
                    <Card.Header className="border-0 bg-transparent">
                        <Row>
                            {!loading && <>
                                <div className="text-center text-sm-end">
                                    <button
                                        data-tip
                                        data-for="regenerateMatches"
                                        className={"bg-primary-dark text-white rounded-10 p-16 fs-4 w-sm-160"}
                                        onClick={regenerateGrantMatches}
                                    >
                                        Regenerate Matches
                                    </button>
                                </div>
                                <h1 className="text-center text-sm-start text-secondary fw-bold mt-48 mt-xl-0 p-0">We have found {grantMatchesLength} matches</h1>
                                {grantMatchesLength > 0 &&
                                    <p> See your matches below, or search for free in the Grant Finder.</p>
                                }
                            </>}
                        </Row>
                    </Card.Header>
                    {grantMatchesLength > 0 &&
                        <Card.Body className="text-start m-0 w-100 mb-32">
                            <Row>
                                {grantMatches?.map((grant, idx) =>
                                    <Col className="col-12 col-lg-4 p-16">
                                        <div className={(idx + 1) % 3 !== 0 ? "position-absolute h-50 border-lg-1-end border-secondary top-25 end-0" : ""}></div>
                                        <div className={(grantMatchesLength > (idx + 3) && (idx + 3) < grantMatches.length) ? "position-absolute w-50 border-bottom border-secondary left-25 bottom-0" : "position-absolute w-50 border-secondary left-25 bottom-0" + (idx !== grantMatches.length - 1 ? " border-lg-0-bottom" : "")}></div>
                                        {grant.Portal_Listings || grant.portal_Listings ? <GrantList
                                            grant={grant.Portal_Listings || grant.portal_Listings}
                                            viewGrant={openGrantDetails}
                                        /> : <FunderList
                                            grant={grant.Funders}
                                            viewGrant={openGrantDetails} />}
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                    }
                    <Card.Footer className="bg-transparent border-0">
                        {grantMatchesLength === 0 && <Row className="align-items-center">
                            <Col className='col text-center text-md-start pb-24 p-md-0'>
                                <p className="fw-bold fs-2 lh-sm text-gray mb-0">
                                    {!org.description && "We couldn't find a description from you."} Please update your organization description to unlock grant matches.
                                </p>
                            </Col>
                            <Col className="col-12 col-md-auto text-center ps-md-48">
                                <button
                                    className={"bg-primary-dark text-white rounded-10 p-16 fs-4 mb-0 w-sm-160"}
                                    onClick={() => history.push("/grant-seeker/onboarding")}
                                >
                                    Edit Description
                                </button>
                            </Col>
                        </Row>}
                    </Card.Footer>
                </>}
            </Card>
        </Row >

        {/* MODAL */}
        {
            isGrantOpen &&
            <Modal
                isOpen={isGrantOpen}
                centered={true}
                backdrop={true}
                toggle={openGrantDetails}
                fade={false}
                className="ModalDiv"
                size="xl">
                <ModalBody className="p-64">
                    <GrantDetails
                        iFrameConfig={iFrameConfig}
                        closeModal={openGrantDetails}

                        listing={grant}
                        updateGrantMatches={updateGrantMatches}
                        favorites={favorites}
                        updateFavorites={updateFavorites}
                    />
                </ModalBody>
            </Modal >
        }
    </>)
}

export default GrantNotification
