import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { stripeActions } from '../../../../../_actions';
import { sharedCouponService, stripeService } from '../../../../../_services';

export const Coupon = (props) => {
    const dispatch = useDispatch();
    const billingHandler = useSelector(state => state.billingHandler);

    //use 'NxkVvK3z' for testing coupon
    const [coupon_id, setCouponId] = useState('');
    const [coupon_id_applied, setCouponIdApplied] = useState('');
    const [coupon, setCoupon] = useState('');

    function handleCouponInput(e) {
        e.preventDefault();
        const { value } = e.target;

        setCouponId(value);
    }

    async function handleCouponSubmit(e) {
        e.preventDefault();
        if (!coupon || coupon_id != coupon.id) {
            let priceIds = billingHandler.priceIds.selectedPriceIds.map((price) => price.product.id);
            let recurringPriceIds = billingHandler.priceIds.selectedRecurringPriceIds.map((price) => price.product.id);

            var paymentDetails = {
                ProductDetails: {
                    CouponId: coupon_id.trim(),
                    priceIds,
                    recurringPriceIds
                }
            }

            let is_applicable = false;
            const result = await stripeService.getCouponDiscount(paymentDetails);
            const coupon = result.coupon;
            for (let i = 0; i < props.prices.length; i++) {
                if (sharedCouponService.product_is_applicable(props.prices[i], coupon)) {
                    is_applicable = true;
                }
            }
            if (!is_applicable) {
                for (let i = 0; i < props.addOns.length; i++) {
                    if (sharedCouponService.product_is_applicable(props.prices[i], coupon)) {
                        is_applicable = true;
                    }
                }
            }

            if (is_applicable) {
                setCoupon(coupon);
                dispatch(stripeActions.setCoupon(result));
            } else {
                setCoupon({})
                dispatch(stripeActions.setCoupon(''));
            }
        }
        setCouponIdApplied(coupon_id);
        setCouponId('');
    }

    return (
        <div>
            <div className="checkoutCoupon row">
                <form onSubmit={handleCouponSubmit}>
                    <div className="col-lg-10 p-0 pe-lg-16">
                        <input value={coupon_id} placeholder="Promo Code" onChange={handleCouponInput} />
                    </div>
                    <div className="col-lg-2" style={{ padding: 0 }}>
                        <button className="checkoutPrimaryButton applyButton m-0" onClick={handleCouponSubmit} disabled={!billingHandler.priceIds}>Apply</button>
                    </div>
                </form>
            </div>
            {coupon && coupon.id && <p className="checkoutCouponText">Applied {coupon_id_applied}</p>}
            {!coupon || !coupon.id && <p className="checkoutCouponText" style={{ color: 'red' }}>Could not apply {coupon_id_applied}</p>}
        </div>
    )

}