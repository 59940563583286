import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import { pluginService } from '../../../_services';

const ThankYou = () => {
  const history = useHistory()
  return (
    <Card className="mt-32 py-32 px-24 bg-light-200 border border-1 border-light-gray text-center text-secondary rounded-3">
      <h1 className='fw-bold'>Thank you!!</h1>
      <p className='mt-24 fst-italic'>If anything changes, come back and update your info. Meanwhile, keep an eye on your grant matches, or use the Grant Finder to find grants that are available now!</p>
      <div className='w-100 d-flex justify-content-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 mt-40 ms-lg-16'
          onClick={() => history.push(pluginService.redirect("/dashboard"))}
        >
          Back to Dashboard
        </Button>
      </div>
    </Card>
  )
}

export default ThankYou