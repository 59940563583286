import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const authenticateService = {
    login,
    logout,
    refreshToken,
    setUser,
    forgotPassword,
    resetPassword,
    checkUsername,
    deleteUser,
    generateTempToken,
};

function login(username, password, google) {
    if (google == null) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ username, password })
        };

        return fetch(API_Route + '/api/authenticate/authenticate_portal_user', requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                var userCopy = Object.assign({}, user);
                userCopy.partnerClient = "";
                localStorage.setItem('user', JSON.stringify(userCopy));
                // getUserRoles(user);

                return user;
            });
    }
    else {
        let google_Token = (JSON.parse(localStorage.getItem("googleToken")));
        if (!google_Token) {
            google_Token = google.tokenId;
        }
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ token: google_Token })
        };

        return fetch(API_Route + '/api/authenticate/google_authentication', requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                var userCopy = Object.assign({}, user);
                userCopy.partnerClient = "";
                localStorage.setItem('user', JSON.stringify(userCopy));
                // getUserRoles(user);

                return user;
            });
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function refreshToken(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/authenticate/refresh_portal_user_token', requestOptions)
        .then(sharedService.handleResponse)
        .then(async user => {
            await localStorage.setItem('user', JSON.stringify(user));

            const favorites = localStorage.getItem('favorite');
            if (!favorites) {
                var emptyFavorites = []
                localStorage.setItem('favorite', JSON.stringify(emptyFavorites));
            }

            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function setUser(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/authenticate/set_user?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            var userCopy = Object.assign({}, user);
            userCopy.partnerClient = "";
            localStorage.setItem('user', JSON.stringify(userCopy));

            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/authenticate/forgot_password?email=' + email, requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function resetPassword(resetPassword) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(resetPassword)
    };

    return fetch(API_Route + '/api/authenticate/reset_password', requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function checkUsername(username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/authenticate/check_username?username=' + username, requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(API_Route + '/api/authenticate/delete_user/' + id, requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function generateTempToken() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(API_Route + '/api/authenticate/generate/token', requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == "401") {
                sessionStorage.setItem('redirect', window.location.href);
                var redirect = pluginService.redirect("/login");
                window.location.href = redirect;
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}