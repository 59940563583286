import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationSAM extends React.Component {

    render() {
        const { org } = this.props;
        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel-Top">Are You Registered in SAM?</h4>
                <p className="inputFieldSubLabel">
                    SAM registration is required to conduct business with the US government. You will need this to apply for a grant. If you aren’t registered, register for SAM
                    <a href="https://sam.gov/content/home" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        className="select orgPageTextField"
                        name="saM_Number"
                        defaultValue={org.organization.saM_Number === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "saM_Number")}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationSAM = connect(mapStateToProps)(EditOrganizationSAM);
export { connectedEditOrganizationSAM as EditOrganizationSAM };
