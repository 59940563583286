import React from "react";
import { Card, Col, Row } from 'react-bootstrap';

const GrantList = ({ grant, blur, viewGrant }) => {
    const reformattedFundingAmount = grant.funding_Amount ?
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(grant.funding_Amount) :
        ''

    return (
        <Card className="bg-transparent border-0 text-secondary" style={blur ? { filter: 'blur(3px)' } : {}}>
            <Card.Header className="bg-transparent border-0"><h2>{grant.title}</h2></Card.Header>
            <Card.Body className="w-auto">
                <p>{grant.agency}</p>
                <p>{reformattedFundingAmount}</p>
                <button disabled={blur} className="bg-primary-dark text-white rounded-10 p-16" onClick={() => viewGrant(grant)}>View Grant</button>
            </Card.Body>
        </Card>
    )
}

export default GrantList
