import { Client as ConversationsClient } from '@twilio/conversations';
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import '../../Styles/Messenger.css';
import { chatActions, organizationActions } from '../../_actions';
import { asyncLocalStorage, chatService, organizationService, serviceProviderService } from '../../_services';
import { ServiceProvidersChatBox_v2 } from '../ServiceProviders';
import { Loader as Loading } from '../Shared/Loader';
import MessagePreview from "./MessagePreview";

class Messenger_v2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service_Provider: this.props.service_Provider,
            loading: true,

            conversations: [],
            conversation: null,

            channels: [],
            channel: null,

            has_an_org: true,

            messageSent: false,
            newConversation: false,
            newUserInfo: {},
            connectionStatus: false,
            iFrameConfig: null,
            openExportOptions: false
        }

        this.closeChatBox = this.closeChatBox.bind(this);
    }

    componentDidMount = async () => {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
        const { channel_type, fromMarketplace, user } = this.props;

        //if in marketplace, verify user has already created an org
        if (fromMarketplace) {

            var result = await organizationService.getAllOrganizationsByUser(user.id);

            if (!result.organizations || Object.keys(result.organizations).length === 0) {
                //allow user to create org
                this.setState({
                    has_an_org: false
                });
            }
        }

        if (channel_type === "all" || channel_type === "service_Provider" || channel_type === "client") {
            this.getAllChannels();
        }
        else {
            this.getOrganizationchannels();
        }
    }

    componentDidUpdate = (oldProps, oldState) => {
        const { fromMarketplace, channel_type, created_organization } = this.props;
        let { has_an_org } = this.state;

        if ((!fromMarketplace || created_organization || (fromMarketplace && has_an_org))
            && !channel_type
            && this.props.service_Provider.organization_Id !== oldProps.service_Provider.organization_Id
        ) {
            this.getOrganizationchannels();
        }
        else if ((channel_type === "service_Provider" || channel_type === "client")
            && (this.props.organization_Id !== oldProps.organization_Id || this.props.open_chat !== oldProps.open_chat)
        ) {
            this.messageUserByOrgId(this.state.channels, this.props.organization_Id);
        }
    }

    messageUserByOrgId = async (channel_list, organization_Id) => {

        if (organization_Id) {

            let result = null;
            let desired_channel = null;

            if (this.props.channel_type === "client") {

                if (this.props.fromMarketplace) {
                    result = await serviceProviderService.getChannelByOrgId(this.props.user.id, organization_Id);
                }
                else {
                    result = await serviceProviderService.getChannelByServiceProvider(this.props.user.id, organization_Id);
                }

                desired_channel = channel_list.filter((channel) => {
                    if (channel.user_Info.organization_Id == result.channel.organization_Id) {
                        return channel;
                    }
                });
            }
            else {
                desired_channel = channel_list.filter((channel) => {
                    if (channel.user_Info && (channel.user_Info.organization_Id == organization_Id)) {
                        return channel;
                    }
                });
            }

            if (desired_channel.length > 0) {
                this.prepareChat(desired_channel[0]);
            }
            else {
                if (this.props.channel_type === "service_Provider") {
                    result = await serviceProviderService.getChannelByOrgId(this.props.user.id, organization_Id);
                }

                let desired_channel = {
                    user_Info: result.channel
                };

                channel_list.push(desired_channel);

                this.setState({
                    channel_list
                })

                if (!result.channel.channel) {
                    this.setState({
                        show_Chat: true,
                        newConversation: true,
                        newUserInfo: result.channel,
                    })
                }
                else {
                    this.prepareChat(desired_channel)
                }
            }
        }
    }

    getAllChannels = async () => {

        const { user, channel_type, organization_Id } = this.props;

        var result = await serviceProviderService.getAllChannels(user.id);

        let channel_list = result.channels;

        if (channel_type === "service_Provider" || channel_type === "client") {
            this.messageUserByOrgId(channel_list, organization_Id);
        }

        this.setState({
            channels: channel_list,
            loading: false,
        });
    }

    getOrganizationchannels = async () => {

        const { user, organization_Id } = this.props;

        const organization_chat = {
            participant_1_user_id: user.id,
            organization_id: organization_Id
        };

        var result = await serviceProviderService.getOrganizationChannels(organization_chat);

        this.setState({
            channels: result.channels,
            loading: false,
        });
    }

    componentWillUnmount() {
        if (this.conversationsClient) {
            this.conversationsClient.shutdown();
        }
    }

    closeChatBox() {
        this.setState({
            channel: null,
            conversation: null
        });

        if (this.props.close_chat) {
            this.props.close_chat();
        }
    }

    add_participants = async (channel) => {

        const { user } = this.props;

        var chat = {
            participant_1_user_id: user.id,
            participant_2_user_id: channel.username,
            channel: channel.channel
        }

        await chatService.add_participants(chat);
    }

    //Prepare all chat information after sending message (when there is no message history)

    prepareNewChat = async () => {

        const { user } = this.props
        const { channel, newUserInfo } = this.state

        //create conversation channel

        let result = await chatService.create_conversation(user.name + user.surname + "_" + newUserInfo.name + newUserInfo.surname);

        //set conversation channel

        let conversation_ch = result.conversation_ch;

        newUserInfo.channel = conversation_ch;

        this.setState({
            conversation_ch,
            channel: newUserInfo,
        })

        //add participants to conversation

        let chat = {
            participant_1_user_id: user.id,
            participant_2_user_id: newUserInfo.username,
            channel: newUserInfo.channel
        }

        await chatService.add_participants(chat);

        //create conversation client

        if (!this.conversationsClient) {

            let result = await chatService.generate_token(user.id);

            this.setState({
                token: result.token,
                conversation_ch,
                channel
            }, () => { this.initConversations() });
        }

        this.setState({
            loading: false,
            show_Chat: true
        });
    }

    //Prepare all chat information before sending message (when there is message history)

    prepareChat = async (channel) => {

        const { user } = this.props

        let conversation_ch = channel.channel;

        this.setState({
            loading: false,
            show_Chat: true
        });

        if (!this.state.has_an_org && !this.props.created_organization) {
            this.props.dispatch(organizationActions.organizationAlert("no_organization"));
        }

        //channel exists, conversation client does not
        if (conversation_ch && !this.conversationsClient) {

            let result = await chatService.generate_token(user.id);

            this.setState({
                token: result.token,
                conversation_ch,
                channel
            }, () => { this.initConversations() })
        }

        //channel exists, conversation client exits
        if (conversation_ch && this.conversationsClient) {

            this.setState({
                conversation_ch,
                channel
            });

            let conversation_list = this.state.conversations.filter(conv => conv.sid === conversation_ch);

            if (conversation_list.length > 0) {
                this.setState({ conversation: conversation_list[0], channel });
                this.props.dispatch(chatActions.set_conversation(conversation_list[0]));
            }
        }

        //channel exists and there are multiple conversations
        if (channel
            && channel.last_Message_User_Id //a message was sent
            && (channel.last_Message_User_Id !== user.id) //message was sent by other user
            && (!channel.last_Message_Read  //message was never read
                || (new Date(channel.last_Message_Read) < new Date(channel.last_Message_Sent)))) //message has not been read yet
        {

            let messageInfo = {
                channel: channel.channel,
                last_Message_Read: new Date()
            }

            chatService.updateChatInfo(messageInfo);

            let channels = this.state.channels.map(conv => {
                if (conv.channel === conversation_ch) {
                    conv.last_Message_Read = new Date();
                }
                return conv;
            });

            this.setState({
                channels
            });
        }

        this.props.dispatch(chatActions.set_channel(channel));

        await this.setState({
            loading: false
        });
    }

    initConversations = async () => {

        const { user } = this.props

        this.conversationsClient = await ConversationsClient.create(this.state.token);
        this.setState({ statusString: "Connecting to Twilio…" });

        this.conversationsClient.on("connectionStateChanged", (state) => {
            if (state === "connecting") {
                this.setState({
                    statusString: "Connecting to Twilio…",
                    status: "default"
                });
            }
            if (state === "connected") {
                this.setState({
                    statusString: "You are connected.",
                    status: "success"
                });
            }
            if (state === "disconnecting") {
                this.setState({
                    statusString: "Disconnecting from Twilio…",
                    conversationsReady: false,
                    status: "default"
                });
            }
            if (state === "disconnected") {
                this.setState({
                    statusString: "Disconnected.",
                    conversationsReady: false,
                    status: "warning"
                });
            }
            if (state === "denied") {
                this.setState({
                    statusString: "Failed to connect.",
                    conversationsReady: false,
                    status: "error"
                });
            }
        });

        this.conversationsClient.on("conversationJoined", (conversation) => {

            if (this.state.conversations.filter(conv => conv.sid === conversation.sid).length === 0) {
                if (conversation.sid === this.state.conversation_ch) {
                    this.setState({
                        conversations: [...this.state.conversations, conversation],
                        conversation
                    }, () => { this.props.dispatch(chatActions.set_conversation(conversation)); });


                }
                else {
                    this.setState({
                        conversations: [...this.state.conversations, conversation],
                    });
                }
            }
        });

        this.conversationsClient.on("conversationLeft", (thisConversation) => {
            this.setState({
                conversation: null,
                conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
            });
        });

        this.conversationsClient.on('tokenAboutToExpire', async () => {
            // let email = user.email;
            let result = await chatService.generate_token(user.id);
            let token = result.token;
            this.conversationsClient.updateToken(token);
        });
    };

    exportMessages = async (file_type) => {
        var blob = await chatService.exportAllMessages(file_type);
        const file = window.URL.createObjectURL(blob);

        var aTag = document.createElement("a");
        if (file_type === "csv") {
            aTag.download = "MessageResults.csv"
        } else {
            aTag.download = "MessageResults.xlsx"
        }
        aTag.href = file;
        aTag.target = "_blank";

        aTag.click();
    }

    generateMessageBox = (channels, updated_channels) => {
        const { user } = this.props

        if (!updated_channels || updated_channels.length === 0) {
            channels.sort((a, b) => {
                if (new Date(a.last_Message_Sent) > new Date(b.last_Message_Sent)) {
                    return -1;
                }
                else if (new Date(a.last_Message_Sent) < new Date(b.last_Message_Sent)) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }

        return channels.map((channel) => {
            if (channel.last_Message_Sent) {

                if (updated_channels && updated_channels.length > 0) { //filter for updated channels only
                    if (updated_channels.includes(channel.channel)) {
                        return null;
                    }
                }
                return (
                    <div>
                        {channel && user &&
                            <MessagePreview
                                channel={channel}
                                user={user}
                                prepareChat={this.prepareChat}
                            />
                        }
                    </div>
                )
            }
        });
    }

    toggleExportOptions = () => {
        this.setState({
            openExportOptions: !this.state.openExportOptions
        })
    }

    render() {
        const { channels, channel, conversation, show_Chat, loading, status, newUserInfo } = this.state;
        const { hide_channels, updated_channels, channel_type } = this.props;

        let currentPath = window.location.pathname

        let channelList = null;
        let channelList_updated = null;

        if (!hide_channels && channels.length > 0) {
            channelList = this.generateMessageBox(channels, Object.keys(updated_channels));
            channelList_updated = this.generateMessageBox(Object.values(updated_channels));
        }

        return (
            <div>
                {loading && <Loading />}

                {currentPath.includes("/messages") &&
                    <>
                        {/* <button
                            className="defaultWhiteButton exportAllMessagesButton"
                            style={{ border: this.state.iFrameConfig?.primary ? `1px solid ${this.state.iFrameConfig?.primary}` : '1px solid #0C7069', color: this.state.iFrameConfig?.primary ? this.state.iFrameConfig?.primary : '#0C7069'}}
                            onClick={this.exportMessages}
                            disabled={channelList ? false : true}
                        >
                            Export All Messages
                        </button> */}
                        <Dropdown className="whiteButton exportAllMessagesButton" isOpen={this.state.openExportOptions} toggle={this.toggleExportOptions}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                Export All Messages
                            </DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={() => this.exportMessages("excel")}>Excel</DropdownItem>
                                <DropdownItem onClick={() => this.exportMessages("csv")}>CSV</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </>
                }

                {/* Display a list of all messages */}
                {channelList_updated}
                {channelList}

                {!channelList && this.props.displayHelperText &&
                    <p id="noMoreMessagesDiv">You have no messages.</p>
                }

                {/* Display chat box */}
                {this.state.newConversation ?
                    show_Chat &&

                    <ServiceProvidersChatBox_v2
                        channel_type={channel_type}
                        client={newUserInfo}
                        conversationProxy={conversation}
                        closeChat={this.closeChatBox}
                        prepareNewChat={this.prepareNewChat}
                        connectionStatus={status}
                        newConversation={true}
                    />
                    :
                    conversation && channel && show_Chat &&

                    <ServiceProvidersChatBox_v2
                        channel_type={channel_type}
                        client={channel}
                        conversationProxy={conversation}
                        closeChat={this.closeChatBox}
                        connectionStatus={status}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { created_organization } = state.organization_createorganizationalert;
    const { no_organization } = state.organization_noorganizationalert;
    const { setUser } = state;
    const { user } = setUser;
    const { updated_channels } = state.serviceprovider_alert;

    return {
        created_organization,
        no_organization,
        user,
        updated_channels,
    };
}

const connectedMessenger_v2 = connect(mapStateToProps)(Messenger_v2);
export { connectedMessenger_v2 as Messenger_v2 };

