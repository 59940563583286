import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { stripeActions } from '../../../../../_actions';
import { sharedCouponService } from '../../../../../_services';

export const OrderSummary = (props) => {
    const dispatch = useDispatch();

    const { priceIds, coupon } = useSelector(state => state.billingHandler);
    const [total, setTotal] = useState(0);
    const [monthly_total, setMonthlyTotal] = useState(0);
    const [annual_total, setAnnualTotal] = useState(0);
    const [type_month, setTypeMonth] = useState(false);
    const [type_year, setTypeYear] = useState(false);
    const [actual_monthly_total, setActualMonthlyTotal] = useState(0);
    const [actual_annual_total, setActualAnnualTotal] = useState(0);

    const [email_agreement, setEmailAgreement] = useState(false);
    const [terms_privacy_agreement, setTermsPrivacyAgreement] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (priceIds) {
            let total_new = 0;
            let monthly_total_new = 0;
            let annual_total_new = 0;
            let actual_monthly_total_new = 0;
            let actual_annual_total_new = 0;

            let type_month_new = false;
            let type_year_new = false;

            priceIds.selectedPriceIds.forEach((price) => {
                total_new += sharedCouponService.product_is_applicable(price, coupon) ?
                    sharedCouponService.calculateDiscount(price, coupon) : price.unit_amount;
            });

            priceIds.selectedRecurringPriceIds.forEach((price) => {
                const product_is_applicable = sharedCouponService.product_is_applicable(price, coupon);
                const duration = sharedCouponService.get_duration_monthly(coupon);
                const amount = product_is_applicable ?
                    sharedCouponService.calculateDiscount(price, coupon) : price.unit_amount;
                total_new += amount;
                if (price.recurring) {
                    if (price.recurring.interval === "month") {
                        if (!product_is_applicable || duration > 1 || (product_is_applicable && !duration)) {
                            type_month_new = true;
                            monthly_total_new += amount;
                        }

                        if (product_is_applicable && duration) {
                            actual_monthly_total_new += price.unit_amount;
                        }
                    } else if (price.recurring.interval === "year") {
                        if (!product_is_applicable || duration > 1 || (product_is_applicable && !duration)) {
                            type_year_new = true;
                            annual_total_new += amount;
                        }
                        if (product_is_applicable && duration) {
                            actual_annual_total_new += price.unit_amount;
                        }
                    }
                }
            });

            setTotal(total_new);
            setMonthlyTotal(monthly_total_new);
            setAnnualTotal(annual_total_new);
            setActualMonthlyTotal(actual_monthly_total_new);
            setActualAnnualTotal(actual_annual_total_new);

            setTypeMonth(type_month_new);
            setTypeYear(type_year_new);
        }
    })

    function handleAgreement(e) {
        const agreement_type = e.target.id;
        const agreement = e.target.checked;

        if (agreement_type === "email_agreement") {
            setEmailAgreement(agreement);
        } else if (agreement_type === "terms_privacy_agreement") {
            setTermsPrivacyAgreement(agreement);
        }
    }

    function placeOrder(e) {
        e.preventDefault();

        setSubmitted(true);

        const agreements = {
            email_agreement,
            terms_privacy_agreement
        }
        dispatch(stripeActions.setAgreements(agreements));

        //handle payment and address information and submit order
        if (terms_privacy_agreement) {
            props.handleSubmit(agreements);
        }

    }

    return (
        <>
            <div>
                <h2 className="checkoutHeading">Order Summary</h2>
                <hr />
                <div className="checkoutSummaryBody">
                    <p>Billed Today: ${(total / 100).toFixed(2)} USD</p>
                    {type_month && <p>Billed Monthly: ${(monthly_total / 100).toFixed(2)} USD / month</p>}
                    {type_year && <p>Billed Annually: ${(annual_total / 100).toFixed(2)} USD / year</p>}
                    {actual_monthly_total > 0 && <p>After {sharedCouponService.get_duration_monthly(coupon)}: ${(actual_monthly_total / 100).toFixed(2)} USD / month</p>}
                    {actual_annual_total > 0 && <p>After {sharedCouponService.get_duration_annually(coupon)}: ${(actual_annual_total / 100).toFixed(2)} USD / year</p>}
                    <br />
                    <div className="checkoutSummaryTerms">
                        <input id="email_agreement" type="checkbox" onChange={handleAgreement} />
                        <label for="email_agreement">I agree to receive updates, helpful tips and news from OpenGrants.</label>
                    </div>
                    <div className="checkoutSummaryTerms">
                        <input id="terms_privacy_agreement" type="checkbox" onChange={handleAgreement} />
                        <label for="terms_privacy_agreement">I agree to the Terms of Use and Privacy Policy.<span style={{ color: 'red' }}>*</span></label>
                    </div>
                    <button className="checkoutPrimaryButton placeOrderButton" onClick={(e) => { placeOrder(e) }}>Place Order</button>
                    {submitted && !terms_privacy_agreement && <p style={{ color: "red" }}>You must agree to the Terms of Use and Privacy Policy.</p>}
                </div>
            </div>
        </>
    )
}