import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import connectDateRange from './connectDateRange';

class CustomDateRangePicker extends Component {
  static propTypes = {
    currentRefinement: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    refine: PropTypes.func.isRequired,
  };

  state = {
    focusedInput: null,
  };

  onClear = () => this.props.refine({});

  normalizeDateValuesForRender({ from, to }) {
    return {
      from: from && moment(from),
      to: to && moment(to),
    };
  }

  normalizeDateValuesForRefine({ startDate, endDate }) {
    const from =
      startDate
      &&
      moment(startDate)
        .startOf('day')
        .valueOf();

    const to =
      endDate &&
      moment(endDate)
        .endOf('day')
        .valueOf();

    sessionStorage.setItem('from', startDate);
    sessionStorage.setItem('from_time', from / 1000);
    sessionStorage.setItem('to', endDate);
    sessionStorage.setItem('to_time', to / 1000);

    return { from, to };
  }

  render() {
    const { focusedInput } = this.state;
    const { currentRefinement, refine } = this.props;
    const { from, to } = this.normalizeDateValuesForRender(currentRefinement);

    return (
      <div className="dueDates">
        <DateRangePicker
          startDate={from}
          startDateId="from"
          endDate={to}
          endDateId="to"
          onDatesChange={({ startDate, endDate }) =>
            refine(
              this.normalizeDateValuesForRefine({
                startDate,
                endDate,
              })
            )
          }
          focusedInput={focusedInput}
          onFocusChange={focusedInput =>
            this.setState(() => ({
              focusedInput,
            }))
          }
        />
      </div>
    );
  }
}

export default connectDateRange(CustomDateRangePicker);