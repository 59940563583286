import React from 'react';
import { Form, Button, FormCheck, Image } from 'react-bootstrap'
import { Previews } from "../../ServiceProviders/Onboarding/GC_Image_Upload";

const PhoneNumber = ({ form, radioCheck, changeForm, handleNext, setForm }) => {
  const removePreview = () => { }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const setOrgLogo = (file) => {
    getBase64(file).then(base64 => {
      file.preview = "";
      setForm({
        ...form,
        headshot: base64
      });
    })
  }

  const handlePhoneChange = (e) => {
    let phone = e.target.value
    if (phone.length > 13) {
      phone = phone.slice(0, -1)
    }
    setForm({
      ...form,
      phone,
    })
  }

  return (
    <>
      <Form className='w-100 mt-48'>
        <p className='fw-bold'>Phone Number</p>
        <Form.Control
          type='number'
          name='phone'
          value={form.phone}
          onChange={handlePhoneChange}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>Personal Bio</p>
        <Form.Control
          as='textarea'
          name='bio'
          value={form.bio}
          onChange={changeForm}
          className='border border-2 border-light-gray'
          style={{ minHeight: '10rem' }}
        />
        <p className='mt-40 fw-bold'>Profile Photo</p>
        <div className='w-100'>
          <Previews
            removePreview={removePreview}
            setOrgLogo={setOrgLogo}
            imageUrl={form.headshot}
          />
        </div>
        <p className='fw-bold mt-40'>How would you describe your gender?</p>
        <Form.Select
          aria-label="Default select example"
          name='gender'
          value={form.gender}
          onChange={changeForm}
          size='lg'
          className='border border-2 border-light-gray'
        >
          <option >Please select one</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Non-binary">Non-binary</option>
          <option value="I prefer not to say">I prefer not to say</option>
        </Form.Select>
        <p className='fw-bold mt-40'>What is your ethnic background?</p>
        <Form.Select
          aria-label="Default select example"
          size='lg'
          name='ethnicity'
          value={form.ethnicity}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        >
          <option value={null}>Please select one</option>
          <option value="White/Caucasian">White/Caucasian</option>
          <option value="Asian-Eastern">Asian-Eastern</option>
          <option value="Asian-Indian">Asian-Indian</option>
          <option value="Hispanic">Hispanic</option>
          <option value="Black">Black</option>
          <option value="Native-American">Native-American</option>
          <option value="Mixed race">Mixed race</option>
          <option value="I prefer not to say">I prefer not to say</option>
        </Form.Select>
        <p className='fw-bold mt-40'>Do you consider yourself to have a disability?</p>
        <div className='d-lg-flex align-items-center mt-24'>
          <FormCheck className='me-40'>
            <FormCheck.Input
              checked={form.disability === 'yes'}
              type="radio"
              name='disability'
              style={{ height: '1.75rem', width: '1.75rem' }}
              id='yes'
              onChange={radioCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className='me-40'>
            <FormCheck.Input
              checked={form.disability === 'no'}
              type="radio"
              name='disability'
              style={{ height: '1.75rem', width: '1.75rem' }}
              id='no'
              onChange={radioCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
          <FormCheck className='me-40'>
            <FormCheck.Input
              checked={form.disability === 'I prefer not to say'}
              type="radio"
              name='disability'
              style={{ height: '1.75rem', width: '1.75rem' }}
              id='I prefer not to say'
              onChange={radioCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>I prefer not to say</FormCheck.Label>
          </FormCheck>
        </div>
      </Form>
      <div className='mt-80 px-xl-168'>
        <Button
          variant='primary-dark w-100 py-16'
          className='fw-bold fs-3'
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default PhoneNumber