//this file is temporarily not in use

import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { chatService } from '../../_services';
import '../../Styles/ServiceProvider.css';
import  { history } from '../../_helpers';

let actionOptions = [
    {
        value: "createProject",
        label: "Create Project"
    },
    {
        value: "exportMessages",
        label: "Export Messages"
    }
]

const customStyles = {
    control: (base) => ({
        ...base,
        backgroundColor: "#4161BB",
    }),
    menu: (base) => ({
        ...base,
        width: "150px"
    }),
    menuList: (base) => ({
        ...base,
        width: "150px",
    }),
    placeholder: (base) => ({
        ...base,
        color: "white"
    })
}

class ServiceProvidersChatBoxDropDown extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedAction: ""
        }
    }

    handleActionChange = (e) => {
        this.setState({
            selectedAction: e.value
        })

        if (e.value === "createProject") {
            history.push(this.props.organization_Id ? "/create-project?project_Client=" + this.props.organization_Id : "/create-project");
        }
        else if (e.value === "exportMessages") {
            this.exportMessages();
        }

    }

    exportMessages = async () => {

        var blob = await chatService.exportMessages(this.props.channel_id);
        const file = window.URL.createObjectURL(blob);

        var aTag = document.createElement("a");
        aTag.download = "MessageResults.csv"
        aTag.href = file;
        aTag.target = "_blank";

        aTag.click();
    }

    render() {
        return (
            <Select
                value={this.state.selectedAction}
                placeholder="Take Action"
                isClearable={false}
                isSearchable={false}
                onChange={(e) => { this.handleActionChange(e) }}
                options={actionOptions}
                styles={customStyles}
                autosize={false}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
        );
    }
}

function mapStateToProps() {
    return;
}

const connectedServiceProvidersChatBoxDropDown = connect(mapStateToProps)(ServiceProvidersChatBoxDropDown);
export { connectedServiceProvidersChatBoxDropDown as ServiceProvidersChatBoxDropDown };