const mp_project = {
    project_Id: 0, //leave as 0 if creating new project
    project_Title: '', //required
    project_Description: '', //required
    project_Goal: '',
    start_Date: null,
    end_Date: null,
    project_Budget: 0.0,
    project_Status: '', //leave as empty if creating new project. valid status: 'Open', 'Active', 'Complete', 'Cancel'
    documents: [], //list of mp_project_document. leave empty if updating project

    //not mapped
    focus_Tags_List: [], //list of industry focus string
    service_Tags_List: [], //list of industry service string
    
    //do not modify
    client_Organization_Id: 0, 
    created_By_User_Id: '',

    client_Organization: null, //organization
    applications: [], //list of mp_project_application
    focus_Tags: [], //list of mp_project_industry_focus_tag
    service_Tags: [], //list of mp_project_industry_service_tag
    date_Created: new Date().toLocaleDateString(),
    date_Completed: null
}

const mp_project_document = {
    document_Id: 0, //leave as 0 if creating new project document
    project_Id: 0, //required
    segment_Id: 0, //required if uploading with segment
    document_Link: '', 
    document_Name: '', //required if uploading new document

    //not mapped
    document_File: '', //required if uploading new document

    //do not modify
    uploaded_By_Client_Organization_Id: null,
    uploaded_By_Service_Provider_Id: null,
    uploaded_By_User_Id: null,
    date_Created: new Date().toLocaleDateString(),
}

const mp_project_industry_focus_tag = {
    project_Id: 0, //required
    industry_Focus: '' //required
}

const mp_project_industry_service_tag = {
    project_Id: 0, //required
    industry_Service: '' //required
}

const mp_project_application = {
    application_Id: 0, //leave as 0, if creating new project application
    project_Id: 0, //required
    additional_Details: '', //required
    hourly_Rate: null,
    total_Hours: null,
    flat_Rate: null,
    total_Cost: 0.0,
    status: '', //leave as empty if creating new project application. valid status: 'Accepted', 'Rejected'
    documents: [], //list of mp_project_application_document. leave empty if updating application

    //do not modify
    service_Provider_Id: 0,
    applied_By_User_Id: '',
    project: null, //mp_project
    service_Provider: null, //service_Provider
    date_Accepted_Rejected: null,
    accepted_Or_Rejected_By_User_Id: '',
    date_Created: new Date().toLocaleDateString()
}

const mp_project_application_document = {
    document_Id: 0, //leave as 0 if creating new application document
    application_Id: 0, //required
    document_Link: '', 
    document_Name: '', //required if uploading new document

    //not mapped
    document_File: '', //holds file data in bytes. required if uploading new document

    //do not modify
    application: null, //mp_project_application
    date_Created: new Date().toLocaleDateString(),
}

const mp_project_segment = {
    segment_Id: 0,
    project_Id: 0,
    segment_Title: '',
    segment_Description: '',
    segment_Flat_Rate: null,
    segment_Hours: null,
    segment_Hourly_Rate: null,
    segment_Date_Performed: new Date().toLocaleDateString(),

    //not mapped
    documents: [], //list of mp_project_document

    //do not modify
    invoice_Id: 0,
    service_Provider_Id: 0,
    service_Provider_User_Id: '',
    is_Editable: true,
    date_Created: new Date().toLocaleDateString(),

    invoice: null,
    project: null,
    service_Provider: null,

    harvest_project_Task_Id: 0,
    harvest_Task_Id: 0,
    harvest_Time_Entry: 0,
}

export const marketplaceProjectObjects = {
    mp_project,
    mp_project_document,
    mp_project_industry_focus_tag,
    mp_project_industry_service_tag,
    mp_project_application,
    mp_project_application_document,
    mp_project_segment
}