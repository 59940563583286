import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import Button from '../../../_components/dashboardComponents/CustomButtons/Button';

class GoogleLoginPage extends React.Component {

    render() {
        return (
            <div>
                <GoogleLogin
                    clientId="1034864638994-1vgens45tqsu9h85uh7db9kiec1hpmi7.apps.googleusercontent.com"
                    render={renderProps => (
                        <div>
                            <Button
                                className="googleLoginButton"
                                onClick={renderProps.onClick}
                                disabled={this.props.disabled}
                                style={{ background: "none" }}
                            >
                                <p
                                    id="googleLoginText"
                                    style={{ textTransform: "none" }}
                                >
                                    <img
                                        width="20px"
                                        style={{ marginTop: 5, marginRight: 15, float: "left" }}
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                                        alt="Google sign-in"
                                    />
                                    {this.props.location == "login" ? "LOGIN WITH GOOGLE" : "SIGN UP WITH GOOGLE"}
                                </p>
                            </Button>
                            {this.props.disabled == true &&
                                <p style={{ color: "red", fontSize: 12, fontFamily: "open sans", textAlign: "center" }}>Google login is not available in Incognito mode.</p>
                            }
                        </div>
                    )}

                    buttonText="Login"
                    onSuccess={this.props.handleSubmit}
                    onFailure={this.props.handleSubmit}
                    cookiePolicy={'single_host_origin'}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedGoogleLoginPage = connect(mapStateToProps)(GoogleLoginPage);
export { connectedGoogleLoginPage as GoogleLoginPage };

