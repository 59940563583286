import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { pluginService, sharedService } from "../../../../_services";

let accentColor = pluginService.getIFrameColor()

class GS_Onboarding_OrganizationAttributes1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
    }
  }

  render() {
    const { updateNav, new_Organization, user, handleChange, handleSubmit } = this.props;
    const { isNotValid } = this.state;

    const nextPage = () => {
      this.setState({
        isNotValid: true
      });
      if (new_Organization.organization.org_Type && new_Organization.organization.name) {
        this.setState({
          isNotValid: false
        });
        updateNav("onboard_organizationAttributes2")
      }
    }

    const companyName = () => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        new_Organization.organization.name = `${user.name} ${user.surname}`;
        return (
          <input
            className="gc_onboarding-form-input-lg"
            type="text"
            id="organization-standard-basic"
            name="name"
            disabled
            value={new_Organization.organization.name}
            onChange={handleChange}
            maxlength="450"
          // inputProps={{
          //   autocomplete: "new-password",
          //   form: {
          //     autocomplete: "off",
          //   },
          //   maxLength: 450,
          // }}
          />
        )
      } else {
        return (
          <input
            className="gc_onboarding-form-input-lg"
            type="text"
            id="organization-standard-basic"
            name="name"
            value={new_Organization.organization.name}
            onChange={handleChange}
            maxlength="450"
          // inputProps={{
          //   autocomplete: "new-password",
          //   form: {
          //     autocomplete: "off",
          //   },
          //   maxLength: 450,
          // }}
          />
        )
      }
    };

    const submitClicked = async (e) => {
      await handleSubmit(e);
    }

    const submitBtn = (e) => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        new_Organization.organization.name = `${user.name} ${user.surname}`;
        return (
          <button
            className="gc_started-button-next"
            onClick={(e) => submitClicked(e)}
            style={(pathSplit === "org" || this.props.creatingOrg) ? { width: "20%", marginLeft: 0, paddingTop: "1.5%", paddingBottom: "1.5%", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' } : { backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
          >
            Submit <i class="icon-arrow-right" />
          </button>
        )
      } else {
        return (
          <>
            <button
              className="gc_started-button-next"
              onClick={() => nextPage()}
              style={(pathSplit === "org" || this.props.creatingOrg) ? { width: "20%", marginLeft: 0, paddingTop: "1.5%", paddingBottom: "1.5%", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' } : { backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Next <i class="icon-arrow-right" />
            </button>
          </>

        )
      }
    }

    const fillOutLater = () => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        return null;
      } else {
        return (
          <>
            <div className="row center">
              <button
                className="gc_started-button-fillLater"
                onClick={(e) => handleSubmit(e, true)}
                style={{ color: accentColor || '#0C7069' }}
              >
                Fill Out Later
              </button>
              <br />
            </div>
          </>
        )
      }
    }

    //is creating org
    const path = window.location.pathname;
    let pathSplit = path.split('/').pop();

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              {(pathSplit !== "org" && !this.props.creatingOrg) && (new_Organization.organization.org_Type === "Individual/Sole Proprietor" ? "2 of 2" : "2 of 6")}
              {(pathSplit === "org" || this.props.creatingOrg) && (new_Organization.organization.org_Type === "Individual/Sole Proprietor" ? "1 of 1" : "1 of 5")}
            </p>
          </div>
        </div>
        {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ?
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10 complete-color" style={{ backgroundColor: accentColor || '#00C58E' }} />
          </div>
          : <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10" >
              <div className="col-md-4 progress-color" style={{ backgroundColor: accentColor || '#00C58E' }} />
              <div className="col-md-8 gray-progress-color" />
            </div>
          </div>}
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header">Organization Attributes</h1>
            <p>What type of organization do you represent? If you're an individual or sole proprietor, we'll use your name as your Company Name.</p>
            <hr />
            <div className="col-md-12 gc_onboarding-form-col">
              <p className="gc_onboarding-form-input-name-top">
                Type of Organization <span className="red-star">*</span>
              </p>
              <div>
                <select
                  className="gc_onboarding-form-select-lg"
                  name="org_Type"
                  id="organization-standard-basic"
                  value={new_Organization.organization.org_Type}
                  onChange={handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 450,
                  }}
                >
                  <option value="">Select</option>
                  <option value="Individual/Sole Proprietor">Individual/Sole Proprietor</option>
                  <option value="Partnership">Partnership</option>
                  <option value="LLC">LLC</option>
                  <option value="Corporation">Corporation</option>
                  <option value="Nonprofit">Nonprofit</option>
                  <option value="State Government">State Government</option>
                  <option value="Local Government">Local Government</option>
                  <option value="Federal Government">Federal Government</option>
                  <option value="Tribal Government">Tribal Government</option>
                  <option value="Other">Other</option>
                </select>
                {isNotValid && !new_Organization.organization.org_Type && (
                  <p className="red-message">
                    You must enter the type for your organization.
                  </p>
                )}
                <div className="row" style={{ margin: "10px 0", display: "flex", alignItems: "center" }}>
                  <div class="col-md-8" style={{ paddingLeft: 0, textAlign: 'start' }}>
                    <p>Does your organization or government require W-9 vendor setup?</p>
                  </div>
                  <div class="col-md-4" style={{ textAlign: "center", paddingRight: 0 }}>
                    <button
                      onClick={sharedService.getVendorDoc}
                      style={{ color: "white", width: "100%", padding: 10, borderRadius: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                    >
                      Download Our Vendor Documents
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div>
              <p className="gc_onboarding-form-input-name">
                Organization Name <span className="red-star">*</span>
              </p>
              <div>
                {companyName()}
              </div>
              {isNotValid && !new_Organization.organization.name && (
                <p className="red-message">
                  You must enter the name for your organization.
                </p>
              )}
            </div>
            <hr />
          </form>
          <div className="row mobileBtnRow">
            {(pathSplit !== "org") && !this.props.creatingOrg && <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_basics")}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>}
            <div className={(pathSplit !== "org") && !this.props.creatingOrg ? "col-md-6 gc_onboarding-form-col" : "gc_onboarding-form-col singleNextButton"} style={(pathSplit !== "org") && !this.props.creatingOrg ? {} : { textAlign: "center" }}>
              {submitBtn()}
            </div>
          </div>
          {this.props.createOrgError &&
            <p className="red-message">
              We're sorry, there was an issue processing your submission. Please double check entered information and try again.
              If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
            </p>
          }
          {isNotValid && (
            <p className="red-message">
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGS_Onboarding_OrganizationAttributes1 =
  connect(mapStateToProps)(GS_Onboarding_OrganizationAttributes1);
export { connectedGS_Onboarding_OrganizationAttributes1 as GS_Onboarding_OrganizationAttributes1 };

