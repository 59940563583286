import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Previews } from '../OrganizationImageUpload';

class MainOrganizationForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_Logo: true,
            imageError: "",
        }
    }

    removePreview = () => {
        this.setState({
            imageError: "",
            show_Logo: false,
        })
    }

    imageUploadFailed = (msg) => {
        this.setState({
            imageError: msg,
            show_Logo: false,
        })

        setTimeout(() => {
            this.setState({
                imageError: "",
            })
        }, 6000);
    }

    render() {
        const { submitted, organizationPassed } = this.props
        const { show_Logo } = this.state

        let organization = organizationPassed;

        return (
            <div>

                {/* Logo */}
                <div>
                    <p style={{ textAlign: "center", fontFamily: "Ubuntu", fontSize: "20px", fontWeight: "bold" }} >Upload Company Logo or Profile Image (Max 5Mb)</p>
                    <p className='inputFieldSubLabel'>For best display, choose a square aspect image less than 500px in size. </p>
                    {submitted && !organization.logo_URL &&
                        <p className="defaultErrorMessage">You must upload your logo.</p>
                    }
                    <p className="defaultErrorMessage">{this.state.imageError}</p>
                    <Previews
                        removePreview={this.removePreview}
                        imageUploadFailed={this.imageUploadFailed}
                    />
                    <div className="centerFlex">
                        {organization.logo_URL && show_Logo &&
                            <img src={organization.logo_URL} alt="organization logo" style={{ borderRadius: "50%", objectFit: "cover", width: "200px", height: "200px" }} />
                        }
                    </div>
                </div>

                {/* Name */}
                {/* <div>
                    <h4 className="inputFieldLabel">Company Name <span className="red">*</span></h4>
                    {submitted && !organization_namesInput &&
                        <p className="errorMessage">You must specify your organization's name.</p>
                    }
                    <input className="inputField" id="organization_Name" name="organization_namesInput" value={organization_namesInput} required onChange={this.props.updateOrganizationName} />
                    {organizationNameError && <p className="boxText red">This organization name already exists.</p>}
                </div> */}

                {/* Description */}
                {/* <div className="text_section">
                    <h4 className="inputFieldLabel">Company Description  {description_is_required ? <span className="red">*</span> : ''}</h4>
                    {submitted && !organization.description &&
                        <p className="errorMessage">You must specify your organization's description.</p>
                    }
                    <textarea className="textAreaField" name="description" value={organization.description} onChange={this.props.handleChange}></textarea>
                </div> */}

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedMainOrganizationForm = connect(mapStateToProps)(MainOrganizationForm);
export { connectedMainOrganizationForm as MainOrganizationForm };