import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import "../../../../../Styles/MarketplaceProject_v2/NewMarketplaceProject_v2.css";
import { pluginService, sharedService } from '../../../../../_services';
import { NewProjectClient_v2 } from '../../CreateProject_v2/NewProjectClient_v2';
import { NewProjectFinish_v2 } from '../../CreateProject_v2/NewProjectFinish_v2';
import { NewProjectOverview_v2 } from '../../CreateProject_v2/NewProjectOverview_v2';

let accentColor = pluginService.getIFrameColor()

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient(136deg, rgb(33 154 170) 0%, rgb(33 154 169) 50%, rgb(30 67 83) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient(136deg, rgb(33 154 170) 0%, rgb(33 154 169) 50%, rgb(30 67 83) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <SettingsIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select your Client', 'Edit Project Details', 'Final Steps'];
}

function getStepContent(step, project, project_Client, client_Organization_Id, handleInput, handleSelectionChange, handleSelectionInput, all_Organizations, handleProposalInput, handleFormattedInput, submitted, selectedOrg, handleSelectedRate, selectedRate) {
  switch (step) {
    case 0:
      return <NewProjectClient_v2
        project={project}
        project_Client={project_Client}
        handleInput={handleInput}
        handleSelectionChange={handleSelectionChange}
        handleSelectionInput={handleSelectionInput}
        all_Organizations={all_Organizations}
        submitted={selectedOrg}
        client_Organization_Id={client_Organization_Id}
      />;
    case 1:
      return <NewProjectOverview_v2
        project={project}
        project_Client={project_Client}
        client_Organization_Id={client_Organization_Id}
        submitted={submitted}
        handleInput={handleInput}
        handleSelectionChange={handleSelectionChange}
        handleProposalInput={handleProposalInput}
        handleFormattedInput={handleFormattedInput}
        all_Organizations={all_Organizations}
        handleSelectedRate={handleSelectedRate}
        selectedRate={selectedRate}
      />;
    case 2:
      return <NewProjectFinish_v2
        project={project}
        project_Client={project_Client}
        handleInput={handleInput}
        handleSelectionChange={handleSelectionChange}
        all_Organizations={all_Organizations}
      />;
    default:
      return 'Unknown step';
  }
}

const progressBarStyles = makeStyles({
  root: {
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: accentColor || '#00C58E'
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: accentColor || '#00C58E'
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: accentColor || '#00C58E'
    }
  }
});

function LinearProgressWithLabel(activeStep) {
  const steps = getSteps();
  const precentValue = Math.floor(((activeStep + 1) / steps.length) * 100)
  const classes = progressBarStyles();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: "3rem" }}>
        <div className='progressLable'>
          <p>
            {activeStep + 1} of {steps.length}
          </p>
        </div>
        <div style={{ width: '80%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={precentValue}
            classes={{
              root: classes.root
            }}
            color="primary"
          />
        </div>
      </div>
    </>
  );
}

export default function NewMarketplaceProjectSteps_v2(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [submitted, setSubmitted] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState(false);
  const { project, project_Client, client_Organization_Id, handleInput, handleSelectionChange, handleSelectionInput, handleSubmit, all_Organizations, handleProposalInput, handleFormattedInput, handleSelectedRate, selectedRate } = props;

  let accentColor = pluginService.getIFrameColor()

  const handleNext = () => {
    if (activeStep === 0 && !client_Organization_Id){
      setSelectedOrg(true);
    }
    else if (activeStep === 1 &&
      (!project.project_Proposal || //proposal not empty
        !project.project_Proposal.proposal_Title || //title not empty
        sharedService.slateTextIsEmpty(project.project_Proposal.proposal_Scope) //scope not empty
        || (project.project_Proposal.project_Total_Hours && !sharedService.isFloat(project.project_Proposal.project_Total_Hours)) //total hours exists and is not float
        || (project.project_Proposal.project_Hourly_Rate && !sharedService.isInteger(project.project_Proposal.project_Hourly_Rate)) //hourly rate exists and is not integer
        || (project.project_Proposal.project_Flat_Rate && !sharedService.isInteger(project.project_Proposal.project_Flat_Rate)) //falt rate exists and is not integer
      )) {

      setSubmitted(true);

      document.body.scrollTo(0, 0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root} id="newProjectDiv">
      {LinearProgressWithLabel(activeStep)}
      <div id="newProject">
        {activeStep === steps.length ? handleSubmit : (
          <div className='newProjectFormContainer_v2'>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, project, project_Client, client_Organization_Id, handleInput, handleSelectionChange, handleSelectionInput, all_Organizations, handleProposalInput, handleFormattedInput, submitted, selectedOrg, handleSelectedRate, selectedRate)}
            </Typography>
            <div className="row mobileBtnRow">
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-back"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                >
                  Back <i class="icon-arrow-left" />
                </button>
              </div>
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-next"
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                  disabled={
                    (activeStep === 0 && !project_Client) ||
                    (activeStep === 1 && (!project.project_Proposal ||
                      !project.project_Proposal.proposal_Title ||
                      !project.project_Proposal.proposal_Scope || sharedService.slateTextIsEmpty(project.project_Proposal.proposal_Scope)))
                  }
                  style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
}
