import { timerConstants } from "../_constants";

export const openMinimizedWidget = () => {
    return {
        type: timerConstants.MINIMIZED_OPEN,
        status: true,
    };
};

export const closeMinimizedWidget = () => {
    return {
        type: timerConstants.MINIMIZED_CLOSE,
        status: false,
    };
};

export const openFullWidget = () => {
    return {
        type: timerConstants.FULL_OPEN,
        status: true,
    };
};

export const closeFullWidget = () => {
    return {
        type: timerConstants.FULL_CLOSE,
        status: false,
    };
};