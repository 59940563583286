//this file is no longer in use ?

import React from 'react';
import { connect } from 'react-redux';
import { serviceProviderService, chatService, organizationService } from '../../_services';
import { chatActions, organizationActions } from '../../_actions';
import { Client as ConversationsClient } from '@twilio/conversations';
import { ServiceProvidersChatBox } from '../ServiceProviders';
import { Loader as Loading } from '../Shared/Loader';
import '../../Styles/Messenger.css';

class Messenger extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      service_Provider: this.props.service_Provider,
      loading: true,

      conversations: [],
      conversation: null,

      channels: [],
      channel: null,

      has_an_org: true
    }

    this.closeChatBox = this.closeChatBox.bind(this);
  }

  componentDidMount = async () => {
    const { channel_type, is_marketplace } = this.props;

    //if in marketplace, verify user has already created an org
    if (is_marketplace) {
      const user_id = JSON.parse(localStorage.getItem('user')).id;
      var result = await organizationService.getAllOrganizationsByUser(user_id);
      if (!result.organizations
        || Object.keys(result.organizations).length === 0) {
        //allow user to create org
        this.setState({
          has_an_org: false
        });
      }
    }

    if (channel_type === "all" || channel_type === "service_Provider" || channel_type === "client") {
      this.getAllChannels();
    }
    else {
      this.getOrganizationchannels();
    }
  }

  componentDidUpdate = (oldProps, oldState) => {
    const { is_marketplace, channel_type, created_organization } = this.props;
    let { has_an_org } = this.state;

    if ((!is_marketplace || created_organization || (is_marketplace && has_an_org))
      && !channel_type
      && this.props.service_Provider.organization_Id !== oldProps.service_Provider.organization_Id
    ) {
      this.getOrganizationchannels();
    }
    else if ((channel_type === "service_Provider" || channel_type === "client")
      && (this.props.organization_Id !== oldProps.organization_Id || this.props.open_chat !== oldProps.open_chat)
    ) {
      this.messageUserByOrgId(this.state.channels, this.props.organization_Id);
    }
  }

  messageUserByOrgId = async (channel_list, organization_Id) => {
    if (organization_Id) {
      let result = null;
      let desired_channel = null;
      if (this.props.channel_type === "client") {
        result = await serviceProviderService.getChannelByServiceProvider(this.props.user.id, organization_Id);

        desired_channel = channel_list.filter((channel) => {
          if (channel.user_Info.organization_Id == result.channel.organization_Id) {
            return channel;
          }
        });
      }
      else {
        desired_channel = channel_list.filter((channel) => {
          if (channel.user_Info.organization_Id == organization_Id) {
            return channel;
          }
        });
      }

      if (desired_channel.length > 0) {
        this.message_user(desired_channel[0]);
      } else {
        if (this.props.channel_type === "service_Provider") {
          result = await serviceProviderService.getChannelByOrgId(this.props.user.id, organization_Id);
        }

        let desired_channel = {
          user_Info: result.channel
        };

        channel_list.push(desired_channel);
        this.setState({
          channel_list
        }, () => { this.message_user(desired_channel) });
      }
    }
  }

  getAllChannels = async () => {
    const { user, channel_type, organization_Id } = this.props;
    var result = await serviceProviderService.getAllChannels(user.id);
    let channel_list = result.channels;

    if (channel_type === "service_Provider" || channel_type === "client") {
      this.messageUserByOrgId(channel_list, organization_Id);
    }
    this.setState({
      channels: channel_list,
      loading: false,
    });
  }

  getOrganizationchannels = async () => {
    const { user, organization_Id } = this.props;

    const organization_chat = {
      participant_1_user_id: user.id,
      organization_id: organization_Id
    };
    var result = await serviceProviderService.getOrganizationChannels(organization_chat);

    this.setState({
      channels: result.channels,
      loading: false,
    });

    if (this.props.message_user) {
      this.message_user(result.channels[0]);
    }
  }

  componentWillUnmount() {
    if (this.conversationsClient)
      this.conversationsClient.shutdown();
  }

  closeChatBox() {
    this.setState({
      channel: null,
      conversation: null
    });

    if (this.props.close_chat) {
      this.props.close_chat();
    }
  }

  add_participants = async (channel) => {
    const { user } = this.props;

    var chat = {
      participant_1_user_id: user.id,
      participant_2_user_id: channel.user_Info.username,
      channel: channel.channel
    }

    await chatService.add_participants(chat);
  }

  create_conversation = async (client_name) => {
    var result = await chatService.create_conversation(client_name);
    var conversation_ch = result.conversation_ch;
    return conversation_ch;
  }

  message_user = async (channel) => {

    await this.setState({ loading: true, show_Chat: false });

    if (!this.state.has_an_org && !this.props.created_organization) {
      this.props.dispatch(organizationActions.organizationAlert("no_organization"));
    }

    else {
      const { user } = this.props;
      let conversation_ch = channel.channel;

      if (!conversation_ch) {
        conversation_ch = await this.create_conversation(user.name + user.surname + "_" + channel.user_Info.name + channel.user_Info.surname);
        channel.channel = conversation_ch;
        this.setState({ conversation_ch, channel });
        await this.add_participants(channel);
      }

      if (!this.conversationsClient) {
        let result = await chatService.generate_token(user.id);
        let token = result.token;
        //TODO: verify token is generated, token != ""
        this.setState({ token: token, conversation_ch, channel },
          async () => {
            await this.initConversations();
            if (conversation_ch && this.conversationsClient) {
              this.setState({ conversation_ch, channel });
            }

            this.props.dispatch(chatActions.set_channel(channel));
            await this.setState({ loading: false, show_Chat: true });
          })
      }
      else {
        let conversation_list = this.state.conversations.filter(conv => conv.sid === conversation_ch);

        if (conversation_list.length > 0) {
          this.setState({ conversation: conversation_list[0], channel });
          this.props.dispatch(chatActions.set_conversation(conversation_list[0]));
        }

        this.props.dispatch(chatActions.set_channel(channel));
        await this.setState({ loading: false, show_Chat: true });
      }

      if (channel //channel exists
        && channel.last_Message_User_Id //a message was sent
        && channel.last_Message_User_Id !== user.id //message was sent by other user
        && (!channel.last_Message_Read  //message was never read
          || (new Date(channel.last_Message_Read) < new Date(channel.last_Message_Sent)))) //message has not been read yet
      {

        let messageInfo = {
          channel: channel.channel,
          last_Message_Read: new Date()
        }

        chatService.updateChatInfo(messageInfo);


        let channels = this.state.channels.map(conv => {
          if (conv.channel === conversation_ch) {
            conv.last_Message_Read = new Date();
          }
          return conv;
        });

        this.setState({
          channels
        });
      }
    }
  }

  initConversations = async () => {
    this.conversationsClient = await ConversationsClient.create(this.state.token);
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default"
        });
      if (state === "connected") {
        this.setState({
          statusString: "You are connected.",
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error"
        });
    });

    this.conversationsClient.on("conversationJoined", (conversation) => {
      if (this.state.conversations.filter(conv => conv.sid === conversation.sid).length === 0) {
        if (conversation.sid === this.state.conversation_ch) {
          this.setState({
            conversations: [...this.state.conversations, conversation],
            conversation
          });

          this.props.dispatch(chatActions.set_conversation(conversation));
        }
        else {
          // else if (this.state.conversation) {
          this.setState({
            conversations: [...this.state.conversations, conversation],
          });
        }
      }
    });

    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      this.setState({
        conversation: null,
        conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
      });
    });

    this.conversationsClient.on('tokenAboutToExpire', async () => {
      let email = JSON.parse(localStorage.getItem('user')).email;
      let result = await chatService.generate_token(email);
      let token = result.token;
      this.conversationsClient.updateToken(token);
    });
  };

  cvtDateTimeString = (datetime) => {
    var datetime_split = datetime.split("T");

    var date_split = datetime_split[0].split("-");
    if (date_split[1][0] == "0") {
      date_split[1] = date_split[1].substr(1);
    }
    if (date_split[2][0] == "0") {
      date_split[2] = date_split[2].substr(1);
    }
    var date = date_split[1] + "/" + date_split[2] + "/" + date_split[0];

    let meridiem = "AM";

    var time = datetime_split[1].split(".")[0];
    var hour = time.split(":")[0];
    if (parseInt(hour) > 12) {
      var hour_string = (parseInt(hour) - 12).toString();
      time = hour_string + time.substr(2);
      meridiem = "PM";
    }
    if (time[0] == "0") {
      time = time.substr(1);
    }

    var datetime_string = date + ", " + time + " " + meridiem;
    return datetime_string;
  }

  generateMessageBox = (channels, updated_channels) => {
    const user = JSON.parse(localStorage.getItem('user'));

    // let updated_conversation_ch = [];
    // if (updated_channels) {
    //   updated_conversation_ch = Object.keys(this.props.updated_channels);
    // }
    if (!updated_channels || updated_channels.length === 0) {
      channels.sort((a, b) => {
        if (new Date(a.last_Message_Sent) > new Date(b.last_Message_Sent)) {
          return -1;
        }
        else if (new Date(a.last_Message_Sent) < new Date(b.last_Message_Sent)) {
          return 1;
        }
        else {
          return 0;
        }
      });
    }

    let ch = channels.map((channel) => {
      if (channel.last_Message_Sent) {
        // if (updated_conversation_ch.length > 0) {
        //   if (updated_channels) {
        //     if (updated_conversation_ch.includes(channel.channel)) {
        //       channel.last_Message = updated_channels[channel.channel].last_Message;
        //       channel.last_Message_Sent = updated_channels[channel.channel].last_Message_Sent;
        //       channel.last_Message_User_Id = updated_channels[channel.channel].last_Message_User_Id;
        //     } else {
        //       return null;
        //     }
        //   } else {
        //     if (updated_conversation_ch.includes(channel.channel)) {
        //       return null;
        //     }
        //   }
        // }

        if (updated_channels && updated_channels.length > 0) { //filter for updated channels only
          if (updated_channels.includes(channel.channel)) {
            return null;
          }
        }
        let background_color = channel.last_Message_User_Id !== user.id
          && (!channel.last_Message_Read
            || (new Date(channel.last_Message_Read) < new Date(channel.last_Message_Sent)))
          ? "rgb(217, 240, 255)" : ""
        return (
          <div key={channel.user_Info ? channel.user_Info.username : channel.username} id="messageBox" onClick={() => this.message_user(channel)}
            style={{ background: background_color }}>
            {/* <h4>{JSON.stringify(channel.channel)}</h4> */}
            <div className="row">
              <p style={{ float: "right" }}>{channel.last_Message_Sent && (this.cvtDateTimeString(channel.last_Message_Sent))}</p>
              <img
                src={(channel.user_Info && channel.user_Info.logo_URL) ? channel.user_Info.logo_URL : "https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/alternate.png"}
                height="60"
                width="60"
                style={{ float: "left" }}
                alt="user icon"
              />
              <div id="msgText" style={{ float: "left", marginLeft: 15, maxWidth: "65%" }}>
                <h5 style={{ marginTop: 0, marginBottom: 5, fontWeight: 500 }}>
                  <span id="name" style={{ fontSize: 24, fontFamily: 'Raleway', fontWeight: 600, color: "#1e4353" }}>
                    {channel.user_Info ? channel.user_Info.name : channel.name} {channel.user_Info ? channel.user_Info.surname : channel.surname}
                  </span>
                  <br />
                  <span id="orgName" style={{ fontSize: 14, fontWeight: 600, color: "#777" }}>
                    {channel.user_Info ? channel.user_Info.organization_Name : channel.organization_Name}
                  </span>
                </h5><br />
                <span style={{ fontWeight: 900, color: "#777", fontFamily: "open sans", fontSize: 12 }}>Last Message</span><br />
                <strong>{channel.last_Message_User_Id === user.id ? "You" : (channel.user_Info ? channel.user_Info.name : channel.name)}:</strong> {channel.last_Message}
                <br />
              </div>
            </div>
          </div>
        )
      }
      return null;
    });

    return ch.filter(val => { return val !== null });
  }

  render() {
    const { channels, channel, conversation, show_Chat, loading } = this.state;
    const { hide_channels, updated_channels } = this.props;

    let channelList = null;
    let channelList_updated = null;
    if (!hide_channels && channels.length > 0) {
      channelList = this.generateMessageBox(channels, Object.keys(updated_channels));
      channelList_updated = this.generateMessageBox(Object.values(updated_channels));
    }

    return (
      <div>
        {loading &&
          <Loading />
        }
        {channelList_updated}
        {channelList}
        {!hide_channels && ((!loading && !channelList) ||
          channelList && channelList.length === 0 &&
          channelList_updated && channelList_updated.length === 0) &&
          <div id="noMoreMessagesDiv">
            <p>You have no more messages.</p>
          </div>
        }
        {conversation && channel && show_Chat &&
          <div>
            <ServiceProvidersChatBox
              channel_type={this.props.channel_type}
              client={channel}
              conversationProxy={conversation}
              closeChat={this.closeChatBox}
            />
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { created_organization } = state.organization_createorganizationalert;
  const { no_organization } = state.organization_noorganizationalert;
  const { setUser } = state;
  const { user } = setUser;
  const { updated_channels } = state.serviceprovider_alert;

  return {
    created_organization,
    no_organization,
    user,
    updated_channels
  };
}

const connectedMessenger = connect(mapStateToProps)(Messenger);
export { connectedMessenger as Messenger };