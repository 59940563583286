import React from 'react'
import { InsertDriveFileOutlined, LinkOutlined, WarningAmber } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../Styles/GrantWriters.css';
import "../../Styles/ProjectFinder.css";
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import { organizationstripeActions } from '../../_actions';
import messageIcon from '../../_assets/img/marketPlaceMessageIcon.png';
import SlateTextDisplay from '../../_components/dashboardComponents/CustomInput/SlateTextDisplay';
import { history } from '../../_helpers';
import { marketplaceProjectService, organizationService, organizationStripeService, pluginService, sharedService, stripeService, serviceProviderService } from '../../_services';
import { Messenger_v2 } from '../Marketplace/Messenger_v2';
import ServiceProviderModal from '../Marketplace/ServiceProviderModal';
import { SP_Onboarding_Success } from '../ServiceProviders';
import { Loader } from '../Shared/Loader';
import { PaymentHandler } from '../Stripe/Stripe_Copy/PaymentMethod';
import { ApplyModal } from './ApplyModal';
import { CongratulationsModal } from './CongratulationsModal';
import Recommendation from '../Recommendations/Recommendation';
import RecommendationModal from '../Recommendations/RecommendationModal';
import DeleteRecommendationModal from '../Recommendations/DeleteRecommendationModal';
import { recommendationService } from '../../_services/recommendation.service';
import GradeIcon from "@mui/icons-material/Grade"
import chevron from "../../_assets/img/icons/chevron-down.svg"

export function ViewListing({ project_id_passed, onlyProjectDetails, hideTop, hideApplications, fixedProduct, productSp }) {

  const { user } = useSelector(state => state.setUser);
  const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);

  const billingInfo_org = useSelector(state => state.billingInfo_org);

  const dispatch = useDispatch();

  const [project, setProject] = useState(null);
  const [isApplyOpen, setIsApplyOpen] = useState(false);
  const [isCongratsOpen, setIsCongratsOpen] = useState(false);
  const [projectId, setProjectId] = useState(window.location.pathname.split('/').pop());
  const [viewType, setViewType] = useState(getViewType());
  const [projectOrg, setProjectOrg] = useState(null);
  const [openApplicationId, setOpenApplicationId] = useState(null);
  const [selectedApplicant, setSelectedApplicant] = useState({});
  const [applicationSuccess, setApplicationSuccess] = useState(false);
  const [applicationAcceptedRejectedId, setApplicationAcceptedRejectedId] = useState("");
  const [applicationAccepted, setApplicationAccepted] = useState({});
  const [applicationStatusError, setApplicationStatusError] = useState(false);
  const [canEditListing, setCanEditListing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existingRecommendation, setExistingRecommendation] = useState({ recommendationExists: false, currentRecommendation: null })
  //modal states
  const [applicantModalIsOpen, setApplicantModalIsOpen] = useState(false);
  const [applicantMessengerIsOpen, setApplicantMessengerIsOpen] = useState(false);
  const [creatorMessengerIsOpen, setCreatorMessengerIsOpen] = useState(false);
  const [billingModalIsOpen, setBillingModalIsOpen] = useState(false);
  const [spOnboardingIsOpen, setSpOnboardingIsOpen] = useState(false);
  const [hireModalIsOpen, setHireModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [openRecommendationModal, setOpenRecommendationModal] = useState(false);
  const [openDeleteRecommendationModal, setOpenDeleteRecommendationModal] = useState(false);

  let accentColor = pluginService.getIFrameColor()

  //On Mount
  useEffect(() => {
    async function get_project() {
      let retrieved_project = null;

      // origin is url
      if (viewType === 'project') {
        //get project
        retrieved_project = await marketplaceProjectService.get_project(projectId, ['applications', 'service provider', 'recommendations']);
      }
      //origin is project finder page
      else if (viewType === 'project-finder') {
        //get project
        retrieved_project = await marketplaceProjectService.get_project(project_id_passed, ['applications']);
      }
      //redirect 
      else {
        window.location.pathname.includes('/cl/projects') ? history.push('/cl/projects') : history.push('/project-finder');
      }

      //set project if found
      if (retrieved_project) {

        let acceptedApplication

        if (retrieved_project.project_Status === "Active" || retrieved_project.project_Status === "Complete") {

          acceptedApplication = retrieved_project?.applications.find(application => {
            return application.status === "Accepted";
          })

          retrieved_project.service_Provider = acceptedApplication?.service_Provider

        }

        if (retrieved_project.project_Type == "fixed-product") {
          retrieved_project.service_Provider = productSp;
        }

        setProject(retrieved_project);

        //get fixed rate product creator's organization
        if (fixedProduct && window.location.pathname.includes('/cl/projects')) {
          let projectSp = await serviceProviderService.getServiceProviderById(retrieved_project.service_Provider_Id);
          if (projectSp) {
            setProjectOrg(projectSp.organization)
          }
        }
        //get listing creator's or fixed rate product client's organization
        else {
          let projectOrg = await organizationService.getOrganization(user.id, retrieved_project.client_Organization_Id);
          setProjectOrg(projectOrg.org.organization)
        }

        //get creator's org billing info
        dispatch(organizationstripeActions.getOrgBillingInfo(user.id, retrieved_project.client_Organization_Id))

        //get user's default organization
        let userOrg = await organizationService.getFirstOrganizationByUser(user.id);

        //check if user org is the same as project org, if so, allow editing
        if (userOrg.organization_Id === retrieved_project.client_Organization_Id) {
          setCanEditListing(true)
        }

      }
      //redirect if no project found
      else {
        window.location.pathname.includes('/cl/projects') ? history.push('/cl/projects') : history.push('/project-finder');
      }

      if (!((window.location.pathname.includes('/sp/projects') || window.location.pathname.includes('/project-finder/'))
        && retrieved_project.applications && retrieved_project.applications.length > 0 && retrieved_project.applications[0].status === "Rejected")) {
        recommendationExistsCheck(retrieved_project);
      }
    }

    get_project();

  }, []);

  //----------------------------------------------------------------------------------------------Helper Functions

  function getViewType() {
    if (!isNaN(projectId)) { return 'project'; }
    if (!isNaN(project_id_passed)) { return 'project-finder'; }
    return '';
  }

  const downloadFile = async (documentObj) => {
    var blob = await marketplaceProjectService.download_mp_application_document(documentObj);
    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    aTag.download = documentObj.document_Name
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  //Add HTTPS to URLs
  const withHttp = (url) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  //Truncate attachment name for display
  const truncate = (text) => {
    return text.length > 28 ? text.substring(0, 25) + "..." : text;
  };

  // Turn timestamp into this date format: "Jan 10, 2022"
  function formatDate(timestamp) {
    var newDate = new Date(timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = newDate.getFullYear();
    var month = months[newDate.getMonth()];
    var date = newDate.getDate();

    return month + ' ' + date + ', ' + year;
  };

  //----------------------------------------------------------------------------------------------Logic functions

  //Hire applicant
  function hireApplicant(application, status) {
    setLoading(true);
    setApplicationAcceptedRejectedId(application.application_Id);
    handleApplicationStatus(application, status);
    setHireModalIsOpen(false);
  }

  //Reject applicant
  function rejectApplicant(application, status) {
    setApplicationAcceptedRejectedId(application.application_Id);
    handleApplicationStatus(application, status)
  }

  //Change application status to accepted or rejected
  async function handleApplicationStatus(application, status) {
    let updatedBillingInfo = await organizationStripeService.getBillingInfo(project.client_Organization_Id)

    setLoading(true);

    if (updatedBillingInfo && sharedService.hasValidPaymentMethod(updatedBillingInfo.billingInfo)) {

      let applicationObj = Object.assign({}, application)
      applicationObj.status = status;

      let result = await marketplaceProjectService.update_application_status(applicationObj);

      if (result) {
        let updatedProject = await marketplaceProjectService.get_project(projectId, ['applications', 'service provider', 'recommendations']);

        let acceptedApplication

        if (updatedProject.project_Status === "Active" || updatedProject.project_Status === "Complete") {

          acceptedApplication = updatedProject?.applications.find(application => {
            return application.status === "Accepted";
          })

          updatedProject.service_Provider = acceptedApplication.service_Provider

        }

        setProject(updatedProject);
        recommendationExistsCheck(updatedProject);
      }
      else {
        setApplicationStatusError(true)

        setTimeout(() => {
          setApplicationStatusError(false)
        }, 3000);
      }
    }
    else {
      setBillingModalIsOpen(true)
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(false);
  }, [project?.project_Status])

  //Submit billing info and hire applicant
  function handleBillingInfoSubmit() {

    //get application that user wanted to accept
    project.applications.forEach((application) => {
      if (application.application_Id === applicationAcceptedRejectedId) {
        handleApplicationStatus(application, "Accepted")
      }
    })

    //close billing modal
    setBillingModalIsOpen(!billingModalIsOpen);
  }

  //if sp is verified - open application, if not - open onboarding
  async function applyNow() {

    let stripe_Verified = await stripeService.checkStripeConnectVerification(service_Provider.stripe_Account_Id);

    if (sharedService.isSPVerified(service_Provider, stripe_Verified) && sharedService.hasPayoutMethod(stripe_Verified)) {
      setIsApplyOpen(!isApplyOpen)
    }
    else {
      setSpOnboardingIsOpen(true)
    }
  }

  //check if service provider has applied to current listing
  function checkIfApplied() {
    let applied = false;

    if (project && project.applications) {

      project.applications.forEach(application => {
        if (application.service_Provider_Id === service_Provider.service_Provider_Id) {
          applied = true;
        }
      });
    }

    return applied;
  }

  //check if current listing is open
  function checkIfOpen() {
    if (project && project.project_Status !== "Open") {
      return false;
    }
    else {
      return true;
    }
  }

  // async function editListingSubmit() {

  //   projectCopy.project_Description = JSON.stringify(projectCopy.project_Description);

  //   projectCopy.focus_Tags = projectCopy.focus_Tags_List.map((tag) => {
  //     return { projectId: projectCopy.project_Id, industry_Focus: tag }
  //   })

  //   projectCopy.service_Tags = projectCopy.service_Tags_List.map((tag) => {
  //     return { projectId: projectCopy.project_Id, industry_Service: tag }
  //   })

  //   //submit listing 
  //   await marketplaceProjectService.update_project(projectCopy);

  //   let retrieved_project = await marketplaceProjectService.get_project(projectId, ['applications', 'service provider']);

  //   setProject(retrieved_project);
  //   setEditFormOpen(false);
  // }

  // function editListing() {

  //   let tempProject = Object.assign({}, project);

  //   tempProject.project_Description = JSON.parse(tempProject.project_Description);
  //   tempProject.start_Date = tempProject.start_Date ? tempProject.start_Date.split("T")[0] : null;
  //   tempProject.end_Date = tempProject.end_Date ? tempProject.end_Date.split("T")[0] : null;

  //   tempProject.focus_Tags_List = tempProject.focus_Tags.map((tag) => {
  //     return tag.industry_Focus
  //   })

  //   tempProject.service_Tags_List = tempProject.service_Tags.map((tag) => {
  //     return tag.industry_Service
  //   })

  //   setProjectCopy(tempProject);
  //   setEditFormOpen(true);
  // }

  // function handleEditInput(e) {

  //   let name = e.target.name;
  //   let value = e.target.value;

  //   setProjectCopy({
  //     ...projectCopy,
  //     [name]: value
  //   });
  // }

  // function handleEditCategorySelection(categories) {
  //   setProjectCopy({
  //     ...projectCopy,
  //     ...categories
  //   });
  // }

  // function handleEditCategoryInput() {
  //   // setCategoryInput(categoryInputs);
  // };

  async function deleteListing() {

    setLoading(true);
    project.project_Status = 'Cancel';

    await marketplaceProjectService.update_project_status(project);

    let updatedProject = await marketplaceProjectService.get_project(projectId, ['applications', 'service provider', 'recommendations']);
    setProject(updatedProject);
    setDeleteModalIsOpen(false);
    setLoading(false);

  }

  //----------------------------------------------------------------------------------------------Display functions

  //set applications view
  function generateApplicants(isSpApplication) {

    if (project && project.applications && project.applications.length !== 0) {
      return project.applications.map((application) => {

        return (
          <>
            {/* DESKTOP */}
            <div className='desktop-applicant'>

              <div className='row applicant-preview'>
                <div className="col-md-4 applicant-info">
                  <img className="applicant-image" alt="organization logo" src={application.service_Provider.organization.logo_URL ? application.service_Provider.organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} />
                  <div className='applicant-profile'>
                    <p>{application.service_Provider.organization.name}</p>
                    {!isSpApplication &&
                      <a onClick={() => { setSelectedApplicant(application.service_Provider); setApplicantModalIsOpen(true) }}>
                        View Profile
                      </a>
                    }
                  </div>
                </div>

                <div className="col-md-3">
                  {application.flat_Rate !== 0 ?
                    <p>Flat rate: ${(application.total_Cost).toFixed(2)}</p>
                    :
                    <p>Hourly rate: ${(application.total_Cost).toFixed(2)} (${application.hourly_Rate} x {application.total_Hours}hr)</p>
                  }
                </div>

                <div className="col-md-2">
                  <p>{sharedService.trimDate(application.date_Created)}</p>
                </div>

                <div className='col-md-3 applicant-buttons'>
                  {project.project_status !== "Active" && !application.status && !isSpApplication &&
                    <div className='applicant-status'>
                      <button
                        className='defaultGreenButton'
                        onClick={() => { setApplicationAccepted(application); setHireModalIsOpen(true) }}
                        style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                      >
                        Hire me!
                      </button>
                      <button
                        className='defaultWhiteButton'
                        onClick={() => { rejectApplicant(application, "Rejected") }}
                        style={{ marginRight: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                      >
                        Reject
                      </button>
                    </div>
                  }

                  {application.status &&
                    <p style={{ fontFamily: "Ubuntu", margin: 0, color: application.status === "Accepted" ? "green" : "red" }}>{application.status === "Cancel" ? "Canceled" : application.status}</p>
                  }

                  {service_Provider && project.project_Status === "Active" && !application.status &&
                    <p style={{ fontFamily: "Ubuntu", margin: 0, color: "red" }}>Rejected</p>
                  }

                  {/* {openApplicationId && (openApplicationId === application.application_Id) ?
                    <div className="applicant-toggle-up">
                      <img onClick={() => { setOpenApplicationId(null) }} src="/static/media/chevron-down.dbfd6ca0.svg" />
                    </div>
                    :
                    <div className="applicant-toggle-down">
                      <img onClick={() => { setOpenApplicationId(application.application_Id) }} src="/static/media/chevron-down.dbfd6ca0.svg" />
                    </div>
                  } */}

                </div>

              </div>

              {!isSpApplication &&
                <div className='row applicant-preview' style={{ marginTop: -10, marginBottom: -10 }}>
                  <div className="col-md-9" />
                  <div className='col-md-3 applicant-buttons'>
                    <div onClick={() => { setSelectedApplicant(application.service_Provider); setApplicantMessengerIsOpen(true) }} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <img style={{ height: "fit-content", width: 20 }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIkSURBVHgBtVVRbtNAEJ1Zr60KqJQjuCeocwKSG/BDpX7FiATx13CCJieA/iFASvKBAvQDboBvUB8hN6h/Wlm1vdNZ20nbVLU3TvqkeO3VZN/szJsZhArYg5+eyoSHQrj6G4EiAgrTNA5h+i6CBsBHO/6k5TivTpSiISK0Kv4aANAs+X40haaE9uDPCRGMqonWDkD4d3OTfYLp8cLIfvki++eniDSCZlgkSdY1Ic0J+WY+LxPYDkx61a7LrSjXU9geruO8GNYZCev97zfaGHYAIvG6zkZy0j3YGaijn3b/1xBQHiZJMl7Pq4BnAAGyypVv29b/Uh/3CVUAu0OYP9E6LL9d/k2cD+crjYg03dNGjbrGY2RnOR+oB2kiolGplbIs8piLz7ANkMvi29FBRYlFXDYHq8Jnw0tejDvMOpklsm4c8zmcN3hC9URZV5RkflMyIgwKsjiqIisgOgL8uTbIk0p8bY73mE+Zaa/riLTH6Y+35c1eXoBBPUspwWWSGTftcH//Ooi+3LUm2Z93SIF7fzwpwsXKTk8WVqCeLGAQIXYwRNgETGDbjqtnpLBkjx31jCdLKSpZT/K3ZTvJBcfbXW4JK/dXjyZzUDYueA2w93HuZooFQY17bsiDuq1fjFpb/PU4l32dkCrgPSj8TWAP5j6R7PGw7jxlo9XOCjlDrVrEXrnNhZ+1NyZcQoc5SVg8SG7RrJlIqYWwLB2+cN2umEoquAWFAO3bze6OYgAAAABJRU5ErkJggg==" />
                      <p style={{ marginBottom: 0, marginLeft: 5, color: "#337ab7" }}>Message Me</p>
                    </div>
                  </div>
                </div>
              }

              {applicationStatusError && (application.application_Id === applicationAcceptedRejectedId) &&
                <div className="applicant-status-error">
                  <p className='errorMessage'>There was an error performing this action.</p>
                </div>
              }

              {/* {openApplicationId && (openApplicationId === application.application_Id) && */}
              <div className='applicant-expanded'>
                <div className='applicant-proposal'>
                  <h4>Message</h4>
                  <p>{application.additional_Details}</p>
                  <h4 style={{ marginTop: 20, marginBottom: 0 }}>Proposal</h4>
                  {generateAttachments(application.documents)}
                  {/* {window.location.pathname.includes("/cl/projects/") &&
                    <div>
                      <h4>Ratings</h4>
                      {generateRatings(application.service_Provider.recommendations)}
                    </div>
                  } */}
                </div>
              </div>
              {/* } */}

            </div >

            {/* MOBILE */}

            <div className='mobile-applicant'>

              <div className='applicant-preview'>
                <div className="applicant-info">
                  <img className="applicant-image" alt="organization logo" src={application.service_Provider.organization.logo_URL ? application.service_Provider.organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} />
                  <div className='applicant-profile'>
                    <p>
                      {application.service_Provider.organization.name}&nbsp;

                      {application.status &&
                        <span className="applicant-status-name" style={{ color: application.status === "Accepted" ? "green" : "red" }}>
                          ({application.status})
                        </span>
                      }

                      {service_Provider && project.project_Status === "Active" && !application.status &&
                        <span className="applicant-status-name" style={{ color: "red" }}>
                          (Rejected)
                        </span>
                      }

                    </p>
                    {!isSpApplication &&
                      <>
                        <a onClick={() => { setSelectedApplicant(application.service_Provider); setApplicantModalIsOpen(true) }}>
                          View Profile
                        </a>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <img onClick={() => { setSelectedApplicant(application.service_Provider); setApplicantMessengerIsOpen(true) }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIkSURBVHgBtVVRbtNAEJ1Zr60KqJQjuCeocwKSG/BDpX7FiATx13CCJieA/iFASvKBAvQDboBvUB8hN6h/Wlm1vdNZ20nbVLU3TvqkeO3VZN/szJsZhArYg5+eyoSHQrj6G4EiAgrTNA5h+i6CBsBHO/6k5TivTpSiISK0Kv4aANAs+X40haaE9uDPCRGMqonWDkD4d3OTfYLp8cLIfvki++eniDSCZlgkSdY1Ic0J+WY+LxPYDkx61a7LrSjXU9geruO8GNYZCev97zfaGHYAIvG6zkZy0j3YGaijn3b/1xBQHiZJMl7Pq4BnAAGyypVv29b/Uh/3CVUAu0OYP9E6LL9d/k2cD+crjYg03dNGjbrGY2RnOR+oB2kiolGplbIs8piLz7ANkMvi29FBRYlFXDYHq8Jnw0tejDvMOpklsm4c8zmcN3hC9URZV5RkflMyIgwKsjiqIisgOgL8uTbIk0p8bY73mE+Zaa/riLTH6Y+35c1eXoBBPUspwWWSGTftcH//Ooi+3LUm2Z93SIF7fzwpwsXKTk8WVqCeLGAQIXYwRNgETGDbjqtnpLBkjx31jCdLKSpZT/K3ZTvJBcfbXW4JK/dXjyZzUDYueA2w93HuZooFQY17bsiDuq1fjFpb/PU4l32dkCrgPSj8TWAP5j6R7PGw7jxlo9XOCjlDrVrEXrnNhZ+1NyZcQoc5SVg8SG7RrJlIqYWwLB2+cN2umEoquAWFAO3bze6OYgAAAABJRU5ErkJggg==" alt="Message Icon" width="30px" height="22px" />
                          <p style={{ marginBottom: 0, marginLeft: 5, color: "#337ab7" }}>Message Me</p>
                        </div>
                      </>
                    }
                  </div>
                </div>

                <div className='applicant-buttons'>
                  {project.project_status !== "Active" && !application.status && !isSpApplication &&
                    <div className="applicant-status">
                      <button className='defaultGreenButton' onClick={() => { setApplicationAccepted(application); setHireModalIsOpen(true) }} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                        Hire me!
                      </button>
                      <button className='defaultWhiteButton' onClick={() => { rejectApplicant(application, "Rejected") }} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                        Reject
                      </button>
                    </div>
                  }

                  {application.status &&
                    <div className="applicant-status">
                      <p style={{ fontFamily: "Ubuntu", margin: "0 20px 0 0", color: application.status === "Accepted" ? "green" : "red" }}>{application.status}</p>
                    </div>
                  }

                  {service_Provider && project.project_Status === "Active" && !application.status &&
                    <div className="applicant-status">
                      <p style={{ fontFamily: "Ubuntu", margin: "0 20px 0 0", color: "red" }}>Rejected</p>
                    </div>
                  }

                  {openApplicationId && (openApplicationId === application.application_Id) ?
                    <div className="applicant-toggle-up">
                      <img alt="expand application icon" onClick={() => { setOpenApplicationId(null) }} src={chevron} />
                    </div>
                    :
                    <div className="applicant-toggle-down">
                      <img alt="collapse application icon" onClick={() => { setOpenApplicationId(application.application_Id) }} src={chevron} />
                    </div>
                  }
                </div>

              </div>

              {applicationStatusError && (application.application_Id === applicationAcceptedRejectedId) &&
                <div className="applicant-status-error">
                  <p className='errorMessage'>There was an error performing this action.</p>
                </div>
              }

              {openApplicationId && (openApplicationId === application.application_Id) &&
                <div className='applicant-expanded'>
                  <p><b>Submission Date:</b> {sharedService.trimDate(application.date_Created)}</p>
                  <p><b>Quote:</b>
                    {application.flat_Rate !== 0 ?
                      <p>Flat rate: ${(application.total_Cost).toFixed(2)}</p>
                      :
                      <p>Hourly rate: ${(application.total_Cost).toFixed(2)} (${application.hourly_Rate} x {application.total_Hours}hr)</p>
                    }
                  </p>
                  <p><b>Message:</b> {application.additional_Details}</p>
                  <p><b>Proposal:</b> {generateAttachments(application.documents)}</p>
                  {/* <p><b>Ratings:</b></p>
                  {generateRatings(application.service_Provider.recommendations)} */}
                  <div className='buttons'>
                    {project.project_status !== "Active" && !application.status &&
                      <div className="applicant-status">
                        <button className='defaultGreenButton' onClick={() => { setApplicationAccepted(application); setHireModalIsOpen(true) }} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                          Hire me!
                        </button>
                        <button className='defaultWhiteButton' onClick={() => { rejectApplicant(application, "Rejected") }} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                          Reject
                        </button>
                      </div>
                    }
                  </div>
                  {applicationStatusError && (application.application_Id === applicationAcceptedRejectedId) &&
                    <div className="applicant-status-error-expanded">
                      <p className='errorMessage'>There was an error performing this action.</p>
                    </div>
                  }
                </div>
              }

            </div>

            <hr />
          </>
        )
      })
    }
    else {
      return <p style={{ marginLeft: 20 }}>There are currently no applicants.</p>
    }
  }

  function generateAttachments(attachments) {
    if (attachments) {
      return attachments.map(attachment => {
        return (
          <div key={attachment.document_Link} className="attachmentTagBox preview application-attachments">
            {attachment.document_Link &&
              <div className="attachmentTagContent">
                <LinkOutlined className="attachmentTagIcon link" />
                <a className="attachmentTagName" href={withHttp(attachment.document_Link)} target="_blank" rel="noreferrer noopener" >{truncate(attachment.document_Link)}</a>
              </div>
            }
            {attachment.document_Name &&
              <div className="attachmentTagContent">
                <InsertDriveFileOutlined className="attachmentTagIcon" />
                <a className="attachmentTagName" onClick={() => { downloadFile(attachment) }}>{truncate(attachment.document_Name)}</a>
              </div>
            }
          </div>
        )
      });
    }
  }

  // function generateRatings(recommendations) {
  //   return (recommendations.length > 0 ?
  //     <div>
  //       <RecommendationRatings recommendations={recommendations}></RecommendationRatings>
  //     </div> :
  //     <p>This service provider does not have any ratings yet.</p>
  //   )
  // }

  //---------------------------------------------------------------------------------------------Recommendation Functions

  function open_recommendationForm() {

    setOpenRecommendationModal(!openRecommendationModal);

  }

  async function createRecommendation(recommendation) {

    let returnedRecommendation = await recommendationService.createRecommendation(recommendation);

    //Add recommendation to end of SP.recommendation Array
    if (project.service_Provider) {
      project.service_Provider.recommendations.push(returnedRecommendation);
    }

    setExistingRecommendation({ recommendationExists: true, currentRecommendation: returnedRecommendation });
    setOpenRecommendationModal(!openRecommendationModal);

  }

  async function updateRecommendation(recommendation) {

    let returnedRecommendation = await recommendationService.updateRecommendation(recommendation);

    //index of recommendation to update in SP
    let previousRecommendation = project.service_Provider.recommendations.indexOf(project.service_Provider.recommendations.find(obj => {
      return obj.recommendation_Id === returnedRecommendation.recommendation_Id
    }))

    //update SP.recommendations
    project.service_Provider.recommendations[previousRecommendation] = returnedRecommendation;

    setExistingRecommendation({ recommendationExists: true, currentRecommendation: returnedRecommendation });
    setOpenRecommendationModal(!openRecommendationModal);
  }

  function toggle_deleteRecommendationModal(isOpen) {

    setOpenDeleteRecommendationModal(isOpen)

  }

  function deleteRecommendation() {

    recommendationService.deleteRecommendation(existingRecommendation.currentRecommendation.recommendation_Id);

    //index of recommendation to remove in SP
    let deletedRecommendation = project.service_Provider.recommendations.indexOf(project.service_Provider.recommendations.find(obj => {
      return obj.recommendation_Id === existingRecommendation.currentRecommendation.recommendation_Id
    }))

    //removes recommendation from SP
    project.service_Provider.recommendations.splice(deletedRecommendation, 1);

    toggle_deleteRecommendationModal(false);

    setExistingRecommendation({ recommendationExists: false, currentRecommendation: null });
  }

  function recommendationExistsCheck(project) {
    if (project.project_Status === "Active" || project.project_Status === "Complete") {
      let recommendations = project?.service_Provider?.recommendations;

      if (recommendations) {
        recommendations.map(recommendation => {
          if (recommendation.client_Organization_Id === project.client_Organization_Id) {
            setExistingRecommendation({ recommendationExists: true, currentRecommendation: recommendation });
          }
        })
      }
    }
  }



  //get project status
  let projectStatus = "";

  if (project?.project_Status === "Active") {
    projectStatus = <span className="proposalStatus green">{service_Provider && project.applications && project.applications.length !== 0 && (project.applications[0].status !== "Accepted" || !project.applications[0].status) ? <span className='red'>Rejected</span> : "Active"}</span>
  }
  else if (project?.project_Status === "Open") {

    if (service_Provider && project.applications && project.applications.length !== 0) {
      projectStatus = <span className="proposalStatus" style={{ color: "#0C7069" }}>{project.applications[0].status === "Rejected" ? <span className='red'>Rejected</span> : "Applied"}</span>
    }
    else {
      projectStatus = <span className="proposalStatus" style={{ color: "#0C7069" }}>Open</span>
    }

  }
  else if (project?.project_Status === "Cancel") {
    projectStatus = <span className="proposalStatus red">Canceled</span>
  }
  else if (project?.project_Status === "Complete") {
    projectStatus = <span className="proposalStatus green">Completed</span>
  }

  //check if sp already applied
  let alreadyApplied;

  if (service_Provider) {
    alreadyApplied = checkIfApplied();
  }

  //check if project is open
  let projectIsOpen = checkIfOpen()

  return (
    <div>
      {(!project || loading) &&
        <Loader />
      }

      {project && projectOrg &&
        <div id="marketplaceProfile" className='projectListingModal'>
          <div id="mainSettings">

            {!hideTop &&
              <>
                <div style={viewType === "project" ? { marginTop: '1%' } : {}}>

                  {/* BACK TO PROJECTS */}
                  {viewType === 'project' &&
                    <div className="row" style={{ padding: '20px 0px' }}>
                      <a className="projectBackLink" href={pluginService.redirect(window.location.pathname.substring(0, (window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/"))).lastIndexOf("/")))}>
                        <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} />Back to Projects
                      </a>
                    </div>
                  }

                  {/* TITLE & STATUS */}
                  <div className="row HeaderMarketplace" style={viewType === "project" ? { display: "flex", alignItems: "center" } : { paddingTop: "0px !important", display: "flex", justifyContent: "center" }}>
                    <div className="col-md-8 payment-form-col orgPageMobileHeaderCol3Left">
                      <h4 className="acceptingProjectHeader" style={viewType === "project" ? { wordBreak: "break-word" } : { wordBreak: "break-word", display: "flex", justifyContent: "center" }}>{project.project_Title}</h4>
                    </div>
                    {viewType === 'project' &&
                      <div className="col-md-3 payment-form-col orgPageMobileHeaderCol3Right">
                        <p style={{ fontWeight: "bold", fontFamily: "Ubuntu", margin: 0, fontSize: 16 }}>Status: {projectStatus}</p>
                      </div>
                    }
                  </div>
                </div>

                {/* ORGANIZATION */}
                {viewType === 'project-finder' &&
                  <div>
                    <h4 className="grantConsultantHeader" style={{ margin: "20px 0", borderBottom: "1px solid lightgrey" }}>Organization</h4>
                    <div className="marketPlaceFlex row marketPlaceContainer" style={{ display: "flex" }}>
                      <div className="col-md-3 marketPlaceAboutHeader" style={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <img className="marketplaceProfileImg" alt="organization logo" width="60" height="60" src={projectOrg.logo_URL ? projectOrg.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} />
                      </div>
                      <a className="col-md-6 projectListingOrgName" href={pluginService.redirect("/organizations/" + projectOrg.organization_Id)}>
                        <p style={{ margin: 0, fontWeight: "bold" }}>{projectOrg.name}</p>
                      </a>
                      {!canEditListing &&
                        <div className="MessageMeBtn col">
                          <div className="marketPlaceBtn">
                            <button
                              onClick={() => { setCreatorMessengerIsOpen(true) }}
                              style={{ background: "none", fontFamily: "Ubuntu", fontWeight: "bold", fontSize: 15, color: accentColor || '#0C7069', display: "flex", alignItems: "center" }}
                            >
                              <i class="icon-message-icon" style={{ fontSize: 20 }} />
                              Message Them!
                            </button>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </>
            }

            {/* CLIENT INFO */}

            {((service_Provider && viewType === "project") || (fixedProduct)) &&
              <div>
                <div className="row" style={{ display: "flex", margin: "20px 0", borderBottom: "1px solid lightgrey", backgroundColor: "#fafafb" }}>
                  <h4 className="col-md-8 grantConsultantHeader orgPageMobileHeaderCol3Left">Client</h4>
                </div>

                <div className="row paymentRow projectDetailsBottom" style={{ display: 'flex' }}>

                  <div className="col-md-4 applicant-info" style={{ display: 'flex' }}>
                    <img className="circlePicture" alt="organization logo" src={projectOrg.logo_URL ? projectOrg.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"} style={{ borderRadius: "50%", width: 80, height: 80 }} />
                    <div className='applicant-profile'>
                      <p>{projectOrg.name || 'N/A'}</p>
                    </div>
                  </div>

                  <div className='col-md-6 mobileTopMargin' style={{ display: "flex" }}>
                    <div className="col-md-6 flexColStart">
                      <button
                        className="backToProjects"
                        onClick={() => { setCreatorMessengerIsOpen(true) }}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 0 }}
                      >
                        <img src={messageIcon} alt="Message Icon" width="30px" height='22px' style={{ marginRight: "10px" }} />
                        <p className='noMargin spPageBlueText'>Message {service_Provider ? "Client" : "Provider"}</p>
                      </button>
                    </div>

                    <div className="col-md-6 flexColStart">
                      <a className="backToProjects" href={pluginService.redirect("/organizations/" + (fixedProduct ? projectOrg?.organization_Id : project?.client_Organization_Id))} target={pluginService.isIFrame() ? "_self" : "_blank"} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 0, color: "#0565AA" }}>
                        <PeopleAltIcon style={{ marginRight: "8px", fontSize: "30px" }} />
                        <p className='noMargin spPageBlueText'>View Organization</p>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            }

            {/* PROJECT DETAILS */}
            <div>

              <div className="row" style={{ display: "flex", margin: "20px 0", borderBottom: "1px solid lightgrey", backgroundColor: "#fafafb" }}>
                <h4 className="col-md-8 grantConsultantHeader orgPageMobileHeaderCol3Left">Project Details</h4>
                <h4 className="col-md-4 grantConsultantHeader orgPageMobileHeaderCol3Right" style={{ fontWeight: "normal" }}>
                  {/* {canEditListing && project.project_Status === "Open" && viewType === 'project' && !editFormOpen &&
                    <a className="backToProjects " style={{ cursor: 'pointer' }} onClick={() => { editListing() }} color="info">
                      <i className="icon-pencil-icon" />Edit
                    </a>
                  } */}
                </h4>
              </div>

              {/* {editFormOpen ?
                //EDIT FORM
                <>
                  <NewProjectListingForm
                    project={projectCopy}
                    user={user}
                    handleInput={handleEditInput}
                    handleCategorySelection={handleEditCategorySelection}
                    noClientDisplay={true}
                    handleCategoryInput={handleEditCategoryInput}
                  // submitted={submitted}
                  // categoryInput={categoryInput}
                  />
                  <div className="row mobileBtnRow">
                    <div className="col-md-6 gc_onboarding-form-col">
                      <button
                        className="gc_started-button-back"
                        type="submit"
                        onClick={() => { editListingSubmit() }}
                      >
                        <i className="icon-ok" />
                        Update
                      </button>
                    </div>
                    <div className="col-md-6 gc_onboarding-form-col">
                      <button
                        type="button"
                        className="gc_started-button-next"
                        onClick={() => { setEditFormOpen(false); setProjectCopy(null) }}
                      >
                        <i className="icon-cancel" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
                :
                // DISPLAY DETAILS
                <> */}
              <div>
                <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                  <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                    <strong>Description</strong>
                  </div>
                  <div className={`${viewType === "project" ? "col-md-7" : "col-md-8"} marketPlaceAboutDescription`}>
                    <SlateTextDisplay text={project.project_Description || 'N/A'} />
                  </div>
                </div>
                <br />

                {!fixedProduct &&
                  <>
                    <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                      <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                        <strong>Goal</strong>
                      </div>
                      <div className={`${viewType === "project" ? "col-md-7" : "col-md-8"} marketPlaceAboutDescription`}>
                        <p>{project.project_Goal}</p>
                      </div>
                    </div>
                    <br />
                  </>
                }

                {project.focus_Tags && project.focus_Tags.length > 0 &&
                  <div className="row marketPlaceContainer mb-40">
                    <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                      <strong>Focus Area</strong>
                    </div>
                    <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {project.focus_Tags.map(focus_tag => (
                        <div id="wtfTag" className="wtfTagClass" style={{ marginTop: 0 }}>
                          <p style={{ display: "inline" }}>{focus_tag.industry_Focus.trim()}</p>
                        </div>
                      ))}
                    </div>
                    <br />
                  </div>
                }

                {project.service_Tags && project.service_Tags.length > 0 &&
                  <div className="row marketPlaceContainer mb-40">
                    <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                      <strong>Services Required</strong>
                    </div>
                    <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {project.service_Tags && project.service_Tags.map(service_tag => (
                        <div id="wtfTag" className="wtfTagClass" style={{ marginTop: 0 }}>
                          <p style={{ display: "inline" }}>{service_tag.industry_Service.trim()}</p>
                        </div>
                      ))}
                    </div>
                    <br />
                  </div>
                }

                <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                  <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                    <strong>Start Date</strong>
                  </div>
                  <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"} marketPlaceAboutDescription`}>
                    <p>{project.start_Date ? formatDate(project.start_Date) : 'N/A'}</p>
                  </div>
                </div>
                <br />

                {!fixedProduct &&
                  <>
                    <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                      <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                        <strong>End Date</strong>
                      </div>
                      <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"} marketPlaceAboutDescription`}>
                        <p>{project.end_Date ? formatDate(project.end_Date) : 'N/A'}</p>
                      </div>
                    </div>
                    <br />
                  </>
                }

                <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                  <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                    <strong>Project Budget</strong>
                  </div>
                  <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"}`} style={{ padding: 0, color: accentColor || '#0C7069' }}>
                    <p>${project.project_Budget.toLocaleString()}.00 (USD)</p>
                  </div>
                </div>
                <br />

                {/* If project is active or complete */}
                {(project.project_Status === "Active" || project.project_Status === "Complete") &&
                  (!window.location.pathname.includes('/project-finder/') && (window.location.pathname.includes('/sp/projects/')) || window.location.pathname.includes('/cl/projects/')) &&
                  <div className="row marketPlaceContainer" style={{ marginBottom: 10 }}>
                    <div className={`${viewType === "project" ? "col-md-2" : "col-md-3"} marketPlaceAboutHeader`}>
                      <strong>Rating</strong>
                    </div>
                    <div className={`${viewType === "project" ? "col-md-9" : "col-md-8"}`} style={{ paddingLeft: 0 }}>

                      {/* If there is a recommendation for Sp from Client */}
                      {(existingRecommendation.recommendationExists) ?
                        <Recommendation
                          recommendation={existingRecommendation.currentRecommendation}
                          location="project"
                          open_recommendationForm={open_recommendationForm}
                          toggle_deleteRecommendationModal={toggle_deleteRecommendationModal}
                          type={window.location.pathname.includes("/sp/projects") ? "service_Provider" : "client"}
                        />
                        :
                        <>
                          {window.location.pathname.includes("/sp/projects") ?
                            <p>This client has not left a recommendation for you yet.</p>
                            :
                            <div className='writeRecommendationContainer'>
                              <p>You have not left a recommendation for the service provider assigned to this project yet.</p>
                              <button
                                className="backToProjects"
                                onClick={() => open_recommendationForm()}
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "2px 8px", marginTop: "10px", border: "2px solid lightgrey", borderRadius: "5px", width: "fit-content" }}
                              >
                                <GradeIcon style={{ marginRight: "3px", fontSize: "27px" }} />
                                <p className='noMargin spPageBlueText' style={{ lineHeight: "1.4" }}>Write a Recommendation</p>
                              </button>
                            </div>
                          }
                        </>

                      }


                    </div>
                  </div>}
              </div>
              {/* </>
              } */}

            </div>

            <br />

            {!hideApplications &&
              <>
                {/* APPLY NOW BUTTON */}
                {!canEditListing &&
                  <div className="marketPlaceFlex row marketPlaceContainer" style={{ justifyContent: "center" }}>

                    {/*user has not applied */}
                    {service_Provider && projectIsOpen && !alreadyApplied && !applicationSuccess &&
                      <button onClick={() => { applyNow() }} className="defaultGreenButton" style={{ width: 175, padding: "10px 15px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                        <i className="icon-pencil-icon" />Apply Now
                      </button>
                    }

                    {/* user has applied (modal view) */}
                    {projectIsOpen && (alreadyApplied || applicationSuccess) && !window.location.pathname.includes("/sp/projects") &&
                      <p className="already-applied-banner">
                        <i className="icon-ok" />
                        You already applied. You'll be notified if you're awarded.
                      </p>
                    }

                    {/* project not open */}
                    {!projectIsOpen && !window.location.pathname.includes("/sp/projects") &&
                      <p className="not-open-banner">
                        <WarningAmber />
                        This project is no longer open.
                      </p>
                    }
                  </div>
                }

                {/* SP APPLICATION DISPLAY */}
                {window.location.pathname.includes("/sp/projects") &&
                  <>
                    {/* DESKTOP */}
                    <div className='desktop-applications'>
                      <div className="row applications-header">
                        <h4 className="col-md-4">My Application</h4>
                        <h4 className="col-md-3">Quote</h4>
                        <h4 className="col-md-2">Submission Date</h4>
                        <h4 className="col-md-3" />
                      </div>
                      {generateApplicants(true)}
                    </div>

                    {/* MOBILE */}
                    <div className='mobile-applications'>
                      <div className="applications-header">
                        <h4>My Application</h4>
                      </div>
                      {generateApplicants(true)}
                    </div>
                  </>
                }

                <br />
              </>
            }

            {/* APPLICATIONS */}

            {viewType !== 'project-finder' && canEditListing && !fixedProduct &&
              <>
                {/* DESKTOP */}
                <div className='desktop-applications'>
                  <div className="row applications-header">
                    <h4 className="col-md-4">Applicants</h4>
                    {project.applications.length !== 0 &&
                      <>
                        <h4 className="col-md-3">Quote</h4>
                        <h4 className="col-md-2">Submission Date</h4>
                      </>
                    }
                    <h4 className="col-md-3" />
                  </div>
                  {generateApplicants()}

                  < br /><br />
                  {project.project_Status === "Open" &&
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button className="delete-listing-button" onClick={() => { setDeleteModalIsOpen(true) }} color="info">
                        <i className="icon-cancel" />Delete Project
                      </button>
                    </div>
                  }
                </div>

                {/* MOBILE */}
                <div className='mobile-applications'>
                  <div className="applications-header">
                    <h4>Applicants</h4>
                  </div>
                  {generateApplicants()}

                  < br /><br />
                  {project.project_Status === "Open" &&
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button className="delete-listing-button" onClick={() => { setDeleteModalIsOpen(true) }} color="info">
                        <i className="icon-cancel" />Delete Project
                      </button>
                    </div>
                  }

                </div>
              </>
            }
          </div>
        </div>
      }

      {/* MODALS */}

      {isApplyOpen &&
        <ApplyModal
          isOpen={isApplyOpen}
          project={project}
          toggleApplyModal={() => { setIsApplyOpen(!isApplyOpen) }}
          toggleCongratsModal={() => { setIsCongratsOpen(!isCongratsOpen) }}
          applicationSuccess={setApplicationSuccess}
        />
      }
      {isCongratsOpen &&
        <CongratulationsModal
          isOpen={isCongratsOpen}
          toggleCongratsModal={() => { setIsCongratsOpen(!isCongratsOpen) }}
        />
      }
      {applicantModalIsOpen &&
        <ServiceProviderModal
          openModal={applicantModalIsOpen}
          service_Provider={selectedApplicant}
          applicantId={selectedApplicant.service_Provider_Id}
          viewservice_Provider={() => { setApplicantModalIsOpen(!applicantModalIsOpen) }}
        />
      }
      {applicantMessengerIsOpen &&
        <Messenger_v2
          channel_type={"client"}
          hide_channels={true}
          organization_Id={selectedApplicant.organization_Id}
          open_chat={applicantMessengerIsOpen}
          close_chat={setApplicantMessengerIsOpen}
          fromMarketplace={true}
        />
      }
      {creatorMessengerIsOpen &&
        <Messenger_v2
          channel_type={"service_Provider"}
          hide_channels={true}
          organization_Id={projectOrg.organization_Id}
          open_chat={creatorMessengerIsOpen}
          close_chat={setCreatorMessengerIsOpen}
          fromMarketplace={true}
        />
      }
      {spOnboardingIsOpen &&
        <Modal
          isOpen={spOnboardingIsOpen}
          toggle={() => { setSpOnboardingIsOpen(!spOnboardingIsOpen) }}
          centered={true}
          backdrop={true}
          fade={false}
          className="ModalDiv"
          size='xl'
        >
          <ModalHeader toggle={() => { setSpOnboardingIsOpen(!spOnboardingIsOpen) }} style={{ borderBottom: "none" }} />
          <ModalBody>
            <SP_Onboarding_Success modalView={true} handleClosing={() => { setSpOnboardingIsOpen(false) }} />
          </ModalBody>
          <ModalFooter style={{ borderTop: "none" }} />
        </Modal>
      }
      {billingModalIsOpen &&
        <Modal
          isOpen={billingModalIsOpen}
          centered={true}
          backdrop={true}
          toggle={() => { setBillingModalIsOpen(!billingModalIsOpen) }}
          fade={false}
          size="xl"
        >
          <ModalHeader toggle={() => { setBillingModalIsOpen(!billingModalIsOpen) }} style={{ borderBottom: "none", justifyContent: 'end' }} />
          <ModalBody className='modal-padding' style={{ paddingTop: '0 30px 30px 30px' }}>
            <p>Please enter your payment information to accept the project.</p>
            {applicationAccepted.flat_Rate !== 0 ?
              <p style={{ color: "#337ab7" }}>You will be charged immediately. Funds will be held in escrow until the project is completed.</p>
              :
              <>
                <p>
                  You will not be charged until your expert logs hours. Actual costs may vary.
                  You'll receive an invoice on the Saturday of every week in which your expert completes work.
                </p>
                <p style={{ color: "#337ab7" }}>
                  It's your responsibility to communicate your needs with your grant consultant to avoid over-billing.
                </p>
              </>
            }
            <PaymentHandler
              type={'org'}
              org_Id={project.client_Organization_Id}
              billingInfo={billingInfo_org}
              cancelButton={() => { setBillingModalIsOpen(!billingModalIsOpen) }}
              submitButton={() => { handleBillingInfoSubmit() }}
            />
          </ModalBody>
        </Modal>
      }
      {hireModalIsOpen &&
        <Modal
          isOpen={hireModalIsOpen}
          centered={true}
          fade={false}
          size='xl'
          id='listingPreviewModal'
        >
          <ModalHeader className="invoiceModalHeader" style={{ textAlign: 'center', borderBottom: "none", fontWeight: 700, fontSize: 18 }}>
            Are You Sure You Want To Hire This Applicant?
          </ModalHeader>

          <ModalBody style={{ textAlign: "center" }}>
            {true ?
              <p>You will be charged immediately. Funds will be held in escrow until the project is completed.</p>
              :
              <p>
                You will not be charged until your expert logs hours. Actual costs may vary.
                You'll receive an invoice on the Saturday of every week in which your expert completes work.
                It's your responsibility to communicate your needs with your grant consultant to avoid over-billing.
              </p>
            }
          </ModalBody>

          <ModalFooter style={{ textAlign: 'center', borderTop: 'none', justifyContent: 'center', marginBottom: 30 }}>
            <button
              style={{
                minWidth: "fit-content",
                width: "20%",
                fontSize: 14,
                padding: '10px',
                marginRight: 15,
                borderRadius: 5,
                backgroundColor: '#ffff',
                fontWeight: 600,
                color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
              }}
              onClick={() => { setHireModalIsOpen(false) }}
            >
              Cancel
            </button>
            <button
              style={{
                minWidth: "fit-content",
                width: "20%",
                fontSize: 14,
                padding: '10px',
                color: '#fff',
                borderRadius: 5,
                fontWeight: 600,
                backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
              }}
              onClick={() => { hireApplicant(applicationAccepted, "Accepted") }}
            >
              Hire
            </button>
          </ModalFooter>
        </Modal>
      }
      {deleteModalIsOpen &&
        <Modal
          isOpen={deleteModalIsOpen}
          centered={true}
          fade={false}
          size='xl'
          id='listingPreviewModal'
        >
          <ModalHeader className="invoiceModalHeader" style={{ textAlign: 'center', borderBottom: "none", fontWeight: 700, fontSize: 18 }}>
            Are You Sure You Want To Delete This Project?
          </ModalHeader>

          <ModalFooter style={{ textAlign: 'center', borderTop: 'none', justifyContent: 'center' }}>
            <button
              style={{
                minWidth: "fit-content",
                width: "20%",
                fontSize: 14,
                padding: '10px',
                marginRight: 15,
                borderRadius: 5,
                backgroundColor: '#ffff',
                fontWeight: 600,
                color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
              }}
              onClick={() => { setDeleteModalIsOpen(false) }}
            >
              Cancel
            </button>
            <button
              style={{
                minWidth: "fit-content",
                width: "20%",
                fontSize: 14,
                padding: '10px',
                color: '#fff',
                borderRadius: 5,
                fontWeight: 600,
                backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
              }}
              onClick={() => { deleteListing() }}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>

      }
      {/* Modal for Recommendation */}
      {openRecommendationModal &&
        <RecommendationModal
          openModal={openRecommendationModal}
          open_recommendationForm={open_recommendationForm}
          service_Provider_Id={project?.service_Provider?.service_Provider_Id}
          recommendationExists={existingRecommendation.recommendationExists}
          createRecommendation={createRecommendation}
          updateRecommendation={updateRecommendation}
          currentRecommendation={existingRecommendation.currentRecommendation}
        />
      }

      {/* Modal for Recommendation */}
      {
        openDeleteRecommendationModal &&
        <DeleteRecommendationModal
          modalOpen={openDeleteRecommendationModal}
          toggle_deleteRecommendationModal={toggle_deleteRecommendationModal}
          deleteRecommendation={deleteRecommendation}
        />
      }
    </div >
  )
}