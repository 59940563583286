import React from 'react';

import StarIcon from '@mui/icons-material/Star';

export default function RecommendationRatings({ recommendations }) {

    //Necessary Calculations
    let numOfRecommendations = 0;
    let existingRatings = {
        One: 0,
        Two: 0,
        Three: 0,
        Four: 0,
        Five: 0
    }

    recommendations.map(recommendation => {
        switch (recommendation.recommendation_Rating) {
            case 1:
                existingRatings.One++;
                break;
            case 2:
                existingRatings.Two++;
                break;
            case 3:
                existingRatings.Three++;
                break;
            case 4:
                existingRatings.Four++;
                break;
            case 5:
                existingRatings.Five++;
                break;
        }

        numOfRecommendations++;
    });

    let averageRating = (existingRatings.One + (existingRatings.Two * 2) + (existingRatings.Three * 3) + (existingRatings.Four * 4) + (existingRatings.Five * 5)) / numOfRecommendations

    for (const numOfRatings in existingRatings) {
        existingRatings[numOfRatings] = (existingRatings[numOfRatings] / numOfRecommendations) * 100
    }


    return (
        <div className="row ratingsContainer">

            <div className="ratingsNumbers">
                <p style={{ color: "grey", marginBottom: 5, textAlign: "center" }}><span class="rating">{averageRating.toFixed(1)}</span>/5.0</p>
                <p style={{ color: "grey", fontSize: 14, textAlign: "center", marginBottom: 0 }}>{numOfRecommendations}{numOfRecommendations === 1 ? " Rating" : " Ratings"}</p>
            </div>

            <div className="row ratingsRatios">
                <div className="col-4 starIcons" style={{ padding: 0, margin: 0 }}>
                    <div className="row starContainer">
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                    </div>
                    <div className="row starContainer">
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                    </div>
                    <div className="row starContainer">
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                    </div>
                    <div className="row starContainer">
                        <StarIcon class="starIcon barStar"></StarIcon>
                        <StarIcon class="starIcon barStar"></StarIcon>
                    </div>
                    <div className="row starContainer">
                        <StarIcon class="starIcon barStar"></StarIcon>
                    </div>
                </div>
                <div className="col-8 ratingsBars" style={{ padding: 0, margin: 0 }}>
                    <div className="row ratingsBarContainer">
                        <div className="ratingsBar">
                            <div className="ratingsBarFill" style={{ width: `${existingRatings.Five}%` }}></div>
                        </div>
                    </div>
                    <div className="row ratingsBarContainer">
                        <div className="ratingsBar">
                            <div className="ratingsBarFill" style={{ width: `${existingRatings.Four}%` }}></div>
                        </div>
                    </div>
                    <div className="row ratingsBarContainer">
                        <div className="ratingsBar">
                            <div className="ratingsBarFill" style={{ width: `${existingRatings.Three}%` }}></div>
                        </div>
                    </div>
                    <div className="row ratingsBarContainer">
                        <div className="ratingsBar">
                            <div className="ratingsBarFill" style={{ width: `${existingRatings.Two}%` }}></div>
                        </div>
                    </div>
                    <div className="row ratingsBarContainer">
                        <div className="ratingsBar">
                            <div className="ratingsBarFill" style={{ width: `${existingRatings.One}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}