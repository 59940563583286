import React from 'react'
import onboardingCircleLgWGuy from '../../../../_assets/img/onboardingCircleLgWGuy.png'
import OnboardingBase from '../../Components/OnboardingBase'

const Onboarding_Intro = () => {
  return (
    <OnboardingBase bgImage={``} hideGuyCharacter={true} hideGirlCharacter={true} hideCard={true}>
      <img style={{ width: 'auto', marginTop: '-200px' }} src={onboardingCircleLgWGuy} />
    </OnboardingBase>
  )
}

export default Onboarding_Intro