import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import ReactPasswordStrength from 'react-password-strength';
import { connect } from 'react-redux';
import "../../../Styles/Login_v2.css";
import "../../../Styles/Login_v3.css";
import { authenticateActions } from '../../../_actions';
import userIcon from "../../../_assets/img/icons/user-icon.svg";
import abstract from "../../../_assets/img/login-abstract-round.svg";
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from "../../../_helpers";
import { asyncLocalStorage, pluginService, registrationService, sharedService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import { GoogleLoginPage } from "../LoginPage/StyledGoogleButton";
const striptags = require('striptags');

class RegisterPage_v2 extends React.Component {
    state = {
        user: {},
        submitted: false,
        captcha: sharedService.isTestWebsite(),
        emailExists: false,
        emailFormat: true,
        confirmPasswordValid: false,
        password: '',
        passwordValid: false,
        themeColor: '',
        google_Registration: false,
        loading: false,
        show_password: false,
        show_password_img: "show_password.png",
        google_Registration_Option: true,
        googleAccExists: false,
        iFrameConfig: null,
        passwordSecureError: false,
        google_initialized: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        passwordError: false,
        confirmPasswordError: false,
    }

    divRef = React.createRef();

    async componentDidMount() {
        let iFrameUrl = '';
        await asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value });
            iFrameUrl = value?.url;
        });
        this.props.dispatch(authenticateActions.logout(iFrameUrl));

        const path = window.location.pathname;
        if (path.toLowerCase() !== "/register" && path !== "/register/null") {
            const pathSplit = path.split('/');
            const pathSplit_pop = path.split('/').pop();
            //partner
            if (path.includes("partner")) {
                const partnerName = pathSplit_pop;
                this.setState({
                    user: {
                        ...this.state.user,
                        partner: partnerName,
                    }
                });
            }
            //org invitations
            else if (path.includes("organization_invitations")) {
                this.setState({
                    organization_Id: pathSplit_pop
                });

                const redirect = sharedService.getRedirect(pathSplit);
                if (redirect) {
                    sessionStorage.setItem('redirect', redirect);
                }
            }
            //redirect
            else {
                const redirect = sharedService.getRedirect(pathSplit);
                if (redirect) {
                    sessionStorage.setItem('redirect', redirect);
                }
            }
        }
    }
    componentDidUpdate() {
        if (this.props.user?.token) {
            if (this.state.iFrameConfig?.url) {
                window.location.href = pluginService.redirect('/grant-seeker/onboarding');
            } else {
                let page_redirect = "/register/type";
                const { organization_Id } = this.state;
                if (organization_Id) {
                    page_redirect = "/register/type/org/" + organization_Id;
                    sessionStorage.setItem('redirect', '');
                }
                window.location.href = pluginService.redirect(page_redirect);
            }
        }

        if (this.divRef.current && !this.state.google_initialized) {
            if (!window.google) {
                setTimeout(() => {
                    if (window.google) {
                        window.google.accounts.id.initialize({
                            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                            callback: window.handleGoogleRegister,
                        });
                        window.google.accounts.id.renderButton(this.divRef.current, {
                            type: 'standard',
                            size: 'large',
                            theme: 'outline',
                            text: 'signup_with',
                            shape: 'rectangular',
                            logo_alignment: 'left'
                        });
                    }
                }, 500);
            } else {
                window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: window.handleGoogleRegister,
                });
                window.google.accounts.id.renderButton(this.divRef.current, {
                    type: 'standard',
                    size: 'large',
                    theme: 'outline',
                    text: 'signup_with',
                    shape: 'rectangular',
                    logo_alignment: 'left'
                });
            }

            this.setState({
                google_initialized: true
            })
        }
    }

    goToLogin = () => {
        if (history.location.state && history.location.state.from) {
            var url =
                history.location.state.from.pathname +
                history.location.state.from.search +
                this.state.iFrameConfig?.url;
            sessionStorage.setItem("redirect", url);
        } else {
            const path = window.location.pathname;
            let pathSplit = path.split("/");
            const redirect = sharedService.getRedirect(pathSplit);
            if (redirect) {
                sessionStorage.setItem("redirect", redirect);
            }
        }
        if (this.state.iFrameConfig?.public) {
            window.location.href = "/login" + this.state.iFrameConfig?.url;
        } else {
            window.location.href = "/login";
        }
    }

    handleLogin = async (user) => {
        this.props.dispatch(authenticateActions.setUser(user));

        window.heap.identify(user.id);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            user: {
                ...this.state.user,
                [name]: value
            }
        })
    }

    handlePasswordView(e) {
        this.setState((prevState) => ({
            show_password: !prevState.show_password,
            show_password_img: this.state.show_password
                ? "show_password.png"
                : "hide_password.png",
        }));
    }

    handleSubmit = async (e) => {
        var { user } = this.state;

        e.preventDefault();

        if (!user.Name || !user.Surname || !user.email || !user.Password || !user.ConfirmPassword) {
            if (!user.Name) { this.setState({ firstNameError: true }) } else { this.setState({ firstNameError: false }); console.log('WREGVGERGF') }
            if (!user.Surname) { this.setState({ lastNameError: true }) } else { this.setState({ lastNameError: false }) }
            if (!user.email) { this.setState({ emailError: true }) } else { this.setState({ emailError: false }) }
            if (!user.Password) { this.setState({ passwordError: true }) } else { this.setState({ passwordError: false }) }
            if (!user.ConfirmPassword) { this.setState({ confirmPasswordError: true }) } else { this.setState({ confirmPasswordError: false }) }
            return
        } else {
            this.setState({
                firstNameError: false,
                lastNameError: false,
                emailError: false,
                passwordError: false,
                confirmPasswordError: false
            })
        }

        this.setState({ loading: true })

        let emailExists = false;
        let emailFormat = false;
        let valid = true;

        user.Name = striptags(user.Name?.trim());
        user.Surname = striptags(user.Surname?.trim());
        user.email = user.email?.trim();

        //confirm form valid
        if (!user.Password || !user.ConfirmPassword || user.Password !== user.ConfirmPassword) {
            valid = false;
        }
        if (!user.Name || !user.Surname || !this.state.captcha)
            valid = false;

        if (!user.email) {
            valid = false;
        }
        else {
            let emailState = await registrationService.checkEmail(user);

            if (!emailState.valid) {
                if (emailState.type === "format") {
                    valid = false;
                    emailFormat = true;
                } else if (emailState.type === "present") {
                    valid = false;
                    emailExists = true;
                }
            }
        }

        let passwordSecure = sharedService.passwordSecure(user.Password, user.email);

        if (!passwordSecure) {
            this.setState({
                passwordSecureError: true
            })
        }

        //register
        if (valid && this.state.passwordValid && passwordSecure) {

            const server = window.location.protocol + '//' + window.location.host;

            //headshot file
            user.file_string = localStorage.getItem('fileBase64');

            //register
            var user = await registrationService.register(user, server, null);
            if (user) {
                this.handleLogin(user);
            }
        }

        this.setState({
            ...this.state,
            emailExists: emailExists,
            emailFormat: emailFormat,
            submitted: true,
            loading: false
        });
    }

    handleGoogleSubmit = async (google) => {
        this.setState({ loading: true })
        if (google.error == "idpiframe_initialization_failed") {
            this.setState({
                google_Registration_Option: false,
            });
        }
        else {
            this.setState({
                google_Registration: true,
                loading: true,
            });
            const server = window.location.protocol + '//' + window.location.host;

            try {
                let user = await registrationService.register(null, server, google);

                if (user) {
                    if (!user.token) {

                        this.setState({
                            emailExists: true,
                            loading: false
                        });

                        return;
                    }
                    else {
                        this.handleLogin(user);
                    }
                }
            }
            catch {
                this.setState({
                    loading: false,
                })
            }
        }

        this.setState({ loading: false });
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            passwordValid: state.isValid,
            passwordSecureError: false,
            user: {
                ...prevState.user,
                Password: state.password,
            }
        }), () => { });
    }

    render() {
        const {
            user,
            submitted,
            emailExists,
            emailFormat,
            captcha,
            loading,
            google_Registration_Option,
            iFrameConfig,
            passwordSecureError,
            passwordValid,
            firstNameError,
            lastNameError,
            emailError,
            passwordError,
            confirmPasswordError,
        } = this.state;
        // const classes = this.useStyles;

        return (
            <div style={{ background: iFrameConfig?.public ? "lightgrey" : "#1a5a83", minHeight: "100vh" }}>
                <div className="login-page-container" style={{ backgroundColor: iFrameConfig?.public ? 'lightgray' : '#1a5a83' }}>
                    {loading && <Loader />}

                    {/* Background image */}
                    {!iFrameConfig?.public &&
                        <img src={abstract} alt="" className="login-background-image" />
                    }

                    {/* Signup card */}
                    <div className="login-card">

                        {/* Logo */}
                        <div className="login-logo-div">
                            <img src={iFrameConfig?.logo ? iFrameConfig?.logo : logo} alt="" className="text-center mb-48" style={{ height: iFrameConfig?.public ? '100px' : 'auto', width: iFrameConfig?.public ? 'auto' : '85%' }} />
                        </div>
                        <div className="text-center mb-48">
                            <p>7 day free trial, then $19/month. No credit card required to start.</p>
                        </div>

                        <form noValidate autoComplete="off" onSubmit={(e) => { this.handleSubmit(e) }}>

                            {/* First name */}
                            <div>
                                <div className='d-flex align-items-center mb-16'>
                                    <p className="card-text mb-0">First Name</p>
                                    {/* {firstNameError && <p className='ms-32 mb-0 text-danger'>required</p>} */}
                                </div>
                                <input
                                    required
                                    id="standard-basic"
                                    label="FIRST NAME"
                                    name="Name"
                                    value={user.Name}
                                    onChange={this.handleChange}
                                    className="email-text-field font-input-default"
                                />
                                {firstNameError &&
                                    <p className="login-error"> First name is required</p>
                                }
                            </div>

                            {/* Last name */}
                            <div>
                                <p className="card-text">Last Name</p>
                                <input
                                    required
                                    id="standard-basic"
                                    label="LAST NAME"
                                    name="Surname"
                                    value={user.Surname}
                                    onChange={this.handleChange}
                                    className="email-text-field font-input-default"
                                />
                                {lastNameError &&
                                    <p className="login-error">Last name is required</p>
                                }
                            </div>

                            {/* Email */}
                            <div>
                                <p className="card-text">Email Address</p>
                                <input
                                    required
                                    id="standard-basic"
                                    label="EMAIL ADDRESS"
                                    name="email"
                                    value={user.email}
                                    onChange={this.handleChange}
                                    className="email-text-field font-input-default"
                                />
                                {emailError &&
                                    <p className="login-error">Email address is required</p>
                                }
                                {submitted && emailFormat &&
                                    <p className="login-error">This email address is not valid</p>
                                }
                                {emailExists &&
                                    <p className="login-error">This email address is already in use</p>
                                }
                            </div>

                            {/* Create Password */}
                            <div>
                                <div className='d-flex align-items-center mb-16'>
                                    <p className="card-text">Create Password</p>
                                </div>
                                <div className='register-password-input'>
                                    <ReactPasswordStrength
                                        className="login-passwordStrength"
                                        minLength={8}
                                        minScore={2}
                                        scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                        changeCallback={this.changeCallback}
                                        inputState={{ name: "Password", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                    />
                                </div>
                                {passwordError &&
                                    <p className="login-error" style={{ marginTop: 10 }}>Password is required</p>
                                }
                                {(submitted && user.Password) && !passwordValid &&
                                    <p className="login-error" style={{ marginTop: 10 }}>Password is too weak</p>
                                }
                                {(submitted && user.Password) && passwordValid && passwordSecureError &&
                                    <p className="login-error" style={{ marginTop: 10 }}>Password has a max length of 128 characters. Please do not use personal information or consecutive numbers.</p>
                                }
                            </div>

                            {/* Confirm Password */}
                            <div>
                                <p className="card-text" style={{ marginTop: "20px" }}>Confirm Password</p>
                                <input
                                    required
                                    type="password"
                                    id="standard-basic"
                                    name="ConfirmPassword"
                                    value={user.confirmPassword}
                                    onChange={this.handleChange}
                                    className="email-text-field font-input-default"
                                />
                                {confirmPasswordError &&
                                    <p className="login-error">Confirm password is required</p>
                                }
                                {(submitted && user.Password && user.ConfirmPassword) && (user.ConfirmPassword !== user.Password) &&
                                    <p className="login-error">Passwords do not match</p>
                                }
                            </div>
                            <br />

                            {/* Terms */}
                            <div className='register-text'>
                                <p className="register-text-medium">Already signed up? <a onClick={() => this.goToLogin()} style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565aa' }}>Log in.</a></p>

                                {/* <p style={{fontSize:14}}>*By clicking "Try 14 days for $1." you agree to the */}
                                <p className="register-text-small">*By clicking "Sign Up!" you agree to the
                                    <a style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565aa' }} href="https://www.opengrants.io/opengrants-legal/" >&nbsp;privacy policy</a>
                                    &nbsp;and
                                    <a style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565aa' }} href="https://www.opengrants.io/opengrants-legal/" >&nbsp;terms of service.</a>
                                </p>
                            </div>

                            {/* Captcha */}
                            <div className="login-helpers">
                                {!sharedService.isTestWebsite() &&
                                    <div className="row center" style={{ marginTop: 25 }}>
                                        <ReCAPTCHA
                                            sitekey="6LdgnyQaAAAAAJ8smgG-HhYlEHkI8lp_4LMVYPC-"
                                            onChange={() => this.setState({ captcha: true })}
                                        />
                                        {submitted && !captcha &&
                                            <p className="error">Captcha is required</p>
                                        }
                                    </div>
                                }
                            </div>

                            {/* Sign up */}
                            <div className="login-helpers">
                                <button
                                    className="sign-in-button"
                                    type="submit"
                                    style={{ background: iFrameConfig?.primary ? iFrameConfig?.primary : '#0C7069' }}
                                >
                                    <img
                                        src={userIcon}
                                        alt=""
                                        style={{ paddingRight: "10px" }}
                                        className='white-icon-filter'
                                    />
                                    Sign up!
                                </button>
                            </div>

                            {/* Sign up with Google */}
                            <div className="login-footer-text">Or sign up with</div>
                            <div className="login-helpers">
                                {/* <GoogleLoginPage
                                    handleSubmit={this.handleGoogleSubmit}
                                    location={"register"}
                                    disabled={google_Registration_Option === true ? false : true}
                                /> */}
                                {/* <div id="g_id_onload"
                                    data-client_id="1034864638994-1vgens45tqsu9h85uh7db9kiec1hpmi7.apps.googleusercontent.com"
                                    data-callback="handleGoogleRegister"
                                    data-context="signin">
                                </div>
                                <div class="g_id_signin"
                                    data-type="standard"
                                    data-size="large"
                                    data-theme="outline"
                                    data-text="sign_in_with"
                                    data-shape="rectangular"
                                    data-logo_alignment="left"
                                    style={{ textAlign: '-webkit-center', textAlign: '-moz-center' }}>
                                </div> */}
                                <div ref={this.divRef} style={{ textAlign: '-webkit-center', textAlign: '-moz-center' }} />
                                <div id="googleLoginAlert" style={{ marginTop: 20 }}></div>
                                {/* {this.state.googleAccExists && (
                                    <div
                                        className={`alert alert-danger`}
                                        style={{ marginTop: 10, textAlign: "center" }}
                                    >
                                        This account is already signed up with Google.
                                    </div>
                                )} */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;

    return { user };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage_v2);
export { connectedRegisterPage as RegisterPage_v2 };

