import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';

class EditOrganizationNAICS extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getNAICSCodes();
    }

    getNAICSCodes = async () => {
        const { user } = this.props;
        var naicS_Codes = await organizationService.getNAICSCodes(user.id);

        this.setState({
            naicS_Codes
        });
    }

    render() {
        const { naicS_Codes } = this.state;
        const { org } = this.props;

        let naicS_Codes_Cat = { "naicS_Codes": naicS_Codes };

        return (
            <>
                <p className="inputFieldSubLabel">
                    A NAICS (pronounced NAKES) Code is a classification within the North American Industry Classification System. The NAICS System was developed for use by Federal Statistical Agencies for the collection, analysis and publication of statistical data related to the US Economy. Find your NAICS code(s)
                </p>
                <Registration_AutoSuggestion
                    categories={naicS_Codes_Cat}
                    categoryName="naicS_Codes"
                    currentSelectedCategories={org.naicS_Codes}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationNAICS = connect(mapStateToProps)(EditOrganizationNAICS);
export { connectedEditOrganizationNAICS as EditOrganizationNAICS };
