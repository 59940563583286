import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "../Card/Card.js"
// import Card from "dashboardComponents/Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import styles from "../../../_assets/jss/material-dashboard-react/components/customTabsStyle.js";

const useStyles = makeStyles(styles);

const tabStyles = theme => ({
  tabRootButton: {
    color: "#FFF !important",
    width: "unset !important",
    border: "0 !important",
    height: "unset !important",
    padding: "10px 15px",
    maxWidth: "unset !important",
    minWidth: "unset !important",
    maxHeight: "unset !important",
    minHeight: "unset !important",
    lineHeight: 24,
    marginLeft: 4,
    borderRadius: 3,
  },
  tabSelected: {
    color: "#FFF !important",
    width: "unset !important",
    border: "0 !important",
    height: "50px!important",
    padding: "10px 15px",
    maxWidth: "unset !important",
    minWidth: "unset !important",
    maxHeight: "unset !important",
    minHeight: "unset !important",
    lineHeight: 24,
    marginLeft: 4,
    borderRadius: 3,
    opacity: 0.9,
    transition: "0.2s background-color 0.1s",
    backgroundColor: "rgba(255, 255, 255, 0.2)!important",
  }
});

const useTabStyles = makeStyles(tabStyles);

export default function CustomTabs(props) {
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  let trimmedTabs = [];

  tabs.map((prop) => {
    if (prop.display != "none") {
      trimmedTabs.push(prop);
    }
  });

  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });

  const [value, setValue] = React.useState(props.initialValue);
  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <Card plain={plainTabs} style={{ width: props.tabsWidth }}>
      <CardHeader id={props.tabsClass} style={{ background: headerColor, height: "50px", display: "flex", alignItems: "center" }} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle} id="tabsTitleHeader">{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {trimmedTabs.map((prop) => {
            const display = prop.display;
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />
              };
            };
            return (
              <Tab
                display={display}
                classes={{
                  root: prop.tabName.toLowerCase() == value ? tabClasses.tabSelected : classes.tabRootButton,
                  selected: tabClasses.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                style={{ marginRight: props.marginRight ? props.marginRight : 0 , color: "red", backgroundColor: "transparent"}}
                key={prop.tabName}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {trimmedTabs.map((prop, key) => {
          if ((key == (value) || (prop.tabName.toLowerCase() == value)) && prop.display != "none") {
            return <div key={prop.tabName}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};
