import React from 'react'
import { InsertDriveFileOutlined, LinkOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { marketplaceprojectActions, serviceProviderActions } from '../../../../_actions';
import { marketplaceProjectObjects } from '../../../../_components/_objects';
import { marketplaceProjectService, pluginService, serviceProviderService } from '../../../../_services';
import { FileUpload } from "../../../Shared/Upload/FileUpload";

export default function ProjectDocuments({ segments, project, type }) {

    const [filesToAdd, setFilesToAdd] = useState([]);
    const [linksToAdd, setLinksToAdd] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [linksToDelete, setLinksToDelete] = useState([]);
    const [editingGeneral, setEditingGeneral] = useState(false)

    let accentColor = pluginService.getIFrameColor()

    const { user } = useSelector(state => state.setUser);
    const dispatch = useDispatch();

    //Set files/links to be added/deleted on submit
    const handleEditAttachment = (attachment, action) => {
        if (action === "filesToAdd") {
            setFilesToAdd(attachment)
        }
        else if (action === "linksToAdd") {
            setLinksToAdd(attachment)
        }
        else if (action === "filesToDelete") {
            setFilesToDelete([...filesToDelete, attachment])
        }
        else {
            setLinksToDelete([...linksToDelete, attachment])
        }
    }

    const handleSubmit = async () => {

        let attachmentsUnchanged = ((filesToAdd.length === 0) && (linksToAdd.length === 0) && (filesToDelete.length === 0) && (linksToDelete.length === 0));

        // Edit Submit
        if (!attachmentsUnchanged) {

            //mp project
            if (project.applications) {
                let docsToDelete = [...filesToDelete, ...linksToDelete]
                let docsToAdd = [...filesToAdd, ...linksToAdd]

                docsToDelete.forEach((doc) => {
                    doc.uploaded_By_Client_Organization_Id = 0;
                    doc.uploaded_By_Service_Provider_Id = 0;
                    doc.uploaded_By_User_Id = '';
                    doc.date_Created = new Date().toLocaleDateString();
                    doc.segment_Id = 0;
                })

                docsToAdd.forEach((doc) => {
                    doc.uploaded_By_Client_Organization_Id = 0;
                    doc.uploaded_By_Service_Provider_Id = 0;
                    doc.uploaded_By_User_Id = '';
                    doc.date_Created = new Date().toLocaleDateString();
                    doc.segment_Id = 0;
                })

                if (docsToDelete.length !== 0) {
                    await marketplaceProjectService.delete_mp_documents(docsToDelete)
                }
                if (docsToAdd.length !== 0) {
                    await marketplaceProjectService.upload_mp_documents(docsToAdd)
                }
            }
            //sp project
            else {
                if (filesToDelete.length !== 0) {
                    await serviceProviderService.deleteDocumentFiles(filesToDelete)
                }
                if (linksToDelete.length !== 0) {
                    await serviceProviderService.deleteDocumentLinks(linksToDelete)
                }
                if (filesToAdd.length !== 0) {
                    await serviceProviderService.addDocumentFiles(filesToAdd)
                }
                if (linksToAdd.length !== 0) {
                    await serviceProviderService.addDocumentLinks(linksToAdd)
                }
            }
        }

        // New Entry Submit
        if (generalDocuments.length === 0) {

            //get uploaded attachments
            let document_files = localStorage.getItem("document_uploads") ? JSON.parse(localStorage.getItem("document_uploads")) : [];
            let document_links = localStorage.getItem("document_links") ? JSON.parse(localStorage.getItem("document_links")) : [];

            //mp project
            if (project.applications) {

                if (document_files && document_files.length !== 0) {
                    document_files.forEach((file, idx) => {
                        let new_file = Object.assign({}, marketplaceProjectObjects.mp_project_document);
                        new_file.document_Name = file.document_name;
                        new_file.document_File = file.document_file;
                        new_file.project_Id = project.project_Id;
                        document_files[idx] = new_file;
                    });
                }

                if (document_links && document_links.length !== 0) {
                    document_links.forEach((link, idx) => {
                        let new_link = Object.assign({}, marketplaceProjectObjects.mp_project_document);
                        new_link.document_Link = link.document_Link;
                        new_link.project_Id = project.project_Id;
                        document_links[idx] = new_link;
                    });
                }

                let documents = [...document_files, ...document_links];

                await marketplaceProjectService.upload_mp_documents(documents)

            }
            //sp project
            else {
                //submit new files
                if (document_files.length !== 0) {
                    document_files.forEach((doc) => {
                        doc.project_id = project.project_Id;
                        doc.document_link = "";
                    })

                    await serviceProviderService.addDocumentFiles(document_files);
                }

                // submit new links
                if (document_links.length !== 0) {
                    document_links.forEach((doc) => {
                        doc.project_id = project.project_Id;
                        doc.document_name = "";
                        doc.document_file = null;
                    })

                    await serviceProviderService.addDocumentLinks(document_links);
                }
            }
        }

        //get updated project
        if (project.applications) {
            dispatch(marketplaceprojectActions.getProject(project.project_Id, ['documents', 'applications', 'client organization', 'segments']))
        }
        else {
            dispatch(serviceProviderActions.getProject(project.project_Id, user.id));
        }

        //close form
        handleCancel();
    }

    const handleCancel = () => {
        setEditingGeneral(false)
        setFilesToAdd([])
        setLinksToAdd([])
        setFilesToDelete([])
        setLinksToDelete([])
    }

    //Download an existing file
    const downloadFile = async (documentObj) => {
        let blob;

        if (project.applications) {
            blob = await marketplaceProjectService.download_mp_document(documentObj);
        }
        else {
            blob = await serviceProviderService.downloadDocument(documentObj);
        }

        const file = window.URL.createObjectURL(blob);

        var aTag = document.createElement("a");
        aTag.download = documentObj.document_Name
        aTag.href = file;
        aTag.target = "_blank";

        aTag.click();
    }

    //Add HTTPS to URLs
    const withHttp = (url) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

    //truncate attachment name for display
    const truncate = (text) => {
        return text.length > 28 ? text.substring(0, 25) + "..." : text;
    };

    let projectDocs = project.applications ? project.documents : project.document_Links;

    let filteredGeneralDocuments = projectDocs.filter(doc => doc.segment_Id === 0);

    let generalDocuments = filteredGeneralDocuments.map((doc) => {
        return (
            <div key={doc.document_Link} className="attachmentTagBox preview">
                {doc.document_Link &&
                    <div className="attachmentTagContent">
                        <LinkOutlined className="attachmentTagIcon link" />
                        <a className="attachmentTagName" href={withHttp(doc.document_Link)} target="_blank" rel="noopener noreferrer" >{truncate(doc.document_Link)}</a>
                    </div>
                }
                {doc.document_Name &&
                    <div className="attachmentTagContent">
                        <InsertDriveFileOutlined className="attachmentTagIcon" />
                        <a className="attachmentTagName" onClick={() => { downloadFile(doc) }}>{truncate(doc.document_Name)}</a>
                    </div>
                }
            </div>
        )
    })

    let hoursDocuments = segments.map((seg) => {

        let projectDocs = project.applications ? project.documents : project.document_Links;

        let filteredHoursDocuments = projectDocs.filter(doc => doc.segment_Id === seg.segment_Id)

        if (filteredHoursDocuments.length !== 0) {
            return (
                <div className='documentsTabSegment'>

                    <h4>{seg.segment_Title}</h4>

                    {filteredHoursDocuments.map(doc => {
                        return (
                            <div key={doc.document_Link} className="attachmentTagBox preview">
                                {doc.document_Link &&
                                    <div className="attachmentTagContent">
                                        <LinkOutlined className="attachmentTagIcon link" />
                                        <a className="attachmentTagName" href={withHttp(doc.document_Link)} target="_blank" rel="noopener noreferrer" >{truncate(doc.document_Link)}</a>
                                    </div>
                                }
                                {doc.document_Name &&
                                    <div className="attachmentTagContent">
                                        <InsertDriveFileOutlined className="attachmentTagIcon" />
                                        <a className="attachmentTagName" onClick={() => { downloadFile(doc) }}>{truncate(doc.document_Name)}</a>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            )
        }
        else {
            return null
        }

    }).filter(seg => seg !== null)

    return (
        <>
            {(project.project_Status.toLowerCase() === "active" || project.project_Status.toLowerCase() === "completed" || project.project_Status.toLowerCase() === "complete") ?
                <div className='documentsTab'>
                    {generalDocuments.length === 0 && hoursDocuments.length === 0 && (type === "client" || (type === "service_Provider" && project.project_Status.toLowerCase() === "complete")) &&
                        <p style={{ textAlign: "center", marginTop: 50, fontFamily: "Lato", fontSize: 18 }}>There are currently no documents for this project.</p>
                    }

                    {((generalDocuments.length !== 0 && !editingGeneral) //docs exist and are not edited
                        || (((generalDocuments.length === 0 && project.project_Status.toLowerCase() === "active") || editingGeneral) && type === "service_Provider")) &&
                        //is a service provider, docs don't exist and project is active or docs are edited 

                        <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                            <div className="col-md-3" style={{ padding: 0 }}>
                                <p className="acceptingProjectHeader mobileFloatHeader">Project Documents</p>
                            </div>
                            <div className="col-md-5" />
                            <div className="col-md-3" style={{ marginTop: '5px' }}>
                                {generalDocuments.length !== 0 && type === "service_Provider" && project.project_Status.toLowerCase() === "active" &&
                                    <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => { setEditingGeneral(true) }} color="info">
                                        <i className="icon-pencil-icon" />Edit
                                    </a>
                                }
                            </div>
                        </div>
                    }

                    {/* General Attachments Display */}
                    {(generalDocuments.length !== 0 && !editingGeneral) &&
                        <div className="documentsTabSegment" style={{ marginBottom: 40 }}>
                            {generalDocuments}
                        </div>
                    }

                    {/* General Attachments Form */}
                    {(generalDocuments.length === 0 || editingGeneral) && type === "service_Provider" && project.project_Status.toLowerCase() === "active" &&
                        <>
                            <FileUpload
                                project={project}
                                handleEditAttachment={handleEditAttachment}
                                generalAttachments={editingGeneral}
                                removeSubtitle={true}
                            />

                            <div className='documentsTabButtons' style={{ marginBottom: 40 }}>
                                <button
                                    className="defaultGreenButton"
                                    onClick={() => { handleSubmit() }}
                                    style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                >
                                    Save
                                </button>
                                {editingGeneral &&
                                    <button
                                        className="defaultWhiteButton"
                                        onClick={() => { handleCancel() }}
                                        style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                    >
                                        Cancel
                                    </button>
                                }
                            </div>
                        </>
                    }

                    {/* Hours/Invoices Attachments Display */}
                    {hoursDocuments.length !== 0 &&
                        <>
                            <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                <p className="acceptingProjectHeader mobileFloatHeader">Hours/Invoices Documents</p>
                            </div>
                            <div className='hoursDocumentContainer'>
                                {hoursDocuments}
                            </div>
                        </>
                    }
                </div >
                :
                <p style={{ textAlign: "center", marginTop: 50, fontFamily: "Lato", fontSize: 18 }}>This project is currently not active.</p>
            }
        </>
    )
}