import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/Onboarding.css';
import SlateTextEditor from "../../../../_components/dashboardComponents/CustomInput/SlateTextEditor";
import { organizationService, sharedService } from '../../../../_services';
import { Loader as Loading } from '../../../Shared/Loader';
import Select from 'react-select';

const customSelectStyle = {
    control: (styles) => ({
        ...styles,
        border: "2px ridge",
        backgroundColor: "none",
        boxShadow: "none",
        borderRadius: 5,
        height: 44,
        '&:hover': {
            border: "2px ridge"
        },
    })
};

class NewProjectOverview_v2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            organization_Select: [],
            selectedOption: null,
            proposal: {},
            client: {},
            client_Organization_Id: this.props.client_Organization_Id
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });

        // const client_Organization_Id = this.props.all_Organizations.filter(x => {if (x.name == this.props.project_Client) return x})[0].organization_Id;
        const client = await organizationService.getOrganization(this.props.user.id, this.props.client_Organization_Id);
        this.setState({ client: client });
        this.setState({ loading: false });

        document.body.scrollTo(0, 0);
    }

    render() {
        const { service_Provider, handleProposalInput, handleFormattedInput, project, submitted, selectedRate, handleSelectedRate } = this.props;
        const { client, loading } = this.state;
        const project_Proposal = project.project_Proposal;

        return (
            <div>
                {loading && <Loading />}

                <div className="newProjectFormHeader_v2">Project Details</div>

                {/* CLIENT INFO */}
                <div className="newProjectForm_v2">
                    <div style={{ display: 'flex', alignItems: "center", marginBottom: "2.5%" }}>
                        <img
                            src={client.org && (client.org.organization.logo_URL ? client.org.organization.logo_URL : "https://img.icons8.com/bubbles/2x/company.png")}
                            width="75"
                            height="75"
                            style={{ borderRadius: "50%", objectFit: "cover", marginLeft: 16 }}
                            alt="organization logo"
                        />
                        <span style={{ fontSize: 18, fontWeight: 600, lineHeight: "75px", paddingLeft: '1rem' }}>
                            {client.org && client.org.organization.name}
                        </span>
                    </div>
                    {service_Provider &&
                        <div style={{ float: "right", maxWidth: "150", textAlign: "center" }}>
                            <img
                                src={service_Provider.organization.logo_URL ? service_Provider.organization.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"}
                                height="100"
                                style={{ textAlign: "center" }}
                                alt="organization logo"
                            />
                            <br />
                            <span style={{ fontWeight: 600, lineHeight: "75px" }}>
                                {service_Provider.organization.name}
                            </span>
                        </div>
                    }

                    <hr />

                    <form>
                        {/* TITLE */}
                        <div className="projectProposalField">
                            <h3>Title <strong><span style={{ color: "red" }}>*</span></strong></h3>
                            <p>Give your project a name!</p>
                            <input
                                className="proposalInput"
                                name="proposal_Title"
                                value={project_Proposal.proposal_Title}
                                onChange={handleProposalInput}
                                style={{ width: "100%", background: "rgba(52, 122, 228, 0.08)", border: "2px solid rgba(52, 122, 228, 0.65)" }}
                            />
                            {submitted && !project_Proposal.proposal_Title &&
                                <p class="input_error">This is required</p>
                            }
                        </div>
                        <br />
                        {/* SCOPE */}
                        <div className="projectProposalField">
                            <h3>Scope of Project <strong><span style={{ color: "red" }}>*</span></strong></h3>
                            <p>
                                Thoroughly describe the work that will be done during this project.
                                Include why the work is necessary and how it will support the desired outcomes,
                                especially if it’s work that does not include a deliverable.
                                This includes research, writing, editing, outreach, etc.
                            </p>
                            <SlateTextEditor
                                name="proposal_Scope"
                                handleFormattedInput={handleFormattedInput}
                                savedText={project_Proposal.proposal_Scope}
                            />
                            {submitted && sharedService.slateTextIsEmpty(project.project_Proposal.proposal_Scope) &&
                                <p class="input_error">This is required</p>
                            }
                        </div>
                        <br />
                        {/* DELIVERABLES */}
                        <div className="projectProposalField">
                            <h3>Deliverables</h3>
                            <p>
                                Explain the milestones and/or deliverables your client can expect from you.
                                This may be a shortlist of grants, drafts of applications, research reports, etc.
                                If possible, include rough dates of when your client can expect to receive each deliverable.
                            </p>
                            <SlateTextEditor
                                name="proposal_Additional_Information"
                                handleFormattedInput={handleFormattedInput}
                                savedText={project_Proposal.proposal_Additional_Information}
                            />
                        </div>
                        <br />
                        {/* COMPLETION DATE */}
                        <div className="projectProposalField">
                            <h3>Project Completion Date</h3>
                            <p>Not required if your project is ongoing</p>
                            <input
                                className="proposalInput"
                                name="project_Completion_Date"
                                value={project_Proposal.project_Completion_Date}
                                onChange={handleProposalInput}
                                type="date"
                            />
                        </div>
                        <br />
                        <div className='projectProposalWrapper'>

                            {/* RATE SELECTION */}
                            <div className="projectProposalField" style={{ flex: 1 }}>
                                <h3>Project Rate</h3>
                                <p>Please select hourly or flat project rate.</p>
                                <Select
                                    value={selectedRate}
                                    onChange={handleSelectedRate}
                                    options={[{ value: "hourlyRate", label: "Hourly Rate" }, { value: "flatRate", label: "Flat Rate" }]}
                                    styles={customSelectStyle}
                                />
                            </div>
                            {selectedRate.value === "hourlyRate" &&
                                <>
                                    {/* HOURLY RATE */}
                                    <div className="projectProposalField" style={{ flex: 1 }}>
                                        <h3>Project Hourly Rate</h3>
                                        <p>This will be used to calculate the total project cost.</p>
                                        <input
                                            className="proposalInput"
                                            name="project_Hourly_Rate"
                                            value={project_Proposal.project_Hourly_Rate}
                                            onChange={handleProposalInput}
                                        />
                                        {!!project_Proposal.project_Hourly_Rate && !sharedService.isInteger(project_Proposal.project_Hourly_Rate) &&
                                            <p className="red-message">You may only enter positive integers.</p>
                                        }
                                    </div>
                                    {/* TOTAL HOURS */}
                                    <div className="projectProposalField" style={{ flex: 1 }}>
                                        <h3>Project Total Hours</h3>
                                        <p>If relevant estimate the total hours to complete your project.</p>
                                        <input
                                            className="proposalInput"
                                            name="project_Total_Hours"
                                            value={project_Proposal.project_Total_Hours}
                                            onChange={handleProposalInput}
                                        />
                                        {!!project_Proposal.project_Total_Hours && !sharedService.isTypingFloat(project_Proposal.project_Total_Hours) &&
                                            <p className="red-message">You may only enter positive integers.</p>
                                        }
                                        {submitted && !(project_Proposal.project_Total_Hours && sharedService.isFloat(project_Proposal.project_Total_Hours)) &&
                                            <p className="red-message">Invalid input</p>
                                        }
                                    </div>
                                </>
                            }

                            {/* FLAT RATE */}
                            {selectedRate.value === "flatRate" && <>
                                <div className="projectProposalField" style={{ flex: 1 }}>
                                    <h3>Project Flat Rate</h3>
                                    <p>Flat rate projects are billed immediately and held in escrow. You get paid when the project is complete.</p>
                                    <input
                                        className="proposalInput"
                                        name="project_Flat_Rate"
                                        value={project_Proposal.project_Flat_Rate}
                                        onChange={handleProposalInput}
                                    />
                                    {!!project_Proposal.project_Flat_Rate && !sharedService.isInteger(project_Proposal.project_Flat_Rate) &&
                                        <p className="red-message">You may only enter positive integers.</p>
                                    }
                                </div>
                            </>
                            }
                        </div>
                        <br />

                        {/* TOTAL COST */}
                        <div className="projectProposalField">
                            <h3>Project Total Cost</h3>
                            <p>
                                <strong>
                                    $ {project_Proposal && project_Proposal.project_Total_Cost ? project_Proposal.project_Total_Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"} USD
                                </strong>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return { user };
}

const connectedNewProjectOverview = connect(mapStateToProps)(NewProjectOverview_v2);
export { connectedNewProjectOverview as NewProjectOverview_v2 };
