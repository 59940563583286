import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Login.css";
import "../../../Styles/Marketplace.css";
import { authenticateActions, settingsActions } from '../../../_actions';
import { organizationService } from "../../../_services";
import { Loader as Loading } from "../../Shared/Loader";
import { GS_Onboarding_Confirmation } from "./GS_Onboarding_Confirmation";
import { GS_Onboarding_AdvancedSettings } from "./GS_onBoarding_Forms/GS_Onboarding_AdvancedSettings";
import { GS_Onboarding_Basics } from "./GS_onBoarding_Forms/GS_Onboarding_Basics";
import { GS_Onboarding_BusinessAddress } from "./GS_onBoarding_Forms/GS_Onboarding_BusinessAddress";
import { GS_Onboarding_Members } from "./GS_onBoarding_Forms/GS_Onboarding_Members";
import { GS_Onboarding_OrganizationAttributes1 } from "./GS_onBoarding_Forms/GS_Onboarding_OrganizationAttributes1";
import { GS_Onboarding_OrganizationAttributes2 } from "./GS_onBoarding_Forms/GS_Onboarding_OrganizationAttributes2";
import { GS_Onboarding_Org_Confirmation } from "./GS_Onboarding_Org_Confirmation";
const striptags = require('striptags');

let genderOptions = ["Male", "Female", "Non-binary", "I prefer not to say", null];
let ethnicityOptions = ["White/Caucasian", "Asian-Eastern", "Asian-Indian", "Hispanic", "African-American", "Native-American", "Mixed race", "I prefer not to say", null]

class GS_Onboarding_v2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      categories: {
        category: [],
        category_Tags: [],
      },
      categoryInput: [],
      category_TagsInput: "",
      categoryError: false,
      category_TagsError: false,
      primary_Org: null,
      new_Organization: null,
      is_New_Org: false,
      loading: true,
      form_errors: false,
      user: this.props.user,

      showGenderInput: false,
      showEthnicityInput: false,

      createOrgError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateNav = this.updateNav.bind(this);
    this.setIndividualOrg = this.setIndividualOrg.bind(this);
    this.setOrgLogo = this.setOrgLogo.bind(this);
  }

  async componentDidMount() {
    document.body.scrollTo(0, 0);
    localStorage.setItem("fileBase64", "")

    //Define user
    const user = JSON.parse(localStorage.getItem("user"));

    // //Check if user already service provider
    // const service_Provider =
    //   await serviceProviderService.getServiceProvidersByUser(user.id);

    //Check if user has an existing org
    //If user has one org, populate org details
    const user_Orgs = await organizationService.getAllOrganizationsByUser(
      user.id
    );
    const org_Keys = Object.keys(user_Orgs.organizations);
    const num_Orgs = org_Keys.length;

    //Set org as user's first org in list
    var primary_Org = null;
    if (num_Orgs > 0) {
      primary_Org = user_Orgs.organizations[org_Keys[0]].organization;
    }

    let formattedMainUsed = primary_Org?.technologies_Main_Used?.map((tech) => {
      return tech.technology
    })

    let formattedSubUsed = primary_Org?.technologies_Sub_Used?.map((tech) => {
      return tech.technology
    })

    //is creating org
    const path = window.location.pathname;
    let pathSplit = path.split('/').pop();

    let otherGender = this.checkOther("gender", genderOptions)
    let otherEthnicity = this.checkOther("ethnicity", ethnicityOptions)

    //Set state details for Service Provider and tag lists
    this.setState({
      nav: (pathSplit === "org") || this.props.creatingOrg
        ? "onboard_organizationAttributes1" : "onboard_basics",
      is_New_Org: num_Orgs === 0 ? true : false,
      new_Organization: {
        organization: num_Orgs === 0 ? {
          naicS_Codes: [],
          industries: [],
          technologies_Sub_Used: [],
          technologies_Main_Used: [],
          social_Impact_Categories: [],
        } : primary_Org,
        technologies_Main_Used: num_Orgs === 0 ? [] : formattedMainUsed,
        technologies_Sub_Used: num_Orgs === 0 ? [] : formattedSubUsed
      },
      primary_Org: primary_Org,
      loading: false,
      invite_emailInput: '',
      accepted_profiles: '',
      accepted_profile_list: [],
      pending_profiles: [],
      pending_profile_list: [],
      pending_invitation_profiles: [],
      pending_invitation_list: [],
      transfer: false,
      show_Logo: false,
      showGenderInput: !otherGender ? true : false,
      showEthnicityInput: !otherEthnicity ? true : false,
    });
  }

  async updateNav(location) {
    //route user to individual signup
    // if (location === "onboard_intro") this.setState({ nav: "onboard_intro" });
    if (location === "onboard_basics") this.setState({ nav: "onboard_basics" });
    if (location === "onboard_organizationAttributes1") this.setState({ nav: "onboard_organizationAttributes1" });
    if (location === "onboard_organizationAttributes2") this.setState({ nav: "onboard_organizationAttributes2" });
    if (location === "onboard_businessAddress") this.setState({ nav: "onboard_businessAddress" });
    if (location === "onboard_advancedSettings") this.setState({ nav: "onboard_advancedSettings" });
    if (location === "onboard_members") this.setState({ nav: "onboard_members" });
    if (location === "onboard_confirmation") this.setState({ nav: "onboard_confirmation" });
    //route user to organization signup
    // else if (location === "onboard_organization")
    //   this.setState({ nav: "onboard_organization" });
    //route user to verify / create Stripe connect account
    // else this.setState({ nav: "onboard_connect" });
    document.body.scrollTo(0, 0);
  }

  imageUploadFailed = (msg) => {
    this.setState({
      imageError: msg,
      show_Logo: false,
    })

    setTimeout(() => {
      this.setState({
        imageError: "",
      })
    }, 6000);
  }
  removePreview = () => {
    this.setState({
      imageError: "",
      show_Logo: false,
    })
  }
  //Passed down to save cropped image data
  setCroppedPixels = (pixels) => {
    this.setState({
      croppedAreaPixels: pixels
    })
  }

  handleChange(event) {
    const { id, name, value } = event.target;
    const { user, new_Organization } = this.state;
    if (id !== "organization-standard-basic") {
      this.setState({
        user: {
          ...user,
          [name]: value
        }
      });
    } else {
      this.setState({
        new_Organization: {
          ...new_Organization,
          organization: {
            ...new_Organization.organization,
            [name]: value,
          },
        }
      });
    }
  }

  handleRadioChange = (event) => {
    const { id, name, value } = event.target;
    const { user } = this.state;

    if (name === "gender" && id !== "gender_Other") {
      this.setState({
        showGenderInput: false,
      })
    }

    if (name === "ethnicity" && id !== "ethnicity_Other") {
      this.setState({
        showEthnicityInput: false,
      })
    }

    if (id === "gender_Other") {
      this.setState({
        showGenderInput: true,
      })

    }
    else if (id === "ethnicity_Other") {
      this.setState({
        showEthnicityInput: true,
      })
    }

    this.setState({
      user: {
        ...user,
        [name]: value,
      }
    })
  }

  handleCategorySelection = (categories) => {
    const { new_Organization } = this.state;
    this.setState({
      new_Organization: {
        ...new_Organization,
        organization: {
          ...new_Organization.organization,
          ...categories,
        }
      }
    });
  };

  handleAdvancedSelection = (categories) => {
    const { new_Organization } = this.state;
    this.setState({
      new_Organization: {
        ...new_Organization,
        ...categories
      }
    })
  }

  handleCategoryInput = (categoryInputs) => {
    this.setState(categoryInputs);
  };

  handleLocationInput = (categoryInputs) => {
    var key = Object.keys(categoryInputs)[0].split("Input")[0];
    var value = Object.values(categoryInputs)[0];

    this.setState({
      new_Organization: {
        ...this.state.new_Organization,
        organization: {
          ...this.state.new_Organization.organization,
          [key]: value,
        },
      },
    });
  };

  handleLocationSelection = (categories) => {
    const { new_Organization } = this.state;

    this.setState({
      new_Organization: {
        ...new_Organization,
        organization: {
          ...new_Organization.organization,
          ...categories,
        },
      },
    });
  };

  setIndividualOrg() {
    const { user } = this.props;

    this.setState({
      new_Organization: {
        organization: {
          name: user.name + " " + user.surname,
          website_URL: "",
        },
      },
    });
  }

  setOrgLogo(file) {
    this.getBase64(file).then(base64 => {
      file.preview = "";
      this.setState({
        new_Organization: {
          ...this.state.new_Organization,
          organization: {
            ...this.state.new_Organization.organization,
            logo_URL: base64,
            logo_file: file
          },
        },
      });
    })
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  setProfileImage_file = (file) => {
    this.getBase64(file).then(base64 => {
      file.preview = "";
      this.setState({
        user: {
          ...this.state.user,
          headshot_file: file,
          headshot: base64
        }
      })
    })
  }

  inviteToJoinOrganization = async (user_id, organization_id, email) => {
    if (!email) {
      return;
    }
    const { new_Organization } = this.state;
    if (!new_Organization) {
      var invited_user = await organizationService.inviteToJoinOrganization(organization_id, user_id, email);

      var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
      if (invited_user) {
        new_pending_invitation_profiles.push(invited_user);
      }
      this.setState({
        pending_invitation_profiles: [...new_pending_invitation_profiles],
        invite_emailInput: ''
      });
    } else {
      var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
      new_pending_invitation_profiles.push({ email: email });
      var emailInvites = Object.assign([], this.state.emailInvites);
      emailInvites.push(email);
      this.setState({
        emailInvites: [...emailInvites],
        pending_invitation_profiles: [...new_pending_invitation_profiles],
        invite_emailInput: ''
      });
    }
  }

  handleNewOrganization = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  //go through organization attributes, if one has "Select" value, change it to false
  handleSelectOption = (new_Organization) => {
    for (const [key, value] of Object.entries(new_Organization.organization)) {
      if (value === "Select") {
        new_Organization.organization[key] = false;
      }
    }
  }

  handleCreatableSelection = (input, categoryType) => {
    const { new_Organization } = this.state;

    if (!new_Organization[categoryType] || !new_Organization[categoryType].includes(input.value)) {

      let techArray = [];

      if (!new_Organization[categoryType]) {
        techArray = [...techArray, input.value]
      }
      else {
        techArray = [...new_Organization[categoryType], input.value]
      }

      this.setState({
        new_Organization: {
          ...new_Organization,
          [categoryType]: techArray,
        }
      })
    }
  }

  handleCreatableRemoval = (input, clearAll, categoryType) => {
    const { new_Organization } = this.state

    let tempArray;

    if (clearAll) {
      //set empty array
      tempArray = []
    }
    else {
      //remove from array
      tempArray = new_Organization[categoryType].filter(item => item !== input)
    }
    this.setState({
      new_Organization: {
        ...new_Organization,
        [categoryType]: tempArray,
      }
    })
  }

  async handleSubmit(e, skip_Org) {
    e.preventDefault();

    this.setState({
      loading: true,
      form_errors: false,
    });
    const { user } = this.props;

    let form_errors = false;
    const { is_New_Org, new_Organization } = this.state;
    const user_input = this.state.user;

    let is_Valid = true;

    //change "Select" options to false
    await this.handleSelectOption(new_Organization);

    if (is_Valid === true) { // Set this to true to test onboarding Onboarding

      const path = window.location.pathname;
      let pathSplit = path.split('/').pop();

      //creating or updating org
      if ((is_New_Org && new_Organization.organization.name && !skip_Org) || (pathSplit === "org" || this.props.creatingOrg)) {
        //set indiv org with profile values
        if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
          new_Organization.organization.description = this.state.user.bio;
          if (this.state.user.headshot_file) {
            const base64 = await this.getBase64(this.state.user.headshot_file);
            new_Organization.organization.logo_URL = base64;
            new_Organization.organization.logo_file = this.state.user.headshot_file;
          }
        }
        //create/update org
        const org = await organizationService.createOrganization(user.id, new_Organization);
        if (!org) {
          this.setState({
            createOrgError: true
          });
          return;
        }

        //is creating/updating org
        if (pathSplit === "org" || this.props.creatingOrg) {

          this.updateNav('onboard_confirmation');

          this.setState({
            org,
            loading: false,
            createOrgError: false,
          });

          //do not update user
          return;
        }
      }

      user_input.name = striptags(user_input.name);
      user_input.surname = striptags(user_input.surname);

      let updateUser = {
        user: {
          ...user,
          ...user_input,
          onboard_Complete: true
        },
        file_string: localStorage.getItem('fileBase64')
      }

      await this.props.dispatch(settingsActions.updateProfile(updateUser));
      await this.props.dispatch(authenticateActions.setUser(updateUser.user));

      this.updateNav('onboard_confirmation');

      this.setState({
        loading: false,
      });
    }
    else {
      this.setState({
        submitted: true,
        loading: false,
        form_errors,
      });
    }
  }

  checkOther = (optionType, optionArray) => {
    return optionArray.some((option) => this.state.user[optionType] === option)
  }

  render() {
    const {
      loading,
      nav,
      is_New_Org,
      new_Organization,
      submitted,
      form_errors,
      show_Logo,
      user
    } = this.state;

    return (
      <div>
        {loading && <Loading />}
        {nav === "onboard_basics" && (
          <div>
            <GS_Onboarding_Basics
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              user={user}
              handleChange={this.handleChange}
              imageUploadFailed={this.imageUploadFailed}
              removePreview={this.removePreview}
              setCroppedPixels={this.setCroppedPixels}
              setProfileImage_file={this.setProfileImage_file}
              show_Logo={show_Logo}
              is_New_Org={is_New_Org}
              handleSubmit={this.handleSubmit}
              handleRadioChange={this.handleRadioChange}
              showEthnicityInput={this.state.showEthnicityInput}
              showGenderInput={this.state.showGenderInput}
              genderOptions={genderOptions}
              ethnicityOptions={ethnicityOptions}
              checkOther={this.checkOther}
            />
          </div>
        )}
        {nav === "onboard_organizationAttributes1" && (
          <div>
            <GS_Onboarding_OrganizationAttributes1
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              user={user}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              creatingOrg={this.props.creatingOrg}
              createOrgError={this.state.createOrgError}
            />
          </div>
        )}
        {nav === "onboard_organizationAttributes2" && (
          <div>
            <GS_Onboarding_OrganizationAttributes2
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              setOrgLogo={this.setOrgLogo}
              handleChange={this.handleChange}
              creatingOrg={this.props.creatingOrg}
            />
          </div>
        )}
        {nav === "onboard_businessAddress" && (
          <div>
            <GS_Onboarding_BusinessAddress
              updateNav={this.updateNav}
              new_Organization={new_Organization}
              submitted={submitted}
              handleChange={this.handleChange}
              handleLocationSelection={this.handleLocationSelection}
              handleLocationInput={this.handleLocationInput}
              creatingOrg={this.props.creatingOrg}
            />
          </div>
        )}
        {nav === "onboard_advancedSettings" && (
          <div>
            <GS_Onboarding_AdvancedSettings
              updateNav={this.updateNav}
              submitted={submitted}
              handleChange={this.handleChange}
              // category_TagsError={category_TagsError}
              new_Organization={new_Organization}
              handleCategorySelection={this.handleAdvancedSelection}
              handleCategoryInput={this.handleCategoryInput}
              creatingOrg={this.props.creatingOrg}
              handleCreatableSelection={this.handleCreatableSelection}
              handleCreatableRemoval={this.handleCreatableRemoval}
              onboardingStyling={true}
            />
          </div>
        )}
        {nav === "onboard_members" && (
          <div>
            <GS_Onboarding_Members
              updateNav={this.updateNav}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              form_errors={form_errors}
              new_Organization={new_Organization}
              invite_emailInput={this.state.invite_emailInput}
              accepted_profiles={this.state.accepted_profiles}
              accepted_profile_list={this.state.accepted_profile_list}
              pending_profiles={this.state.pending_profiles}
              pending_profile_list={this.state.pending_profile_list}
              pending_invitation_profiles={this.state.pending_invitation_profiles}
              pending_invitation_list={this.state.pending_invitation_list}
              transfer={this.state.transfer}
              inviteToJoinOrganization={this.inviteToJoinOrganization}
              handleNewOrganization={this.handleNewOrganization}
              creatingOrg={this.props.creatingOrg}
              createOrgError={this.state.createOrgError}
            />
          </div>
        )}
        {nav === "onboard_confirmation" && !this.props.noCongrats &&
          <div>
            <GS_Onboarding_Confirmation />
          </div>
        }
        {nav === "onboard_confirmation" && this.props.noCongrats &&
          <div>
            <GS_Onboarding_Org_Confirmation
              handleSubmittedOrg={() => this.props.handleSubmittedOrg(this.state.org)}
            />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}
const connectedGS_Onboarding_v2 = connect(mapStateToProps)(GS_Onboarding_v2);
export { connectedGS_Onboarding_v2 as GS_Onboarding_v2 };

