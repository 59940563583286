import he from 'he';
import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import '../../Styles/Algolia.css';
import '../../Styles/MarketplaceSearch_v2/MarketplaceSearch_v2.css';
import SlateTextDisplay from '../../_components/dashboardComponents/CustomInput/SlateTextDisplay.jsx';
import { pluginService } from '../../_services';
import ModalView from '../Shared/Modal/ModalView.js';
import { ViewListing } from './ViewListing.jsx';

export const unixDate = (timestamp) => {
  var a = new Date(timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ', ' + year;

  return time;
};

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <div>
      {parsedHit.map(
        part => (part.isHighlighted ? <mark>{part.value}</mark> : part.value)
      )}
    </div>
  );
});

let accentColor = pluginService.getIFrameColor()

class MarketplaceResult_v2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFavorite: false,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal
    })
  }

  render() {
    const project = this.props.project.hit;
    const { details } = this.props;

    return (
      <div id="searchHitItem">
        {project.project_Status && project.project_Status === "Open" &&
          <>
            <div>
              <div className="spContainer">
                {/* SP PROFILE IMAGE */}
                <div className="spImageDiv">
                  <img
                    src={project.client_Organization.logo_URL ? project.client_Organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"}
                    className="spLogo"
                    alt="organization logo"
                  />
                </div>

                {/* SP INFORMATION */}
                <div className="spInfoDiv">

                  {/* TITLE */}
                  <div className="hit-title">
                    <CustomHighlight attribute="title" hit={project} />
                    <div className="hit-header project-listing-hit-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <TextTruncate
                        line={2}
                        element="class"
                        truncateText="…"
                        text={he.decode(project.client_Organization.name)}
                        style={{ fontWeight: 900, display: "block" }}
                      />
                      <button
                        onClick={this.toggleModal}
                        className="defaultGreenButton"
                        style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                      >
                        {details.button}
                      </button>
                    </div>
                    <hr style={{ marginBottom: '10px', marginTop: '10px' }} />
                  </div>

                  {/* ABOUT */}
                  <div className="spAboutDiv" >
                    <p className="TaletFinderTitle">{project.project_Title}</p>
                    <SlateTextDisplay text={project.project_Description} truncate={true} charLimit={300} />
                    <br />

                    {/* BUDGET */}
                    {project &&
                      <div>
                        <p className="TaletFinderTitle">Budget</p>
                        <p>${project.project_Budget.toLocaleString()}.00 (USD)</p>
                      </div>
                    }
                    <br />

                    {/* TAGS */}
                    {project && (project.focus_Tags.length > 0 || project.service_Tags.length > 0) &&
                      <div className={`${(project.focus_Tags.length === 0 || project.service_Tags.length === 0) ? "" : "spTags"} `}>
                        {project.focus_Tags.length > 0 &&
                          <div className="focusAreasTags">
                            <div>
                              <p className="TaletFinderTitle">
                                Focus Area
                              </p>
                            </div>
                            {project.focus_Tags.map(tag => (
                              <div id="wtfTag">
                                <p style={{ display: "inline", textWrap: 'wrap' }}>{tag.industry_Focus.trim()}</p>
                              </div>
                            ))}
                          </div>}
                        {project.service_Tags.length > 0 &&
                          <div className="focusAreasTags">
                            <div>
                              <p className="TaletFinderTitle">
                                Services Required
                              </p>
                            </div>
                            {project.service_Tags.map(tag => (
                              <div id="wtfTag">
                                <p style={{ display: "inline", textWrap: 'wrap' }}>{tag.industry_Service.trim()}</p>
                              </div>
                            ))}
                          </div>}
                      </div>}
                  </div>
                </div>
              </div>

              <br />

              {/* TEMPORARILY NOT SHOWN (because of multiple sps having one organization's info) */}

              {/* <div className="row" style={{ padding: 0 }}>
            {project.sP_Industry_Focus_Tags &&
              project.sP_Industry_Focus_Tags.length > 0 &&
              <div>
                <p className="TaletFinderTitle">
                  Organization
                </p>
              </div>
            }
            <img
              src={project.logo_URL ? project.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"}
              width="70" height="70"
            />
          </div> */}

              {this.state.openModal &&
                <ModalView
                  openModal={this.state.openModal}
                  toggleModal={this.toggleModal}
                >
                  <ViewListing project_id_passed={project ? project.objectID : null} />
                </ModalView>
              }
            </div>
            <br />
            <hr />
          </>
        }
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedResult = connect(mapStateToProps)(MarketplaceResult_v2);
export { connectedResult as MarketplaceResult_v2 };
