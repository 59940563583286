import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import Select from 'react-select'

const Ein = ({
  form,
  setForm,
  changeForm,
  handleNext,
  handleBack,
  naicsCodes,
  industries,
  technologies,
  socialImpacts,
}) => {
  const [naicsSelections, setNaicsSelections] = useState([])
  const [industrySelections, setIndustrySelections] = useState([])
  const [technologySelections, setTechnologySelections] = useState([])
  const [socialImpactSelections, setSocialImpactSelections] = useState([])

  const handleNaicsSelection = (value) => {
    setNaicsSelections([...naicsSelections, value])
  }
  const handleRemoveNaics = (idx) => {
    const updatedNaicsArray = [...naicsSelections]
    updatedNaicsArray.splice(idx, 1)
    setNaicsSelections(updatedNaicsArray)
  }

  const handleIndustrySelection = (value) => {
    if (value.value === 'Not Applicable') {
      setIndustrySelections([value])
    } else {
      const newIndustrySelections = industrySelections.filter(item => item.value !== 'Not Applicable')
      setIndustrySelections([...newIndustrySelections, value])
    }
  }
  const handleRemoveIndustry = (idx) => {
    const updatedIndustryArray = [...industrySelections]
    updatedIndustryArray.splice(idx, 1)
    setIndustrySelections(updatedIndustryArray)
  }

  const handleTechnologySelection = (value) => {
    if (value.value === 'Not Applicable') {
      setTechnologySelections([value])
    } else {
      const newTechnologySelections = technologySelections.filter(item => item.value !== 'Not Applicable')
      setTechnologySelections([...newTechnologySelections, value])
    }
  }
  const handleRemoveTechnology = (idx) => {
    const updatedTechnologiesArray = [...technologySelections]
    updatedTechnologiesArray.splice(idx, 1)
    setTechnologySelections(updatedTechnologiesArray)
  }

  const handleSocialSelection = (value) => {
    setSocialImpactSelections([...socialImpactSelections, value])
  }
  const handleRemoveSocial = (idx) => {
    const updatedSocialArray = [...socialImpactSelections]
    updatedSocialArray.splice(idx, 1)
    setSocialImpactSelections(updatedSocialArray)
  }

  const onNext = () => {
    setForm({
      ...form,
      // The code below excludes duplicate answers and then formats them into the proper data types to post to the orgs table
      naics: naicsSelections.map(item => item.value).filter((item, idx) => naicsSelections.map(item => item.value).indexOf(item) === idx),
      technologies: technologySelections.map(item => item.value).filter((item, idx) => technologySelections.map(item => item.value).indexOf(item) === idx),
      industries: industrySelections.map(item => item.value).filter((item, idx) => industrySelections.map(item => item.value).indexOf(item) === idx),
      socialImpactAreas: socialImpactSelections.map(item => item.value).filter((item, idx) => socialImpactSelections.map(item => item.value).indexOf(item) === idx),
    })
    handleNext()
  }

  const handleEinChange = (e) => {
    let ein = e.target.value
    if (ein.length > 9) {
      ein = ein.slice(0, -1)
    }
    setForm({
      ...form,
      ein,
    })
  }

  useEffect(() => {
    if (form.naics && form.naics.length) {
      setNaicsSelections([...form.naics.map(item => ({ value: item, label: item }))])
    }
    if (form.industries && form.industries.length) {
      setIndustrySelections([...form.industries.map(item => ({ value: item, label: item }))])
    }
    if (form.technologies && form.technologies.length) {
      setTechnologySelections([...form.technologies.map(item => ({ value: item, label: item }))])
    }
    if (form.socialImpactAreas && form.socialImpactAreas.length) {
      setSocialImpactSelections([...form.socialImpactAreas.map(item => ({ value: item, label: item }))])
    }
  }, [form.naics, form.socialImpactAreas, form.technologies, form.industries])

  return (
    <>
      <Form className='w-100 mt-48'>
        <p className='fw-bold'>EIN</p>
        <Form.Control
          type="number"
          name='ein'
          value={form.ein}
          onChange={handleEinChange}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>UEI</p>
        <p className='mt-16'>Previously DUNS</p>
        <Form.Control
          type="text"
          name='uei'
          value={form.uei}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>NAICS Codes</p>
        <Select
          name="naics_codes"
          placeholder="Begin entering code"
          value={naicsSelections}
          options={naicsCodes.map((code) => (
            { value: code, label: code }
          ))}
          onChange={handleNaicsSelection}
          multi
        />
        <Row className="m-0">
          {naicsSelections?.length > 0 && naicsSelections.map((code, i) => (
            <Col key={i} xs={12} md={6} lg={4} className="px-0 px-md-8 my-8">
              <div className="d-flex align-items-center justify-content-between rounded-5 bg-primary ps-16 py-4 pe-8">
                <p className="small text-white fw-bold m-0">{code.value}</p>
                <Button
                  className="border-0 py-0 px-4"
                  onClick={() => handleRemoveNaics(i)}
                >X
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <p className='fw-bold mt-40'>Industry</p>
        <Select
          name="industries"
          placeholder="Begin typing"
          value={industrySelections}
          options={industries.map((industry) => (
            { value: industry, label: industry }
          ))}
          onChange={handleIndustrySelection}
          multi
        />
        <Row className="m-0">
          {industrySelections?.length > 0 && industrySelections.map((industry, i) => (
            <Col key={i} xs={12} md={6} lg={4} className="px-0 px-lg-8 my-8">
              <div className={`d-flex align-items-center justify-content-between rounded-5 ps-16 py-4 pe-8 ${industry.value === 'Not Applicable' ? 'bg-danger' : 'bg-primary'}`}>
                <p className="small text-white fw-bold m-0">{industry.value}</p>
                <Button
                  className={`border-0 py-0 px-4 ${industry.value === 'Not Applicable' && 'bg-danger'}`}
                  onClick={() => handleRemoveIndustry(i)}
                >X
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <p className='fw-bold mt-40'>Technologies</p>
        <Select
          name="technologies"
          placeholder="Begin typing"
          value={technologySelections}
          options={technologies.map((tech) => (
            { value: tech, label: tech }
          ))}
          onChange={handleTechnologySelection}
          multi
        />
        <Row className="m-0">
          {technologySelections?.length > 0 && technologySelections.map((tech, i) => (
            <Col key={i} xs={12} md={6} lg={4} className="px-0 px-lg-8 my-8">
              <div className={`d-flex align-items-center justify-content-between rounded-5 ps-16 py-4 pe-8 ${tech.value === 'Not Applicable' ? 'bg-danger' : 'bg-primary'}`}>
                <p className="small text-white fw-bold m-0">{tech.value}</p>
                <Button
                  className={`border-0 py-0 px-4 ${tech.value === 'Not Applicable' && 'bg-danger'}`}
                  onClick={() => handleRemoveTechnology(i)}
                >X
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <p className='fw-bold mt-40'>Social Impact Areas</p>
        <Select
          name="social_impact_areas"
          placeholder="Begin typing"
          value={socialImpactSelections}
          options={socialImpacts.map((impact) => (
            { value: impact, label: impact }
          ))}
          onChange={handleSocialSelection}
          multi
        />
        <Row className="m-0">
          {socialImpactSelections?.length > 0 && socialImpactSelections.map((impact, i) => (
            <Col key={i} xs={12} md={6} lg={4} className="px-0 px-lg-8 my-8">
              <div className="d-flex align-items-center justify-content-between rounded-5 bg-primary ps-16 py-4 pe-8">
                <p className="small text-white fw-bold m-0">{impact.value}</p>
                <Button
                  className="border-0 py-0 px-4"
                  onClick={() => handleRemoveSocial(i)}
                >X
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </Form>
      <div className='mt-80 px-xl-168 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 sms-lg-16 w-50'
          onClick={() => onNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default Ein