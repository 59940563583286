import { organizationStripeConstants } from '../_constants';
import { organizationStripeService } from '../_services';

export const organizationstripeActions = {
    addBillingInfo,
    getOrgBillingInfo,
    setOrgBillingInfo,
    updateOrgBillingInfo,

    setIndvBilling,

    updateOrgBillingAddress,
    setOrgBillingAddress,

    verifyBankAccount,
    requestUpdatePaymentMethod
};

function addBillingInfo(billing_info) {
    return dispatch => {
        dispatch(request());
        organizationStripeService.addBillingInfo(billing_info)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: organizationStripeConstants.ADDBILLINGINFO_REQUEST } }
    function success() { return { type: organizationStripeConstants.ADDBILLINGINFO_SUCCESS } }
    function failure(error) { return { type: organizationStripeConstants.ADDBILLINGINFO_FAILURE, error } }
}

function getOrgBillingInfo(user_id, org_id) {
    return dispatch => {
        dispatch(request());
        organizationStripeService.getBillingInfo(org_id)
            .then(
                (result) => {
                    dispatch(success(result))
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: organizationStripeConstants.GET_BILLING_INFO_REQUEST } }
    function success(result) { return { type: organizationStripeConstants.GET_BILLING_INFO_SUCCESS, billingInfo: { ...result.billingInfo, same_as_indv: result.same_as_indv, is_required: result.is_required } } }
    function failure(error) { return { type: organizationStripeConstants.GET_BILLING_INFO_FAILURE, error } }
}

function setOrgBillingInfo(billingInfo) {
    return dispatch => {
        dispatch(success(billingInfo))
    }

    function success(billingInfo) { return { type: organizationStripeConstants.GET_BILLING_INFO_SUCCESS, billingInfo } }
}

function updateOrgBillingInfo(productDetails) {
    return dispatch => {
        dispatch(request());
        organizationStripeService.updateBillingInfo(productDetails)
            .then(
                result => {
                    dispatch(success(result.billingInfo));
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() { return { type: organizationStripeConstants.GET_BILLING_INFO_REQUEST } }
    function success() { return { type: organizationStripeConstants.GET_BILLING_INFO_SUCCESS } }
    function failure() { return { type: organizationStripeConstants.GET_BILLING_INFO_FAILURE } }
}

function setIndvBilling(userDetails) {
    return async dispatch => {
        dispatch(success(userDetails.same_as_indv));
        await organizationStripeService.setIndvBilling(userDetails);
    }

    function success(same_as_indv) { return { type: organizationStripeConstants.SET_INDIVIDUAL_BILLING, same_as_indv } }
}

function updateOrgBillingAddress(billings) {
    return dispatch => {
        dispatch(request());
        organizationStripeService.updateBillingAddress(billings).then(
            billingAddress => {
                dispatch(success(billingAddress));
            },
            error => {
                dispatch(failure(error));
            }
        )
    }

    function request() { return { type: organizationStripeConstants.BILLING_ADDRESS_REQUEST } }
    function success(billingAddress) { return { type: organizationStripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
    function failure(error) { return { type: organizationStripeConstants.BILLING_ADDRESS_FAILURE, error } }
}

function setOrgBillingAddress(billingAddress) {
    return dispatch => {
        dispatch(success(billingAddress));
    }

    function success(billingAddress) { return { type: organizationStripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
}

function verifyBankAccount(reset, org_id, deposit_1, deposit_2) {
    return dispatch => {
        if (reset) {
            dispatch(failure())
        }
        else {
            dispatch(request());
            organizationStripeService.verifyBankAccount(org_id, deposit_1 * 100, deposit_2 * 100)
                .then(
                    (result) => {
                        if (result) {
                            dispatch(success(result.billingInfo));
                        } else {
                            dispatch(failure());
                        }
                    },
                    error => {
                        dispatch(failure(error));
                    }
                )
        }
    }

    function request() { return { type: organizationStripeConstants.BANK_VERIFICATION_REQUEST } }
    function success(billingInfo) { return { type: organizationStripeConstants.BANK_VERIFICATION_SUCCESS, billingInfo } }
    function failure() { return { type: organizationStripeConstants.BANK_VERIFICATION_FAILURE } }
}

function requestUpdatePaymentMethod(paymentSelection) {
    return dispatch => {
        dispatch(request(paymentSelection));
    }

    function request(paymentSelection) { return { type: organizationStripeConstants.UPDATE_BILLING_INFO_REQUEST, paymentSelection } }
}