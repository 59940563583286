import React from 'react';
import { Component } from 'react';
import { connectRange } from 'react-instantsearch-dom';

class CustomRangeInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMin: "",
            currentMax: "",
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearMinMax !== prevProps.clearMinMax) {
            if (this.props.clearMinMax === true) {
                this.setState({
                    currentMin: "",
                    currentMax: "",
                })
            }
        }

        if (this.props.savedRange !== prevProps.savedRange) {
            if (this.props.savedRange && this.props.savedRange.range && this.props.savedRange.range.funding_Amount) {
                this.setState({
                    currentMin: this.props.savedRange.range.funding_Amount.min,
                    currentMax: this.props.savedRange.range.funding_Amount.max,
                })
            }
        }
    }

    render() {

        const { min, max, currentRefinement, refine } = this.props
        const { currentMin, currentMax } = this.state

        return (
            <div>
                <form onSubmit={e => { e.preventDefault() }}>
                    {/* MIN */}
                    <input
                        className="ais-RangeInput-input ais-RangeInput-input--min"
                        type="number"
                        placeholder="Min"
                        min={min}
                        max={max}
                        value={currentMin}
                        onChange={(e) => {
                            this.setState({
                                currentMin: e.target.value
                            })
                        }}
                    />
                    {/* MAX */}
                    <input
                        className="ais-RangeInput-input ais-RangeInput-input--max"
                        type="number"
                        placeholder="Max"
                        min={min}
                        max={max}
                        value={currentMax}
                        onChange={(e) => {
                            this.setState({
                                currentMax: e.target.value
                            })
                        }}
                    />
                    <button
                        className="ais-RangeInput-submit"
                        type="submit"
                        onClick={() => {
                            refine({
                                ...currentRefinement,
                                min: currentMin,
                                max: currentMax,
                            })
                            this.props.handleMinMax(false)
                        }}>
                        ok
                    </button>
                </form>
            </div>
        )
    }
}

export default connectRange(CustomRangeInput);
