import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap"
import { useSelector } from "react-redux";
import 'react-slideshow-image/dist/styles.css';
import '../../Styles/Algolia.css';
import "../../_assets/css/material-dashboard-react.css";
import "../../_assets/img/icons/sideNavIcons/css/fontello.css";
import { asyncLocalStorage, dashboardService, favoritesService, grantMatchesService, organizationService, pluginService, serviceProviderService, sharedService, stripeService } from '../../_services';
import GrantNotification from "./Notifications/Grant/GrantNotification";
import ActionNotification from "./Notifications/Actions/ActionNotification";
import ogLogoSmall from "../../_assets/img/opengrants-logo-small.svg";

const DashboardView_OpenGrants_v2 = (props) => {
    const [iFrameConfig, setIFrameConfig] = useState({});
    const { user } = useSelector(state => state.setUser);
    const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);
    const [actions, setActions] = useState([]);
    const [grantMatches, setGrantMatches] = useState([]);
    const [grantMatchesLength, setGrantMatchesLength] = useState(0);
    const [favorites, setFavorites] = useState({});

    const [org, setOrg] = useState({});

    const [loading, setLoading] = useState('Retrieving');

    const history = useHistory()

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let query = [
            getIFrameConfig(),
            getActions(),
            getGrantMatches(),
            getFavorites(),
            getOrganization(),
            getUserType()
        ];

        await Promise.all(query);
        setLoading(false);
    }

    const getIFrameConfig = () => {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            setIFrameConfig(value)
        });
    }

    const getActions = async () => {
        const dashboard = await dashboardService.loadDashboard(user);
        setActions(dashboard.action_Items);
    }

    const getGrantMatches = async () => {
        setLoading('Retrieving');

        //get matches
        const result = await grantMatchesService.getAllMatches();
        if (!result) return;

        //check if matches need regeneration
        if (result?.regenerate || result?.matches?.length) {
            if (result?.regenerate || new Date(result.matches[0].date_Created.split('T')[0]) < new Date('2023-12-26')) {
                await regenerateGrantMatches();
                return;
            }
        }

        setGrantMatches(result?.matches ?? []);
        setGrantMatchesLength(result?.total ?? 0);
    }

    const regenerateGrantMatches = async () => {
        setLoading('Regenerating');

        //regenerate matches
        try {
            await grantMatchesService.regenerateEmbeddings();
            await grantMatchesService.regenerateGrantMatches();
        } catch (err) { }
        //get new matches
        await getGrantMatches();

        setLoading(false);
    }

    const getFavorites = async () => {
        const favorites = await favoritesService.getFavorites(user);

        setFavorites(favorites);
    }

    const updateFavorites = async (newFavorites) => {
        setFavorites(newFavorites);
    }

    const getOrganization = async () => {
        //get org
        const userOrg = await organizationService.getFirstOrganizationByUser(user.id, true);
        setOrg(userOrg);
    }

    const getUserType = async () => {
        // const service_Provider = await serviceProviderService.getServiceProvidersByUser(user.id);
        // console.log(service_Provider)
        console.log(user)
    }

    const updateGrantMatches = (type, grant) => {
        let newGrantMatches = [];
        grantMatches.map(grantMatch => {
            if (type === 'listings') {
                if (grant.listing_Id == grantMatch.Grant_Id) {
                    grantMatch.Portal_Listings = grant;
                }
            }
            if (type === 'funders') {
                if (grant.id == grantMatch.Funder_Id) {
                    grantMatch.Funders = grant;
                }
            }

            newGrantMatches.push(grantMatch);
        });

        setGrantMatches(newGrantMatches);
    }

    return (

        <div className="dashboardView">
            <div className="row">
                <div className="col-md-12">
                    <div>
                        {!user?.date_Completed_Common_App &&
                            <div className={`d-flex justify-content-center w-100 ${service_Provider ? 'py-40' : 'pb-64'}`}>
                                {!service_Provider && <Button
                                    className="bg-transparent border border-2 border-primary-dark rounded-pill fw-bold py-16 px-104"
                                    onClick={() => history.push('/landing')}
                                >
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 me-24">Get Pre-Qualified for Future Grants</p>
                                        <Image src={ogLogoSmall} style={{ height: "3rem" }} className="ms-24" />
                                    </div>
                                </Button>}
                            </div>}

                        <ActionNotification user={user} actions={actions} />

                        {/* GRANT MATCHES */}
                        {org && <>
                            <GrantNotification
                                org={org}
                                iFrameConfig={iFrameConfig}
                                grantMatches={grantMatches}
                                grantMatchesLength={grantMatchesLength}
                                regenerateGrantMatches={regenerateGrantMatches}
                                updateGrantMatches={updateGrantMatches}
                                favorites={favorites}
                                updateFavorites={updateFavorites}
                                loading={loading}
                            />
                            {/* <TANotification
                                user={user}
                                techAssistance={techAssistance}
                            /> */}
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardView_OpenGrants_v2;