import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Component } from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'reactstrap';
import '../../../Styles/NavMenu.css';
import logout from "../../../_assets/img/icons/logout-icon.png";
import message from "../../../_assets/img/icons/message-icon.svg";
import { asyncLocalStorage, pluginService, sharedService } from '../../../_services';

class MobileNavMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            iFrameUrl: null,
        }
    }

    async componentDidMount() {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({
                iFrameUrl: value?.url ? value?.url : ''
            })
        });
    }

    goToLink = (url) => {
        window.location.href = pluginService.redirect(url);
    }

    render() {
        const { user } = this.props;
        const { service_Provider } = this.props;
        const { iFrameUrl } = this.state;

        return (
            <div>
                {user &&

                    <div hidden id="mobile-nav" className="nav navbar-nav">
                        <ul>
                            <NavItem onClick={() => this.goToLink("/dashboard")} className="mobileLI">
                                <i className="icon-home-icon" />
                                <a>Dashboard</a>
                            </NavItem>
                            <NavItem onClick={() => this.goToLink("/search")} className="mobileLI">
                                <i className="icon-magnify-icon" />
                                <a>Search Grants</a>
                            </NavItem>
                            {!service_Provider &&
                                <NavItem onClick={() => this.goToLink("/marketplace")} className="mobileLI">
                                    <i class="icon-store-icon" />
                                    <a>Hire Experts</a>
                                </NavItem>
                            }
                            {(user.userRoles !== 'Client_Free') && !iFrameUrl &&
                                <NavItem onClick={() => this.goToLink('/favorites')} className="mobileLI">
                                    <i class="icon-heart-icon" />
                                    <a>Saved Grants</a>
                                </NavItem>
                            }
                            {(user.userRoles === 'Client_Free') && !iFrameUrl &&
                                <NavItem onClick={() => this.goToLink('/profiles?val=billing')} className="mobileLI">
                                    <i class="icon-heart-icon" />
                                    <a>Saved Grants</a>
                                </NavItem>
                            }
                            <NavItem onClick={() => this.goToLink('/grant-navigator')} className="mobileLI">
                                <i class="icon-briefcase-icon" />
                                <a>AI Grant Helper</a>
                            </NavItem>
                            <NavItem onClick={() => this.goToLink('/profiles/' + user.userName + iFrameUrl)} className="mobileLI">
                                <i class="icon-usercircle-icon" />
                                <a>Profile</a>
                            </NavItem>
                            {service_Provider &&
                                <NavItem onClick={() => this.goToLink("/organizations/" + service_Provider.organization_Id)} className="mobileLI" style={{ display: "flex" }}>
                                    <div style={{ position: "relative" }}>
                                        <i class="icon-usercircle-icon" />
                                        <i className="icon-store-icon" style={{ backgroundColor: "#fff", borderRadius: "50%", position: "absolute", top: 8, left: 15, fontSize: 10, width: 17, height: 17, lineHeight: 1.75 }} />
                                    </div>
                                    <a>Marketplace Profile</a>
                                </NavItem>
                            }
                            <NavItem onClick={() => this.goToLink("/messages" + iFrameUrl)} className="mobileLI">
                                <img src={message} alt="Messages" style={{ marginLeft: "4px", width: "21px" }} />
                                <a>Messages</a>
                            </NavItem>
                            {/* <NavItem id="nav-settings" onClick={() => this.goToLink('/settings')}className="mobileLI">
                            <i className="material-icons">settings</i>
                            <a>Settings</a>
                        </NavItem> */}
                            <NavItem onClick={() => this.goToLink('/login' + iFrameUrl)} className="mobileLI" style={{ padding: 0 }}>
                                <img src={logout} alt="Sign out" style={{ marginLeft: "4px", width: "20px" }} />
                                <a>Logout</a>
                            </NavItem>
                        </ul>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    return {
        user,
        service_Provider,
    };
}

const connectedHomePage = connect(mapStateToProps)(MobileNavMenu);
export { connectedHomePage as MobileNavMenu };
