import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { sharedService } from './shared.service';

export const funderService = {
    get
};

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/funders/' + id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}