import React from 'react';
import { useState } from 'react';
import { pluginService } from "../../../../_services";
import { Verify_ACH_Debit } from './PaymentTypes/Verify_ACH_Debit';

export const PaymentMethodView = (props) => {
  const { card, bank_Account } = props.billingInfo;

  const [showButton, setShowButton] = useState(true)
  const [showVerificationForm, setshowVerificationForm] = useState(false);

  let accentColor = pluginService.getIFrameColor()

  function displayPaymentMethod() {
    if (card.cardNumber) {
      return displayCard();
    }
    else if (bank_Account.account_Number) {
      return displayBank();
    }
    return null;
  }

  function displayCard() {
    return (
      <>
        {/* Regular styling */}

        {props.regularStyling &&
          <div className="">
            <h3 className="checkoutSubHeading">Payment Information</h3>
            <p>Card Number: <span>xxxx-xxxx-xxxx-{card.cardNumber}</span></p>
            <p>Expiration Date: <span>{card.expDate}</span></p>
          </div>
        }

        {/* Billing page specific styling */}

        {!props.regularStyling &&
          <>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                Card Number
              </div>
              <div className="col-md-5 billingInfoRightCol">
                xxxx-xxxx-xxxx-{card.cardNumber}
              </div>
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                Expiration Date
              </div>
              <div className="col-md-5 billingInfoRightCol">
                {card.expDate}
              </div>
            </div>
          </>
        }
      </>
    )
  }

  function setVerificationForm(e, show) {
    e.preventDefault();
    setshowVerificationForm(show)
    setShowButton(!show)
  }

  function displayBank() {
    return (
      <>
        {/* Regular styling */}

        {props.regularStyling &&
          <div className="">
            <h3 className="checkoutSubHeading">Payment Information</h3>
            {!(bank_Account.status === 'validated' || bank_Account.status === 'verified') && showButton &&
              <>
                <p style={{ fontSize: 15, marginTop: 20, marginBottom: 30 }}>
                  <b>ACTION REQUIRED!</b> Stripe will automatically send two small deposits to your account.
                  These deposits take 1-2 business days to appear on your online statement.
                  The statement has a description that includes AMTS followed by the two microdeposit amounts.
                  When you have those, return to the billing page and click "Verify" to complete your payment setup.
                </p>
              </>
            }
            <p>Bank Name: <span>{bank_Account.bank_Name}</span></p>
            <p>Account Holder: <span>{bank_Account.account_Holder_Name}</span></p>
            <p>Account Type: <span>{bank_Account.account_Holder_Type}</span></p>
            <p>Account Number: <span>****-***-{bank_Account.account_Number}</span></p>
            <p>Routing Number: <span>{bank_Account.routing_Number}</span></p>
            <p>Verification Status: <span>{(bank_Account.status === 'validated' || bank_Account.status === 'verified') ? "Verified" : "Not Verified"}</span></p>
            {!(bank_Account.status === 'validated' || bank_Account.status === 'verified') && showButton &&
              <>
                <button className="billingButton" style={{ marginTop: "10px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={(e) => setVerificationForm(e, true)}>Verify <span style={{ color: 'red' }}>*</span></button>
              </>
            }
          </div>
        }

        {/* Billing page specific styling */}

        {!props.regularStyling &&
          <div>
            <div className="row paymentRow">
              <div className="billingInfoRightCol">
                {!(bank_Account.status === 'validated' || bank_Account.status === 'verified') && showButton &&
                  <>
                    <p style={{ fontSize: 15, margin: "10px 15px" }}>
                      <b>ACTION REQUIRED!</b> Stripe will automatically send two small deposits to your account.
                      These deposits take 1-2 business days to appear on your online statement.
                      The statement has a description that includes AMTS followed by the two microdeposit amounts.
                      When you have those, return to the billing page and click "Verify" to complete your payment setup.
                    </p>
                  </>
                }
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Bank Name</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>{bank_Account.bank_Name}</p>
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Account Holder</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>{bank_Account.account_Holder_Name}</p>
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Account Type</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>{bank_Account.account_Holder_Type}</p>
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Account Number</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>****-***-{bank_Account.account_Number}</p>
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Routing Number</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>{bank_Account.routing_Number}</p>
              </div >
            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol">
                <p>Verification Status</p>
              </div>
              <div className="col-md-5 billingInfoRightCol">
                <p>{(bank_Account.status === 'validated' || bank_Account.status === 'verified') ? "Verified" : "Not Verified"}</p>
              </div >
            </div>
            <div className='row paymentRow'>
              <div className="col-md-3 billingInfoLeftCol" />
              <div className="col-md-5 billingInfoRightCol">
                {!(bank_Account.status === 'validated' || bank_Account.status === 'verified') && showButton &&
                  <>
                    <button
                      className="billingButton"
                      onClick={(e) => setVerificationForm(e, true)}
                      style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                    >
                      Verify <span style={{ color: 'red' }}>*</span>
                    </button>
                  </>
                }
              </div>
            </div >
          </div>
        }
      </>
    )
  }

  return (
    <>
      {displayPaymentMethod()}
      {showVerificationForm && !(bank_Account.status === "validated" || bank_Account.status === "verified") &&
        <Verify_ACH_Debit
          billingInfo={props.billingInfo}
          regularStyling={props.regularStyling}
          org_Id={props.org_Id}
          setVerificationForm={(e) => setVerificationForm(e, false)}
          setStopSubmit={props.setStopSubmit}
        />
      }
    </>
  )
}