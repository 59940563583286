import React from 'react';
import { Redirect } from "react-router-dom";

export const Redirect_Routes = (props) => {
    const username = (props.user && props.user.userName) ? "/"+props.user.userName : '';

    return [
      <Redirect from="/registerclient" to="/register" />,
      <Redirect from="/settings" to={"/profiles"+username} />,
      <Redirect from="/profile" to={"/profiles"+username } />,
      <Redirect from="/listings/grant/:string" to="/grants/:string" />,
      <Redirect from="/listings" to="/search" />,
      <Redirect from="/grants" exact={true} to="/search" />,
      <Redirect from="/invoices" exact={true} to="/invoices-hours" />,
      <Redirect from="/cl/invoices" exact={true} to="/invoices-hours" />,
      <Redirect from="/sp/invoices" exact={true} to="/invoices-hours" />,
      <Redirect from="/cl/projects/marketplace" exact={true} to="/cl/projects" />,
    ]
}