import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";

export default function MessagePreview({ channel, user, prepareChat }) {
    const [backgroundColor, setBackgroundColor] = useState((channel.last_Message_User_Id !== user.id) && (!channel.last_Message_Read || (new Date(channel.last_Message_Read) < new Date(channel.last_Message_Sent))) ? "rgb(217, 240, 255)" : "")

    const cvtDateTimeString = (datetime) => {
        var datetime_split = datetime.split("T");

        var date_split = datetime_split[0].split("-");
        if (date_split[1][0] == "0") {
            date_split[1] = date_split[1].substr(1);
        }
        if (date_split[2][0] == "0") {
            date_split[2] = date_split[2].substr(1);
        }
        var date = date_split[1] + "/" + date_split[2] + "/" + date_split[0];

        let meridiem = "AM";

        var time = datetime_split[1].split(".")[0];
        var hour = time.split(":")[0];
        if (parseInt(hour) > 12) {
            var hour_string = (parseInt(hour) - 12).toString();
            time = hour_string + time.substr(2);
            meridiem = "PM";
        }
        if (time[0] == "0") {
            time = time.substr(1);
        }

        var datetime_string = date + ", " + time + " " + meridiem;
        return datetime_string;
    }

    let channelUsername = channel.user_Info ? channel.user_Info.username : channel.username;

    return (
        <>
            <div
                key={channelUsername}
                id="messageBox"
                onClick={() => { prepareChat(channel); setBackgroundColor(""); }}
                style={{ background: backgroundColor }}
                data-tip={"Click to open chat box"}
                data-for='messageBoxTT'
            >
                <ReactTooltip
                    id='messageBoxTT'
                    className='messageBoxTT'
                    place={window.location.pathname.includes("messages") ? "right" : "top"}
                    effect="solid"
                    multiline={true}
                    textColor='dark'
                    backgroundColor='white'
                    border={true}
                    borderColor='rgb(128, 128, 128)'
                />
                <div className="row">
                    <div className="last-message col pe-40">
                        <img
                            src={(channel.user_Info && channel.user_Info.logo_URL) ? channel.user_Info.logo_URL : "https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/alternate.png"}
                            height="60"
                            width="60"
                            style={{ float: "left" }}
                            alt="profile circle"
                        />

                        <div id="msgText">
                            <h5 style={{ marginTop: 0, marginBottom: 5, fontWeight: 500 }}>
                                <span id="name" className='last-message-name'>
                                    {channel.user_Info ? channel.user_Info.name : channel.name} {channel.user_Info ? channel.user_Info.surname : channel.surname}
                                </span>
                                <br />
                                <span id="orgName" style={{ fontFamily: "Ubuntu", fontSize: 14, fontWeight: 600, color: "#777" }}>
                                    {channel.user_Info ? channel.user_Info.organization_Name : channel.organization_Name}
                                </span>
                            </h5>
                            <br />
                            <span style={{ fontWeight: 900, color: "#777", fontFamily: "Lato", fontSize: 12 }}>Last Message</span>
                            <br />
                            <strong>{channel.last_Message_User_Id == user.id ? "You" : (channel.user_Info ? channel.user_Info.name : channel.name)}:</strong>
                            <p style={{ wordBreak: "break-word" }}>{channel.last_Message}</p>
                        </div>
                    </div>
                    <p className="last-message-date col-auto">{channel.last_Message_Sent && (cvtDateTimeString(channel.last_Message_Sent))} </p>
                </div>
            </div>
            <hr />
        </>

    )
}
