import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/Marketplace.css';
import '../../Styles/SearchResults.css';
import { searchActions } from '../../_actions';
import { PowerSearch_v2 } from './PowerSearch_v2';

class SearchListings_v2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                categories: [],
                category_Tags: [],
                locations: [],
                agencies: [],
                page: 1,
                totalResults: 0,
            },

            // has_an_org: true,
            display_no_org_alert: true,
            display_created_organization: true,
        };

        this.childDiv = React.createRef();
        this.updateSearch = this.updateSearch.bind(this);
    }

    async componentDidMount() {
        // const user = JSON.parse(localStorage.getItem('user'));
        // var result = await organizationService.getAllOrganizationsByUser(user.id);
        // let has_an_org = Object.keys(result.organizations).length > 0 ? true : false;

        // this.setState({
        //     has_an_org
        // });
    }

    async updateSearch(searchParameters) {
        await this.setState(prevState => ({
            searchGroup: {
                ...searchParameters,
                search: localStorage.getItem('activeSearch'),
            }
        }), () => {
            this.props.dispatch(searchActions.getSearchResults(this.state.searchGroup))
        });
    }

    update_display_alert = (alert) => {
        this.setState({
            ...alert
        });
    }

    cancel_messaging = () => {
        // this.props.dispatch(organizationActions.organizationAlert("remove_message_block"));
        window.location.reload();
    }

    render() {
        // const { created_organization } = this.props;
        // const { has_an_org } = this.state;

        return (
            <div>
                {/* <h1 id="mobileSearchHeader">Search Listings</h1> */}
                <div id="searchListingsDiv" className="row">
                    {/* {!has_an_org && !created_organization && <div id="createOrgStepBackground">
                        {this.state.display_no_org_alert &&
                            <div id="createOrgStep">
                            <p><strong>You must create your organization before searching for grants.</strong></p>
                                <br />
                                <Button color="info" onClick={() => this.update_display_alert({display_no_org_alert: false})} style={{marginRight: 20}}>Continue</Button>
                                <Button onClick={this.cancel_messaging}>Cancel</Button>
                            </div>
                        }
                        {!this.state.display_no_org_alert &&
                            <div style={{    
                                background: "white",
                                padding: "50px",
                                margin: 50
                            }}>
                                <Organization serviceProviderId={this.state.service_Provider_Id}/>
                            </div>
                        }
                    </div>} */}

                    {/* {created_organization && this.state.display_created_organization &&
                        <div id="createOrgStepBackground">
                            <div id="createOrgStep">
                                <p><strong>Thanks for creating an organization! <br />You may now search for grants.</strong></p>
                                <br />
                                <Button color="info" onClick={() => this.update_display_alert({display_created_organization: false})}>Close</Button>
                            </div>
                        </div>
                    } */}
                    <div id="searchResultsReturned" className="col-sm-12">
                        <PowerSearch_v2 />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { searchResults, setUser, favorites } = state;
    const { created_organization } = state.organization_createorganizationalert;
    const { user } = setUser;
    return {
        user,
        searchResults,
        favorites,
        created_organization
    };
}

const connectedHomePage = connect(mapStateToProps)(SearchListings_v2);
export { connectedHomePage as SearchListings_v2 };

