import { searchConstants } from '../_constants';

export function searchResults(state = {}, action) {
  switch (action.type) {
    case searchConstants.GETSEARCHRESULTS_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GETSEARCHRESULTS_SUCCESS:
      localStorage.setItem('activeCategories', JSON.stringify(action.searchResults.listingCategoriesTags));
      localStorage.setItem('activeAgencies', JSON.stringify(action.searchResults.listingAgencyTags));
      return {
        result: action.searchResults
      };
    case searchConstants.GETSEARCHRESULTS_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}

export function categories(state = {}, action) {
  switch (action.type) {
    case searchConstants.GETALLCATEGORIES_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GETALLCATEGORIES_SUCCESS:
      return {
        items: action.categoriesList
      };
    case searchConstants.GETALLCATEGORIES_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}

export function agencies(state = {}, action) {
  switch (action.type) {
    case searchConstants.GETALLAGENCIES_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GETALLAGENCIES_SUCCESS:
      return {
        items: action.agenciesList
      };
    case searchConstants.GETALLAGENCIES_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}

export function locations(state = {}, action) {
  switch (action.type) {
    case searchConstants.GETALLLOCATIONS_REQUEST:
      return {
        loading: true
      };
    case searchConstants.GETALLLOCATIONS_SUCCESS:
      return {
        items: action.locationsList
      };
    case searchConstants.GETALLLOCATIONS_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}