import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import girlStanding from '../../../_assets/img/girlStanding2.svg'
import chatBubble2 from '../../../_assets/img/chatBubble2.png'
import OnboardingBase from './OnboardingBase'

const GirCharacter = ({ chatQuoteOptions, girlPosition }) => {
  const [chatQuote, setChatQuote] = useState(chatQuoteOptions ? chatQuoteOptions[0] : null)

  useEffect(() => {
    if (chatQuoteOptions) getNextQuote(1)
  }, [])

  const getNextQuote = (n) => {
    if (chatQuoteOptions.length === 1) return;

    setTimeout(() => {
      setChatQuote(chatQuoteOptions[n]);
      if (n + 1 < chatQuoteOptions.length) getNextQuote(n + 1);
    }, 5000);
  }

  return (<div className={girlPosition ? girlPosition : 'girl-position-1'}>
    <Image style={{ width: 110, position: 'absolute', zIndex: 3 }} src={girlStanding} />
    {chatQuote && <div style={{ position: 'absolute', right: 0, maxWidth: 370, width: 'max-content' }}>
      <Image className='w-100 position-absolute h-100' style={{ zIndex: 2, left: 0 }} src={chatBubble2} />
      <p className='fs-4' style={{ zIndex: 3, position: 'relative', padding: '0px 42px 0px 50px', textAlign: 'center', margin: 'revert' }}>{chatQuote}</p>
    </div>}
  </div>)
}

export default GirCharacter;