import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import "../../../../Styles/Onboarding.css";
import stripeBanner from "../../../../_assets/img/stripe-banner-black.svg";
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../../../_helpers';
import { pluginService, stripeService } from '../../../../_services';

let accentColor = pluginService.getIFrameColor()

class GC_Stripe_Onboarding extends React.Component {
    constructor(props) {
        super(props);

        this.state = { displayForm: false };
        this.stripe_Connect_Onboarding_Link = this.stripe_Connect_Onboarding_Link.bind(this);
    }

    async componentDidMount() {
        document.body.scrollTo(0, 0);
    }

    async stripe_Connect_Onboarding_Link() {
        const { user, service_Provider } = this.props;
        if (!service_Provider.stripe_Account_Id) {
            service_Provider.created_By_User_Id = user.id;
            var stripe_IDs = await stripeService.createStripeConnectAccount(service_Provider, 'individual');

            if (stripe_IDs) {
                service_Provider.stripe_Account_Id = stripe_IDs.stripe_Account_Id;
            } else {
                this.setState({ displayForm: true });
                return;
            }
        }

        let return_Link = await stripeService.onboardConnectedStripeAccount(service_Provider.stripe_Account_Id, window.location.origin + '/service-provider-success');
        window.location.href = pluginService.redirect(return_Link.url)
    }

    render() {
        const { displayForm } = this.state;

        return (
            <>
                <div className="row">
                    <div className="col-md-10" />
                    <div className="col-md-1">
                        <p className="gc_onboarding-form-input-name-top">
                            1 of 2
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-5 progress-color" />
                    <div className="col-md-5 gray-progress-color" />
                </div>
                <div className="gc_onboarding-form-card-stripe p-48" id="service_provider_onboarding_page">
                    <h1>Payment Onboarding</h1>
                    <hr />
                    <div className="gc_onboarding-form-col">
                        <p className="gc_onboarding-form-input-name">
                            One more required step!
                        </p>
                        <p className="gc_onboarding-form-input-name">
                            Complete your Stripe verification so we can pay you quickly and securely. Your profile will not be approved until Stripe verification is complete.
                        </p>
                    </div>
                    <div className="gc_onboarding-form-col">
                        <p className="gc_onboarding-form-input-name">
                            To help Stripe verify your account quickly, we recommend using the "Business website" verification option. If you do not have a business website, use your Linkedin profile.
                        </p>
                    </div>
                    <br />
                    <div className="gc_onboarding-form-col">
                        <button
                            className="darkGreenBtn"
                            style={{ borderRadius: "5px", height: "60px", width: "250px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                            onClick={this.stripe_Connect_Onboarding_Link}
                        >
                            Continue to Stripe
                            <i class="icon-arrow-right" /></button>
                        <br /><br />
                        <img src={stripeBanner} height="60px" width="200px" alt="stripe banner" />
                    </div>
                    <br />
                </div>

                {/* POP UP FORMS TO CORRECT ERRORS */}
                {displayForm && <div>
                    <Modal
                        id="listingPreviewModal"
                        isOpen={displayForm}
                        fade={false}
                    >
                        <ModalHeader id="modalTop">
                        </ModalHeader>

                        <ModalBody>
                            <div>
                                <p>We're sorry, an error occurred while continuing to Stripe.
                                    The OpenGrants team have been notified,
                                    and they will contact you within 24 hours.
                                </p>
                            </div>
                        </ModalBody>

                        <ModalFooter className="centerFlex flexColumn">
                            <Button className="submitButton" onClick={() => history.push('/dashboard')}>
                                Return to Dashboard
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;

    return {
        user,
    };
}

const connectedGC_Stripe_Onboarding = connect(mapStateToProps)(GC_Stripe_Onboarding);
export { connectedGC_Stripe_Onboarding as GC_Stripe_Onboarding };

