import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";
import { EditAdditional } from "./EditAdditional";
import { EditExpertise } from "./EditExpertise";
import { EditFocus } from "./EditFocus";
import { EditRoles } from "./EditRoles";

export default class EditOrganizationAdditionalAttributes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            category_TagsError: false,
        }
    }

    render() {

        const { category_TagsError } = this.state;
        const {
            handleSelectionChange,
            handleChange,
            submitted,
            service_Provider,
            editOrganization,
            industry_Focus_List,
            industry_Service_List,
            foundation_Expertise_List,
            agency_Expertise_List,
            program_Expertise_List,
            checkboxes,
            handleEditCategoryInput,
            handleEditCategorySelection,
            handleAdditionalSubmit,
        } = this.props

        let industry_Focus_List_Cat = { "SP_Industry_Focus_Tags": industry_Focus_List };
        let industry_Service_List_Cat = { "SP_Industry_Services_Tags": industry_Service_List };
        let foundation_Expertise_List_Cat = { "GW_Foundation_Tags": foundation_Expertise_List };
        let agency_Expertise_List_Cat = { "GW_Agency_Tags": agency_Expertise_List };
        let program_Expertise_List_Cat = { "GW_Program_Tags": program_Expertise_List };

        return (
            <div>
                <h1 id="dashHeader">Additional Attributes</h1>
                <div className="row">

                    <div className="col-lg-6" style={{ fontSize: "18px" }}>
                        <br /><br />
                        <EditRoles
                            service_Provider={service_Provider}
                            handleChange={handleChange}
                            handleSelectionChange={handleSelectionChange}
                            submitted={submitted}
                            editOrganization={editOrganization}
                            checkboxes={this.props.checkboxes}
                            handleCheckbox={this.props.handleCheckbox}
                            handleDefaultCheckbox={this.props.handleDefaultCheckbox}
                            editErrors={this.props.editErrors}
                            isEmptyCheckbox={this.props.isEmptyCheckbox}
                        />

                        {checkboxes.roles.grant_Writer &&
                            <EditExpertise
                                service_Provider={service_Provider}
                                handleChange={handleChange}
                                handleSelectionChange={handleSelectionChange}
                                handleCategorySelection={handleEditCategorySelection}
                                handleCategoryInput={handleEditCategoryInput}
                                category_TagsError={category_TagsError}
                                submitted={submitted}
                                foundation_Expertise_List_Cat={foundation_Expertise_List_Cat}
                                agency_Expertise_List_Cat={agency_Expertise_List_Cat}
                                program_Expertise_List_Cat={program_Expertise_List_Cat}
                                editOrganization={editOrganization}
                                checkboxes={checkboxes}
                                editErrors={this.props.editErrors}
                                handleCheckbox={this.props.handleCheckbox}
                                handleDefaultCheckbox={this.props.handleDefaultCheckbox}
                                stringFoundationTypes={this.props.stringFoundationTypes}
                                stringAgencyTypes={this.props.stringAgencyTypes}
                                stringProgramTypes={this.props.stringProgramTypes}
                                handleEditChange={this.props.handleEditChange}
                                editTextInput={this.props.editTextInput}
                            />
                        }
                    </div>
                    <div className="col-lg-6" style={{ fontSize: "18px" }}>
                        <br /><br />
                        <EditFocus
                            service_Provider={service_Provider}
                            handleChange={handleChange}
                            handleSelectionChange={handleSelectionChange}
                            handleCategorySelection={handleEditCategorySelection}
                            handleCategoryInput={handleEditCategoryInput}
                            category_TagsError={category_TagsError}
                            submitted={submitted}
                            industry_Focus_List_Cat={industry_Focus_List_Cat}
                            industry_Service_List_Cat={industry_Service_List_Cat}
                            editOrganization={editOrganization}
                            stringFocusTypes={this.props.stringFocusTypes}
                            stringServiceTypes={this.props.stringServiceTypes}
                            editErrors={this.props.editErrors}
                        />

                        <br /><br />
                        <EditAdditional
                            service_Provider={service_Provider}
                            handleChange={this.props.handleChange}
                            handleSelectionChange={this.props.handleSelectionChange}
                            submitted={submitted}
                            handleEditChange={this.props.handleEditChange}
                            editTextInput={this.props.editTextInput}
                            checkboxes={this.props.checkboxes}
                            handleCheckbox={this.props.handleCheckbox}
                            handleDefaultCheckbox={this.props.handleDefaultCheckbox}
                        />
                    </div>
                </div>
                <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        onClick={handleAdditionalSubmit}
                        style={{ marginBottom: 30, marginTop: 30, background: "#29a" }}
                    >
                        UPDATE ADDITIONAL SETTINGS
                    </Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationAdditionalAttributes = connect(mapStateToProps)(EditOrganizationAdditionalAttributes);
export { connectedEditOrganizationAdditionalAttributes as EditOrganizationAdditionalAttributes };

