import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import '../../Styles/SettingsStyles/Billings.css';
import { organizationstripeActions, stripeActions } from '../../_actions';
import { organizationService } from '../../_services';
import { BillingView } from '../Stripe/Stripe_Copy/PaymentMethod';
import { Checkout_v2 } from '../Stripe/Stripe_Copy/Checkout';
import { billingHandler } from '../../_reducers/stripe.reducer';

class Billing_v2 extends Component {
  state = {
    updatePaymentMethod: false,
    profileInformationEdit: false,
    loading: true,
    updating: false,
    org_Id: 0,
  }

  async componentDidMount() {
    this.init();
  }

  init = async () => {
    await Promise.all([
      this.getBillingInfo(),
      this.getPlanSubscription()
    ]);
  }

  getBillingInfo = () => {
    if (!this.props.billingInfo?.card?.cardNumber && !this.props.billingInfo?.bank_Account?.account_Number) {
      this.props.dispatch(stripeActions.getBillingInfo(this.props.user.id));
    }
  }

  getPlanSubscription = async () => {
    if (!this.props.billingHandler.plan) {
      this.props.dispatch(stripeActions.getPlanSubscription(this.props.user.id));
    }
  }

  render() {
    const { billingInfo, billingInfo_org } = this.props;
    const { org_Id } = this.state;
    return (<>
      {
        this.props.billingHandler.plan ?
          <BillingView
            billingInfo={billingInfo}
            updatePage={this.props.updatePage}
            billingInfo_org={billingInfo_org}
            org_Id={org_Id}
          /> :
          <Checkout_v2 />
      }
    </>);
  }
}

function mapStateToProps(state) {
  const { setUser, billingInfo, billingInfo_org, billingHandler } = state;
  const { user } = setUser;
  return {
    user,
    billingInfo,
    billingHandler,
    billingInfo_org
  };
}

const connectedBilling_v2 = connect(mapStateToProps)(Billing_v2);
export { connectedBilling_v2 as Billing_v2 };
