import React from "react";
import { connect } from "react-redux";
import '../../Styles/ForgotPassword.css';
import { authenticateActions } from "../../_actions";
import { asyncLocalStorage, authenticateService } from "../../_services";
import logo from '../../_assets/img/opengrants-logo-big.svg';

class DeletionCompletePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iFrameConfig: null
    }

    // reset login status
    authenticateService.logout();
  }

  async componentDidMount() {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
    });
  }

  render() {
    const {
      iFrameConfig,
    } = this.state;

    return (
      <div id="forgotPassword">
        <div id="forgotPassowrdRightLogin">
          <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="logo" className="login-logo forgot-pswd-logo" style={iFrameConfig?.public ? { width: "40%", marginBottom: 40 } : { width: "70%", marginBottom: 40 }} />
          <hr />
          <br />
          <h3 className="forgot-pswd-heading">We're sorry to see you go. Your account has been removed from our system. If you have any questions or concerns please feel free to contact us at support@opengrants.io.</h3>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  return {
    user,
  };
}

const connectedDeletionCompletePage = connect(mapStateToProps)(DeletionCompletePage);
export { connectedDeletionCompletePage as DeletionCompletePage };

