import { authenticateConstants, settingsConstants } from '../_constants';
import { settingsService } from '../_services';
import { alertActions } from './';

export const settingsActions = {
    updateProfile,
    updateFocus,
    updateOrganization,
    updateAdvanced,
    updatePassword,
};

function updateProfile(updateUser) {
    return async dispatch => {
        dispatch(request());
        await settingsService.updateProfile(updateUser)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: settingsConstants.UPDATEPROFILE_REQUEST } }
    function success(user) { return { type: settingsConstants.UPDATEPROFILE_SUCCESS, user } }
    function failure(error) { return { type: settingsConstants.UPDATEPROFILE_FAILURE, error } }
}

function updateFocus(updateUser) {
    return async dispatch => {
        dispatch(request());
        await settingsService.updateFocus(updateUser)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { return { type: settingsConstants.UPDATEFOCUS_REQUEST } }
    function success(user) { return { type: settingsConstants.UPDATEFOCUS_SUCCESS, user } }
    function failure(error) { return { type: settingsConstants.UPDATEFOCUS_FAILURE, error } }
}

function updateOrganization(updateUser) {
    return async dispatch => {
        dispatch(request());
        await settingsService.updateOrganization(updateUser)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { return { type: settingsConstants.UPDATEORGANIZATION_REQUEST } }
    function success(user) { return { type: settingsConstants.UPDATEORGANIZATION_SUCCESS, user } }
    function failure(error) { return { type: settingsConstants.UPDATEORGANIZATION_FAILURE, error } }
}

function updateAdvanced(updateUser) {
    return async dispatch => {
        dispatch(request());
        await settingsService.updateAdvanced(updateUser)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: settingsConstants.UPDATEADVANCED_REQUEST } }
    function success(user) { return { type: settingsConstants.UPDATEADVANCED_SUCCESS, user } }
    function failure(error) { return { type: settingsConstants.UPDATEADVANCED_FAILURE, error } }
}

function updatePassword(password_model, user) {
    return async dispatch => {
        dispatch(request());
        await settingsService.updatePassword(password_model.oldpassword, password_model.newpassword, user)
            .then(
                user => {
                    //dispatch(userActions.login(user.email, password_model.newpassword));
                    dispatch(success(user));
                    dispatch(alertActions.updatePasswordSuccess(
                        "Your password has been succesfully updated."
                    ));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.updatePasswordError(
                        ["There was an error updating your password. ",
                            "Verfiy your current password is correct ",
                            "and that your new password is different from your current one."]
                    ));
                }
            );
    };

    function request() { return { type: authenticateConstants.UPDATEPASSWORD_REQUEST } }
    function success(user) { return { type: authenticateConstants.UPDATEPASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authenticateConstants.UPDATEPASSWORD_FAILURE, error } }
}