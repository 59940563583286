import React, { useEffect } from 'react';
import { Form, Button, Row, Col, FormCheck } from 'react-bootstrap'
import { Loader_v2 } from '../../Shared/Loader/Loader_v2';

const FundingSources = ({ form, boxCheck, handleNext, handleBack, setForm, onSubmit, loading }) => {
  const changeFundingSourcesIntros = (event) => {
    setForm({
      ...form,
      fundingSourceIntros: {
        ...form.fundingSourceIntros,
        [event.target.id]: {
          source: event.target.name,
          answer: event.target.value
        }
      }
    })
  }

  const checkYes = (event) => {
    setForm({
      ...form,
      fundingSourceIntros: {
        ...form.fundingSourceIntros,
        [event.target.id]: {
          source: event.target.name,
          answer: form.fundingSourceIntros?.[event.target.id]?.answer === 'Yes' ? '' : 'Yes'
        }
      }
    })
  }

  const checkNo = (event) => {
    setForm({
      ...form,
      fundingSourceIntros: {
        ...form.fundingSourceIntros,
        [event.target.id]: {
          source: event.target.name,
          answer: form.fundingSourceIntros?.[event.target.id]?.answer === 'No' ? '' : 'No'
        }
      }
    })
  }

  const handleUnsureCheckBox = () => {
    if (form.fundingSourceIntros?.['Current valuation? Unsure is an acceptable answer.'].answer === 'Unsure') {
      setForm({
        ...form,
        fundingSourceIntros: {
          ...form.fundingSourceIntros,
          ['Current valuation? Unsure is an acceptable answer.']: {
            source: 'Venture Capital',
            answer: ''
          }
        }
      })
    } else {
      setForm({
        ...form,
        fundingSourceIntros: {
          ...form.fundingSourceIntros,
          ['Current valuation? Unsure is an acceptable answer.']: {
            source: 'Venture Capital',
            answer: 'Unsure'
          }
        }
      })
    }
  }

  const onNext = async () => {
    await onSubmit()
    handleNext()
  }

  useEffect(() => {
    if (form.fetchedIntros && form.fetchedIntros.length) {

      let newIntros = {}

      for (let i = 0; i < form.fetchedIntros.length; i++) {
        newIntros = { ...newIntros, [form.fetchedIntros[i].question]: { answer: form.fetchedIntros[i].answer, source: form.fetchedIntros[i].source } }
      }

      setForm({
        ...form,
        fundingSourceIntros: newIntros,
        introBusinessLoans: !!form.fetchedIntros.filter((item) => item.source === 'Business Loans').length,
        introCreditLine: !!form.fetchedIntros.filter((item) => item.source === 'Credit Line').length,
        introGrantAdvance: !!form.fetchedIntros.filter((item) => item.source === 'Grant Advance').length,
        introVentureCapital: !!form.fetchedIntros.filter((item) => item.source === 'Venture Capital').length,
      })
    }
  }, [form.fetchedIntros])

  return (
    <>
      {loading && <div className='w-100 mt-48 text-center'>
        <Loader_v2 />
        <p className='mt-24'>Updating ...</p>
      </div>}
      {!loading && <>
        <Form className='w-100 mt-48'>
          <p className='fw-bold mt-40'>Are you interested in warm introductions to any of the following funding sources?</p>
          <Row className='mt-24'>
            <Col sm={6} className='d-block d-lg-flex justify-content-center'>
              <FormCheck className='d-flex align-items-center ms-lg-24 me-40'>
                <FormCheck.Input
                  checked={form.introVentureCapital}
                  type="checkbox"
                  style={{ height: '1.75rem', width: '1.75rem' }}
                  name="introVentureCapital"
                  onChange={boxCheck}
                />
                <FormCheck.Label className='mb-0 ms-16'>Venture Capital</FormCheck.Label>
              </FormCheck>
            </Col>
            <Col sm={6} className='mt-16 mt-lg-0 d-block d-lg-flex justify-content-center'>
              <FormCheck className='d-flex align-items-center ms-lg-24 me-40'>
                <FormCheck.Input
                  checked={form.introCreditLine}
                  type="checkbox"
                  style={{ height: '1.75rem', width: '1.75rem' }}
                  name="introCreditLine"
                  onChange={boxCheck}
                />
                <FormCheck.Label className='mb-0 ms-16'>Credit Line</FormCheck.Label>
              </FormCheck>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className='mt-16 d-block d-lg-flex justify-content-center'>
              <FormCheck className='d-flex align-items-center ms-lg-24 me-40'>
                <FormCheck.Input
                  checked={form.introBusinessLoans}
                  type="checkbox"
                  style={{ height: '1.75rem', width: '1.75rem' }}
                  name="introBusinessLoans"
                  onChange={boxCheck}
                />
                <FormCheck.Label className='mb-0 ms-16'>Business Loans</FormCheck.Label>
              </FormCheck>
            </Col>
            <Col sm={6} className='mt-16 d-block d-lg-flex justify-content-center'>
              <FormCheck className='d-flex align-items-center ms-lg-24 me-40'>
                <FormCheck.Input
                  checked={form.introGrantAdvance}
                  type="checkbox"
                  style={{ height: '1.75rem', width: '1.75rem' }}
                  name="introGrantAdvance"
                  onChange={boxCheck}
                />
                <FormCheck.Label className='mb-0 ms-16'>Grant Advance</FormCheck.Label>
              </FormCheck>
            </Col>
          </Row>
          {form.introVentureCapital &&
            <>
              <p className='fw-bold mt-40'>How much are you interested in raising?</p>
              <Form.Control
                type="number"
                id='How much are you interested in raising?'
                name='Venture Capital'
                value={form.fundingSourceIntros?.['How much are you interested in raising?']?.answer}
                onChange={changeFundingSourcesIntros}
                className='border border-2 border-light-gray'
              />
              <p className='mt-40 fw-bold'>Do you have a lead investor?</p>
              <div className='mt-24 d-flex align-items-center'>
                <FormCheck className='ms-24 me-40'>
                  <FormCheck.Input
                    checked={form.fundingSourceIntros?.['Do you have a lead investor?']?.answer === 'Yes'}
                    type="radio"
                    style={{ height: '1.75rem', width: '1.75rem' }}
                    name="Venture Capital"
                    id='Do you have a lead investor?'
                    onChange={checkYes}
                  />
                  <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
                </FormCheck>
                <FormCheck className=''>
                  <FormCheck.Input
                    checked={form.fundingSourceIntros?.['Do you have a lead investor?']?.answer === 'No'}
                    type="radio"
                    style={{ height: '1.75rem', width: '1.75rem' }}
                    name="Venture Capital"
                    id='Do you have a lead investor?'
                    onChange={checkNo}
                  />
                  <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
                </FormCheck>
              </div>
              <p className='fw-bold mt-40'>Current Valuation?</p>
              <Row>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    id='Current valuation? Unsure is an acceptable answer.'
                    name='Venture Capital'
                    value={form.fundingSourceIntros?.['Current valuation? Unsure is an acceptable answer.']?.answer || null}
                    onChange={changeFundingSourcesIntros}
                    className='border border-2 border-light-gray'
                  />
                </Col>
                <Col sm={4} className='d-flex align-items-center'>
                  <FormCheck className='d-flex align-items-center mt-16 mt-lg-0 ms-lg-14 me-40 mb-0'>
                    <FormCheck.Input
                      checked={form.fundingSourceIntros?.['Current valuation? Unsure is an acceptable answer.']?.answer === 'Unsure'}
                      type="checkbox"
                      style={{ height: '1.75rem', width: '1.75rem' }}
                      name="currentValuation"
                      id="unsure"
                      onChange={handleUnsureCheckBox}
                    />
                    <FormCheck.Label className='mb-0 ms-16'>I'm unsure</FormCheck.Label>
                  </FormCheck>
                </Col>
              </Row>
            </>
          }
          {form.introCreditLine &&
            <>
              <p className='fw-bold mt-40'>What is your current cash balance in bank?</p>
              <Row className='d-flex align-items-center'>
                <Col sx={5}>
                  <Form.Control
                    type="number"
                    id='cashMin'
                    name="Credit Line"
                    value={form.fundingSourceIntros?.cashMin?.answer}
                    onChange={changeFundingSourcesIntros}
                    placeholder='Min'
                    className='border border-2 border-light-gray'
                  />
                </Col>
                <Col sm={1} className='d-flex justify-content-center'>
                  <p className='d-none d-lg-block mb-0 fw-bold'>-</p>
                </Col>
                <Col sx={5} className='mt-24 mt-lg-0'>
                  <Form.Control
                    type="number"
                    id='cashMax'
                    name="Credit Line"
                    value={form.fundingSourceIntros?.cashMax?.answer}
                    onChange={changeFundingSourcesIntros}
                    placeholder='Max'
                    className='border border-2 border-light-gray'
                  />
                </Col>
              </Row>
            </>
          }
          {form.introBusinessLoans &&
            <>
              <p className='fw-bold mt-40'>What are you planning on using the funds for?</p>
              <Form.Control
                as='textarea'
                id='What are you planning on using the funds for?'
                name='Business Loans'
                value={form.fundingSourceIntros?.['What are you planning on using the funds for?']?.answer}
                onChange={changeFundingSourcesIntros}
                className='border border-2 border-light-gray'
                style={{ minHeight: '10rem' }}
              />
              <p className='fw-bold mt-40'>How soon do you need the funds?</p>
              <Form.Control
                type="text"
                id='How soon do you need the funds?'
                name='Business Loans'
                value={form.fundingSourceIntros?.['How soon do you need the funds?']?.answer}
                onChange={changeFundingSourcesIntros}
                className='border border-2 border-light-gray'
              />
              <p className='fw-bold mt-40'>How much do you need?</p>
              <Form.Control
                type="number"
                id='How much do you need?'
                name='Business Loans'
                value={form.fundingSourceIntros?.['How much do you need?']?.answer}
                onChange={changeFundingSourcesIntros}
                className='border border-2 border-light-gray'
              />
            </>
          }
        </Form>
        <div className='mt-80 px-xl-168 d-flex align-items-center'>
          <Button
            variant='primary-dark'
            className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
            onClick={() => handleBack()}
          >
            Back
          </Button>
          <Button
            variant='primary-dark'
            className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
            onClick={() => onNext()}
          >
            Next
          </Button>
        </div>
      </>}
    </>
  )
}

export default FundingSources