import React from 'react';
import { Component } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { unixDate } from '../SearchListings/Result';
import CustomClearAllRefinements from "./ClearRefinements";

class CustomCurrentRefinements extends Component {
  async componentDidMount() {
    sessionStorage.removeItem('to');
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to_time');
    sessionStorage.removeItem('from_time');
  }

  render() {
    const { items, refine, createURL } = this.props;
    const from = sessionStorage.getItem('from');
    const from_time = unixDate(sessionStorage.getItem('from_time'));
    const to = sessionStorage.getItem('to');
    const to_time = unixDate(sessionStorage.getItem('to_time'));

    return (
      <div id="currentFilters">
        {(items.length > 0 || from || to) &&
          <CustomClearAllRefinements handleMinMax={this.props.handleMinMax} />
        }
        {from &&
          <a
            href="#"
            onClick={event => {
              sessionStorage.removeItem('from');
              event.preventDefault();
              refine(undefined, true, "from");
            }}
          >
            <strong>Due After: </strong>
            <span style={{ paddingLeft: 0 }}>
              {from_time}
            </span>
          </a>
        }
        {(to && to !== null) &&
          <a
            href="#"
            onClick={event => {
              sessionStorage.removeItem('to');
              event.preventDefault();
              refine(undefined, true, "to");
            }}
          >
            <strong>Due Before: </strong>
            <span style={{ paddingLeft: 0 }}>
              {to_time}
            </span>
          </a>
        }
        {items.map(item => (
          <div>
            {item.items && item.items.map(nested => (
              <a
                href={createURL(nested.value)}
                onClick={event => {
                  event.preventDefault();
                  refine(nested.value);
                }}
              >
                {/* GRANT FINDER */}
                {item.label === "agency: " && <strong>Agency: </strong>}
                {item.label === "tags: " && <strong>Tag: </strong>}
                {item.label === "locations.country: " && <strong>Country: </strong>}
                {item.label === "locations.uS_State: " && <strong>US State: </strong>}
                {item.label === "state: " && <strong>US State: </strong>}
                {item.label === "locations.uS_City: " && <strong>US City: </strong>}
                {item.label === "locations.region: " && <strong>US Region: </strong>}
                {/* TALENT FINDER */}
                {item.label === "agency: " && <strong>Agency: </strong>}
                {item.label === "tags: " && <strong>Tag: </strong>}
                {item.label.includes('funding_Amount') &&
                  <span>
                    <strong>($) </strong>
                    {item.label.replace('funding_Amount', 'Award Amount')}
                  </span>
                }
                {item.label.includes('base_Hourly_Rate') &&
                  <span>
                    <strong>($) </strong>
                    {item.label.replace('base_Hourly_Rate', 'Hourly Rate')}
                  </span>
                }
                {/* PROJECT FINDER */}
                {/* {item.label == "focus_Tags.industry_Focus: " && <strong>Focus: </strong>}
                  {item.label == "service_Tags.industry_Service: " && <strong>Service: </strong>} */}
                {nested.label}
              </a>
            ))}
            {!item.items &&
              <a
                href={createURL(item.value)}
                onClick={event => {
                  event.preventDefault();
                  refine(item.value);
                }}
              >
                {/* TALENT FINDER */}
                {item.label.includes('funding_Amount') &&
                  <span>
                    <strong>($) </strong>
                    {item.label.replace('funding_Amount', 'Award Amount')}
                  </span>
                }
                {item.label.includes('base_Hourly_Rate') &&
                  <span>
                    <strong>($) </strong>
                    {item.label.replace('base_Hourly_Rate', 'Hourly Rate')}
                  </span>
                }
                {/* PROJECT FINDER */}
                {item.label.includes('project_Budget') &&
                  <span>
                    <strong>($) </strong>
                    {item.label.replace('project_Budget', 'Project Budget')}
                  </span>
                }
              </a>
            }
          </div>
        ))}
        {/* Display message if no filters selected */}
        {!to && !from && items.length === 0 &&
          <div>
            <p>No filters selected.</p>
          </div>
        }
      </div>
    );
  }
}

export default connectCurrentRefinements(CustomCurrentRefinements);