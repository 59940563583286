import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Loader } from '../../../../Shared/Loader';
import { Item } from './Item';

export const Cart = (props) => {
    const billingHandler = useSelector(state => state.billingHandler);
    const [loading, setLoading] = useState(true);
    const [mainProduct, setMainProduct] = useState('');
    const [items, setItems] = useState('');

    useEffect(() => {
        if (!mainProduct) { displayMainProduct(); }
        displayItems();
    }, [props.consolidatedProducts, billingHandler.priceIds])

    function displayMainProduct() {
        const selectedPriceIds = billingHandler.priceIds?.selectedPriceIds;
        const selectedRecurringPriceIds = billingHandler.priceIds?.selectedRecurringPriceIds;

        if (!selectedPriceIds && !selectedRecurringPriceIds) { return null; }
        if (!props.consolidatedProducts) { return null; }

        let product_id = "";
        const main_price = selectedPriceIds.filter((price) => {
            return price.product.metadata["group"] == props.group
        });

        if (main_price.length == 0) {
            const main_price_recurring = selectedRecurringPriceIds.filter((price) => {
                return price.product.metadata["group"] == props.group
            });

            if (main_price_recurring.length > 0) {
                product_id = main_price_recurring[0].product.id;
            }
        }
        else {
            product_id = main_price.product[0].id
        }
        setMainProduct(<Item
            prices={props.consolidatedProducts[product_id]}
        />);
    }

    function displayItems() {
        const selectedPriceIds = billingHandler.priceIds?.selectedPriceIds;
        const selectedRecurringPriceIds = billingHandler.priceIds?.selectedRecurringPriceIds;

        if (!selectedPriceIds && !selectedRecurringPriceIds) { return null; }
        if (!props.consolidatedProducts) { return null; }

        const selected = [...selectedPriceIds, ...selectedRecurringPriceIds];
        setItems(selected.map((price) => {
            if (price.product.metadata["group"] !== props.group) {
                return <Item
                    removeable={true}
                    prices={props.consolidatedProducts[price.product.id]}
                />
            }
        }));

        setLoading(false);
    }

    return (
        <div>
            {loading && <Loader />}
            {mainProduct}
            <div class="mb-32">
                {items}
            </div>
        </div>
    )

}