import React from 'react'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InvoiceDetails } from '.';
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import "../../_assets/img/icons/sideNavIcons/css/fontello.css";
import { organizationStripeService, sharedService, stripeService } from '../../_services';

export const Invoices = ({ singleProject, singleProjectId, isMpProject }) => {
  const { user } = useSelector(state => state.setUser);
  const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);

  const [isOpen, setIsOpen] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");

  useEffect(() => {
    const getInvoices = async () => {
      let result;

      //get single invoice
      if (singleProjectId) {
        if (isMpProject) {
          result = await stripeService.getMpInvoice(singleProjectId)
          result = result.filter(invoice => invoice !== null)
          setInvoices(result)
        }
        else {
          result = await stripeService.getSpInvoice(singleProjectId)
          result = result.filter(invoice => invoice !== null)
          setInvoices(result)
        }
      }
      //get all invoices
      else {
        if (service_Provider) {
          result = await stripeService.getInvoicesByServiceProvider(user.id);
          setInvoices(result.service_provider_invoices)
        }
        else {
          result = await stripeService.getInvoicesByClient(user.id);
          setInvoices(result.client_invoices)
        }
      }
    }

    getInvoices();

  }, [service_Provider])

  async function getInvoiceDetails(invoice_id) {

    setSelectedInvoiceId(invoice_id)
    setIsOpen(true);
  }

  // async function getInvoiceUrl(invoice_id) {
  //   var result = await stripeService.getInvoiceUrl(user.id, invoice_id);
  //   if (result) {
  //     var a = document.createElement('a');
  //     a.href = result.url;
  //     a.target = "blank";
  //     a.click();
  //   }
  // }

  async function viewInvoice(invoice_id) {
    let pdf_link = await organizationStripeService.getInvoiceLink(invoice_id);
    window.open(pdf_link, "_blank");
  }

  function setStatus(status) {
    let invoiceStatus = '';
    if (status === "Paid") {
      invoiceStatus = <span style={{ color: '#009E94' }}>Paid</span>
    }
    if (status === "Viewed") {
      invoiceStatus = <span style={{ color: '#D08B04' }}>Viewed</span>
    }
    if (status === "Delivered") {
      invoiceStatus = <span style={{ color: '#0565AA' }}>Delivered</span>
    }
    if (status === "Past_Due") {
      invoiceStatus = <span style={{ color: 'red' }}>Past Due</span>
    }
    if (status === "Refunded") {
      invoiceStatus = <span style={{ color: 'red' }}>Refunded</span>
    }

    return invoiceStatus;
  }

  function displayInvoices() {
    return (invoices && invoices.map((invoice) => {
      return (
        <>
          {/* DESKTOP */}

          <div className="row hoursInvoicesRow">
            {singleProject ?
              <div className="col-md-4"><p>{singleProject.client_Organization.name}</p></div>
              :
              <div className="col-md-4"><p>{invoice.client ? invoice.client : invoice.service_Provider}</p></div>
            }
            <div className="col-md-2"><p>{sharedService.trimDate(invoice.date_Created)}</p></div>
            <div className="col-md-2"><p>{sharedService.trimDate(invoice.scheduled_Billing_Date)}</p></div>
            <div className="col-md-2"><p>${invoice.total_Amount.toFixed(2)} USD</p></div>
            <div className="col-md-2"><p>{setStatus(invoice.status)}</p></div>
            <div className="col-md-1"><p className="hoursInvoicesActionButton" onClick={() => getInvoiceDetails(invoice.invoice_Id)}><i className='icon-folder-open' /></p></div>
            <div className="col-md-1"><p className="hoursInvoicesActionButton" onClick={() => viewInvoice(invoice.invoice_Id)}><i className='icon-download-alt' /></p></div>
          </div>

          {/* MOBILE */}

          <div className="hoursInvoicesRowMobile">
            <div className="leftColumn"><p>{service_Provider ? "Client:" : "Service Provider:"}</p></div>
            {singleProject ?
              <div className="rightColumn"><p>{singleProject.client_Organization.name}</p></div>
              :
              <div className="rightColumn"><p>{invoice.client ? invoice.client : invoice.service_Provider}</p></div>
            }
          </div>
          <div className="hoursInvoicesRowMobile">
            <div className="leftColumn"><p>Date Created:</p></div>
            <div className="rightColumn"><p>{sharedService.trimDate(invoice.date_Created)}</p></div>
          </div>
          <div className="hoursInvoicesRowMobile">
            <div className="leftColumn"><p>Scheduled Billing:</p></div>
            <div className="rightColumn"><p>{sharedService.trimDate(invoice.scheduled_Billing_Date)}</p></div>
          </div>
          <div className="hoursInvoicesRowMobile">
            <div className="leftColumn"><p>Total Amount:</p></div>
            <div className="rightColumn"><p>${invoice.total_Amount.toFixed(2)} USD</p></div>
          </div>
          <div className="hoursInvoicesRowMobile">
            <div className="leftColumn"><p>Status:</p></div>
            <div className="rightColumn"><p>{setStatus(invoice.status)}</p></div>
          </div>
          <div className="hoursInvoicesRowMobile" style={{ marginTop: 20 }}>
            <div className="leftColumn">
              <p className="hoursInvoicesActionButton" onClick={() => getInvoiceDetails(invoice.invoice_Id)}>
                <i className='icon-folder-open' /> View Invoice
              </p>
            </div>
            <div className="rightColumn">
              <p className="hoursInvoicesActionButton" onClick={() => viewInvoice(invoice.invoice_Id)}>
                <i className='icon-download-alt' /> Download Invoice
              </p>
            </div>
          </div>

          <hr className='hoursInvoicesHr' />

          {(selectedInvoiceId === invoice.invoice_Id) &&
            <InvoiceDetails
              invoice={invoice}
              isOpen={isOpen}
              closeModal={() => { setIsOpen(false) }}
            />
          }
        </>
      )
    }))
  }

  return (
    <div>

      {/* DESKTOP */}
      {invoices && invoices.length !== 0 ?
        <>
          <div className="row hoursInvoicesHeader">
            <div className="col-md-4"><p>{service_Provider ? "Client" : "Service Provider"}</p></div>
            <div className="col-md-2"><p>Date Created</p></div>
            <div className="col-md-2"><p>Scheduled Billing</p></div>
            <div className="col-md-2"><p>Total Amount</p></div>
            <div className="col-md-2"><p>Status</p></div>
            <div className="col-md-1" />
            <div className="col-md-1" />
          </div>
          {displayInvoices()}
        </>
        :
        <p style={{ textAlign: "center", marginTop: 50, fontFamily: "Lato", fontSize: 18 }}>You don't have any invoices.</p>
      }
    </div>
  );
}