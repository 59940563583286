import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const settingsService = {
    updateProfile,
    updateFocus,
    updateOrganization,
    updateServiceProvider,
    updateBilling,
    updatePassword,
    getAllCategories,
    getAllLocations,
    updateEmail,
    checkProfileComplete,
    checkOrganizationComplete,
    updateAvailability,
    getNotificationSettings,
    updateNotificationSettings
};

function getAllCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_categories', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_locations', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

async function checkProfileComplete(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/settings/check_profile_complete', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

async function checkOrganizationComplete(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/settings/check_organization_complete', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

async function updateProfile(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch(API_Route + '/api/settings/update_client_profile', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

async function updateFocus(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch(API_Route + '/api/settings/update_client_focus', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

async function updateOrganization(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch(API_Route + '/api/settings/update_client_organization', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

async function updateServiceProvider(updateServiceProvider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateServiceProvider)
    };

    return fetch(API_Route + '/api/serviceprovider/update_service_provider', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

async function updateBilling(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch(API_Route + '/api/settings/billing_profile', requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

async function updatePassword(oldpassword, newpassword, user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ oldpassword, newpassword })
    };

    return fetch(API_Route + '/api/manage/password_profile?user_id=' + user.id, requestOptions)
        .then(sharedService.handleResponse)
    // .then(user => {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('user', JSON.stringify(user));

    //     return user;
    // });
}

async function updateEmail(user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(email)
    };

    return fetch(API_Route + '/api/manage/update_email?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
}

async function updateAvailability(user_id, currently_available) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/update_service_provider_availability?user_id=' + user_id + '&currently_available=' + currently_available, requestOptions)
        .then(sharedService.handleResponse)
}

async function getNotificationSettings(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/notification/get_notification_settings?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
}

async function updateNotificationSettings(notifications) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(notifications)
    };

    return fetch(API_Route + '/api/notification/update_notification_settings', requestOptions)
        .then(sharedService.handleResponse)
}