import React from 'react';
import { InsertDriveFileOutlined, LinkOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export function FileUpload({
    segment, handleEditAttachment, clearAllTags, project, generalAttachments, removeSubtitle, setUploadErrorMessage
}) {
    const [files, setFiles] = useState([]);
    const [link, setLink] = useState("");
    const [links, setLinks] = useState([]);
    const [segmentCopy, setSegmentCopy] = useState("")
    const [projectCopy, setProjectCopy] = useState("")
    const [addLink, setAddLink] = useState(false)
    const [uploadError, setUploadError] = useState(false);
    const [filesNotUploaded, setFilesNotUploaded] = useState([]);
    const [fileSizeError, setFileSizeError] = useState(false)

    let oldFilePreview;
    let oldLinkPreview;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv, .pdf',
        onDrop: acceptedFiles => {

            //go through uploaded files and check for duplicates
            let newFiles = acceptedFiles.map((acceptedFile) => {

                let counter = 0; //keeps track of duplicate file numbering
                let formattedName = acceptedFile.name.replace(/[^a-zA-Z0-9_./-]+/g, "_");

                //check if file is already uploaded
                files.forEach((uploadedFile) => {
                    if ((formattedName === uploadedFile.name) || uploadedFile.name.startsWith(formattedName.slice(0, formattedName.indexOf('.')))) {
                        counter++
                    }
                })

                //check if file is already existing in db (only for edit)
                if (projectCopy) {

                    let projectDocs = projectCopy.applications ? projectCopy.documents : projectCopy.document_Links;

                    projectDocs.forEach((existingFile) => {

                        if ((formattedName === existingFile.document_Name || acceptedFile.name === existingFile.document_Name) ||
                            existingFile.document_Name.startsWith(formattedName.slice(0, formattedName.indexOf('.')))
                        ) {
                            counter++
                        }
                    })
                }

                let acceptedFileCopy;
                if (counter !== 0) {
                    formattedName = formattedName.split(".")
                    formattedName = `${formattedName[0]}_${counter}.${formattedName[1]}`
                }
                acceptedFileCopy = copyFile(acceptedFile, formattedName)

                //if file doesn't exist yet and is not uploaded, add it
                return Object.assign(acceptedFileCopy, { preview: URL.createObjectURL(acceptedFileCopy) })

            })

            //remove the null values returned from map
            newFiles = newFiles.filter((file) => {
                if (file === null) {
                    //show files that were not uploaded
                    setUploadError(true)
                    setTimeout(() => {
                        setUploadError(false)
                        setFilesNotUploaded([])
                    }, 6000);
                }
                return file !== null
            })

            //update all uploaded files
            if (newFiles.length !== 0) {
                setFiles(files => [...files, ...newFiles])
            }
        }
    });

    function copyFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
            path: originalFile.path,
            preview: originalFile.preview
        });
    }

    //Turn file into base 64
    const docUpload = async (file) => {
        return await getBase64(file);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    //truncate attachment name for display
    const truncate = (text) => {
        return text.length > 28 ? text.substring(0, 25) + "..." : text;
    };

    //displays attachment preview 
    const displayTag = (attachmentStatus, attachmentType, attachment, attachmentName) => {
        return (
            <div key={attachmentName} className="attachmentTagBox">
                <div className="attachmentTagContent">
                    {/* Icon */}
                    {attachmentType === "file" ? <InsertDriveFileOutlined className="attachmentTagIcon" /> : <LinkOutlined className="attachmentTagIcon link" />}

                    {/* Attachment Name */}
                    <p className="attachmentTagName"> {truncate(attachmentName)} </p>

                    {/* Delete Button */}
                    <button className="attachmentTagX" onClick={() => { attachmentStatus === "new" ? removeNewAttachment(attachment) : removeOldAttachment(attachment) }}> X </button>
                </div>
            </div>
        )
    }

    //generate uploaded file tags (preview names)
    const newFilePreview = files.map(file => (
        displayTag("new", "file", file, file.name)
    ));

    //generate uploaded link tags (preview links)
    const newLinkPreview = links.map(link => (
        displayTag("new", "link", link, link.document_Link)
    ))

    //if segment exists (editing)
    if (segmentCopy) {

        //generate existing file tags (preview names)
        let formattedOldFilePreview = segmentCopy.document_Links.filter(file => file && file.document_Name !== "");

        oldFilePreview = formattedOldFilePreview.map(file => {
            return (displayTag("old", "file", file, file.document_Name))
        });


        let formattedOldLinkPreview = segmentCopy.document_Links.filter(link => link && link.document_Link !== "");

        //generate existing file tags (preview names)
        oldLinkPreview = formattedOldLinkPreview.map(link => {
            return (displayTag("old", "link", link, link.document_Link))
        });
    }

    //remove attachment when creating segment
    const removeNewAttachment = (attachment) => {

        let array = attachment.name ? [...files] : [...links];
        let indexToRemove = array.indexOf(attachment)
        array.splice(indexToRemove, 1);

        attachment.name ? setFiles(array) : setLinks(array);

    }

    //remove attachment from existing segments
    const removeOldAttachment = (attachment) => {

        let array = segmentCopy
        let indexToRemove = array.document_Links.indexOf(attachment)
        array.document_Links.splice(indexToRemove, 1);

        setSegmentCopy(array)

        let segment_id = segmentCopy.segment_Id;
        let project_id = segmentCopy.project_Id;
        let document_id = attachment.document_Id
        let document_file = null;

        if (attachment.document_Link) {
            let document_link = attachment.document_Link;
            let document_name = "";

            let linkToDelete = { segment_id, document_link, document_name, document_file, project_id, document_id }

            handleEditAttachment(linkToDelete, "linksToDelete")
        }
        if (attachment.document_Name) {
            let document_link = "";
            let document_name = attachment.document_Name;

            let fileToDelete = { segment_id, document_link, document_name, document_file, project_id, document_id }

            handleEditAttachment(fileToDelete, "filesToDelete")
        }
    }

    //update link input on change
    const handleChange = (e) => {
        setLink(e.target.value);
    }

    //Onclick for adding links to segment
    const uploadLink = () => {

        //hide the input field
        setAddLink(!addLink)

        let canAdd = true;

        //remove space from the beginning and end of the string
        let sanitizedLink = link.trim();

        //check if link is already uploaded
        links.forEach((uploadedLink) => {
            if (sanitizedLink === uploadedLink.document_Link) {
                canAdd = false;
            }
        })

        //check if link is already existing in db (only for edit)
        if (segmentCopy) {
            segmentCopy.document_Links.forEach((existingFile) => {
                if (sanitizedLink === existingFile.document_Link) {
                    canAdd = false;
                }
            })
        }

        //if link doesn't exist yet and is not uploaded, add it 
        if (canAdd) {

            if (segmentCopy) {
                handleEditAttachment({ segment_id: segmentCopy.segment_Id, document_link: sanitizedLink }, "linksToAdd")
            }

            setLinks(links => [...links, { document_Link: sanitizedLink }])
        }
        else {
            setUploadError(true)
            setTimeout(() => {
                setUploadError(false)
            }, 6000);
        }
    }

    useEffect(() => {
        if (setUploadErrorMessage && (files.length === 0 && links.length === 0)) {
            setUploadErrorMessage(true);
        }

    }, [files, links])

    //add uploaded links to local storage
    useEffect(() => {

        //remove text from input field
        setLink("")

        if (segmentCopy) {

            let i = 0;
            let document_links = [];

            if (links.length === 0) {
                handleEditAttachment([], "linksToAdd");
            }

            else {
                links.forEach((link) => {

                    let document_link = link.document_Link;
                    let document_name = "";
                    let segment_id = segmentCopy.segment_Id;
                    let document_file = null;
                    let project_Id = segmentCopy.project_Id;

                    document_links.push({ segment_id, document_name, document_file, document_link, project_Id });

                    //wait for all files to be added to the document_files array before setting local storage
                    i += 1;
                    if (i === links.length) {
                        handleEditAttachment(document_links, "linksToAdd");
                    }
                })
            }
        }
        else {
            if (setUploadErrorMessage && links.length !== 0) {
                setUploadErrorMessage(false);
            }
            localStorage.setItem('document_links', JSON.stringify(links));
        }

    }, [links])

    //add uploaded files to local storage
    useEffect(() => {

        let i = 0;
        let document_files = [];

        files.forEach(async (file) => {
            let document_file = await docUpload(file, i);
            let document_name = file.name;

            if (segmentCopy) {
                let segment_id = segmentCopy.segment_Id;
                let document_link = "";
                let project_Id = segmentCopy.project_Id;

                document_files.push({ segment_id, document_name, document_file, document_link, project_Id });
            }
            else {
                document_files.push({ document_name, document_file });
            }

            //Wait for all files to be added to the document_files array before setting local storage
            i += 1;
            if (i === files.length) {
                if (segmentCopy) {
                    handleEditAttachment(document_files, "filesToAdd");
                }
                else {
                    if (setUploadErrorMessage) {
                        setUploadErrorMessage(false);
                    }
                    try {
                        localStorage.setItem('document_uploads', JSON.stringify(document_files));
                    }
                    catch {
                        let tempFiles = files;
                        tempFiles.splice(-1)

                        setFiles(tempFiles)

                        setFileSizeError(true)

                        setTimeout(() => {
                            setFileSizeError(false)
                        }, 3000);

                    }
                }
            }
        })

        if (segmentCopy && files.length === 0) {
            handleEditAttachment(document_files, "filesToAdd");
        }

        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));

    }, [files]);

    useEffect(() => {
        setFiles([])
        setLinks([])
    }, [clearAllTags])

    //Initial mount
    useEffect(() => {

        //save copy of the project for duplicate attachment check
        if (project) {
            let projectCopyObj = JSON.parse(JSON.stringify(project))
            setProjectCopy(projectCopyObj)
        }

        //save copy of the segment for edit for invoices/hours page
        if (segment && Object.keys(segment).length !== 0) {

            let segmentCopyObj = JSON.parse(JSON.stringify(segment))

            let projectDocs = project.applications ? project.documents : project.document_Links;

            segmentCopyObj.document_Links = projectDocs.map((doc) => {
                if (doc.segment_Id === segmentCopyObj.segment_Id) {
                    return doc
                }
                else {
                    return null
                }
            }).filter(doc => doc !== null)

            setSegmentCopy(segmentCopyObj)
        }
        //save copy of the project for edit general attachments
        else if (generalAttachments) {
            let docTabCopy = JSON.parse(JSON.stringify(project))

            let projectDocs = project.applications ? project.documents : project.document_Links;

            docTabCopy.document_Links = projectDocs.map((doc) => {
                if (doc.segment_Id === 0) {
                    return doc
                }
                else {
                    return null
                }
            }).filter(doc => doc !== null)

            setSegmentCopy(docTabCopy)
        }

        //remove files from local storage on refresh
        localStorage.removeItem("document_uploads");
        localStorage.removeItem("document_links");
    }, [])

    //check if there are attachments
    let showAttachments = ((segmentCopy && segmentCopy.document_Links.length !== 0) || (files && files.length !== 0) || (links && links.length !== 0));

    return (
        <div className="attachmentMainContainer">

            {/* Attachment Preview */}

            {(!removeSubtitle && showAttachments) &&
                <p className="attachmentTagContainerTitle">Attached Documents</p>
            }

            <div className="attachmentTagContainer">
                {oldFilePreview}
                {newFilePreview}
                {oldLinkPreview}
                {newLinkPreview}
            </div>

            {/* File Upload Error */}
            {uploadError && (filesNotUploaded.length !== 0) &&
                <p className="defaultErrorMessage">
                    {filesNotUploaded.map((file, idx) => (
                        <span>
                            {file}{((filesNotUploaded.length !== 1) && ((filesNotUploaded.length - 1) !== idx)) ? "," : ""}
                            &nbsp;
                        </span>
                    ))}
                    {filesNotUploaded.length === 1 ? "is" : "are"} already uploaded.
                </p>
            }
            {/* Link Upload Error */}
            {uploadError && (filesNotUploaded.length === 0) &&
                <p className="defaultErrorMessage">Link is already uploaded.</p>
            }
            {fileSizeError &&
                <p className="defaultErrorMessage">The file is too large.</p>
            }

            {/* Add Attachment Buttons */}
            <div className="attachmentButtonsContainer">
                <button {...getRootProps({ className: 'dropzone hoursEditAttachmentButton' })}>
                    <input {...getInputProps()} />
                    {isDragActive ? "+ Drop files here" : '+ Add a file'}
                </button>

                <button
                    className="hoursEditAttachmentButton"
                    onClick={() => { setAddLink(!addLink) }}
                >
                    + Add a link
                </button>
            </div>

            {/* Link Input Field */}

            {addLink &&
                <div className="attachmentInputContainer">
                    <input
                        className="email-text-field font-input-default attachmentInputField"
                        type="text"
                        value={link}
                        placeholder="Enter your link here..."
                        onChange={(e) => { handleChange(e) }}
                        style={{ fontSize: 14 }}
                    />
                    <button
                        className='attachmentInputButton'
                        onClick={() => { uploadLink() }}
                        style={{ fontSize: 14 }}
                    >
                        Add
                    </button>
                </div>
            }
        </div >
    );
}