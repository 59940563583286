import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/Login.css';
import '../../../../Styles/Marketplace.css';
import { EditAutoSuggestion } from './EditAutoSuggestion';

class EditExpertise extends React.Component {

    componentDidMount() {
        this.checkIfChecked()
    }

    checkIfChecked = () => {
        if (this.props.service_Provider.grant_Types) {
            let grants = this.props.service_Provider.grant_Types
            grants.map((grant) => {
                this.props.handleDefaultCheckbox(grant.grant_Type, "grants")
            })
        }
    }

    render() {
        const {
            submitted, foundation_Expertise_List_Cat, agency_Expertise_List_Cat, program_Expertise_List_Cat, checkboxes, handleCheckbox,
            handleEditChange, stringFoundationTypes, stringAgencyTypes, stringProgramTypes, editErrors, editTextInput
        } = this.props;

        return (
            <div id="GW_Onboarding_Tags" >
                {checkboxes && checkboxes.roles.grant_Writer == true &&
                    <div>
                        <div>
                            <h4 className="inputFieldLabel">Select a Grant Type <span style={{ color: "red" }}>*</span></h4>
                            {submitted && editErrors.emptyGrantType === true &&
                                <p className="errorMessage">You must select a grant type.</p>
                            }
                            <p className="inputFieldSubLabel">
                                Do You Typically Work With Foundation or Government Grants?
                            </p>
                            <div>
                                <FormGroup style={{ display: "block" }}>
                                    <div style={{ paddingLeft: 15 }} className="SP_Checkboxes">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={checkboxes.grants.foundation_Grants}
                                                    onChange={(e) => handleCheckbox(e, "grants")}
                                                    name="service_Provider_GW_GrantType_Tags"
                                                    id="foundation_Grants"
                                                />}
                                            label={<Typography style={{ backgroundColor: "#CFDFFF", display: "inline-table" }}>Foundation Grants</Typography>}
                                            style={{ display: "block" }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={checkboxes.grants.government_Grants}
                                                    onChange={(e) => handleCheckbox(e, "grants")}
                                                    name="service_Provider_GW_GrantType_Tags"
                                                    id="government_Grants"
                                                />}
                                            label={<Typography style={{ backgroundColor: "#C2F5E9", display: "inline-table" }}>Government Grants</Typography>}
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <br />
                        {checkboxes && checkboxes.grants.foundation_Grants == true &&
                            <div>
                                <div>
                                    <h4 className="inputFieldLabel">Foundation Expertise</h4>
                                    <p className="inputFieldSubLabel">Please select the foundation(s) you work with regularly.</p>
                                    <EditAutoSuggestion
                                        categories={foundation_Expertise_List_Cat}
                                        categoryName="GW_Foundation_Tags"
                                        stateName="stringFoundationTypes"
                                        currentSelectedCategories={stringFoundationTypes} //pass in the array of strings - stringFoundationTypes
                                        multiple={true}
                                        addTags={true}
                                        submitted={this.props.submitted}
                                        errorInput={this.props.category_TagsError}
                                        handleCategorySelection={this.props.handleCategorySelection}
                                        handleCategoryInput={this.props.handleCategoryInput} />
                                </div>
                                <br />
                                <div>
                                    <h4 className="inputFieldLabel">Additional Foundation Expertise</h4>
                                    <p className="inputFieldSubLabel">
                                        If not listed above, please note the foundations and/or programs you work with here.
                                    </p>
                                    <textarea
                                        required
                                        id="service-provider-standard-basic"
                                        name="Foundational_Expertise_Description"
                                        value={editTextInput.Foundational_Expertise_Description}
                                        onChange={(e) => handleEditChange(e)}
                                        multiline
                                        rowsMax="20"
                                        rows={5}
                                        className="inputField"
                                    />
                                </div>
                                <br />
                            </div>
                        }
                        {checkboxes && checkboxes.grants.government_Grants == true &&
                            <div>

                                <div>
                                    <h4 className="inputFieldLabel">Government Agency Expertise</h4>
                                    <p className="inputFieldSubLabel">
                                        Please select the agencies you work with regularly.
                                    </p>
                                    <EditAutoSuggestion
                                        categories={agency_Expertise_List_Cat}
                                        categoryName="GW_Agency_Tags"
                                        stateName="stringAgencyTypes"
                                        currentSelectedCategories={stringAgencyTypes}
                                        multiple={true}
                                        addTags={true}
                                        submitted={this.props.submitted}
                                        errorInput={this.props.category_TagsError}
                                        handleCategorySelection={this.props.handleCategorySelection}
                                        className="tag"
                                        handleCategoryInput={this.props.handleCategoryInput} />
                                </div>

                                <div>
                                    <h4 className="inputFieldLabel">Agencies You Work With</h4>
                                    <p className="inputFieldSubLabel">
                                        If any agencies you work with were not listed above, please write them in here.
                                    </p>
                                    <textarea
                                        required
                                        id="service-provider-standard-basic"
                                        name="Agency_Expertise_Description"
                                        value={editTextInput.Agency_Expertise_Description}
                                        onChange={(e) => handleEditChange(e)}
                                        multiline
                                        rowsMax="20"
                                        rows={5}
                                        className="inputField boxText"
                                    />
                                </div>

                                <div>
                                    <h4 className="inputFieldLabel">Government Program or Office Expertise</h4>
                                    <p className="inputFieldSubLabel">
                                        Please select the agency-specific programs or offices you work with regularly.
                                    </p>
                                    <EditAutoSuggestion
                                        categories={program_Expertise_List_Cat}
                                        categoryName="GW_Program_Tags"
                                        stateName="stringProgramTypes"
                                        currentSelectedCategories={stringProgramTypes}
                                        multiple={true}
                                        addTags={true}
                                        submitted={this.props.submitted}
                                        errorInput={this.props.category_TagsError}
                                        handleCategorySelection={this.props.handleCategorySelection}
                                        handleCategoryInput={this.props.handleCategoryInput} />
                                </div>

                                <div>
                                    <h4 className="inputFieldLabel">Specific Funding Programs or Offices You Work With</h4>
                                    <p className="inputFieldSubLabel">
                                        If any programs or offices you work with were not listed above, please write them in here.
                                    </p>
                                    <textarea
                                        required
                                        id="service-provider-standard-basic"
                                        name="Program_Expertise_Description"
                                        value={editTextInput.Program_Expertise_Description}
                                        onChange={(e) => handleEditChange(e)}
                                        multiline
                                        rowsMax="20"
                                        rows={5}
                                        className="inputField boxText"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return {
        user,
    };
}

const connectedEditExpertise = connect(mapStateToProps)(EditExpertise);
export { connectedEditExpertise as EditExpertise };

