import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const notificationsService = {
    getAllNotifications,
    markAllNotificationsSeen,
    markNotificationAsRead,

    get_email_notification_settings,
    update_email_notification_settings
};

function getAllNotifications(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/notification/get_all_notifications?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function markAllNotificationsSeen(userId, unseenIds) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ "UnseenIds": unseenIds })
    };

    return fetch(API_Route + '/api/notification/mark_all_notifications_seen?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function markNotificationAsRead(userId, notificationId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/notification/mark_notification_as_read?user_id=' + userId + '&notificationId=' + notificationId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function get_email_notification_settings(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/notification/get_email_notification_settings?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function update_email_notification_settings(user_updated_notifications) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user_updated_notifications)
    };

    return fetch(API_Route + '/api/notification/update_email_notification_settings', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}