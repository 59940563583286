import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const chatService = {
    create_conversation,
    delete_conversation,
    add_participants,
    generate_token,

    updateChatInfo,
    exportMessages,
    exportAllMessages,

    send_email_notification_about_dm
};

function create_conversation(conversation_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(conversation_name)
    };

    return fetch(API_Route + '/api/chat/create-conversation', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function delete_conversation(channel) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(channel)
    };

    return fetch(API_Route + '/api/chat/delete-conversation', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function add_participants(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/chat/add-participants', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function generate_token(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/generate-token?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function send_email_notification_about_dm(from_user_id, to_username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/send_email_notification_about_dm?from_user_id=' + from_user_id + '&to_username=' + to_username, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateChatInfo(messageInfo) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(messageInfo)
    };

    return fetch(API_Route + '/api/chat/update_chat_info', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function exportMessages(channel_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/export_messages?channel_id=' + channel_id, requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function exportAllMessages(file_type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/export_all_messages?file_type=' + file_type, requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}