import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { settingsService, authenticateService } from '../../_services';
import { Loader } from '../Shared/Loader';
import PhoneNumberModal from "./PhoneNumberModal";

class NotificationSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: {},
            initialNotificationChannel: "",
            selectedNotificationChannel: "",
            selectedNotificationFrequency: "",
            isOpen: false,
            loading: false,
            displayDeleteConfirmation: false
        }
    }

    //Get existing notification settings
    componentDidMount = async () => {

        const { user } = this.props

        const notifications = await settingsService.getNotificationSettings(user.id);

        let selectedNotificationChannel;

        if (notifications.newMessage_Email && notifications.newMessage_SMS) {
            selectedNotificationChannel = "both";
        }
        // else if (!notifications.newMessage_Email && !notifications.newMessage_SMS) {
        //     selectedNotificationChannel = "none";
        // }
        else if (notifications.newMessage_Email) {
            selectedNotificationChannel = "email";
        }
        else {
            selectedNotificationChannel = "sms";
        }

        this.setState({
            notifications,
            selectedNotificationChannel,
            initialNotificationChannel: selectedNotificationChannel,
            selectedNotificationFrequency: notifications.frequency ? notifications.frequency : "daily",
        });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.submitNotifications !== prevProps.submitNotifications) {
            this.handleNotificationSubmit();
        }
    }

    //Update notification settings
    handleNotificationSwitch = (e) => {
        const { value, name } = e.target;
        const { user, onboarding } = this.props

        this.setState({
            [name]: value
        }, () => {

            //if selected SMS or both and don't have a phone number
            if ((value === "sms" || value === "both") && (!user.phoneNumber || user.phoneNumber === "")) {

                //open modal in account page
                if (!onboarding) {
                    this.togglePhoneNumberModal();
                }

                //make phone number required for GS
                else if (this.props.handlePhoneRequirement) {
                    this.props.handlePhoneRequirement(true);
                }
            }

            //for GS onboarding, make phone number not required if email is selected
            if (this.props.handlePhoneRequirement && (value === "email")) {
                this.props.handlePhoneRequirement(false);
            }

            //for Account page, submit update on selection
            else if (!onboarding) {
                this.handleNotificationSubmit();
            }
        });

    }

    //go back to initial notification channel selection
    cancelNotificationSwitch = (e) => {
        this.setState({
            selectedNotificationChannel: this.state.initialNotificationChannel
        }, () => {
            this.handleNotificationSubmit()
        })
    }

    //Submit notification preferences
    handleNotificationSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        const { selectedNotificationChannel, selectedNotificationFrequency } = this.state
        const { user } = this.props

        let user_notifications = {};

        this.setState({
            loading: true,
        })

        //set user_notifications depending on the selected radio button value

        if (selectedNotificationChannel === "both") {
            user_notifications = {
                newMessage_Email: true,
                newMessage_SMS: true
            }
        }
        // else if (selectedNotificationChannel === "none") {
        //     user_notifications = {
        //         newMessage_Email: false,
        //         newMessage_SMS: false
        //     }
        // }
        else if (selectedNotificationChannel === "email") {
            user_notifications = {
                newMessage_Email: true,
                newMessage_SMS: false
            }
        }
        else {
            user_notifications = {
                newMessage_Email: false,
                newMessage_SMS: true
            }
        }

        user_notifications["frequency"] = selectedNotificationFrequency;
        user_notifications["user_Id"] = user.id;

        //update notification preferences
        await settingsService.updateNotificationSettings(user_notifications);

        this.setState({
            loading: false,
        })

    }

    //Open or close phone number form modal
    togglePhoneNumberModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const { notifications, selectedNotificationChannel, selectedNotificationFrequency, isOpen, loading } = this.state
        const { onboarding } = this.props

        return (
            <div>
                {!onboarding && loading &&
                    <Loader />
                }
                {notifications &&
                    <div>

                        {/* HEADER */}

                        {onboarding ?
                            <hr />
                            :
                            <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                <div className="col-md-12" style={{ padding: 0 }}>
                                    <p className="acceptingProjectHeader mobileFloatHeader">Notification Preferences</p>
                                </div>
                            </div>
                        }

                        {/* CHECKBOXES */}

                        <form style={{ marginLeft: 30 }}>

                            {/* NOTIFICATION CHANNEL */}

                            <p className="gc_onboarding-form-input-name" style={{ marginTop: 30 }}>
                                How would you like to be notified when you receive messages on OpenGrants?
                            </p>

                            <div>
                                <input id="newMessage_Email" name="selectedNotificationChannel" value="email" checked={selectedNotificationChannel === "email"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                <label for="newMessage_Email" style={{ display: "inline-block" }}>Email</label>
                            </div>
                            <div>
                                <input id="newMessage_SMS" name="selectedNotificationChannel" value="sms" checked={selectedNotificationChannel === "sms"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                <label for="newMessage_SMS" style={{ display: "inline-block" }}>SMS*</label>
                            </div>
                            <div>
                                <input id="newMessage_Both" name="selectedNotificationChannel" value="both" checked={selectedNotificationChannel === "both"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                <label for="newMessage_Both" style={{ display: "inline-block" }}>Both</label>
                            </div>
                            {/* {userType === "Grant_Seeker" &&
                                <div>
                                    <input id="newMessage_None" name="selectedNotificationChannel" value="none" checked={selectedNotificationChannel === "none"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                    <label for="newMessage_None" style={{ display: "inline-block" }}>None</label>
                                </div>
                            } */}

                            <p className="gc_onboarding-form-input-name-top-secondary" style={{ marginTop: 5 }}>
                                *Standard message and data rates apply
                            </p>

                            {/* NOTIFICATION FREQUENCY */}

                            <p className="gc_onboarding-form-input-name" style={{ marginTop: 30 }}>
                                How often would you like to be notified when you receive messages on OpenGrants?
                            </p>

                            <div>
                                <input id="newMessage_Freq_Daily" name="selectedNotificationFrequency" value="daily" checked={selectedNotificationFrequency === "daily"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                <label for="newMessage_Freq_Daily" style={{ display: "inline-block" }}>Once per day (5pm PST)</label>
                            </div>
                            <div>
                                <input id="newMessage_Freq_Each" name="selectedNotificationFrequency" value="each" checked={selectedNotificationFrequency === "each"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                <label for="newMessage_Freq_Each" style={{ display: "inline-block" }}>Immediately</label>
                            </div>
                            {/* {userType === "Grant_Seeker" &&
                                <div>
                                    <input id="newMessage_Freq_None" name="selectedNotificationFrequency" value="none" checked={selectedNotificationFrequency === "none"} type="radio" onChange={this.handleNotificationSwitch} style={{ width: "auto" }} />
                                    <label for="newMessage_Freq_None" style={{ display: "inline-block" }}>Never</label>
                                </div>
                            } */}
                        </form>

                        {onboarding &&
                            <hr style={{ marginBottom: 0 }} />
                        }

                        {/* PHONE NUMBER FORM MODAL*/}

                        <PhoneNumberModal
                            isOpen={isOpen}
                            cancelNotificationSwitch={this.cancelNotificationSwitch}
                            togglePhoneNumberModal={this.togglePhoneNumberModal}
                        />
                    </div >
                }
            </div >
        )
    }

}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;

    return {
        user,
    };
}

const connectedNotificationSettings = connect(mapStateToProps)(NotificationSettings);
export { connectedNotificationSettings as NotificationSettings };
