import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const organizationService = {
    getAllOrganizations,
    getAllOrganizationsLite,
    getAllOrganizationsByUser,
    getFirstOrganizationByUser,
    getOrganization,
    createOrganization,
    getIndustries,
    getSocialImpactCategories,
    getTechnologies,
    getSubTechnologies,
    getNAICSCodes,
    getOrganizationMembers,
    getAllOrganizationNames,
    requestToJoinOrganization,
    confirmOrganizationRequest,
    acceptOrganizationRequest,
    rejectOrganizationRequest,
    getAllOrganizationRequests,
    cancelOrganizationRequest,
    deleteOrganization,
    updateOrganizationLogo,
    updateOrganizationOwner,
    getUserOrganizationPendingInvitations,
    getOrganizationInvitation,
    confirmOrganizationInvitation,
    cancelOrganizationInvitation,
    inviteToJoinOrganization,
    checkOrganizationName,
    get,
    create,
    update,
};

function createOrganization(user_id, organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization/create_organization?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function get(extend = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + `/api/organization?extend=${extend}`, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function create(organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function update(organization) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllOrganizations(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_all_organizations?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllOrganizationsLite(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_all_organizations_lite?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllOrganizationsByUser(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_all_organizations_by_user?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getFirstOrganizationByUser(user_id, extend = false) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + `/api/organization/get_first_organization_by_user?user_id=${user_id}&extend=${extend}`, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getOrganization(user_id, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_organization?user_id=' + user_id + '&organization_id=' + organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getIndustries() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_industries', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getSocialImpactCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_social_impact_categories', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getTechnologies(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_technologies?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getSubTechnologies() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/get_sub_technologies', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getNAICSCodes(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/getNAICSCodes?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getOrganizationMembers(user_id, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/getOrganizationMembers?user_id=' + user_id + '&organization_id=' + organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllOrganizationNames() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/organization/getAllOrganizationNames', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function requestToJoinOrganization(user_id, organization_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization_name)
    };

    return fetch(API_Route + '/api/organization/requestToJoinOrganization?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function confirmOrganizationRequest(organization_id, user_id, username, confirmation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/confirmOrganizationRequest?organization_id=' + organization_id + '&user_id=' + user_id + '&username=' + username + '&confirmation=' + confirmation, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function acceptOrganizationRequest(organization_id, user_id, username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/acceptOrganizationRequest?organization_id=' + organization_id + '&user_id=' + user_id + '&username=' + username, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function rejectOrganizationRequest(organization_id, user_id, username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/rejectOrganizationRequest?organization_id=' + organization_id + '&user_id=' + user_id + '&username=' + username, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllOrganizationRequests(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/getAllOrganizationRequests?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteOrganization(user_id, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/deleteOrganization?user_id=' + user_id + '&organization_id=' + organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateOrganizationLogo(organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization/update_organization_logo', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateOrganizationOwner(user_id, username, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/updateOrganizationOwner?user_id=' + user_id + '&username=' + username + '&organization_id=' + organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getUserOrganizationPendingInvitations(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/getUserOrganizationPendingInvitations?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getOrganizationInvitation(organization_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/getOrganizationInvitation?organization_id=' + organization_id + '&user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function confirmOrganizationInvitation(organization_id, user_id, confirmation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/confirmOrganizationInvitation?organization_id=' + organization_id + '&user_id=' + user_id + '&confirmation=' + confirmation, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function cancelOrganizationRequest(organization_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/cancelOrganizationRequest?organization_id=' + organization_id + '&user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function cancelOrganizationInvitation(organization_id, user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/cancelOrganizationInvitation?organization_id=' + organization_id + '&user_id=' + user_id + '&email=' + email, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function inviteToJoinOrganization(organization_id, user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(email)
    };

    return fetch(API_Route + '/api/organization/inviteToJoinOrganization?organization_id=' + organization_id + '&user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function checkOrganizationName(organization_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/organization/checkOrganizationName?organization_name=' + organization_name, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}