import React from "react";

export const GrantNavigator = () => {
  return (<>
    <div id="grant-navigator">
      <iframe
        src="https://www.chatbase.co/chatbot-iframe/ANw0b9AfBc3vVDHf6DOl6"
        style={{ width: '100%', height: '100%', padding: '0px' }}
      ></iframe>
    </div>
  </>)

}