import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { Private_Routes, Public_Routes, Redirect_Routes } from ".";
import { Helmet } from "react-helmet";
import { UserRedirect } from "../_pages/Shared/Landing";
import logo from "../_assets/img/opengrants-logo-small.svg"
import "../Styles/Main_v2.css";
import "../Styles/SettingsStyles/Focus.css"
import "../Styles/SettingsStyles/Settings.css"
import "../Styles/Checkout.css"
import "../Styles/Listing.css"
import "../Styles/Notification.css"
import "../Styles/LoginPartialDropdown.css"
import "../Styles/NavMenu.css"
import "../Styles/Onboarding.css"
import '../scss/style.scss'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: { history },
      platform_Settings: null,
      iFrameData: null,
    };
  }

  async componentDidMount() {
    const query = new URLSearchParams(history.location?.search);
    const iFrameConfig = {
      logo: query.get('logo') || '',
      primary: query.get('primary') ? `#${query.get('primary')}` : '',
      secondary: query.get('secondary') ? `#${query.get('secondary')}` : '',
      user: JSON.parse(query.get('user')) || '',
      public: JSON.parse(query.get('public')) || false,
      url: query.get('logo') ? history.location?.search : '',
    }
    localStorage.setItem("iFrameConfig", JSON.stringify(iFrameConfig));
    this.setState({ iFrameData: iFrameConfig?.url ? iFrameConfig : null });
  }

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  render() {
    const server = window.location.protocol + "//" + window.location.host;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>opengrants.io</title>
          <link
            rel="icon"
            href={logo}
            type="image/x-icon"
          />
          <meta property="og:type" content="website"></meta>
          <meta
            name="og:description"
            content="Effortlessly discover, secure and manage grant funding."
          />
          <meta
            name="description"
            content="Effortlessly discover, secure and manage grant funding."
          />
          <meta property="og:title" content="opengrants.io" />
          <meta property="og:url" content={server} />
          <meta
            property="og:image"
            content="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_graph.jpg"
          />
          <meta name="twitter:title" content="opengrants.io" />
          <meta
            name="twitter:description"
            content="Effortlessly discover, secure and manage grant funding."
          />
          <meta
            name="twitter:image"
            content="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_graph.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Router history={history}>
          <Switch>
            <Route exact path="/" component={UserRedirect} />

            {Public_Routes({ user: this.props.user })}
            {Redirect_Routes({ user: this.props.user })}
            {Private_Routes({ props: this.props })}
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;
  const { stripe_Verified } = state.stripeVerified;

  return {
    user,
    service_Provider,
    stripe_Verified
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
