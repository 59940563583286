import { combineReducers } from 'redux';
import { authenticateConstants } from '../_constants';

import { emailAlert, loginAlert, registrationAlert, settingsAlert, updatePasswordAlert } from './alert.reducer';
import { authenticateUser, confirmEmail, setUser, updatePassword } from './authenticate.reducer';
import { chatalert_setchannel, chatalert_setconversation, chatalert_updateconversations, chat_messageuserrequest } from './chat.reducer';
import { dashboard } from './dashboard.reducer';
import { getFavorites, saveListing } from './favorites.reducer';
import { layout } from './layout.reducer';
import { listing } from './listing.reducer';
// import { updateToS } from './marketplace.reducer';
import { mp_project, mp_projects } from './marketplaceproject.reducer';
import { discardMatch, getMatches, saveMatch } from './matches.reducer';
import { getAllNotifications, get_email_notification_settings, markAllNotificationsSeen, update_email_notification_settings } from './notifications.reducer';
import { organization_createorganizationalert, organization_noorganizationalert, organization_removemessageblock } from './organization.reducer';
import { billingInfo_org, organizationstripe_addbillinginfo } from './organizationstripe.reducer';
import { update_perks } from "./perks.reducer";
import { registration } from './registration.reducer';
import { setExpertsAdvisorsExpanded, setGrantsExpanded } from './resources.reducer';
import { categories, locations, searchResults } from './search.reducer';
import {
    serviceprovider_alert,
    serviceprovider_createproject,
    serviceprovider_createprojectproposal,
    serviceprovider_createworksegment,
    serviceprovider_deleteworksegment,
    serviceprovider_editworksegment,
    serviceprovider_getAllClientProjectsAndProposals,
    serviceprovider_getallclients,
    serviceprovider_getclient,
    serviceprovider_getproject,
    serviceprovider_getprojects,
    serviceprovider_getserviceprovider,
    serviceprovider_updateproject,
    serviceprovider_updateprojectproposal,
    serviceprovider_updateprojectproposalstatus,
    serviceprovider_updateprojectproposalview
} from './serviceprovider.reducer';
import { advanced, focus, organization, profile } from './settings.reducer';
import {
    billingHandler,
    billingInfo,
    cancelSubscription,
    createStripeAccount,
    getBillingAddress,
    handleStripeToken,
    paywallAlert,
    purchaseProduct,
    stripeCheckout,
    stripeVerified,
    updateBilling,
    updateSubscription
} from './stripe.reducer';
import { fullTimerWidget, minimizedTimerWidget } from "./timer.reducer";
import { upgradeModal } from './upgrade.reducer';

const appReducer = combineReducers({
    //alert
    emailAlert,
    loginAlert,
    registrationAlert,
    settingsAlert,
    updatePasswordAlert,
    //authenticate
    authenticateUser,
    confirmEmail,
    setUser,
    updatePassword,
    //chat
    chatalert_setchannel,
    chatalert_setconversation,
    chatalert_updateconversations,
    chat_messageuserrequest,
    //dashboard
    dashboard,
    //favorites
    getFavorites,
    saveListing,
    //layout
    layout,
    //listing
    listing,
    //marketplace
    // updateToS,
    //marketplace project
    mp_project,
    mp_projects,
    //matches
    discardMatch,
    getMatches,
    saveMatch,
    //notifications
    getAllNotifications,
    get_email_notification_settings,
    markAllNotificationsSeen,
    update_email_notification_settings,
    //organization
    organization_createorganizationalert,
    organization_noorganizationalert,
    organization_removemessageblock,
    //organization stripe
    billingInfo_org,
    organizationstripe_addbillinginfo,
    //perks
    update_perks,
    //registration
    registration,
    //resources
    setExpertsAdvisorsExpanded,
    setGrantsExpanded,
    //search
    categories,
    locations,
    searchResults,
    //service provider
    serviceprovider_alert,
    serviceprovider_createproject,
    serviceprovider_createprojectproposal,
    serviceprovider_createworksegment,
    serviceprovider_deleteworksegment,
    serviceprovider_editworksegment,
    serviceprovider_getAllClientProjectsAndProposals,
    serviceprovider_getallclients,
    serviceprovider_getclient,
    serviceprovider_getproject,
    serviceprovider_getprojects,
    serviceprovider_getserviceprovider,
    serviceprovider_updateproject,
    serviceprovider_updateprojectproposal,
    serviceprovider_updateprojectproposalstatus,
    serviceprovider_updateprojectproposalview,
    //settings
    advanced,
    focus,
    organization,
    profile,
    //stripe
    billingHandler,
    billingInfo,
    cancelSubscription,
    createStripeAccount,
    getBillingAddress,
    handleStripeToken,
    paywallAlert,
    purchaseProduct,
    stripeCheckout,
    stripeVerified,
    updateBilling,
    updateSubscription,
    //timer
    fullTimerWidget,
    minimizedTimerWidget,
    //upgrade
    upgradeModal,
});

const rootReducer = (state, action) => {
    if (action.type === authenticateConstants.LOGOUT) {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;
