import React from 'react'
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import tooltipIcon from '../../_assets/img/spProjectTooltipIcon.png';
import { marketplaceProjectObjects } from '../../_components/_objects';
import { marketplaceProjectService, pluginService, sharedService } from '../../_services';
import { FileUpload } from '../Shared/Upload';

//Select field styling
const customStyles = {
  singleValue: (provided) => {
    const fontSize = 15;
    return { ...provided, fontSize };
  },
  control: (provided) => {
    const height = 40;
    return { ...provided, height };
  }
}

export function ApplyModal({
  isOpen,
  project,
  toggleApplyModal,
  toggleCongratsModal,
  applicationSuccess
}) {
  const [mpProjectApplication, setMpProjectApplication] = useState(Object.assign({}, { ...marketplaceProjectObjects.mp_project_application, project_Id: project.project_Id }));
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedRate, setSelectedRate] = useState("");
  const [uploadError, setUploadError] = useState(true);

  let accentColor = pluginService.getIFrameColor()

  //handle application form input
  function handleInput(e) {
    const { name, value } = e.target;

    let totalCost;

    //had hours, added hourly rate
    if ((name === "hourly_Rate") && mpProjectApplication.total_Hours) {
      totalCost = value * mpProjectApplication.total_Hours
    }

    //had hourly rate, added hours
    if ((name === "total_Hours") && mpProjectApplication.hourly_Rate) {
      totalCost = value * mpProjectApplication.hourly_Rate
    }

    //added flat rate
    if (name === "flat_Rate") {
      totalCost = value
    }

    setMpProjectApplication(
      {
        ...mpProjectApplication,
        [name]: name !== "additional_Details" ? parseFloat(value) : value,
        total_Cost: totalCost ?
          parseFloat(totalCost)
          : mpProjectApplication.total_Cost ?
            mpProjectApplication.total_Cost
            : 0.0
      }
    )
  }

  //handle application submission
  async function handleSubmitApplication() {

    //get uploaded attachments
    let document_files = localStorage.getItem("document_uploads") ? JSON.parse(localStorage.getItem("document_uploads")) : [];
    let document_links = localStorage.getItem("document_links") ? JSON.parse(localStorage.getItem("document_links")) : [];

    if (document_files && document_files.length !== 0) {
      document_files.forEach((file, idx) => {
        let new_file = Object.assign({}, marketplaceProjectObjects.mp_project_application_document);
        new_file.document_Name = file.document_name;
        new_file.document_File = file.document_file;

        document_files[idx] = new_file;
      });
    }

    if (document_links && document_links.length !== 0) {
      document_links.forEach((link, idx) => {
        let new_link = Object.assign({}, marketplaceProjectObjects.mp_project_application_document);
        new_link.document_Link = link.document_Link;

        document_links[idx] = new_link;
      });
    }

    mpProjectApplication.documents = [...document_files, ...document_links];

    //reset error message
    setErrorMessage('');

    // check if required fields are filled
    if (mpProjectApplication.additional_Details //message entered
      && mpProjectApplication.documents && mpProjectApplication.documents.length !== 0  //documents added
      && (
        ((selectedRate.value === "hourlyRate") && mpProjectApplication.hourly_Rate && mpProjectApplication.total_Hours) //hourly rate and hours entered
        || ((selectedRate.value === "flatRate") && mpProjectApplication.flat_Rate) //flat rate entered
      )
      && mpProjectApplication.total_Cost //total cost calculated
    ) {

      // submit application
      const application = await marketplaceProjectService.create_application(mpProjectApplication);

      //application submission successful
      if (application) {
        //close accept modal
        toggleApplyModal();

        //open congrats modal
        toggleCongratsModal();

        applicationSuccess(true)
      }
      //error submitting application
      else {
        setErrorMessage("We're sorry. There was an error submitting your proposal. " +
          "Please try again. If you still have trouble, open the chat in the " +
          "lower right of this screen to talk with our support team.")
      }
    }

    //flag submission
    setSubmitted(true);
  }

  //Set files/links to be added/deleted on submit
  function handleEditAttachment(attachment, action) {
    if (action === "filesToAdd") {
      this.setState({
        filesToAdd: attachment
      })
    }
    else if (action === "linksToAdd") {
      this.setState({
        linksToAdd: attachment
      })
    }
    else if (action === "filesToDelete") {
      this.setState({
        filesToDelete: [...this.state.filesToDelete, attachment]
      })
    }
    else {
      this.setState({
        linksToDelete: [...this.state.linksToDelete, attachment]
      })
    }
  }

  function handleSelectedRate(e) {

    if (e.value === "hourlyRate") {
      setMpProjectApplication({ ...mpProjectApplication, flat_Rate: 0, total_Cost: 0.0 })
    }
    else {
      setMpProjectApplication({ ...mpProjectApplication, hourly_Rate: 0, total_Hours: 0.0, total_Cost: 0.0 })
    }

    setSelectedRate(e)
  }

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => toggleApplyModal()}
      fade={false}
      className="ModalDiv"
      size='xl'
    >
      <ModalHeader className="row border-0 align-self-end" toggle={() => toggleApplyModal()} />
      <ModalBody>
        <div style={{ padding: '0px 5%' }}>
          <div className="row">
            <h3 style={{ fontWeight: '700' }}>Project Application</h3>
          </div>
          <hr />

          {/* MESSAGE */}
          <p style={{ fontWeight: '700' }}>Your Message<span style={{ color: 'red' }}>*</span></p>
          <p>Include a message with your proposal file.</p>
          <textarea
            name="additional_Details"
            value={mpProjectApplication.additional_Details}
            onChange={handleInput}
            className="apply-modal-textarea"
            style={{ width: '100%', borderRadius: '5px', padding: '10px 10px' }}
          />
          {submitted && !mpProjectApplication.additional_Details &&
            <p className="red-message">Message is required</p>
          }

          <hr style={{ margin: "30px 0" }} />

          {/* RATE SELECTOR */}
          <p style={{ fontWeight: '700' }}>Your Rate<span style={{ color: 'red' }}>*</span></p>
          <Select
            value={selectedRate}
            onChange={(e) => handleSelectedRate(e)}
            options={[{ value: "hourlyRate", label: "Hourly Rate" }, { value: "flatRate", label: "Flat Rate" }]}
            styles={customStyles}
          />
          {submitted && !selectedRate &&
            <p className="red-message" style={{ marginTop: 5 }}>Rate is required</p>
          }

          {/* HOURLY RATE */}
          {selectedRate.value === "hourlyRate" &&
            <div>
              <br />
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ fontWeight: '700' }}>Hourly Rate <span style={{ color: 'red' }}>*</span></p>
                  <img
                    className='projectToolTipIcon'
                    data-tip="Hourly projects require you to log hours in OpenGrants as you work. <br/>Your client is invoiced weekly, and you're paid for that work the following week."
                    data-for='questionIconHourly'
                    src={tooltipIcon}
                    height='20'
                    alt=""
                    style={{ paddingLeft: 10, marginBottom: 10 }}
                  />
                  <ReactTooltip
                    id='questionIconHourly'
                    clickable={true}
                    className='projectTooltip'
                    placement="right"
                    effect="solid"
                    multiline={true}
                    textColor='dark'
                    backgroundColor='white'
                    border={true}
                    borderColor='rgb(128, 128, 128)'
                  />
                </div>
                <p>This will be used to calculate the project cost. </p>
                <Input
                  startAdornment={<InputAdornment position="start" className="inputAdornment" style={{ marginLeft: 10, color: "black !important" }}>$</InputAdornment>}
                  endAdornment={<InputAdornment position="end" className="inputAdornment" style={{ marginRight: 10 }}>(USD) /hr</InputAdornment>}
                  value={mpProjectApplication.hourly_Rate}
                  onChange={handleInput}
                  name="hourly_Rate"
                  type="number"
                  disableUnderline={true}
                  InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  className="apply-modal-input"
                />
                {(!!mpProjectApplication.hourly_Rate && mpProjectApplication.hourly_Rate !== 0) && !sharedService.isInteger(mpProjectApplication.hourly_Rate) &&
                  <p className="red-message" style={{ marginTop: 5 }}>You may only enter positive integers</p>
                }
                {submitted && !mpProjectApplication.hourly_Rate &&
                  <p className="red-message" style={{ marginTop: 5 }}>Hourly Rate is required</p>
                }
              </div>
              <br />

              {/* TOTAL HOURS */}
              <div>
                <p style={{ fontWeight: '700' }}>Total Hours <span style={{ color: 'red' }}>*</span></p>
                <p>If relevant estimate the total hours to complete this project.</p>
                <Input
                  value={mpProjectApplication.total_Hours}
                  onChange={handleInput}
                  name="total_Hours"
                  type="number"
                  disableUnderline={true}
                  InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  className="apply-modal-input"
                />
                {submitted && !mpProjectApplication.total_Hours &&
                  <p className="red-message" style={{ marginTop: 5 }}>Total Hours is required</p>
                }
              </div>
              <br />

              {/* TOTAL COST */}
              <div>
                <p style={{ fontWeight: '700' }}>Total Cost</p>
                <p><strong>$ {mpProjectApplication.total_Cost.toFixed(2)} (USD)</strong></p>
              </div>

            </div>
          }

          {/* FLAT RATE */}
          {selectedRate.value === "flatRate" &&
            <>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontWeight: '700' }}>Flat Rate <span style={{ color: 'red' }}>*</span></p>
                <img
                  className='projectToolTipIcon'
                  data-tip="Flat rate projects are billed immediately and held in escrow. <br/>You get paid when the project is complete."
                  data-for='questionIconFlat'
                  src={tooltipIcon}
                  height='20'
                  alt=""
                  style={{ paddingLeft: 10, marginBottom: 10 }}
                />
                <ReactTooltip
                  id='questionIconFlat'
                  clickable={true}
                  className='projectTooltip'
                  placement="right"
                  effect="solid"
                  multiline={true}
                  textColor='dark'
                  backgroundColor='white'
                  border={true}
                  borderColor='rgb(128, 128, 128)'
                />
              </div>
              <Input
                startAdornment={<InputAdornment position="start" className="inputAdornment" style={{ marginLeft: 10, color: "black !important" }}>$</InputAdornment>}
                endAdornment={<InputAdornment position="end" className="inputAdornment" style={{ marginRight: 10 }}>(USD)</InputAdornment>}
                value={mpProjectApplication.flat_Rate}
                onChange={handleInput}
                name="flat_Rate"
                type="number"
                disableUnderline={true}
                InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                className="apply-modal-input"
              />
              {(!!mpProjectApplication.flat_Rate && mpProjectApplication.flat_Rate !== 0) && !sharedService.isInteger(mpProjectApplication.flat_Rate) &&
                <p className="red-message" style={{ marginTop: 5 }}>You may only enter positive integers</p>
              }
              {submitted && !mpProjectApplication.flat_Rate &&
                <p className="red-message" style={{ marginTop: 5 }}>Flat Rate is required</p>
              }
            </>
          }

          <hr style={{ margin: "30px 0" }} />

          {/* DOCUMENT UPLOAD */}
          <p style={{ fontWeight: '700' }}>Upload Proposal<span style={{ color: 'red' }}>*</span></p>
          <p>Upload a detailed proposal or other supporting documents that  would appreciate when reviewing your application.</p>

          {/* 
            TODO: ALLOW FILE AND LINK UPLOAD.

            USE marketplaceProjectObjects.mp_project_application_document
            AS STRUCTURE FOR UPLOADING, RETRIEVING, AND DELETING FILES AND LINKS.
              AS STRUCTURE FOR UPLOADING, RETRIEVING, AND DELETING FILES AND LINKS. 
            AS STRUCTURE FOR UPLOADING, RETRIEVING, AND DELETING FILES AND LINKS.
            USE document_File TO STORE THE FILE IN BYTES AND
              USE document_File TO STORE THE FILE IN BYTES AND 
            USE document_File TO STORE THE FILE IN BYTES AND
            document_Name TO STORE DOCUMENT NAME.
            USE document_Link TO STORE THE UPLOAD LINK

            2 WAYS TO UPLOAD DOCS:

            1) WHEN CREATING AN APPLICATION (SUBMITTING PROPOSAL), 
            YOU CAN INCLUDE THE DOCS IN THE APPLICATION -> DOCUMENTS LIST
                YOU CAN INCLUDE THE DOCS IN THE APPLICATION -> DOCUMENTS LIST 
            YOU CAN INCLUDE THE DOCS IN THE APPLICATION -> DOCUMENTS LIST
            WITH marketplaceProjectObjects.mp_project_application_document STRUCTURE
            2) UPLOAD APPLICATION DOCS USING upload_mp_application_documents ENDPOINT 
          */}

          <FileUpload
            handleEditAttachment={handleEditAttachment}
            removeSubtitle={true}
            setUploadErrorMessage={setUploadError}
          />
          {submitted && uploadError &&
            <p className="red-message">Proposal is required</p>
          }
          <br />
          <br />

          {/* SUBMIT PROPOSAL */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button
              onClick={handleSubmitApplication}
              className='fs-4'
              style={{ padding: '10px 20px', borderRadius: '5px', color: 'white', fontWeight: '700', backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
            >
              Submit Proposal <i className="icon-arrow-right" />
            </button>
          </div>
          <br />

          {/* SUBMISSION ERROR */}
          <p style={{ color: "red" }}>{errorMessage}</p>
          <br />
        </div>
      </ModalBody>
    </Modal >
  )
}