import React from 'react';
import { Route } from "react-router-dom";
import { UserRedirect } from "../../_pages/Shared/Landing";
import { EmbeddedAuth, ForgotPassword, ResetPassword} from "../../_pages/Account/LoginPage";
import { LoginPage } from "../../_pages/Account/LoginPage/LoginPage_v2";
import { Register_Routes } from '..';
import { DeletionCompletePage } from '../../_pages/Account';

export const Public_Routes = (props) => {
    return [
        <Route exact path="/" component={UserRedirect} />,

        //Login
        <Route path="/login" component={LoginPage} />,

        //Network 
        <Route path="/embedded_auth" component={EmbeddedAuth} />,

        //Partner Routing 
        <Route exact path="/login/partner/:string" component={LoginPage} />,
        <Route exact path="/login/grants/:listing_id?" component={LoginPage} />,

        <Route exact path="/forgot_password" component={ForgotPassword} />,
        <Route exact path="/reset_password" component={ResetPassword} />,
        
        //Registration
        <Route path="/register" render={() => Register_Routes(props)} />,

        //Delete Confirmation
        <Route exact path="/complete" component={DeletionCompletePage} />,
    ]
}