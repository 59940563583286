//this file is no longer in use ?

import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { EditAdditional } from "../AdditionalAttributes/EditAdditional";
import { EditExpertise } from "../AdditionalAttributes/EditExpertise";
import { EditFocus } from "../AdditionalAttributes/EditFocus";
import { EditRoles } from "../AdditionalAttributes/EditRoles";

class AdditionalAttributesForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            category_TagsError: false,
        }
    }

    render() {

        const { category_TagsError } = this.state
        const {
            service_Provider, handleChange, handleSelectionChange, submitted, editOrganization, handleEditCategorySelection, handleEditCategoryInput,
            checkboxes, handleCheckbox, handleDefaultCheckbox, editErrors, handleEditChange, editTextInput, isEmptyCheckbox,
            foundation_Expertise_List, agency_Expertise_List, program_Expertise_List, stringFoundationTypes, stringAgencyTypes,
            stringProgramTypes, industry_Focus_List, industry_Service_List, stringFocusTypes, stringServiceTypes,
        } = this.props

        let industry_Focus_List_Cat = { "SP_Industry_Focus_Tags": industry_Focus_List };
        let industry_Service_List_Cat = { "SP_Industry_Services_Tags": industry_Service_List };
        let foundation_Expertise_List_Cat = { "GW_Foundation_Tags": foundation_Expertise_List };
        let agency_Expertise_List_Cat = { "GW_Agency_Tags": agency_Expertise_List };
        let program_Expertise_List_Cat = { "GW_Program_Tags": program_Expertise_List };

        return (
            <div>
                <EditAdditional
                    service_Provider={service_Provider}
                    handleChange={handleChange}
                    handleSelectionChange={handleSelectionChange}
                    submitted={submitted}
                    checkboxes={checkboxes}
                    handleCheckbox={handleCheckbox}
                    handleDefaultCheckbox={handleDefaultCheckbox}
                    handleEditChange={handleEditChange}
                    editTextInput={editTextInput}
                />
                <hr className="formHr" />

                <EditRoles
                    service_Provider={service_Provider}
                    handleChange={handleChange}
                    handleSelectionChange={handleSelectionChange}
                    submitted={submitted}
                    editOrganization={editOrganization}
                    checkboxes={checkboxes}
                    handleCheckbox={handleCheckbox}
                    handleDefaultCheckbox={handleDefaultCheckbox}
                    isEmptyCheckbox={isEmptyCheckbox}
                    editErrors={editErrors}
                />

                {checkboxes.roles.grant_Writer &&
                    <div>
                        <hr className="formHr" />
                        <EditExpertise
                            service_Provider={service_Provider}
                            handleChange={handleChange}
                            handleSelectionChange={handleSelectionChange}
                            submitted={submitted}
                            editOrganization={editOrganization}
                            handleCategorySelection={handleEditCategorySelection}
                            handleCategoryInput={handleEditCategoryInput}
                            checkboxes={checkboxes}
                            handleCheckbox={handleCheckbox}
                            handleDefaultCheckbox={handleDefaultCheckbox}
                            editErrors={editErrors}
                            category_TagsError={category_TagsError}
                            foundation_Expertise_List_Cat={foundation_Expertise_List_Cat}
                            agency_Expertise_List_Cat={agency_Expertise_List_Cat}
                            program_Expertise_List_Cat={program_Expertise_List_Cat}
                            stringFoundationTypes={stringFoundationTypes}
                            stringAgencyTypes={stringAgencyTypes}
                            stringProgramTypes={stringProgramTypes}
                            handleEditChange={handleEditChange}
                            editTextInput={editTextInput}
                        />
                    </div>
                }
                <hr className="formHr" />

                <EditFocus
                    service_Provider={service_Provider}
                    handleChange={handleChange}
                    handleSelectionChange={handleSelectionChange}
                    submitted={submitted}
                    editOrganization={editOrganization}
                    handleCategorySelection={handleEditCategorySelection}
                    handleCategoryInput={handleEditCategoryInput}
                    editErrors={editErrors}
                    category_TagsError={category_TagsError}
                    industry_Focus_List_Cat={industry_Focus_List_Cat}
                    industry_Service_List_Cat={industry_Service_List_Cat}
                    stringFocusTypes={stringFocusTypes}
                    stringServiceTypes={stringServiceTypes}
                />
                <hr className="formHr" />

                <div>
                    <h4 className="inputFieldLabel">Testimonials/References</h4>
                    <p className="inputFieldSubLabel">
                        Please copy/paste or link to past client/customer testimonials and/or references. For grant writers, contactable customer references are preferred.
                    </p>
                    <textarea
                        required
                        name="References"
                        value={editTextInput.References}
                        onChange={(e) => handleEditChange(e)}
                        multiline
                        rowsMax="20"
                        rows={5}
                        className="inputField boxText"
                    />
                </div>
                <br />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedAdditionalAttributesForm = connect(mapStateToProps)(AdditionalAttributesForm);
export { connectedAdditionalAttributesForm as AdditionalAttributesForm };
