import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { organizationstripeActions, stripeActions } from '../../../../_actions';
import tooltipIcon from '../../../../_assets/img/spProjectTooltipIcon.png';
import { asyncLocalStorage, pluginService, sharedService } from '../../../../_services';
import VendorBanner from "../../../InvoicesHours/VendorBanner";
import { SP_Payout_Account_Billing_Page } from '../../../ServiceProviders';
import CreateOrganizationModal from '../../../Settings/OrganizationSettings/CreateOrganizationModal';
import { Loader } from '../../../Shared/Loader';
import { PaymentMethodView } from './PaymentMethodView';
import { PaymentSelection } from './PaymentSelection';

export const BillingView = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.setUser.user);
  const billingHandler = useSelector(state => state.billingHandler);
  const { isPaymentValid } = useSelector(state => state.billingHandler);

  const plan = useSelector(state => state.billingHandler.plan);
  const serviceProvider = useSelector(state => state.serviceprovider_getserviceprovider.service_Provider);
  const stripe_Verified = useSelector(state => state.stripeVerified.stripe_Verified)

  const { billingInfo, billingInfo_org, org_Id, hidePlan, updatePage } = props;

  const [initializedBillingInfo, setInitializedBillingInfo] = useState(false);
  const [initializedBillingInfo_org, setInitializedBillingInfo_org] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updating_org, setUpdating_org] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [updateOrgPaymentMethod, setOrgUpdatePaymentMethod] = useState(false);
  const [updatePayoutMethod, setUpdatePayoutMethod] = useState(false);
  const [showVendorBanner, setShowVendorBanner] = useState(true);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [newOrgId, setNewOrgId] = useState('');
  const [individualErrorMessage, setIndividualErrorMessage] = useState(false);
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState(false);
  const [indvOrgErrorMessage, setIndvOrgErrorMessage] = useState('');
  const [iFrameConfig, setIFrameConfig] = useState(null);

  let accentColor = pluginService.getIFrameColor()

  useEffect(() => {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      setIFrameConfig(value)
    });
  }, []);

  useEffect(() => {
    const { loading_getbilling } = billingInfo;
    if (!updating && loading_getbilling) {
      setUpdating(true);
    } else if (updating && !loading_getbilling) {
      setInitializedBillingInfo(true);
      setUpdating(false);
      setUpdatePaymentMethod(false);
    }
  }, [billingInfo.loading_getbilling]);

  useEffect(() => {
    const { loading_getbilling } = billingInfo_org;
    if (!updating_org && loading_getbilling) {
      setUpdating_org(true);
    } else if (updating_org && !loading_getbilling) {
      setInitializedBillingInfo_org(true);
      setUpdating_org(false);
      setOrgUpdatePaymentMethod(false);
    }
  }, [billingInfo_org.loading_getbilling]);

  //update address after verifying bank
  useEffect(() => {
    if (billingInfo.billingAddress && Object.keys(billingInfo.billingAddress).length === 0) {
      dispatch(stripeActions.getBillingInfo(user.id));
    }
  }, [billingInfo.bank_Account.status])

  function renewPlan() {
    dispatch(stripeActions.renewPlanSubscription(user.id));
  }

  async function handlePaymentSubmit(key) {
    if (!key) {
      if (!org_Id && !newOrgId) { toggleOrgBilling() }
      return;
    }

    const valid_payment = await billingHandler?.isPaymentValid[key]();

    if (valid_payment) {
      setLoading(true);

      billingHandler.handlePaymentSubmission[key]();

      setIndvOrgErrorMessage('');
    }
    else {

      if (key === "user") {
        setIndividualErrorMessage(true);
      }
      else {
        setOrganizationErrorMessage(true);
      }
    }
  }

  function toggleOrgBilling(new_org_id) {
    if (!org_Id && !new_org_id && !newOrgId //no org created
      && !billingInfo_org.same_as_indv) { //is setting org payment info same as indv payment info
      setOpenOrgModal(true); //open org modal 
    } else {
      if (
        !billingInfo_org.same_as_indv //marking same 
        && !sharedService.hasPaymentMethod(billingInfo) //no personal payment
        && billingInfo_org.is_required //org payment info is required
      ) {
        setIndvOrgErrorMessage('Payment information is required. You must add payment information first.');
        return;
      }

      const userDetails = {
        user_id: user.id,
        email: user.email,
        name: user.name + " " + user.surname,
        org_id: org_Id ? org_Id : newOrgId ? newOrgId : new_org_id,
        same_as_indv: new_org_id ? billingInfo_org.same_as_indv : !billingInfo_org.same_as_indv
      };

      dispatch(organizationstripeActions.setIndvBilling(userDetails));
      setOpenOrgModal(false);

      if (!billingInfo_org.same_as_indv //same
        && !billingInfo_org.is_required) { //not required
        dispatch(organizationstripeActions.setOrgBillingInfo({}))
      }
      else if (!billingInfo_org.same_as_indv //same
        && billingInfo_org.is_required) { //required
        dispatch(organizationstripeActions.setOrgBillingInfo(billingInfo))
      }
    }
  }

  function handleSubmittedOrg(org_details) {
    setNewOrgId(org_details.organization.organization_Id);
    window.location.reload();
  }

  return (
    <div id="payment-form">
      {(loading || billingInfo.loading_getbilling || billingHandler.loading_getPlan || billingInfo_org.loading_getbilling) &&
        <Loader />
      }

      {/* Org Modal */}
      {openOrgModal &&
        <CreateOrganizationModal
          isOpen={openOrgModal}
          openModal={() => setOpenOrgModal(!openOrgModal)}
          handleSubmittedOrg={handleSubmittedOrg}
        />
      }
      <div id="cardForm" className="billingAddress">
        {/* Billing Information */}
        {/* <div className="billingAddress">
          <>
            <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
              <div className="col-md-3" style={{ padding: 0 }}>
                <p className="acceptingProjectHeader mobileFloatHeader">Billing Information</p>
              </div>
              <div className="col-md-5" />
              <div className="col-md-3" style={{ marginTop: '5px' }}>
                {!profileInformationEdit && sharedService.hasBillingAddress(billingInfo) &&
                  <button className="backToProjects" onClick={(evt) => editProfileInformation(evt, true)} color="info">
                    <i className="icon-pencil-icon" />Edit
                  </button>
                }
              </div>
            </div>
            <Address
              // org_Id={org_Id}
              profileInformationEdit={!sharedService.hasBillingAddress(billingInfo) ? true : profileInformationEdit}
              billingInfo={billingInfo}
              additionalAction={() => { setLoading(false); editProfileInformation('', false) }}
            />

            <div div className="row">
              <div className="col-md-3 billingInfoLeftCol" style={{ marginTop: "15px" }} />
              <div className="col-md-5">
                {!sharedService.hasBillingAddress(billingInfo) &&
                  <button className="billingButton" onClick={handleAddressSubmit} color="info">
                    Save Billing Address
                  </button>

                }
                {profileInformationEdit &&
                  <div>
                    <button className="billingButton" onClick={handleAddressSubmit} color="info">
                      Update
                    </button>
                    <button className="billingButton secondary" onClick={() => editProfileInformation('', false)} color="info">
                      Cancel
                    </button>
                  </div>
                }

              </div>
            </div>
          </>
        </div> */}

        {/* Current Plan */}
        {!hidePlan && !iFrameConfig?.url && <>
          <div className="row viewProfileHeader my-24">
            <div className="col-md-8" style={{ flex: "auto", padding: 0 }}>
              <p className="acceptingProjectHeader mobileFloatHeader">Current Subscription</p>
            </div>
            {/* <div className="col-md-3" style={{ flex: "auto", marginTop: '5px' }}>
              <a className="backToProjects" style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }} onClick={() => updatePage("choosePlan")} type='buttton' color="info">
                <i className="icon-pencil-icon" />Change Plan
              </a>
            </div> */}
          </div>

          <div className="row paymentRow">
            <div className="col-md-3 flexColEnd">
              {
                plan?.amount
              }
            </div>
          </div>
          <br />
        </>
        }

        {/* Payment Information */}
        <br />
        {initializedBillingInfo && !iFrameConfig?.url && <div>
          <div className="row viewProfileHeader mb-24">

            <div className="col-md-8" style={{ flex: "auto", padding: 0 }}>
              <p className="acceptingProjectHeader mobileFloatHeader">Payment Information</p>
            </div>

            {/* ICONS */}

            <div className="col-md-3" style={{ flex: "auto", marginTop: '5px' }}>

              {/* Update Payment Method */}
              {(billingInfo.card?.cardNumber || billingInfo.bank_Account?.account_Number) && !updatePaymentMethod &&
                <a className="backToProjects" style={{ textAlign: "left", color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }} onClick={(e) => setUpdatePaymentMethod(true)} color="info">
                  <i className="icon-pencil-icon" style={{ float: "left" }} />Edit
                </a>
              }
            </div>
          </div>

          {(sharedService.hasPaymentMethod(billingInfo) && !updatePaymentMethod) ?
            <PaymentMethodView
              // org_Id={org_Id}
              billingInfo={billingInfo}
            />
            :
            <PaymentSelection
              // org_Id={org_Id}
              type={'user'}
              billingInfo={billingInfo}
              additionalAction={() => { setUpdatePaymentMethod(false) }}
              showErrorMessage={(value) => { setIndividualErrorMessage(value) }}
              handleLoading={() => { setLoading(false) }}
            />
          }
          {(!(billingInfo.card?.cardNumber || billingInfo.bank_Account?.account_Number) || updatePaymentMethod) &&
            <div className="row" style={{ marginBottom: 15 }}>
              <div className="col-md-3 billingInfoLeftCol" style={{ marginTop: "15px" }} />

              <div className="col-md-5">
                <div>
                  <button className="billingButton" onClick={() => { handlePaymentSubmit('user') }} style={{ backgroundColor: iFrameConfig?.primary ? iFrameConfig?.primary : '#0C7069' }}>
                    {!(billingInfo.card?.cardNumber || billingInfo.bank_Account?.account_Number) ? "Save Payment Method" : "Update"}
                  </button>
                  {updatePaymentMethod &&
                    <button className="billingButton secondary" onClick={() => { setIndividualErrorMessage(false); setUpdatePaymentMethod(false) }} color="info">
                      Cancel
                    </button>
                  }
                </div>
                {individualErrorMessage &&
                  <p className='red' style={{ marginTop: 10 }}>
                    We're sorry, there was an issue processing your payment.
                    Please double check your payment details and try again.
                    If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
                  </p>
                }
              </div>
            </div>
          }
        </div>}

      </div >
    </div >
  )
}