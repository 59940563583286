import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Onboarding.css";
import AlluraParty from '../../../_assets/img/AlluraParty.png';
import { pluginService } from "../../../_services";

let accentColor = pluginService.getIFrameColor()

class GS_Onboarding_Org_Confirmation extends React.Component {
  showButtons = () => {
    const redirect_url = sessionStorage.getItem("redirect");
    sessionStorage.removeItem("redirect");
    return (redirect_url ?
      <>
        <br />
        {this.redirectPage(5000, redirect_url)}
        <p>
          You will automatically be redirected. If you are not redirected within 10 seconds, please <a onClick={() => window.location.href = pluginService.redirect(redirect_url)}>click here</a>
        </p>
      </>
      :
      <a href={pluginService.redirect("/dashboard")}>
        Go to Dashboard <i class="icon-arrow-right" />
      </a>
    )
  }

  redirectPage = (timeout, url) => {
    setTimeout(() => {
      window.location.href = pluginService.redirect(url);
    }, timeout);
  }

  render() {
    return (
      <div className="congratsBody">
        <img className="congratsBackground" src={AlluraParty} alt="" />
        <div className="congratsText" style={{ width: "100%" }}>
          <div className="row center">
            <h1 className="congratsHeader">
              Congratulations!
            </h1>
          </div>
          <div className="row center">
            <p className="congratsDescription">
              Nice work, you have created an organization. You may now continue on with your work.
            </p>
          </div>
          <br />
          <button className="defaultGreenButton" style={{ padding: "8px 32px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={this.props.handleSubmittedOrg}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const GS_Onboarding_Org_Confirmation_x =
  connect(mapStateToProps)(GS_Onboarding_Org_Confirmation);
export { GS_Onboarding_Org_Confirmation_x as GS_Onboarding_Org_Confirmation };
