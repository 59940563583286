//this file is not longer in use

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { stripeService } from '../../../../_services';
import { stripeActions } from '../../../../_actions';
import { checkoutService } from '../PaymentMethod';

import { Cart } from './StripeComponents/Cart';
import { Coupon } from './StripeComponents/Coupon';
import { OrderSummary } from './StripeComponents/OrderSummary';
import { AddOns } from './StripeComponents/AddOns';

import { PaymentMethodView } from '../PaymentMethod/PaymentMethodView';
import { PaymentSelection } from '../PaymentMethod/PaymentSelection';
import { Address } from '../../../Settings';

import { Loader } from '../../../Shared/Loader';

import '../../../../Styles/NewCheckout.css'

export const Checkout = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.setUser);
    const billingHandler = useSelector(state => state.billingHandler);
    const billingInfo = useSelector(state => state.billingInfo);
    const purchaseProduct = useSelector(state => state.purchaseProduct);

    const [prices, setPrices] = useState('');
    const [addOns, setAddOns] = useState('');
    const [consolidatedProducts, setConsolidatedProducts] = useState('');
    const [consolidatedProductsAddOns, setConsolidatedProductsAddOns] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [updatePayment, setUpdatePayment] = useState(false);
    const [updateAddress, setUpdateAddress] = useState(false);
    const [submittingNewAddress, setSubmittingNewAddress] = useState(false);
    const [addressFilled, setAddressFilled] = useState(false);
    const [infoUpdated, setInfoUpdated] = useState(false);
    const [canSubmitPurchase, setCanSubmitPurchase] = useState(false);
    const [agreements, setAgreements] = useState(null)
    const [showError, setShowError] = useState(false);
    const [updatingInfo, setUpdatingInfo] = useState(false);
    // const [selectedPriceIds, setSelectedPriceIds] = useState([]);
    // const [selectedRecurringPriceIds, setSelectedRecurringPriceIds] = useState([]);

    const isInitialMount1 = useRef(true);
    const isInitialMount2 = useRef(true);

    useEffect(() => {
        if (!prices) {
            document.body.scrollTo(0, 0);

            const group = props.group;
            let product_prices = "";
            let product_prices_add_ons = "";

            async function getPrices() {
                //get, sort and set main product
                if (group) {
                    product_prices = await stripeService.getPricesByGroup(group);
                }

                if (product_prices && product_prices.length > 0) {
                    product_prices = sortPrices(product_prices);
                    setPrices(product_prices);

                    let selectedPriceIds = [];
                    let selectedRecurringPriceIds = [];
                    let priceIds_new = { selectedPriceIds, selectedRecurringPriceIds };
                    const selected_price_ids = checkoutService.addToCart(priceIds_new, product_prices[0]);
                    dispatch(stripeActions.setPriceIds(selected_price_ids));
                }

                //get, sort and set add ons
                product_prices_add_ons = await stripeService.getPricesByGroup("add ons", [group]);
                if (product_prices_add_ons && product_prices_add_ons.length > 0) {
                    product_prices_add_ons = sortPrices(product_prices_add_ons);
                    setAddOns(product_prices_add_ons);

                    const consolidated_products_add_ons = consolidate(product_prices_add_ons);
                    setConsolidatedProductsAddOns(consolidated_products_add_ons);
                }

                let all_prices = [...product_prices, ...product_prices_add_ons];
                const consolidated_products = consolidate(all_prices);
                setConsolidatedProducts(consolidated_products);
            }
            getPrices();
        }
    }, [])

    //after the payment and billing information are done updating, get new props
    useEffect(() => {
        //skip first mount
        if (isInitialMount2.current) {
            isInitialMount2.current = false;
        }
        else if (infoUpdated === true) {
            setUpdatingInfo(false);
            dispatch(stripeActions.getBillingInfo(user.id));
        }
    }, [infoUpdated])

    //after the props are changed, submit purchase
    useEffect(() => {
        //skip first mount
        if (isInitialMount1.current) {
            isInitialMount1.current = false;
        }
        else {
            setCanSubmitPurchase(true);
        }
    }, [billingInfo.billingAddress, billingInfo.card.card_Number, billingInfo.bank_Account.account_Number])

    //sort prices - main, once, monthly, annually
    function sortPrices(product_prices) {
        if (!product_prices) {
            return [];
        }

        let defaultProductId = props.group;

        return product_prices.sort((a, b) => {
            //main purchase
            if (defaultProductId != "") {
                if (a.product.metadata["group"] == defaultProductId && b.product.metadata["group"] != defaultProductId) {
                    return -1
                } else if (a.product.metadata["group"] != defaultProductId && b.product.metadata["group"] == defaultProductId) {
                    return 1
                }
            }

            //add ons
            if (!a.recurring) return -1
            if (!b.recurring) return 1
            if (a.recurring && b.recurring) {
                if (a.recurring.interval == "month" && b.recurring.interval == "year") return -1
                if (a.recurring.interval == "year" && b.recurring.interval == "month") return 1
                return a.unit_amount - b.unit_amount;
            }
            if (a.recurring && !b.recurring)
                return 1
            if (!a.recurring && b.recurrig)
                return -1
            return a.unit_amount - b.unit_amount;
        });
    }

    //consolidate to products
    function consolidate(prices) {
        const consolidated_products = {};
        prices.forEach((price) => {
            if (price) {
                if (!consolidated_products[price.product.id]) {
                    consolidated_products[price.product.id] = [price];
                } else {
                    consolidated_products[price.product.id].push(price);
                }
            }
        });

        return consolidated_products;
    }

    //mark whether address is currently being submitted
    const handleSubmittingNewAddress = () => {
        setSubmittingNewAddress(!submittingNewAddress);
    }

    //trigger address update
    const handleUpdateAddress = () => {
        handleSubmittingNewAddress(true);
    }

    //check if billing address form is fully filled
    const handleAddressFilled = () => {
        setAddressFilled(true);
    }

    //check if payment and billing info is updated
    const handleIsInfoUpdated = (value) => {
        setInfoUpdated(value)
    }

    //stop loading if trying to submit address with errors
    const handleUpdatingInfo = () => {
        setUpdatingInfo(false);
    }

    //handle payment and billing address information before purchase
    const handleCheckoutInformation = async (agreements) => {

        setAgreements(agreements)

        //payment method exists, billing address exists
        if (((billingInfo.card && Object.keys(billingInfo.card).length !== 0) //have a card
            || (billingInfo.bank_Account && Object.keys(billingInfo.bank_Account).length !== 0)) //or have a bank
            && (billingInfo.billingAddress && Object.keys(billingInfo.billingAddress).length !== 0) //and have billing address
        ) {
            //payment and address are not being edited
            if (!updateAddress && !updatePayment) {
                setCanSubmitPurchase(true);
            }

            //payment is being edited
            if (updatePayment) {

                //address fields are filled
                if ((!updateAddress || (updateAddress && addressFilled))) {
                    //update payment method
                    setUpdatingInfo(true);
                    await billingHandler.handlePaymentSubmission();
                }
                else {
                    setShowError(true)
                }
            }

            //only address is being edited 
            if (updateAddress && !updatePayment) {

                //update address
                setUpdatingInfo(true);
                handleUpdateAddress();

                if (!addressFilled) {
                    setShowError(true)
                }
            }
        }
        else {
            //payment method does not exist
            if ((!billingInfo.card || Object.keys(billingInfo.card).length === 0) //card doesn't exist
                && (!billingInfo.bank_Account || Object.keys(billingInfo.bank_Account).length === 0) //bank doesn't exist
            ) {

                //address fields are filled
                if ((billingInfo.billingAddress && Object.keys(billingInfo.billingAddress).length !== 0) //address already exists
                    || (addressFilled) // or address is filled out 
                ) {
                    //add payment method
                    setUpdatingInfo(true);
                    billingHandler.handlePaymentSubmission();
                }
                else {
                    setShowError(true)
                }
            }

            //billing address does not exist
            if (!billingInfo.billingAddress || Object.keys(billingInfo.billingAddress).length === 0) {

                //payment fields are filled
                if (((billingInfo.card && Object.keys(billingInfo.card).length !== 0) //have a card
                    || (billingInfo.bank_Account && Object.keys(billingInfo.bank_Account).length !== 0)) //have a bank
                    || updatePayment //or payment is being updated
                ) {
                    //add billing address
                    setUpdatingInfo(true);
                    setSubmittingNewAddress(true);
                }
                else {
                    setShowError(true)
                }
            }
        }
    }

    //handle checkout
    async function handleSubmit() {

        let isValid = true;

        //change forms to display
        setUpdatePayment(false)
        setUpdateAddress(false)

        setSubmitted(true);

        let termsAndPrivacy_Agreement = false;
        let email_Agreement = false;

        //verify agreements
        if (agreements) {

            //check if bank is verified
            if ((billingInfo.bank_Account && Object.keys(billingInfo.bank_Account).length !== 0)
                && !(billingInfo.bank_Account.status === 'validated' || billingInfo.bank_Account.status === 'verified')
            ) {
                isValid = false;
            }

            termsAndPrivacy_Agreement = agreements.terms_privacy_agreement;
            email_Agreement = agreements.email_agreement;

            //check if terms are checked
            if (!termsAndPrivacy_Agreement) {
                isValid = false;
            }
        }
        else {

            termsAndPrivacy_Agreement = billingHandler.agreements.terms_privacy_agreement;

            //check if terms are checked
            if (!termsAndPrivacy_Agreement) {
                isValid = false;
            }

            email_Agreement = billingHandler.agreements.email_agreement;
        }

        if (isValid === true) {

            // set purchases
            let priceIds = billingHandler.priceIds.selectedPriceIds.map((price) => price.id);
            let recurringPriceIds = billingHandler.priceIds.selectedRecurringPriceIds.map((price) => price.id);

            var productDetails = {
                userDetails: {
                    user_Id: user.id,
                    email_Agreement,
                    termsAndPrivacy_Agreement
                },
                productDetails: {
                    priceIds,
                    recurringPriceIds
                }
            };

            //add coupon
            let couponId = billingHandler.coupon?.couponId;
            if (couponId) {
                productDetails.productDetails["couponId"] = couponId;
            }

            //dispatch purchase
            dispatch(stripeActions.purchaseProduct(productDetails, user));

            //return
            if (props.returnPage) {
                props.returnPage();
            }
        }

        setCanSubmitPurchase(false);
        handleIsInfoUpdated(false);
        setShowError(false);
    }

    return (
        <>
            {/* Load while updating payment and billing information */}
            {updatingInfo && <Loader />}

            {/* After all the editing and entering, check if we have all info and then consider as ready to submit */}
            {canSubmitPurchase &&
                <>
                    {handleSubmit()}
                </>
            }
            {purchaseProduct.loading && <Loader />}
            <div className="row">
                <div className="col-md-8">

                    {/* SERVICES */}

                    <div className="checkoutBox" >
                        <div>
                            <div>
                                <h2 className="checkoutHeading">Subscribe</h2>
                                <Cart
                                    group={props.group}
                                    consolidatedProducts={consolidatedProducts}
                                />
                            </div>

                            {(billingHandler.priceIds?.selectedPriceIds && billingHandler.priceIds?.selectedRecurringPriceIds) && //add ons exist
                                ((billingHandler.priceIds?.selectedPriceIds.length + billingHandler.priceIds?.selectedRecurringPriceIds.length - 1)
                                    < Object.keys(consolidatedProductsAddOns).length) && //add ons not added to cart
                                <div>
                                    <hr />
                                    <h2 className="checkoutHeading">Add-Ons</h2>
                                    <AddOns consolidatedProductsAddOns={consolidatedProductsAddOns} />
                                </div>
                            }
                        </div>
                        <hr />
                        <Coupon />
                    </div>

                    <br />

                    <div className="checkoutBox">
                        <h2 className="checkoutHeading">Billing Information</h2>

                        {/* Display payment method */}

                        {(billingInfo.card.cardNumber || billingInfo.bank_Account.account_Number) && !updatePayment &&
                            <div className='row paymentMethodRow'>
                                <div className='col-md-10 paymentMethodCol' style={{ padding: 0 }}>
                                    <PaymentMethodView
                                        regularStyling={true}
                                        billingInfo={billingInfo}
                                        verifyBankAccount={stripeActions.verifyBankAccount}
                                    />
                                </div>
                                <div className='col-md-2 paymentMethodEditCol' style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button className="checkoutEditButton" onClick={() => setUpdatePayment(true)}>
                                        <i className="icon-pencil-icon" />
                                    </button>
                                </div>
                            </div>
                        }

                        {/* Enter/Edit payment method */}

                        {((Object.keys(billingInfo.card).length === 0 && Object.keys(billingInfo.bank_Account).length === 0) || updatePayment) &&
                            <div>
                                {(Object.keys(billingInfo.card).length > 0 || Object.keys(billingInfo.bank_Account).length > 0) &&
                                    <div className='col-md-2 paymentMethodEditCol' style={{ float: 'right' }}>
                                        <a className="checkoutEditButton" onClick={() => setUpdatePayment(false)}>
                                            Cancel
                                        </a>
                                    </div>}
                                <PaymentSelection
                                    updatePaymentWithServiceCall={true}
                                    handleUpdateAddress={(updateAddress || addressFilled) ? handleUpdateAddress : ""}
                                    regularStyling={true}
                                    handleIsInfoUpdated={handleIsInfoUpdated}

                                    billingInfo={billingInfo}
                                />
                            </div>
                        }

                        <hr />

                        {/* Display billing address */}

                        {((billingInfo.billingAddress && (Object.keys(billingInfo.billingAddress).length !== 0))) && !updateAddress &&
                            <div className='row billingAddressRow'>
                                <div className='col-md-8 billingAddressCol' style={{ padding: 0 }}>
                                    <Address regularStyling={true}
                                        billingInfo={billingInfo}
                                    />
                                </div>
                                <div className='col-md-4 billingAddressEditCol' style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button className="checkoutEditButton" onClick={() => setUpdateAddress(true)}>
                                        <i className="icon-pencil-icon" />
                                    </button>
                                </div>
                            </div>
                        }

                        {/* Enter/Edit billing address */}

                        {((!billingInfo.billingAddress || (billingInfo.billingAddress && Object.keys(billingInfo.billingAddress).length === 0)) || updateAddress) &&
                            <div >
                                {(billingInfo.billingAddress && (Object.keys(billingInfo.billingAddress).length !== 0)) &&
                                    <div className='col-md-2 paymentMethodEditCol' style={{ float: 'right' }}>
                                        <a className="checkoutEditButton" onClick={() => setUpdateAddress(false)}>
                                            Cancel
                                        </a>
                                    </div>}
                                <Address
                                    regularStyling={true}
                                    editAddress={true}
                                    updateAddressWithServiceCall={true}
                                    submittingNewAddress={submittingNewAddress}
                                    handleAddressFilled={handleAddressFilled}
                                    handleSubmittingNewAddress={handleSubmittingNewAddress}
                                    handleIsInfoUpdated={handleIsInfoUpdated}
                                    handleUpdatingInfo={handleUpdatingInfo}

                                    billingInfo={billingInfo}
                                />
                            </div>
                        }

                    </div>

                </div>

                <div className="col-md-4">

                    {/* ORDER SUMMARY */}

                    <div className="checkoutBox orderSummaryBox">
                        <OrderSummary handleSubmit={handleCheckoutInformation} />
                        {showError &&
                            <p className="errorMessage">Please make sure that your payment and billing information is correct.</p>
                        }
                        {submitted
                            && ((billingInfo.bank_Account && Object.keys(billingInfo.bank_Account).length !== 0) //is bank
                                && !(billingInfo.bank_Account?.status === 'validated' || billingInfo.bank_Account?.status === 'verified')) && //bank not validated/verified
                            <p className='errorMessage'>Please verify your bank.</p>
                        }
                    </div>

                </div>

            </div>
        </>
    );
}
