import React from 'react';
import Class from "@material-ui/icons/Class";
import CustomTabs from "../../_components/dashboardComponents/CustomTabs/CustomTabs.js";
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import GridItem from "../../_components/dashboardComponents/Grid/GridItem.js";
import { Organization } from './OrganizationSettings/Organization';

// const styles = {
//   cardCategoryWhite: {
//     color: "rgba(255,255,255,.62)",
//     margin: "0",
//     fontSize: "14px",
//     marginTop: "0",
//     marginBottom: "0"
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none"
//   }
// };

export default function WorkProfile(props) {
  // const initVal = window.location.search.includes('val') ? parseInt(window.location.search.split("=")[1] , 10 ) : 0;
  const initVal = window.location.search.includes('val') ? window.location.search.split("=")[1] : "";

  return (
    <div style={{ maxWidth: 1000 }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            initialValue={initVal}
            headerColor={"#29a"}
            tabs={[
              {
                display: null,
                tabName: "Organization",
                tabIcon: Class,
                tabContent: (
                  <div>
                    <br />
                    <Organization />
                  </div>
                )
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
