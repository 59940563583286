//this file is no longer in use ?

import React from "react";
import { connect } from "react-redux";
import { history } from "../../../_helpers";
import { asyncLocalStorage, organizationService } from "../../../_services";
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import seeker from "../../../_assets/img/seeker-model.svg";
import provider from "../../../_assets/img/provider-model.svg";
import searchIcon from "../../../_assets/img/icons/search-icon.svg";
import pencilIcon from "../../../_assets/img/icons/pencil-icon.svg";
import "../../../Styles/Registration.css";
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';

class RegistrationType extends React.Component {
  constructor(props) {
    super(props);
    const { nav } = this.props;

    this.state = {
      user: {
        Discriminator: "Client",
        Linked_Listing_Id: "",

        Email: "",
        Password: "",

        Name: "",
        Surname: "",
        Phone_Number: "",

        Organization_Name: "",
        Website: "",
        Title: "",
        Organization_Description: "",

        category: "",
        category_Tags: [],

        EIN_Number: "",
        DUNS_Number: "",
        SAM_Registration: "",
        Woman_Owned: "",
        Veteran_Owned: "",
        Hubzone_Owned: "",

        Organization_Address: "",
        City: "",
        Country: "",

        Referral: "",

        Twitter: "",
        LinkedIn: "",
        file: "",
        partner: "",
      },
      categories: [],
      nav: nav,
      totalNav: 0,
      listing_Id: 0,

      user_type: 'grant_seeker',
      invitation_agreement: false,
      loading: true,
      iFrameConfig: null,
      errors: {}
    };
  }

  handleSubmit(type) {
    let page_redirect = '/grant-seeker/onboarding';
    if (type === 'grant_consultant') {
      page_redirect = '/service-provider-onboarding'
    }
    // history.push(page_redirect);
    window.location.href = page_redirect;
  }

  async componentDidMount() {
    document.body.scrollTo(0, 0);

    const path = window.location.pathname;
    let pathSplit = path.split('/').pop();

    //is invited by org
    let org_invitation = {};
    if (!isNaN(pathSplit)) {
      const { user } = this.props;

      //get invitation
      org_invitation = await organizationService.getOrganizationInvitation(pathSplit, user.id);
      //auto accept if grant seeker account
      if (org_invitation && org_invitation.invitation && org_invitation.invitation.pending && org_invitation.user_type === "grant_seeker") {
        await organizationService.confirmOrganizationInvitation(pathSplit, user.id, "Accepted");
      }
    }

    await asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      if (value?.url) {
        window.location.href = '/grant-seeker-onboarding' + (value?.url ? value?.url : '')
      } else {
        this.setState({ ...org_invitation, loading: false });
      }
    });
  }

  handleFormInputs = (inputs) => {
    let { user } = inputs;

    user.file_string = localStorage.getItem("fileBase64");
    this.handleSubmit(user);
  };

  handleAgreementChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    })
  }

  acceptInvitation = async () => {
    const { user } = this.props;
    const { invitation, invitation_agreement } = this.state;
    this.setState({
      errors: {}
    })

    if (invitation_agreement) {
      let pending_invitation = await organizationService.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Accepted");
      let user_type = pending_invitation.user_Type;
      history.push('/service-provider-success');
    } else {
      this.setState({
        errors: {
          invitation_agreement: true
        }
      })
    }
  }

  rejectInvitation = async () => {
    const { user } = this.props;
    const { invitation } = this.state;

    let pending_invitation = await organizationService.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Rejected");
    let user_type = pending_invitation.user_Type;
    history.push('/service-provider-onboarding');
  }

  render() {
    const { user_type, invitation, invitation_agreement, loading, errors } = this.state;

    return (
      <div className="registration-wrapper">
        {loading && <Loader />}
        <div className="registration-container d-flex flex-column">
          <div className="registration-header">
            <img src={logo} alt="" className="logo" />
          </div>


          {user_type === "grant_consultant" && invitation && invitation.pending &&
            <div style={{ textAlign: "center", padding: "5% 15%" }}>
              <p>By joining {invitation.organization.name}'s organization as a Grant Consultant,
                we assume that you work for that organization and agree that
                <b> all money earned from your work will be sent to {invitation.organization.name}'s Stripe account. </b>
              </p>
              <p style={{ marginBottom: "5%" }}>Please click "I would like my own Stripe Account" if you'd like these funds to be sent to your own Stripe account instead.</p>
              <div style={{ marginBottom: "5%" }}>
                <input checked={invitation_agreement} name="invitation_agreement" type="checkbox" onChange={this.handleAgreementChange} />
                <label style={{ display: "inline-block", fontSize: 16 }}>I agree</label>
                {errors.invitation_agreement && <p style={{ color: "red" }}>This is required for accepting</p>}
              </div>

              <div>
                <Button color="info" onClick={this.acceptInvitation} style={{ marginRight: "5%" }}>Accept</Button>
                <Button color="info" onClick={this.rejectInvitation}>I would like my own Stripe Account</Button>
              </div>
            </div>
          }

          {(user_type !== "grant_consultant" || !invitation || !invitation.pending) && <div>
            <div className="registration-header">
              <h1>Select your profile type</h1>
            </div>
            <div className="registration-content">
              <div className="card-container">
                <div className="registration-card">
                  <h1 className="seeker-header">Grant Seeker</h1>
                  <div className="card-body">
                    <p>
                      I'm searching for grants for my business or nonprofit. I may also be searching for grant consultants to help me.
                    </p>
                    <button className="seeker-button" onClick={() => this.handleSubmit('grant_seeker')}>
                      <img
                        src={searchIcon}
                        alt=""
                        style={{ paddingRight: "10px" }}
                      />
                      That's me!
                    </button>
                  </div>
                </div>
                <img src={seeker} className="footer-image" alt="" />
              </div>
              <div className="card-container">
                <div className="registration-card">
                  <h1 className="provider-header">TA Provider</h1>
                  <div className="card-body">
                    <p>
                      I'm a grant writer, strategist, consultant or a representative of an organization that provides similar services and want to be connected to those in need of services.
                    </p>
                    <button className="provider-button" onClick={() => this.handleSubmit('grant_consultant')}>
                      <img
                        src={pencilIcon}
                        alt=""
                        style={{ paddingRight: "10px" }}
                      />
                      That's me!
                    </button>
                  </div>
                </div>
                <img src={provider} className="footer-image" alt="" />
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;

  return { user };
}

const connectedRegistrationType = connect(mapStateToProps)(RegistrationType);
export { connectedRegistrationType as RegistrationType };
