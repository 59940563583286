import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/PayoutMethods.css';
import { history } from '../../../../_helpers';
import { SP_Payout_Account } from './SP_Payout_Account';

class SP_Payout_Methods_HOC extends React.Component {
    render() {
        return (
            <div style={{ maxWidth: 650, margin: "auto" }}>
                <strong>
                    <a onClick={() => { history.push('/sp/projects') }}>
                        <i class="icon-arrow-left"></i>
                        Back to Projects
                    </a>
                </strong>
                <br /><br />
                <SP_Payout_Account />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return {
        user,
    };
}

const connectedSP_Payout_Methods_HOC = connect(mapStateToProps)(SP_Payout_Methods_HOC);
export { connectedSP_Payout_Methods_HOC as SP_Payout_Methods_HOC };

