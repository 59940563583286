import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/Organization.css';
import '../../../../Styles/SettingsStyles/Profile.css';
import { OrganizationInvitationList } from './OrganizationInvitationList.jsx';

class OrganizationInvitation extends React.Component {
    render() {
        return (
            <div className='org-invitation-container'>
                {/* ORGNAIZATION INVITATIONS */}
                <h1 className='org-invitation-heading'>Confirm Organization Invitations</h1>
                <OrganizationInvitationList />
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedOrganizationInvitation = connect(mapStateToProps)(OrganizationInvitation);
export { connectedOrganizationInvitation as OrganizationInvitation };
