import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements';
import '../../../../../Styles/SettingsStyles/Billings.css';
import { stripeActions } from '../../../../../_actions';

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#999',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class _CardForm extends Component {
  cardEl = React.createRef();

  state = {
    emailAgreement_billing: localStorage.getItem("user") ? true : false,
    termsAndPrivacyAgreement: true,

    termsAndPrivacyAgreementError: false,

    loading: true
  };

  componentDidMount = () => {
    this.setPaymentMethods();
  }

  componentDidUpdate(prevProps) {
    const { type, org_Id, billingHandler } = this.props

    if (prevProps.billingHandler !== this.props.billingHandler) {
      if (!!(type === 'org' && org_Id) && (!billingHandler || !billingHandler.isPaymentValid[org_Id])) {
        this.setPaymentMethods();
      }
    }
  }

  setPaymentMethods = () => {
    let key = this.props.type;
    if (this.props.type !== 'user') {
      if (this.props.type === 'org') {
        key = this.props.org_Id;
      }
    }

    if (key) {
      this.props.dispatch(stripeActions.setPaymentSubmissionHandler({
        handlePaymentSubmission: { [key]: this.handleSubmit },
        submitPayment: { [key]: this.submitPayment },
        isPaymentValid: { [key]: this.formIsValid }
      }));
    }
  }

  //clear card and billing info
  resetInputs = () => {
    this.cardEl.current._element.clear();
    document.getElementById("cardForm").getElementsByClassName("error")[0].innerHTML = "";
  }

  //handle card error message
  handleChange = ({ error }) => {
    if (this.props.stripeCheckout?.message) {
      this.props.dispatch(stripeActions.updateCheckoutMessage(""));
    }

    if (error) {
      this.setState({ errorMessage: error.message });
    } else if (this.state.errorMessage) {
      this.setState({ errorMessage: '' })
    }
  };

  //purchase prodcut
  handleSubmit = async (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    var paymentMethodId = await this.formIsValid();
    if (paymentMethodId) {
      this.submitPayment(paymentMethodId);
    }
  };

  submitPayment = async (paymentmethodId) => {
    return await this.props.handlePaymentDetails(paymentmethodId);
  }

  formIsValid = async () => {
    //create payment method and purchase product//clear payment info error messages
    const error = {
      message: ""
    }
    this.handleChange({ error });

    if (this.props.stripe) {
      const cardElement = this.props.elements.getElement("card");
      let payment_details = {
        type: 'card',
        card: cardElement
      };

      if (this.props.billingInfo?.billingAddress?.address) {
        const billingAddress = this.props.billingInfo?.billingAddress;
        const billing_details = {
          "name": billingAddress.cardHolderName,
          "address": {
            "line1": billingAddress.address,
            "city": billingAddress.city,
            "state": billingAddress.state,
            "postal_code": billingAddress.zipCode,
            'country': 'US'
          }
        }

        payment_details["billing_details"] = billing_details;
      }

      //create payment method
      return this.props.stripe.createPaymentMethod({
        ...payment_details
      }).then(async (result) => {
        if (result.error) {
          this.handleChange(result);
          return '';
        } else {
          return { paymentMethodId: result.paymentMethod.id }
        }
      }, (error) => {
        this.setState({ errorMessage: error });
        return '';
      });
    }
  }

  render() {
    const {
      errorMessage
    } = this.props;

    return (
      <div id="payment-form" className="payment-form">
        <div>
          <form id="cardForm" onSubmit={this.handleSubmit}>
            <div>
              <div className="background-white" style={{ padding: 0 }}>
                <div id="billingNumbers_v2">
                  <CardElement
                    onChange={this.handleChange}
                    ref={this.cardEl}
                    {...createOptions()}
                  />
                </div>
                {errorMessage != "" && this.state.errorMessage == "" ? <div className="error" role="alert">{errorMessage}</div> : null}
                <div className="error" role="alert">
                  {this.state.errorMessage}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class Card extends Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(window.stripeKey) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(window.stripeKey) });
      });
      document.querySelector('#stripe-js').addEventListener('error', () => {
        this.props.updateStripeError(true);
      });
    }
  }

  render() {
    const {
      user,
      billingInfo,
      billingHandler
    } = this.props;
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardForm
            user={user}
            handlePaymentDetails={this.props.handlePaymentDetails}
            errorMessage={this.props.errorMessage}
            dispatch={this.props.dispatch}
            billingHandler={billingHandler}
            billingInfo={billingInfo}
            card={billingInfo?.card}
            stripeCheckout={this.props.stripeCheckout}
            type={this.props.type}
            org_Id={this.props.org_Id}
          // additionalAction={this.props.additionalAction} 
          />
        </Elements>
      </StripeProvider>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedBilling = connect(mapStateToProps)(Card);
export { connectedBilling as Card };

