import React from 'react';
import { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "../../../Styles/NavMenu_v2.css";
import message from "../../../_assets/img/icons/message-icon.svg";
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from '../../../_helpers';
import { asyncLocalStorage, dashboardService, organizationService, pluginService } from "../../../_services";
import { NavDropDown } from "../LoginPartialDropdown/NavDropDown";
import { Col, Row } from 'react-bootstrap';

class DesktopNavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeColor: localStorage.getItem("themeColor"),
      firstOrganization: "",
      messageNotification: 0,
      iFrameConfig: null,
    };

    this.goToKB = this.goToKB.bind(this);
  }

  async componentDidMount() {
    const { user } = this.props;
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
    });
    //get organization
    let organizations = await organizationService.getAllOrganizationsByUser(user.id);
    if (organizations && Object.values(organizations.organizations).length > 0) {
      let firstOrganization = (Object.values(organizations.organizations))[0].organization;
      this.setState({
        firstOrganization: firstOrganization
      })
    }
    this.getMessageNotification();

  }

  getMessageNotification = async () => {
    const { user } = this.props;

    const dashboard = await dashboardService.loadDashboard(user);
    this.setState({
      messageNotification: dashboard.new_Messages
    });

    // dashboard.action_Items.forEach((actionItem) => {
    //   if (actionItem.action_Type === "message-new") {
    //     this.setState({
    //       messageNotification: true
    //     })
    //   }
    // })
  }

  goToKB = () => {
    window.open("https://kb.opengrants.io/", "_blank");
  };

  truncate = (text) => {
    return text.length > 22 ? text.substring(0, 19) + "..." : text;
  };

  completeProfile = () => {
    window.location.href = pluginService.redirect('/grant-seeker/onboarding');
  }

  truncateName = (text) => {
    const { user } = this.props
    if (text.length <= 22) {
      return text
    }
    else {
      return user.name + " " + user.surname.charAt(0) + "."
    }
  }

  componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      if (location.pathname === "/messages") {
        this.setState({
          messageNotification: false
        })
      }
      // else {
      //   this.getMessageNotification()
      // }
    })
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const user = JSON.parse(localStorage.getItem("user"));

    let titleAndOrganization = (user.title ? user.title + " • " : "") + (this.state.firstOrganization.name ? this.state.firstOrganization.name : "No Organization")

    return (
      <div>
        {(user || !user) && (
          <div className="row">
            <div
              id="desktop-nav"
              style={{ height: "100px" }}
            >
              <div
                id="desktop-title"
                className="col-md-4 mt-auto mb-auto"
                onClick={() => { history.push(pluginService.redirect("/dashboard")); }}
                style={{ cursor: "pointer" }}
              >
                <img src={this.state.iFrameConfig?.logo || logo} alt="logo" style={{ width: this.state.iFrameConfig?.logo ? 'auto' : "200px", display: "inline", height: this.state.iFrameConfig?.logo ? '90px' : 'auto' }} />
              </div>
              <div className="col-md-8">
                <Row>
                  <Col
                    className="nav-left-links my-auto"
                    style={{ paddingRight: "25px" }}
                  >
                    {/* <NavLink
                    to="/messages"
                    id="navLinkApply"
                    style={{ float: "right", paddingRight: "25px" }}
                  >
                    <img src={calendar} alt="Calendar" />
                  </NavLink> */}
                    <NavLink
                      to={pluginService.redirect("/messages")}
                      id="navLinkApply"
                      style={{ float: "right", position: "relative", display: "flex", alignItems: "center" }}
                    >
                      <p style={{ marginRight: "10px", marginBottom: 4, fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "18px", color: "#2b2c2d" }}>Messages</p>
                      <img src={message} alt="Messages" />
                      {this.state.messageNotification ?
                        <div style={{ background: "red", borderRadius: "50%", width: 20, height: 20, lineHeight: "20px", position: "absolute", top: -6, right: 0, color: 'white' }}>{this.state.messageNotification}</div>
                        : null
                      }
                    </NavLink>
                  </Col>
                  <Col className="col-auto user-column">
                    {user && (
                      <div className="user-details">
                        <p className="user-details-header text-nowrap">

                          {!user.name && !user.surname && this.state.iFrameConfig?.url && (
                            <button type="button" onClick={() => window.location.href(pluginService.redirect(`/profile`))} className="btn"
                              style={{
                                color: this.state.iFrameConfig ? this.state.iFrameConfig?.primary : '#0565AA',
                                border: this.state.iFrameConfig ? `solid 1px ${this.state.iFrameConfig?.primary}` : 'solid 1px #0565AA',
                              }}
                            >
                              Complete Profile
                            </button>
                          )
                          }

                          {user.name && user.surname && (
                            `${user.name} ${' '} ${user.surname}`
                          )
                          }
                        </p >
                        {!this.state.iFrameConfig?.url && (
                          <p className="user-details-content text-nowrap">
                            {this.truncate(titleAndOrganization)}
                          </p>
                        )}
                        {/* {user.title && user.organization_Name == "" && (
                          <p className="user-details-content">{user.title}</p>
                        )}
                        {user.organization_Name != "" && !user.title && (
                          <p className="user-details-content">
                            {user.organization_Name}
                          </p>
                        )}
                        {user.organization_Name != "" && user.title && (
                          <p className="user-details-content">
                            {user.title} • {user.organization_Name}
                          </p>
                        )}
                        */}
                        <div className="dropdown-chevron">
                          <NavDropDown />
                        </div>
                      </div >
                    )
                    }
                  </Col >
                </Row >
              </div>
            </div >
          </div >
        )}
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedLoginPage = connect(mapStateToProps)(DesktopNavMenu);
export { connectedLoginPage as DesktopNavMenu };

