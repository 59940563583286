//this file is no longer in use ?

import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { pluginService } from '../../../_services/shared.service.js';

class AcceptedProfileList extends React.Component {

    render() {
        const { user, profile, transfer, org } = this.props;

        return (
            <div key={profile.email} className={`dFlex spaceBtw flexWrap ${ profile.hasOwnProperty("owner") ? "memberListForm" : "member" }`} >
                <div className="dFlex">
                    {!profile.headshot ? <div className="pfpCircle centerFlex" id="noPfpCircle">{profile.surname[0]}</div> : <img src={profile.headshot} className="pfpCircle" alt="profile circle" />}
                    <a href={pluginService.redirect("/profiles/" + profile.username)} target={pluginService.isIFrame() ? "_self" : "_blank"} rel="noopener noreferrer" className="centerFlex">
                        <div className="memberText ">
                            <h4 className="boxSubtitle noMargin">{profile.name} {profile.surname} {profile.owner ? "(owner)" : ""} </h4>
                            <p className="boxText noMargin">{profile.title}</p>
                        </div>
                    </a>
                </div>

                {!transfer && !profile.owner && profile.hasOwnProperty("owner") &&
                    <div className="dFlex memberButtons">
                        <Button onClick={() => { this.props.updateOrganizationOwner(user.id, profile.username, org.organization.organization_Id) }} color="info">
                            Transfer Ownership
                        </Button>
                        <Button className="removeBtn" onClick={() => { this.props.confirmOrganizationRequest(org.organization.organization_Id, user.id, profile.username, "Rejected") }}>
                            Remove
                        </Button>
                    </div>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedAcceptedProfileList = connect(mapStateToProps)(AcceptedProfileList);
export { connectedAcceptedProfileList as AcceptedProfileList };
