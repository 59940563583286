import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../../../../Styles/ServiceProvider_v2/Projects_v2.css';
import '../../../../Styles/SettingsStyles/Billings.css';
import { stripeActions } from '../../../../_actions';
import { pluginService, sharedService } from '../../../../_services';
import { Loader } from '../../../Shared/Loader';
import { PaymentMethodView } from '../../../Stripe/Stripe_Copy/PaymentMethod';
import { PaymentSelection } from '../../../Stripe/Stripe_Copy/PaymentMethod/PaymentSelection';

export const PaymentHandler = (props) => {
  const dispatch = useDispatch();

  const billingHandler = useSelector(state => state.billingHandler);

  const { billingInfo, org_Id } = props;

  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [despositsSubmitted, setDepositSubmitted] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  let accentColor = pluginService.getIFrameColor()

  useEffect(() => {
    dispatch(stripeActions.setPaymentHandler(handleSubmit));
  }, []);

  useEffect(() => {
    //bank verification
    if (!despositsSubmitted && billingInfo.loading_bankRequest) { //is verifying bank
      setDepositSubmitted(true);
      setLoading('verifying');
    } else if (despositsSubmitted && !billingInfo.loading_bankRequest) { //bank verification finished
      let open = openSubmission;
      if (sharedService.bankIsVerified(billingInfo.bank_Account)) {
        open = true;
      }

      setOpenSubmission(open);
      setDepositSubmitted(false);
      setLoading(false);
    }
  }, [billingInfo.loading_bankRequest])

  function edit_payment_information(isEditingPayment) {
    if (!isEditingPayment) {
      dispatch(stripeActions.requestUpdatePaymentMethod(true, sharedService.getPaymentSelection(billingInfo)));
    }
    setIsEditingPayment(isEditingPayment);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setErrorMessage(false);

    setLoading('verifying');

    const key = org_Id ? org_Id : 'user';

    //payment is valid
    let valid_payment =
      (isEditingPayment  //updating car
        || !sharedService.hasPaymentMethod(billingInfo)) //adding card
        ? await billingHandler.isPaymentValid[key]() : 'none';

    //address is valid
    let valid_address = 'none';
    // if (billingInfo.paymentSelection === "card" && //is card
    //   (profileInformationEdit //updating address
    //     || !sharedService.hasBillingAddress(billingInfo))) //adding address
    // {
    //   valid_address = await billingHandler.isAddressValid[key]();
    // }

    //add/update payment/address
    if (valid_payment && valid_address) {
      //update payment
      let billingInfo_result = billingInfo;
      if (valid_payment !== 'none') {
        billingInfo_result = await billingHandler.submitPayment[key](valid_payment);
      }

      //update address
      if (valid_address != 'none') {
        const billingAddress_result = await billingHandler.submitAddress[key](valid_address)
        billingInfo_result.billingAddress = billingAddress_result;
      }

      // if (sharedService.hasValidPaymentMethod(billingInfo_result)) {
      //   setIsEditingPayment(false);
      // }

      if (sharedService.hasValidPaymentMethod(billingInfo_result) && props.submitButton) {
        props.submitButton(e);
      }
    }

    setLoading(false);
  }

  return (
    <div>
      {loading && <Loader />}

      {/* Display Payment Method */}
      <div>
        {sharedService.hasPaymentMethod(props.billingInfo) &&

          <div >

            {sharedService.hasPaymentMethod(billingInfo) &&
              (!isEditingPayment ?
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => edit_payment_information(true)} color="info">
                    <i className="icon-pencil-icon" />Edit
                  </a>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => { edit_payment_information(false); setErrorMessage(false) }} color="info">
                    <i className="icon-pencil-icon" />Cancel
                  </a>
                </div>
              )
            }
          </div>

        }

        {(isEditingPayment || !sharedService.hasPaymentMethod(billingInfo)) ?
          <PaymentSelection
            type={props.type}
            org_Id={props.org_Id}
            billingInfo={billingInfo}
            regularStyling={true}
            showErrorMessage={(value) => { setErrorMessage(value) }}
            additionalAction={() => { setIsEditingPayment(false) }}
          />
          :
          <PaymentMethodView
            type={props.type}
            org_Id={props.org_Id}
            billingInfo={billingInfo}
            regularStyling={true}
          />
        }
      </div>

      {/* Billing Address */}
      {/* {paymentSelection === "card" &&
        <>
          {sharedService.hasBillingAddress(billingInfo) &&
            <div className="row" style={{ marginBottom: '1%' }}>
              {sharedService.hasBillingAddress(billingInfo) &&
                <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  {!profileInformationEdit ?
                    <button className="backToProjects" onClick={(e) => { e.preventDefault(); setProfileInformationEdit(true) }} color="info">
                      <i className="icon-pencil-icon" />Edit
                    </button>
                    :
                    <button className="backToProjects" onClick={(e) => { e.preventDefault(); setProfileInformationEdit(false) }} color="info">
                      <i className="icon-pencil-icon" />Cancel
                    </button>
                  }
                </div>}
            </div>}
          <Address
            regularStyling={true}
            org_Id={props.org_Id}
            editAddress={profileInformationEdit}
            billingInfo={billingInfo}
          />
        </>
      } */}

      {errorMessage &&
        <>
          <br />
          <p style={{ color: "red" }}>
            We're sorry, there was an issue processing your payment.
            Please double check your payment details and try again.
            If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
          </p>
        </>
      }

      {/* Buttons */}
      {/* Payment Method Updated */}
      {(!openSubmission &&
        (isEditingPayment //editing
          || !sharedService.hasPaymentMethod(billingInfo) //no payment method
          || sharedService.hasValidPaymentMethod(billingInfo))) && //valid payment method
        <>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={props.cancelButton}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={(isEditingPayment || !sharedService.hasPaymentMethod(billingInfo)) ? handleSubmit : props.submitButton}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      }
      {/* Bank Verified */}
      {openSubmission &&
        <>
          <hr />
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={props.cancelButton}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={props.submitButton}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      }
    </div>
  )
}