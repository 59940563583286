import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../../../../Styles/SettingsStyles/Billings.css';
import '../../../../Styles/SettingsStyles/ChangePlan.css';
import { stripeActions } from '../../../../_actions';
import ChangeProfilePlanIcon from '../../../../_assets/img/ChangeProfilePlanIcon.png';
import { Loader } from '../../../Shared/Loader';

export const PlanSelection = (props) => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.setUser);
  const { plan } = useSelector(state => state.billingHandler);
  const [loading, setLoading] = useState(false);

  return (
    <div id="payment-form" style={{ marginLeft: '2.5%' }}>
      {loading && <Loader />}
      <br />
      <p className="acceptingProjectHeader">Choose your Plan! You can change it at any time.</p>
      <br />
      <div className="row">
        <div className="col-md-6 freeCard">
          <p className="freeCardHeader">Free</p>
          <p className="freeCardPrice">$0 USD</p>
          <hr />
          <p><i className="icon-ok checkIconMargin" /> Search Grants</p>
          <p><i className="icon-ok checkIconMargin" /> Hire Grant Writers</p>
          {/* <p><i className="icon-ok checkIconMargin" /> Weekly Office Hours</p> */}
          <p><i className="icon-ok checkIconMargin" /> DIY Guides</p>
          <p><i className="icon-ok checkIconMargin" /> Flag Grants</p>
          <p className="disabledFreeCard"><div className="proIcon">PRO</div> Get Email Alerts</p>
          <p className="disabledFreeCard"><div className="proIcon">PRO</div> Take Notes</p>
          <p className="disabledFreeCard"><div className="proIcon">PRO</div> Power Search</p>
          <p className="disabledFreeCard"><div className="proIcon">PRO</div> Export Results</p>
          <p className="disabledFreeCard"><div className="proIcon">PRO</div> Personalized Recommendations</p>
          <br />
          {(!plan?.name
            || (plan?.status === "incomplete"
              || plan?.status === "incomplete_expired"
              || plan?.status === "canceled")
          ) ?
            <button className="freeBtn" disabled style={{ opacity: '0.5' }}>
              Selected
            </button>
            :
            <button className="paidBtn" onClick={() => { setLoading(true); props.setPlan("Free") }}>
              Select
            </button>
          }
        </div>
        <div className="col-md-6 purchaseCard">
          <img className="ChangeProfilePlanIcon" src={ChangeProfilePlanIcon} alt="ChangeProfilePlanIcon" />
          <p className="freeCardHeader">Pro</p>
          <p className="freeCardPrice">$29 USD</p>
          <hr />
          <p><i className="icon-ok checkIconMargin" /> Search Grants</p>
          <p><i className="icon-ok checkIconMargin" /> Hire Grant Writers</p>
          {/* <p><i className="icon-ok checkIconMargin" /> Weekly Office Hours</p> */}
          <p><i className="icon-ok checkIconMargin" /> DIY Guides</p>
          <p><i className="icon-ok checkIconMargin" /> Flag Grants</p>
          <p><i className="icon-ok checkIconMargin" /> Get Email Alerts</p>
          <p><i className="icon-ok checkIconMargin" /> Take Notes</p>
          <p><i className="icon-ok checkIconMargin" /> Power Search</p>
          <p><i className="icon-ok checkIconMargin" /> Export Results</p>
          <p><i className="icon-ok checkIconMargin" /> Personalized Recommendations</p>
          <br />
          {(plan?.name === "Pro Plan"
            && !plan?.canceled
            && (plan?.status !== "incomplete_expired"
              && plan?.status !== "canceled")) ?
            <button className="freeBtn" style={{ opacity: '0.5' }} onClick={() => { setLoading(true); props.setPlan("Free") }}>
              Cancel
            </button>
            :
            plan?.name === "Pro Plan" && plan?.canceled ? <button className="freeBtn" style={{ opacity: '0.5' }} onClick={() => { setLoading(true); props.setPlan("Pro"); dispatch(stripeActions.renewPlanSubscription(user.id)); }}>
              Renew
            </button>
              :
              <button className="paidBtn" onClick={() => { setLoading(true); props.setPlan("Pro") }}>
                Select
              </button>
          }
        </div>
      </div>
    </div>
  );
}