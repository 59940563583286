import React from 'react';
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../../_components";
import { Error404Page } from "../../_pages/Error";
import { UserRedirect } from "../../_pages/Shared/Landing";
import { Matches } from "../../_pages/Matches";
import { ListingNotes } from "../../_pages/Listing";
import { PowerSearch_v2 } from "../../_pages/SearchListings_v2";
import { ProfileViewNav_v2, Work } from "../../_pages/Settings";
import { OrganizationRequest, OrganizationInvitation } from "../../_pages/Settings/OrganizationSettings";
import { SP_Onboarding_Success, Messages } from "../../_pages/ServiceProviders";
import { ServiceProvider_v2 } from "../../_pages/Marketplace";
import { MarketplaceSearch_v2 } from "../../_pages/Marketplace/MarketplaceSearch_v2";
import { Organization_v2 } from "../../_pages/Settings/OrganizationSettings";
import { CommonAppLanding } from "../../_pages/CommonApp/CommonAppLanding"
import { CommonApp } from '../../_pages/CommonApp/CommonApp';

//v2 imports
import { GC_Onboarding_v2 } from "../../_pages/ServiceProviders/Onboarding/GC_Onboarding_v2";
import { GS_Onboarding_v2 } from "../../_pages/ServiceProviders/Onboarding/GS_Onboarding_v2";
import { Favorites_v2 } from "../../_pages/Favorites";
import { InvoicesHours } from '../../_pages/InvoicesHours';
import DashboardView_OpenGrants_v2 from '../../_pages/Dashboard/DashboardView_OpenGrants_v2';
import { NLPrivateRoute } from '../../_components/NLPrivateRoute';
import GrantSeekerOnboarding from '../../_pages/Onboarding/GrantSeeker/Onboarding';
import { GrantSingle } from '../../_pages/Grants/GrantSingle';
import { GrantNavigator } from '../../_pages/GrantNavigator/GrantNavigator';
import { Checkout_v2 } from '../../_pages/Stripe/Stripe_Copy/Checkout';

export const Private_Routes = (props) => {

  return (
    <Switch>
      <Route exact path="/" component={UserRedirect} />

      <NLPrivateRoute exact path="/grant-seeker/onboarding" props={props} component={GrantSeekerOnboarding} />

      <PrivateRoute exact path="/dashboard" props={props} component={DashboardView_OpenGrants_v2} />

      {/* Checkout */}
      <PrivateRoute exact path="/checkout" props={props} component={Checkout_v2} />

      {/* Grants */}
      <PrivateRoute exact path="/grants/:listing_Id" props={props} component={GrantSingle} />
      <PrivateRoute exact path="/funders/:listing_Id" props={props} component={GrantSingle} />
      <PrivateRoute exact path="/matches" component={Matches} />
      <PrivateRoute exact path="/favorites" component={Favorites_v2} />
      <PrivateRoute exact path="/search" component={PowerSearch_v2} />
      <PrivateRoute exact path="/notes" component={ListingNotes} />

      {/* Profile */}
      {/* <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/settings/:area" component={Settings} />
          <PrivateRoute exact path="/managebilling" component={StripeManageBilling} /> */}
      <PrivateRoute exact path="/profiles/:username?" component={ProfileViewNav_v2} />

      {/* CommonApp Routes */}
      <Route exact path="/landing" component={CommonAppLanding} />
      <NLPrivateRoute exact path="/commonapp" component={CommonApp} />

      {/* Organization Routes */}
      <PrivateRoute exact path="/work" component={Work} />
      <PrivateRoute exact path="/organizations/:organizationId?" component={Organization_v2} />
      <PrivateRoute exact path="/organization_requests" component={OrganizationRequest} />
      <PrivateRoute exact path="/organization_invitations/:org_id?" component={OrganizationInvitation} />

      {/* Service Provider Routes */}
      {/* <PrivateRoute exact path="/sp/projects/:project?" component={ServiceProviderProjects_v2} />
      <PrivateRoute exact path="/sp/projects/marketplace/:project_id" component={ServiceProviderProjects_v2} />
      <PrivateRoute exact path="/sp/payout-methods" component={SP_Payout_Methods_HOC} /> */}
      {/* <PrivateRoute exact path="/sp/invoices" component={Invoices} /> */}
      <PrivateRoute exact path="/service-provider-onboarding" component={GC_Onboarding_v2} />
      <PrivateRoute exact path="/service-provider-success" component={SP_Onboarding_Success} />
      {/* <PrivateRoute exact path="/service-provider-projects" component={ServiceProviderProject} /> */}
      <PrivateRoute exact path="/service-provider/:serviceProviderId" component={ServiceProvider_v2} />

      {/* Client Dashboard */}
      <PrivateRoute exact path="/grant-seeker-onboarding/:org?" component={GS_Onboarding_v2} />
      {/* <PrivateRoute exact path="/cl/projects/marketplace/:project_id" component={ClientProjects_v2} />
      <PrivateRoute exact path="/cl/projects/:project?" component={ClientProjects_v2} /> */}
      {/* <PrivateRoute exact path="/cl/invoices" component={Invoices} /> */}

      <PrivateRoute exact path="/invoices-hours" component={InvoicesHours} />

      {/* Marketplace Routes */}
      <PrivateRoute exact path="/messages" component={Messages} />
      <PrivateRoute exact path="/marketplace" component={MarketplaceSearch_v2} />
      {/* <PrivateRoute exact path="/create-project" component={NewMarketplaceProject_v2} /> */}

      {/* Project Finder */}
      {/* <PrivateRoute exact path="/project-finder" component={ProjectFinder} />
      <PrivateRoute exact path="/project-finder/:projectId" component={ViewListing} />
      <PrivateRoute exact path="/create-project-listing" component={NewProjectListing} /> */}

      {/* AI Grant Helper */}
      <PrivateRoute exact path="/grant-navigator" component={GrantNavigator} />

      {/* 404 */}
      <Route exact path="*" component={Error404Page} />
    </Switch>
  )
}