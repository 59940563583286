import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { pluginService } from "../../../../_services";


export function ConfirmationModal(props) {
    let accentColor = pluginService.getIFrameColor()

    const acceptProject = () => {
        props.toggleConfirmationModal()
        props.toggleAcceptModal()
    }

    return (
        <>
            <Modal
                isOpen={props.isConfirmationOpen}
                centered={true}
                backdrop={true}
                toggle={() => props.toggleConfirmationModal()}
                fade={false}
                size="lg"
            >
                <ModalHeader
                    toggle={() => props.toggleConfirmationModal()}
                    style={{ border: 0, justifyContent: 'end' }}
                />
                <ModalBody className='modal-padding mb-48'>
                    <div className="row center">
                        <p style={{ fontWeight: 700, fontSize: 24, marginBottom: 40 }}>Are you sure you want to accept this proposal?</p>
                    </div>
                    {props.project.project_Proposals[0] && props.project.project_Proposals[0].project_Flat_Rate && <div className="row center">
                        <p style={{ marginBottom: 20 }}>If you accept the project, you will be charged immediately. OpenGrants will hold all funds in escrow until the project is complete. After work is completed, your service provider will mark the project as complete, and they will be paid.</p>
                    </div>}
                    <div className="row mobileBtnRow">
                        <div className="col-md-6" >
                            <button
                                type="button"
                                className="gc_started-button-back"
                                onClick={() => { props.toggleConfirmationModal() }}
                                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069', marginBottom: 0 }}
                            >
                                Go Back
                            </button>
                        </div>
                        <div className="col-md-6 " style={{ paddingBottom: 0 }}>
                            <button
                                type="button"
                                className="gc_started-button-next"
                                onClick={() => { acceptProject() }}
                                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069', marginBottom: 0 }}
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                </ModalBody >
            </Modal >
        </>
    )
}
