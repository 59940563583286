import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Component } from 'react';
import { connect } from 'react-redux';
import { pluginService, stripeService } from '../../../../_services';

let accentColor = pluginService.getIFrameColor()

class SP_Payout_Account_Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bank_Account: {},

      submitted: false,
      loading: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { bank_Account } = this.state;

    this.setState({
      bank_Account: {
        ...bank_Account,
        [name]: value
      }
    });
  }

  updatePaymentAccount = async () => {

    this.setState({
      loading: true,
      submitted: true,
      errorMessage: ''
    });

    const stripe = await loadStripe(window.stripeKey);
    const { service_Provider, stripe_Verified, stripe_Payout_Currency, user } = this.props;
    const { bank_Account } = this.state;

    if (!bank_Account.account_Holder_Name
      || !bank_Account.account_Holder_Type
      || (stripe_Payout_Currency === "cad" ? (!bank_Account.transit_Number || !bank_Account.institution_Number) : !bank_Account.routing_Number)
      || !bank_Account.account_Number) {

      this.setState({
        loading: false
      })

      return;
    }

    await stripe.createToken('bank_account', {
      country: stripe_Verified.country,
      currency: stripe_Verified.currency,
      routing_number: (stripe_Payout_Currency === "cad" ? (bank_Account.transit_Number.trim() + bank_Account.institution_Number.trim()) : bank_Account.routing_Number.trim()),
      account_number: bank_Account.account_Number.trim(),
      account_holder_name: bank_Account.account_Holder_Name,
      account_holder_type: bank_Account.account_Holder_Type,
    })
      .then(async (result) => {
        if (result.error) {
          this.setState({
            errorMessage: "Unable to update account. Please verify that your information is correct and re-submit.",
            loading: false
          })

          return;
        }
        await stripeService.updateStripeConnectPaymentAccount(service_Provider.stripe_Account_Id, result.token.id);
        window.location.href = pluginService.redirect('/profiles/' + user.userName + '?val=billing')
      });
  }

  render() {

    const { stripe_Payout_Account, stripe_Payout_Currency, cancelEdit } = this.props
    const { bank_Account, submitted, errorMessage } = this.state;

    return (
      <div>

        <div className="row paymentRow billingAddress">
          <div className="col-md-3 billingInfoLeftCol">
            <p>Bank Information</p>
          </div>
          <div className="col-md-5 billingInfoRightCol ">

            {/* Account Holder */}
            <input
              className="billingInput"
              placeholder='Account Holder Name'
              name="account_Holder_Name"
              value={bank_Account.account_Holder_Name}
              onChange={this.handleChange}
            />
            {submitted && !bank_Account.account_Holder_Name && <p className="defaultErrorMessage">Account Holder Name is required.</p>}

            {/* Account Type */}
            <select
              className="billingSelectInput"
              name="account_Holder_Type"
              value={bank_Account.account_Holder_Type}
              onChange={this.handleChange}
            >
              <option value={""}>Account Type</option>
              <option value={"Company"}>Company</option>
              <option value={"Individual"}>Individual</option>
            </select>
            {submitted && !bank_Account.account_Holder_Type && <p className="defaultErrorMessage">Account Type is required.</p>}

            {/* Account Number */}
            <input
              className="billingInput"
              placeholder='Account Number'
              name="account_Number"
              value={bank_Account.account_Number}
              onChange={this.handleChange}
            />
            {submitted && !bank_Account.account_Number && <p className="defaultErrorMessage">Account Number is required.</p>}

            {/* United States */}
            {(stripe_Payout_Currency === "usd") &&
              <>
                {/* Routing Number */}
                <input
                  className="billingInput"
                  placeholder='Routing Number'
                  name="routing_Number"
                  value={bank_Account.routing_Number}
                  onChange={this.handleChange}
                />
                {submitted && !bank_Account.routing_Number && <p className="defaultErrorMessage">Routing Number is required.</p>}
              </>
            }

            {/* Canada */}
            {(stripe_Payout_Currency === "cad") &&
              <>
                {/* Transit Number */}
                <input
                  className="billingInput"
                  placeholder='Transit Number'
                  name="transit_Number"
                  value={bank_Account.transit_Number}
                  onChange={this.handleChange}
                />
                {submitted && !bank_Account.transit_Number && <p className="defaultErrorMessage">Transit Number is required.</p>}

                {/* Institution Number */}
                <input
                  className="billingInput"
                  placeholder='Institution Number'
                  name="institution_Number"
                  value={bank_Account.institution_Number}
                  onChange={this.handleChange}
                />
                {submitted && !bank_Account.institution_Number && <p className="defaultErrorMessage">Institution Number is required.</p>}
              </>
            }
          </div>
        </div>

        {/* Submit/Cancel Buttons */}

        <div className="row">
          <div className="col-md-3 billingInfoLeftCol" style={{ marginTop: "15px" }} />
          <div className="col-md-5">

            <button className="billingButton" style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { this.updatePaymentAccount() }} >
              {!stripe_Payout_Account ? "Save Payout Method" : "Update"}
            </button>

            {stripe_Payout_Account &&
              <button className="billingButton secondary" style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { cancelEdit() }} >
                Cancel
              </button>
            }

            <p style={{ color: "red", marginTop: 10 }}>{errorMessage}</p>
          </div>
        </div>

      </div >
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { stripe_Verified } = state.stripeVerified;
  const { service_Provider } = state.serviceprovider_getserviceprovider;

  return {
    user,
    stripe_Verified,
    service_Provider
  };
}

const connectedSP_Payout_Account_Form = connect(mapStateToProps)(SP_Payout_Account_Form);
export { connectedSP_Payout_Account_Form as SP_Payout_Account_Form };

