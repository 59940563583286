import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const serviceProviderService = {
    registerServiceProvider,
    createServiceProviderOrganization,
    getServiceProvidersByUser,

    getServiceProvider,
    getServiceProviderById,
    getServiceProviders,
    updateSPAvailability,

    getAllClients,
    getClient,
    getClients,

    getMessageIdentity,
    getAllChannels,
    getAllOrganizationChannels,
    getOrganizationChannels,
    getChannelByServiceProvider,
    getChannelByOrgId,

    createProposal,
    updateProposal,
    updateProposalStatus,
    updateProposalView,

    getProjects,
    getAllProjects,
    getProject,
    createProject,
    updateProject,
    getAllClientProjectsAndProposals,
    getAllSPProjectsAndProposals,

    createWorkSegment,
    editWorkSegment,
    deleteWorkSegment,
    downloadDocument,

    addDocumentLinks,
    deleteDocumentLinks,
    addDocumentFiles,
    deleteDocumentFiles,
    submitTimeEntry,

    getServiceProviderCount,

    getProducts,
    createProduct,
    updateProduct,
    deleteProduct,
    purchaseProduct,
    getFeaturedServiceProviders,

    getMatches
};

function registerServiceProvider(service_Provider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(service_Provider)
    };

    return fetch(API_Route + '/api/serviceprovider/register_service_provider', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createServiceProviderOrganization(email, organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization/create_service_provider_organization?email=' + email, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProvidersByUser(user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider_by_user?user_id=' + user_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProvider(provider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(provider)
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProviderById(provider_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider_by_id?service_Provider_Id=' + provider_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProviders(client_organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_providers?client_organization_id=' + client_organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateSPAvailability(user_id, currently_available) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/update_service_provider_availability?user_id=' + user_id + '&currently_available=' + currently_available, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getAllClients(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_clients?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getClient(client) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(client)
    };

    return fetch(API_Route + '/api/serviceprovider/get-client', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getClients(provider_organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_clients?provider_organization_id=' + provider_organization_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getMessageIdentity() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_message_identity', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}



function getAllChannels(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_channels?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getAllOrganizationChannels(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_organization_channels', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getOrganizationChannels(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/serviceprovider/get_organization_channels', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getChannelByServiceProvider(user_id, provider_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/get_channel_by_service_provider?user_id=' + user_id + '&provider_id=' + provider_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getChannelByOrgId(user_id, org_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/get_channel_by_org_id?user_id=' + user_id + '&org_id=' + org_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createProposal(proposal) {
    // alert(JSON.stringify(proposal))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/create_proposal', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateProposal(proposal) {
    // alert(JSON.stringify(proposal))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/update_proposal', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateProposalStatus(proposal) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/update_proposal_status', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateProposalView(proposal) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/update_proposal_view', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllProjects(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_projects?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getProjects(provider_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_projects?provider_id=' + provider_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getProject(project_Id, user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_project?project_Id=' + project_Id + "&user_Id=" + user_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createProject(project) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/serviceprovider/create_project', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateProject(project) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/serviceprovider/update_project', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllClientProjectsAndProposals(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_client_projects?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllSPProjectsAndProposals(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_service_provider_projects?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createWorkSegment(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/serviceprovider/create_work_segment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function editWorkSegment(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/serviceprovider/edit_work_segment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteWorkSegment(segment_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/delete_work_segment?segment_id=' + segment_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function submitTimeEntry(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/serviceprovider/submit_time_entry', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function downloadDocument(documentObj) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(documentObj)
    };

    return fetch(API_Route + '/api/serviceprovider/download_document_file', requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function addDocumentLinks(document_links) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(document_links)
    };

    return fetch(API_Route + '/api/serviceprovider/add_document_link', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteDocumentLinks(document_links) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(document_links)
    };

    return fetch(API_Route + '/api/serviceprovider/delete_document_link', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function addDocumentFiles(document_links) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(document_links)
    };

    return fetch(API_Route + '/api/serviceprovider/add_document_file', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteDocumentFiles(document_links) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(document_links)
    };

    return fetch(API_Route + '/api/serviceprovider/delete_document_file', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getServiceProviderCount() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider_count', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getFeaturedServiceProviders() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(API_Route + '/api/serviceprovider/get_featured_service_providers', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getProducts(service_Provider_Id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceproviderproduct/get_products/' + service_Provider_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createProduct(product) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(product)
    };

    return fetch(API_Route + '/api/serviceproviderproduct/create_product', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateProduct(product) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(product)
    };

    return fetch(API_Route + '/api/serviceproviderproduct/update_product', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteProduct(product_Id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(product_Id)
    };

    return fetch(API_Route + '/api/serviceproviderproduct/delete_product', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function purchaseProduct(product_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(product_Id)
    };

    return fetch(API_Route + '/api/serviceproviderproduct/purchase_product/' + product_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getMatches(user_id) {
    const requestOptions = {
        method: 'Get',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/matches?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}