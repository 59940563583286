import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import '../../../../Styles/GrantWriters.css';
import "../../../../Styles/ServiceProvidersFinishProject.css";
import { serviceProviderActions } from '../../../../_actions';
import gearIcon from '../../../../_assets/img/badge-check.png';
import { marketplaceProjectService, pluginService, serviceProviderService } from '../../../../_services';

let accentColor = pluginService.getIFrameColor()

class ServiceProvidersFinishProject_v2 extends React.Component {
    state = {
        project: this.props.project ? this.props.project :
            {
                project_Title: '',
                project_Description: ''
            },
        errors: {},
        submitted: false,
        view: 'view',
        is_Open: false,
        modal_Type: null,
    }

    async componentDidMount() { }

    changeView = (view) => {
        this.setState({
            view
        });
    }

    handle_input = (e) => {

        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            project: {
                ...this.state.project,
                [name]: value
            }
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let submitted = false;
        const { project_Title } = this.state.project;
        let errors = {
            project_title_error: false,
            project_description_error: false,
        };

        var form_is_valid = true;
        if (!project_Title) {
            errors.title_error = true;
            form_is_valid = false;
        }

        //TODO: organization
        if (form_is_valid) {
            //handle proposal submission
            let project = Object.assign({}, this.state.project);

            if (project.project_ID) {
                this.props.dispatch(serviceProviderActions.updateProject(project));
            }

            submitted = true;
        }

        this.setState({
            submitted,
            errors
        });
    }

    showModal(action) {
        this.setState({
            is_Open: true,
            modal_Type: action
        });
    }

    async completeProject(project) {
        let update_Project = project;

        if (project.applications || (project.project_Type === "fixed-product")) {
            update_Project.project_Status = "Complete";
            await marketplaceProjectService.update_project_status(update_Project)
        }
        else {
            update_Project.project.project_Status = 'completed';
            await serviceProviderService.updateProject(update_Project.project);
        }
        window.location.reload();
        this.setState({ is_Open: false });

    }

    async cancelProject(project) {

        let update_Project;

        if (this.props.mpProject) {
            update_Project = project
            update_Project.status = "Canceled";
            alert("Canceling mp project")
        }
        else {
            update_Project = project.project;
            update_Project.project_Status = 'canceled';
            await serviceProviderService.updateProject(update_Project);
        }

        window.location.reload();
        this.setState({ is_Open: false });
    }

    render() {
        const { view, submitted, is_Open, modal_Type } = this.state;
        const { project, type } = this.props;
        // let project_Finished = (project.project.project_Status == "active" || project.project.project_Status == "completed");

        return (
            <div>
                {submitted &&
                    <p>Form Submitted</p>
                }

                {view === 'view' &&
                    <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                        {type === "service_Provider" && (project?.project?.project_Status === "active" || project?.project_Status === "Active") &&
                            <button
                                className='defaultGreenButton'
                                onClick={() => { this.showModal("complete") }}
                                style={{ display: "flex", alignItems: "center", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                            >
                                <img src={gearIcon} height="18px" style={{ paddingRight: 5 }} alt="" />
                                Complete Project
                            </button>
                        }
                    </div>
                }
                <div>
                    <Modal
                        isOpen={is_Open}
                        centered={true}
                        fade={false}
                        size='xl'
                        id='listingPreviewModal'
                    >
                        <ModalHeader style={{ textAlign: 'center', borderBottom: 'none', fontWeight: 700, fontSize: 18 }}>
                            Are you sure you want to complete this project?
                        </ModalHeader>
                        <ModalFooter style={{ textAlign: 'center', borderTop: 'none' }}>
                            <button
                                style={{
                                    width: "20%",
                                    fontSize: 14,
                                    padding: '10px',
                                    marginRight: 15,
                                    borderRadius: 5,
                                    backgroundColor: '#ffff',
                                    fontWeight: 600,
                                    color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
                                }}
                                onClick={() => { this.setState({ is_Open: false }) }}
                            >
                                Cancel
                            </button>
                            <button
                                style={{
                                    width: "20%",
                                    fontSize: 14,
                                    padding: '10px',
                                    color: '#fff',
                                    borderRadius: 5,
                                    fontWeight: 600,
                                    backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069'
                                }}
                                onClick={modal_Type === "complete" ? () => { this.completeProject(project) } : () => { this.cancelProject(project) }}
                            >
                                {modal_Type === "complete" ? "Yes" : "Cancel Project"}
                            </button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedServiceProvidersFinishProject_v2 = connect(mapStateToProps)(ServiceProvidersFinishProject_v2);
export { connectedServiceProvidersFinishProject_v2 as ServiceProvidersFinishProject_v2 };
