import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Recommendation from '../Recommendations/Recommendation';
import RecommendationRatings from '../Recommendations/RecommendationRatings';
import { connect } from 'react-redux';
import TextTruncate from "react-text-truncate";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Messenger_v2 } from '.';
import '../../Styles/GrantWriters.css';
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import { organizationstripeActions, upgradeActions } from '../../_actions';
import Button from '../../_components/dashboardComponents/CustomButtons/Button';
import { asyncLocalStorage, organizationService, organizationStripeService, pluginService, serviceProviderService, sharedService } from '../../_services';
import { RejectedChatBox_v2 } from "../ServiceProviders/ServiceProviderProjects_v2/Components/RejectedChatBox_v2";
import { RejectedMessenger_v2 } from "../ServiceProviders/ServiceProviderProjects_v2/Components/RejectedMessenger_v2";
import { Organization } from '../Settings/OrganizationSettings';
import CreateOrganizationModal from '../Settings/OrganizationSettings/CreateOrganizationModal';
import { Loader as Loading } from '../Shared/Loader';
import { PaymentHandler } from '../Stripe/Stripe_Copy/PaymentMethod';

let accentColor = pluginService.getIFrameColor()

class ServiceProvider_v2 extends React.Component {

    constructor(props) {
        super(props);
        let service_Provider_Id;

        if (this.props.spId) {
            service_Provider_Id = this.props.spId
        } else {
            service_Provider_Id = window.location.pathname.split('/').pop();
        }

        this.state = {
            service_Provider_Id: service_Provider_Id,
            loading: true,

            display_no_org_alert: true,
            display_created_organization: true,

            conversations: [],
            conversation: null,

            channelMembers: [],
            member: null,

            show_Chat: false,
            hasOrg: true,

            submittedOrg: false,

            alertCopyLink: false,
            iFrameConfig: null,

            showCreateOrgModal: false,
            showBillingModal: false,
            selectedProduct: {},
            showMoreProductDescription: "",
            showPurchaseModal: false,
            showPurchaseError: false,
            showPurchaseSuccess: false,

        }

        // this.closeChatBox = this.closeChatBox.bind(this);
    }

    async componentDidMount() {
        const { user } = this.props
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });

        // get service provider info

        let service_Provider_Id;

        if (this.props.spId) {
            service_Provider_Id = this.props.spId
        }
        else {
            service_Provider_Id = window.location.pathname.split('/').pop();
        }

        const service_Provider = await serviceProviderService.getServiceProviderById(service_Provider_Id);

        // let products = await serviceProviderService.getProducts(123735074);

        this.setState({
            service_Provider: service_Provider,
            loading: false
        });

        // get channels
        let result = await serviceProviderService.getChannelByServiceProvider(user.id, service_Provider.service_Provider_Id);
        await this.setState({
            channelMembers: [result.channel]
        });

        // get organization members

        let orgMembers = await organizationService.getOrganization(user.id, service_Provider.organization.organization_Id)
        await this.setState({
            organizationMembers: orgMembers.accepted_profiles,
        });

        //check if user has an org
        let org = await organizationService.getFirstOrganizationByUser(user.id);

        if (org) {
            this.setState({
                userOrg: org,
                hasOrg: true,
            })
        }

        //get org billing info
        this.props.dispatch(organizationstripeActions.getOrgBillingInfo(user.id, org.organization_Id))
    }

    componentDidUpdate = async (prevState) => {
        if (prevState.submittedOrg !== this.state.submittedOrg) {

            if (this.state.submittedOrg === true) {

                let org = await organizationService.getAllOrganizationsByUser(this.props.user.id);

                if (org.organizations || Object.keys(org.organizations).length !== 0) {

                    this.setState({
                        hasOrg: true,
                        submittedOrg: false
                    });
                }
            }
        }
    }

    handleSubmittedOrg = () => {
        this.setState({
            submittedOrg: !this.state.submittedOrg
        })
    }

    open_chat = (status) => {
        this.setState({
            open_chat: status
        })
    }

    update_display_alert = (alert) => {
        this.setState({
            ...alert
        });
    }

    cancel_messaging = () => {
        window.location.reload();
    }

    componentWillUnmount() {
        if (this.conversationsClient)
            this.conversationsClient.shutdown();
    }

    changeView = (view) => {
        this.setState({
            view
        });
    }

    // Show an alert if grant link was copied
    linkCopied = () => {
        this.setState({ alertCopyLink: true })
        setTimeout(() => {
            this.setState({ alertCopyLink: false })
        }, 3000);
    }

    handlePurchaseProduct = (product) => {
        this.setState({
            selectedProduct: product,
            showPurchaseModal: true
        });
    }

    purchaseProduct = async () => {
        const { hasOrg, userOrg, selectedProduct } = this.state

        this.setState({
            loading: true,
            showPurchaseModal: false,
        })

        if (hasOrg) {

            let billingInfo = await organizationStripeService.getBillingInfo(userOrg.organization_Id)

            //purchase product
            if (billingInfo && sharedService.hasValidPaymentMethod(billingInfo.billingInfo)) {

                try {
                    await serviceProviderService.purchaseProduct(selectedProduct.product_Id)

                    this.setState({
                        showPurchaseError: false,
                        showPurchaseModal: false,
                        showPurchaseSuccess: true,
                        loading: false
                    })
                }
                catch {
                    this.setState({
                        showPurchaseModal: true,
                        showPurchaseError: true,
                        loading: false,
                    })

                    setTimeout(() => {
                        this.setState({
                            showPurchaseError: false
                        })
                    }, 5000);
                }
            }
            //add payment
            else {
                this.setState({
                    showBillingModal: true,
                    loading: false,
                })
            }
        }
        //create org
        else {
            this.setState({
                showCreateOrgModal: true
            })
        }
    }

    //Submit billing info and purchase product
    handleBillingInfoSubmit = () => {

        //purchase product
        this.purchaseProduct();

        //close billing modal
        this.setState({ showBillingModal: false })
    }

    render() {
        const { service_Provider, channelMembers, loading, showCreateOrgModal, showBillingModal, showMoreProductDescription, showPurchaseModal, showPurchaseSuccess } = this.state
        const { user, created_organization, no_organization, applicant } = this.props;

        let memberList = null;
        let i = 0;
        if (channelMembers.length > 0) {
            memberList = channelMembers.map((member) => {
                if (i < 1) {
                    // return first member of organization as message recipient
                    i++;
                    return (
                        <div key={member.username} className="MessageMeBtn">
                            <div className="marketPlaceBtn">
                                {service_Provider.organization && service_Provider.currently_Available === true ?
                                    <button
                                        onClick={() => this.open_chat(true)}
                                        className="defaultGreenButton"
                                        style={{ padding: "10px 20px", float: "right", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                    >
                                        <i class="icon-message-icon" />Hire Me
                                    </button>
                                    :
                                    <button
                                        disabled={true}
                                        className="defaultGreenButton"
                                        style={{ opacity: 0.25, float: "right", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                    >
                                        I'm currently unavailable.
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }
            });
        }

        let reversedRecs;

        if (service_Provider?.recommendations) {
            reversedRecs = service_Provider?.recommendations.slice().reverse();
        }

        return (
            <div>
                {loading &&
                    <Loading style={{ zIndex: 99999999 }} />
                }
                {!this.props.spId &&
                    <div className='fs-4'>
                        <strong><a href={pluginService.redirect("/marketplace")}><i class="icon-arrow-left" />Back to Talent Finder</a></strong>
                        <br /><br />
                    </div>
                }
                {service_Provider &&
                    <div id="marketplaceProfile">
                        <div id="mainSettings">
                            {!applicant &&
                                <>
                                    <div className="grantConsultantHeader" style={{ display: "flex", justifyContent: "space-between", paddingRight: 45 }}>
                                        <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>Grant Consultant</p>
                                        {!this.state.iFrameConfig?.url &&
                                            <div onClick={() => { this.linkCopied() }} className="copySpLink">
                                                <CopyToClipboard text={sharedService.generate_copy_link_sp(service_Provider)}>
                                                    <Button
                                                        style={{ padding: 0, marginTop: 0, color: '#0C7069', background: 'transparent', boxShadow: 'none', fontWeight: 'bold' }}
                                                        className="hitViewProfileBtn"
                                                    >
                                                        Copy Link to Profile <FontAwesomeIcon icon={faCopy} style={{ marginLeft: 5 }} />
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                        }
                                    </div>
                                    <hr style={{ margin: 0 }} />
                                    <br />
                                </>
                            }
                            <div className="row marketPlaceContainer">
                                <div className={applicant ? "applicant-modal-top" : "marketPlaceFlex"}>
                                    {applicant ?
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                            <img alt="organization logo" src={service_Provider.organization.logo_URL ? service_Provider.organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} />
                                            <p>{service_Provider.organization.name}</p>
                                        </div>
                                        :
                                        <>
                                            <div className="col-md-2" style={{ padding: 0 }}>
                                                <img alt="organization logo" className="marketplaceProfileImg" src={service_Provider.organization.logo_URL ? service_Provider.organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} width="75" height="75" />
                                            </div>
                                            <a className="col-md-7" href={pluginService.redirect("/organizations/" + service_Provider.organization.organization_Id)} style={{ float: 'left', display: 'flex', padding: 0 }} >
                                                <p className="marketplaceProfileName">{service_Provider.organization.name}</p>
                                            </a>
                                        </>
                                    }
                                    {!applicant &&
                                        <div className="col-md-3 marginLeftAuto" style={{ padding: 0 }}>
                                            {memberList}
                                            {this.state.open_chat && this.state.hasOrg ?

                                                <Messenger_v2
                                                    channel_type={"client"}
                                                    hide_channels={true}
                                                    organization_Id={service_Provider.organization_Id}
                                                    open_chat={this.state.open_chat}
                                                    close_chat={this.open_chat}
                                                    fromMarketplace={true}
                                                />
                                                :
                                                <CreateOrganizationModal
                                                    isOpen={this.state.open_chat}
                                                    openModal={this.open_chat}
                                                    handleSubmittedOrg={this.handleSubmittedOrg}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <br />
                            {applicant ?
                                <div className="grantConsultantHeader" style={{ display: "flex", justifyContent: "space-between", paddingRight: 45, borderBottom: "1px solid rgba(80, 81, 82, 0.25)" }}>
                                    <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>Details</p>
                                </div>
                                :
                                <div className='grantConsultantHeaderContainer'>
                                    {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("grant_Writer") && <p className="grantConsultantHeader">Grant Writer</p>}
                                    {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("startup_Advisor") && <p className="grantConsultantHeader">Startup Advisor</p>}
                                    {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("other_Industry_Expert") && <p className="grantConsultantHeader">Other Industry Expert</p>}
                                </div>
                            }

                            <div className="row">
                                <br />
                                <div className="row marketPlaceContainer">
                                    <div className="col-md-2 marketPlaceAboutHeader">
                                        <strong>About Me</strong>
                                    </div>
                                    <div className="col-md-9 marketPlaceAboutDescription">
                                        <p>{service_Provider.organization.description}</p>
                                    </div>
                                </div>
                                <br />
                                <br className="removeBr" />
                                <div className="row marketPlaceContainer">
                                    <div className="col-md-2 marketPlaceAboutHeader">
                                        <strong>Focus Area</strong>
                                    </div>
                                    <div class="col-md-9" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {service_Provider.sP_Focus && service_Provider.sP_Focus.map(focus => (
                                            <div className="tag" style={{ padding: "5px 10px" }}>
                                                <p style={{ display: "inline" }}>{focus.focus_Type.trim()}</p>
                                            </div>
                                        ))}
                                        {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("grant_Writer") &&
                                            <>
                                                {service_Provider.grant_Types.map((tag) => { return (tag.grant_Type) }).includes("foundation_Grants") &&
                                                    <div className="tag" style={{ padding: "5px 10px" }}>
                                                        <p style={{ display: "inline" }}>Foundation Grants</p>
                                                    </div>
                                                }
                                                {service_Provider.grant_Types.map((tag) => { return (tag.grant_Type) }).includes("government_Grants") &&
                                                    <div className="tag" style={{ padding: "5px 10px" }}>
                                                        <p style={{ display: "inline" }}>Government Grants</p>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br className="removeBr" />
                                <div className="row marketPlaceContainer">
                                    <div className="col-md-2 marketPlaceAboutHeader">
                                        <strong> Services </strong>
                                    </div>
                                    <div class="col-md-9" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {service_Provider.sP_Services && service_Provider.sP_Services.map(service => (
                                            <div className="tag" style={{ padding: "5px 10px" }}>
                                                <p style={{ display: "inline" }}>{service.service_Type.trim()}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br />
                                <br className="removeBr" />
                                {service_Provider &&
                                    <div className="row marketPlaceContainer">
                                        <div className="col-md-2 marketPlaceAboutHeader">
                                            <strong>Hourly Rate</strong>
                                        </div>
                                        <div className="col-md-9" style={{ padding: 0, color: accentColor || '#0C7069' }}>
                                            <p>${service_Provider.base_Hourly_Rate} USD</p>
                                        </div>
                                    </div>
                                }
                                <br className="removeBr" />

                                {/* RECOMMENDATIONS */}

                                <div className='mt-32'>
                                    <div className="grantConsultantHeader" style={{ marginBottom: 30, borderBottom: "1px solid lightgrey" }}>
                                        <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>Recommendations</p>
                                    </div>
                                    {service_Provider &&
                                        <div className="row marketPlaceContainer recommendation-container">

                                            {service_Provider.recommendations.length > 0 ?
                                                <RecommendationRatings recommendations={service_Provider.recommendations} />
                                                :
                                                <p>This service provider does not have any ratings yet.</p>
                                            }

                                            <div className="recommendation-scroll-container">
                                                {reversedRecs ?
                                                    reversedRecs.map(recommendation =>
                                                        <Recommendation recommendation={recommendation} location="profile" />
                                                    )
                                                    :
                                                    <p>This service provider does not have any recommendations yet.</p>
                                                }
                                            </div>

                                        </div>
                                    }
                                    <br />
                                </div>

                                {/* BACK TO APPLICANTS BUTTON */}
                                {applicant &&
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <br />
                                        <button className='defaultGreenButton' style={{ padding: "10px 15px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { this.props.viewservice_Provider() }}>
                                            <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} /> Back to All Applicants
                                        </button>
                                    </div>
                                }
                                <br />

                                {/* ARE YOU SURE MODAL */}

                                {showPurchaseModal &&
                                    <Modal
                                        isOpen={showPurchaseModal}
                                        centered={true}
                                        backdrop={true}
                                        fade={false}
                                        className="ModalDiv"
                                        size='xl'
                                    >
                                        <ModalHeader className="hoursDeleteModalHeader" style={{ padding: 25 }}>
                                            Are you sure you want to purchase this product?
                                        </ModalHeader>
                                        <ModalBody >
                                            <div style={{ textAlign: 'center', borderTop: 'none', marginBottom: 30, display: "flex", justifyContent: "center" }}>
                                                <button
                                                    className='defaultWhiteButton'
                                                    style={{ width: "30%", height: "42px", marginRight: 10 }}
                                                    onClick={() => { this.setState({ showPurchaseModal: false, selectedProduct: {} }) }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="defaultGreenButton"
                                                    style={{ width: "30%", height: "42px" }}
                                                    onClick={() => { this.purchaseProduct() }}
                                                >
                                                    Purchase
                                                </button>
                                            </div>

                                            {this.state.showPurchaseError &&
                                                <p className='defaultErrorMessage'>
                                                    We're sorry, there was an issue processing your purchase.
                                                    If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
                                                </p>
                                            }
                                        </ModalBody>
                                    </Modal>
                                }

                                {/* PURCHASE SUCCESS MODAL */}

                                {showPurchaseSuccess &&
                                    <Modal
                                        isOpen={showPurchaseSuccess}
                                        centered={true}
                                        backdrop={true}
                                        toggle={() => { this.setState({ showPurchaseSuccess: !showPurchaseSuccess }) }}
                                        fade={false}
                                        size="xl"
                                    >
                                        <ModalHeader toggle={() => { this.setState({ showPurchaseSuccess: !showPurchaseSuccess }) }} style={{ borderBottom: "none" }} />
                                        <ModalBody>
                                            <div className="row">
                                                <div className="row center">
                                                    <p className="rejectCard-header">Congratulations on your purchase!</p>
                                                </div>

                                                <div style={{ width: "80%", margin: "40px auto" }}>
                                                    <p style={{ fontWeight: "bold" }}> Here are the next steps: </p>
                                                    <ol style={{ fontSize: 16, lineHeight: 1.5 }}>
                                                        <li>Your payment will be held in escrow by OpenGrants until the project is complete.</li>
                                                        <li>We told {service_Provider.organization.name} that you purchased their product. They will contact you ASAP to gather more information and start work.</li>
                                                        <li>If you'd like, you can send the first message now. This might include details about your needs, what you want the outcome to be, and your contact information.</li>
                                                    </ol>
                                                </div>

                                                <RejectedMessenger_v2
                                                    channel_type={"project"}
                                                    organization_Id={service_Provider.organization.organization_Id}
                                                    service_Provider={service_Provider}
                                                >
                                                    <RejectedChatBox_v2
                                                        toggleRejection={() => { this.setState({ showPurchaseSuccess: !showPurchaseSuccess }) }}
                                                        productPurchase={true}
                                                    />
                                                </RejectedMessenger_v2>

                                            </div>
                                            <br />
                                        </ModalBody>
                                    </Modal>
                                }

                                {showCreateOrgModal &&
                                    <CreateOrganizationModal
                                        isOpen={showCreateOrgModal}
                                        openModal={(value) => { this.setState({ showCreateOrgModal: value }) }}
                                        handleSubmittedOrg={() => { this.handleSubmittedOrg() }}
                                    />
                                }

                                {showBillingModal &&
                                    <Modal
                                        isOpen={showBillingModal}
                                        centered={true}
                                        backdrop={true}
                                        toggle={() => { this.setState({ showBillingModal: !showBillingModal }) }}
                                        fade={false}
                                        size="xl"
                                    >
                                        <ModalHeader toggle={() => { this.setState({ showBillingModal: !showBillingModal }) }} style={{ borderBottom: "none" }} />
                                        <ModalBody className='modal-padding' style={{ paddingTop: 10 }}>
                                            <PaymentHandler
                                                type={'org'}
                                                org_Id={this.state.userOrg.organization_Id}
                                                billingInfo={this.props.billingInfo_org}
                                                cancelButton={() => { this.setState({ showBillingModal: !showBillingModal }) }}
                                                submitButton={() => { this.handleBillingInfoSubmit() }}
                                            />
                                        </ModalBody>
                                    </Modal>
                                }

                                {/*
                                <br className="removeBr" />
                                <p className="grantConsultantHeader">
                                    Service Provider(s)
                                </p>
                                <br />

                                {organizationMembers && organizationMembers.map(member => (
                                    <div className="serviceProviders">
                                        <div className="col-md-12" style={{ display: "flex", padding: "10px" }}>
                                            <img style={{ borderRadius: "50%", objectFit: "cover" }} src={member.headshot ? member.headshot : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} width="75" height="75" />
                                            <a href={"/profiles/" + member.username} style={{ display: "flex", alignItems: "center", marginLeft: "10px", fontWeight: "bold", fontSize: "16px" }}>{member.name} {member.surname}</a>
                                        </div>
                                    </div>
                                ))}

                                 */}
                            </div>
                        </div>
                    </div>
                }

                {
                    no_organization && !created_organization && this.props.block_messaging &&
                    <div id="createOrgStepBackground" style={{ zIndex: 1000 }}>
                        {this.state.display_no_org_alert &&
                            <div id="createOrgStep">
                                <p><strong>You must create you organization before messaging this service provider.</strong></p>
                                <br />
                                <Button color="info" onClick={() => this.update_display_alert({ display_no_org_alert: false })} style={{ marginRight: 20 }}>Continue</Button>
                                <Button onClick={this.cancel_messaging}>Cancel</Button>
                            </div>
                        }
                        {!this.state.display_no_org_alert &&
                            <div style={{
                                background: "white",
                                padding: "50px",
                                margin: 50
                            }}>
                                <Organization serviceProviderId={this.state.service_Provider_Id} />
                            </div>
                        }
                    </div>
                }

                {
                    created_organization && this.state.display_created_organization &&
                    <div id="createOrgStepBackground">
                        <div id="createOrgStep">
                            <p><strong>Thanks for creating an organization! <br />You may now message any service provider that you choose.</strong></p>
                            <br />
                            <Button color="info" onClick={() => this.update_display_alert({ display_created_organization: false })}>Close</Button>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { channel } = state.chatalert_setchannel;
    const { conversation } = state.chatalert_setconversation;
    const { billingInfo_org } = state
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    const { created_organization } = state.organization_createorganizationalert;
    const { no_organization } = state.organization_noorganizationalert;
    const { block_messaging } = state.organization_removemessageblock;

    return {
        user,
        channel,
        conversation,
        created_organization,
        no_organization,
        block_messaging,
        billingInfo_org,
        service_Provider
    };
}

const connectedServiceProvider_v2 = connect(mapStateToProps)(ServiceProvider_v2);
export { connectedServiceProvider_v2 as ServiceProvider_v2 };

