import React from 'react'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";
import '../../Styles/MarketplaceProject_v2/MarketplaceProjectList_v2.css';
import '../../Styles/MarketplaceSearch_v2/MarketplaceSearch_v2.css';
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import { history } from '../../_helpers';
import { pluginService, sharedService, stripeService } from '../../_services';
import StartTrackingTimeButton from "../Shared/Timer/StartTrackingTimeButton";
import { Hours, Invoices } from './';
import VendorBanner from './VendorBanner';

export const InvoicesHours = ({ singleProject, singleProjectId, isFlatRateMpProject, isMpProject, isFlatRateSpProject }) => {

  const { service_Provider } = useSelector(state => state.serviceprovider_getserviceprovider);

  const [currentSection, setCurrentSection] = useState('invoices');
  const [showVendorBanner, setShowVendorBanner] = useState(true);
  const [stripe_Verified, setStripe_Verified] = useState("");

  let accentColor = pluginService.getIFrameColor()

  useEffect(() => {

    //tab in the invoices/hours page
    let activeMainTab = window.location.href.split("tab=")[1];

    //secondary tab in the project page
    let activeSubTab = window.location.href.split("tab2=")[1]?.split("&")[0];

    if (activeMainTab === "hours" || activeSubTab === "hours") {
      setCurrentSection('hours')
    } else {
      setCurrentSection('invoices')
      window.history.pushState(null, null, window.location.pathname + "?type=" + (isMpProject ? "mpProject" : "spProject") + "&tab=hoursInvoices&tab2=invoices")

    }
  }, [])

  useEffect(() => {

    if (service_Provider) {

      const setVerified = async () => {
        const stripe_Verified = await stripeService.checkStripeConnectVerification(service_Provider.stripe_Account_Id);
        setStripe_Verified(stripe_Verified);
      }

      setVerified();
    }

  }, [service_Provider])

  function switchTab(tabName) {
    if (singleProjectId) {
      history.push(window.location.pathname + "?type=" + (isMpProject ? "mpProject" : "spProject") + "&tab=hoursInvoices&tab2=" + tabName)
    }
    else {
      history.push("/invoices-hours?tab=" + tabName);
    }
    setCurrentSection(tabName)
  }

  return (
    <div className="invoicesHours">
      <div className="row paymentRow">
        {(currentSection === 'hours') &&
          <>
            {singleProject &&
              <br />
            }
            <div className="col-md-12 payment-form-col">
              <p className="acceptingProjectHeader">Log New Hours</p>
              <br />
              <p style={{ fontFamily: "Lato" }}>
                Log your hours every week by Saturday at 11:59pm Pacific time. If you do not log hours in time, you will have to wait until the following week to record them. Your client will have until the following Thursday to review your hours and ask questions. They will be billed Thursday night, and you will receive funds. This may take 2-3 business days depending on your bank.
              </p>
              <br />
              <p style={{ fontFamily: "Lato" }}>
                <b>All you have to do is log your hours here and make sure they're correct. We will automatically generate your invoice(s) every Thursday night. Remember that OpenGrants takes a 10% fee, which allows us to maintain (and keep improving) this service.</b>
              </p>
              <br />
              {/* hours page */}
              {!singleProject &&
                <StartTrackingTimeButton />
              }
              {/* project page */}
              {singleProject && ((singleProject.project && singleProject.project.project_Status === "active") || singleProject.project_Status === "Active") &&
                <StartTrackingTimeButton />
              }
            </div>
          </>
        }

        {(currentSection === "invoices") &&
          <>
            {showVendorBanner && !singleProjectId &&
              <VendorBanner closeBanner={() => { setShowVendorBanner(false) }} />
            }

            {!isFlatRateMpProject && !isFlatRateSpProject &&
              <>
                <br />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div className="">
                    <p className="acceptingProjectHeader">Invoices</p>
                  </div>
                  <div className="">
                    {service_Provider &&
                      <>
                        <ReactTooltip
                          id='lognewhours'
                          className='projectTooltip'
                          place="left"
                          effect="solid"
                          multiline={true}
                          textColor='dark'
                          backgroundColor='white'
                          border={true}
                          borderColor='rgb(128, 128, 128)'
                        />
                        <button
                          className="logNewHours"
                          onClick={sharedService.isSPVerified(service_Provider, stripe_Verified) ? () => { switchTab("hours") } : () => { history.push("/service-provider-success") }}
                          data-tip={!sharedService.isSPVerified(service_Provider, stripe_Verified) ? "Cannot log new hours until verification is complete." : null}
                          data-for='lognewhours'
                        >
                          + Log New Hours
                        </button>
                      </>
                    }
                  </div>
                </div>
              </>
            }
            {!service_Provider &&
              <p style={{ fontFamily: "Lato", marginTop: 10 }}>
                Want more details? Message your expert to clarify what was billed, and to ask them to include more context on future invoices.
              </p>
            }
          </>
        }
      </div>

      {
        (currentSection !== "payment-method") && !isFlatRateMpProject && !isFlatRateSpProject &&
        <div className="row viewProjectHeaderRow" style={{ marginBottom: 20 }}>
          <div className="col-md-6 payment-form-col">
            {service_Provider &&
              <button
                className={`${currentSection === 'hours' ? 'acceptProjectTabsActive' : "acceptProjectTabs"}`} id="overview"
                style={currentSection === 'hours' ? { borderBottom: accentColor ? `2px solid ${accentColor}` : '2px solid #00C58E' } : {}}
                onClick={() => { switchTab("hours") }}
              >
                Hours
              </button>
            }
            <button
              className={`${currentSection === 'invoices' ? 'acceptProjectTabsActive' : "acceptProjectTabs"}`} id="messages"
              style={currentSection === 'invoices' ? { borderBottom: accentColor ? `2px solid ${accentColor}` : '2px solid #00C58E' } : {}}
              onClick={() => { switchTab("invoices") }}
            >
              Invoices
            </button>
          </div>
        </div>
      }
      <div>
        {currentSection === 'hours' ?
          <Hours
            singleProjectId={singleProjectId}
            isMpProject={isMpProject}
          />
          :
          <Invoices
            singleProject={singleProject}
            singleProjectId={singleProjectId}
            isMpProject={isMpProject}
          />
        }
      </div>
      <br />
    </div >
  );
}