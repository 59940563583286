import { Checkbox, FormControlLabel, FormGroup, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";

class GC_Onboarding_ContactTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
    }
  }
  render() {
    const { updateNav, service_Provider, handleSelectionChange, handleChange, handleSubmit, new_Organization, is_New_Org, other_referral } = this.props;
    
    const { isNotValid } = this.state;
    const nextPage = (e) => {
      this.setState({
        isNotValid: true
      });
      if (service_Provider.Opengrants_Terms_Conditions === true
        && (service_Provider.Referral //referral selected
          || (!service_Provider.Referral //no referral
            || (service_Provider.Referral === "Others" //other referrals
              && (!service_Provider.Other_Referral //empty other referrals
                || (service_Provider.Other_Referral //not empty other referrals
                  && service_Provider.Other_Referral.length < 60001)))))) //character limit
      {
        if (!is_New_Org || new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
          this.setState({
            isNotValid: false
          });
          onSubmit(e);
        } else {
          this.setState({
            isNotValid: false
          });
          updateNav("onboard_advancedSettings")
        }
      }
    }

    const onSubmit = async (e) => {
      await handleSubmit(e);
    }

    const submitBtn = () => {
      if (!is_New_Org || new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        return (
          <button
            className="gc_started-button-next"
            onClick={(e) => nextPage(e)}
          >
            Submit <i class="icon-arrow-right" />
          </button>
        )
      } else {
        return (
          <button
            className="gc_started-button-next"
            onClick={(e) => nextPage(e)}
          >
            Next <i class="icon-arrow-right" />
          </button>
        )
      }
    }

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ? "8 of 8" : "6 of 8"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ?
            <div className="col-md-10 complete-color" /> :
            <div className="col-md-10" >
              <div className="col-md-8 progress-color" />
              <div className="col-md-4 gray-progress-color" />
            </div>
          }
        </div>
        <div className="onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
            <h1 className="onboarding-form-header">Terms of Service</h1>
            <hr />
            <div>
              <p className="gc_onboarding-form-input-name-top">Agree to OpenGrants Terms of Service<span className="red-star">*</span></p>
              <div className="row">
                <p className="gc_onboarding-form-col gc_onboarding-form-input-name-top-secondary col-md-10">
                  I agree to OpenGrants Terms of Service. OpenGrants Terms of Service and Privacy Policy can be found here: <a href="https://www.opengrants.io/opengrants-legal/" target="_blank" rel="noopener noreferrer">https://www.opengrants.io/opengrants-legal/</a>
                </p>
              </div>
              <FormGroup>
                <div
                  style={{ paddingLeft: 15 }}
                  className="SP_Checkboxes"
                  id="OH_Checkbox"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.Opengrants_Terms_Conditions}
                        onChange={(e) => handleSelectionChange(e)}
                        name="Opengrants_Terms_Conditions"
                        id="Opengrants_Terms_Conditions"
                        style={{
                          marginleft: 0,
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          marginTop: "-10px",
                          // backgroundColor: "#CFDFFF",
                          display: "inline-table",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                </div>
              </FormGroup>
              {isNotValid && service_Provider.Opengrants_Terms_Conditions === false && (
                <p className="red-message">
                  You must accept OpenGrants Terms of Service.
                </p>
              )}
            </div>

            <hr />

            {/* REFERRAL */}
            <div className="row">
              <div className="col-md-6" style={{ padding: 0 }}>
                <div>
                  <p className="gc_onboarding-form-input-name-top">How Did You Hear About Us?</p>
                  <div>
                    <select
                      className="gc_onboarding-form-select"
                      name="Referral"
                      id="service-provider-standard-basic"
                      value={service_Provider.Referral}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="Learn Grant Writing">Learn Grant Writing</option>
                      <option value="Others">Other</option>
                    </select>
                  </div>

                </div>
                {service_Provider.Referral === "Others" && <>
                  <div>
                    <p className="gc_onboarding-form-input-name">Who Referred You?</p>
                    <div>
                      <input
                        className="gc_onboarding-form-input"
                        type="text"
                        id="service-provider-standard-basic"
                        name="Other_Referral"
                        value={other_referral}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {service_Provider.Other_Referral && service_Provider.Other_Referral.length > 60000 && (
                    <p className="red-message">
                      You've gone over the 60,000 character limit for referrals.
                      Please shorten your entry.
                    </p>
                  )}
                </>}
              </div>
            </div>
            <br />
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_professionalInfo")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              {submitBtn()}
            </div>
          </div>
          {isNotValid && (
            <p style={{ marginTop: 30, color: "red" }}>
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
          {this.props.registrationFailed &&
            <p style={{ marginTop: 30, color: "red" }}>
              We're sorry, there was an issue processing your submission. Please double check entered information and try again.
              If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
            </p>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_ContactTerms =
  connect(mapStateToProps)(GC_Onboarding_ContactTerms);
export { connectedGC_Onboarding_ContactTerms as GC_Onboarding_ContactTerms };

