import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../../../../Styles/NewCheckout.css';
import { stripeActions } from '../../../../_actions';
import stripeBanner from "../../../../_assets/img/stripe-banner-black.svg";
import { sharedService, stripeService } from '../../../../_services';
import { Loader } from '../../../Shared/Loader';
import { checkoutService } from '../PaymentMethod';
import { PaymentMethodView } from '../PaymentMethod/PaymentMethodView';
import { PaymentSelection } from '../PaymentMethod/PaymentSelection';
import { AddOns } from './StripeComponents/AddOns';
import { Cart } from './StripeComponents/Cart';
import { Coupon } from './StripeComponents/Coupon';
import { OrderSummary } from './StripeComponents/OrderSummary';
import { useHistory } from 'react-router';
import { history } from '../../../../_helpers';

export const Checkout_v2 = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.setUser);
    const billingHandler = useSelector(state => state.billingHandler);
    const billingInfo = useSelector(state => state.billingInfo);
    const purchaseProduct = useSelector(state => state.purchaseProduct);

    const [prices, setPrices] = useState('');
    const [addOns, setAddOns] = useState('');
    const [consolidatedProducts, setConsolidatedProducts] = useState('');
    const [consolidatedProductsAddOns, setConsolidatedProductsAddOns] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [stopSubmit, setStopSubmit] = useState(false)

    const [editPayment, setEditPayment] = useState(false);
    const [infoUpdated, setInfoUpdated] = useState(false);
    const [agreements, setAgreements] = useState(null)

    const [purchaseLoading, setPurchaseLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false);
    const isInitialMount2 = useRef(true);

    const [isPlacingOrder, setIsPlacingOrder] = useState(false);

    const init = async () => {
        await Promise.all([
            getBillingInfo(),
            getPlanSubscription()
        ]);
    }

    const getBillingInfo = () => {
        if (!billingInfo?.card?.cardNumber && !billingInfo?.bank_Account?.account_Number) {
            dispatch(stripeActions.getBillingInfo(user.id));
        }
    }

    const getPlanSubscription = async () => {
        if (!billingHandler.plan) {
            dispatch(stripeActions.getPlanSubscription(user.id));
        }
    }

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {

        if (!prices) {
            document.body.scrollTo(0, 0);

            const group = props.group || 'opengrants_premium default';
            let product_prices = "";

            let product_prices_add_ons = "";

            async function getPrices() {
                //get, sort and set main product
                if (group) {
                    product_prices = await stripeService.getPricesByGroup(group);
                }

                if (product_prices && product_prices.length > 0) {
                    product_prices = sortPrices(product_prices);
                    setPrices(product_prices);

                    let selectedPriceIds = [];
                    let selectedRecurringPriceIds = [];
                    let priceIds_new = { selectedPriceIds, selectedRecurringPriceIds };
                    const selected_price_ids = checkoutService.addToCart(priceIds_new, product_prices[0]);
                    dispatch(stripeActions.setPriceIds(selected_price_ids));
                }

                //get, sort and set add ons
                product_prices_add_ons = await stripeService.getPricesByGroup("add ons", [group]);
                if (product_prices_add_ons && product_prices_add_ons.length > 0) {
                    product_prices_add_ons = sortPrices(product_prices_add_ons);
                    setAddOns(product_prices_add_ons);

                    const consolidated_products_add_ons = consolidate(product_prices_add_ons);
                    setConsolidatedProductsAddOns(consolidated_products_add_ons);
                }

                let all_prices = [...product_prices, ...product_prices_add_ons];
                const consolidated_products = consolidate(all_prices);
                setConsolidatedProducts(consolidated_products);
            }
            getPrices();
        }

    }, [])

    //after the payment and billing information are done updating, get new props
    useEffect(() => {

        //skip first mount
        if (isInitialMount2.current) {
            isInitialMount2.current = false;
        }
        else if (infoUpdated === true) {
            dispatch(stripeActions.getBillingInfo(user.id));
        }

    }, [infoUpdated])

    //after the props are changed, submit purchase
    useEffect(() => {
        if (isPlacingOrder && sharedService.hasPaymentMethod(billingInfo)) {
            handleSubmit();
        }
    }, [billingInfo.billingAddress, billingInfo.card, billingInfo.bank_Account.account_Number])

    useEffect(() => {
        if (!purchaseLoading && purchaseProduct.loading) {
            setPurchaseLoading(true);
        } else if (purchaseLoading && !purchaseProduct.loading) {
            setLoading(false);

            //return
            if (purchaseProduct.purchaseSuccess) {
                if (props.returnPage) props.returnPage();
                else history.push('/dashboard');
            } else if (!purchaseProduct.purchaseSuccess) {
                setErrorMessage(true);
            }
        }

    }, [purchaseProduct.loading]);

    useEffect(() => {
        return dispatch(stripeActions.setCoupon(''));
    }, [])

    //sort prices - main, once, monthly, annually
    function sortPrices(product_prices) {
        if (!product_prices) {
            return [];
        }

        let defaultProductId = props.group || 'opengrants_premium default';

        return product_prices.sort((a, b) => {
            //main purchase
            if (defaultProductId !== "") {
                if (a.product.metadata["group"] === defaultProductId && b.product.metadata["group"] !== defaultProductId) {
                    return -1
                } else if (a.product.metadata["group"] !== defaultProductId && b.product.metadata["group"] === defaultProductId) {
                    return 1
                }
            }

            //add ons
            if (!a.recurring) return -1
            if (!b.recurring) return 1
            if (a.recurring && b.recurring) {
                if (a.recurring.interval === "month" && b.recurring.interval === "year") return -1
                if (a.recurring.interval === "year" && b.recurring.interval === "month") return 1
                return a.unit_amount - b.unit_amount;
            }
            if (a.recurring && !b.recurring)
                return 1
            if (!a.recurring && b.recurrig)
                return -1
            return a.unit_amount - b.unit_amount;
        });
    }

    //consolidate to products
    function consolidate(prices) {
        const consolidated_products = {};
        prices.forEach((price) => {
            if (price) {
                if (!consolidated_products[price.product.id]) {
                    consolidated_products[price.product.id] = [price];
                } else {
                    consolidated_products[price.product.id].push(price);
                }
            }
        });

        return consolidated_products;
    }

    //check if payment and billing info is updated
    const handleIsInfoUpdated = (value) => {
        setInfoUpdated(value)
    }

    const handleLoading = (value) => {
        setLoading(value);
    }

    //handle payment and billing address information before purchase
    const handleCheckoutInformation = async (agreements) => {
        if (!stopSubmit) setIsPlacingOrder(true);

        setAgreements(agreements)

        setLoading(true)

        if ((sharedService.hasCard(billingInfo) && !editPayment) //card exists, no edits
            || (sharedService.hasBank(billingInfo) && !editPayment) //bank exists, no edits
        ) {
            // setLoading(false);
            handleSubmit(agreements);
        }

        //edit or enter payment
        else if (editPayment || (!sharedService.hasCard(billingInfo) && !sharedService.hasBank(billingInfo))) {

            //check if entered payment is valid
            let valid_payment = await billingHandler.isPaymentValid['user']();

            if (valid_payment) {
                await billingHandler.submitPayment['user'](valid_payment);
                setErrorMessage(false)
            }
            else {
                setErrorMessage(true)
                setLoading(false)
            }
        }
    }

    const handleUpdateBank = async (e) => {
        e.preventDefault();
        setLoading(true);

        //check if entered payment is valid
        let valid_payment = await billingHandler.isPaymentValid['user']();

        if (valid_payment) {
            await billingHandler.submitPayment['user'](valid_payment);
            setErrorMessage(false)
        }
        else {
            setErrorMessage(true)
            setLoading(false)
        }
        setEditPayment(false);
        setLoading(false);
    }

    //handle checkout
    async function handleSubmit(agreements_param) {

        let isValid = true;

        //change forms to display
        setEditPayment(false)

        setSubmitted(true);

        let termsAndPrivacy_Agreement = false;
        let email_Agreement = false;

        //verify agreements
        if (agreements || agreements_param) {

            //check if bank is verified
            if ((billingInfo.bank_Account && Object.keys(billingInfo.bank_Account).length !== 0)
                && !(billingInfo.bank_Account.status === 'validated' || billingInfo.bank_Account.status === 'verified')
            ) {
                isValid = false;
            }

            termsAndPrivacy_Agreement = agreements?.terms_privacy_agreement || agreements_param?.terms_privacy_agreement;
            email_Agreement = agreements?.email_agreement || agreements_param?.email_agreement;

            //check if terms are checked
            if (!termsAndPrivacy_Agreement) {
                isValid = false;
            }
        }
        else {

            termsAndPrivacy_Agreement = billingHandler.agreements.terms_privacy_agreement;

            //check if terms are checked
            if (!termsAndPrivacy_Agreement) {
                isValid = false;
            }

            email_Agreement = billingHandler.agreements.email_agreement;
        }

        if (isValid === true) {

            // set purchases
            let priceIds = billingHandler.priceIds.selectedPriceIds.map((price) => price.id);
            let recurringPriceIds = billingHandler.priceIds.selectedRecurringPriceIds.map((price) => price.id);

            var productDetails = {
                userDetails: {
                    user_Id: user.id,
                    email_Agreement,
                    termsAndPrivacy_Agreement
                },
                productDetails: {
                    priceIds,
                    recurringPriceIds
                }
            };

            //add coupon
            let couponId = billingHandler.promoCode?.code;
            if (!couponId) {
                couponId = billingHandler.coupon?.id;
            }
            if (couponId) {
                productDetails.productDetails["couponId"] = couponId;
            }

            //dispatch purchase
            dispatch(stripeActions.purchaseProduct(productDetails, user));
        }
        handleIsInfoUpdated(false);
        setLoading(false);
    }

    return (
        <>
            {/* Load while updating payment and billing information */}
            {(loading || purchaseProduct.loading || billingInfo.loading_getbilling || billingHandler.loading_getPlan) && <Loader />}

            {!billingInfo.loading_getbilling &&
                !billingHandler.loading_getPlan &&
                < div className="row px-40 pb-16" style={{ marginTop: 10 }}>
                    <div className={"mb-24" + (billingHandler.plan ? "" : " col-lg-8")}>

                        {/* SERVICES */}

                        {billingHandler.plan ?
                            (<div className="checkoutBox">
                                <h2 className="checkoutHeading">Subscription</h2>
                                <p>{billingHandler.plan.amount}</p>
                            </div>) :
                            (<div className="checkoutBox" >
                                <div>
                                    <div>
                                        <h2 className="checkoutHeading">{props.upgradeModal ? "Checkout" : "Subscribe"}</h2>
                                        <Cart
                                            group={props.group || 'opengrants_premium default'}
                                            consolidatedProducts={consolidatedProducts}
                                        />
                                    </div>

                                    {(billingHandler.priceIds?.selectedPriceIds && billingHandler.priceIds?.selectedRecurringPriceIds) && //add ons exist
                                        ((billingHandler.priceIds?.selectedPriceIds.length + billingHandler.priceIds?.selectedRecurringPriceIds.length - 1)
                                            < Object.keys(consolidatedProductsAddOns).length) && //add ons not added to cart
                                        <div>
                                            <hr />
                                            <h2 className="checkoutHeading">Add-Ons</h2>
                                            <AddOns consolidatedProductsAddOns={consolidatedProductsAddOns} />
                                        </div>
                                    }
                                </div>
                                <hr />
                                <Coupon
                                    prices={prices}
                                    addOns={addOns}
                                />
                            </div>)
                        }

                        <br />

                        <div className="checkoutBox">
                            <h2 className="checkoutHeading row mb-24">Billing Information</h2>

                            {/* Display payment method */}

                            {(billingInfo.card.cardNumber || billingInfo.bank_Account.account_Number) && !editPayment &&
                                <div className='row paymentMethodRow'>
                                    <div className='col-md-10 paymentMethodCol' style={{ padding: 0 }}>
                                        <PaymentMethodView
                                            regularStyling={true}
                                            billingInfo={billingInfo}
                                            verifyBankAccount={stripeActions.verifyBankAccount}
                                            setStopSubmit={setStopSubmit}
                                        />
                                    </div>
                                    <div className='col-md-2 paymentMethodEditCol' style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <button className="checkoutEditButton" onClick={() => setEditPayment(true)}>
                                            <i className="icon-pencil-icon" />
                                        </button>
                                    </div>
                                </div>
                            }

                            {/* Enter/Edit payment method */}

                            {((Object.keys(billingInfo.card).length === 0 && (Object.keys(billingInfo.bank_Account).length === 0 || !billingInfo.bank_Account.account_Number)) || editPayment) &&
                                <div>
                                    {(Object.keys(billingInfo.card).length > 0 || (Object.keys(billingInfo.bank_Account).length > 0 && billingInfo.bank_Account.account_Number)) &&
                                        <div className='col-md-2 paymentMethodEditCol' style={{ float: 'right' }}>
                                            <a className="checkoutEditButton" onClick={() => {
                                                setEditPayment(false);
                                                // setSelectedPaymentMethod(sharedService.getPaymentSelection(billingInfo));
                                            }}>
                                                Cancel
                                            </a>
                                        </div>}
                                    <PaymentSelection
                                        type={'user'}
                                        updatePaymentWithServiceCall={true}
                                        regularStyling={true}
                                        handleIsInfoUpdated={handleIsInfoUpdated}
                                        handleLoading={handleLoading}
                                        billingInfo={billingInfo}
                                    />
                                    {billingHandler.plan &&
                                        (
                                            <button className="checkoutPrimaryButton placeOrderButton" onClick={(e) => { handleUpdateBank(e) }}>Update</button>
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    {!billingHandler.plan && <div className='col-lg-4'>

                        {/* ORDER SUMMARY */}

                        <div className="checkoutBox orderSummaryBox">
                            <OrderSummary handleSubmit={handleCheckoutInformation} />
                            {(submitted && !editPayment && sharedService.hasBank(billingInfo) && billingInfo.bank_Account.status !== "verified") &&
                                <p style={{ color: "red" }}>Please verify your bank.</p>
                            }
                            {errorMessage &&
                                <p style={{ color: "red" }}>We're sorry, there was an issue processing your payment. Please double check your payment details and try again. If you still have trouble, open the chat in the lower right of this screen to talk with our support team.</p>
                            }
                        </div>
                        <img src={stripeBanner} alt="stripe banner" height="60px" width="220px" style={{ marginTop: "10px", filter: "invert(81%) sepia(0%) saturate(1027%) hue-rotate(47deg) brightness(100%) contrast(92%)" }} />

                    </div>}

                </div >}
        </>
    );
}