import PropTypes from "prop-types";
import { createConnector } from "react-instantsearch-dom";

const connectDateRange = createConnector({
  displayName: "CustomConnectDateRange",

  propTypes: {
    attributes: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    }).isRequired,
    restrictResultsToStrictRange: PropTypes.bool
  },

  defaultProps: {
    restrictResultsToStrictRange: false
  },

  getProvidedProps(props, searchState) {
    return {
      currentRefinement: {
        from: searchState.dateRange && searchState.dateRange.from,
        to: searchState.dateRange && searchState.dateRange.to
      }
    };
  },

  refine(props, searchState, nextRefinement) {
    const nextDateRange = {};

    if (nextRefinement.from) {
      nextDateRange.from = nextRefinement.from;
    }

    if (nextRefinement.to) {
      nextDateRange.to = nextRefinement.to;
    }

    return {
      ...searchState,
      dateRange: nextDateRange
    };
  },

  cleanUp(props, searchState) {
    const { dateRange, ...rest } = searchState;

    return rest;
  },

  getSearchParameters(searchParameters, props, searchState) {
    const { dateRange = {} } = searchState;
    const { attributes, restrictResultsToStrictRange } = props;

    if (dateRange.from) {
      const lowerBound = restrictResultsToStrictRange
        ? attributes.from
        : attributes.to;

      searchParameters = searchParameters.addNumericRefinement(
        lowerBound,
        ">=",
        (dateRange.from / 1000)
      );
    }

    if (dateRange.to) {
      const upperBound = restrictResultsToStrictRange
        ? attributes.from
        : attributes.to;

      searchParameters = searchParameters.addNumericRefinement(
        upperBound,
        "<=",
        (dateRange.to / 1000)
      );
    }

    return searchParameters;
  }
});

export default connectDateRange;
