import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticateActions } from '../_actions';
import { Layout } from '../_pages/Shared/Layout/Layout_v2';

export const PrivateRoute = ({ component: Component, props: Props, ...rest }) => (
    <Route {...rest} render={props => {
        const userCookie = document.cookie.split(';').filter(c => c.includes('user='));
        if (userCookie.length > 0) {
            if (!localStorage.getItem('user')) {
                const user = userCookie[0].split('=')[1];
                Props.props.dispatch(authenticateActions.setUser(JSON.parse(user)));
                localStorage.setItem('user', user);
            }

            document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.opengrants.io";
        }

        let isLoggedIn = false;
        let isTrialOver = false;
        const user = JSON.parse(localStorage.getItem("user"));

        if (localStorage.getItem('user') && user.token) {
            isLoggedIn = true;
            const userRoles = user.userRoles;
            const dateStartedTrial = new Date(user.date_Started_Trial);
            const dateTrialOver = new Date(dateStartedTrial.setDate(dateStartedTrial.getDate() + 7));
            const dateToday = new Date(new Date);
            if (userRoles.includes("Client_Free") && dateToday > dateTrialOver) {
                if (window.location.pathname !== '/checkout' &&
                    !window.location.pathname.includes('profiles') &&
                    !window.location.pathname.includes('organizations')
                ) {
                    isTrialOver = true;
                }
            }
        }

        return (
            isLoggedIn ?
                isTrialOver ?
                    <Redirect to={{ pathname: '/checkout', state: { from: props.location } }} /> :
                    <Layout><Component {...props} /></Layout> :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    }} />
)