import { timerConstants } from "../_constants";

export const minimizedTimerWidget = (state = false, action) => {
    switch (action.type) {
        case timerConstants.MINIMIZED_OPEN:
            return {
                isOpen: action.status,
            };
        case timerConstants.MINIMIZED_CLOSE:
            return {
                isOpen: action.status,
            };
        default:
            return state;
    }
};

export const fullTimerWidget = (state = false, action) => {
    switch (action.type) {
        case timerConstants.FULL_OPEN:
            return {
                isOpen: action.status,
            };
        case timerConstants.FULL_CLOSE:
            return {
                isOpen: action.status,
            };
        default:
            return state;
    }
};