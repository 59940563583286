import React, { Component } from 'react';
import { Image, Col } from 'react-bootstrap'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ProfileList } from '.';
import '../../Styles/SettingsStyles/Billings.css';
import '../../Styles/SettingsStyles/OrganizationViewEdit.css';
import '../../Styles/SettingsStyles/ProfileViewEdit.css';
import { authenticateActions } from '../../_actions';
import briefcaseIcon from '../../_assets/img/icons/briefcase.png';
import userIcon from '../../_assets/img/icons/user.png';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { asyncLocalStorage, authenticateService, organizationService, personalprofileService, pluginService, settingsService } from '../../_services';
import { Loader } from '../Shared/Loader';
import getCroppedImg from "./ImageCropFunction";
import { ProfileInfoFormPopUp_v2 } from "./ProfileInfoFormPopUp_v2";
import ogIconWhite from "../../_assets/img/og-icon-white.svg"
import { OrganizationInvitationList } from './OrganizationSettings/OrganizationInvitation/OrganizationInvitationList';

const striptags = require('striptags');

let genderOptions = ["Male", "Female", "Non-binary", "I prefer not to say", null];
let ethnicityOptions = ["White/Caucasian", "Asian-Eastern", "Asian-Indian", "Hispanic", "African-American", "Native-American", "Mixed race", "I prefer not to say", null]

let accentColor = pluginService.getIFrameColor()

let hoverStyle = {
    hover: {
        background: accentColor || "#0C7069"
    }
}

class ProfileViewEdit_v2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            originalUser: {},
            username: window.location.pathname.split('/').pop(),
            organizations: [],
            loading: true,
            openForm: false,
            submitted: false,
            submitting: false,
            updatedAlert: false,
            usernameError: "",
            submitSuccess: false,
            croppedAreaPixels: null,
            croppedImage: null,
            profileBasicsEdit: false,
            profileSocialEdit: false,
            profileDemographicsEdit: false,
            editingAllowed: false,
            showGenderInput: false,
            showEthnicityInput: false,
            iFrameConfig: null,
            hover: false,
            isEditing: "",
        }
    }

    componentDidMount = async () => {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });

        //Getting user profile data 
        await this.getUserInfo();

        //Setting custom image to state
        if (this.state.user.user_Image_Dimensions) {
            this.getCroppedImage(this.state.user.headshot, this.state.user.user_Image_Dimensions)
        }

        let otherGender = this.checkOther("gender", genderOptions, this.props.user)
        let otherEthnicity = this.checkOther("ethnicity", ethnicityOptions, this.props.user)

        this.setState({
            user: {
                ...this.state.user,
                gender: this.props.user.gender,
                ethnicity: this.props.user.ethnicity,
                disability: this.props.user.disability,
            },
            showGenderInput: !otherGender ? true : false,
            showEthnicityInput: !otherEthnicity ? true : false,
        })
    }

    //Getting user data depending on if profile is public or private
    getUserInfo = async () => {
        let { username } = this.state
        username = decodeURIComponent(username);
        if (username) {
            if (username === "profiles") {
                username = this.props.user.userName;
            }

            let result = await personalprofileService.getProfile(encodeURIComponent(username));
            let editingAllowed = (this.props.user.userName && username)
                && (this.props.user.userName === username);

            if (result) {
                if (editingAllowed) {
                    result.profile.id = this.props.user.id;
                }

                let same_as_org_logo = false;
                if (result.organizations //not null
                    && result.organizations.length > 0 //has organizations
                    && result.organizations[0].org_Type === "Individual/Sole Proprietor") { //is individual org
                    same_as_org_logo = true;
                }

                //if the user is public
                this.setState({
                    user: result.profile,
                    originalUser: result.profile,
                    organizations: result.organizations,
                    same_as_org_logo,
                    editingAllowed
                });
            } else {
                this.setState({
                    editingAllowed
                })
            }
        }
        this.setState({
            loading: false
        });
    }

    //Add HTTPS to URLs
    withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

    //Add confirmation notification
    cancel = async (sectionName) => {
        //reset profile state
        this.resetProfileState();
        this.setState({
            isEditing: sectionName ? sectionName : "",
            openForm: false,
        })

        localStorage.removeItem("fileBase64")
    }

    //Switch user profile to public or private
    handlePublicSwitch = async (e) => {
        const current_user = this.props.user;
        const { user } = this.state;
        let check = e.target.checked

        await personalprofileService.setPublicProfile(current_user.id, check);

        let tempUser = user;
        tempUser.public_Profile = check;
        current_user.public_Profile = check;
        localStorage.setItem('user', JSON.stringify(current_user));

        this.setState({
            user: tempUser
        });
    }

    //Event handler for fields
    handleChange = (event) => {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    //Reset profile when menu is closed
    resetProfileState = () => {
        const { originalUser } = this.state
        this.setState({
            user: originalUser,
            submitted: false,
            submitSuccess: false,
            usernameError: ""
        })
    }

    //Submit functionality
    submitProfile = async (event) => {

        const { user } = this.state

        await this.handleSubmit(event);

        //SetTimeout used for success or failure message in popup form
        if (this.state.submitSuccess) {
            this.setState({
                openForm: false,
                submitSuccess: false,
                submitted: false,
                isEditing: ""
            })
            window.history.pushState(null, null, `/profiles/${user.userName}`);
        }
        // document.scrollTo(0, 0);
    }

    submitCancelButtons = (sectionName) => {
        return (
            <div className="row paymentRow">
                <div className="col-md-3 flexColEnd" />
                <div className="col-md-5">
                    {this.state.isEditing === sectionName &&
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <button className='defaultGreenButton' onClick={(e) => this.submitProfile(e)} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                Update
                            </button>
                            <button className="defaultWhiteButton" onClick={() => this.cancel()} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} >
                                Cancel
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    //Passed down to save cropped image data
    setCroppedPixels = (pixels) => {
        this.setState({
            croppedAreaPixels: pixels
        })
    }

    //Creates cropped image and sets to state
    getCroppedImage = async (image, size) => {
        try {
            let tempImg = await getCroppedImg(image, size)
            this.setState({
                croppedImage: tempImg
            })
        } catch (err) { }
    }

    //Handle submit for form
    async handleSubmit(event) {

        event.preventDefault();

        const { user, username, croppedAreaPixels } = this.state;

        let updateUser = {};
        let isValid = true;

        this.setState({
            submitting: true,
            usernameError: "",
        });

        user.name = striptags(user.name);
        user.surname = striptags(user.surname);

        updateUser.user = {
            ...this.props.user,
            ...user
        };
        updateUser.file_string = localStorage.getItem('fileBase64');

        // if a profile image exists, get the dimensions
        if (updateUser.file_string) {
            updateUser.user.user_Image_Dimensions = croppedAreaPixels
            updateUser.user.user_Image_Dimensions.user_Id = user.id
        }

        // if (encodeURI(updateUser.user.userName) !== username && updateUser.user.userName !== username) {
        //     isValid = await authenticateService.checkUsername(updateUser.user.userName);
        //     updateUser.user.username_Custom = updateUser.user.userName;
        // }

        if (isValid) {
            // await dispatch(settingsActions.updateProfile(updateUser));
            await settingsService.updateProfile(updateUser);
            await this.props.dispatch(authenticateActions.setUser(updateUser.user));

            this.setState({
                submitted: true,
                submitting: false,
                updatedAlert: true,
                submitSuccess: true,
                usernameError: "",
                username: user.userName,
                originalUser: user,
            });

            //get the profile again with the changes
            let result = await personalprofileService.getProfile(user.userName);
            let localUser = JSON.parse(localStorage.getItem('user'));

            if (this.state.same_as_org_logo) {
                const organization = this.state.organizations[0];
                organization.logo_URL = localStorage.getItem('fileBase64');

                organizationService.updateOrganizationLogo(organization);
            }

            if (result) {
                //if the user is public
                this.setState({
                    // profile: result.profile,
                    user: {
                        ...this.state.user,
                        ...result.profile
                    }
                });
            }
            //if the user is private
            else {
                this.setState({
                    user: localUser,
                })
            }

            if (this.state.profileBasicsEdit) {
                this.editBasics();
            }
        }
        else {
            this.setState({
                submitted: true,
                submitting: false,
                usernameError: "This username already exists"
            });
        }

        setTimeout(() => {
            this.setState({
                updatedAlert: false,
            })
        }, 4000)

        this.getCroppedImage(this.state.user.headshot, this.state.user.user_Image_Dimensions)

        // document.body.scrollTo(0, 0);
    }

    handleEditing = (sectionName) => {
        if (this.state.isEditing === "") {
            this.setState({
                isEditing: sectionName,
            })
        }
        else {
            this.cancel(sectionName)
        }
    }

    handleRadioChange = (event) => {
        const { id, name, value } = event.target;
        const { user } = this.state;

        if (name === "gender" && id !== "gender_Other") {
            this.setState({
                showGenderInput: false,
            })
        }

        if (name === "ethnicity" && id !== "ethnicity_Other") {
            this.setState({
                showEthnicityInput: false,
            })
        }

        if (id === "gender_Other") {
            this.setState({
                showGenderInput: true,
            })

        }
        else if (id === "ethnicity_Other") {
            this.setState({
                showEthnicityInput: true,
            })
        }

        this.setState({
            user: {
                ...user,
                [name]: value,
            }
        })
    }

    checkOther = (optionType, optionArray, user) => {
        return optionArray.some((option) => user[optionType] === option)
    }

    completeProfile = () => {
        window.location.href = pluginService.redirect('/profile');
    }

    addToOrganizationList = (organization) => {
        this.setState({
            organizations: [...this.state.organizations, organization]
        });
    }

    render() {

        const {
            loading, organizations, user, username, privateUser, openForm,
            submitSuccess, submitted, usernameError, showGenderInput,
            showEthnicityInput, iFrameConfig, editingAllowed, isEditing, originalUser
        } = this.state;

        let organizationList = null;

        let otherGender = this.checkOther("gender", genderOptions, user)
        let otherEthnicity = this.checkOther("ethnicity", ethnicityOptions, user)

        //Displaying organizations
        if (organizations) {
            organizationList = organizations.map((organization) => {
                return (
                    <div className="row paymentRow" style={{ display: "flex" }}>
                        <div className="col-md-3 flexColEnd">
                            <div className="profileOrgPicture">
                                {organization.logo_URL ? <img src={organization.logo_URL} className="pfpCircle" alt="organization logo" /> : <img src={briefcaseIcon} alt="organization icon" style={{ width: "60px", height: "60px", border: accentColor ? `2px solid ${accentColor}` : "2px solid #0C7069", borderRadius: "50%" }} />}
                            </div>
                        </div>
                        <div className="col-md-5 mobileEditBtn cursor-pointer" onClick={() => window.location.href = pluginService.redirect("/organizations/" + organization.organization_Id)}>
                            <p className="boxSubtitle noMargin" style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }}>{organization.name}</p>
                            <p className="boxText noMargin">{!organization.description || organization.description.length < 55 ? organization.description : organization.description.substring(0, 55) + "..."}</p>
                        </div>
                    </div>
                )
            });
        }

        return (
            <div>
                {loading && <Loader />}
                {/* the user is not you and is not public */}
                {(!loading && username !== user.userName && username !== encodeURI(user.userName) && privateUser) ?
                    <ProfileList />
                    :
                    <div >
                        {/* the user is you or other person is public */}
                        <div id="mainContainer">
                            <div className="billingAddress">
                                <div className="row paymentRow" style={{ display: "flex" }}>
                                    <div className="col-md-2 flexColEnd">
                                        {user.userName === this.props.user.userName ?
                                            //your profile
                                            <div className="profileCircle" onClick={() => this.setState({ openForm: true })}
                                                onMouseEnter={() => {
                                                    this.setState({ hover: true })
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hover: false })
                                                }}
                                                style={{
                                                    ...(this.state.hover ? hoverStyle.hover : null)
                                                }}>
                                                {this.state.croppedImage &&
                                                    <img
                                                        src={this.state.croppedImage}
                                                        alt="profile circle"
                                                        className="profileCircle hoverImage"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                                {!this.state.croppedImage && user.headshot &&
                                                    <img
                                                        src={user.headshot}
                                                        alt="profile circle"
                                                        className="profileCircle hoverImage"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                                {!this.state.croppedImage && !user.headshot &&
                                                    <img
                                                        src={userIcon}
                                                        alt="user icon"
                                                        className="profileCircle hoverImage"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                                <p className="editImageText">Edit Image</p>
                                            </div>
                                            :
                                            //somebody else's profile
                                            <div className="profileCircle otherUser" style={{ border: "none" }}>
                                                {this.state.croppedImage &&
                                                    <img
                                                        src={this.state.croppedImage}
                                                        className="profileCircle"
                                                        alt="profile circle"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                                {!this.state.croppedImage && user.headshot &&
                                                    <img
                                                        src={user.headshot}
                                                        alt="profile circle"
                                                        className="profileCircle"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                                {!this.state.croppedImage && !user.headshot &&
                                                    <img
                                                        src={userIcon}
                                                        alt="user icon"
                                                        className="profileCircle"
                                                        style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="mobileEditBtn" style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 10 }}>
                                        {(!user?.name && !user?.surname) && (user.userName === this.props.user.userName) ? (
                                            <button type="button" onClick={() => this.completeProfile()} className="btn"
                                                style={{
                                                    color: this.state.iFrameConfig ? this.state.iFrameConfig?.primary : '#0565AA',
                                                    border: this.state.iFrameConfig ? `solid 1px ${this.state.iFrameConfig?.primary}` : 'solid 1px #0565AA',
                                                }}
                                            >
                                                Complete Profile
                                            </button>
                                        ) : (
                                            <p className="profileHeaderName">{originalUser.name && originalUser.surname ? <span>{originalUser.name} {originalUser.surname}</span> : "N/A"}</p>
                                        )}

                                        <div className='toggles'>

                                            {/* public profile toggle */}
                                            {this.state.editingAllowed &&
                                                <div className="centerFlex" id="togglePublic" style={{ marginBottom: 0 }}>
                                                    <FormControlLabel className="margin-vertical toggle-label"
                                                        control={
                                                            <Switch
                                                                checked={this.props.user.public_Profile}
                                                                onChange={(e) => this.handlePublicSwitch(e)}
                                                                color=""
                                                                style={this.props.user.public_Profile ? { color: "limegreen" } : { color: "grey" }}
                                                            />
                                                        }
                                                        label={this.props.user.public_Profile ? <span className="green boxSubtitle noMargin noBold" style={{ display: "inlineBlock", minWidth: 100 }}>Public Profile (for search engines like Google)</span> : <span className="red boxSubtitle noMargin noBold" style={{ display: "inlineBlock", minWidth: 100 }}>Private Profile (for search engines like Google)</span>}
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="billingAddress">
                                <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                    <div className="col-md-3" style={{ flex: "auto", padding: 0 }}>
                                        <p className="acceptingProjectHeader mobileFloatHeader">Basics</p>
                                    </div>
                                    <div className="col-md-5" />
                                    <div className="col-md-3 " style={{ flex: "auto", marginTop: '5px' }}>
                                        {editingAllowed && isEditing !== "basics" &&
                                            <a className="backToProjects" style={{ cursor: 'pointer', color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }} onClick={() => this.handleEditing("basics")} color="info">
                                                <i className="icon-pencil-icon" />Edit
                                            </a>
                                        }
                                    </div>
                                </div>
                                <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        User Type
                                    </div>
                                    <div className="col-md-5 mobileEditBtn">
                                        {this.props.service_Provider ? "Grant Expert" : "Grant Seeker"}
                                    </div>
                                </div>
                                <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        Full Name
                                    </div>
                                    <div className="col-md-5 mobileEditBtn">
                                        {isEditing === "basics" ?
                                            <>
                                                <input className="accountInput" id="name" name="name" defaultValue={user.name} required onChange={this.handleChange} />
                                                <input className="accountInput" id="surname" name="surname" defaultValue={user.surname} required onChange={this.handleChange} />
                                            </>
                                            :
                                            <>
                                                {user.name && user.surname ? <>{user.name} {user.surname}</> : "N/A"}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        Username
                                    </div>
                                    <div className="col-md-5 mobileEditBtn">
                                        {user.userName || 'N/A'}
                                    </div>
                                </div>




                                {/* {!this.props.service_Provider && <Button
                                        className="rounded-pill py-16 px-104"
                                        style={{ backgroundColor: "#29A79F" }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <a href='/landing' className="mb-0 me-24 text-white fw-bold">Get Pre-Qualified for Future Grants</a>
                                            <Image src={ogLogoSmall} style={{ height: "3rem" }} className="ms-24" />
                                        </div>
                                    </Button>} */}
                                {/* </div> */}
                                {/* <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        Title
                                    </div>
                                    <div className="col-md-5 mobileEditBtn">
                                        {isEditing === "basics" ?
                                            <input className="accountInput" name="title" defaultValue={user.title} onChange={this.handleChange} />
                                            :
                                            <>
                                                {user.title || 'N/A'}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        Personal Bio
                                    </div>
                                    <div className="col-md-5 mobileEditBtn">
                                        {isEditing === "basics" ?
                                            <textarea className="accountInput" id="profileBio" name="bio" defaultValue={user.bio} onChange={this.handleChange} />
                                            :
                                            <>
                                                {user.bio || 'N/A'}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row paymentRow">
                                    <div className="col-md-3 flexColEnd">
                                        Phone Number
                                    </div>
                                    {isEditing === "basics" ?
                                        <div className="col-md-5">
                                            <input className="billingInput" id="address_line1" name="phoneNumber" placeholder="Phone Number" value={user.phoneNumber} onChange={this.handleChange} required />
                                        </div>
                                        :
                                        <div className="col-md-5">
                                            <div style={{ display: 'block' }}>{user.phoneNumber || 'N/A'}</div>
                                        </div>
                                    }
                                </div> */}
                                {this.submitCancelButtons("basics")}
                            </div>

                            {!this.props.service_Provider && <>
                                <div className="billingAddress"><div className="row viewProfileHeader mb-24">
                                    <div className="col-md-3" style={{ flex: "auto", padding: 0 }}>
                                        <p className="acceptingProjectHeader mobileFloatHeader">Common App</p>
                                    </div>
                                    <div className="col-md-5" />
                                </div>
                                    <div className="row paymentRow text-center">
                                        <div className="justify-content-center d-flex">
                                            <div className="rounded-pill py-16 px-104" style={{ backgroundColor: "#29A79F" }}>
                                                <a href='/landing' className=" mb-0 me-24 text-white fw-bold">Update Full Profile in the Common App</a>
                                                <Image src={ogIconWhite} style={{ height: "3rem" }} className="ms-24" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {this.props.service_Provider &&
                                <>
                                    {/* CONTACT INFORMATION */}
                                    <div className="billingAddress">
                                        <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                            <div className="col-md-3" style={{ flex: "auto", padding: 0 }}>
                                                <p className="acceptingProjectHeader mobileFloatHeader">Social</p>
                                            </div>
                                            <div className="col-md-5" />
                                            <div className="col-md-3" style={{ flex: "auto", marginTop: '5px' }}>
                                                {editingAllowed && isEditing !== "socials" &&
                                                    <a className="backToProjects" style={{ cursor: 'pointer', color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }} onClick={() => this.handleEditing("socials")} color="info">
                                                        <i className="icon-pencil-icon" />Edit
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        <div className="row paymentRow socialLinksRow">
                                            <div className="col-md-3 flexColEnd">
                                                Twitter
                                            </div>
                                            <div className="col-md-5 mobileEditBtn">
                                                {isEditing === "socials" ?
                                                    <>
                                                        <input className="accountInput" placeholder="Paste the Link to your Twitter" name="twitter" defaultValue={user.twitter} onChange={this.handleChange} />
                                                    </>
                                                    :
                                                    <>
                                                        {user.twitter ? <a className="socialLink" href={this.withHttp(user.twitter)} target='_blank' rel="noopener noreferrer">Link to Twitter Account</a> : "N/A"}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row paymentRow socialLinksRow">
                                            <div className="col-md-3 flexColEnd">
                                                Linkedin
                                            </div>
                                            <div className="col-md-5 mobileEditBtn">
                                                {isEditing === "socials" ?
                                                    <>
                                                        <input className="accountInput" name="linkedin_Profile" placeholder="Paste the Link to your Linkedin" defaultValue={user.linkedin_Profile} onChange={this.handleChange} />
                                                    </>
                                                    :
                                                    <>
                                                        {user.linkedin_Profile ? <a className="socialLink" href={this.withHttp(user.linkedin_Profile)} target="_blank" rel="noopener noreferrer" >Link to Linkedin Profile</a> : "N/A"}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {this.submitCancelButtons("socials")}

                                    </div>

                                    {/* DEMOGRAPHICS */}

                                    {(user.userName === this.props.user.userName) &&

                                        <div className="billingAddress">
                                            <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                                <div className="col-md-3" style={{ flex: "auto", padding: 0 }}>
                                                    <p className="acceptingProjectHeader mobileFloatHeader">Demographics</p>
                                                </div>
                                                <div className="col-md-5" />
                                                <div className="col-md-3" style={{ flex: "auto", marginTop: '5px' }}>
                                                    {editingAllowed && isEditing !== "demographics" &&
                                                        <a className="backToProjects" style={{ cursor: 'pointer', color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }} onClick={() => this.handleEditing("demographics")} color="info">
                                                            <i className="icon-pencil-icon" />Edit
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row paymentRow socialLinksRow">
                                                <div className="col-md-3 flexColEnd">
                                                    Gender
                                                </div>
                                                <div className="col-md-5 mobileEditBtn">
                                                    {isEditing === "demographics" ?
                                                        <div>
                                                            <p className="gc_onboarding-form-textarea-name" style={{ marginTop: 0 }}>How would you describe your gender?</p>
                                                            <div>
                                                                <input checked={user.gender === "Male"} id="gender_Male" name="gender" value="Male" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="gender_Male" style={{ display: "inline-block" }}>Male</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.gender === "Female"} id="gender_Female" name="gender" value="Female" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="gender_Female" style={{ display: "inline-block" }}>Female</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.gender === "Non-binary"} id="gender_Non-binary" name="gender" value="Non-binary" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="gender_Non-binary" style={{ display: "inline-block" }}>Non-binary</label>
                                                            </div>
                                                            <div>
                                                                <input checked={!otherGender} id="gender_Other" name="gender" value="" type="radio" onChange={(e) => { this.handleRadioChange(e); }} style={{ width: "auto" }} />
                                                                <label for="gender_Other" style={{ display: "inline-block" }}>Other</label>
                                                            </div>
                                                            {showGenderInput &&
                                                                <>
                                                                    <input className="accountInput" id="gender_Other" name="gender" value={user.gender} type="text" onChange={(e) => { this.handleRadioChange(e) }} />

                                                                    {user.gender?.length > 50 &&
                                                                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                                                                    }
                                                                </>
                                                            }
                                                            <div>
                                                                <input checked={user.gender === "I prefer not to say"} id="gender_No_Say" name="gender" value="I prefer not to say" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="gender_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ display: 'block' }}>{this.props.user.gender || 'N/A'}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row paymentRow socialLinksRow">
                                                <div className="col-md-3 flexColEnd">
                                                    Ethnicity
                                                </div>
                                                <div className="col-md-5 mobileEditBtn">
                                                    {isEditing === "demographics" ?
                                                        <div>
                                                            <p className="gc_onboarding-form-textarea-name" style={{ marginTop: 0 }}>What is your ethnic background?</p>
                                                            <div>
                                                                <input checked={user.ethnicity === "White/Caucasian"} id="ethnicity_White/Caucasian" name="ethnicity" value="White/Caucasian" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_White/Caucasian" style={{ display: "inline-block" }}>White/Caucasian</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "Asian-Eastern"} id="ethnicity_Asian-Eastern" name="ethnicity" value="Asian-Eastern" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Asian-Eastern" style={{ display: "inline-block" }}>Asian-Eastern</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "Asian-Indian"} id="ethnicity_Asian-Indian" name="ethnicity" value="Asian-Indian" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Asian-Indian" style={{ display: "inline-block" }}>Asian-Indian</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "Hispanic"} id="ethnicity_Hispanic" name="ethnicity" value="Hispanic" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Hispanic" style={{ display: "inline-block" }}>Hispanic</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "African-American"} id="ethnicity_African-American" name="ethnicity" value="African-American" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_African-American" style={{ display: "inline-block" }}>African-American</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "Native-American"} id="ethnicity_Native-American" name="ethnicity" value="Native-American" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Native-American" style={{ display: "inline-block" }}>Native-American</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.ethnicity === "Mixed race"} id="ethnicity_Mixed_Race" name="ethnicity" value="Mixed race" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Mixed_Race" style={{ display: "inline-block" }}>Mixed race</label>
                                                            </div>
                                                            <div>
                                                                <input checked={!otherEthnicity} id="ethnicity_Other" name="ethnicity" value="" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_Other" style={{ display: "inline-block" }}>Other</label>
                                                            </div>
                                                            {showEthnicityInput &&
                                                                <>
                                                                    <input className="accountInput" id="ethnicity_Other" name="ethnicity" value={user.ethnicity} type="text" onChange={(e) => { this.handleRadioChange(e) }} />

                                                                    {user.ethnicity?.length > 50 &&
                                                                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                                                                    }
                                                                </>
                                                            }
                                                            <div>
                                                                <input checked={user.ethnicity === "I prefer not to say"} id="ethnicity_No_Say" name="ethnicity" value="I prefer not to say" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="ethnicity_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ display: 'block' }}>{this.props.user.ethnicity || 'N/A'}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row paymentRow socialLinksRow">
                                                <div className="col-md-3 flexColEnd">
                                                    Disability
                                                </div>
                                                <div className="col-md-5 mobileEditBtn">
                                                    {isEditing === "demographics" ?

                                                        <div>
                                                            <p className="gc_onboarding-form-textarea-name" style={{ marginTop: 0 }}>Would you consider yourself to have a disability?</p>
                                                            <div>
                                                                <input checked={user.disability === "Yes"} id="disability_Yes" name="disability" value="Yes" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="disability_Yes" style={{ display: "inline-block" }}>Yes</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.disability === "No"} id="disability_No" name="disability" value="No" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="disability_No" style={{ display: "inline-block" }}>No</label>
                                                            </div>
                                                            <div>
                                                                <input checked={user.disability === "I prefer not to say"} id="disability_No_Say" name="disability" value="I prefer not to say" type="radio" onChange={(e) => { this.handleRadioChange(e) }} style={{ width: "auto" }} />
                                                                <label for="disability_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ display: 'block' }}>
                                                            {this.props.user.disability || 'N/A'}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {this.submitCancelButtons("demographics")}
                                        </div>

                                    }

                                    {/* ---------------------------- ORGANIZATIONS ---------------------------- */}
                                    <div className="billingAddress">
                                        <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                                            <div className="col-md-5" style={{ padding: 0 }}>
                                                <p className="acceptingProjectHeader mobileFloatHeader">Organizations</p>
                                            </div>
                                            <div className="col-md-3" />
                                            {editingAllowed && organizationList.length === 0 &&
                                                <div className="col-md-3">
                                                    <a className="backToProjects" href={pluginService.redirect("/grant-seeker/onboarding")} color="info">
                                                        <i className="icon-pencil-icon" />Add
                                                    </a>
                                                </div>
                                            }
                                        </div>

                                        {organizationList.length !== 0 ?
                                            <>
                                                {organizationList}
                                            </>
                                            :
                                            <div className="row paymentRow">
                                                <div className="col-md-3 flexColEnd" />
                                                <div className="col-md-5 mobileEditBtn">
                                                    <p className="orgPageItemText">N/A</p>
                                                </div>
                                            </div>
                                        }
                                        {user.userName === this.props.user.userName &&
                                            <OrganizationInvitationList
                                                hideOrganization={true}
                                                addToOrganizationList={this.addToOrganizationList}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>

                        {/* ---------------------------- POPUP FORMS ---------------------------- */}
                        <div >
                            <Modal
                                id="listingPreviewModal"
                                isOpen={openForm}
                                fade={false}
                            >
                                <ModalHeader>
                                    <div className="centerFlex spaceBtw">
                                        <h4 className="boxTitle noMargin">Edit Profile</h4>
                                        <Button className="backButton" onClick={() => this.cancel()}>
                                            Back
                                        </Button>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    {submitted && !submitSuccess &&
                                        <p className="boxText red redBackground">{submitSuccess ? "" : "Error: Please Review Invalid Fields"}</p>
                                    }
                                    <ProfileInfoFormPopUp_v2
                                        user={user}
                                        organization={organizations && organizations.length > 0 ? organizations[0] : null}
                                        usernameError={usernameError}
                                        handleChange={this.handleChange}
                                        setCroppedPixels={this.setCroppedPixels}
                                        same_as_org_logo={this.state.same_as_org_logo}
                                        toggleSameFlag={() => { this.setState({ same_as_org_logo: !this.state.same_as_org_logo }) }}
                                    />
                                </ModalBody>

                                <ModalFooter className="centerFlex flexColumn">
                                    <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={(e) => this.submitProfile(e)}>
                                        Save Changes
                                    </button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        <div >
                            <Modal
                                id="listingPreviewModal"
                                isOpen={this.state.displayDeleteConfirmation}
                                fade={false}
                            >
                                <ModalHeader>
                                    <div className="centerFlex spaceBtw">
                                        <h4 className="boxTitle noMargin">Delete My Account</h4>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: 'center' }}>Are you sure you want to delete your account?</div>
                                </ModalBody>

                                <ModalFooter>
                                    <div style={{ display: 'flex', margin: '20px' }}>
                                        <button
                                            className='perkButton'
                                            onClick={this.displayDeleteConfirmation}
                                            style={{ flex: 1, margin: '15px', padding: '10px' }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='perkButton'
                                            onClick={this.deleteUser}
                                            style={{ flex: 1, margin: '15px', padding: '10px', backgroundColor: 'red', color: 'white' }}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    return ({
        user,
        service_Provider
    })
}

const connectedProfileViewEdit_v2 = connect(mapStateToProps)(ProfileViewEdit_v2);
export { connectedProfileViewEdit_v2 as ProfileViewEdit_v2 };
