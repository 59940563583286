import React from 'react';
import { Form, Button } from 'react-bootstrap'

const AmountOfFunding = ({ form, changeForm, handleNext, handleBack }) => {
  return (
    <>
      <Form className='w-100 mt-48'>             
        <p className='fw-bold mt-40'>Amount of funding requested</p>
        <Form.Control 
          type='number'
          name='amountOfFunding'
          value={form.amountOfFunding}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>Total funding received to-date</p>
        <Form.Control 
          type='number'
          name='totalFundingReceived'
          value={form.totalFundingReceived}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
      </Form>
      <div className='mt-80 px-xl-168 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default AmountOfFunding