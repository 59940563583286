export const notificationsConstants = {
    NOTIFICATIONS_GETALLNOTIFICATIONS_REQUEST: 'NOTIFICATIONS_GETALLNOTIFICATIONS_REQUEST',
    NOTIFICATIONS_GETALLNOTIFICATIONS_SUCCESS: 'NOTIFICATIONS_GETALLNOTIFICATIONS_SUCCESS',
    NOTIFICATIONS_GETALLNOTIFICATIONS_FAILURE: 'NOTIFICATIONS_GETALLNOTIFICATIONS_FAILURE',
    NOTIFICATIONS_GETALLNOTIFICATIONS_SEEN: 'NOTIFICATIONS_GETALLNOTIFICATIONS_SEEN',

    NOTIFICATIONS_MARKSEEN_REQUEST: 'NOTIFICATIONS_MARKSEEN_REQUEST',
    NOTIFICATIONS_MARKSEEN_SUCCESS: 'NOTIFICATIONS_MARKSEEN_SUCCESS',
    NOTIFICATIONS_MARKSEEN_FAILURE: 'NOTIFICATIONS_MARKSEEN_FAILURE',

    NOTIFICATIONS_MARKREAD_REQUEST: 'NOTIFICATIONS_MARKREAD_REQUEST',
    NOTIFICATIONS_MARKREAD_SUCCESS: 'NOTIFICATIONS_MARKREAD_SUCCESS',
    NOTIFICATIONS_MARKREAD_FAILURE: 'NOTIFICATIONS_MARKREAD_FAILURE',

    NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_REQUEST: 'NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_REQUEST',
    NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_SUCCESS: 'NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_SUCCESS',
    NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_FAILURE: 'NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_SUCCESS',

    NOTIFICATIONS_UPDATEEMAILNOTIFICATION_REQUEST: 'NOTIFICATIONS_UPDATEEMAILNOTIFICATION_REQUEST',
    NOTIFICATIONS_UPDATEEMAILNOTIFICATION_SUCCESS: 'NOTIFICATIONS_UPDATEEMAILNOTIFICATION_SUCCESS',
    NOTIFICATIONS_UPDATEEMAILNOTIFICATION_FAILURE: 'NOTIFICATIONS_UPDATEEMAILNOTIFICATION_FAILURE',
}