import { marketplaceprojectConstants } from '../_constants';
import { marketplaceProjectService } from '../_services';

export const marketplaceprojectActions = {
    getAllProjectByUser,
    getProject,
};

function getAllProjectByUser(user_id, expand) {
    return async dispatch => {
        dispatch(request());
        await marketplaceProjectService.get_all_projects(user_id, expand)
            .then(
                projects => dispatch(success(projects)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: marketplaceprojectConstants.GETALLPROJECTS_REQUEST } }
    function success(projects) { return { type: marketplaceprojectConstants.GETALLPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: marketplaceprojectConstants.GETALLPROJECTS_FAILURE, error } }
}

function getProject(project_id, expand) {
    return async dispatch => {
        dispatch(request());
        await marketplaceProjectService.get_project(project_id, expand)
            .then(
                project => { dispatch(success(project)) },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: marketplaceprojectConstants.GETPROJECT_REQUEST } }
    function success(project) { return { type: marketplaceprojectConstants.GETPROJECT_SUCCESS, project } }
    function failure(error) { return { type: marketplaceprojectConstants.GETPROJECT_FAILURE, error } }
}
