import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { sharedService } from '../../../../_services';
import { Previews } from "../../../Settings/ImageUpload.jsx";
import { NotificationSettings } from "../../../Settings/NotificationSettings";

class GC_Onboarding_Basics extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotValid: false,
      submitNotifications: false,
    }
  }

  checkOther = (optionType, optionArray) => {
    return optionArray.some((option) => this.props.user[optionType] === option)
  }

  render() {
    const {
      updateNav,
      service_Provider,
      user,
      handleChange,
      setCroppedPixels,
      setProfileImage_file,
      is_New_Org,
      handleRadioChange,
      showGenderInput,
      showEthnicityInput,
      genderOptions,
      ethnicityOptions,
      checkOther
    } = this.props;

    const { isNotValid } = this.state;

    const firstLetter = user.name?.charAt(0) || '';
    const lastLetter = user.surname?.charAt(0) || '';

    const nextPage = () => {
      this.setState({
        isNotValid: true,
        submitNotifications: !this.state.submitNotifications
      }, () => {

        if (user.name && user.surname && user.phoneNumber && service_Provider.Contact_Email && user.bio
          && sharedService.isInteger(user.phoneNumber)
          && (!user.gender || user.gender?.length <= 50)
          && (!user.ethnicity || user.ethnicity?.length <= 50)) {
          if (!is_New_Org) {
            this.setState({
              isNotValid: false,
            });
            updateNav("onboard_professionalInfo")
          }
          else {
            this.setState({
              isNotValid: false,
            });
            updateNav("onboard_organizationAttributes1")
          }
        }
      });
    }

    const removePreview = () => {
      this.setState({
        imageError: "",
        show_Logo: false,
      })
    }

    const imageUploadFailed = (msg) => {
      this.setState({
        imageError: msg,
        show_Logo: false,
      })

      setTimeout(() => {
        this.setState({
          imageError: "",
        })
      }, 6000);
    }

    let otherGender = checkOther("gender", genderOptions)
    let otherEthnicity = checkOther("ethnicity", ethnicityOptions)

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              1 of 8
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10" >
            <div className="col-md-3 progress-color" />
            <div className="col-md-9 gray-progress-color" />
          </div>
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header" >Basics</h1>
            <hr />
            <div className="row gc_onboarding-header">
              {(user.headshot || localStorage.getItem("fileBase64")) ?
                <div className="gc_onboarding-form-profile-pic-true" style={{ width: 250, height: 250 }}>
                  <img
                    width="100%"
                    height="100%"
                    src={localStorage.getItem("fileBase64") || user.headshot || ''}
                    style={{
                      borderRadius: '50%'
                    }}
                    alt="profile circle"
                  />
                  <div className="gc_onboarding_previews">
                    <div id="profileImage">
                      <Previews
                        removePreview={removePreview}
                        imageUploadFailed={imageUploadFailed}
                        setCroppedPixels={setCroppedPixels}
                        setProfileImage_file={setProfileImage_file}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="gc_onboarding-form-profile-pic" id="profileImage">
                  <div className="gc_onboarding_previews">
                    <div id="profileImage">
                      <Previews
                        removePreview={removePreview}
                        imageUploadFailed={imageUploadFailed}
                        setCroppedPixels={setCroppedPixels}
                        setProfileImage_file={setProfileImage_file}
                      />
                    </div>
                  </div>
                  <p className="gc_onboarding-form-profile-pic-name">{firstLetter}{lastLetter}</p>
                  <p className="gc_onboarding-form-profile-pic-plus">+</p>
                </div>
              }
            </div>
            <div className="row gc_onboarding-header">
              <p className="gc_onboarding-form-profile-pic-text">Add Photo or Logo</p>
            </div>
            <div>
              {this.state.imageError &&
                <p className="red-message" style={{ textAlign: "center" }}>{this.state.imageError}</p>
              }
            </div>
            {/* <div className="row gc_onboarding-header">
              {user.headshot ? <img width="50%" src={user.headshot} alt='' /> : null}
            </div> */}
            <div className="row">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name">
                  First Name <span className="red-star">*</span>
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  name="name"
                  disabled
                  id="organization-standard-basic"
                  value={user.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right">
                <p className="gc_onboarding-form-input-name">
                  Last Name <span className="red-star">*</span>
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  disabled
                  name="surname"
                  id="organization-standard-basic"
                  value={user.surname}
                  onChange={handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
            </div>
            <div className="row mobile-row">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name">
                  Phone Number <span className="red-star">*</span>
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  name="phoneNumber"
                  value={user.phoneNumber}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                />
                {isNotValid && !user.phoneNumber && (
                  <p className="red-message">
                    You must enter a contact number.
                  </p>
                )}
                {user.phoneNumber && !sharedService.isInteger(user.phoneNumber) && (
                  <p className="red-message">
                    You may only enter integers.
                  </p>
                )}
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right">
                {isNotValid && !service_Provider.Contact_Email && (
                  <p className="red-message">
                    You must enter the contact email for your organization.
                  </p>
                )}
                <p className="gc_onboarding-form-input-name">Primary Contact Email<span className="red-star">*</span></p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  disabled
                  id="service-provider-standard-basic"
                  name="Contact_Email"
                  value={service_Provider.Contact_Email}
                  onChange={handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 50,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 gc_onboarding-form-col">
                <div id="notification_container">
                  <NotificationSettings
                    onboarding={true}
                    userType={"Grant_Consultant"}
                    submitNotifications={this.state.submitNotifications}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 gc_onboarding-form-col">
                <div style={{ marginBottom: 10 }}>
                  <p className="gc_onboarding-form-textarea-name">
                    Personal Bio <span className="red-star">*</span>
                  </p>
                </div>
                <textarea
                  className="gc_onboarding-form-textarea"
                  name="bio"
                  value={user.bio}
                  onChange={handleChange}
                  multiline
                  rows={15}
                />
                {isNotValid && !user.bio && (
                  <p className="red-message">
                    You must provide a bio.
                  </p>
                )}
              </div>
            </div>
            <hr />

            <div>
              <p className="gc_onboarding-form-textarea-name">
                Demographics
              </p>
              <p className="gc_onboarding-form-input-name-top-secondary">
                The following demographic questions are optional and will be kept completely anonymous.
                Collecting this information helps us create a more diverse and inclusive community.
              </p>

              <div className="row">

                {/* GENDER */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">How would you describe your gender?</p>
                  <div>
                    <input checked={user.gender === "Male"} id="gender_Male" name="gender" value="Male" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Male" style={{ display: "inline-block" }}>Male</label>
                  </div>
                  <div>
                    <input checked={user.gender === "Female"} id="gender_Female" name="gender" value="Female" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Female" style={{ display: "inline-block" }}>Female</label>
                  </div>
                  <div>
                    <input checked={user.gender === "Non-binary"} id="gender_Non-binary" name="gender" value="Non-binary" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Non-binary" style={{ display: "inline-block" }}>Non-binary</label>
                  </div>
                  <div>
                    <input checked={!otherGender} id="gender_Other" name="gender" value="" type="radio" onChange={(e) => { handleRadioChange(e); }} style={{ width: "auto" }} />
                    <label for="gender_Other" style={{ display: "inline-block" }}>Other</label>
                  </div>
                  {showGenderInput &&
                    <>
                      <input className="gc_onboarding-form-input" id="gender_Other" name="gender" value={user.gender} type="text" onChange={(e) => { handleRadioChange(e) }} />

                      {user.gender?.length > 50 &&
                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                      }
                    </>
                  }
                  <div>
                    <input checked={user.gender === "I prefer not to say"} id="gender_No_Say" name="gender" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>

                </div>

                {/* ETHNIC BACKGROUND */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">What is your ethnic background?</p>
                  <div>
                    <input checked={user.ethnicity === "White/Caucasian"} id="ethnicity_White/Caucasian" name="ethnicity" value="White/Caucasian" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_White/Caucasian" style={{ display: "inline-block" }}>White/Caucasian</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Asian-Eastern"} id="ethnicity_Asian-Eastern" name="ethnicity" value="Asian-Eastern" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Asian-Eastern" style={{ display: "inline-block" }}>Asian-Eastern</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Asian-Indian"} id="ethnicity_Asian-Indian" name="ethnicity" value="Asian-Indian" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Asian-Indian" style={{ display: "inline-block" }}>Asian-Indian</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Hispanic"} id="ethnicity_Hispanic" name="ethnicity" value="Hispanic" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Hispanic" style={{ display: "inline-block" }}>Hispanic</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "African-American"} id="ethnicity_African-American" name="ethnicity" value="African-American" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_African-American" style={{ display: "inline-block" }}>African-American</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Native-American"} id="ethnicity_Native-American" name="ethnicity" value="Native-American" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Native-American" style={{ display: "inline-block" }}>Native-American</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Mixed race"} id="ethnicity_Mixed_Race" name="ethnicity" value="Mixed race" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Mixed_Race" style={{ display: "inline-block" }}>Mixed race</label>
                  </div>
                  <div>
                    <input checked={!otherEthnicity} id="ethnicity_Other" name="ethnicity" value="" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Other" style={{ display: "inline-block" }}>Other</label>
                  </div>
                  {showEthnicityInput &&
                    <>
                      <input className="gc_onboarding-form-input" id="ethnicity_Other" name="ethnicity" value={user.ethnicity} type="text" onChange={(e) => { handleRadioChange(e) }} />

                      {user.ethnicity?.length > 50 &&
                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                      }
                    </>
                  }
                  <div>
                    <input checked={user.ethnicity === "I prefer not to say"} id="ethnicity_No_Say" name="ethnicity" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>
                </div>

                {/* DISABILITY */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">Would you consider yourself to have a disability?</p>
                  <div>
                    <input checked={user.disability === "Yes"} id="disability_Yes" name="disability" value="Yes" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_Yes" style={{ display: "inline-block" }}>Yes</label>
                  </div>
                  <div>
                    <input checked={user.disability === "No"} id="disability_No" name="disability" value="No" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_No" style={{ display: "inline-block" }}>No</label>
                  </div>
                  <div>
                    <input checked={user.disability === "I prefer not to say"} id="disability_No_Say" name="disability" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>
                </div>
              </div>
            </div>


          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_intro")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={(e) => nextPage(e)}
              >
                Next <i class="icon-arrow-right" />
              </button>
            </div>
          </div>
          {isNotValid && (
            <p style={{ marginTop: 30, color: "red" }}>
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  // const { user } = state.setUser;
  // return {
  //   user,
  // };
}

const connectedGC_Onboarding_Basics =
  connect(mapStateToProps)(GC_Onboarding_Basics);
export { connectedGC_Onboarding_Basics as GC_Onboarding_Basics };

