import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'

const OrganizationName = ({ form, changeForm, handleNext, handleBack, setForm }) => {
  const [linkedInProfiles, setLinkInProfiles] = useState([])

  const handleAddLinkedIn = () => {
    setLinkInProfiles([
      ...linkedInProfiles,
      ''
    ])
  }

  const handleRemoveLinkedIn = () => {
    const updatedLinkedInArray = [...linkedInProfiles]
    updatedLinkedInArray.pop()
    setLinkInProfiles([...updatedLinkedInArray])
  }

  const handleChangeLinkedIn = (event) => {
    const idx = event.target.name
    const newData = [...linkedInProfiles]
    newData[Number(idx)] = event.target.value
    setLinkInProfiles(newData)
  }

  const handleUpdateFormAndNext = () => {
    const profilesSansBlanks = linkedInProfiles.filter(profile => !!profile.length)
    setForm({ ...form, linkedInProfiles: profilesSansBlanks })
    console.log(profilesSansBlanks)
    handleNext()
  }

  const handleZipChange = (e) => {
    let zip = e.target.value
    console.log(e.target.value)
    if (zip.length > 5) {
      zip = zip.slice(0, -1)
    }
    if (!zip) {
      zip = form.zip
    }
    setForm({
      ...form,
      zip,
    })
  }

  useEffect(() => {
    if (form.linkedInProfiles && form.linkedInProfiles.length) {
      setLinkInProfiles([...form.linkedInProfiles, ''])
    }
  }, [form.linkedInProfiles])

  return (
    <>
      <Form className='w-100 mt-48'>
        <p className='fw-bold'>Organization Name</p>
        <Form.Control
          type="text"
          name='orgName'
          value={form.orgName}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>Organization Website/LinkedIn</p>
        <Form.Control
          type="text"
          name='website'
          value={form.website}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40 mb-16'>Organization Address</p>
        <Row>
          <Col sm={6}>
            <p className='mb-8'>Country</p>
            {/* <Form.Control
              type="text"
              name='country'
              value={form.country}
              onChange={changeForm}
              className='mb-16 border border-2 border-light-gray'
            /> */}
            <CountryDropdown
              valueType='full'
              value={form.country}
              onChange={(updatedValue) => { changeForm({ target: { name: 'country', value: updatedValue }}); }}
              className='mb-16 border border-2 border-light-gray w-100'
              style={{ height: '3.35rem' }}
            />
          </Col>
          <Col sm={6}>
            <p className='mb-8'>Address</p>
            <Form.Control
              type="text"
              name='address'
              value={form.address}
              onChange={changeForm}
              className='mb-16 border border-2 border-light-gray'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className='mb-8'>City</p>
            <Form.Control
              type="text"
              name='city'
              value={form.city}
              onChange={changeForm}
              className='mb-16 border border-2 border-light-gray'
            />
          </Col>
          <Col sm={6}>
            <p className='mb-8'>State</p>
            {/* <Form.Control
              type="text"
              name='state'
              value={form.state}
              onChange={changeForm}
              className='mb-16 border border-2 border-light-gray'
            /> */}
            <RegionDropdown
              countryValueType="full"
              country={form.country}
              name='state'
              value={form.state}
              onChange={(updatedValue) => { changeForm({ target: { name: 'state', value: updatedValue }}); }}
              className='mb-16 border border-2 border-light-gray w-100'
              style={{ height: '3.35rem' }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className='mb-8'>Zip Code</p>
            <Form.Control
              type="number"
              name='zip'
              value={form.zip}
              onChange={handleZipChange}
              className='border border-2 border-light-gray'
            />
          </Col>
        </Row>
        <p className='fw-bold mt-40'>Organization Size</p>
        <Form.Control
          type="number"
          name='orgSize'
          value={form.orgSize}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        />
        <p className='fw-bold mt-40'>Add LinkedIn profiles of your leadership</p>
        {linkedInProfiles?.length > 0 && linkedInProfiles.map((person, idx) => (
          <div className='mt-40'>
            <Form.Control
              type="text"
              name={idx}
              value={linkedInProfiles[idx]}
              placeholder='LinkedIn Profile Url'
              onChange={handleChangeLinkedIn}
              className='border border-2 border-light-gray mt-24'
            />
          </div>
        ))}
        <div className='d-flex justify-content-end w-100 mt-8'>
          {linkedInProfiles.length > 1 &&
            <Button
              className='bg-transparent border-0 py-8'
              onClick={() => handleRemoveLinkedIn()}
            >
              <p className='mb-0'>Remove -</p>
            </Button>
          }
          <Button
            className='bg-transparent border-0 py-8 text-dark'
            onClick={() => handleAddLinkedIn()}
          >
            <p className='mb-0'>Add +</p>
          </Button>
        </div>
        <p className='fw-bold mt-40'>Organization Type</p>
        <Form.Select
          aria-label="Default select example"
          size='lg'
          name='orgType'
          value={form.orgType}
          onChange={changeForm}
          className='border border-2 border-light-gray'
        >
          <option value={null}>Please select one</option>
          <option value="Individual/Sole Proprietor">Individual/Sole Proprietor</option>
          <option value="Partnership">Partnership</option>
          <option value="LLC">LLC</option>
          <option value="Corporation">Corporation</option>
          <option value="Nonprofit">Nonprofit</option>
          <option value="State Government">State Government</option>
          <option value="Local Government">Local Government</option>
          <option value="Federal Government">Federal Government</option>
          <option value="Tribal Government">Tribal Government</option>
          <option value="Other">Other</option>
        </Form.Select>
      </Form>
      <div className='mt-80 px-xxl-104 px-xxxl-144 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
          onClick={() => handleUpdateFormAndNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default OrganizationName