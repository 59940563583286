import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../../Styles/SettingsStyles/ImageUpload.css';
import { acceptStyle, activeStyle, baseStyle, rejectStyle } from '../../../Styles/SettingsStyles/ImageUploadStyles.js';

let show = true;

export function Previews(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 5242880,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      acceptedFiles.forEach((file) => {
        imageUpload(file);
        props.removePreview();
      })

      fileRejections.forEach((file) => {
        let wrongType = file.type !== "image/jpeg" && file.type !== "image/png"
        props.imageUploadFailed(wrongType ? "The file type is incorrect. Previous image will be saved" : "The file exceeds maximum size. Previous image will be saved")
      });
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  const imageUpload = (file) => {
    getBase64(file).then(base64 => {
      localStorage.setItem('organizationLogo', base64);
      console.debug("file stored", base64);
      props.removePreview();
      show = true;
    }).catch(err => {
      show = false;
      props.imageUploadFailed("The file exceeds maximum size. Previous image will be saved")
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const thumbs = files.map(file => (
    <div className='previewThumb' key={file.name}>
      <div className='previewThumbInner'>
        <img
          id="previewThumbImg"
          style={{ borderRadius: "50%", objectFit: "cover", width: 200, height: 200 }}
          src={file.preview}
          alt="file preview"
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container" id="imgUploadContainer">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? "Drop your image here." : 'Click me or drag a file to upload!'}
      </div>
      <aside className='thumbsContainer' style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}>
        {show && thumbs}
      </aside>
    </section>
  );
}