import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { pluginService } from "../../../../_services";

let accentColor = pluginService.getIFrameColor()

class MembersForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPendingInvitations: false
        }
    }

    render() {
        const { user,
            invite_emailInput,
            accepted_profiles, accepted_profile_list,
            pending_profiles, pending_profile_list,
            pending_invitation_profiles,
            pending_invitation_list,
            transfer,
            org,
        } = this.props;

        const { organization } = org;

        return (
            <div>
                <div id="mainSettings" className='orgPageMembersForm'>

                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">{accepted_profiles ? accepted_profiles.length : 0} Member(s)</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {accepted_profile_list}
                        </div>
                    </div>

                    <br />

                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">{pending_invitation_profiles ? pending_invitation_profiles.length : 0} Pending Invitation(s)</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {!transfer &&
                                <div className="memberInviteButtons">
                                    <input
                                        className="orgPageTextField"
                                        name="invite_emailInput"
                                        value={invite_emailInput}
                                        placeholder="Email"
                                        onChange={this.props.handleNewOrganization}
                                        style={{ width: "70%", marginTop: 0 }}
                                    />
                                    <button
                                        className='defaultGreenButton'
                                        onClick={() => { this.props.inviteToJoinOrganization(user.id, organization.organization_Id, invite_emailInput) }}
                                        style={{ marginLeft: 10, width: "30%", height: 42, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                    >
                                        Invite
                                    </button>
                                </div>
                            }

                            {pending_invitation_profiles.length !== 0 &&
                                <a className='showMoreInvitations' onClick={() => { this.setState({ showPendingInvitations: !this.state.showPendingInvitations }) }}>{this.state.showPendingInvitations ? "Hide Pending Invitation(s)" : "Show Pending Invitation(s)"} </a>
                            }

                            {this.state.showPendingInvitations &&
                                <div style={{ marginTop: 20 }}>
                                    {pending_invitation_list}
                                </div>
                            }
                        </div>
                    </div>

                    <br />

                    <div className="row paymentRow pendingRequests">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">{pending_profiles ? pending_profiles.length : 0} Pending Request(s)</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {pending_profiles.length !== 0 ?
                                pending_profile_list
                                :
                                <p style={{ fontFamily: "Lato", marginBottom: 0 }}>There are no pending requests.</p>
                            }
                        </div>
                    </div>

                </div>
            </div >

        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedMembersForm = connect(mapStateToProps)(MembersForm);
export { connectedMembersForm as MembersForm };
