import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { pluginService } from '../../_services';

let accentColor = pluginService.getIFrameColor()

function setStarColor(rating, star) {
    if (star <= rating) {
        return "rgb(229,197,86)";
    } else {
        return "lightgrey";
    }
}

// Turn timestamp into this date format: "Jan 10, 2022"
function formatDate(timestamp) {
    var newDate = new Date(timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = newDate.getFullYear();
    var month = months[newDate.getMonth()];
    var date = newDate.getDate();

    return month + ' ' + date + ', ' + year;
};

export default function Recommendation({
    recommendation, location, open_recommendationForm, toggle_deleteRecommendationModal, type
}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    let toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <div className="row recommendationContainer">
            <div className="recommendationCard">

                <div className="recommendationHeader">

                    <div className='recommendationHeaderLeft'>
                        <div className="pfpCircle centerFlex" id="noPfpCircle" style={{ backgroundColor: accentColor || '#0C7069' }}>  {recommendation.client_Organization.name[0]}</div>
                        <div style={{ marginLeft: 10 }}>
                            <p style={{ marginBottom: 0 }}>{recommendation.client_Organization.name}</p>

                            <div className="recommendationRating">
                                <div className='recommendationStars'>
                                    <StarIcon id="1" style={{ fill: `${setStarColor(recommendation?.recommendation_Rating, 1)}` }}></StarIcon>
                                    <StarIcon id="2" style={{ fill: `${setStarColor(recommendation?.recommendation_Rating, 2)}` }}></StarIcon>
                                    <StarIcon id="3" style={{ fill: `${setStarColor(recommendation?.recommendation_Rating, 3)}` }}></StarIcon>
                                    <StarIcon id="4" style={{ fill: `${setStarColor(recommendation?.recommendation_Rating, 4)}` }}></StarIcon>
                                    <StarIcon id="5" style={{ fill: `${setStarColor(recommendation?.recommendation_Rating, 5)}` }}></StarIcon>
                                </div>
                                <h6 className="recommendationDate">{formatDate(recommendation?.date_Created)}</h6>
                            </div>
                        </div>
                    </div>


                    {location === "project" && type !== "service_Provider" &&
                        <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret style={{ background: "none", border: 0, color: 'black' }}>
                                <MoreVertIcon />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => open_recommendationForm(recommendation?.service_Provider_Id)} style={{ fontSize: "16px", color: "rgb(5, 101, 170)", textAlign: "center" }}>Edit</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => toggle_deleteRecommendationModal(true)} style={{ fontSize: "16px", color: "rgb(5, 101, 170)", textAlign: "center" }}>Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    }
                </div>

                <div className="recommendationInfo">
                    <h5 className="recommendationTitle">{recommendation?.recommendation_Title}</h5>
                    <h6 className="recommendationText">{recommendation?.recommendation_Body}</h6>
                </div>

            </div>
        </div>
    )
}
