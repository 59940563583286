import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const registrationService = {
    register,
    checkEmail,
    getAllLocations,
    confirmEmail,
    sendConfirmationEmail,
    getUserPartner,
};

function register(user, server, google) {
    if (google == null) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(user)
        };

        return fetch(API_Route + '/api/register/register_portal_user?server=' + server, requestOptions)
            .then((handleError) => sharedService.handleResponse(handleError))
            .catch(sharedService.handleFetchErrorResponse);
    }
    else {
        localStorage.setItem("googleToken", JSON.stringify(google.tokenId))
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ token: google.tokenId })
        };

        return fetch(API_Route + '/api/register/register_portal_google_user?server=' + server, requestOptions)
            .then((handleError) => sharedService.handleResponse(handleError))
            .catch(sharedService.handleFetchErrorResponse);

    }
}

function checkEmail(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/register/check_email/', requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/register/get_locations', requestOptions)
        .then(sharedService.handleResponse)
}

function confirmEmail(emailConfirmation, server) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(emailConfirmation)
    };

    return fetch(API_Route + '/api/register/confirm_email?server=' + server, requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function sendConfirmationEmail(email, server) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(email)
    };

    return fetch(API_Route + '/api/authorize/send_confirmation_email?server=' + server, requestOptions)
        .then((handleError) => sharedService.handleResponse(handleError))
        .catch(sharedService.handleFetchErrorResponse);
}

function getUserPartner(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/authorize/get_user_Partner?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .then(user => {
            localStorage.setItem('partner', user.partner);

            return user.partner;
        })
        .catch(sharedService.handleFetchErrorResponse);
}