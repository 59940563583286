import React from 'react';
import { connect } from 'react-redux';
import SP_Payout_Account_Display from './SP_Payout_Account_Display';
import { SP_Payout_Account_Form } from './SP_Payout_Account_Form';

class SP_Payout_Account_Billing_Page extends React.Component {
    render() {
        const { stripe_Verified, updatePayoutMethod, cancelEdit } = this.props;

        let stripe_Payout_Account = stripe_Verified?.external_Accounts?.data[0];
        let stripe_Payout_Currency = stripe_Verified?.currency;

        return (
            <div>
                {(stripe_Payout_Account && !updatePayoutMethod) ?

                    <SP_Payout_Account_Display
                        stripe_Payout_Account={stripe_Payout_Account}
                    />
                    :
                    <SP_Payout_Account_Form
                        stripe_Payout_Account={stripe_Payout_Account}
                        stripe_Payout_Currency={stripe_Payout_Currency}
                        cancelEdit={cancelEdit}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {

    const { stripe_Verified } = state.stripeVerified;

    return {
        stripe_Verified,
    };
}

const connectedSP_Payout_Account = connect(mapStateToProps)(SP_Payout_Account_Billing_Page);
export { connectedSP_Payout_Account as SP_Payout_Account_Billing_Page };

