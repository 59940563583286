import { Client as ConversationsClient } from '@twilio/conversations';
import React from 'react';
import { connect } from 'react-redux';
import { chatActions, organizationActions } from '../../../../_actions';
import { chatService, organizationService, serviceProviderService } from '../../../../_services';
import { Loader as Loading } from '../../../Shared/Loader';

class RejectedMessenger_v2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service_Provider: this.props.service_Provider,
            loading: true,

            conversations: [],
            conversation: null,

            channels: [],
            channel: null,

            has_an_org: true,
        }

    }

    componentDidMount = async () => {
        const { is_marketplace } = this.props;

        if (is_marketplace) {
            const user_id = JSON.parse(localStorage.getItem('user')).id;
            var result = await organizationService.getAllOrganizationsByUser(user_id);
            if (!result.organizations
                || Object.keys(result.organizations).length === 0) {
                //allow user to create org
                this.setState({
                    has_an_org: false
                });
            }
        }

        this.getOrganizationchannels();

    }

    componentDidUpdate = (oldProps, oldState) => {
        const { is_marketplace, channel_type, created_organization } = this.props;
        let { has_an_org } = this.state;

        if ((!is_marketplace || created_organization || (is_marketplace && has_an_org))
            && !channel_type
            && this.props.service_Provider.organization_Id !== oldProps.service_Provider.organization_Id
        ) {
            this.getOrganizationchannels();
        }
    }

    getOrganizationchannels = async () => {
        const { user, organization_Id, service_Provider } = this.props;

        let currentChannel;
        const organization_chat = {
            participant_1_user_id: user.id,
            organization_id: organization_Id
        };
        let result1 = await serviceProviderService.getOrganizationChannels(organization_chat);
        currentChannel = result1.channel ? result1.channel[0] : null;

        this.setState({
            channels: result1.channels,
        });

        if (!currentChannel) {
            let result2 = await serviceProviderService.getChannelByServiceProvider(user.id, service_Provider.service_Provider_Id);
            currentChannel = result2.channel
        }

        this.message_user(currentChannel);
    }

    componentWillUnmount() {
        if (this.conversationsClient)
            this.conversationsClient.shutdown();
    }

    add_participants = async (channel) => {
        const { user } = this.props;

        var chat = {
            participant_1_user_id: user.id,
            participant_2_user_id: channel.username,
            channel: channel.channel
        }

        await chatService.add_participants(chat);
    }

    create_conversation = async (client_name) => {
        var result = await chatService.create_conversation(client_name);
        var conversation_ch = result.conversation_ch;
        return conversation_ch;
    }

    message_user = async (channel) => {
        const user = JSON.parse(localStorage.getItem('user'));

        await this.setState({ show_Chat: false });

        if (!this.state.has_an_org && !this.props.created_organization) {
            this.props.dispatch(organizationActions.organizationAlert("no_organization"));
        }

        else {
            const { user } = this.props;

            let conversation_ch = channel.channel;

            if (!conversation_ch) {
                conversation_ch = await this.create_conversation(user.name + user.surname + "_" + channel.name + channel.surname);
                channel.channel = conversation_ch;
                this.setState({ conversation_ch, channel });
                await this.add_participants(channel);
            }

            if (!this.conversationsClient) {
                let result = await chatService.generate_token(user.id);
                let token = result.token;

                this.setState({ token: token, conversation_ch, channel },
                    async () => {
                        await this.initConversations();
                        if (conversation_ch && this.conversationsClient) {
                            this.setState({ conversation_ch, channel });
                        }

                        this.props.dispatch(chatActions.set_channel(channel));
                        await this.setState({ show_Chat: true });
                    })
            }
            else {
                let conversation_list = this.state.conversations.filter(conv => conv.sid === conversation_ch);

                if (conversation_list.length > 0) {
                    this.setState({ conversation: conversation_list[0], channel });
                    this.props.dispatch(chatActions.set_conversation(conversation_list[0]));
                }

                this.props.dispatch(chatActions.set_channel(channel));
                await this.setState({ loading: false, show_Chat: true });
            }

            if (channel //channel exists
                && channel.last_Message_User_Id //a message was sent
                && channel.last_Message_User_Id !== user.id //message was sent by other user
                && (!channel.last_Message_Read  //message was never read
                    || (new Date(channel.last_Message_Read) < new Date(channel.last_Message_Sent)))) //message has not been read yet
            {

                let messageInfo = {
                    channel: channel.channel,
                    last_Message_Read: new Date()
                }

                chatService.updateChatInfo(messageInfo);


                let channels = this.state.channels.map(conv => {
                    if (conv.channel === conversation_ch) {
                        conv.last_Message_Read = new Date();
                    }
                    return conv;
                });

                this.setState({
                    channels
                });
            }
        }
    }

    initConversations = async () => {
        const { user } = this.props

        this.conversationsClient = await ConversationsClient.create(this.state.token);
        this.setState({ statusString: "Connecting to Twilio…" });

        this.conversationsClient.on("connectionStateChanged", (state) => {
            if (state === "connecting")
                this.setState({
                    statusString: "Connecting to Twilio…",
                    status: "default"
                });
            if (state === "connected") {
                this.setState({
                    statusString: "You are connected.",
                    status: "success"
                });
            }
            if (state === "disconnecting")
                this.setState({
                    statusString: "Disconnecting from Twilio…",
                    conversationsReady: false,
                    status: "default"
                });
            if (state === "disconnected")
                this.setState({
                    statusString: "Disconnected.",
                    conversationsReady: false,
                    status: "warning"
                });
            if (state === "denied")
                this.setState({
                    statusString: "Failed to connect.",
                    conversationsReady: false,
                    status: "error"
                });
        });

        this.conversationsClient.on("conversationJoined", (conversation) => {

            if (this.state.conversations.filter(conv => conv.sid === conversation.sid).length === 0) {
                if (conversation.sid === this.state.conversation_ch) {
                    this.setState({
                        conversations: [...this.state.conversations, conversation],
                        conversation
                    });

                    this.props.dispatch(chatActions.set_conversation(conversation));
                }
                else {

                    this.setState({
                        conversations: [...this.state.conversations, conversation],
                    });
                }
            }
        });

        this.conversationsClient.on("conversationLeft", (thisConversation) => {
            this.setState({
                conversation: null,
                conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
            });
        });

        this.conversationsClient.on('tokenAboutToExpire', async () => {
            // let email = JSON.parse(localStorage.getItem('user')).email;
            let result = await chatService.generate_token(user.id);
            let token = result.token;
            this.conversationsClient.updateToken(token);
        });

        this.setState({
            loading: false,
        })

    };

    render() {
        const { channel, conversation, show_Chat, loading } = this.state;

        let childrenWithExtraProp = null;
        if (conversation) {
            childrenWithExtraProp = React.Children.map(this.props.children, child =>
                React.cloneElement(child, {
                    channel_type: this.props.channel_type,
                    client: channel,
                    conversationProxy: conversation
                })
            );
        }

        return (
            <>
                {loading && <Loading />}
                <div>{childrenWithExtraProp}</div>
            </>
        )

        // return (
        //     <div>
        //         {loading && <Loading />}

        //         {conversation && channel && show_Chat &&
        //             <div>
        //                 {this.props.children({
        //                     channel_type:this.props.channel_type,
        //                     client:channel,
        //                     conversationProxy:conversation,
        //                     toggleRejection:this.props.toggleRejection,
        //                     handleRejectFunction:this.props.handleRejectFunction})
        //                 }
        //             </div>
        //         }
        //     </div>
        // )
    }
}

function mapStateToProps(state) {
    const { created_organization } = state.organization_createorganizationalert;
    const { no_organization } = state.organization_noorganizationalert;
    const { setUser } = state;
    const { user } = setUser;
    const { updated_channels } = state.serviceprovider_alert;

    return {
        created_organization,
        no_organization,
        user,
        updated_channels
    };
}

const connectedRejectedMessenger = connect(mapStateToProps)(RejectedMessenger_v2);
export { connectedRejectedMessenger as RejectedMessenger_v2 };
