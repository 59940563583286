import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/Login.css';
import '../../../../Styles/Marketplace.css';
import { EditAutoSuggestion } from "./EditAutoSuggestion";

class EditFocus extends React.Component {

    render() {
        const {
            category_TagsError,
            submitted,
            industry_Focus_List_Cat,
            industry_Service_List_Cat,
            stringServiceTypes,
            stringFocusTypes,
        } = this.props;

        return (
            <div id="GW_Onboarding_Focus">
                <div>
                    <h4 className="inputFieldLabel">Industry Focus <span className="red">*</span></h4>
                    {submitted && stringFocusTypes.length === 0 &&
                        <p className="errorMessage">You must select a focus type.</p>
                    }
                    <p className="inputFieldSubLabel">
                        Tell us a bit about your industry focus. Where do you do most of your work?
                    </p>
                    <EditAutoSuggestion
                        categories={industry_Focus_List_Cat}
                        categoryName="SP_Industry_Focus_Tags"
                        stateName="stringFocusTypes"
                        currentSelectedCategories={stringFocusTypes}
                        multiple={true}
                        addTags={true}
                        submitted={submitted}
                        errorInput={category_TagsError}
                        handleCategorySelection={this.props.handleCategorySelection}
                        handleCategoryInput={this.props.handleCategoryInput} />
                </div>
                <br />
                <div>
                    <h4 className="inputFieldLabel">Services Provided <span className="red">*</span></h4>
                    {submitted && stringServiceTypes.length === 0 &&
                        <p className="errorMessage">You must select service(s) you provide.</p>
                    }
                    <p className="inputFieldSubLabel">
                        What services do you or your team provide?
                    </p>
                    <EditAutoSuggestion
                        categories={industry_Service_List_Cat}
                        categoryName="SP_Industry_Services_Tags"
                        stateName="stringServiceTypes"
                        currentSelectedCategories={stringServiceTypes}
                        multiple={true}
                        addTags={true}
                        submitted={submitted}
                        errorInput={category_TagsError}
                        handleCategorySelection={this.props.handleCategorySelection}
                        handleCategoryInput={this.props.handleCategoryInput} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return {
        user,
    };
}

const connectedEditFocus = connect(mapStateToProps)(EditFocus);
export { connectedEditFocus as EditFocus };

