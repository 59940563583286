import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const organizationStripeService = {
    addBillingInfo,
    getBillingInfo,
    updateBillingInfo,

    setIndvBilling,

    updateBillingAddress,

    getInvoiceLink,
    verifyBankAccount
    // downloadInvoice,
};

function addBillingInfo(billing_info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(billing_info)
    };

    return fetch(API_Route + '/api/stripeorganization/add-billing-info', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getBillingInfo(org_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripeorganization/get-billing-info?org_id=' + org_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateBillingInfo(productDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productDetails)
    };

    return fetch(API_Route + '/api/stripeorganization/update_organization_payment', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function setIndvBilling(userDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(userDetails)
    };

    return fetch(API_Route + '/api/stripe/set_org_billing_same_as_indv', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateBillingAddress(billings) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(billings)
    };

    return fetch(API_Route + '/api/stripeorganization/update_billing_address', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function verifyBankAccount(org_id, deposit_1, deposit_2) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(API_Route + '/api/stripeorganization/verify_bank_account?org_id=' + org_id + "&deposit_1=" + deposit_1 + "&deposit_2=" + deposit_2, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function getInvoiceLink(invoice_Id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripeorganization/get_invoice_pdf?invoice_id=' + invoice_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}