import { alertConstants } from '../_constants';

export function loginAlert(state = {}, action) {
  switch (action.type) {
    case alertConstants.LOGIN_SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.LOGIN_ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.LOGIN_CLEAR:
      return {};
    default:
      return state
  }
}

export function updatePasswordAlert(state = {}, action) {
  switch (action.type) {
    case alertConstants.UPDATEPASSWORD_SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.UPDATEPASSWORD_ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.UPDATEPASSWORD_CLEAR:
      return {};
    default:
      return state
  }
}

export function emailAlert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUBSCRIBE_SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.SUBSCRIBE_ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.SUBSCRIBE_CLEAR:
      return {};
    default:
      return state
  }
}

export function registrationAlert(state = {}, action) {
  switch (action.type) {
    case alertConstants.REGISTRATION_SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.REGISTRATION_ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.REGISTRATION_CLEAR:
      return {};
    default:
      return state
  }
}

export function settingsAlert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SETTINGS_SUCCESS:
      return {
        type: 'alert-success',
        message: action.message
      };
    case alertConstants.SETTINGS_ERROR:
      return {
        type: 'alert-danger',
        message: action.message
      };
    case alertConstants.SETTINGS_CLEAR:
      return {};
    default:
      return state
  }
}