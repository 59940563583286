import React from 'react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../../Styles/SettingsStyles/ImageUpload.css';
import uploadIcon from '../../../_assets/img/icons/drag-and-drop.svg';

let show = true;

export function Previews(props) {
  const [files, setFiles] = useState(props.files ? props.files : []);
  const [imageUrl, setImageUrl] = useState(props.imageUrl ? props.imageUrl : "");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 5242880,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      acceptedFiles.forEach((file) => {
        imageUpload(file);
        props.removePreview();
      })

      fileRejections.forEach((file) => {
        let wrongType = file.type !== "image/jpeg" && file.type !== "image/png"
        props.imageUploadFailed(wrongType ? "The file type is incorrect. Previous image will be saved" : "The file exceeds maximum size. Previous image will be saved")
      });
    }
  });

  useEffect(() => {
    if (files.length > 0) {
      imageUpload(files[0]);
    }
  }, []);

  const imageUpload = (file) => {
    props.setOrgLogo(file);
    getBase64(file).then(base64 => {
      //   localStorage.setItem('organizationLogo', base64);
      props.removePreview();
      show = true;
    }).catch(err => {
      show = false;
      props.imageUploadFailed("The file exceeds maximum size. Previous image will be saved")
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const thumbs =
    (files && files.length > 0) ? (
      files.map(file => (
        <div className='previewThumb' key={file.name}>
          <div className='previewThumbInner'>
            <img className='orgImageCircle'
              src={file.preview ? file.preview : URL.createObjectURL(file)}
              alt="file preview"
            />
          </div>
        </div>)))
      :
      (<div className='previewThumb'>
        <div className='previewThumbInner'>
          <img className='orgImageCircle'
            src={props.imageUrl}
            alt=""
          />
        </div>
      </div>);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section id="imgUploadContainer">
      <div {...getRootProps(/* { style } */)} className="drag-and-drop-border">
        <img src={uploadIcon} alt="Upload Icon" />
        <input {...getInputProps()} />
        {isDragActive ?
          "Drop your image here" :
          <p><span style={{ color: '#009E94' }}>Drag and drop</span> or browse files</p>
        }
        <aside className='thumbsContainer'>
          {show && thumbs}
        </aside>
      </div>
    </section>
  );
}