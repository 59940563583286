import { authHeader } from '../_helpers';
import { API_Route } from '../App/API_Route';
import { sharedService } from './shared.service';

export const recommendationService = {
    createRecommendation,
    updateRecommendation,
    getRecommendation,
    deleteRecommendation,
};

function createRecommendation(recommendation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recommendation)
    };

    return fetch(API_Route +
        '/api/recommendation/create_recommendation', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateRecommendation(recommendation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recommendation) //not sure about this line
    };

    return fetch(API_Route +
        '/api/recommendation/update_recommendation', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getRecommendation(recommendation_Id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route +
        '/api/recommendation/get_recommendation/' + recommendation_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}


function deleteRecommendation(recommendation_Id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(API_Route +
        '/api/recommendation/delete_recommendation/' + recommendation_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}