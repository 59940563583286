import { serviceproviderConstants } from '../_constants';

export function serviceprovider_getserviceprovider(state = { loading: 'initial' }, action) {
  switch (action.type) {
    case serviceproviderConstants.GETSERVICEPROVIDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case serviceproviderConstants.GETSERVICEPROVIDER_SUCCESS: {
      let new_service_provider = action.service_Provider;
      let service_Provider = '';
      if ((new_service_provider && Object.keys(new_service_provider).length)
        || (state.service_Provider && Object.keys(state.service_Provider).length)) {
        service_Provider = {
          ...state.service_Provider,
          ...new_service_provider
        };
      }
      return {
        service_Provider,
        loading: false
      };
    }
    case serviceproviderConstants.GETSERVICEPROVIDER_FAILURE:
      return {
        ...state,
        error: "There was an error processing this request.",
        loading: false
      };
    default:
      return state
  }
};

export function serviceprovider_getclient(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.GETCLIENT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.GETCLIENT_SUCCESS:
      return {
        client: action.client
      };
    case serviceproviderConstants.GETCLIENT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_getallclients(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.GETALLCLIENTS_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.GETALLCLIENTS_SUCCESS:
      return {
        clients: action.clients
      };
    case serviceproviderConstants.GETALLCLIENTS_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_getprojects(state = { projects: [], loading: false }, action) {
  switch (action.type) {
    case serviceproviderConstants.GETPROJECTS_REQUEST:
      return {
        projects: [],
        loading: true
      };
    case serviceproviderConstants.GETPROJECTS_SUCCESS:
      return {
        projects: action.projects.projects_and_proposals ? action.projects.projects_and_proposals : action.projects,
        loading: false
      };
    case serviceproviderConstants.GETPROJECTS_FAILURE:
      return {
        error: "There was an error processing this request.",
        loading: false
      };
    default:
      return state
  }
};

export function serviceprovider_getproject(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.GETPROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case serviceproviderConstants.GETPROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        loading: false
      };
    case serviceproviderConstants.GETPROJECT_FAILURE:
      return {
        ...state,
        error: "There was an error processing this request.",
        loading: false
      };
    default:
      return state
  }
};

export function serviceprovider_getAllClientProjectsAndProposals(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.GETALLCLIENTPROJECTANDPROPOSALS_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.GETALLCLIENTPROJECTANDPROPOSALS_SUCCESS:
      return {
        projects_and_proposals: action.result.projects_and_proposals,
        can_update_proposal_status: action.result.can_update_proposal_status
      };
    case serviceproviderConstants.GETALLCLIENTPROJECTANDPROPOSALS_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_createproject(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.CREATEPROJECT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.CREATEPROJECT_SUCCESS:
      return {
        project: action.project
      };
    case serviceproviderConstants.CREATEPROJECT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_updateproject(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.UPDATEPROJECT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.UPDATEPROJECT_SUCCESS:
      return {
        project: action.project
      };
    case serviceproviderConstants.UPDATEPROJECT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_createprojectproposal(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.CREATEPROJECTPROPOSAL_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.CREATEPROJECTPROPOSAL_SUCCESS:
      return {
        proposal: action.proposal
      };
    case serviceproviderConstants.CREATEPROJECTPROPOSAL_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_updateprojectproposal(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.UPDATEPROJECTPROPOSAL_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSAL_SUCCESS:
      return {
        proposal: action.proposal
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSAL_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_updateprojectproposalstatus(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_SUCCESS:
      return {
        proposal: action.proposal
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_updateprojectproposalview(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_SUCCESS:
      return {
        proposal: action.proposal
      };
    case serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_createworksegment(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.CREATEWORKSEGMENT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.CREATEWORKSEGMENT_SUCCESS:
      return {
        segment: action.segment
      };
    case serviceproviderConstants.CREATEWORKSEGMENT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_editworksegment(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.EDITWORKSEGMENT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.EDITWORKSEGMENT_SUCCESS:
      // alert(JSON.stringify(action.segment));
      return {
        segment: action.segment
      };
    case serviceproviderConstants.EDITWORKSEGMENT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_deleteworksegment(state = {}, action) {
  switch (action.type) {
    case serviceproviderConstants.DELETEWORKSEGMENT_REQUEST:
      return {
        loading: true
      };
    case serviceproviderConstants.DELETEWORKSEGMENT_SUCCESS:
      return {
        segment: action.segment
      };
    case serviceproviderConstants.DELETEWORKSEGMENT_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
};

export function serviceprovider_alert(state = { updated_channels: {} }, action) {
  switch (action.type) {
    case serviceproviderConstants.ALERT_CHANNELUPDATE:
      {
        let updated_channels = Object.assign({}, state.updated_channels);
        updated_channels[action.channel.channel] = action.channel;
        return {
          updated_channels
        }
      }
    default:
      return state;
  }
}