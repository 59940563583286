import React from 'react'
import { useSelector } from "react-redux";
import "../../Styles/Error_v2.css";
import ErrorImage from "../../_assets/img/ErrorImage.png";
import logo from "../../_assets/img/opengrants-logo-big.svg";
import { history } from '../../_helpers';

function Error404Page() {

    const { user } = useSelector(state => state.setUser);

    const handleViewDashboard = () => {
        history.push('/dashboard');
    }

    const handleEditPreferences = () => {
        history.push('/profiles/' + user.userName);
    }

    const handleRegister = () => {
        history.push('/register');
    }

    const handleLogin = () => {
        history.push('/login');
    }

    return (
        <div>
            <div className="pageNotFoundDiv">
                <img src={ErrorImage} alt="404 error"/>
                <p>Looks like this page cannot be found.</p>
            </div>
            <div className="helpfulLinksDiv">
                <p>Here are some helpful links you might be looking for</p>

                {/* Logged In Users */}
                {user &&
                    <div className='helpfulLinksButtons'>
                        <button onClick={() => { handleViewDashboard() }}>View Dashboard</button>
                        <button onClick={() => { handleEditPreferences() }}>Edit Preferences</button>
                    </div>
                }

                {/* Not Logged In Users */}
                {!user &&
                    <>
                        <div id="desktop-nav" >
                            <div
                                id="desktop-title"
                                className="col-md-4 mt-auto mb-auto"
                                onClick={() => { history.push("/dashboard") }}
                                style={{ cursor: "pointer" }}
                            >
                                <img src={logo} alt="logo" style={{ width: "220px", display: "inline" }} />
                            </div>
                        </div>
                        <div className='helpfulLinksButtons'>
                            <button onClick={() => { handleLogin() }}>Login</button>
                            <button onClick={() => { handleRegister() }}>Create an Account</button>
                        </div>
                    </>
                }
            </div>

            {/* Commented out for later use */}

            {/* <div className="searchDiv centerFlex">
                <h4>Cant find what you need?</h4>
                <p>Take a moment to search our website.</p>
                <input/>
            </div> */}
        </div>
    )
}

export { Error404Page };

