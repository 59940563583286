import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AcceptingModal, CongratsModal, RejectedModal, ConfirmationModal } from '.';
import '../../../../Styles/GrantWriters.css';
import "../../../../Styles/ServiceProvidersFinishProject.css";
import '../../../../Styles/SettingsStyles/Billings.css';
import { organizationstripeActions } from '../../../../_actions';
import gearIcon from '../../../../_assets/img/badge-check.png';
import { history } from '../../../../_helpers';
import { pluginService, sharedService } from '../../../../_services';
import { Loader } from '../../../Shared/Loader';

let accentColor = pluginService.getIFrameColor()

class ServiceProvidersAcceptOrRejectProject_v2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAcceptOpen: false,
            isCongratulationsOpen: false,
            isConfirmationOpen: false,
            currentSection: 'overview',
            open_chat: false,
            isRejectedOpen: false,
            view: 'view',
            loading: false,
        }
    }

    componentDidMount() {
        if (this.props.client_Org) {
            this.props.dispatch(organizationstripeActions.getOrgBillingInfo(this.props.user.id, this.props.client_Org))
        } else {
            history.push('/cl/projects')
        }
    }

    //Open And Close Modals
    toggleAcceptModal = async () => {
        const { isAcceptOpen } = this.state;

        if (!isAcceptOpen) {
            this.setState({
                loading: true
            })

            if (!sharedService.hasValidPaymentMethod(this.props.billingInfo_org)) {
                this.setState({ isAcceptOpen: !isAcceptOpen })
            }
            else {
                await this.props.updateProposalStatus("Accepted");
                this.openCongratulations();
            }

            this.setState({
                loading: false
            })
        }
        else {
            this.setState({ isAcceptOpen: !isAcceptOpen })
        }

    }

    toggleConfirmationModal = () => {
        const { isConfirmationOpen } = this.state;
        this.setState({ isConfirmationOpen: !isConfirmationOpen })
    }
    openCongratulations = () => {
        const { isCongratulationsOpen } = this.state;
        this.setState({ isCongratulationsOpen: !isCongratulationsOpen })
    }
    toggleRejection = () => {
        const { isRejectedOpen } = this.state;
        this.setState({ isRejectedOpen: !isRejectedOpen })
    }

    render() {
        const { project, updateNav, billingInfo_org } = this.props;
        const { isAcceptOpen, isConfirmationOpen, isCongratulationsOpen, isRejectedOpen, view, loading } = this.state;

        return (
            <div>
                {loading && <Loader />}
                {view === 'view' &&
                    <div className="row accept-project-buttons-row">
                        <div className="col-md-6 accept-project-left-div">
                            <button
                                className="accept-project-button"
                                type="button"
                                onClick={() => this.toggleConfirmationModal()}
                                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069', color: "#fff" }}
                            >
                                <img src={gearIcon} height="18px" style={{ paddingRight: 5 }} alt="" />
                                Accept
                            </button>
                        </div>
                        <div className="col-md-6 accept-project-right-div">
                            <button
                                type="button"
                                className="accept-project-button"
                                onClick={() => this.toggleRejection()}
                                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069', backgroundColor: "#fff" }}
                            >
                                <i className="icon-cancel" />
                                Reject
                            </button>
                        </div>
                    </div>
                }

                {isConfirmationOpen && <ConfirmationModal
                    isConfirmationOpen={isConfirmationOpen}
                    toggleConfirmationModal={(value) => this.setState({ isConfirmationOpen: value })}
                    toggleAcceptModal={this.toggleAcceptModal}
                    project={project}
                />

                }
                {isRejectedOpen && <RejectedModal
                    type={this.props.type}
                    organization_Id={this.props.organization_Id}
                    isRejectedOpen={isRejectedOpen}
                    updateNav={updateNav}
                    toggleRejection={(value) => this.setState({ isRejectedOpen: value })}
                    handleRejectFunction={() => this.props.updateProposalStatus("Rejected")}
                    service_Provider={this.props.service_Provider}
                />}

                {isAcceptOpen && <AcceptingModal
                    additional_action={this.props.updateProposalStatus}
                    is_accepting_proposal={true}
                    display_payment_form={this.props.display_payment_form}
                    client_Org={this.props.client_Org}
                    showForm={true}
                    isAcceptOpen={isAcceptOpen}
                    updateNav={updateNav}
                    toggleAcceptModal={(value) => this.setState({ isAcceptOpen: value })}
                    handleSubmitFunction={() => this.props.updateProposalStatus("Accepted")}
                    allowProposalStatusUpdate={this.props.allowProposalStatusUpdate}
                    openCongratulations={this.openCongratulations}
                    billingInfo_org={billingInfo_org}
                    project={project}
                />}
                {isCongratulationsOpen && <CongratsModal
                    isCongratulationsOpen={isCongratulationsOpen}
                    updateNav={updateNav}
                    openCongratulations={this.openCongratulations}
                    project={project}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { billingInfo_org } = state;
    return { user, billingInfo_org };
}

const connectedServiceProvidersAcceptOrRejectProject_v2 = connect(mapStateToProps)(ServiceProvidersAcceptOrRejectProject_v2);
export { connectedServiceProvidersAcceptOrRejectProject_v2 as ServiceProvidersAcceptOrRejectProject_v2 };
