import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { locationsService } from "../../../../_services";
import { Registration_AutoSuggestion } from "../../../Account/RegisterPage/Registration_AutoSuggestion";
import { Loader as Loading } from "../../../Shared/Loader";

class GC_Onboarding_BusinessAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
      notUSorCanada: !(this.props.new_Organization?.organization?.country?.toLowerCase() === "united states" || this.props.new_Organization?.organization?.country?.toLowerCase() === "canada"),
    };
  }
  async componentDidMount() {
    var city = await locationsService.getLocations("City");
    var state = await locationsService.getLocations("US_State");
    var country = await locationsService.getLocations("Country_Stripe");
    // var country = ['Canada', 'United States']
    this.setState({
      city,
      state,
      country
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.new_Organization?.organization?.country !== this.props.new_Organization?.organization?.country) {

  //     if (this.props.new_Organization?.organization?.country?.toLowerCase() === "united states" || this.props.new_Organization?.organization?.country?.toLowerCase() === "canada") {
  //       this.setState({
  //         notUSorCanada: false
  //       })
  //     }
  //   }
  // }

  render() {
    const { updateNav, new_Organization, organizationStateError, handleChange, handleLocationSelection, handleLocationInput, } = this.props;
    const { state, country, isNotValid, notUSorCanada } = this.state;

    const nextPage = () => {
      this.setState({
        isNotValid: true,
      });

      // Check if state is valid
      if (new_Organization.organization.country
        && new_Organization.organization.country?.toLowerCase() === "united states"
        && new_Organization.organization.state) {
        let state_input = new_Organization.organization.state;
        if (state_input) {
          let result = state.filter((s) => s.toLowerCase() === state_input.toLowerCase());
          if (result.length > 0) {
            new_Organization.organization.state = result[0];
          }
        }
      }

      // //check if country is valid
      if (new_Organization.organization.country) {
        let country_input = new_Organization.organization.country;
        if (country_input) {
          let result = country.filter((s) => s.toLowerCase() === country_input.toLowerCase());
          if (result.length > 0) {
            new_Organization.organization.country = result[0];

            if (new_Organization.organization.country !== "United States") {
              new_Organization.organization.address = '';
              new_Organization.organization.city = '';
              new_Organization.organization.state = '';
              new_Organization.organization.zipCode = '';
            }
          }
        }
      }

      // if (!country.includes(new_Organization.organization.country)) {
      //   new_Organization.organization.address = '';
      //   new_Organization.organization.city = '';
      //   new_Organization.organization.state = '';
      //   new_Organization.organization.zipCode = '';        
      // }

      if ((new_Organization.organization.country && country.includes(new_Organization.organization.country)) //country
        && (!new_Organization.organization.address || //no adress
          !(new_Organization.organization.address.toLowerCase().includes("p.o. box") //address not po box
            || new_Organization.organization.address.toLowerCase().includes("po box")))
        // ((new_Organization.organization.address && //address
        //   !(new_Organization.organization.address.toLowerCase().includes("p.o. box") //address not po box
        //   || new_Organization.organization.address.toLowerCase().includes("po box"))) &&
        // new_Organization.organization.city && //city
        // new_Organization.organization.state && //state
        // new_Organization.organization.zipCode) //zipcode
        && (
          (new_Organization.organization.state && new_Organization.organization.country !== "United States") //state entered and not US
          || (new_Organization.organization.state && new_Organization.organization.country === "United States" && state.includes(new_Organization.organization.state)) //state entered and US and in the list
          || !new_Organization.organization.state //no state entered
        )
      ) {
        this.setState({
          isNotValid: false,
        });
        updateNav("onboard_professionalInfo");
      }
    };

    return (
      <>
        {!state && <Loading />}
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              {new_Organization.organization.org_Type ===
                "Individual/Sole Proprietor"
                ? "6 of 8"
                : "4 of 8"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          {new_Organization.organization.org_Type ===
            "Individual/Sole Proprietor" ? (
            <div className="col-md-10">
              <div className="col-md-8 progress-color" />
              <div className="col-md-4 gray-progress-color" />
            </div>
          ) : (
            <div className="col-md-10">
              <div className="col-md-6 progress-color" />
              <div className="col-md-6 gray-progress-color" />
            </div>
          )}
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header" >Business Address</h1>
            <hr />
            {new_Organization.organization.org_Type === "Individual/Sole Proprietor"
              && new_Organization.organization.country === "United States" &&
              <div className="row">
                <p>Your state will be visible to grant seekers, but the rest of your address will be hidden.</p>
              </div>}
            <div className="row">
              <div className="col-md-6 gc_onboarding-form-col-left">

                <p className="gc_onboarding-form-input-name-top">
                  Country <span className="red-star">*</span>
                </p>

                <Registration_AutoSuggestion
                  className="Registration_AutoSuggestion_display .gc_onboarding-form-input-lg"
                  categories={{ country: country ? country : [] }}
                  categoryName={"country"}
                  id="organization-standard-basic"
                  currentSelectedCategories={[
                    new_Organization.organization.country
                      ? new_Organization.organization.country
                      : "",
                  ]}
                  handleCategorySelection={handleLocationSelection}
                  handleCategoryInput={handleLocationInput}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 gc_onboarding-form-col-left">
                {isNotValid && !new_Organization.organization.country && (
                  <p className="red-message">
                    You must enter your organization's country.
                  </p>
                )}
                {isNotValid && new_Organization.organization.country && !(country.includes(new_Organization.organization.country)) &&
                  <p className="red-message">
                    Please select a country from the list.
                  </p>
                }
              </div>
            </div>
            {(this.props.new_Organization?.organization?.country?.toLowerCase() === "united states" || this.props.new_Organization?.organization?.country?.toLowerCase() === "canada") &&
              <div>
                <div className="row">
                  <div className="col-md-6 gc_onboarding-form-col-left">
                    <p className="gc_onboarding-form-input-name">
                      Street Address
                    </p>
                    <input
                      className="gc_onboarding-form-input"
                      type="text"
                      name="address"
                      id="organization-standard-basic"
                      value={new_Organization.organization.address}
                      onChange={handleChange}
                      inputProps={{ maxLength: 200 }}
                    />
                    {/* {isNotValid && !new_Organization.organization.address && (
                      <p className="red-message">
                        You must enter your organization's street address.
                      </p>
                    )} */}
                    {new_Organization.organization.address && (new_Organization.organization.address.toLowerCase().includes("p.o. box")
                      || new_Organization.organization.address.toLowerCase().includes("po box")
                    ) &&
                      <p className="errorMessage">You cannot use a P.O. Box Address.</p>
                    }
                  </div>
                  <div className="col-md-6 gc_onboarding-form-col-right">
                    <p className="gc_onboarding-form-input-name">
                      City
                    </p>
                    <input
                      className="gc_onboarding-form-input"
                      type="text"
                      name="city"
                      id="organization-standard-basic"
                      value={new_Organization.organization.city}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                    {/* {isNotValid && !new_Organization.organization.city && (
                      <p className="red-message">
                        You must enter your organization's city.
                      </p>
                    )} */}
                  </div>
                </div>
                <div className="row mobile-row">
                  <div className="col-md-6 gc_onboarding-form-col-left">
                    {(new_Organization.organization.country?.toLowerCase() !== "canada") && <>
                      <p className="gc_onboarding-form-input-name">
                        State
                      </p>
                      <Registration_AutoSuggestion
                        className="Registration_AutoSuggestion_display"
                        categories={{ state: state && new_Organization.organization.country?.toLowerCase() === "united states" ? state : [] }}
                        categoryName={"state"}
                        id="organization-standard-basic"
                        currentSelectedCategories={[
                          new_Organization.organization.state
                            ? new_Organization.organization.state
                            : "",
                        ]}
                        handleCategorySelection={handleLocationSelection}
                        handleCategoryInput={handleLocationInput}
                        inputProps={{ maxLength: 50 }}
                      />
                      {isNotValid
                        && new_Organization.organization.state
                        && new_Organization.organization.country?.toLowerCase() === "united states"
                        && !(state.includes(new_Organization.organization.state))
                        && (
                          <p className="red-message">
                            Please select a state from the list.
                          </p>
                        )}
                    </>}
                    {new_Organization.organization.country?.toLowerCase() === "canada" && <>
                      <p className="gc_onboarding-form-input-name">
                        Province
                      </p>
                      <Registration_AutoSuggestion
                        className="Registration_AutoSuggestion_display"
                        categories={{ state: [] }}
                        categoryName={"state"}
                        id="organization-standard-basic"
                        currentSelectedCategories={[
                          new_Organization.organization.state
                            ? new_Organization.organization.state
                            : "",
                        ]}
                        handleCategorySelection={handleLocationSelection}
                        handleCategoryInput={handleLocationInput}
                        inputProps={{ maxLength: 50 }}
                      />
                      {/* {isNotValid && !new_Organization.organization.state && (
                    <p className="red-message">
                      You must select your organization's province.
                    </p>
                  )} */}
                    </>}
                    {organizationStateError && (
                      <p className="red-star">Please select a valid input.</p>
                    )}
                  </div>
                  <div className="col-md-6 gc_onboarding-form-col-right">
                    <p className="gc_onboarding-form-input-name">
                      Zipcode
                    </p>
                    <input
                      className="gc_onboarding-form-input"
                      type="text"
                      name="zipCode"
                      id="organization-standard-basic"
                      value={new_Organization.organization.zipCode}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                    {/* {isNotValid && !new_Organization.organization.zipCode && (
                      <p className="red-message">
                        You must enter your organization's zipcode.
                      </p>
                    )} */}
                    {/* {isNotValid && new_Organization.organization.zipCode && !sharedService.is5Integers(new_Organization.organization.zipCode) && (
                      <p className="red-message">
                        Organization's zipcode must have 5 digits.
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
            }

            <br />
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_organizationAttributes2")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={() => nextPage()}
              >
                Next <i class="icon-arrow-right" />
              </button>
            </div>
          </div>
          {isNotValid && (
            <p style={{ marginTop: 30, color: "red" }}>
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_BusinessAddress = connect(mapStateToProps)(
  GC_Onboarding_BusinessAddress
);
export { connectedGC_Onboarding_BusinessAddress as GC_Onboarding_BusinessAddress };

