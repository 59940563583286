import React from "react";
import { Card, Col, Row } from 'react-bootstrap';

const FunderList = ({ grant, blur, viewGrant }) => {
    return (
        <Card className="bg-transparent border-0 text-secondary" style={blur ? { filter: 'blur(3px)' } : {}}>
            <Card.Header className="bg-transparent border-0"><h2>{grant.name}</h2></Card.Header>
            <Card.Body className="w-auto">
                <button disabled={blur} className="bg-secondary text-white rounded-10 p-16" onClick={() => viewGrant(grant)}>View Funder</button>
            </Card.Body>
        </Card>
    )
}

export default FunderList
