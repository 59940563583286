export const organizationStripeConstants = {
    GET_BILLING_INFO_REQUEST: 'ORG_GET_BILLING_INFO_REQUEST',
    GET_BILLING_INFO_SUCCESS: 'ORG_GET_BILLING_INFO_SUCCESS',
    GET_BILLING_INFO_FAILURE: 'ORG_GET_BILLING_INFO_FAILURE',

    BILLING_ADDRESS_REQUEST: 'ORG_BILLING_ADDRESS_REQUEST',
    BILLING_ADDRESS_SUCCESS: 'ORG_BILLING_ADDRESS_SUCCESS',
    BILLING_ADDRESS_FAILURE: 'ORG_BILLING_ADDRESS_FAILURE',

    BANK_VERIFICATION_REQUEST: 'ORG_BANK_VERIFICATION_REQUESET',
    BANK_VERIFICATION_SUCCESS: 'ORG_BANK_VERIFICATION_SUCCESS',
    BANK_VERIFICATION_FAILURE: 'ORG_BANK_VERIFICATION_FAILURE',

    UPDATE_BILLING_INFO_REQUEST: 'ORG_UPDATE_BILLING_INFO_REQUEST',

    ADDBILLINGINFO_REQUEST: 'ORG_ADDBILLINGINFO_REQUEST',
    ADDBILLINGINFO_SUCCESS: 'ORG_ADDBILLINGINFO_SUCCESS',
    ADDBILLINGINFO_FAILURE: 'ORG_ADDBILLINGINFO_FAILURE',

    SET_INDIVIDUAL_BILLING: 'SET_INDIVIDUAL_BILLING'
};
