import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";

class GC_Onboarding_Members extends React.Component {
  render() {
    const {
      user,
      updateNav,
      handleSubmit,
      form_errors,
      invite_emailInput,
      accepted_profiles, accepted_profile_list,
      pending_profiles,
      pending_invitation_profiles,
      handleNewOrganization,
      emailInvites
    } = this.props;
    const { organization } = this.props.new_Organization;

    const handleSubmitFunction = async (e) => {
      await handleSubmit(e);
    }

    let pending_invitation_list = null;
    if (emailInvites) {
      pending_invitation_list = emailInvites.map((email) => {
        return (
          <p>{email}</p>
        )
      });
    }

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              8 of 8
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10 complete-color" />
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={(e) => this.props.inviteToJoinOrganization(e, user.id, organization.organization_Id, invite_emailInput)} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
            <h1 className="onboarding-form-header">Members</h1>
            <p>Want to invite employees or colleagues to join your organization? Add their emails here. They will receive an invitation to create an account and join your organization. They will also be asked if they want to sign up as a regular user, or request to get approved as a Grant Consultant.</p>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-8 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  <span><strong>{accepted_profiles ? accepted_profiles.length : 0}</strong> Member(s)</span>
                </p>
                <div style={{ marginTop: 25 }}>
                  {accepted_profile_list}
                </div>
              </div>
            </div>
            <div className="row">
              <p className="gc_onboarding-form-input-name-top-pendingMembers">{pending_invitation_profiles ? pending_invitation_profiles.length : 0} Pending Invitation(s)</p>
              {pending_profiles.length > 0 ? <hr className="formHr" /> : <br />}
              <div>{pending_invitation_list}</div>
            </div>
            <div className="row">
              <div className="col-md-10 gc_onboarding-form-col" style={{ padding: 0 }}>
                <input
                  type="text"
                  name="invite_emailInput"
                  value={invite_emailInput}
                  placeholder="Email"
                  onChange={handleNewOrganization}
                  className="gc_onboarding-form-input-lg"
                  inputProps={{ maxLength: 255 }}
                  style={{ margin: 0 }}
                />
              </div>
              <div className="col-md-2 gc_onboarding-form-col-right">
                <Button
                  className="gc_onboarding-invite-btn "
                  onClick={(e) => { this.props.inviteToJoinOrganization(e, user.id, organization.organization_Id, invite_emailInput) }}
                >
                  Invite
                </Button>
              </div>
            </div>
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_advancedSettings")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={(e) => handleSubmitFunction(e)}
              >
                Submit <i class="icon-arrow-right" />
              </button>
            </div>
            {form_errors && (
              <p style={{ marginTop: 30, color: "red" }}>
                There are errors in your form. Please correct all errors shown in
                red above, then click Submit again.
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_Members =
  connect(mapStateToProps)(GC_Onboarding_Members);
export { connectedGC_Onboarding_Members as GC_Onboarding_Members };

