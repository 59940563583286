import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import { Loader } from '../../Shared/Loader';
import { EditOrganizationAdditionalAttributes } from "./AdditionalAttributes/EditOrganizationAdditionalAttributes";
import { EditOrganization8A } from './EditOrganization8A';
import { EditOrganizationDUNS } from './EditOrganizationDUNS';
import { EditOrganizationHubzone } from './EditOrganizationHubzone';
import { EditOrganizationImpact } from './EditOrganizationImpact';
import { EditOrganizationIndustries } from './EditOrganizationIndustries';
import { EditOrganizationNAICS } from './EditOrganizationNAICS';
import { EditOrganizationSAM } from './EditOrganizationSAM';
import { EditOrganizationSB } from './EditOrganizationSB';
import { EditOrganizationTechnologies } from './EditOrganizationTechnologies';
import { EditOrganizationVOB } from './EditOrganizationVOB';
import { EditOrganizationWOB } from './EditOrganizationWOB';
import { Previews } from './OrganizationImageUpload';

const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class EditOrganizationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_Logo: true,
            imageError: ""
        }

        this.removePreview = this.removePreview.bind(this);
    }

    removePreview = () => {
        this.setState({
            imageError: "",
            show_Logo: false,
        })
    }

    imageUploadFailed = (msg) => {
        this.setState({
            imageError: msg,
            show_Logo: false,
        })

        setTimeout(() => {
            this.setState({
                imageError: "",
            })
        }, 6000);
    }

    componentDidMount() {
    }

    render() {
        const { submitting, submitted,
            org, organization_namesInput,
            organizationNameError, organizationStateError, organizationCountryError,
            state, country, handleChange, handleCategorySelection, handleCategoryInput, category_TagsError,
            editOrganization, service_Provider, industry_Focus_List, industry_Service_List, handleSwitch, currentlyAvailable
        } = this.props;
        const { organization } = this.props.org;
        const { show_Logo } = this.state;

        return (

            <div>
                <div id="mainSettings">
                    {submitting && <Loader />}
                    <div className="row">
                        <div className="flex-settings-form" style={{ marginLeft: "13px", marginRight: "13px" }}>
                            <Button
                                onClick={this.props.hideAddEditOrganization}
                                style={{ background: "#29a" }}
                            >
                                ← &nbsp;&nbsp;Back
                            </Button>
                            {service_Provider &&
                                <FormControlLabel className="margin-vertical"
                                    control={
                                        <Switch
                                            checked={currentlyAvailable}
                                            name="currentlyAvailable"
                                            onChange={(e) => handleSwitch(e)}
                                            color=""
                                            style={currentlyAvailable ? { color: "limegreen" } : { color: "grey" }}
                                        />
                                    }
                                    style={{ fontSize: "18px", fontFamily: "Roboto" }}
                                    label={currentlyAvailable ? "Available" : "Unavailable"}
                                    labelPlacement="start"
                                />
                            }
                        </div>
                        <div style={{ paddingLeft: 15, paddingRight: 15, maxWidth: 500 }}>
                            {submitted && !organization.logo_URL &&
                                <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must upload your logo.</p>
                            }
                            <h2>Company Logo <span style={{ color: "red" }}>*</span></h2>
                            <p className="boxText red">{this.state.imageError}</p>
                            <Previews
                                removePreview={this.removePreview}
                                imageUploadFailed={this.imageUploadFailed}
                            />
                            {organization.logo_URL && show_Logo &&
                                <img src={organization.logo_URL} width="250" style={{ marginTop: "2.5%" }} alt="organization logo" />
                            }
                        </div>
                        <div className="col-lg-6">
                            <div className="text_section">
                                {submitted && !organization_namesInput &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's name.</p>
                                }
                                <h2>Company Name <span style={{ color: "red" }}>*</span></h2>
                                <input id="organization_Name" name="organization_namesInput" value={organization_namesInput} maxLength="450" required onChange={this.props.updateOrganizationName} />
                                {organizationNameError && <p style={{ color: "indianred", fontWeight: 600, fontSize: 14 }}>This organization name already exists.</p>}
                            </div>
                            <div className="text_section">
                                {submitted && !organization.website_URL &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's website.</p>
                                }
                                <h2>Website <span style={{ color: "red" }}>*</span></h2>
                                <input name="website_URL" value={organization.website_URL} onChange={this.props.handleChange} />
                            </div>
                            <div className="text_section">
                                {submitted && !organization.description &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's description.</p>
                                }
                                <h2>Company Description <span style={{ color: "red" }}>*</span></h2>
                                <textarea id="organizationTextArea" name="description" value={organization.description} onChange={this.props.handleChange}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {submitted && !organization.country &&
                                <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's country.</p>
                            }
                            <label style={{ marginTop: 10 }}>Country <span style={{ color: "red" }}>*</span></label>
                            <Registration_AutoSuggestion
                                style={{ display: "inline-block" }}
                                categories={{ "country": country ? country : [] }}
                                categoryName={"country"}
                                currentSelectedCategories={[organization.country ? organization.country : ""]}
                                handleCategorySelection={this.props.handleLocationSelection}
                                handleCategoryInput={this.props.handleLocationInput}
                                maxLength="50"
                            />
                            {organizationCountryError && <p style={{ color: "red" }}>Please select a valid input.</p>}
                            {organization.country === "United States" && <div className="text_section">
                                <h2>Company Address</h2>
                                {submitted && !organization.address &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's street address.</p>
                                }
                                <label style={{ marginTop: 10 }}>Street Address <span style={{ color: "red" }}>*</span></label>
                                <input name="address" value={organization.address} maxLength="200" onChange={this.props.handleChange} />
                                {submitted && !organization.address &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's city.</p>
                                }
                                <label style={{ marginTop: 10 }}>City <span style={{ color: "red" }}>*</span></label>
                                <input name="city" value={organization.city} maxLength="50" onChange={this.props.handleChange} />
                                {submitted && !organization.state &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's state.</p>
                                }
                                <label style={{ marginTop: 10 }}>State <span style={{ color: "red" }}>*</span></label>
                                <Registration_AutoSuggestion
                                    style={{ display: "inline-block" }}
                                    categories={{ "state": state ? state : [] }}
                                    categoryName={"state"}
                                    currentSelectedCategories={[organization.state ? organization.state : ""]}
                                    handleCategorySelection={this.props.handleLocationSelection}
                                    handleCategoryInput={this.props.handleLocationInput}
                                    maxLength="50"
                                />
                                {organizationStateError && <p style={{ color: "red" }}>Please select a valid input.</p>}
                                {submitted && !organization.zipCode &&
                                    <p style={{ color: "red", marginBottom: -10, marginTop: 25 }}>You must specify your organization's zip code.</p>
                                }
                                <label style={{ marginTop: 10 }}>ZipCode <span style={{ color: "red" }}>*</span></label>
                                <input name="zipCode" value={organization.zipCode} maxLength="50" onChange={this.props.handleChange} />
                            </div>}
                            <div className="text_section">
                                <h2>Phone Number</h2>
                                <input name="phone_Number" value={organization.phone_Number} maxLength="50" onChange={this.props.handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
                {service_Provider && editOrganization &&
                    <div>
                        <hr style={{ marginTop: 75 }} />

                        <div id="additionalAttributes">
                            <EditOrganizationAdditionalAttributes
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                                handleChange={handleChange}
                                submitted={submitted}
                                service_Provider={service_Provider}
                                handleCategorySelection={handleCategorySelection}
                                handleCategoryInput={handleCategoryInput}
                                handleEditCategoryInput={this.props.handleEditCategoryInput}
                                handleEditCategorySelection={this.props.handleEditCategorySelection}
                                category_TagsError={category_TagsError}
                                editOrganization={editOrganization}
                                industry_Focus_List={industry_Focus_List}
                                industry_Service_List={industry_Service_List}
                                foundation_Expertise_List={this.props.foundation_Expertise_List}
                                agency_Expertise_List={this.props.agency_Expertise_List}
                                program_Expertise_List={this.props.program_Expertise_List}
                                checkboxes={this.props.checkboxes}
                                handleCheckbox={this.props.handleCheckbox}
                                handleDefaultCheckbox={this.props.handleDefaultCheckbox}
                                stringFoundationTypes={this.props.stringFoundationTypes}
                                stringAgencyTypes={this.props.stringAgencyTypes}
                                stringProgramTypes={this.props.stringProgramTypes}
                                stringFocusTypes={this.props.stringFocusTypes}
                                stringServiceTypes={this.props.stringServiceTypes}
                                editErrors={this.props.editErrors}
                                isEmptyCheckbox={this.props.isEmptyCheckbox}
                                handleEditChange={this.props.handleEditChange}
                                editTextInput={this.props.editTextInput}
                                handleAdditionalSubmit={this.props.handleAdditionalSubmit}
                            />
                        </div>
                    </div>
                }

                <hr style={{ marginTop: 75 }} />
                <div id="mainSettings">
                    <div className="row" style={{ marginTop: "5%" }}>
                        <h1 id="dashHeader">Advanced Settings</h1>
                        <div className="col-lg-6">
                            {/* <EditOrganizationSAM
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            /> */}
                            <EditOrganization8A
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationWOB
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationVOB
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationHubzone
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationSB
                                org={org}
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                        </div>
                        <div id="advancedSettingsTextAreas" className="col-lg-6">
                            <EditOrganizationDUNS
                                org={org}
                                handleChange={this.props.handleChange}
                            />
                            <EditOrganizationNAICS
                                org={org}
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationIndustries
                                org={org}
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationTechnologies
                                org={org}
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationImpact
                                org={org}
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationForm = connect(mapStateToProps)(EditOrganizationForm);
export { connectedEditOrganizationForm as EditOrganizationForm };
