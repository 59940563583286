//this file is no longer in use ?

import React from 'react';
import { connect } from 'react-redux';
import { ServiceProvidersChatBoxDropDown, ServiceProvidersMessages } from '.';
import '../../Styles/ServiceProvider.css';
import { notificationsActions, serviceProviderActions } from '../../_actions';
import { chatService } from '../../_services';

class ServiceProvidersChatBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newMessage: '',
            conversationProxy: this.props.conversationProxy,
            messages: [],
            loadingState: 'initializing',
            boundConversations: new Set(),
            prevPage: "",
            messageInfo: {
                channel: null,
                last_Message: "",
                last_Message_User_Id: "",
            },

            show_dropdown: false
        };
    }

    loadMessagesFor = (thisConversation) => {
        if (this.state.conversationProxy === thisConversation) {
            thisConversation.getMessages()
                .then(messagePaginator => {
                    if (this.state.conversationProxy === thisConversation) {
                        let prevPage = "";
                        if (messagePaginator.hasPrevPage) {
                            prevPage = messagePaginator.prevPage;
                        }
                        this.setState({ messages: messagePaginator.items, loadingState: 'ready', prevPage });
                    }
                })
                .catch(err => {
                    console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    this.setState({ loadingState: "failed" });
                });
        }
    };

    loadMore = async () => {
        let { prevPage } = this.state;

        const messagePaginator = await prevPage();
        prevPage = "";
        if (messagePaginator.hasPrevPage) {
            prevPage = messagePaginator.prevPage;
        }
        this.setState({ messages: [...messagePaginator.items, ...this.state.messages], loadingState: 'ready', prevPage });
    }

    componentDidMount = () => {
        if (this.state.conversationProxy) {
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }

        if (!this.props.email_notification_settings) {
            const user_id = JSON.parse(localStorage.getItem('user')).id;
            this.props.dispatch(notificationsActions.get_email_notification_settings(user_id));
        }

        const objDiv = document.getElementById('messageWindowBody');
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    componentDidUpdate = (oldProps, oldState) => {
        if (this.state.conversationProxy !== oldState.conversationProxy) {
            this.setState({
                messages: []
            });

            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }
        const objDiv = document.getElementById('messageWindowBody');
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    static getDerivedStateFromProps(newProps, oldState) {
        let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
        if (logic) {
            return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
        } else {
            return null;
        }
    }

    messageAdded = (message, targetConversation) => {
        if (targetConversation === this.state.conversationProxy)
            this.setState((prevState, props) => ({
                messages: [...prevState.messages, message]
            }));

        const user = JSON.parse(localStorage.getItem("user"));
        if (user.message_Identity && (message.author != user.message_Identity)) {
            const messageInfo = {
                channel: this.props.client.channel,
                last_Message_Read: new Date()
            }

            chatService.updateChatInfo(messageInfo);
        }
    };

    onMessageChanged = event => {
        this.setState({ newMessage: event.target.value });
    };

    sendMessage = async event => {
        // alert(JSON.stringify(this.props.client));
        event.preventDefault();
        const message = this.state.newMessage;
        if (!message) {
            return
        }
        this.setState({ newMessage: '' });
        this.state.conversationProxy.sendMessage(message);
        const user_id = JSON.parse(localStorage.getItem('user')).id;

        await this.setState({
            messageInfo: {
                channel: this.props.client.channel,
                last_Message: message,
                last_Message_User_Id: user_id,
                user_Notified: false
            }
        })

        chatService.updateChatInfo(this.state.messageInfo);

        let client = Object.assign({}, this.props.client);
        client.last_Message_User_Id = user_id;
        client.last_Message = message;
        client.last_Message_Sent = (new Date()).toISOString();
        this.props.dispatch(serviceProviderActions.alertChannelUpdate(client));

        // const { email_notification_settings } = this.props;
        // if (email_notification_settings && email_notification_settings.all_Direct_Messages) {

        //     // establish correct username
        //     let user_name = client.user_Info ? client.user_Info.username : client.username;
        //     chatService.send_email_notification_about_dm(user_id, user_name);
        // }
    };

    handleInput = (e) => {
        let value = e.target.value;

        this.setState({
            newMessage: value
        });
    }

    show_dropdown = (e) => {
        if ((e.nativeEvent && e.nativeEvent.path[0].id === "chatboxDropdownButton") ||
            (e.path && e.path[0].id === "chatboxDropdownButton")
        ) {
            e.stopPropagation();
        }

        let show_dropdown = this.state.show_dropdown;

        if (!show_dropdown) {
            document.getElementById("chatboxDropdownButton").classList.add("dropdownButton_open");
            document.getElementById("messageWindow").addEventListener("click", this.show_dropdown);
        } else {
            document.getElementById("chatboxDropdownButton").classList.remove("dropdownButton_open");
            document.getElementById("messageWindow").removeEventListener("click", this.show_dropdown);
        }

        this.setState({
            show_dropdown: !show_dropdown
        });
    }

    handleInputFocus = (e) => {
        let el = e.target;
        el.classList.add("message_input_focus");
        el.classList.remove("message_input");

        const messageContainer = document.getElementById("messageWindowBody");
        messageContainer.classList.add("chatContainer_input_focus");
        messageContainer.classList.remove("chatContainer_input");
        messageContainer.scrollTo(0, messageContainer.scrollHeight);

    }

    handleInputOnBlur = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let el = e.target;
        el.classList.add("message_input");
        el.classList.remove("message_input_focus");

        const messageContainer = document.getElementById("messageWindowBody");
        messageContainer.classList.add("chatContainer_input");
        messageContainer.classList.remove("chatContainer_input_focus");
        messageContainer.scrollTo(0, messageContainer.scrollHeight);

        if (e.nativeEvent.relatedTarget && e.nativeEvent.relatedTarget.id == "sendMessageButton") {
            if (this.state.newMessage) {
                e.nativeEvent.relatedTarget.click();
            }
        }
    }

    render() {
        const { newMessage, messages, prevPage, show_dropdown } = this.state;
        const { client } = this.props;

        return (
            <div style={{ background: "pink" }} >
                <div id="messageWindow">

                    {/* HEADER */}
                    <div id="messageWindowHeader" style={{ position: "relative", display: "flex" }}>

                        <img
                            src={(client.user_Info && client.user_Info.logo_URL) ? client.user_Info.logo_URL : "https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/alternate.png"}
                            style={{ width: 50, height: 50 }}
                            alt="user icon"
                        />
                        <div className="messageWindowNameAndAction">
                            {client &&
                                <h1>
                                    {client.user_Info ? client.user_Info.name : client.name} {client.user_Info ? client.user_Info.surname : client.surname}
                                </h1>
                            }
                            <div>
                                {show_dropdown &&
                                    <ServiceProvidersChatBoxDropDown
                                        organization_Id={client.user_Info.organization_Id}
                                        channel_type={this.props.channel_type}
                                    />
                                }
                                {this.props.channel_type !== "client" &&
                                    <button
                                        id="chatboxDropdownButton"
                                        className="dropdownButton"
                                        onClick={this.show_dropdown}
                                    >
                                        Take Action
                                    </button>
                                }
                            </div>
                        </div>

                        <button
                            id="closeChatButton"
                            onClick={this.props.closeChat}
                            style={{ width: 25, position: "absolute", top: "15px", right: "15px" }}
                        >
                            X
                        </button>

                    </div>

                    {/* BODY */}
                    <div id="messageWindowBody" >
                        {client &&
                            <ServiceProvidersMessages
                                messages={messages}
                                loadMore={prevPage ? this.loadMore : null}
                                channel={client}
                            />
                        }
                    </div>

                    {/* FOOTER */}
                    <div id="messageWindowFooter">
                        <textarea class="message_input" placeholder="Click to start typing..." value={newMessage} onChange={this.handleInput} onFocus={this.handleInputFocus} onBlur={this.handleInputOnBlur} />
                        <input id="sendMessageButton" class="message_send" type="button" value="Send" onClick={this.sendMessage} />
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { email_notification_settings } = state.get_email_notification_settings;
    let new_email_notification_settings = state.update_email_notification_settings.email_notification_settings;

    if (new_email_notification_settings) {
        email_notification_settings = new_email_notification_settings;
    }

    return (
        email_notification_settings
    )
}

const connectedServiceProvidersChatBox = connect(mapStateToProps)(ServiceProvidersChatBox);
export { connectedServiceProvidersChatBox as ServiceProvidersChatBox };
