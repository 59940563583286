import { css } from "@emotion/core";
import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import '../../../Styles/SearchResults.css';

const override = css`
  display: block;
  margin: auto;
  top: 45%;
  left: 45%;
  position: absolute;
`;

export class Loader extends React.Component {

    render() {
        return (
            <div className="sweet-loading">
                <ClipLoader
                    css={override}
                    size={75}
                    color={"#123abc"}
                //loading={this.props.searchResult.loading}
                />
            </div>
        )
    }
}
