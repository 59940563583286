import { organizationStripeConstants } from '../_constants';
import { sharedService } from '../_services';

export function billingInfo_org(state = {
  billingAddress: {},
  card: {},
  bank_Account: {},
  paymentSelection: 'card',
  loading_getbilling: false,
  loading_bankRequest: false
}
  , action) {
  switch (action.type) {
    /* Payment Information */
    case organizationStripeConstants.GET_BILLING_INFO_REQUEST:
      return {
        ...state,
        loading_getbilling: true
      }
    case organizationStripeConstants.GET_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billingAddress: {},
        card: {},
        bank_Account: {},
        ...action.billingInfo,
        paymentSelection: sharedService.getPaymentSelection(action.billingInfo),
        loading_getbilling: false
      }
    case organizationStripeConstants.GET_BILLING_INFO_FAILURE:
      return {
        ...state,
        loading_getbilling: true
      }
    case organizationStripeConstants.UPDATE_BILLING_INFO_REQUEST:
      return {
        ...state,
        paymentSelection: action.paymentSelection
      }

    /* Individual Billing */
    case organizationStripeConstants.SET_INDIVIDUAL_BILLING:
      return {
        ...state,
        same_as_indv: action.same_as_indv
      }

    /* Billing Address */
    case organizationStripeConstants.BILLING_ADDRESS_REQUEST:
      return { ...state, loading_billingAdress: true };
    case organizationStripeConstants.BILLING_ADDRESS_SUCCESS:
      return { ...state, status: "success", loading_billingAdress: false, billingAddress: action.billingAddress };
    case organizationStripeConstants.BILLING_ADDRESS_FAILURE:
      return { ...state, status: "failure", loading_billingAdress: false };

    /* Bank Verification */
    case organizationStripeConstants.BANK_VERIFICATION_SUCCESS:
      return {
        ...state,
        billingAddress: {},
        card: {},
        bank_Account: {},
        ...action.billingInfo,
        loading_bankRequest: false
      }

    case organizationStripeConstants.BANK_VERIFICATION_REQUEST:
      return {
        ...state,
        loading_bankRequest: true
      }

    case organizationStripeConstants.BANK_VERIFICATION_FAILURE:
      return {
        ...state,
        loading_bankRequest: false
      }

    default:
      return state
  }
}

export function organizationstripe_addbillinginfo(state = {}, action) {
  switch (action.type) {
    case organizationStripeConstants.ADDBILLINGINFO_REQUEST:
      return {
        loading: true
      };
    case organizationStripeConstants.ADDBILLINGINFO_SUCCESS:
      return {
        billing_info_added: true
      };
    case organizationStripeConstants.ADDBILLINGINFO_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}