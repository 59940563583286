import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const stripeService = {
    createStripeAccount,
    createStripeConnectAccount,
    onboardConnectedStripeAccount,
    checkStripeConnectVerification,
    deleteStripeConnectAccount,
    updateStripeConnectPaymentAccount,

    verifyBankAccount,

    handleStripeToken,
    getBilling,
    getBillingInfo,
    getOrgBillingInfo,
    getSubscriptions,
    getBillingAddress,
    updateBillingAddress,
    getAllProducts,
    purchaseProduct,
    purchaseProductType,
    getPurchasedPrices,
    getCouponDiscount,
    setOrgBillingSameAsIndv,

    getInvoicePrices,
    updateInvoiceStatus,

    redirectToBilling,
    redirectToConnect,
    getCheckoutSuccessMessage,
    getDashboardRedirectUrl,
    getPurchaseHistory,
    updateUserDetails,
    cancelSubscription,
    updateSubscription,
    cancelSubscriptionDowngrade,
    getPlanSubscription,
    getPricesByGroup,
    cancelPlanSubscription,
    renewPlanSubscription,

    getAllInvoices,
    getInvoicesByClient,
    getInvoicesByServiceProvider,
    getInvoiceDetails,
    getInvoiceUrl,
    getMpInvoice,
    getSpInvoice,
};

function createStripeAccount(code, state) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ code, state })
    };

    return fetch(API_Route + '/api/stripe/stripe_connect', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function createStripeConnectAccount(service_Provider, account_Type) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(service_Provider)
    };

    return fetch(API_Route + '/api/stripe/create_stripe_connect_account?account_Type=' + account_Type, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function onboardConnectedStripeAccount(stripe_Id, base_URL) {
    // alert("STRIPE ONBOARDING SERVICEL: " + stripe_Id + " ," + base_URL);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/generate_stripe_connect_link?stripe_Id=' + stripe_Id + "&base_URL=" + base_URL, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function checkStripeConnectVerification(stripe_Account_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/check_stripe_connect_verification?stripe_Id=' + stripe_Account_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteStripeConnectAccount(stripe_Account_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/delete_stripe_connect_account?stripe_Id=' + stripe_Account_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateStripeConnectPaymentAccount(stripe_Account_Id, stripe_Token_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/update_stripe_connect_payment?stripe_Id=' + stripe_Account_Id + "&token_Id=" + stripe_Token_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function verifyBankAccount(user_id, deposit_1, deposit_2) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }

    return fetch(API_Route + '/api/stripe/verify_bank_account?user_id=' + user_id + "&deposit_1=" + deposit_1 + "&deposit_2=" + deposit_2, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function handleStripeToken(billingAddress) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ ...billingAddress })
    };

    return fetch(API_Route + '/api/settings/billing_profile', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getBillingAddress(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ userId })
    };

    return fetch(API_Route + '/api/settings/billing_address', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateBillingAddress(billings) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(billings)
    };

    return fetch(API_Route + '/api/stripe/update_billing_address', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getBilling(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getBilling?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getBillingInfo(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getBillingInfo?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getOrgBillingInfo(userId, org_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getOrgBillingInfo?user_id=' + userId + '&org_id=' + org_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getSubscriptions(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getSubscriptions?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllProducts() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getStripeProducts', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function purchaseProduct(productDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productDetails)
    };

    return fetch(API_Route + '/api/stripe/purchaseProduct', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function purchaseProductType(userId, productGroup) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productGroup)
    };

    return fetch(API_Route + '/api/stripe/purchaseProductType?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getDashboardRedirectUrl(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getDashboardRedirectUrl?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getPurchasedPrices(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getPurchasedPrices?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getCouponDiscount(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(paymentDetails)
    };

    return fetch(API_Route + '/api/stripe/getCouponDiscount', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function setOrgBillingSameAsIndv(userDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(userDetails)
    };

    return fetch(API_Route + '/api/stripe/set_org_billing_same_as_indv', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getInvoicePrices(email, invoiceId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getInvoicePrices?email=' + email + '&invoiceId=' + invoiceId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateInvoiceStatus(invoice) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(invoice)
    };

    return fetch(API_Route + '/api/stripe/update_invoice_status', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function redirectToBilling(userId, returnUrl) {
    const server = window.location.protocol + '//' + window.location.host + returnUrl;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/redirectToBilling?user_id=' + userId + '&returnUrl=' + server, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function redirectToConnect(userId, returnUrl) {
    const server = window.location.protocol + '//' + window.location.host + returnUrl;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/redirectToConnect?user_id=' + userId + '&returnUrl=' + server, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getCheckoutSuccessMessage(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getCheckoutSuccessMessage?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getPurchaseHistory(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/stripe/getPurchaseHistory?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateUserDetails(productDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productDetails)
    };

    return fetch(API_Route + '/api/stripe/updateUserDetails', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function cancelSubscription(subscriptionId, isFree) {
    // var body = { subscriptionId, isFree }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch(API_Route + '/api/stripe/cancelSubscription?isFree=' + isFree, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function cancelSubscriptionDowngrade(userId, subscriptionId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch(API_Route + '/api/stripe/cancelSubscriptionDowngrade?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function cancelPlanSubscription(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/cancelPlanSubscription?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getPlanSubscription(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/getPlanSubscription?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getPricesByGroup(group, excluded_group) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(excluded_group)
    };

    return fetch(API_Route + '/api/stripe/getPricesByGroup?group=' + group, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function renewPlanSubscription(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/renewPlanSubscription?user_id=' + userId, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateSubscription(subscriptionId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch(API_Route + '/api/stripe/updateSubscription', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllInvoices(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/get_all_invoices?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getInvoicesByClient(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/get_invoices_by_client?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getInvoicesByServiceProvider(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/get_invoices_by_service_provider?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getInvoiceDetails(user_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/get_inovice_details?user_id=' + user_id + "&invoice_id=" + invoice_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getInvoiceUrl(user_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/stripe/get_invoice_url?user_id=' + user_id + "&invoice_id=" + invoice_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getMpInvoice(project_id) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/marketplaceproject/get_invoices/mp_project/' + project_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getSpInvoice(project_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/get_invoices/project/' + project_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}