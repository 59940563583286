import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Component } from "react";
import { connect } from "react-redux";
import { favoritesActions } from '../../../_actions';
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "../../../Styles/NavMenu_v2.css";
import "../../../_assets/img/icons/sideNavIcons/css/fontello.css";
import { asyncLocalStorage, dashboardService } from "../../../_services";

class DesktopSideNavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeColor: localStorage.getItem("themeColor"),
      iFrameUrl: '',
      iFrameUser: '',
      iFramePrimary: null,
      applicantNotification: 0,
    };

    this.goToKB = this.goToKB.bind(this);
  }

  async componentDidMount() {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({
        iFrameUrl: value?.url,
        iFrameUser: value?.user,
        iFramePrimary: value?.primary,
      })
    });

    await this.props.dispatch(favoritesActions.getFavorites(this.props.user));
    this.getApplicantsNotification();
  }

  getApplicantsNotification = async () => {
    const { user } = this.props;

    const dashboard = await dashboardService.loadDashboard(user);

    this.setState({
      applicantNotification: dashboard.new_Applications
    });
  }

  goToKB = () => {
    window.open("https://kb.opengrants.io/", "_blank");
  };

  truncate = (text) => {
    return text.length > 25 ? text.substring(0, 22) + "..." : text;
  };

  render() {
    const user = JSON.parse(localStorage.getItem("user"));

    const { service_Provider, pathName, favorites } = this.props;
    const { iFrameUrl, applicantNotification } = this.state;

    let freeUser = user?.userRoles?.includes("Client_Free");

    return (
      <>
        {(user || !user) && (
          <div className="side-nav-container">
            <div className="side-nav-link-wrapper d-flex flex-column">
              <div className="side-nav-links">
                <NavLink to={"/dashboard" + iFrameUrl}>
                  {pathName?.includes('/dashboard') ? (
                    <i class="icon-home-icon" style={{
                      color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                      webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                    }} />
                  ) : (
                    <i class="icon-home-icon" />
                  )}
                  Dashboard
                </NavLink>
              </div>
              <div className="side-nav-links">
                <NavLink
                  to="/search"
                  isActive={() =>
                    window.location.pathname.includes("/search") ||
                    window.location.pathname.includes("/grants")
                  }
                >
                  {pathName?.includes('/search' || '/grants') ? (
                    <i class="icon-magnify-icon" style={{
                      color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                      webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                    }} />
                  ) : (
                    <i class="icon-magnify-icon" />
                  )}
                  Grant Finder
                </NavLink>
              </div>
              {!service_Provider &&

                <div className="side-nav-links">
                  <NavLink to={"/marketplace" + iFrameUrl}>
                    {(pathName?.includes('/marketplace') && !pathName?.includes('/projects')) ? (
                      <i class="icon-store-icon" style={{
                        color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                        webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                      }} />
                    ) : (
                      <i class="icon-store-icon" />
                    )}
                    Hire Experts
                  </NavLink>
                </div>
              }
              {(!iFrameUrl) &&
              /*((user && user.userRoles !== "Client_Free") 
                || (user && user.userRoles == "Client_Free" 
                    && window.location.href.includes('profiles')))
                && */(
                  <div className="side-nav-links">
                    {/* <ReactTooltip id="savedGrants" effect="solid" placement="bottom" /> */}
                    <NavLink
                      to="/favorites"
                      // data-tip={freeUser ? "Upgrade your account to save listings." : ""}
                      // data-for="savedGrants"
                      isActive={() => window.location.pathname.includes("/favorites")}
                      style={{ position: "relative" }}
                    >
                      {pathName?.includes('/favorites') ? (
                        <i class="icon-heart-icon" style={{
                          color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                          webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                        }} />
                      ) : (
                        <i class="icon-heart-icon" />
                      )}
                      {favorites?.listings?.length || favorites?.funders?.length ?
                        <div className={`applicantNotificationBubble ${(favorites?.listings?.length + favorites?.funders?.length) > 99 ? "threeDigits" : (favorites?.listings?.length + favorites?.funders?.length) > 9 ? "twoDigits" : ""}`} style={{ right: 106 }}>
                          {(favorites?.listings?.length + favorites?.funders?.length)}
                        </div>
                        :
                        null
                      }
                      Saved Grants
                    </NavLink>
                  </div>
                )
              }
              {user &&
                <div className="side-nav-links">
                  <NavLink
                    to={'/grant-navigator'}
                    isActive={() => window.location.pathname.includes("/grant-navigator")}
                  >
                    {pathName?.includes('/grant-navigator') ? (
                      <i class="icon-briefcase-icon" style={{
                        color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                        webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                      }} />
                    ) : (
                      <i class="icon-briefcase-icon" />
                    )}
                    AI Grant Helper
                  </NavLink>
                </div>
              }
              {user && <>
                <div className="side-nav-links">
                  <NavLink
                    to={"/profiles/" + user.userName + iFrameUrl}
                    isActive={() => window.location.pathname.includes("/profiles")}
                  >
                    {pathName?.includes('/profiles') ? (
                      <i class="icon-usercircle-icon" style={{
                        color: this.state.iFramePrimary !== null ? this.state.iFramePrimary : '#00c58e',
                        webkitTextStroke: this.state.iFramePrimary ? this.state.iFramePrimary : '#00c58e',
                      }} />
                    ) : (
                      <i class="icon-usercircle-icon" />
                    )}
                    Profile
                  </NavLink>
                </div>
              </>
              }
              {service_Provider &&
                <div className="side-nav-links" style={{ position: "relative" }}>
                  <NavLink to={"/organizations/" + service_Provider.organization_Id} style={{ display: "flex", alignItems: "center" }}>
                    <i class="icon-usercircle-icon" style={{ marginRight: 28 }} />
                    <i className="icon-store-icon" style={{ position: "absolute", bottom: -5, left: 20, fontSize: 12, background: "#fafafb", borderRadius: "50%" }} />
                    Marketplace Profile
                  </NavLink>
                </div>
              }
            </div >
          </div >
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;
  const { items } = state.getFavorites;

  return {
    user,
    service_Provider,
    favorites: items,
  };
}

const connectedLoginPage = connect(mapStateToProps)(DesktopSideNavMenu);
export { connectedLoginPage as DesktopSideNavMenu };
