import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { format, parseISO } from "date-fns";
import React from 'react';
import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import he from '../../../node_modules/he';
import TextTruncate from '../../../node_modules/react-text-truncate';
import '../../Styles/ListingModal.css';
import '../../Styles/Recommended.css';
import { matchesActions } from '../../_actions';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";

const viewListing = (id) => {
    const server = window.location.protocol + '//' + window.location.host;
    const source_link = server + "/grants/" + id;

    window.open(source_link, "_blank")
}

class Match extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            modal: props.initialModalState,
            fade: true,
            themeColor: localStorage.getItem('themeColor')
        };

        this.saveListing = this.saveMatch.bind(this);
        this.discardListing = this.discardMatch.bind(this);
    }

    saveMatch(e) {
        const { listing, user } = this.props;

        //sheannigans to ease out result card
        let elementId = 'hit_' + listing.listing_Id;
        let element = document.getElementById(elementId);
        ReactDOM.findDOMNode(element).style.visibility = 'none';
        ReactDOM.findDOMNode(element).style.transition = 'opacity 1s ease-out';
        ReactDOM.findDOMNode(element).style.opacity = '0';
        setTimeout(() => {
            ReactDOM.findDOMNode(element).style.display = 'none';
        }, 500);

        this.props.dispatch(matchesActions.saveMatch(listing.listing_Id, user, true));
    }

    async discardMatch(e) {
        const { listing, user } = this.props;

        //sheannigans to ease out result card
        let elementId = 'hit_' + listing.listing_Id;
        let element = document.getElementById(elementId);
        ReactDOM.findDOMNode(element).style.visibility = 'none';
        ReactDOM.findDOMNode(element).style.transition = 'opacity 1s ease-out';
        ReactDOM.findDOMNode(element).style.opacity = '0';
        setTimeout(() => {
            ReactDOM.findDOMNode(element).style.display = 'none';
        }, 500);

        this.props.dispatch(matchesActions.discardMatch(listing.listing_Id, user));
    }

    render() {
        const listing = this.props.listing;
        const app_due_date = listing.application_Due_Date ? format(parseISO(listing.application_Due_Date), "MMMM do, yyyy") : null;
        // const visible = this.state.visible;

        return (
            <li className="ais-Hits-item" id={"hit_" + listing.listing_Id}>
                <div className="matchBody" style={{ height: "100%" }}>
                    <div class="matchTitleAgency">
                        <div class="hit-title">
                            <TextTruncate
                                line={2}
                                element="span"
                                truncateText="…"
                                text={he.decode(listing.title.trim())}
                                style={{ fontWeight: 900 }}
                            />
                        </div>
                        <div class="hit-agency" style={{ marginTop: 5 }}>
                            {listing.agency}
                        </div>
                    </div>
                    <hr />
                    <div className="hit-funding">
                        {(listing.funding_Amount != null && listing.funding_Amount > 0) &&
                            <NumberFormat value={listing.funding_Amount} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={" USD"} />
                        }
                        {(listing.funding_Amount === 0 || !listing.funding_Amount) &&
                            <p>Funding varies.</p>
                        }
                    </div>

                    {/* DUE DATE OUTPUT */}
                    <div className="hit-description">
                        {app_due_date && <p><strong>Due: </strong>{app_due_date}</p>}
                        {!app_due_date && <p>Applications ongoing.</p>}
                    </div>
                    <hr />
                    {/* <br /> */}
                    <div class="hitActions">
                        <div class="saveMatchDiv"
                            onClick={this.saveListing}
                            data-tip="Save match."
                        >
                            <ReactTooltip effect="solid" />
                            <i className="material-icons" id="discardMatchButton">
                                save
                            </i>
                        </div>
                        <div class="clearMatchDiv"
                            onClick={this.discardListing}
                            data-tip="Discard match."
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            </svg>
                        </div>
                        <Button
                            color="info"
                            onClick={() => { viewListing(listing.listing_Id) }}
                            style={{ background: "#29a" }}
                        >
                            View Grant
                            <ArrowForwardIos style={{ float: "right", fontSize: 32, marginTop: 0, marginLeft: 10, marginRight: -10 }} />
                        </Button>
                    </div>
                </div>
            </li>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;

    return {
        user,
    };
}

const connectedMatchPage = connect(mapStateToProps)(Match);
export { connectedMatchPage as Match };

