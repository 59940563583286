import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import { marketplaceprojectActions, serviceProviderActions } from '../../_actions';
import { marketplaceProjectObjects } from '../../_components/_objects';
import { history } from '../../_helpers';
import { Loader as Loading } from '../../_pages/Shared/Loader';
import { marketplaceProjectService, pluginService, serviceProviderService, sharedService, stripeService } from '../../_services';
import DeleteHoursModal from "./DeleteHoursModal";
import SegmentDisplay from "./SegmentDisplay";
import SegmentForm from "./SegmentForm";

const emptyObject = {}

let accentColor = pluginService.getIFrameColor()

class Hours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      projectSelectionOptions: [], //dropdown list of projects
      selectedProject: {}, //selected project
      selectedProjectId: '', //selected project id
      isSelectedMP: false, //check if selected project is mp
      displaySingleProject: false,

      newSegment: {}, //segment to add
      selectedSegment: "", //segment to edit
      segmentToDelete: "", //segment to delete

      modalOpen: false, //delete confirmation modal state
      isEditing: false, //check if hours are being edited
      submitted: false, //check if segment is submitted
      noChanges: false, //check if segment was changed

      stripe_Verified: "",
      filesToAdd: [],
      linksToAdd: [],
      filesToDelete: [],
      linksToDelete: [],
    }
  }

  componentDidMount = async () => {
    const { user, service_Provider } = this.props

    //Get all projects on mount
    this.props.dispatch(serviceProviderActions.getAllProjects(user.id));
    if (service_Provider) {
      this.props.dispatch(serviceProviderActions.getProjects(service_Provider.service_Provider_Id));
    }
    this.props.dispatch(marketplaceprojectActions.getAllProjectByUser(this.props.user.id, ["documents", 'client organization', 'segments']))

    //Redirect to a specific project if coming from the projects page
    if (service_Provider) {
      const stripe_Verified = await stripeService.checkStripeConnectVerification(service_Provider.stripe_Account_Id);

      this.setState({
        stripe_Verified,
      })

      //if in projects page, display specific project hours
      if (this.props.singleProjectId) {
        this.handleSelectionChange()
      }
    }

  }

  //after getting updated single project, set it to state
  componentDidUpdate = (prevProps) => {

    const { displaySingleProject } = this.state;
    const { project, mpProject } = this.props

    if ((prevProps.project !== project) && project.project && displaySingleProject) {
      let selectedProject = project;
      selectedProject.project_Id = selectedProject.project.project_Id;

      this.setState({
        selectedProject: selectedProject
      })
    }

    if ((prevProps.mpProject !== mpProject) && mpProject && displaySingleProject) {
      this.setState({
        selectedProject: mpProject
      })
    }

  }

  //Download an existing file
  downloadFile = async (documentObj, project) => {

    let blob;

    if (project.applications) {
      blob = await marketplaceProjectService.download_mp_document(documentObj);
    }
    else {
      blob = await serviceProviderService.downloadDocument(documentObj);
    }

    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    aTag.download = documentObj.document_Name
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  //Format date for date input field 
  formatDate = (date) => {
    let newDate = new Date(date);
    return newDate.toISOString().split('T')[0]
  }

  //Check if project is completed but has uninvoiced hours
  hasUninvoicedHours = (project) => {
    let hasHours = false;

    if (project && project.project_Segments && project.project_Segments.length !== 0) {

      project.project_Segments.forEach((segment) => {
        if (segment.is_Editable) {
          hasHours = true;
        }
      })
    }

    return hasHours;
  }

  //Create project selection options
  generateOptions = () => {
    const { projects } = this.props

    let projectSelectionOptions = [];

    //push default selection value
    projectSelectionOptions.push({
      value: "",
      label: <strong style={{ fontFamily: "Lato" }}>See All Hours</strong>
    })

    //push project titles 
    projects.forEach((project) => {

      let activeProject = project && project.project_Status.toLowerCase() === "active";
      let completedProject = project && (project.project_Status === "Complete" || project && project.project_Status === "completed") && this.hasUninvoicedHours(project);

      if ((activeProject || completedProject) && (project.client_Organization?.name || project.client_Organization_Name)) {

        projectSelectionOptions.push({
          type: project.applications ? "mpProject" : "spProject",
          value: project.project_Id,
          label: <span style={{ fontFamily: "Lato" }}><strong>{project.applications ? project.client_Organization.name : project.client_Organization_Name}</strong> - {project.project_Title}</span>
        })
      }
    });

    return projectSelectionOptions;
  }

  //Fetch updated project(s)
  getUpdatedProject = async () => {
    const { selectedProject } = this.state;
    const { user } = this.props

    //fetch single project
    if (Object.keys(selectedProject).length !== 0) {

      if (selectedProject.applications) {
        if (this.props.singleProjectId && this.props.isMpProject) {
          let result = await marketplaceProjectService.get_project(this.props.singleProjectId, ["documents", 'applications', "client organization", "segments"])
          this.setState({
            selectedProject: result,
          })
        }
        else {
          this.props.dispatch(marketplaceprojectActions.getProject(selectedProject.project_Id, ['documents', 'applications', 'client organization', 'segments']))
        }
      }
      else {
        this.props.dispatch(serviceProviderActions.getProject(selectedProject.project_Id, user.id));
      }
    }
    //fetch all projects
    this.props.dispatch(marketplaceprojectActions.getAllProjectByUser(this.props.user.id, ['documents', 'client organization', 'segments']))
    this.props.dispatch(serviceProviderActions.getAllProjects(user.id));

  }

  //Submit new hours segment
  submitNewHours = async () => {
    const { newSegment, selectedProjectId } = this.state
    const { user, service_Provider } = this.props

    this.setState({
      submitted: true,
    })

    //handle errors before submitting 
    if (this.validateHours(newSegment)) {

      newSegment.project_ID = selectedProjectId;

      //add required info for mp segment
      if (this.state.isSelectedMP) {

        //get uploaded attachments
        let document_files = localStorage.getItem("document_uploads") ? JSON.parse(localStorage.getItem("document_uploads")) : [];
        let document_links = localStorage.getItem("document_links") ? JSON.parse(localStorage.getItem("document_links")) : [];

        if (document_files && document_files.length !== 0) {
          document_files.forEach((file, idx) => {
            let new_file = Object.assign({}, marketplaceProjectObjects.mp_project_document);
            new_file.document_Name = file.document_name;
            new_file.document_File = file.document_file;
            new_file.project_Id = selectedProjectId;

            document_files[idx] = new_file;
          });
        }

        if (document_links && document_links.length !== 0) {
          document_links.forEach((link, idx) => {
            let new_link = Object.assign({}, marketplaceProjectObjects.mp_project_document);
            new_link.document_Link = link.document_Link;
            new_link.project_Id = selectedProjectId;

            document_links[idx] = new_link;
          });
        }

        newSegment.documents = [...document_files, ...document_links];

        await marketplaceProjectService.create_work_segment(newSegment);
      }

      //add required info for sp segment
      else {

        newSegment.service_Provider_Id = service_Provider.service_Provider_Id;
        newSegment.service_Provider_User_ID = user.id;

        let document_files = localStorage.getItem("document_uploads");
        if (document_files) {
          newSegment.document_files = JSON.parse(localStorage.getItem("document_uploads"))
        }

        let document_links = localStorage.getItem("document_links");
        if (document_links) {
          newSegment.document_links = JSON.parse(localStorage.getItem("document_links"))
        }

        await serviceProviderService.createWorkSegment(newSegment);
      }

      //clear selected segment states
      this.cancelEdit();

      //fetch updated project(s)
      this.getUpdatedProject();

      this.setState({
        submitted: false,
      })
    }
  }

  //Submit edited hours segment
  submitEditHours = async (originalSegment) => {
    const { selectedSegment, filesToAdd, linksToAdd, filesToDelete, linksToDelete } = this.state;

    let segmentUnchanged = (originalSegment.segment_Title === selectedSegment.segment_Title)
      && (originalSegment.segment_Hours === selectedSegment.segment_Hours)
      && (originalSegment.segment_Hourly_Rate === selectedSegment.segment_Hourly_Rate)
      && (this.formatDate(originalSegment.segment_Date_Performed) === (selectedSegment.segment_Date_Performed && this.formatDate(selectedSegment.segment_Date_Performed)));

    let attachmentsUnchanged = ((filesToAdd.length === 0) && (linksToAdd.length === 0) && (filesToDelete.length === 0) && (linksToDelete.length === 0));

    this.setState({
      submitted: true,
    })

    //check if any information or attachments were changed
    if (segmentUnchanged && attachmentsUnchanged) {
      this.setState({
        noChanges: true,
      })

      return;
    }
    //if yes, submit
    else {
      //handle errors before submitting
      if (this.validateHours(selectedSegment)) {

        //submit edited segment
        if (!segmentUnchanged) {
          if (this.state.isSelectedMP) {
            await marketplaceProjectService.update_work_segment(selectedSegment)
          }
          else {
            await serviceProviderService.editWorkSegment(selectedSegment);
          }
        }
        //submit edited attachments
        if (!attachmentsUnchanged) {

          //mp project
          if (this.state.isSelectedMP) {

            let docsToDelete = [...filesToDelete, ...linksToDelete]
            let docsToAdd = [...filesToAdd, ...linksToAdd]

            docsToDelete.forEach((doc) => {
              doc.uploaded_By_Client_Organization_Id = 0;
              doc.uploaded_By_Service_Provider_Id = 0;
              doc.uploaded_By_User_Id = '';
              doc.date_Created = new Date().toLocaleDateString();
            })

            docsToAdd.forEach((doc) => {
              doc.uploaded_By_Client_Organization_Id = 0;
              doc.uploaded_By_Service_Provider_Id = 0;
              doc.uploaded_By_User_Id = '';
              doc.date_Created = new Date().toLocaleDateString();
            })

            if (docsToDelete.length !== 0) {
              await marketplaceProjectService.delete_mp_documents(docsToDelete)
            }
            if (docsToAdd.length !== 0) {
              await marketplaceProjectService.upload_mp_documents(docsToAdd)
            }
          }
          //sp project
          else {
            if (filesToDelete.length !== 0) {
              await serviceProviderService.deleteDocumentFiles(filesToDelete)
            }
            if (linksToDelete.length !== 0) {
              await serviceProviderService.deleteDocumentLinks(linksToDelete)
            }
            if (filesToAdd.length !== 0) {
              await serviceProviderService.addDocumentFiles(filesToAdd)
            }
            if (linksToAdd.length !== 0) {
              await serviceProviderService.addDocumentLinks(linksToAdd)
            }
          }
        }

        //fetch updated project(s)
        this.getUpdatedProject();

        //clear selected segment states
        this.cancelEdit();

        this.setState({
          submitted: false,
        })
      }
    }
  }

  //Check for errors in the hours segment
  validateHours = (segment) => {

    //check if any of the fields is empty
    if (!segment.segment_Title || !segment.segment_Hours || !segment.segment_Hourly_Rate || !segment.segment_Date_Performed) {
      return false;
    }
    //check for integer errors in hours and hourly rate
    else if (!sharedService.isFloat(segment.segment_Hours) || !sharedService.isInteger(segment.segment_Hourly_Rate)) {
      return false;
    }
    else {
      return true;
    }
  }

  //Save project id to render form
  addMoreHours = (project) => {
    this.setState({
      selectedProjectId: project.project_Id,
      isSelectedMP: project.applications ? true : false
    })
  }

  //Delete hours segment
  deleteHours = async () => {
    const { segmentToDelete } = this.state

    //delete hours segment
    if (Object.keys(segmentToDelete).length !== 0) {
      await marketplaceProjectService.delete_work_segment(segmentToDelete)
    }
    else {
      await serviceProviderService.deleteWorkSegment(segmentToDelete)
    }

    //close modal and clear segment id for deletion
    this.toggleDeleteModal(false, null)

    //fetch updated project(s)
    this.getUpdatedProject();
  }

  //Open or close delete confirmation modal 
  toggleDeleteModal = (isOpen, segment) => {

    //set modal state and segment id for deletion
    this.setState({
      modalOpen: isOpen,
      segmentToDelete: segment,
    })
  }

  //Save hours segment for editing
  editHours = (project, entry) => {
    this.setState({
      isSelectedMP: project.applications ? true : false,
      selectedProjectId: project.project_Id,
      selectedSegment: entry,
      isEditing: true,
      filesToAdd: [],
      linksToAdd: [],
      filesToDelete: [],
      linksToDelete: [],
    });
  }

  //Cancel hours segment editing
  cancelEdit = () => {
    this.setState({
      selectedProjectId: "",
      isSelectedMP: false,
      selectedSegment: emptyObject,
      newSegment: emptyObject,
      isEditing: false,
      submitted: false,
      noChanges: false,
      filesToAdd: [],
      linksToAdd: [],
      filesToDelete: [],
      linksToDelete: [],
    });
  }

  //Update hours segment input fields for new hours
  handleNewSegmentChange = (e) => {
    const { newSegment } = this.state
    const { name, value, type } = e.target;

    this.setState({
      newSegment: {
        ...newSegment,
        [name]: type === "number" ? parseFloat(value) : value,
      }
    })
  }

  //Update hours segment input fields for edited hours
  handleEditSegmentChange = (e) => {
    const { selectedSegment } = this.state
    const { name, value, type } = e.target;

    this.setState({
      selectedSegment: {
        ...selectedSegment,
        [name]: type === "number" ? parseFloat(value) : value,
      },
      noChanges: false,
    })
  }

  //Update selected project
  handleSelectionChange = async (e) => {
    const { user } = this.props

    let projectId;
    let projectType;

    if (e) {
      projectId = e.value !== "" ? e.value : "";
    }
    else if (window.location.search.includes('type')) {
      projectId = parseInt(window.location.href.split("projects/")[1]?.split("?")[0]);
      projectType = window.location.href.split("type=")[1]?.split("&")[0]

      if (!this.props.singleProjectId) {
        window.history.pushState(null, null, "/invoices-hours?tab=hours")
      }
    }

    //if project was selected from the dropdown, get that project's info and set it to state
    if (projectId) {

      if ((e?.type === "spProject") || (projectType === "sp")) {

        this.props.dispatch(serviceProviderActions.getProject(projectId, user.id));
      }
      else {
        if (this.props.singleProjectId && this.props.isMpProject) {
          let result = await marketplaceProjectService.get_project(this.props.singleProjectId, ["documents", 'applications', "client organization", "segments"])
          this.setState({
            selectedProject: result,
          })
        }
        else {
          this.props.dispatch(marketplaceprojectActions.getProject(projectId, ["documents", 'applications', "client organization", "segments"]));
        }
      }

      this.cancelEdit()
      this.setState({
        displaySingleProject: true,
      })
    }

    //if not, show all projects
    else {
      this.setState({
        selectedProject: emptyObject,
        displaySingleProject: false,
      })
    }
  };

  //Set files/links to be added/deleted on submit
  handleEditAttachment = (attachment, action) => {

    if (action === "filesToAdd") {
      this.setState({
        filesToAdd: attachment
      })
    }
    else if (action === "linksToAdd") {
      this.setState({
        linksToAdd: attachment
      })
    }
    else if (action === "filesToDelete") {
      this.setState({
        filesToDelete: [...this.state.filesToDelete, attachment]
      })
    }
    else {
      this.setState({
        linksToDelete: [...this.state.linksToDelete, attachment]
      })
    }
  }

  //Render total price for project
  renderTotalMoney(project, total) {
    if (project.project_Segments) {
      project.project_Segments.map((entry) => {
        if ((project.project_Id === entry.project_Id) && entry.is_Editable) {
          let amount = entry.segment_Hourly_Rate * entry.segment_Hours;
          total += amount;
        }
      })
      if (total > 0) {
        return <p style={{ margin: 0 }} className="totalHoursPrice">Total Amount: ${total.toFixed(2)}</p>
      }
    }
  }

  //Render project header
  renderProjectHeader = (project, project_Status) => {
    let activeSegments = false;

    if (project.project_Segments) {
      project.project_Segments.forEach((segment) => {
        if (segment.is_Editable) {
          activeSegments = true;
          return;
        }
      })
    }

    return (
      <div style={{ marginTop: 20 }}>
        <p className="hoursProjectTitle">{project.project_Title}</p>

        {(project.project_Segments && (project.project_Segments.length !== 0) && activeSegments) ?

          // DESKTOP

          <div className="row hoursInvoicesHeader">
            <div className="col-md-6"><p>Description of Work</p></div>
            <div className="col-md-1"><p>Hours</p></div>
            <div className="col-md-1"><p>Rate</p></div>
            <div className="col-md-2"><p>Date</p></div>
            <div className="col-md-2"></div>
          </div>
          :
          <div className="row ">
            <p className="col-md-12" style={{ fontFamily: "Lato" }}>
              {project_Status.toLowerCase() !== "active" ?
                "This project is currently not active."
                :
                "You don't have any hours recorded for this project."
              }
            </p>
          </div>
        }
      </div>
    )
  }

  //Render all projects with hours
  renderAllProjects = () => {
    const { selectedProjectId, isEditing, newSegment, submitted, noChanges, stripe_Verified } = this.state
    const { projects, service_Provider } = this.props;

    let total = 0;

    return projects.map((project, idx) =>
      <>
        {((project.client_Organization?.name || project.client_Organization_Name) &&
          (project && project.project_Status.toLowerCase() === "active")
          || (project && (project.project_Status === "Complete" || project && project.project_Status === "completed") && this.hasUninvoicedHours(project))) &&
          <>
            {/* PROJECT HEADER*/}
            {this.renderProjectHeader(project, project.project_Status)}

            {/* PROJECT HOURS */}
            <div className="projectInvoicesContainer">
              {this.renderProjectHours(project)}
            </div>

            {/* NEW HOURS FORM */}
            {(project.project_Id === selectedProjectId) && !isEditing &&
              <SegmentForm
                handleChange={this.handleNewSegmentChange}
                segment={newSegment}
                project={project}
                submitHours={this.submitNewHours}
                cancelHours={this.cancelEdit}
                submitted={submitted}
                noChanges={noChanges}
              />
            }

            <div className="row" style={{ display: "flex", padding: "0 10px" }}>

              {/* ADD HOURS BUTTON */}

              <div className='row col-md-6' style={{ display: "flex" }}>
                <div style={{ marginRight: 40 }}>
                  <ReactTooltip
                    id='lognewhours'
                    className='projectTooltip'
                    place="right"
                    effect="solid"
                    multiline={true}
                    textColor='dark'
                    backgroundColor='white'
                    border={true}
                    borderColor='rgb(128, 128, 128)'
                  />
                  {project.project_Status.toLowerCase() === "active" &&
                    <button
                      className="addMoreHoursBtn"
                      onClick={sharedService.isSPVerified(service_Provider, stripe_Verified) ? () => this.addMoreHours(project) : () => { history.push("/service-provider-success") }}
                      data-tip={!sharedService.isSPVerified(service_Provider, stripe_Verified) ? "Cannot log new hours until verification is complete." : null}
                      data-for='lognewhours'
                      disabled={(project && project.project_Status.toLowerCase() === "active") ? false : true}
                    >
                      <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 10, width: 20, height: 20 }} />
                      Add More Hours
                    </button>
                  }
                </div>
              </div>

              {/* PROJECT TOTAL */}

              <div className='col-md-6' style={{ display: "flex", justifyContent: "flex-end" }}>
                {this.renderTotalMoney(project, total)}
              </div>
            </div>
            <hr />
          </>
        }
      </>
    )
  }

  renderSingleMPProject = () => {
    const { selectedProject, selectedProjectId, isEditing, newSegment, submitted, noChanges, stripe_Verified } = this.state;
    const { service_Provider } = this.props

    let total = 0;

    return (
      <div>
        {/* PROJECT HEADER*/}
        {this.renderProjectHeader(selectedProject, selectedProject.project_Status)}

        {/* PROJECT HOURS */}
        <div className="projectInvoicesContainer">
          {this.renderProjectHours(selectedProject)}
        </div>

        {/* NEW HOURS FORM */}
        {selectedProject.project_Id && selectedProjectId && !isEditing &&
          <SegmentForm
            handleChange={this.handleNewSegmentChange}
            segment={newSegment}
            project={selectedProject}
            submitHours={this.submitNewHours}
            cancelHours={this.cancelEdit}
            submitted={submitted}
            noChanges={noChanges}
          />
        }

        <div className="row" style={{ display: "flex", padding: "0 10px" }}>

          <div className='row col-md-6' style={{ display: "flex" }}>
            <div style={{ marginRight: 40 }}>
              <ReactTooltip
                id='lognewhours'
                className='projectTooltip'
                place="right"
                effect="solid"
                multiline={true}
                textColor='dark'
                backgroundColor='white'
                border={true}
                borderColor='rgb(128, 128, 128)'
              />
              {selectedProject.project_Status === "Active" &&
                <button
                  className="addMoreHoursBtn"
                  onClick={sharedService.isSPVerified(service_Provider, stripe_Verified) ? () => this.addMoreHours(selectedProject) : () => { history.push("/service-provider-success") }}
                  data-tip={!sharedService.isSPVerified(service_Provider, stripe_Verified) ? "Cannot log new hours until verification is complete." : null}
                  data-for='lognewhours'
                  disabled={(selectedProject && selectedProject.project_Status === "Active") ? false : true}
                >
                  <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 10, width: 20, height: 20 }} />
                  Add More Hours
                </button>
              }
            </div>
          </div>

          {/* PROJECT TOTAL */}

          <div className='col-md-8' style={{ display: "flex", justifyContent: "flex-end" }}>
            {this.renderTotalMoney(selectedProject, total)}
          </div>
        </div>
      </div >
    )
  }

  renderSingleProject = () => {
    const { selectedProject, selectedProjectId, isEditing, newSegment, submitted, noChanges, stripe_Verified } = this.state;
    const { service_Provider } = this.props

    let total = 0;

    selectedProject.project_Title = selectedProject.project.project_Title
    selectedProject.project.project_Segments = selectedProject.project_Segments

    return (
      <div>
        {/* PROJECT HEADER*/}
        {this.renderProjectHeader(selectedProject, selectedProject.project.project_Status)}

        {/* PROJECT HOURS */}
        <div className="projectInvoicesContainer">
          {this.renderProjectHours(selectedProject.project)}
        </div>

        {/* NEW HOURS FORM */}
        {selectedProject.project_Id && selectedProjectId && !isEditing &&
          <SegmentForm
            handleChange={this.handleNewSegmentChange}
            segment={newSegment}
            project={selectedProject.project}
            submitHours={this.submitNewHours}
            cancelHours={this.cancelEdit}
            submitted={submitted}
            noChanges={noChanges}
          />
        }

        <div className="row" style={{ display: "flex", padding: "0 10px" }}>

          <div className='row col-md-6' style={{ display: "flex" }}>
            <div style={{ marginRight: 40 }}>
              <ReactTooltip
                id='lognewhours'
                className='projectTooltip'
                place="right"
                effect="solid"
                multiline={true}
                textColor='dark'
                backgroundColor='white'
                border={true}
                borderColor='rgb(128, 128, 128)'
              />
              {selectedProject.project.project_Status === "active" &&
                <button
                  className="addMoreHoursBtn"
                  onClick={sharedService.isSPVerified(service_Provider, stripe_Verified) ? () => this.addMoreHours(selectedProject) : () => { history.push("/service-provider-success") }}
                  data-tip={!sharedService.isSPVerified(service_Provider, stripe_Verified) ? "Cannot log new hours until verification is complete." : null}
                  data-for='lognewhours'
                  disabled={(selectedProject.project && selectedProject.project.project_Status === "active") ? false : true}
                >
                  <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: 10, width: 20, height: 20 }} />
                  Add More Hours
                </button>
              }
            </div>
          </div>

          {/* PROJECT TOTAL */}

          <div className='col-md-8' style={{ display: "flex", justifyContent: "flex-end" }}>
            {this.renderTotalMoney(selectedProject, total)}
          </div>
        </div>
      </div >
    )
  }

  //Renders projects hours for single project
  renderProjectHours(project) {
    const { selectedSegment, submitted, noChanges } = this.state;

    if (project && project.project_Segments) {
      return project.project_Segments.map((entry, idx) => {

        if ((project.project_Id === entry.project_Id) && entry.is_Editable) {

          // DISPLAY EDIT FORM
          if (selectedSegment && (entry.segment_Id === selectedSegment.segment_Id)) {
            return (
              <SegmentForm
                segment={selectedSegment}
                project={project}
                originalSegment={entry}
                formatDate={this.formatDate}
                handleChange={this.handleEditSegmentChange}
                handleEditAttachment={this.handleEditAttachment}
                submitHours={this.submitEditHours}
                cancelHours={this.cancelEdit}
                submitted={submitted}
                noChanges={noChanges}
              />
            )
          }

          // DISPLAY HOURS
          else {
            return (
              <div className='segmentDisplayContainer'>
                <SegmentDisplay
                  idx={idx}
                  originalSegment={entry}
                  project={project}
                  editHours={this.editHours}
                  toggleDeleteModal={this.toggleDeleteModal}
                  downloadFile={this.downloadFile}
                />
              </div>
            )
          }
        }
      })
    }
  }

  render() {
    const { selectedProject, modalOpen } = this.state;
    const { loading } = this.props;

    let projectSelectionOptions = this.generateOptions();

    let selectedValue = { value: this.state.selectedProjectId, label: <span style={{ fontFamily: "Lato" }}><strong>{selectedProject?.client_Organization?.name}</strong> - {selectedProject?.project ? selectedProject.project.project_Title : selectedProject?.project_Title}</span> }
    let defaultValue = { value: "", label: <strong style={{ fontFamily: "Lato" }}>See All Hours</strong> }

    const customStyles = {
      dropdownIndicator: (provided) => ({
        ...provided,
        color: accentColor || "#00C58E"
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: accentColor || "#00C58E"
      }),
    }

    return (
      <>
        {loading && <Loading />}

        {!this.props.singleProjectId &&
          <>
            {/* Project selection */}
            <Select
              value={Object.keys(selectedProject).length !== 0 ? selectedValue : defaultValue}
              name="client_Organization"
              isClearable={true}
              isSearchable={true}
              onChange={(e) => { this.handleSelectionChange(e) }}
              options={projectSelectionOptions}
              style={{ height: "75px!important" }}
              styles={customStyles}
            />
          </>
        }

        {this.props.singleProjectId && selectedProject && (Object.keys(selectedProject).length !== 0) &&
          (selectedProject.project ? this.renderSingleProject() : this.renderSingleMPProject())
        }

        {!this.props.singleProjectId &&
          <>
            {(Object.keys(selectedProject).length !== 0) ?
              //render hours for a selected project
              (selectedProject.project ? this.renderSingleProject() : this.renderSingleMPProject())
              :
              //render hours for all projects
              this.renderAllProjects()
            }
          </>
        }

        {/* Delete hours confirmation modal */}
        <DeleteHoursModal
          modalOpen={modalOpen}
          toggleDeleteModal={this.toggleDeleteModal}
          deleteHours={this.deleteHours}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { loading } = state.serviceprovider_getprojects;
  const { service_Provider } = state.serviceprovider_getserviceprovider;
  const { project } = state.serviceprovider_getproject;
  const project_created = state.serviceprovider_createproject.project;
  const project_updated = state.serviceprovider_updateproject.project;

  const project_proposals = state.serviceprovider_getprojects.projects;
  const project_listings = state.mp_projects.projects;

  const mpProject = state.mp_project.project;

  let projects = [];

  const mpProjects = project_listings?.filter((project) => {

    //check if application was accepted and if it doesn't have a flat rate
    if (project.applications && project.applications.length !== 0 && project.applications[0].status === "Accepted" && project.applications[0].flat_Rate === 0) {
      //if project is accepted then add documents (temp)
      project.document_Files = project?.document_Files?.length !== 0 ? [] : project.document_Files;
      project.document_Links = project?.document_Links?.length !== 0 ? [] : project.document_Links;
      return project;
    }
  })

  const spProjects = project_proposals?.filter((project => {
    if (!project.project_Proposals[0]?.project_Flat_Rate) {
      return project
    }
  }))

  if (project_proposals) {
    projects.push(...spProjects)
  }
  if (mpProjects) {
    projects.push(...mpProjects)
  }

  let project_Segments = Object.assign([], project && project.project_Segments ? project.project_Segments : []);

  return {
    user,
    projects,
    loading,
    service_Provider,
    project: {
      ...project,
      project: project_updated ? project_updated :
        project_created ? project_created :
          project ? project.project : null,
      project_Segments,
    },
    mpProject,
  };
}

const connectedHours = connect(mapStateToProps)(Hours);
export { connectedHours as Hours };

