import React from 'react';
import { connect } from 'react-redux';
import { organizationService, pluginService, serviceProviderService, sharedService } from '../../../../../_services';
import { Loader } from '../../../../Shared/Loader';

import NewMarketplaceProjectSteps_v2 from './NewMarketplaceProjectSteps_v2';

class NewMarketplaceProject_v2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project:
            {
                project_Title: '',
                project_Description: '',
                project_Proposal: '',
            },

            project_Client: null,
            view: this.props.project ? 'view' : 'edit',
            errors: {},
            submitted: false,
            loading: true,
            selectedRate: { value: "hourlyRate", label: "Hourly Rate" },
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }

    async componentDidMount() {
        // await serviceProviderService.createProject(this.state.project);

        const { user } = this.props;

        var all_Organizations_Object = await organizationService.getAllOrganizationsLite(user.id);
        var all_Organizations = Object.values(all_Organizations_Object).map((org) => {
            return (org)
        });

        let project_Client = null;
        if (this.props.location.search) {
            let query_params = this.props.location.search;
            let query_params_split = query_params.split("&");
            query_params_split.filter((param) => {
                if (param.includes("project_Client")) {
                    let param_split = param.split("=");
                    if (param_split.length > 0) {
                        let client_org = all_Organizations.filter((org) => {
                            if (String(org.organization_Id) === param_split[1]) {
                                return org
                            }
                        });
                        let org = client_org[0];
                        project_Client =
                        {
                            value: org.organization_Id + "-" + org.name,
                            label:
                                <span style={{ minHeight: 75 }}>
                                    <img
                                        src={org.logo_URL ? org.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"}
                                        width="75"
                                        alt="logo"
                                    />
                                    <span style={{ marginLeft: 25, fontWeight: 600, lineHeight: "75px" }}>{org.name}</span>
                                </span>
                        }
                    }
                }
            });
        }

        this.setState({
            all_Organizations: all_Organizations,
            project_Client,
            loading: false
        });
    }

    handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            project: {
                ...this.state.project,
                [name]: value
            }
        });
    }

    handleProposalInput = (e) => {
        const { project_Hourly_Rate, project_Total_Hours, project_Flat_Rate } = this.state.project.project_Proposal;
        let { project_Total_Cost } = this.state.project.project_Proposal;


        let name = e.target.name;
        let value = e.target.value;

        //prevent budget starting with 0 but allow to enter 0 by itself
        if (value[0] == 0 && value.length > 1) {
            value = value.substring(1)
        }

        if ((name === "project_Hourly_Rate" && project_Total_Hours) || (name === "project_Total_Hours" && project_Hourly_Rate)) {
            if (name === "project_Hourly_Rate") {
                project_Total_Cost = value * project_Total_Hours;
            }
            else {
                project_Total_Cost = project_Hourly_Rate * value;
            }
        }
        if (name === "project_Flat_Rate") {
            project_Total_Cost = Number(value);
        }

        this.setState({
            project: {
                ...this.state.project,
                project_Proposal: {
                    ...this.state.project.project_Proposal,
                    [name]: value,
                    project_Total_Cost
                }
            }
        });
    }

    handleFormattedInput = (name, value) => {
        this.setState({
            project: {
                ...this.state.project,
                project_Proposal: {
                    ...this.state.project.project_Proposal,
                    [name]: value,
                }
            }
        });
    }

    handleSelectionChange = categories => {
        let client_Organization_Id = null;
        const client_Organization_Ids = this.state.all_Organizations.filter(x => {if (x.name == Object.values(categories)[0]) return x});
        if (client_Organization_Ids.length > 0)
            client_Organization_Id = client_Organization_Ids[0].organization_Id;

        if (client_Organization_Id)
            this.setState({
                client_Organization_Id
            });
    };

    handleSelectionInput = categories => {
        let client_Organization_Id = null;
        const client_Organization_Ids = this.state.all_Organizations.filter(x => {if (x.name == Object.values(categories)[0]) return x});
        if (client_Organization_Ids.length > 0)
            client_Organization_Id = client_Organization_Ids[0].organization_Id;

        this.setState({
            project_Client: Object.values(categories)[0],
            client_Organization_Id
        })
    }

    handleSelectedRate = (e) => {

        if (e.value === "hourlyRate") {
            this.setState({
                project: {
                    ...this.state.project,
                    project_Proposal: {
                        ...this.state.project.project_Proposal,
                        project_Flat_Rate: 0,
                        project_Total_Cost: 0.0,
                    }
                }
            })
        }
        else {
            this.setState({
                project: {
                    ...this.state.project,
                    project_Proposal: {
                        ...this.state.project.project_Proposal,
                        project_Hourly_Rate: 0,
                        project_Total_Hours: 0,
                        project_Total_Cost: 0.0,
                    }
                }
            })
        }

        this.setState({
            selectedRate: e
        })
    }

    handleSubmit = async (e) => {
        this.setState({ submitted: true, loading: true });
        const { project_Client, client_Organization_Id } = this.state;
        const { project_Proposal } = this.state.project;
        const { service_Provider } = this.props;

        let errors = {
            project_title_error: false,
            project_description_error: false,
        };

        // alert(JSON.stringify(this.state.project.project_Proposal))

        var form_is_valid = true;
        // if (!project_Title) {
        //     errors.title_error = true;
        //     form_is_valid = false;
        // }

        //TODO: organization
        if (form_is_valid) {
            //handle proposal submission
            let project = Object.assign({}, this.state.project);

            project = {
                project_Title: project_Proposal.proposal_Title,
                project_Description: JSON.stringify(project_Proposal.proposal_Scope),
                service_Provider_Id: service_Provider.service_Provider_Id,
                service_Provider_User_Id: this.props.user.id,
                client_Organization_Id
            }

            // create project, await response with project ID
            const create_Project = await serviceProviderService.createProject(project);

            // assign project ID to proposal
            let proposal = {};
            proposal["proposal_Title"] = project_Proposal.proposal_Title;
            proposal["proposal_Scope"] = JSON.stringify(project_Proposal.proposal_Scope);
            proposal["proposal_Additional_Information"] = project_Proposal.proposal_Additional_Information ? JSON.stringify(project_Proposal.proposal_Additional_Information) : "";
            proposal["project_Total_Hours"] = project_Proposal.project_Total_Hours ? project_Proposal.project_Total_Hours : null;
            proposal["project_Hourly_Rate"] = project_Proposal.project_Hourly_Rate ? project_Proposal.project_Hourly_Rate : null;
            proposal["project_Flat_Rate"] = project_Proposal.project_Flat_Rate ? project_Proposal.project_Flat_Rate : null;
            proposal["project_Total_Cost"] = project_Proposal.project_Total_Cost ? project_Proposal.project_Total_Cost : null;
            proposal["project_Completion_Date"] = project_Proposal.project_Completion_Date ? project_Proposal.project_Completion_Date : null;
            proposal["service_Provider_User_ID"] = this.props.user.id;
            proposal["project_ID"] = create_Project.project_Id;

            // create proposal
            await serviceProviderService.createProposal(proposal);

            this.setState({
                submitted: true,
                errors,
                loading: false
            });

            window.location.href = pluginService.redirect("/sp/projects/" + create_Project.project_Id + "?val=proposals")

            return true;
        } else {
            return false;
        }
    }

    render() {
        const { all_Organizations, project, project_Client, client_Organization_Id, submitted, loading, selectedRate } = this.state;
        
        return (
            <div>
                {loading && <Loader />}
                <NewMarketplaceProjectSteps_v2
                    all_Organizations={all_Organizations}
                    project={project}
                    project_Client={project_Client}
                    client_Organization_Id={client_Organization_Id}
                    submitted={submitted}
                    handleInput={this.handleInput}
                    handleSelectionInput={this.handleSelectionInput}
                    handleSelectionChange={this.handleSelectionChange}
                    handleProposalInput={this.handleProposalInput}
                    handleSubmit={this.handleSubmit}
                    handleFormattedInput={this.handleFormattedInput}
                    selectedRate={selectedRate}
                    handleSelectedRate={this.handleSelectedRate}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;
    const { stripe_Verified } = state.stripeVerified;
    const loading_sp = state.serviceprovider_getserviceprovider.loading;
    const loading_sv = state.stripeVerified.loading;


    if (!loading_sp && !loading_sv) {
        //is not service provider
        if (!service_Provider) {
            window.location.href = pluginService.redirect("/service-provider-onboarding");
            return;
        }

        //is not verified sp
        if (!sharedService.isSPVerified(service_Provider, stripe_Verified)) {
            window.location.href = pluginService.redirect("/service-provider-success");
            return;
        }

        //does not have payout method
        if (!sharedService.hasPayoutMethod(stripe_Verified)) {
            window.location.href = pluginService.redirect("/service-provider-success");
            return;
        }

        //bank is not verified
        if (!sharedService.bankIsVerified) {
            window.location.href = pluginService.redirect("/service-provider-success");
            return;
        }
    }

    return {
        user,
        service_Provider,
        stripe_Verified
    };
}

const connectedNewMarketplaceProject = connect(mapStateToProps)(NewMarketplaceProject_v2);
export { connectedNewMarketplaceProject as NewMarketplaceProject_v2 };
