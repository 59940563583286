import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';
import '../../Styles/ServiceProvider.css';
import { pluginService } from '../../_services';

let accentColor = pluginService.getIFrameColor()

export class ServiceProvidersMessages extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()

        this.state = {
            email: JSON.parse(localStorage.getItem('user')).email
        }
    }

    render() {
        const { messages, loadMore, channel, tempMessages, messageError, sendMessage, loadedMessageCount } = this.props;

        let messages_view = null;

        //temp message display for the first message sent
        if (tempMessages && !messages) {
            messages_view = tempMessages?.map((message, idx) => {
                return (
                    <div key={idx}>
                        <h4 style={{ float: "right", display: "block", margin: 0, fontSize: 12, fontWeight: 500 }}>
                            {(messageError || !window.navigator.onLine) ?
                                <span style={{ color: "red", display: "flex", alignItems: "center" }}>
                                    Not Delivered <ErrorOutlineIcon style={{ marginLeft: 5 }} />
                                </span>
                                :
                                "Sending..."
                            }
                        </h4>
                        <br />

                        {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}> */}

                        <p className="outgoing">{message}</p>

                        {/* {(messageError || !window.navigator.onLine) &&
                                <span className="resendMessage">
                                    <CachedIcon className="resendMessageIcon" onClick={async() => { await prepareNewChat() }} />
                                </span>
                            } */}
                        {/* </div> */}
                    </div>
                )
            })
        }
        else if (messages) {

            let allMessages = messages;

            if (tempMessages && tempMessages.length !== 0) {

                tempMessages.forEach((tempMessage) => {
                    if (messages[messages.length - 1].state.body === tempMessage) {
                        return
                    }
                    else {
                        allMessages = [...allMessages, tempMessage];
                    }
                })
            }

            messages_view = allMessages.map((message, idx) => {

                if (message.author !== (channel.user_Info ? channel.user_Info.message_Identity : channel.message_Identity)) {
                    return (
                        <div key={idx} ref={idx === loadedMessageCount - 1 ? this.myRef : null}>

                            <h4 style={{ float: "right", display: "block", margin: 0, fontSize: 12, fontWeight: 500 }}>

                                {!message.dateCreated && !messageError &&
                                    <span>Sending...</span>
                                }
                                {!message.dateCreated && (messageError || !window.navigator.onLine) &&
                                    <span style={{ color: "red", display: "flex", alignItems: "center" }}>
                                        Not Delivered <ErrorOutlineIcon style={{ marginLeft: 5 }} />
                                    </span>
                                }
                                {message.dateCreated &&
                                    new Date(message?.dateCreated.toISOString()).toLocaleString()
                                }

                            </h4>
                            <br />

                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

                                <p class="outgoing"> {message.state ? message.state.body : message}</p>

                                {!message.dateCreated && (messageError || !window.navigator.onLine) &&

                                    <span className='resendMessage' onClick={() => { sendMessage(message) }}>
                                        <CachedIcon className="resendMessageIcon" />
                                    </span>
                                }
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <div key={idx} ref={idx === loadedMessageCount - 1 ? this.myRef : null}>

                            <h6 style={{ display: "block", margin: 0, fontSize: 12, fontWeight: 500 }}>
                                {new Date(message.dateCreated.toISOString()).toLocaleString()}
                            </h6>

                            <p class="incoming"> {message.state.body} </p>
                        </div>
                    )
                }
            });
        }
        // else {
        //     return <div>
        //         <br />
        //         <p>Send a message to this service provider to get a conversation going!</p>
        //     </div>;
        // }

        //
        // ref={this.myRef}
        //this.myRef.current.scrollIntoView()


        return (
            <div class="messages_box">
                {!loadMore &&
                    <h6 style={{ textAlign: "center", marginTop: 20, marginBottom: 25, fontWeight: 500 }}>
                        Beginning of messages.
                        <hr />
                    </h6>
                }
                {loadMore &&
                    <button
                        className="defaultWhiteButton"
                        onClick={async () => { await loadMore(); this.myRef.current.scrollIntoView() }}
                        style={{ margin: "20px 0", height: 42, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                    >
                        Load More Messages...
                    </button>
                }
                {messages_view}
            </div>
        );
    }
}