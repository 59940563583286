import { API_Route } from '../../App/API_Route';
import { authHeader } from '../../_helpers';
import { sharedService } from '../shared.service';

export const feedbackService = {
  sendGrantFeedback,
  sendFunderFeedback
};

function sendGrantFeedback(id, feedback) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(feedback)
  };

  return fetch(API_Route + `/api/user/feedback/grant/${id}`, requestOptions)
    .then(sharedService.handleResponse)
}


function sendFunderFeedback(id, feedback) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(feedback)
  };

  return fetch(API_Route + `/api/user/feedback/funder/${id}`, requestOptions)
    .then(sharedService.handleResponse)
}