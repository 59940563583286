import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { PlanSelection } from '.';
import { stripeService } from '../../../../_services';
import { Loader } from '../../../Shared/Loader';
import { Checkout_v2 } from '../Checkout/Checkout_v2.jsx';

export const ChangeProfilePlan = (props) => {
    const user = useSelector(state => state.setUser.user);

    const [currentPageState, setPageState] = useState(0);
    const [plan, setPlan] = useState('');
    const [group, setGroup] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (plan) {
            setLoading(true);

            const roles = {
                Client_Free: 0,
                Client_Premium: 1,
            }

            const plans = {
                Free: 0,
                Pro: 1
            }

            //user current role
            const role_types = Object.keys(roles);
            const current_roles = user.userRoles.filter((user_role) => {
                if (role_types.includes(user_role)) {
                    return user_role;
                }
            });
            if (current_roles.length === 0) { return; }
            const current_role = roles[current_roles[0]];

            //user new plan
            const new_plan = plans[plan];

            if (new_plan == current_role) { props.updatePage("billingPage"); return; }

            //cancel / free 
            if (new_plan === 0) {
                //cancel plan
                async function cancelPlan() {
                    //TODO: use redux
                    await stripeService.cancelPlanSubscription(user.id);

                    props.updatePage("billingPage");
                }

                cancelPlan();
            }

            //downgrade / upgrade
            else {
                if (new_plan < current_role || new_plan > current_role) {
                    const group_plans = {
                        Pro: "opengrants_premium default"
                    }
                    setGroup(group_plans[plan]);

                    setPageState(1);
                }
            }
            setLoading(false);
        }
    }, [plan]);

    return (
        <>
            {loading && <Loader />}

            {currentPageState === 0 &&
                <PlanSelection
                    setPlan={setPlan}
                />
            }
            {currentPageState === 1 &&
                <Checkout_v2
                    group={group}
                    returnPage={() => props.updatePage("billingPage")}
                />
            }
        </>
    );
}