import { marketplaceprojectConstants } from '../_constants';

export function mp_projects(
  state = {
    loading_allprojects: 'initial',
    projects: []
  }, action) {
  switch (action.type) {
    case marketplaceprojectConstants.GETALLPROJECTS_REQUEST:
      return {
        loading_allprojects: true
      };
    case marketplaceprojectConstants.GETALLPROJECTS_SUCCESS:
      return {
        loading_allprojects: false,
        projects: action.projects
      };
    case marketplaceprojectConstants.GETALLPROJECTS_FAILURE:
      return {
        loading_allprojects: false
      };
    default:
      return state
  }
}

export function mp_project(
  state = {
    loading_project: 'initial',
    project: {}
  }, action) {
  switch (action.type) {
    case marketplaceprojectConstants.GETPROJECT_REQUEST:
      return {
        loading_project: true
      };
    case marketplaceprojectConstants.GETPROJECT_SUCCESS:
      return {
        loading_project: false,
        project: action.project
      };
    case marketplaceprojectConstants.GETPROJECT_FAILURE:
      return {
        loading_project: false
      };
    default:
      return state
  }
}