//this file is no longer in use ?

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { EditOrganizationIndustries } from '../EditOrganizationIndustries';
import { EditOrganizationTechnologies } from '../EditOrganizationTechnologies';
import { EditOrganizationImpact } from '../EditOrganizationImpact';
import { EditOrganizationNAICS } from '../EditOrganizationNAICS';
import { EditOrganizationSAM } from '../EditOrganizationSAM';
import { EditOrganization8A } from '../EditOrganization8A';
import { EditOrganizationWOB } from '../EditOrganizationWOB';
import { EditOrganizationVOB } from '../EditOrganizationVOB';
import { EditOrganizationHubzone } from '../EditOrganizationHubzone';
import { EditOrganizationSB } from '../EditOrganizationSB';
import { EditOrganizationDUNS } from '../EditOrganizationDUNS';

const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class AdvancedAttributesForm extends Component {

    render() {
        const { handleCategoryInput, handleCategorySelection, handleChange, handleSelectionChange, org } = this.props

        return (
            <div>
                <div id="mainSettings">
                    <div className="row">
                        <div>
                            {/* <EditOrganizationSAM
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            /> */}
                            <EditOrganization8A
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            />
                            <EditOrganizationWOB
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            />
                            <EditOrganizationVOB
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            />
                            <EditOrganizationHubzone
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            />
                            <EditOrganizationSB
                                org={org}
                                options={options}
                                handleSelectionChange={handleSelectionChange}
                            />
                        </div>
                        <hr className="formHr"/>
                        <div >
                            <EditOrganizationDUNS
                                org={org}
                                handleChange={handleChange}
                            />
                            <hr className="formHr"/>

                            <EditOrganizationNAICS
                                org={org}
                                handleCategoryInput={handleCategoryInput}
                                handleCategorySelection={handleCategorySelection}
                            />

                            <EditOrganizationIndustries
                                org={org}
                                handleCategoryInput={handleCategoryInput}
                                handleCategorySelection={handleCategorySelection}
                            />

                            <EditOrganizationTechnologies
                                org={org}
                                handleCategoryInput={handleCategoryInput}
                                handleCategorySelection={handleCategorySelection}
                            />

                            <EditOrganizationImpact
                                org={org}
                                handleCategoryInput={handleCategoryInput}
                                handleCategorySelection={handleCategorySelection}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedAdvancedAttributesForm = connect(mapStateToProps)(AdvancedAttributesForm);
export { connectedAdvancedAttributesForm as AdvancedAttributesForm };