import React from 'react';
import { connect } from 'react-redux';
import SlateTextDisplay from '../../../../_components/dashboardComponents/CustomInput/SlateTextDisplay';
import { sharedService } from '../../../../_services';

class NewProjectFinish_v2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            organization_Select: [],
            selectedOption: null,
        }
    }

    async componentDidMount() {
        document.body.scrollTo(0, 0);
    }

    render() {
        const { project, project_Client, all_Organizations } = this.props;

        let organizationDetails = all_Organizations?.map((org) => {
            if ((org.organization_Id + "-" + org.name) === project_Client.value) {
                return (
                    <div style={{ display: 'flex', alignItems: "center", marginBottom: "2.5%" }}>
                        <img
                            src={org.logo_URL ? org.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"}
                            width="75"
                            height="75"
                            style={{ borderRadius: "50%", objectFit: "cover", marginLeft: 16 }}
                            alt="logo"
                        />
                        <span
                            style={{ fontSize: 18, fontWeight: 600, lineHeight: "75px", paddingLeft: 20 }}
                        >
                            {org.name}
                        </span>
                    </div>
                )
            }
        });

        return (
            <div >
                <div className="newProjectFormHeader_v2">
                    Review
                </div>

                <div className="newProjectForm_v2">

                    {organizationDetails}

                    <hr />

                    <div style={{ padding: "0 16px" }}>

                        <div style={{ marginBottom: 20 }}>
                            <p style={{ margin: 0, fontWeight: "bold" }}>Title</p>
                            <p>{project.project_Proposal.proposal_Title}</p>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <p style={{ margin: 0, fontWeight: "bold" }}>Scope of Project</p>
                            <p><SlateTextDisplay text={JSON.stringify(project.project_Proposal.proposal_Scope)} /></p>
                        </div>

                        {project.project_Proposal.proposal_Additional_Information &&
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Deliverables</p>
                                <p><SlateTextDisplay text={JSON.stringify(project.project_Proposal.proposal_Additional_Information)} /></p>
                            </div>
                        }

                        {project.project_Proposal.project_Completion_Date &&
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Completion Date</p>
                                <p>{sharedService.trimDate(project.project_Proposal.project_Completion_Date)}</p>
                            </div>
                        }

                        {!!project.project_Proposal.project_Total_Hours &&
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Total Hours</p>
                                <p>{Number(project.project_Proposal.project_Total_Hours).toLocaleString(undefined, { maximumFractionDigits: 10 })}</p>
                            </div>
                        }

                        {!!project.project_Proposal.project_Hourly_Rate &&
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Hourly Rate</p>
                                <p>{'$' + Number(project.project_Proposal.project_Hourly_Rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD per Hour'}</p>
                            </div>
                        }

                        {!!project.project_Proposal.project_Flat_Rate &&
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Flat Rate</p>
                                <p>{'$' + Number(project.project_Proposal.project_Flat_Rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD'}</p>
                            </div>
                        }

                        {project.project_Proposal.project_Total_Cost && project.project_Proposal.project_Total_Cost !== 0 ?
                            <div>
                                <p style={{ margin: 0, fontWeight: "bold" }}>Total Project Cost</p>
                                <p>{"$" + project.project_Proposal?.project_Total_Cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " USD"}</p>
                            </div>
                            :
                            null
                        }
                    </div>

                    <hr />

                    <div style={{ padding: "0 16px" }}>
                        <p style={{ fontWeight: "bold" }}>
                            Final Steps
                        </p>
                        <ol>
                            <li>After you create this project, it will be sent to your client for review.</li>
                            <li>In the meantime, you will be able to view it within your project dashboard under the "Pending Projects" tab.</li>
                        </ol>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedNewProjectFinish = connect(mapStateToProps)(NewProjectFinish_v2);
export { connectedNewProjectFinish as NewProjectFinish_v2 };
