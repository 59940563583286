import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";
import { pluginService } from "../../../../_services";

let accentColor = pluginService.getIFrameColor()

class GS_Onboarding_Members extends React.Component {
  render() {
    const {
      user,
      updateNav,
      handleSubmit,
      form_errors,
      invite_emailInput,
      accepted_profiles, accepted_profile_list,
      pending_profiles, 
      pending_invitation_profiles,
      pending_invitation_list,
      handleNewOrganization,
    } = this.props;
    const { organization } = this.props.new_Organization;

    const handleSubmitFunction = async (e) => {
      await handleSubmit(e);
      // updateNav("onboard_confirmation");
    }

    //is creating org
    const path = window.location.pathname;
    let pathSplit = path.split('/').pop();

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            {(pathSplit !== "org" && !this.props.creatingOrg) && <p className="gc_onboarding-form-input-name-top"> 6 of 6 </p>}
            {(pathSplit === "org" || this.props.creatingOrg) && <p className="gc_onboarding-form-input-name-top"> 5 of 5 </p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10 complete-color" style={{ backgroundColor: accentColor || '#00C58E' }} />
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
            <h1 className="onboarding-form-header">Members</h1>
            <p>Want to invite employees or colleagues to join your organization? Add their emails here. They will receive an invitation to create an account and join your organization. They will also be asked if they want to sign up as a regular user, or request to get approved as a Grant Consultant.</p>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-8 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  <span><strong>{accepted_profiles ? accepted_profiles.length : 0}</strong> Member(s)</span>
                </p>
                <div style={{ marginTop: 25 }}>
                  {accepted_profile_list}
                </div>
              </div>
            </div>
            <div className="row">
              <p className="gc_onboarding-form-input-name-top-pendingMembers">{pending_invitation_profiles ? pending_invitation_profiles.length : 0} Pending Invitation(s)</p>
              {pending_profiles.length > 0 ? <hr className="formHr" /> : <br />}
              <div>{pending_invitation_list}</div>
            </div>
            <div className="row">
              <div className="col-md-10 gc_onboarding-form-col" style={{ padding: 0 }}>
                <input
                  type="text"
                  name="invite_emailInput"
                  value={invite_emailInput}
                  placeholder="Email"
                  onChange={handleNewOrganization}
                  className="gc_onboarding-form-input-lg"
                  inputProps={{ maxLength: 255 }}
                  style={{ margin: 0 }}
                />
              </div>
              <div className="col-md-2 gc_onboarding-form-col-right">
                <Button
                  className="gc_onboarding-invite-btn2"
                  onClick={() => { this.props.inviteToJoinOrganization(user.id, organization.organization_Id, invite_emailInput) }}
                  style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                >
                  Invite
                </Button>
              </div>
            </div>
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_advancedSettings")}
                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={(e) => handleSubmitFunction(e)}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Submit <i class="icon-arrow-right" />
              </button>
            </div>
            {this.props.createOrgError &&
              <p className="red-message">
                We're sorry, there was an issue processing your submission. Please double check entered information and try again.
                If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
              </p>
            }
            {form_errors && (
              <p style={{ marginTop: 30, color: "red" }}>
                There are errors in your form. Please correct all errors shown in
                red above, then click Submit again.
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const cSnnectedGC_Onboarding_Members =
  connect(mapStateToProps)(GS_Onboarding_Members);
export { cSnnectedGC_Onboarding_Members as GS_Onboarding_Members };

