//this file is no longer in use ?

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Registration_AutoSuggestion } from '../../../Account/RegisterPage';

class OrganizationInfoForm extends Component {

    render() {
        const { submitted, organizationPassed, country, state, organizationStateError, organizationCountryError, service_Provider,
            website_is_required, address_is_required
        } = this.props

        let organization = organizationPassed;

        return (
            <div>
                <div >
                    <div >

                        {/* Country */}

                        {/* <Registration_AutoSuggestion
                            categories={{ "country": country ? country : [] }}
                            categoryName={"country"}
                            currentSelectedCategories={[organization.country ? organization.country : ""]}
                            handleCategorySelection={this.props.handleLocationSelection}
                            handleCategoryInput={this.props.handleLocationInput}
                            />
                        {organizationCountryError && <p className="red">Please select a valid input.</p>} */}

                        <div>
                            {/* ADDRESS */}
                            <h4 className="inputFieldLabel-Top">Company Address {address_is_required ? <span className="red">*</span> : ''}</h4>

                            {/* Country */}
                            <div>
                                {submitted && !organization.country && (
                                    <p className="errorMessage">
                                        You must enter your organization's country.
                                    </p>
                                )}
                                {submitted && organization.country && !(country.includes(organization.country)) &&
                                    <p className="errorMessage">
                                        Please select a country from the list.
                                    </p>
                                }

                                <label className="inputFieldLabelSecondary">
                                    Country <span className="red-star">*</span>
                                </label>

                                <Registration_AutoSuggestion
                                    className="selectField "
                                    categories={{ country: country ? country : [] }}
                                    categoryName={"country"}
                                    // id="organization-standard-basic"
                                    currentSelectedCategories={[
                                        organization.country
                                            ? organization.country
                                            : "",
                                    ]}
                                    handleCategorySelection={this.props.handleLocationSelection}
                                    handleCategoryInput={this.props.handleLocationInput}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </div>

                            {organization.country && organization.country.toLowerCase() === "united states" && <div>
                                {/* Street */}
                                <label className="inputFieldLabelSecondary" >Street Address <span className="red">*</span></label>
                                {submitted && !organization.address &&
                                    <p className="errorMessage">You must specify your organization's street address.</p>
                                }
                                {organization.address && (organization.address.toLowerCase().includes("p.o. box") 
                                    || organization.address.toLowerCase().includes("po box")
                                ) &&
                                    <p className="errorMessage">You cannot use a P.O. Box Address.</p>
                                }                                
                                <input className="inputField" name="address" value={organization.address} onChange={this.props.handleChange} />

                                {/* City */}
                                <label className="inputFieldLabelSecondary">City <span className="red">*</span></label>
                                {submitted && !organization.city &&
                                    <p className="errorMessage">You must specify your organization's city.</p>
                                }
                                <input className="inputField" name="city" value={organization.city} onChange={this.props.handleChange} />

                                {/* State */}
                                <label className="inputFieldLabelSecondary">State <span className="red">*</span></label>
                                {submitted && !organization.state &&
                                    <p className="errorMessage">You must specify your organization's state.</p>
                                }
                                {organizationStateError && (
                                  <p className="boxText noMargin red">Please select a valid input.</p>
                                )}
                                <Registration_AutoSuggestion
                                    categories={{ "state": state ? state : [] }}
                                    categoryName={"state"}
                                    currentSelectedCategories={[organization.state ? organization.state : ""]}
                                    handleCategorySelection={this.props.handleLocationSelection}
                                    handleCategoryInput={this.props.handleLocationInput}
                                    className="select"
                                />

                                {/* Zip Code */}
                                <label className="inputFieldLabelSecondary">ZipCode <span className="red">*</span></label>
                                {submitted && !organization.zipCode &&
                                    <p className="errorMessage">You must specify your organization's zip code.</p>
                                }
                                <input className="inputField" name="zipCode" value={organization.zipCode} onChange={this.props.handleChange} />
                            </div>}
                        </div>

                        {/* PHONE NUMBER */}
                        <div>
                            <h4 className="inputFieldLabel">Phone Number</h4>
                            <input className="inputField" name="phone_Number" value={organization.phone_Number} onChange={this.props.handleChange} />
                        </div>

                        {/* WEBSITE */}
                        <div>
                            <h4 className="inputFieldLabel">Website {website_is_required ? <span className="red">*</span> : ''}</h4>
                            {service_Provider && submitted && !organization.website_URL &&
                                <p className="errorMessage">You must specify your organization's website.</p>
                            }
                            <input className="inputField" name="website_URL" value={organization.website_URL} onChange={this.props.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedOrganizationInfoForm = connect(mapStateToProps)(OrganizationInfoForm);
export { connectedOrganizationInfoForm as OrganizationInfoForm };