import React from 'react';
import { Component } from "react";
import { Container } from "reactstrap";
import { UpgradeHandler } from "../../Settings";
import { NavMenu } from "../../Shared/NavMenu/NavMenu_v2";
import { DesktopSideNavMenu } from "../NavMenu/DesktopSideNavMenu";
import GlobalTimer from "../Timer/GlobalTimer";

export class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let userLoggedIn = localStorage.getItem("user");
    const pathName = window.location.pathname;
    return (
      <div>
        <NavMenu />
        <div className="d-flex flex-row">
          {userLoggedIn &&
            <DesktopSideNavMenu pathName={pathName} />
          }
          <>
            <div
              id="mainLayoutDiv_v2"
              className="overflow"
              style={userLoggedIn ? {} : { marginLeft: 0 }}
            >
              {this.props.children}
            </div>
          </>
        </div>
      </div>
    );
  }
}
