import { stripeActions } from '.';
import { serviceproviderConstants, stripeConstants } from '../_constants';
import { history } from '../_helpers';
import { pluginService, serviceProviderService } from '../_services';

export const serviceProviderActions = {
    getServiceProviderById,
    getServiceProvider,
    setServiceProvider,
    getServiceProvidersByUser,

    getClient,
    getAllClients,

    getAllClientProjectsAndProposals,

    getAllProjects,
    getProjects,
    getProject,
    setProject,
    createProject,
    updateProject,

    createProjectProposal,
    updateProposalStatus,
    updateProposalView,
    updateProjectProposal,

    createWorkSegment,
    editWorkSegment,
    deleteWorkSegment,

    alertChannelUpdate
};

function getServiceProviderById(provider_id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getServiceProviderById(provider_id)
            .then(
                result => {
                    dispatch(success(result))
                },
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.GETSERVICEPROVIDER_REQUEST } }
    function success(service_Provider) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_SUCCESS, service_Provider } }
    function failure(error) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_FAILURE } }
}

function getServiceProvider(provider) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getServiceProvider(provider)
            .then(
                result => dispatch(success(result.provider)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.GETSERVICEPROVIDER_REQUEST } }
    function success(service_Provider) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_SUCCESS, service_Provider } }
    function failure(error) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_FAILURE } }
}

function setServiceProvider(provider) {
    return async dispatch => {
        dispatch(success(provider))
    };

    function success(service_Provider) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_SUCCESS, service_Provider } }
}

function getServiceProvidersByUser(provider) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getServiceProvidersByUser(provider)
            .then(
                result => {
                    dispatch(success(result))
                    if (result && result.stripe_Account_Id) {
                        dispatch(stripeActions.checkStripeConnectVerification(result.stripe_Account_Id));
                    }
                    else {
                        dispatch(verificationSuccess());
                    }
                },
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.GETSERVICEPROVIDER_REQUEST } }
    function success(service_Provider) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_SUCCESS, service_Provider } }
    function failure(error) { return { type: serviceproviderConstants.GETSERVICEPROVIDER_FAILURE } }
    function verificationSuccess() { return { type: stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_SUCCESS, stripe_Verified: {} } }
}

function getClient(client) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getClient(client)
            .then(
                result => dispatch(success(result.client)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.GETCLIENT_REQUEST } }
    function success(client) { return { type: serviceproviderConstants.GETCLIENT_SUCCESS, client } }
    function failure(error) { return { type: serviceproviderConstants.GETCLIENT_FAILURE, error } }
}

function getAllClients(user_id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getAllClients(user_id)
            .then(
                result => dispatch(success(result.clients)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.GETALLCLIENTS_REQUEST } }
    function success(clients) { return { type: serviceproviderConstants.GETALLCLIENTS_SUCCESS, clients } }
    function failure(error) { return { type: serviceproviderConstants.GETALLCLIENTS_FAILURE, error } }
}

function getAllClientProjectsAndProposals(user_id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getAllClientProjectsAndProposals(user_id)
            .then(
                projects => dispatch(success(projects)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.GETPROJECTS_REQUEST } }
    function success(projects) { return { type: serviceproviderConstants.GETPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: serviceproviderConstants.GETPROJECTS_FAILURE, error } }
}

function getProjects(provider_id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getProjects(provider_id)
            .then(
                result => dispatch(success(result.projects)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.GETPROJECTS_REQUEST } }
    function success(projects) { return { type: serviceproviderConstants.GETPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: serviceproviderConstants.GETPROJECTS_FAILURE, error } }
}

function getAllProjects(user_id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getAllProjects(user_id)
            .then(
                projects => dispatch(success(projects)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.GETPROJECTS_REQUEST } }
    function success(projects) { return { type: serviceproviderConstants.GETPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: serviceproviderConstants.GETPROJECTS_FAILURE, error } }
}

function getProject(project_Id, user_Id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.getProject(project_Id, user_Id)
            .then(
                project => dispatch(success(project)),
                error => {
                    if (window.location.href.includes('sp/projects')) {
                        history.location.href = pluginService.redirect("/sp/projects");
                    }
                    else if (window.location.href.includes('cl/projects')) {
                        history.location.href = pluginService.redirect("/cl/projects");
                    }
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: serviceproviderConstants.GETPROJECT_REQUEST } }
    function success(project) { return { type: serviceproviderConstants.GETPROJECT_SUCCESS, project } }
    function failure(error) { return { type: serviceproviderConstants.GETPROJECT_FAILURE, error } }
}

function setProject(project) {
    return async dispatch => {
        dispatch(success(project))
    };

    function success(project) { return { type: serviceproviderConstants.GETPROJECT_SUCCESS, project } }
}

function createProject(project) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.createProject(project)
            .then(
                result => dispatch(success(result.project)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.CREATEPROJECT_REQUEST } }
    function success(project) { return { type: serviceproviderConstants.CREATEPROJECT_SUCCESS, project } }
    function failure(error) { return { type: serviceproviderConstants.CREATEPROJECT_FAILURE, error } }
}

function updateProject(project) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.updateProject(project)
            .then(
                result => dispatch(success(result.project)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.UPDATEPROJECT_REQUEST } }
    function success(project) { return { type: serviceproviderConstants.UPDATEPROJECT_SUCCESS, project } }
    function failure(error) { return { type: serviceproviderConstants.UPDATEPROJECT_FAILURE, error } }
}

function createProjectProposal(proposal) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.createProposal(proposal)
            .then(
                result => dispatch(success(result.proposal)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.CREATEPROJECTPROPOSAL_REQUEST } }
    function success(proposal) { return { type: serviceproviderConstants.CREATEPROJECTPROPOSAL_SUCCESS, proposal } }
    function failure(error) { return { type: serviceproviderConstants.CREATEPROJECTPROPOSAL_FAILURE, error } }
}

function updateProposalStatus(proposal) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.updateProposalStatus(proposal)
            .then(
                result => dispatch(success(result.proposal)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_REQUEST } }
    function success(proposal) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_SUCCESS, proposal } }
    function failure(error) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALSTATUS_FAILURE } }
}

function updateProposalView(proposal) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.updateProposalView(proposal)
            .then(
                result => dispatch(success(result.proposal)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_REQUEST } }
    function success(proposal) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_SUCCESS, proposal } }
    function failure(error) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSALVIEW_FAILURE } }
}

function createWorkSegment(segment) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.createWorkSegment(segment)
            .then(
                result => dispatch(success(result.segment)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.CREATEWORKSEGMENT_REQUEST } }
    function success(segment) { return { type: serviceproviderConstants.CREATEWORKSEGMENT_SUCCESS, segment } }
    function failure(error) { return { type: serviceproviderConstants.CREATEWORKSEGMENT_FAILURE, error } }
}

function editWorkSegment(segment) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.editWorkSegment(segment)
            .then(
                result => dispatch(success(result.segment)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.EDITWORKSEGMENT_REQUEST } }
    function success(segment) { return { type: serviceproviderConstants.EDITWORKSEGMENT_SUCCESS, segment } }
    function failure(error) { return { type: serviceproviderConstants.EDITWORKSEGMENT_FAILURE, error } }
}

function deleteWorkSegment(segment_Id) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.deleteWorkSegment(segment_Id)
            .then(
                result => dispatch(success(result.segment)),
                error => dispatch(failure())
            );
    };

    function request() { return { type: serviceproviderConstants.DELETEWORKSEGMENT_REQUEST } }
    function success(segment) { return { type: serviceproviderConstants.DELETEWORKSEGMENT_SUCCESS, segment } }
    function failure(error) { return { type: serviceproviderConstants.DELETEWORKSEGMENT_FAILURE } }
}

function updateProjectProposal(project) {
    return async dispatch => {
        dispatch(request());
        await serviceProviderService.updateProject(project)
            .then(
                result => dispatch(success(result.project)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSAL_REQUEST } }
    function success(project) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSAL_SUCCESS, project } }
    function failure(error) { return { type: serviceproviderConstants.UPDATEPROJECTPROPOSAL_FAILURE, error } }
}

function alertChannelUpdate(channel) {
    return async dispatch => {
        dispatch(alert(channel));
    };

    function alert() { return { type: serviceproviderConstants.ALERT_CHANNELUPDATE, channel } }
}