import { Checkbox, FormControlLabel, FormGroup, InputAdornment, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { sharedService } from '../../../../_services';
import { Registration_AutoSuggestion } from "../../../Account/RegisterPage/Registration_AutoSuggestion";

class GC_Onboarding_ProfessionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
    }
  }
  render() {
    const {
      updateNav,
      service_Provider,
      category_TagsError,
      submitted,
      handleSelectionChange,
      industry_Focus_List_Cat,
      industry_Service_List_Cat,
      foundation_Expertise_List_Cat,
      agency_Expertise_List_Cat,
      handleCategorySelection,
      handleCategoryInput,
      handleChange,
      is_New_Org,
      new_Organization
    } = this.props;
    const { isNotValid } = this.state;
    const nextPage = () => {
      this.setState({
        isNotValid: true
      });
      if (JSON.stringify(service_Provider.SP_Role_Tags).includes(true)
        &&
        service_Provider.SP_Industry_Focus_Tags.length > 0
        &&
        service_Provider.SP_Industry_Services_Tags.length > 0
        &&
        (!service_Provider.References //empty
          || service_Provider.References.length < 60001) //less than 60,000 chars
        &&
        sharedService.isInteger(service_Provider.Base_Hourly_Rate)
      ) {
        this.setState({
          isNotValid: false
        });
        updateNav("onboard_contactTerms")
      }
    }
    const backPage = () => {
      if (!is_New_Org) {
        this.setState({
          isNotValid: false
        });
        updateNav("onboard_basics")
      } else {
        updateNav("onboard_businessAddress")
      }
    }

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ? "7 of 8" : "5 of 8"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ?
            <div className="col-md-10" >
              <div className="col-md-9 progress-color" />
              <div className="col-md-3 gray-progress-color" />
            </div> :
            <div className="col-md-10" >
              <div className="col-md-7 progress-color" />
              <div className="col-md-5 gray-progress-color" />
            </div>}
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header">Professional Info</h1>
            <p>Now we'll gather information that will help our users find you in the Talent Finder.</p>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-2 gc_onboarding-form-col">
                <p style={{ fontSize: 16, maxWidth: 500 }}>
                  <strong>
                    Select Your Role(s) <span className="red-star">*</span>
                  </strong>
                </p>
              </div>
              <div className="col-md-10 gc_onboarding-form-col">
                <FormGroup style={{ display: "block" }}>
                  <div className="row">
                    <div className="col-md-3 gc_onboarding-form-col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={service_Provider.SP_Role_Tags.grant_Writer}
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="grant_Writer"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#CFDFFF",
                              display: "inline-table",
                            }}
                          >
                            Grant Writer
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={service_Provider.SP_Role_Tags.startup_Advisor}
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="startup_Advisor"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#C2F5E9",
                              display: "inline-table",
                            }}
                          >
                            Startup Advisor
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                    </div>
                    <div className="col-md-3 gc_onboarding-form-col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={service_Provider.SP_Role_Tags.technical_Consultant}
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="technical_Consultant"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#cdb0ff",
                              display: "inline-table",
                            }}
                          >
                            Technical Consultant
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              service_Provider.SP_Role_Tags.accounting_Professional
                            }
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="accounting_Professional"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#FFEAB6",
                              display: "inline-table",
                            }}
                          >
                            Accounting Professional
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                    </div>
                    <div className="col-md-3 gc_onboarding-form-col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={service_Provider.SP_Role_Tags.attorney}
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="attorney"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#FFDAF6",
                              display: "inline-table",
                            }}
                          >
                            Attorney
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={service_Provider.SP_Role_Tags.generalist}
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="generalist"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#D1F7C4",
                              display: "inline-table",
                            }}
                          >
                            Generalist
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                    </div>
                    <div className="col-md-3 gc_onboarding-form-col">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              service_Provider.SP_Role_Tags.non_Profit_Advisor
                            }
                            onChange={(e) => handleSelectionChange(e)}
                            name="service_Provider_Roles"
                            id="non_Profit_Advisor"
                            style={{
                              marginBottom: '4px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              // backgroundColor: "#CCC",
                              display: "inline-table",
                            }}
                          >
                            Non-Profit Advisor
                          </Typography>
                        }
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
            {isNotValid &&
              !JSON.stringify(service_Provider.SP_Role_Tags).includes(
                true
              ) && (
                <p className="red-message">
                  You must select a role type.
                </p>
              )}
            <hr />
            <br />
            {/* Industry / Services */}
            <div className="row" id="GW_Onboarding_Focus">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  Industry Focus <span className="red-star">*</span>
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  Tell us a bit about your industry focus. Where do you do most
                  of your work?
                </p>
                <Registration_AutoSuggestion
                  categories={industry_Focus_List_Cat}
                  categoryName="SP_Industry_Focus_Tags"
                  currentSelectedCategories={
                    service_Provider.SP_Industry_Focus_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={handleCategorySelection}
                  handleCategoryInput={handleCategoryInput}
                  style={{
                    padding: 0,
                  }}
                />
                {isNotValid &&
                  service_Provider.SP_Industry_Focus_Tags.length === 0 && (
                    <p className="red-message">
                      You must select a focus type.
                    </p>
                  )}
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right mobile-margin">
                <p className="gc_onboarding-form-input-name-top">
                  Services Provided <span className="red-star">*</span>
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">What services do you or your team provide?</p>
                <Registration_AutoSuggestion
                  categories={industry_Service_List_Cat}
                  categoryName="SP_Industry_Services_Tags"
                  currentSelectedCategories={
                    service_Provider.SP_Industry_Services_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={handleCategorySelection}
                  handleCategoryInput={handleCategoryInput}
                  className="Registration_AutoSuggestion_display"
                />
                {isNotValid &&
                  service_Provider.SP_Industry_Services_Tags.length === 0 && (
                    <p className="red-message">
                      You must select service(s) you provide.
                    </p>
                  )}
              </div>
            </div>
            <br />
            <hr />
            <br />
            {/* Additional Info */}
            <div className="row" id="GW_Onboarding_Focus">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  Foundation Expertise
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  Please select foundations you have worked with in the past.
                </p>
                <Registration_AutoSuggestion
                  categories={foundation_Expertise_List_Cat}
                  categoryName="GW_Foundation_Tags"
                  currentSelectedCategories={
                    service_Provider.GW_Foundation_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={handleCategorySelection}
                  handleCategoryInput={handleCategoryInput}
                  style={{
                    padding: 0,
                  }}
                />
                {/* {isNotValid &&
                  service_Provider.GW_Foundation_Tags.length == 0 && (
                    <p className="red-message">
                      You must select a foundation expertise.
                    </p>
                  )} */}
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right mobile-margin">
                <p className="gc_onboarding-form-input-name-top">
                  Agencies You Work With
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  Please select agencies you have worked with in the past.
                </p>
                <Registration_AutoSuggestion
                  categories={agency_Expertise_List_Cat}
                  categoryName="GW_Agency_Tags"
                  currentSelectedCategories={
                    service_Provider.GW_Agency_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={handleCategorySelection}
                  handleCategoryInput={handleCategoryInput}
                  style={{
                    padding: 0,
                  }}
                />
                {/* {isNotValid &&
                  service_Provider.GW_Agency_Tags.length == 0 && (
                    <p className="red-message">
                      You must select a an agency you work with.
                    </p>
                  )} */}
              </div>
            </div>
            <div className="row" id="SP_Onboarding_Additional_Info">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  Hourly Rate <span className="red-star">*</span>
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  What is your general hourly rate? Please
                  write $0 if you're offering pro-bono services. <br />
                  *You can also change this rate for each project or work
                  segment.
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  id="service-provider-standard-basic"
                  name="Base_Hourly_Rate"
                  value={service_Provider.Base_Hourly_Rate}
                  onChange={handleChange}
                  // onKeyDown={(evt) =>
                  //   !this.numRegex(evt.key) && evt.preventDefault() // not working.
                  // }
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
                {service_Provider.Base_Hourly_Rate && !sharedService.isInteger(service_Provider.Base_Hourly_Rate) &&
                  <p className="red-message">
                    You may only enter positive integers.
                  </p>
                }
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right" id="service-provider-onboarding-textarea">
                {/* {isNotValid && !service_Provider.References && (
                  <p className="red-message">
                    You must enter a link to past client/customer testimonials and/or references.
                  </p>
                )} */}
                <p className="gc_onboarding-form-input-name-top">Testimonials</p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  Please copy/paste or link to past client/customer
                  testimonials and/or references. For grant writers,
                  contactable customer references are preferred.
                </p>
                <textarea
                  className="gc_onboarding-form-textarea"
                  type="text"
                  id="service-provider-standard-basic"
                  name="References"
                  value={service_Provider.References}
                  onChange={handleChange}
                  multiline
                  rowsMax="20"
                  rows={10}
                />
                {service_Provider.References //is not empty
                  && service_Provider.References.length > 60000  //is over 60,000 char limit
                  &&
                  <p className="red-message">
                    You've gone over the 60,000 character limit for testimonials.
                    Please shorten your entry, or paste links to your testimonials instead.
                  </p>
                }
              </div>
            </div>
            <br />
            <hr />
            <br />
            {/* Mentor Program */}
            <div className="row" id="SP_Onboarding_Mentor_Program">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name-top">
                  Mentor Program
                </p>
                {/* Interest */}
                <div style={{ marginBottom: 20 }}>
                  <p className="gc_onboarding-form-input-name-top-secondary">
                    Are you interested in the mentoring program at OpenGrants?
                  </p>
                  <div>
                    <input
                      className="gc_onboarding-form-input"
                      type="radio"
                      id="Mentor_Program_Yes"
                      name="Mentor_Program_Option"
                      value={true}
                      onChange={handleChange}
                      style={{ width: "auto" }}
                    />
                    <label for="Mentor_Program_Yes" style={{ display: "inline-block" }}>Yes</label>
                  </div>
                  <div>
                    <input
                      className="gc_onboarding-form-input"
                      type="radio"
                      id="Mentor_Program_No"
                      name="Mentor_Program_Option"
                      defaultChecked={!service_Provider.Mentor_Program_Option}
                      value={false}
                      onChange={handleChange}
                      style={{ width: "auto" }}
                    />
                    <label for="Mentor_Program_No" style={{ display: "inline-block" }}>No</label>
                  </div>
                </div>
                {/* Capacity */}
                {service_Provider.Mentor_Program_Option === 'true' &&
                  <div>
                    <p className="gc_onboarding-form-input-name-top-secondary">
                      What capacity are you interested in?
                    </p>
                    <div>
                      <input
                        className="gc_onboarding-form-input"
                        type="radio"
                        id="Mentor_Program_Mentor"
                        name="Mentor_Program"
                        value="Mentor"
                        onChange={handleChange}
                        style={{ width: "auto" }}
                      />
                      <label for="Mentor_Program_Mentor" style={{ display: "inline-block" }}>Mentor</label>
                    </div>
                    <div>
                      <input
                        className="gc_onboarding-form-input"
                        type="radio"
                        id="Mentor_Program_Mentee"
                        name="Mentor_Program"
                        value="Mentee"
                        onChange={handleChange}
                        style={{ width: "auto" }}
                      />
                      <label for="Mentor_Program_Mentee" style={{ display: "inline-block" }}>Mentee</label>
                    </div>
                  </div>}
              </div>
            </div>
            <hr />
          </form>

          {/* Buttons */}
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => backPage()}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={() => nextPage()}
              >
                Next <i class="icon-arrow-right" />
              </button>
            </div>
          </div>
          {isNotValid && (
            <p style={{ marginTop: 30, color: "red" }}>
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_ProfessionalInfo =
  connect(mapStateToProps)(GC_Onboarding_ProfessionalInfo);
export { connectedGC_Onboarding_ProfessionalInfo as GC_Onboarding_ProfessionalInfo };

