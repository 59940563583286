import React from 'react';
import { connect } from 'react-redux';
import { serviceProviderActions } from '../../../../_actions';
import { chatService, pluginService } from '../../../../_services';

let accentColor = pluginService.getIFrameColor()

class RejectedChatBox_v2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newMessage: '',
            conversationProxy: this.props.conversationProxy,

            messageInfo: {
                channel: null,
                last_Message: "",
                last_Message_User_Id: "",
            },
        };
    }

    sendMessage = async event => {

        const message = this.state.newMessage;
        if (!message) {
            return
        }
        this.setState({ newMessage: '' });
        this.state.conversationProxy.sendMessage(message);
        const user_id = JSON.parse(localStorage.getItem('user')).id;

        await this.setState({
            messageInfo: {
                channel: this.props.client.channel,
                last_Message: message,
                last_Message_User_Id: user_id,
                user_Notified: false
            }
        })

        await chatService.updateChatInfo(this.state.messageInfo);

        let client = Object.assign({}, this.props.client);
        client.last_Message_User_Id = user_id;
        client.last_Message = message;
        client.last_Message_Sent = (new Date()).toISOString();
        this.props.dispatch(serviceProviderActions.alertChannelUpdate(client));

        if (this.props.productPurchase) {
            this.props.toggleRejection()
        }
    };

    handleInput = (e) => {
        let value = e.target.value;

        this.setState({
            newMessage: value
        });
    }

    rejectProposal = () => {
        this.sendMessage();
        this.props.handleRejectFunction();
    }

    render() {

        const { newMessage } = this.state;
        const { client, productPurchase } = this.props;

        return (


            <div>
                {client &&
                    <div>
                        <form onSubmit={this.sendMessage}>
                            <div style={productPurchase ? { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" } : {}}>
                                <textarea
                                    className="gc_onboarding-form-textarea"
                                    placeholder="Type your message here"
                                    value={newMessage}
                                    onChange={this.handleInput}
                                    rows={15}
                                    style={{ fontFamily: "Ubuntu", fontSize: "16px", fontWeight: "bold", width: productPurchase ? "80%" : "100%" }}
                                />

                                {productPurchase ?
                                    <div className="row mobileBtnRow w-100" style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                                        <div className='col-6 text-end'>
                                            <button
                                                type="button"
                                                className="defaultWhiteButton"
                                                onClick={() => this.props.toggleRejection()}
                                                style={{ padding: "10px 30px", minWidth: 100, marginRight: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                            >
                                                Close
                                            </button>
                                        </div>
                                        <div className='col-6'>
                                            <button
                                                type="button"
                                                className="defaultGreenButton"
                                                onClick={() => { this.sendMessage() }}
                                                style={{ padding: "10px 30px", minWidth: 100, marginLeft: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mobileBtnRow w-100">
                                        <div className="col-md-6 gc_onboarding-form-col">
                                            <button
                                                type="button"
                                                className="gc_started-button-back"
                                                onClick={() => this.props.toggleRejection()}
                                                style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}

                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="col-md-6 gc_onboarding-form-col">
                                            <button
                                                type="button"
                                                className="gc_started-button-next"
                                                onClick={() => this.rejectProposal()}
                                                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                            >
                                                Reject
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </form>
                    </div>
                }
            </div>

        );
    }
}

function mapStateToProps(state) {
    let { email_notification_settings } = state.get_email_notification_settings;
    let new_email_notification_settings = state.update_email_notification_settings.email_notification_settings;

    if (new_email_notification_settings) {
        email_notification_settings = new_email_notification_settings;
    }

    return (
        email_notification_settings
    )
}

const connectedRejectedChatBox = connect(mapStateToProps)(RejectedChatBox_v2);
export { connectedRejectedChatBox as RejectedChatBox_v2 };
