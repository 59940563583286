import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const favoritesService = {
    getFavorites,
    saveListing,
    saveFunder,
    exportFavorites
};

function getFavorites(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/favorites/get_user_favorites', requestOptions)
        .then(sharedService.handleResponse)
        .then(favorites => {
            localStorage.setItem('favorite', JSON.stringify(favorites));

            return favorites;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function saveListing(listing_id, user, match) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/favorites/save_listing?listing_id=' + listing_id + "&user_id=" + user.id + "&match=" + match, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function saveFunder(funder_id, user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/favorites/save/funder?funder_id=' + funder_id + "&user_id=" + user.id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function exportFavorites(user_id, file_type) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/favorites/export_favorites?user_id=' + user_id + "&file_type=" + file_type, requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}