// set endpoint based on production or beta env
const URL = window.location.hostname;
let API_Route = "https://refactor.opengrants.io"

if (URL.includes("test.opengrants.io")) {
    API_Route = "https://refactortest.opengrants.io"
}
else if (URL.includes("beta.opengrants.io")) {
    API_Route = "https://refactortest.opengrants.io"
}
else if (URL.includes("sandbox.opengrants.io")) {
    API_Route = "https://refactorsandbox.opengrants.io"
}
else if (URL.includes("localhost")) {
    API_Route = "https://refactortest.opengrants.io"
}

export { API_Route };