import React from 'react'
import { InsertDriveFileOutlined, LinkOutlined } from '@mui/icons-material';
import { format, parseISO } from "date-fns";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../Styles/ServiceProvider_v2/Projects_v2.css';
import "../../_assets/img/icons/sideNavIcons/css/fontello.css";
import { Loader } from '../../_pages/Shared/Loader';
import { marketplaceProjectService, pluginService, serviceProviderService } from '../../_services';

export const InvoiceDetails = ({ invoice, isOpen, closeModal }) => {

  const { user } = useSelector(state => state.setUser);
  const [documents, setDocuments] = useState(null);
  const [product, setProduct] = useState(false);

  const { project_Segments, mP_Project_Segments } = invoice;

  let accentColor = pluginService.getIFrameColor()

  useEffect(() => {

    const getDocuments = async () => {

      //sp project
      if (project_Segments && project_Segments.length !== 0) {
        let project = await serviceProviderService.getProject(project_Segments[0].project_Id, user.id);
        setDocuments(project.project.document_Links)
      }
      //mp project
      else if (mP_Project_Segments.length !== 0) {
        let project = await marketplaceProjectService.get_project(mP_Project_Segments[0].project_Id, ['documents'])
        setDocuments(project.documents)
      }
      //fixed-rate product
      else {
        setProduct(true)
      }
    }

    getDocuments();

  }, [])

  //Add HTTPS to URLs
  const withHttp = (url) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  //Truncate attachment name for display
  const truncate = (text) => {
    return text.length > 28 ? text.substring(0, 25) + "..." : text;
  };

  //Download an existing file
  const downloadFile = async (documentObj) => {

    let blob;

    if (mP_Project_Segments && mP_Project_Segments.length !== 0) {
      blob = await marketplaceProjectService.download_mp_document(documentObj);
    }
    else {
      blob = await serviceProviderService.downloadDocument(documentObj);
    }

    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    aTag.download = documentObj.document_Name
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  //Generate attachment files and links
  const generateLinks = (segment) => {

    return documents.map((doc) => {

      if (doc.segment_Id === segment.segment_Id) {

        return (
          <div key={doc.document_Link} className="attachmentTagBox preview">
            {doc.document_Link &&
              <div className="attachmentTagContent">
                <LinkOutlined className="attachmentTagIcon link" />
                <a className="attachmentTagName" href={withHttp(doc.document_Link)} target="_blank" rel="noopener noreferrer" >{truncate(doc.document_Link)}</a>
              </div>
            }
            {doc.document_Name &&
              <div className="attachmentTagContent">
                <InsertDriveFileOutlined className="attachmentTagIcon" />
                <a className="attachmentTagName" onClick={() => { downloadFile(doc) }}>{truncate(doc.document_Name)}</a>
              </div>
            }
          </div>
        )
      }
    })
  }

  function displaySegments() {

    let segments = project_Segments && project_Segments.length !== 0 ? project_Segments : mP_Project_Segments;

    return (
      segments.map((segment) => {

        let links = generateLinks(segment);

        return (
          <>
            {/* DESKTOP */}

            <div className='row hoursInvoicesRow' style={{ flexDirection: "column" }}>
              <div className='row' style={{ display: "flex" }}>
                <div className="col-md-5"><p>{segment.segment_Title}</p></div>

                {((mP_Project_Segments && mP_Project_Segments.length !== 0 && !segment.segment_Hours) || (segment.segment_Flat_Rate)) ?
                  <div className="col-md-4"><p>${segment.segment_Flat_Rate}</p></div>
                  :
                  <>
                    <div className="col-md-2"><p>{segment.segment_Hours}</p></div>
                    <div className="col-md-2"><p>${segment.segment_Hourly_Rate}/hr</p></div>
                  </>
                }
                <div className="col-md-2"><p>{format(parseISO(segment.segment_Date_Performed), "MM/dd/yyyy")}</p></div>
              </div>
              {links && <div className='row hoursInvoicesRowAttachments'>{links}</div>}
            </div>

            {/* MOBILE */}

            <div className="hoursInvoicesRowMobile">
              <div className="leftColumn"><p>Description of Work:</p></div>
              <div className="rightColumn"><p>{segment.segment_Title}</p></div>
            </div>
            {((mP_Project_Segments && mP_Project_Segments.length !== 0 && !segment.segment_Hours) || (segment.segment_Flat_Rate)) ?
              <div className="hoursInvoicesRowMobile">
                <div className="leftColumn"><p>Flat Rate:</p></div>
                <div className="rightColumn"><p>${segment.segment_Flat_Rate}</p></div>
              </div>
              :
              <>
                <div className="hoursInvoicesRowMobile">
                  <div className="leftColumn"><p># of Hours:</p></div>
                  <div className="rightColumn"><p>{segment.segment_Hours}</p></div>
                </div>
                <div className="hoursInvoicesRowMobile">
                  <div className="leftColumn"><p>Rate:</p></div>
                  <div className="rightColumn"><p>${segment.segment_Hourly_Rate}/hr</p></div>
                </div>
              </>
            }
            <div className="hoursInvoicesRowMobile">
              <div className="leftColumn"><p>Date:</p></div>
              <div className="rightColumn"><p>{format(parseISO(segment.segment_Date_Performed), "MM/dd/yyyy")}</p></div>
            </div>
            {links && <div className='hoursInvoicesRowAttachmentsMobile'>{links}</div>}

            <hr className='hoursInvoicesHr' />
          </>
        )
      })
    )
  }

  function displayProduct() {

    return (
      <>
        {/* DESKTOP */}

        <div className='row hoursInvoicesRow' style={{ flexDirection: "column" }}>
          <div className='row' style={{ display: "flex" }}>
            <div className="col-md-5"><p>{invoice.decription}</p></div>
            <div className="col-md-4"><p>${invoice.total_Amount}</p></div>
            <div className="col-md-2"><p>{format(parseISO(invoice.date_Created), "MM/dd/yyyy")}</p></div>
          </div>
        </div>

        {/* MOBILE */}

        <div className="hoursInvoicesRowMobile">
          <div className="leftColumn"><p>Product:</p></div>
          <div className="rightColumn"><p>{invoice.description}</p></div>
        </div>

        <div className="hoursInvoicesRowMobile">
          <div className="leftColumn"><p>Cost:</p></div>
          <div className="rightColumn"><p>${invoice.total_Amount}</p></div>
        </div>

        <div className="hoursInvoicesRowMobile">
          <div className="leftColumn"><p>Date:</p></div>
          <div className="rightColumn"><p>{format(parseISO(invoice.date_Created), "MM/dd/yyyy")}</p></div>
        </div>

        <hr className='hoursInvoicesHr' />
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        centered={true}
        backdrop={true}
        fade={false}
        className="ModalDiv invoiceModal"
        size='xl'
      >
        <ModalHeader className="invoiceModalHeader" style={{ padding: "3rem 3rem 0 3rem" }}> Invoice Summary </ModalHeader >

        <ModalBody className="invoiceModalBody" style={{ padding: "0 3rem 0 3rem" }}>

          {/* Display segments  */}

          {documents &&
            <>
              <div className="row hoursInvoicesHeader">
                <div className="col-md-5"><p>Description of Work</p></div>
                {((mP_Project_Segments && mP_Project_Segments.length !== 0 && !mP_Project_Segments[0].segment_Hours) || (project_Segments[0].segment_Flat_Rate)) ?
                  <div className='col-md-4'><p>Flat Rate</p></div>
                  :
                  <>
                    <div className="col-md-2"><p># of Hours</p></div>
                    <div className="col-md-2"><p>Rate</p></div>
                  </>
                }
                <div className="col-md-3"><p>Date</p></div>
              </div>

              <div>
                {displaySegments()}
              </div>
            </>
          }

          {/*  Display product  */}

          {product &&
            <>
              <div className="row hoursInvoicesHeader">
                <div className="col-md-5"><p>Product</p></div>
                <div className="col-md-4"><p>Cost</p></div>
                <div className="col-md-2"><p>Date</p></div>
              </div>

              <div>
                {displayProduct()}
              </div>
            </>
          }

        </ModalBody>

        <ModalFooter className='invoiceModalFooter' style={{ padding: "0 3rem 3rem 3rem" }}>
          <button className='defaultGreenButton' style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={() => { closeModal() }}>Close</button>
        </ModalFooter>

      </Modal >
    </>
  );
}