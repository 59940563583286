import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Onboarding.css";

class GC_Onboarding_Confirmation extends React.Component {
  render() {
    return (
      <>
        <div className="gs_confirmation-card">
          <h1 className="onboarding-form-header">Thank you!</h1>
          <p>We have received your application to be listed as a Grant Consultant on OpenGrants. Our team will review your application for fit and performance. You'll receive an email within the next few business days with an answer. In the meantime, explore your dashboard and see what OpenGrants is all about.</p>
          <a href="/dashboard">
            Go to Dashboard <i class="icon-arrow-right" />
          </a>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const GC_connectedOnboarding_Confirmation =
  connect(mapStateToProps)(GC_Onboarding_Confirmation);
export { GC_connectedOnboarding_Confirmation as GC_Onboarding_Confirmation };

