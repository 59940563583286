import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Onboarding.css";
import party from '../../../_assets/img/AlluraParty.png';
import { asyncLocalStorage, pluginService } from "../../../_services";

class GS_Onboarding_Confirmation extends React.Component {
  state = {
    iFrameConfig: null
  }

  async componentDidMount() {
    await asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value });
    });
  }

  showButtons = () => {
    const redirect_url = sessionStorage.getItem("redirect");
    sessionStorage.removeItem("redirect");
    const { iFrameConfig } = this.state;
    return (redirect_url ?
      <>
        <br />
        {this.redirectPage(5000, redirect_url + iFrameConfig?.url)}
        <p>
          You will automatically be redirected. If you are not redirected within 10 seconds, please <a onClick={() => window.location.href = pluginService.redirect(redirect_url)}>click here</a>
        </p>
      </>
      :
      <a href={pluginService.redirect("/dashboard?next=true")}>
        Go to Dashboard <i class="icon-arrow-right" />
      </a>
    )
  }

  redirectPage = (timeout, url) => {
    setTimeout(() => {
      window.location.href = pluginService.redirect(url);
    }, timeout);
  }

  render() {
    return (
      <>
        <div className="gs_confirmation-card" style={{ backgroundImage: `url(${party})` }}>
          <h1 className="onboarding-form-header">Congratulations!</h1>
          <p>Nice work, your profile is complete! We'll start finding grants that fit your profile. Don't wait for us though, start searching for grants and hiring experts right away.</p>
          {this.showButtons()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const GS_connectedOnboarding_Confirmation =
  connect(mapStateToProps)(GS_Onboarding_Confirmation);
export { GS_connectedOnboarding_Confirmation as GS_Onboarding_Confirmation };
