export const serviceproviderConstants = {
    GETSERVICEPROVIDER_REQUEST: 'GETSERVICEPROVIDER_REQEUST',
    GETSERVICEPROVIDER_SUCCESS: 'GETSERVICEPROVIDER_SUCCESS',
    GETSERVICEPROVIDER_FAILURE: 'GETSERVICEPROVIDER_FAILURE',

    GETCLIENT_REQUEST: 'GETCLIENT_REQEUST',
    GETCLIENT_SUCCESS: 'GETCLIENT_SUCCESS',
    GETCLIENT_FAILURE: 'GETCLIENT_FAILURE',

    GETALLCLIENTS_REQUEST: 'GETALLCLIENTS_REQEUST',
    GETALLCLIENTS_SUCCESS: 'GETALLCLIENTS_SUCCESS',
    GETALLCLIENTS_FAILURE: 'GETALLCLIENTS_FAILURE',

    GETPROJECTS_REQUEST: 'GETPROJECTS_REQEUST',
    GETPROJECTS_SUCCESS: 'GETPROJECTS_SUCCESS',
    GETPROJECTS_FAILURE: 'GETPROJECTS_FAILURE',

    GETPROJECT_REQUEST: 'GETPROJECT_REQEUST',
    GETPROJECT_SUCCESS: 'GETPROJECT_SUCCESS',
    GETPROJECT_FAILURE: 'GETPROJECT_FAILURE',

    CREATEPROJECT_REQUEST: 'CREATEPROJECT_REQEUST',
    CREATEPROJECT_SUCCESS: 'CREATEPROJECT_SUCCESS',
    CREATEPROJECT_FAILURE: 'CREATEPROJECT_FAILURE',

    UPDATEPROJECT_REQUEST: 'UPDATEPROJECT_REQEUST',
    UPDATEPROJECT_SUCCESS: 'UPDATEPROJECT_SUCCESS',
    UPDATEPROJECT_FAILURE: 'UPDATEPROJECT_FAILURE',

    CREATEPROJECTPROPOSAL_REQUEST: 'CREATEPROJECTPROPOSAL_REQEUST',
    CREATEPROJECTPROPOSAL_SUCCESS: 'CREATEPROJECTPROPOSAL_SUCCESS',
    CREATEPROJECTPROPOSAL_FAILURE: 'CREATEPROJECTPROPOSAL_FAILURE',

    UPDATEPROJECTPROPOSALSTATUS_REQUEST: 'UPDATEPROJECTPROPOSALSTATUS_REQEUST',
    UPDATEPROJECTPROPOSALSTATUS_SUCCESS: 'UPDATEPROJECTPROPOSALSTATUS_SUCCESS',
    UPDATEPROJECTPROPOSALSTATUS_FAILURE: 'UPDATEPROJECTPROPOSALSTATUS_FAILURE',

    UPDATEPROJECTPROPOSALVIEW_REQUEST: 'UPDATEPROJECTPROPOSALVIEW_REQUEST',
    UPDATEPROJECTPROPOSALVIEW_SUCCESS: 'UPDATEPROJECTPROPOSALVIEW_SUCCESS',
    UPDATEPROJECTPROPOSALVIEW_FAILURE: 'UPDATEPROJECTPROPOSALVIEW_FAILURE',

    UPDATEPROJECTPROPOSAL_REQUEST: 'UPDATEPROJECTPROPOSAL_REQEUST',
    UPDATEPROJECTPROPOSAL_SUCCESS: 'UPDATEPROJECTPROPOSAL_SUCCESS',
    UPDATEPROJECTPROPOSAL_FAILURE: 'UPDATEPROJECTPROPOSAL_FAILURE',

    CREATEWORKSEGMENT_REQUEST: 'CREATEWORKSEGMENT_REQEUST',
    CREATEWORKSEGMENT_SUCCESS: 'CREATEWORKSEGMENT_SUCCESS',
    CREATEWORKSEGMENT_FAILURE: 'CREATEWORKSEGMENT_FAILURE',

    EDITWORKSEGMENT_REQUEST: 'EDITWORKSEGMENT_REQEUST',
    EDITWORKSEGMENT_SUCCESS: 'EDITWORKSEGMENT_SUCCESS',
    EDITWORKSEGMENT_FAILURE: 'EDITWORKSEGMENT_FAILURE',

    DELETEWORKSEGMENT_REQUEST: 'DELETEWORKSEGMENT_REQEUST',
    DELETEWORKSEGMENT_SUCCESS: 'DELETEWORKSEGMENT_SUCCESS',
    DELETEWORKSEGMENT_FAILURE: 'DELETEWORKSEGMENT_FAILURE',

    GETALLCLIENTPROJECTANDPROPOSALS_REQUEST: 'GETALLCLIENTPROJECTANDPROPOSALS_REQUEST',
    GETALLCLIENTPROJECTANDPROPOSALS_SUCCESS: 'GETALLCLIENTPROJECTANDPROPOSALS_SUCCESS',
    GETALLCLIENTPROJECTANDPROPOSALS_FAILURE: 'GETALLCLIENTPROJECTANDPROPOSALS_FAILURE',

    ALERT_CHANNELUPDATE: 'ALERT_CHANNELUPDATE'
}