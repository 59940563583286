import { css } from "@emotion/core";
import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import '../../../Styles/SearchResults.css';

// const override = css`
//   display: block;
//   margin: auto;
//   top: 45%;
//   left: 45%;
//   position: absolute;
// `;

export class Loader_v2 extends React.Component {

    render() {
        return (
            <div className="">
                <ClipLoader
                    // css={override}
                    size={60}
                    color={"#1A5A83"}
                //loading={this.props.searchResult.loading}
                />
            </div>
        )
    }
}
