import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/ServiceProvider_v2/Projects_v2.css';
import gfLogo from "../../../_assets/img/gf-payout-logo.png";
import spPayoutLogo from "../../../_assets/img/spPayoutLogo.png";
import { pluginService, sharedService } from '../../../_services';

let accentColor = pluginService.getIFrameColor()

class ServiceProviderPanel_v2 extends React.Component {
  render() {
    const { user, stripe_Verified } = this.props;

    return (
      <div className="dashboardBox">
        <div className={"row"} style={{ marginBottom: 50 }}>
          <div className="notificationCard"  >
            <div className="notificationCardLeft" style={{ padding: 0 }} >
              {user?.userRoles === "Client_Free" ?
                <img src={spPayoutLogo} alt="" className="projectCardLogos" />
                :
                <img src={gfLogo} alt="" className="projectCardLogos" />
              }
            </div>
            <div className="notificationCardRight">
              <div>
                <p className="p-header">Don't Forget to Create a Payout Method!</p>
                <p className="p-secondary">Your payout method is required before starting a project.</p>
              </div>
              <button
                className="notificationBtn"
                onClick={(sharedService.completedStripe(stripe_Verified)) ? this.props.stripe_Connect_Payout_Methods : this.props.stripe_Connect_Onboarding_Link}
                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
              >
                Create Payout Method
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { stripe_Verified } = state.stripeVerified;

  return {
    user,
    stripe_Verified
  };
}

const connectedServiceProviderPanel_v2 = connect(mapStateToProps)(ServiceProviderPanel_v2);
export { connectedServiceProviderPanel_v2 as ServiceProviderPanel_v2 };

