import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/Perks.css";
import { perksActions } from "../../_actions/perks.actions";
import { asyncLocalStorage } from '../../_services';

export default function Perks() {
    const [iFrameConfig, setIFrameConfig] = useState(null);

    const dispatch = useDispatch();
    const perkInfo = useSelector(state => state.update_perks);

    useEffect(() => {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            setIFrameConfig(value)
        });
        dispatch(perksActions.get_all_perks());
        // let result = perksService.get_all_perks()
    }, [])


    //Add HTTPS to URLs
    const withHttp = (url) => {
        return !/^https?:\/\//i.test(url) ? `http://${url}` : url
    }

    // Open link or email
    const redeemPerk = (link) => {

        if (link.includes("@")) {
            window.location = `mailto:${link}`
        }
        else {
            window.open(withHttp(link), "_blank", 'noopener, noreferrer');
        }
    }

    // let testData = [
    //     {
    //         logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwt5x02CYKxGF278Lu2P__dN511x0B5GgTlA&usqp=CAU",
    //         title: "Send 1 ETH, get 1000 ETH back",
    //         description: "Selfies tumblr selvage kitsch, pug snackwave cloud bread tacos actually jean shorts raclette. Beard air plant small batch, hammock craft beer humblebrag photo booth. Yuccie pork belly gentrify, shoreditch schlitz adaptogen readymade freegan viral +1 master cleanse asymmetrical.",
    //         link: "https://ethereum.org"
    //     },
    //     {
    //         logo: "https://s3.amazonaws.com/skipsolabs-california-energy-challenge/frontend/section/organization/8076/23b2c5ba8c9cdd8953cf.png",
    //         title: "20% 6-week Grant Writing Course",
    //         description: "Live-edge wolf subway tile enamel pin. Edison bulb literally cold-pressed leggings. Prism squid yuccie adaptogen. Enamel pin photo booth retro mlkshk raclette crucifix chambray. Affogato microdosing live-edge, shabby chic VHS adaptogen meh af pabst iceland migas bicycle rights slow-carb actually.",
    //         link: "www.opengrants.io"
    //     },
    //     {
    //         logo: "https://play-lh.googleusercontent.com/deyz04czPzpKP09vrklnBHU4S-gT_o6wLt2jCZeeifdh53VW6YfBffj8bEPh4xzG6A",
    //         title: "10% off 1-week Writing Course",
    //         description: "Mixtape vape typewriter affogato tousled poke franzen man braid offal palo santo. Skateboard fixie pork belly drinking vinegar echo park shabby chic hoodie snackwave ramps.",
    //         link: "smilte.val@gmail.com"
    //     },
    //     {
    //         logo: "https://1000logos.net/wp-content/uploads/2016/11/Citibank-logo.jpg",
    //         title: "$100 reward for new users",
    //         description: "Skateboard fixie pork belly drinking vinegar echo park shabby chic hoodie snackwave ramps. Selfies tumblr selvage kitsch, pug snackwave cloud bread tacos actually jean shorts raclette. Live-edge wolf subway tile enamel pin. Edison bulb literally cold-pressed leggings.",
    //         link: "citi.com"
    //     },
    // ];

    let perks = perkInfo?.perks?.map((perk) => {
        return (
            <div className="perkContainer">

                <div className='perkLogoContainer'>
                    <img className='perkLogo' src={perk.logo} alt="logo" />
                </div>

                <h4 className='perkTitle'> {perk.title} </h4>
                <p className='perkDescription'>{perk.description}</p>

                <button
                    className='perkButton defaultGreenButton'
                    onClick={() => { redeemPerk(perk.link) }}
                    style={{ background: iFrameConfig?.primary ? iFrameConfig.primary : '#0C7069', border: iFrameConfig?.primary ? `1px solid ${iFrameConfig.primary}` : '1px solid #0C7069' }}
                >
                    Redeem Perk
                </button>

            </div>
        )
    })

    return (
        <div className='perksContainer'>
            {perks}
        </div>
    )
}
