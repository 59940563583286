import { Checkbox, FormControlLabel, FormGroup, InputAdornment, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Login.css";
import "../../../../Styles/Marketplace.css";
import "../../../../Styles/Onboarding.css";
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";
import { locationsService } from "../../../../_services";
import { Registration_AutoSuggestion } from "../../../Account/RegisterPage/Registration_AutoSuggestion";
import { Previews } from "../../Onboarding/SP_Image_Upload";

class GC_Onboarding_Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    var city = await locationsService.getLocations("City");
    var state = await locationsService.getLocations("US_State");
    this.setState({
      city,
      state,
    });
  }

  render() {
    const {
      service_Provider,
      new_Organization,
      category_TagsError,
      submitted,
      handleSelectionChange,
      industry_Focus_List_Cat,
      industry_Service_List_Cat,
      form_errors,
      organizationStateError,
      user,
    } = this.props;
    const { state } = this.state;

    return (
      <>
        <form>
          {/* NAME/PHONE/EMAIL/PERSONAL BIO */}
          {/* BASICS */}
          <div className="onboarding-form-card">
            <h1>Basics</h1>
            <hr />
            <div className="row">
              <div className="col-md-6">
                {submitted && !new_Organization.organization.name && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must specify your first name.
                  </p>
                )}
                <p>
                  First Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={this.props.handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
              <div className="col-md-6">
                {submitted && !new_Organization.organization.name && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must specify your last name.
                  </p>
                )}
                <p>
                  Last Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  disabled
                  name="surname"
                  value={user.surname}
                  onChange={this.props.handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {submitted && !new_Organization.organization.phone_Number && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must enter a contact number.
                  </p>
                )}
                <p>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  name="phone_Number"
                  value={new_Organization.organization.phone_Number}
                  onChange={this.props.handleChange}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="col-md-6">
                {submitted && !service_Provider.Contact_Email && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must enter the contact email for your organization.
                  </p>
                )}
                <p>Primary Contact Email</p>
                <input
                  type="text"
                  disabled
                  id="service-provider-standard-basic"
                  name="Contact_Email"
                  value={service_Provider.Contact_Email}
                  onChange={this.props.handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 50,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  {submitted && !new_Organization.organization.description && (
                    <p style={{ color: "red", marginBottom: 5 }}>
                      You must provide a bio.
                    </p>
                  )}
                  <p>
                    Personal Bio <span style={{ color: "red" }}>*</span>
                  </p>
                </div>
                <textarea
                  name="description"
                  value={new_Organization.organization.description}
                  onChange={this.props.handleChange}
                  multiline
                  rowsMax="10"
                  rows={10}
                />
              </div>
            </div>
          </div>
          {/* TYPE OF ORG/COMPANY NAME */}
          <div className="onboarding-form-card">
            <h1>Organization Attributes</h1>
            <hr />
            <div>
              {submitted && !new_Organization.organization.name && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must enter the name for your organization.
                </p>
              )}
              <p>
                Company Name <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <input
                  type="text"
                  name="name"
                  value={new_Organization.organization.name}
                  onChange={this.props.handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 450,
                  }}
                />
              </div>
            </div>
          </div>
          {/* LOGO/WEBSITE/ORG DESCRIPTION */}
          <div className="onboarding-form-card">
            <h1>Organization Attributes</h1>
            <hr />
            <div>
              <div>
                {submitted && !new_Organization.organization.logo_URL && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must upload a headshot / logo.
                  </p>
                )}
                <p>
                  Upload Logo / Headshot (Max 5Mb)
                  <span style={{ color: "red" }}>*</span>
                </p>
                <p>
                  For best display, choose a square aspect image less than 500px
                  in size.
                </p>
                <input
                  type="hidden"
                  name="headshot"
                  value={new_Organization.organization.logo_URL}
                />
                <span id="image_error" className="error"></span>
                <div id="profileImage">
                  <Previews
                    removePreview={this.removePreview}
                    setOrgLogo={this.props.setOrgLogo}
                  />
                </div>
              </div>
            </div>
            <div>
              {submitted && !new_Organization.organization.website_URL && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must designate a website or url.
                </p>
              )}
              <p>
                Website / URL <span style={{ color: "red" }}>*</span>
              </p>
              <p>
                Link to your organization's website here. If you do not have
                one, a LinkedIn profile or similar page will suffice.
              </p>
              <input
                type="text"
                name="website_URL"
                value={new_Organization.organization.website_URL}
                onChange={this.props.handleChange}
              />
            </div>
            <div id="service-provider-onboarding-textarea">
              <div>
                {submitted && !new_Organization.organization.description && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must provide a bio.
                  </p>
                )}
                <p>
                  Organization Description
                  <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <input
                type="text"
                name="description"
                value={new_Organization.organization.description}
                onChange={this.props.handleChange}
                multiline
                rowsMax="20"
                rows={20}
              />
            </div>
          </div>
          <div className="onboarding-form-card">
            <h1>Business Address</h1>
            <hr />
            <div>
              {submitted && !new_Organization.organization.address && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must enter your organization's street address.
                </p>
              )}
              <p>
                Street Address <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                name="address"
                value={new_Organization.organization.address}
                onChange={this.props.handleChange}
                inputProps={{ maxLength: 200 }}
              />
            </div>
            <div>
              {submitted && !new_Organization.organization.city && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must enter your organization's city.
                </p>
              )}
              <p>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                name="city"
                value={new_Organization.organization.city}
                onChange={this.props.handleChange}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            {/* New Organization Location (Org State) */}
            <div>
              {submitted && !new_Organization.organization.state && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must enter your organization's state.
                </p>
              )}
              <p>
                State <span style={{ color: "red" }}>*</span>
              </p>
              {/* <label style={{marginBottom: "-5%"}}></label> */}
              <Registration_AutoSuggestion
                style={{ display: "inline-block" }}
                categories={{ state: state ? state : [] }}
                categoryName={"state"}
                currentSelectedCategories={[
                  new_Organization.organization.state
                    ? new_Organization.organization.state
                    : "",
                ]}
                handleCategorySelection={this.props.handleLocationSelection}
                handleCategoryInput={this.props.handleLocationInput}
                inputProps={{ maxLength: 50 }}
              />
              {organizationStateError && (
                <p style={{ color: "red" }}>Please select a valid input.</p>
              )}
            </div>
            {/* New Organization Location (Org Zipcode) */}
            <div>
              {submitted && !new_Organization.organization.zipcode && (
                <p>You must enter your organization's zipcode.</p>
              )}
              <p>
                Zipcode <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                name="zipcode"
                value={new_Organization.organization.zipcode}
                onChange={this.props.handleChange}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
          <div className="onboarding-form-card">
            <h1>Professional Info</h1>
            <hr />
            <div>
              {submitted &&
                !JSON.stringify(service_Provider.SP_Role_Tags).includes(
                  true
                ) && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must select a role type.
                  </p>
                )}
              <p style={{ fontSize: 18, maxWidth: 500 }}>
                <strong>
                  Select Your Role(s) <span style={{ color: "red" }}>*</span>
                </strong>
              </p>
              <p>
                If you're a grant writer with experience writing and winning
                grants, select Grant Writer. If you're a startup advisor select
                Startup Advisor. If you're any other type of grant consultant,
                select Other Grant Consultant.
              </p>
              <FormGroup style={{ display: "block" }}>
                <div style={{ paddingLeft: 15 }} className="SP_Checkboxes">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.SP_Role_Tags.grant_Writer}
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="grant_Writer"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#CFDFFF",
                          display: "inline-table",
                        }}
                      >
                        Grant Writer
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.SP_Role_Tags.startup_Advisor}
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="startup_Advisor"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#C2F5E9",
                          display: "inline-table",
                        }}
                      >
                        Startup Advisor
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          service_Provider.SP_Role_Tags.technical_Consultant
                        }
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="technical_Consultant"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#cdb0ff",
                          display: "inline-table",
                        }}
                      >
                        Technical Consultant
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          service_Provider.SP_Role_Tags.accounting_Professional
                        }
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="accounting_Professional"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#FFEAB6",
                          display: "inline-table",
                        }}
                      >
                        Accounting Professional
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.SP_Role_Tags.attorney}
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="attorney"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#FFDAF6",
                          display: "inline-table",
                        }}
                      >
                        Attorney
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.SP_Role_Tags.generalist}
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="generalist"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#D1F7C4",
                          display: "inline-table",
                        }}
                      >
                        Generalist
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          service_Provider.SP_Role_Tags.non_Profit_Advisor
                        }
                        onChange={(e) => handleSelectionChange(e)}
                        name="service_Provider_Roles"
                        id="non_Profit_Advisor"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          backgroundColor: "#CCC",
                          display: "inline-table",
                        }}
                      >
                        Non-Profit Advisor
                      </Typography>
                    }
                    style={{ display: "block" }}
                  />
                </div>
              </FormGroup>
            </div>
            <div id="GW_Onboarding_Focus">
              <div>
                {submitted &&
                  service_Provider.SP_Industry_Focus_Tags.length === 0 && (
                    <p style={{ color: "red", marginBottom: 5 }}>
                      You must select a focus type.
                    </p>
                  )}
                <p>
                  Industry Focus <span style={{ color: "red" }}>*</span>
                </p>
                <p>
                  Tell us a bit about your industry focus. Where do you do most
                  of your work?
                </p>
                <Registration_AutoSuggestion
                  categories={industry_Focus_List_Cat}
                  categoryName="SP_Industry_Focus_Tags"
                  currentSelectedCategories={
                    service_Provider.SP_Industry_Focus_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={this.props.handleCategorySelection}
                  handleCategoryInput={this.props.handleCategoryInput}
                />
              </div>
              <div>
                {submitted &&
                  service_Provider.SP_Industry_Services_Tags.length === 0 && (
                    <p style={{ color: "red", marginBottom: 5 }}>
                      You must select service(s) you provide.
                    </p>
                  )}
                <p>
                  Services Provided <span style={{ color: "red" }}>*</span>
                </p>
                <p>What services do you or your team provide?</p>
                <Registration_AutoSuggestion
                  categories={industry_Service_List_Cat}
                  categoryName="SP_Industry_Services_Tags"
                  currentSelectedCategories={
                    service_Provider.SP_Industry_Services_Tags
                  }
                  multiple={true}
                  addTags={true}
                  submitted={submitted}
                  errorInput={category_TagsError}
                  handleCategorySelection={this.props.handleCategorySelection}
                  handleCategoryInput={this.props.handleCategoryInput}
                />
              </div>
              <div id="SP_Onboarding_Additional_Info">
                <div>
                  <p>
                    Hourly Rate <span style={{ color: "red" }}>*</span>
                  </p>
                  <p>
                    What is your <strong>general</strong> hourly rate? Please
                    write $0 if you're offering pro-bono services. <br />
                    *You can also change this rate for each project or work
                    segment.
                  </p>
                  <input
                    // type="number"
                    type="number"
                    pattern="[0-9*]"
                    id="service-provider-standard-basic"
                    name="Base_Hourly_Rate"
                    value={service_Provider.Base_Hourly_Rate}
                    onChange={this.props.handleChange}
                    onKeyDown={(evt) =>
                      !this.numRegex(evt.key) && evt.preventDefault()
                    }
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    style={{
                      width: "100%",
                      marginBottom: 15,
                      paddingLeft: 5,
                      maxWidth: 500,
                      height: 40,
                      border: "1px solid lightgrey",
                      background: "#fff",
                      marginTop: 30,
                    }}
                  />
                </div>
                <div id="service-provider-onboarding-textarea">
                  <p>Testimonials/References</p>
                  <p>
                    Please copy/paste or link to past client/customer
                    testimonials and/or references. For grant writers,
                    contactable customer references are preferred.
                  </p>
                  <input
                    type="text"
                    id="service-provider-standard-basic"
                    name="References"
                    value={service_Provider.References}
                    onChange={this.props.handleChange}
                    multiline
                    rowsMax="20"
                    rows={20}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="onboarding-form-card">
            <h1>Contact & Terms</h1>
            <hr />
            <div>
              {submitted && service_Provider.Booking_Link === false && (
                <p style={{ color: "red", marginBottom: 5 }}>
                  You must specify a booking link (use the links below to create
                  an account if needed).
                </p>
              )}
              <p>
                Booking Link <span style={{ color: "red" }}>*</span>
              </p>
              <p>
                We require a booking link so users can easily schedule time with
                you, and so you can manage the time you make available to them.
                If you do not already have one, we recommend using either of
                these free services Calendly{" "}
                <a href="https://calendly.com" target="_blank" rel="noopener noreferrer">
                  (https://calendly.com/)
                </a>
                .
              </p>
              <p>
                Do not leave an email here, users will be unable to contact you.
              </p>
              <input
                type="text"
                id="service-provider-standard-basic"
                name="Booking_Link"
                value={service_Provider.Booking_Link}
                onChange={this.props.handleChange}
                inputProps={{ maxLength: 255 }}
              />
            </div>
            <div>
              <p>Open To Office Hours</p>
              <p>
                We host office hours each week Friday at 10am PST. These are
                hour-long 1-1 meetings with founders on a variety of topics. If
                you are interested, these are a great way to network and do very
                targeted business development. Selecting yes here will put you
                on a list for outreach as we plan office hours connections
                during the week.
              </p>
              <FormGroup>
                <div
                  style={{ paddingLeft: 15 }}
                  className="SP_Checkboxes"
                  id="OH_Checkbox"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.Office_Hours}
                        onChange={this.props.handleSelectionChange}
                        name="Office_Hours"
                        id="Office_Hours"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          marginTop: "-10px",
                          backgroundColor: "#CFDFFF",
                          display: "inline-table",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                </div>
              </FormGroup>
            </div>
            <div>
              {submitted &&
                service_Provider.Opengrants_Terms_Conditions == false && (
                  <p style={{ color: "red", marginBottom: 5 }}>
                    You must accept the terms and conditions to register.
                  </p>
                )}
              <p>
                Agree to OpenGrants Terms & Conditions{" "}
                <span style={{ color: "red" }}>*</span>
              </p>
              <p>
                Indicating yes tells us that you have read and accepted our{" "}
                <a
                  href="http://www.opengrants.io/opengrants-legal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions as published here.
                </a>
              </p>
              <FormGroup>
                <div
                  style={{ paddingLeft: 15 }}
                  className="SP_Checkboxes"
                  id="ToC_Checkbox"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={service_Provider.Opengrants_Terms_Conditions}
                        onChange={this.props.handleSelectionChange}
                        name="Opengrants_Terms_Conditions"
                        id="Opengrants_Terms_Conditions"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          marginTop: "-10px",
                          backgroundColor: "#CFDFFF",
                          display: "inline-table",
                        }}
                      >
                        Yes
                      </Typography>
                    }
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </form>
        <Button onClick={this.props.handleSubmit}>SUBMIT</Button>
        {form_errors && (
          <p style={{ marginTop: 30, color: "red" }}>
            There are errors in your form. Please correct all errors shown in
            red above, then click Submit again.
          </p>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_Form =
  connect(mapStateToProps)(GC_Onboarding_Form);
export { connectedGC_Onboarding_Form as GC_Onboarding_Form };

