import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationSB extends React.Component {
   
    render() {
        const { org } = this.props;

        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel">Are You a Small Business?</h4>
                <p className="inputFieldSubLabel">
                    Not sure? Read the SBA’s standards for small business
                    <a href="https://www.sba.gov/federal-contracting/contracting-guide/size-standards" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        name="small_Business"
                        defaultValue={org.organization.small_Business === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "small_Business")}
                        className="orgPageTextField"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationSB = connect(mapStateToProps)(EditOrganizationSB);
export { connectedEditOrganizationSB as EditOrganizationSB };
