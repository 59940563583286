import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Login_v2.css";
import "../../../Styles/Login_v3.css";
import { authenticateActions } from "../../../_actions";
import lockIcon from "../../../_assets/img/icons/lock-icon.svg";
import userIcon from "../../../_assets/img/icons/user-icon.svg";
import abstract from "../../../_assets/img/login-abstract-round.svg";
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from "../../../_helpers";
import { asyncLocalStorage, pluginService, sharedService } from "../../../_services";
import { Loader } from '../../Shared/Loader';
import { GoogleLoginPage } from "./StyledGoogleButton";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(authenticateActions.logout());
    localStorage.removeItem("user");

    this.state = {
      username: "",
      password: "",
      show_password: false,
      show_password_img: "show_password.png",
      submitted: false,
      partnerLogin: false,
      partnerLogo: "",
      leftPartnerLogin: "",
      partnerLoginSubmitButton: "",
      partnerLoginRegisterButton: "",
      partnerRegisterLink: "",
      linked_Listing_Id: null,
      google_Login: false,
      google_Login_Option: true,
      loading: false,
      iFrameConfig: null,
      google_initialized: false
    };

    this.divRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleSubmit = this.handleGoogleSubmit.bind(this);
    this.handlePasswordView = this.handlePasswordView.bind(this);
  }

  async componentDidMount() {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
    });
    const path = window.location.pathname;

    if (
      path.toLowerCase() !== "/login" &&
      path !== "/login/null"
    ) {
      //redirect
      let pathSplit = path.split("/");
      const redirect = sharedService.getRedirect(pathSplit);
      if (redirect) {
        sessionStorage.setItem('redirect', redirect);
      }
    }

    document.body.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.loginAlert.message) {
      this.setState({ loading: false });
    }

    if (this.divRef.current && !this.state.google_initialized) {
      if (!window.google) {
        setTimeout(() => {
          if (window.google) {
            window.google.accounts.id.initialize({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              callback: window.handleGoogleLogin,
            });
            window.google.accounts.id.renderButton(this.divRef.current, {
              type: 'standard',
              size: 'large',
              theme: 'outline',
              text: 'signin_with',
              shape: 'rectangular',
              logo_alignment: 'left'
            });
          }
        }, 500);
      } else {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: window.handleGoogleLogin,
        });
        window.google.accounts.id.renderButton(this.divRef.current, {
          type: 'standard',
          size: 'large',
          theme: 'outline',
          text: 'signin_with',
          shape: 'rectangular',
          logo_alignment: 'left'
        });
      }

      this.setState({
        google_initialized: true
      })
    }
  }

  handleRegister = () => {
    if (history.location.state && history.location.state.from) {
      var url =
        history.location.state.from.pathname +
        history.location.state.from.search +
        this.state.iFrameConfig?.url;
      sessionStorage.setItem("redirect", url);
    } else {
      const path = window.location.pathname;
      let pathSplit = path.split("/");
      const redirect = sharedService.getRedirect(pathSplit);
      if (redirect) {
        sessionStorage.setItem("redirect", redirect);
      }
    }
    if (this.state.iFrameConfig?.public) {
      window.location.href = "/register" + this.state.iFrameConfig?.url;
    } else {
      window.location.href = "/register";
    }
  }

  handlePasswordView(e) {
    this.setState((prevState) => ({
      show_password: !prevState.show_password,
      show_password_img: this.state.show_password
        ? "show_password.png"
        : "hide_password.png",
    }));
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    const { dispatch } = this.props;

    this.setState({ submitted: true, loading: true })

    if (username && password) {
      dispatch(
        authenticateActions.login(
          username.trim(),
          password,
          null,
          this.state.iFrameConfig?.url || '',

        )
      );
    } else {
      this.setState({ loading: false });
    }
  }

  handleGoogleSubmit(google) {
    this.setState({ loading: true })

    if (google.error == "idpiframe_initialization_failed") {
      this.setState({
        google_Login_Option: false,
        loading: false
      });
    }

    if (google && google.tokenId) {
      this.props.dispatch(
        authenticateActions.login(
          null,
          null,
          google,
          this.state.iFrameConfig?.url || '',
        )
      );
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loginAlert, user
    } = this.props;
    const {
      username,
      password,
      submitted,
      show_password,
      google_Login,
      google_Login_Option,
      loading,
      iFrameConfig,
    } = this.state;

    if (user && user.partnerClient) {
      window.heap.identify(user.id);
      window.location.href = pluginService.redirect(user.partnerClient);
    }

    return (
      <div style={{ background: iFrameConfig?.public ? "lightgrey" : "#1a5a83", minHeight: "100vh" }}>
        {loading && <Loader />}
        <div className="login-page-container" style={{ backgroundColor: iFrameConfig?.public ? 'lightgray' : '#1a5a83' }}>

          {/* Background image */}
          {!iFrameConfig?.public &&
            <img src={abstract} alt="" className="login-background-image" />
          }

          {/* Login card */}
          <div className="login-card">

            {/* OG Logo */}
            <div className="login-logo-div">
              <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="" className="login-logo" style={{ height: iFrameConfig?.public ? '100px' : 'auto', width: iFrameConfig?.public ? 'auto' : '85%' }} />
            </div>

            {(loginAlert.message) && (
              <div
                className={`alert ${loginAlert.type}`}
                style={{ textAlign: "center" }}
              >
                Please enter a valid username and password.
              </div>
            )}

            <div id="googleLoginAlert"></div>

            <form name="form" onSubmit={this.handleSubmit}>

              {/* Email */}
              <div>
                <p className="card-text">Email Address</p>
                <input
                  required
                  label="USERNAME / EMAIL"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                  className="email-text-field font-input-default"
                />
                {submitted && !username && google_Login === false && (
                  <div className="login-error">Email address is required</div>
                )}
              </div>

              {/* Password */}
              <div >
                <p className="card-text">Password</p>
                <div className="password-input">
                  <input
                    required
                    label="PASSWORD"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    type={show_password === true ? "text" : "password"}
                    className="password-text-field font-input-default"
                  />
                  <img
                    className="login-password-input-eye"
                    id="showPassword_login"
                    src={"https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/" + this.state.show_password_img}
                    onClick={this.handlePasswordView}
                    alt="show password icon"
                  />
                </div>
                {submitted && !password && google_Login === false && (
                  <div className="login-error">Password is required</div>
                )}
              </div>

              {/* Forgot password */}
              <div>
                <a className="card-text" href={pluginService.redirect("/forgot_password")} style={{ color: iFrameConfig?.public ? iFrameConfig?.secondary : '#0565aa' }}>
                  Having trouble signing in?
                </a>
              </div>

              {/* Sign In */}
              <div>
                <br />
                <button
                  className="sign-in-button"
                  id="loginLogin"
                  onClick={this.handleSubmit}
                  style={{ backgroundColor: iFrameConfig?.public ? iFrameConfig?.primary : '#0c7069' }}
                >
                  <img
                    src={lockIcon}
                    alt=""
                    style={{ paddingRight: "10px" }}
                  />
                  Sign in
                </button>
              </div>

              {/* Sign Up */}
              <div>
                <button
                  className="sign-up-button"
                  id="loginRegister"
                  onClick={this.handleRegister}
                  style={{ borderColor: iFrameConfig?.public ? iFrameConfig?.primary : '#0c7069', color: iFrameConfig?.public ? iFrameConfig?.primary : '#0c7069' }}
                >
                  {!iFrameConfig?.public || !iFrameConfig && (
                    <img
                      src={userIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    />
                  )}
                  Sign up!
                </button>
              </div>

              {/* Sign Up with Google */}
              <div className="login-footer-text">Or sign in with</div>
              {/* <div>
                <GoogleLoginPage
                  handleSubmit={this.handleGoogleSubmit}
                  location={"login"}
                  disabled={google_Login_Option === true ? false : true}
                />
              </div> */}
              {/* <div id="g_id_onload"
                data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                data-callback="handleGoogleLogin"
                data-context="signin">
              </div>
              <div class="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
                style={{ textAlign: '-webkit-center', textAlign: '-moz-center' }}>
              </div> */}
              <div id='google_sign_in' ref={this.divRef} style={{ textAlign: '-webkit-center', textAlign: '-moz-center' }} />

              {/* Help */}
              {/* <div className="login-footer-text">
                  Need help? <a href="/">We can assist you</a>
                </div> */}

            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loginAlert, paywallAlert, purchaseProduct } = state;
  const { loading, user } = state.setUser;
  return {
    loginAlert,
    loading,
    paywallAlert,
    purchaseProduct,
    user,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };

