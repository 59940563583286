import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/SettingsStyles/Billings.css';
import { organizationstripeActions, stripeActions } from '../../../../_actions';
import { organizationStripeService, sharedService, stripeService } from '../../../../_services';
import { ACH_Debit } from './PaymentTypes/ACH_Debit';
import { Card } from './PaymentTypes/Card';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class PaymentSelection extends Component {
  constructor(props) {
    super(props);

    const paymentSelection = sharedService.getPaymentSelection(this.props.billingInfo)

    this.state = {
      paymentSelection,
      value: paymentSelection === "ach_direct_debit" ? 1 : 0,
    };
  }

  handlePaymentSelection = (event, newValue) => {

    let paymentSelection;

    //Tab selection
    if (this.props.regularStyling) {

      //update tab value
      this.setState({
        value: newValue,
      })

      //get payment selection name
      if (newValue === 0) {
        paymentSelection = "card"
      }
      if (newValue === 1) {
        paymentSelection = "ach_direct_debit"
      }
    }
    //Dropdown selection
    else {
      paymentSelection = event.target.value
    }

    //update payment selection
    this.props.dispatch(organizationstripeActions.requestUpdatePaymentMethod(paymentSelection));
    this.props.dispatch(stripeActions.requestUpdatePaymentMethod(true, paymentSelection));

    this.setState({
      paymentSelection
    })

    // //save payment selection value in checkout
    // if (this.props.handleSelection) {
    //   this.props.handleSelection(paymentSelection)
    // }
  };

  handlePaymentDetails = async (paymentMethodId) => {

    const user = this.props.user;

    //set user details
    const userDetails = {
      user_Id: user.id,
      email: user.email,
      name: user.name + " " + user.surname
    }

    const productDetails = {
      ...paymentMethodId
    }

    //purchase product 

    //update payment information with service call
    if (this.props.updatePaymentWithServiceCall) {

      await stripeService.updateUserDetails({ productDetails, userDetails })

      // //after updating payment method, update billing address if needed
      // if (this.props.handleUpdateAddress !== "") {
      //   this.props.handleUpdateAddress();
      // }

      //submit purchase

      // this.props.handleLoading(false)
      this.props.handleIsInfoUpdated(true);

    }
    //update payment information with action call
    else {

      // this.props.dispatch(stripeActions.updateUserDetails({ productDetails, userDetails }, this.props.additionalAction));
      let result = "";

      if (this.props.org_Id) {

        userDetails["org_Id"] = this.props.org_Id;

        result = await organizationStripeService.updateBillingInfo({ productDetails, userDetails });

        if (result.status !== "Error") {
          this.props.dispatch(organizationstripeActions.setOrgBillingInfo(result.billingInfo));

          this.props.showErrorMessage(false);

          if (this.props.additionalAction) {
            this.props.additionalAction();
          }
        }
        else {
          this.props.showErrorMessage(true);
        }
      }
      else {

        result = await stripeService.updateUserDetails({ productDetails, userDetails });

        if (result.status !== "Error") {
          this.props.dispatch(stripeActions.setBillingInfo(result.billingInfo));

          this.props.showErrorMessage(false);

          if (this.props.additionalAction) {
            this.props.additionalAction();
          }
        }
        else {
          this.props.showErrorMessage(true);
        }
      }
      if (this.props.handleLoading) {
        this.props.handleLoading();
      }

      return result.billingInfo;
    }
  }

  render() {
    const { paymentSelection, value } = this.state;

    return (
      <>
        {/* Regular styling */}

        {this.props.regularStyling &&
          <Box sx={{ width: "100%" }}>
            <div className='mb-32'>
              <AppBar position="static">
                <Tabs
                  value={value}
                  // defaultValue={paymentSelection}
                  onChange={this.handlePaymentSelection}
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab className="paymentSelectionTabLabel" label="Debit/Credit Card" {...a11yProps(0)} />
                  <Tab className="paymentSelectionTabLabel" label="Bank Transfer (US only)" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <hr style={{ margin: -2 }} />
            </div>

            <h3 className="checkoutSubHeading">Payment Information</h3>
            <TabPanel value={value} index={0} className="paymentSelectionTab">
              <Card
                handlePaymentDetails={this.handlePaymentDetails}
                billingInfo={this.props.billingInfo}
                type={this.props.type}
                org_Id={this.props.org_Id}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className="paymentSelectionTab">
              <ACH_Debit
                handlePaymentDetails={this.handlePaymentDetails}
                type={this.props.type}
                org_Id={this.props.org_Id}
              />
            </TabPanel>
          </Box >
        }

        {/* Billing page specific styling */}

        {
          !this.props.regularStyling &&
          <div className="paymentInformationSelection">
            <div className="row paymentRow">

              <div className="col-md-3 billingInfoLeftCol">
                <p>Payment Type</p>
              </div>

              <div className="col-md-5">
                <select className="billingSelectInput"
                  onChange={this.handlePaymentSelection}
                  defaultValue={paymentSelection}
                >
                  <option value="card">Debit/Credit Card</option>
                  <option value="ach_direct_debit">Bank Transfer (US only)</option>
                </select>
              </div>

            </div>
            <div className="row paymentRow">
              <div className="col-md-3 billingInfoLeftCol" style={{ marginTop: "15px" }}>
                {paymentSelection === "card" &&
                  <p>Card Information</p>
                }
                {paymentSelection === "ach_direct_debit" &&
                  <p>Bank Information</p>
                }
              </div>

              <div className="col-md-5">
                {(paymentSelection === "card") &&
                  <Card
                    billingInfo={this.props.billingInfo}
                    billingHandler={this.props.billingHandler}
                    handlePaymentDetails={this.handlePaymentDetails}
                    type={this.props.type}
                    org_Id={this.props.org_Id}
                  />
                }
                {paymentSelection === "ach_direct_debit" &&
                  <ACH_Debit
                    billingHandler={this.props.billingHandler}
                    handlePaymentDetails={this.handlePaymentDetails}
                    type={this.props.type}
                    org_Id={this.props.org_Id}
                  />
                }
              </div>
            </div>
          </div>
        }

      </>
    );
  }
}

function mapStateToProps(state) {
  const { setUser, billingHandler } = state;
  const { user } = setUser;

  return {
    user,
    billingHandler
  };
}

const connectedBilling = connect(mapStateToProps)(PaymentSelection);
export { connectedBilling as PaymentSelection };

