import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { Form, FormGroup, Modal, ModalBody } from "reactstrap";
import { faCopy, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Notes } from "./Components/Notes";
import { authenticateService, favoritesService, funderService, listingService, sharedService } from "../../_services";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { Loader_v2 } from "../Shared/Loader/Loader_v2";
import { useSelector } from "react-redux";
import { Loader } from "../Shared/Loader";
import { primaryButton, primaryButtonInverted } from "../../Styles/CustomButton";
import { feedbackService } from "../../_services/Users/feedback.service";

export const GrantDetails = ({
  iFrameConfig,
  loading,
  closeModal,

  listing,
  updateGrantMatches,
  favorites,
  updateFavorites,
}) => {
  const { user } = useSelector(state => state.setUser);
  const history = useHistory();
  const [isCopied, setIsCopied] = useState(false);
  const [isFavorite, setIsFavorite] = useState(
    favorites ? favorites.some(item => {
      if (listing.type === 'listings')
        return item.listing_Id == listing.listing_Id;
      if (listing.type === 'funders')
        return item.id == listing.id || item.funder_Id == listing.id;
      return false;
    }) : false);
  const [isFavDiscardOpen, setIsFavDiscardOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState({ status: "", loading: false });
  const [feedback, setFeedback] = useState('');
  const [showApplied, setShowApplied] = useState(false);

  const saveListing = async (checkIfSaved) => {
    setIsFavDiscardOpen(false);
    if (!checkIfSaved || !isFavorite) {
      if (listing.type === 'listings') {
        await favoritesService.saveListing(listing.listing_Id, user);
        setIsFavorite(!isFavorite);
      }
      if (listing.type === 'funders') {
        await favoritesService.saveFunder(listing.id, user);
        setIsFavorite(!isFavorite);
      }
    }

    if (updateFavorites) {
      if (!checkIfSaved && isFavorite) {
        const newFavorites = favorites.filter(x => {
          if (listing.type === 'listings') return x.listing_Id != listing.listing_Id;
          if (listing.type === 'funders') return x.id != listing.id;
          return false;
        });
        updateFavorites(newFavorites);
      } else if ((!checkIfSaved && !isFavorite) || (checkIfSaved && !isFavorite)) {
        updateFavorites([listing, ...favorites]);
      }
    }
  };

  const unsaveListing = () => {
    if (isFavorite) setIsFavDiscardOpen(true);
    else saveListing(false);
  }

  /* FEEDBACK */
  const handleFeedback = (e) => {
    e.preventDefault();

    const { value } = e.target;
    setFeedback(value)
  }

  const submitFeedback = async (e) => {
    e.preventDefault();

    //set loading
    setShowFeedbackModal({ status: "open", loading: true });

    let result;
    //send grant feedback
    if (listing.type === "listings") {
      result = await feedbackService.sendGrantFeedback(listing.listing_Id, feedback);
    }
    //send funder feedback 
    else {
      result = await feedbackService.sendFunderFeedback(listing.id, feedback);
    }

    //result == undefined/null if feedback sent successfully
    if (!result) {
      setShowFeedbackModal({ status: "success", loading: false });
      setFeedback('');
    } else {
      setShowFeedbackModal({ status: "error", loading: false });
    }
  }

  const dueDate = () => {
    if (listing.application_Due_Date) return format(parseISO(listing.application_Due_Date), "MMMM do, yyyy");
    if (listing.close_Date) return format(parseISO(listing.close_Date), "MMMM do, yyyy");

    return 'Applications ongoing';
  }

  const submitCommonAppApplication = async () => {
    await listingService.applyForListing(listing.listing_Id);
    setShowApplied(true);
  }

  const redirectToGrantAi = async () => {
    const url = `https://platform.grantai.co/auth/via/og`;
    const { token } = await authenticateService.generateTempToken();
    console.log('token:', token);

    let aTag = document.createElement('a');
    aTag.href = `${url}/${token}`;
    aTag.target = "_blank";
    aTag.click();
  }

  return (<>
    {loading && <Loader_v2 />}
    {closeModal &&
      (<Row className="justify-content-end">
        <button className="mb-48 bg-transparent col-auto" onClick={() => closeModal()}><p className="m-0">X</p></button>
      </Row>)
    }
    <Row className="mb-8 flex-column-reverse flex-lg-row">
      <Col>
        <h3 className="fs-1 fw-bold">{listing.title || listing.name}</h3>
        <p className="noMargin">{listing.agency}</p>
      </Col>
      {!iFrameConfig?.url &&
        <Col className="col-12 col-lg-auto">
          <Row className="text-center pb-24 pb-lgg-0">
            <Col className="col-6">
              <button className="bg-transparent" onClick={isFavorite ? unsaveListing : () => saveListing(false)}>
                <Row className="text-center">
                  <Col className="col-12 mb-8 text-secondary-dark">
                    {isFavorite ?
                      <FontAwesomeIcon className="text-danger" icon={faHeartSolid} size="2x" />
                      :
                      <FontAwesomeIcon icon={faHeart} size="2x" />}
                  </Col>
                  <Col>
                    <label>Save</label>
                  </Col>
                </Row>
              </button>
            </Col>
            <Col className="col-6 text-secondary-dark">
              <button className="bg-transparent" onClick={() => { setIsCopied(true); setTimeout(() => { setIsCopied(false) }, 3000) }}>
                <Row>
                  <Col className="col-12 mb-8">
                    <CopyToClipboard text={sharedService.generate_copy_link(listing.type === 'listings' ? 'grant' : 'funder', listing)}>
                      <FontAwesomeIcon icon={faCopy} size="2x" />
                    </CopyToClipboard>
                  </Col>
                  <Col>
                    <label>{isCopied ? "Link Copied!" : "Copy Link"}</label>
                  </Col>
                </Row>
              </button>
            </Col>
          </Row>
        </Col>
      }
    </Row>
    <hr />
    <Row className="py-8">
      {!listing.is_Applicable_Via_Common_App && <div className="mb-24 d-flex">
        <button
          className="col bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold"
          onClick={() => redirectToGrantAi()}
        >
          Draft an Application with GrantAI
        </button>
      </div>}
      <Row className="mb-24">
        {!listing.is_Applicable_Via_Common_App ?
          <Col className="col-auto">
            <button
              className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
              onClick={() => history.push('/marketplace')}
            >
              Hire a Grant Writer
            </button>
          </Col> : <Col className="col-auto">
            {
              (listing.common_App_Submissions?.length || showApplied) ?
                <button
                  disabled={true}
                  className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
                >
                  Applied!
                </button> :
                <button
                  className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
                  onClick={() => { submitCommonAppApplication(); }}
                >
                  Apply Via the Common App
                </button>
            }
          </Col>
        }
        {listing.type === 'listings' &&
          <Col className="col-auto align-self-center">
            {listing.listing_URL ?
              <button
                disabled={listing.listing_URL ? false : true}
                className={"bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold" + ((listing.listing_URL) ? "" : " button-disabled")}
                onClick={() => { if (listing.listing_URL) { const website = (!listing.listing_URL.includes('http') ? 'https://' : '') + listing.listing_URL; window.open(website, "_blank"); } }}
              >
                View Original
              </button>
              :
              <p className="m-0">We couldn't find a website for this grant</p>
            }
          </Col>
        }
        {listing.type === 'funders' &&
          <Col className="col-auto align-self-center">
            {listing.website ? <button
              disabled={listing.website ? false : true}
              className={"bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold" + ((listing.website) ? "" : " button-disabled")}
              onClick={() => { if (listing.website) { const website = (!listing.website.includes('http') ? 'https://' : '') + listing.website; window.open(website, "_blank"); } }}
            >
              View Website
            </button>
              :
              <p className="m-0">We couldn't find a website for this funder</p>
            }
          </Col>
        }
      </Row>
      {listing.type === 'listings' && <div>
        <p>{listing.funding_Amount ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }).format(listing.funding_Amount) : 'Funding varies.'}</p>
        <p><strong>Due Date:</strong> {dueDate()}</p>
        <p><strong>Match Required:</strong> {listing.match_Required ? 'Yes' : 'No'} </p>
      </div>}
    </Row>
    <hr />
    {!iFrameConfig?.url && <><Row className="py-8">
      <h4 className="fw-bold fs-2 text-gray-200 mb-24">Notes</h4>
      <Notes user={user} listing={listing} saveListing={saveListing} updateGrantMatches={updateGrantMatches} />
    </Row>
      <hr /></>}
    <Row className="py-8">
      <h4 className="fw-bold fs-2 text-gray-200 mb-24">Overview</h4>
      <p className="fw-normal">{listing.description}</p>
    </Row>
    <hr />

    {listing.type === 'listings' && <>
      <Row className="py-8">
        <p><strong>Contact Email:</strong> {listing.grantor_Contact_Email ? listing.grantor_Contact_Email : "N/A"}</p>
        <p><strong>Contact Details:</strong> {listing.contact_Details ? listing.contact_Details : "N/A"}</p>
      </Row>
      <hr />
    </>}
    <Row className="py-8 align-items-center">
      <p className="col-12 pe-24">See something that's wrong or missing? Let us know!</p>
      <button
        className={primaryButton + " col-auto"}
        onClick={() => setShowFeedbackModal({ status: "open", loading: false })}
      >
        Submit Feedback
      </button>
    </Row>

    {/* Favorite Discard Confirmation Modal */}
    {isFavDiscardOpen &&
      <Modal
        isOpen={isFavDiscardOpen}
        centered={true}
        backdrop={true}
        toggle={() => setIsFavDiscardOpen(!isFavDiscardOpen)}
        fade={false}
        className="ModalDiv"
        size="xl">
        <ModalBody className="p-64">
          <h5 className='fs-2 pb-48 text-center'>Are you sure you want to discard this listing?</h5>
          <Row className="mb-8 justify-content-center">
            <Col className="col-auto">
              <button
                className="bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold"
                onClick={() => setIsFavDiscardOpen(false)}
              >
                Cancel
              </button>
            </Col>
            <Col className="col-auto">
              <button
                className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
                onClick={() => saveListing(false)}
              >
                Discard
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal >
    }

    <Modal
      isOpen={showFeedbackModal.status}
      centered={true}
      backdrop={true}
      toggle={() => setShowFeedbackModal({ status: showFeedbackModal.status ? "" : "open", loading: false })}
      fade={false}
      size="xl">
      <ModalBody className="p-64">
        {showFeedbackModal.loading && <Loader />}
        {showFeedbackModal.status !== "success" &&
          <Form onSubmit={submitFeedback}>
            <FormGroup>
              <h5 className='fs-2 pb-48 text-center'>What's wrong? Let us know what's missing, outdated or wrong about this listing.</h5>
              <FormControl
                className="fs-4 mb-24"
                onChange={handleFeedback}
                as="textarea"
                rows={10}
                value={feedback}
              />
              <Row className="mb-8 justify-content-center">
                <Col className="col-auto">
                  <button
                    type="button"
                    className={primaryButtonInverted}
                    onClick={() => setShowFeedbackModal({ status: "", loading: false })}
                  >
                    Cancel
                  </button>
                </Col>
                <Col className="col-auto">
                  <button
                    type="submit"
                    className={primaryButton}
                    onClick={submitFeedback}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
              {showFeedbackModal.status === "error" &&
                <Row className="mb-8 text-center mt-8">
                  <p className="text-danger">We're sorry, we were unable to send the feedback. Please try again.</p>
                </Row>
              }
            </FormGroup >
          </Form>}
        {showFeedbackModal.status === "success" &&
          <div>
            <h5 className='fs-2 pb-48 text-center'>Thanks for the feedback!</h5>
            <Row className="mb-8 justify-content-center">
              <Col className="col-auto">
                <button
                  type="button"
                  className={primaryButton}
                  onClick={() => setShowFeedbackModal({ status: "", loading: false })}
                >
                  Close
                </button>
              </Col>
            </Row>
          </div>}
      </ModalBody>
    </Modal >
  </>
  )
}