import { InputAdornment } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@mui/material/Input';
import React from 'react';
import { connect } from 'react-redux';
import TextTruncate from "react-text-truncate";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../../Styles/SettingsStyles/OrganizationViewEdit.css';
import { serviceProviderActions } from "../../../_actions";
import briefcaseIcon from '../../../_assets/img/icons/briefcase.png';
import { pluginService, serviceProviderService, sharedService, stripeService } from "../../../_services";
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import { SP_Onboarding_Success } from '../../ServiceProviders/Onboarding/SP_Onboarding_Success';
import { Loader } from '../../Shared/Loader';
import { AcceptedProfileList_v2 } from './AcceptedProfileList_v2.jsx';
import { EditAutoSuggestion } from "./AdditionalAttributes/EditAutoSuggestion";
import { EditRoles } from "./AdditionalAttributes/EditRoles";
import { EditOrganization8A } from './EditOrganization8A';
import { EditOrganizationHubzone } from './EditOrganizationHubzone';
import { EditOrganizationImpact } from "./EditOrganizationImpact";
import { EditOrganizationIndustries } from "./EditOrganizationIndustries";
import { EditOrganizationNAICS } from './EditOrganizationNAICS';
import { EditOrganizationSAM } from "./EditOrganizationSAM";
import { EditOrganizationSB } from './EditOrganizationSB';
import { EditOrganizationTechnologies } from './EditOrganizationTechnologies';
import { EditOrganizationVOB } from './EditOrganizationVOB';
import { EditOrganizationWOB } from './EditOrganizationWOB';
import { PendingInvitationList } from './PendingInvitationList';
import { PendingProfileList } from './PendingProfileList';
import { MainOrganizationForm } from './PopupForms/MainOrganizationForm';
import { MembersForm } from "./PopupForms/MembersForm";

let accentColor = pluginService.getIFrameColor()

let hoverStyle = {
    hover: {
        background: accentColor || "#0C7069"
    }
}

class OrganizationViewEdit_v2 extends React.Component {
    constructor(props) {
        super(props);

        let organization_id = window.location.pathname.split('/').pop();

        this.state = {
            user: this.props.user,

            organization_id: organization_id !== "organizations" && organization_id !== "settings" ?
                organization_id :
                this.props.organization_id ? this.props.organization_id : "",

            currentSection: "",
            loading: false,
            openForm: false,

            isEditing: "",
            showMoreTestimonials: false,
            showMoreBio: false,
            showMoreGrantMatchDescription: false,
            showMoreProductDescription: "",
            showProductForm: false,
            showDeleteProductModal: false,
            showSpOnboardingModal: false,
            newProduct: {},
            selectedProduct: {},
            productToDelete: "",
            productSubmissionError: false,
            productRemovalError: false,
            hover: false,
        }
    }

    componentDidMount = () => {
        let section = window.location.href.split("#")[1]

        if (section) {
            let element = document.getElementById("products");
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    //Switch sp to available to work or not available to work
    handleAvailabilitySwitch = async (e) => {
        const current_user = this.props.user;

        let check = e.target.checked

        await serviceProviderService.updateSPAvailability(current_user.id, check)

        this.props.dispatch(serviceProviderActions.getServiceProvidersByUser(current_user.id));
    }

    //Add HTTPS to URLs
    withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

    //Add confirmation notification
    cancel = async (sectionName) => {
        await this.props.resetOrgState();
        this.setState({
            isEditing: sectionName ? sectionName : "",
            openForm: false,
        })
    }

    handleEditing = (sectionName) => {
        if (this.state.isEditing === "") {
            this.setState({
                isEditing: sectionName,
            })
        }
        else {
            this.cancel(sectionName)
        }
    }

    //Handle submit for this page
    handleSubmit = async (e) => {

        const { isEditing } = this.state

        this.setState({
            loading: true
        })

        if (isEditing === "about" || isEditing === "location" || isEditing === "advanced" || isEditing === "users" || isEditing === "logo") {
            await this.props.handleOrgSubmit(e);
        }
        else if (isEditing === "professional") {
            await this.props.handleAdditionalSubmit(e);
        }

        // //SetTimeout used for success or failure message in popup form
        if (this.props.submitSuccess) {
            this.setState({
                isEditing: "",
                openForm: false
            })
            this.props.changeSuccess();
        }//if error with db send error message
        else if (this.props.updatedErrorAlert === true) {
            await this.props.resetOrgState();

        }

        this.setState({
            loading: false,
        })
    }

    // FIXED RATE PRODUCTS

    //handle product value change
    handleNewProduct = (e) => {
        const { name, value } = e.target

        this.setState({
            newProduct: {
                ...this.state.newProduct,
                [name]: name === "cost" ? parseInt(value) : value
            }
        })
    }

    //submit a new product or update existing one
    submitProduct = async (product) => {
        const { newProduct } = this.state
        const { service_Provider } = this.props

        this.setState({
            productSubmitted: true,
            loading: true,
        })

        let isValid = false;

        //check if all fields are filled
        if (newProduct.scope && newProduct.description && newProduct.cost) {
            isValid = true;
        }

        if (isValid) {

            let result;
            newProduct.service_Provider_Id = service_Provider.service_Provider_Id

            //submit updated product
            if (product) {
                try {
                    result = await serviceProviderService.updateProduct(newProduct);

                    this.props.dispatch(serviceProviderActions.getServiceProviderById(service_Provider.service_Provider_Id))
                    this.resetProduct();
                }
                catch {
                    this.setState({
                        productSubmissionError: true,
                    })
                }
            }
            //submit new product
            else {
                try {
                    result = await serviceProviderService.createProduct(newProduct);

                    this.props.dispatch(serviceProviderActions.getServiceProviderById(service_Provider.service_Provider_Id))
                    this.resetProduct();
                }
                //show submission error
                catch {
                    this.setState({
                        productSubmissionError: true,
                    })
                }
            }

            this.setState({
                loading: false
            })
        }
        //show empty field errors
        else {
            this.setState({
                loading: false
            })
        }
    }

    //handle product editing
    editProduct = (product) => {
        this.setState({
            selectedProduct: product.product_Id,
            newProduct: product,
            showProductForm: false,
            productSubmissionError: false,
            productSubmitted: false,
        })
    }

    //handle adding product
    addProduct = async () => {
        const { service_Provider } = this.props

        let stripe_Verified = await stripeService.checkStripeConnectVerification(service_Provider.stripe_Account_Id);

        if (sharedService.isSPVerified(service_Provider, stripe_Verified) && sharedService.hasPayoutMethod(stripe_Verified)) {
            this.setState({
                showProductForm: true,
                selectedProduct: "",
                newProduct: {},
                productSubmissionError: false,
                productSubmitted: false,
            })
        }
        else {
            this.setState({
                showSpOnboardingModal: true
            })
        }

    }

    //reset adding/editing product
    resetProduct = () => {
        this.setState({
            showProductForm: false,
            selectedProduct: "",
            newProduct: {},
            productSubmissionError: false,
            productSubmitted: false,
            productToDelete: "",
            productRemovalError: false,
            productToDelete: "",
        })
    }

    //remove product
    removeProduct = async () => {
        const { productToDelete } = this.state;
        const { service_Provider } = this.props;

        try {
            await serviceProviderService.deleteProduct([productToDelete])

            this.setState({
                showDeleteProductModal: false,
            })

            this.props.dispatch(serviceProviderActions.getServiceProviderById(service_Provider.service_Provider_Id))
            this.resetProduct();
        }
        catch {
            this.setState({
                productRemovalError: true,
            })
        }
    }


    //display a product form, for new product - empty, for existing product - filled
    displayProductForm = (product) => {
        const { newProduct, productSubmitted, productSubmissionError } = this.state

        return (
            <div className='product-form'>

                <p className='product-form-label'>Scope <span className='red'>*</span></p>
                <Input
                    value={newProduct.scope}
                    onChange={this.handleNewProduct}
                    name="scope"
                    type="text"
                    disableUnderline={true}
                    className="apply-modal-input"
                />
                {productSubmitted && !newProduct.scope &&
                    <p className='defaultErrorMessage'>The scope is required</p>
                }

                <p className='product-form-label'>Description <span className='red'>*</span></p>
                <textarea
                    value={newProduct.description}
                    onChange={this.handleNewProduct}
                    name="description"
                    type="text"
                    disableUnderline={true}
                    className="apply-modal-input description-field"
                    rows={3}
                />
                {productSubmitted && !newProduct.description &&
                    <p className='defaultErrorMessage'>The description is required</p>
                }

                <p className='product-form-label'>Cost <span className='red'>*</span></p>
                <Input
                    startAdornment={<InputAdornment position="start" className="inputAdornment" style={{ marginLeft: 10, color: "black !important" }}>$</InputAdornment>}
                    endAdornment={<InputAdornment position="end" className="inputAdornment" style={{ marginRight: 10 }}>(USD)</InputAdornment>}
                    value={newProduct.cost}
                    onChange={this.handleNewProduct}
                    name="cost"
                    type="number"
                    disableUnderline={true}
                    InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    className="apply-modal-input"
                />
                {productSubmitted && !newProduct.cost &&
                    <p className='defaultErrorMessage'>The cost is required</p>
                }

                <div className='product-form-buttons'>
                    {product ?
                        <button className="defaultGreenButton submit" onClick={() => { this.submitProduct(product) }}>Save</button>
                        :
                        <button className="defaultGreenButton submit" onClick={() => { this.submitProduct() }}>Submit</button>
                    }
                    <button className="defaultWhiteButton cancel" onClick={() => { this.resetProduct() }}>Cancel</button>
                </div>

                {productSubmitted && productSubmissionError &&
                    <p className='defaultErrorMessage'>
                        We're sorry, there was an issue processing your submission. Please double check entered information and try again.
                        If you still have trouble, open the chat in the lower right of this screen to talk with our support team.
                    </p>
                }
            </div>
        )
    }

    render() {

        const {
            user,
            openForm,
            isEditing,
            showMoreTestimonials,
            showMoreBio,
            showMoreGrantMatchDescription,
            showProductForm,
            showDeleteProductModal,
            showSpOnboardingModal,
            showMoreProductDescription
        } = this.state;

        const {
            service_Provider,
            org,
            organization_namesInput,
            accepted_profiles,
            pending_profiles,
            transfer,
            pending_invitation_profiles,
            cancelOrganizationInvitation,
            updateOrganizationName,
            handleChange,
            organizationNameError,
            originalOrg,
            submitted,
            country,
            state,
            organizationStateError,
            handleSelectionChange,
            editOrganization,
            checkboxes,
            handleCheckbox,
            handleDefaultCheckbox,
            isEmptyCheckbox,
            editErrors,
            handleEditCategorySelection,
            handleEditCategoryInput,
            category_TagsError,
            industry_Focus_List,
            industry_Service_List,
            stringFocusTypes,
            stringServiceTypes,
            stringFoundationTypes,
            stringAgencyTypes,
            foundation_Expertise_List,
            agency_Expertise_List,
            handleEditChange,
            editTextInput,
            address_is_required,
        } = this.props

        let industry_Focus_List_Cat = { "SP_Industry_Focus_Tags": industry_Focus_List };
        let industry_Service_List_Cat = { "SP_Industry_Services_Tags": industry_Service_List };
        let foundation_Expertise_List_Cat = { "GW_Foundation_Tags": foundation_Expertise_List };
        let agency_Expertise_List_Cat = { "GW_Agency_Tags": agency_Expertise_List };

        let naicS_CodesTags = null;
        let industryTags = null;
        let socialImpactTags = null;
        let mainTechnologyTags = null;
        let subTechnologyTags = null;
        let designationTags = [];
        let rolesTags = null;
        let industryFocusTags = null;
        let servicesTags = null;
        let foundationTags = null;
        let agencyTags = null;

        let accepted_profile_list = null;
        let edit_accepted_profile_list = null;
        let editingAllowed = false;

        let individualOrg = org.organization && org.organization.org_Type && org.organization.org_Type.toLowerCase().includes("individual")

        if (org.naicS_Codes) {
            naicS_CodesTags = org.naicS_Codes.map(naicS_Code => {
                return <span className="tag">{naicS_Code}</span>
            });
        }

        if (org.industries) {
            industryTags = org.industries.map(industry => {
                return <span className="tag">{industry}</span>
            });
        }

        if (org.social_Impact_Categories) {
            socialImpactTags = org.social_Impact_Categories.map(socialImpact => {
                return <span className="tag">{socialImpact}</span>
            });
        }

        if (org.technologies_Used) {
            mainTechnologyTags = org.technologies_Used.map(technology => {
                return <span className="tag">{technology}</span>
            });
        }

        if (service_Provider.sP_Roles) {
            rolesTags = service_Provider.sP_Roles.map(role => {
                return <span className="tag">{(role.role_Type.charAt(0).toUpperCase() + role.role_Type.slice(1)).replaceAll("_", " ")}</span>
            })
        }

        if (service_Provider.sP_Focus) {
            industryFocusTags = service_Provider.sP_Focus.map(focus => {
                return <span className="tag">{focus.focus_Type}</span>
            })
        }

        if (service_Provider.sP_Services) {
            servicesTags = service_Provider.sP_Services.map(service => {
                return <span className="tag">{service.service_Type}</span>
            })
        }

        if (service_Provider.foundation_Types) {
            foundationTags = service_Provider.foundation_Types.map(foundation => {
                return <span className="tag">{foundation.foundation_Type}</span>
            })
        }

        if (service_Provider.agency_Types) {
            agencyTags = service_Provider.agency_Types.map(agency => {
                return <span className="tag">{agency.agency_Type}</span>
            })
        }

        // if (org.organization.saM_Number) {
        //     designationTags.push(<span className='tag'>Registered in SAM</span>)
        // }

        if (org.organization.businiess_8a_Development) {
            designationTags.push(<span className='tag'>8A Business Development</span>)
        }

        if (org.organization.woman_Owned) {
            designationTags.push(<span className='tag'>Women Owned Business</span>)
        }

        if (org.organization.veteran_Owned) {
            designationTags.push(<span className='tag'>Certified Veteran Owned Business</span>)
        }

        if (org.organization.hubZone_Owned) {
            designationTags.push(<span className='tag'>Located in Hubzone</span>)
        }

        if (org.organization.small_Business) {
            designationTags.push(<span className='tag'>Small Business</span>)
        }

        //For general member display and to display members of other organizations
        if (org && org.organizationMembers) {

            accepted_profile_list = org.organizationMembers.map((profile) => {
                return (
                    <AcceptedProfileList_v2
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        updateOrganizationOwner={this.props.updateOrganizationOwner}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //For member display in editing form
        if (accepted_profiles) {
            edit_accepted_profile_list = accepted_profiles.map((profile) => {
                return (
                    <AcceptedProfileList_v2
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        updateOrganizationOwner={this.props.updateOrganizationOwner}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //for pending profiles requesting to join
        var pending_profile_list = null;
        if (pending_profiles) {
            pending_profile_list = pending_profiles.map((profile) => {
                return (
                    <PendingProfileList
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        user={user}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        //for pending invitations
        var pending_invitation_list = null;
        if (pending_invitation_profiles) {
            pending_invitation_list = pending_invitation_profiles.map((profile) => {
                return (
                    <PendingInvitationList
                        user={user}
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        cancelOrganizationInvitation={cancelOrganizationInvitation}
                    />
                )
            });
        }

        //   Check is current user is allowed to edit organization page
        if (org && org.organizationMembers && user) {
            org.organizationMembers.forEach(user => {
                if (user.username === this.state.user.userName) {
                    editingAllowed = true;
                }
            })
        }

        return (
            <div className='orgPage'>

                {editingAllowed &&
                    <div className="row" style={{ padding: '20px 0px' }}>
                        <a
                            className="projectBackLink"
                            href={pluginService.redirect("/profiles/" + this.props.user.userName)}
                        >
                            <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} />Back to Personal Profile
                        </a>
                    </div>
                }

                <p className="orgPageTitle">Organization Profile</p>

                <br />

                {/* ------------------------- LOGO & NAME ------------------------- */}

                <div className='orgPageLogoAndName'>
                    <div className="col-md-2 flexColEnd">
                        {editingAllowed ?
                            <div className="profileCircle" onClick={() => this.setState({ openForm: true, isEditing: "logo" })}
                                onMouseEnter={() => {
                                    this.setState({ hover: true })
                                }}
                                onMouseLeave={() => {
                                    this.setState({ hover: false })
                                }}
                                style={{
                                    ...(this.state.hover ? hoverStyle.hover : null)
                                }}>
                                {org.organization.logo_URL ?
                                    <img src={org.organization.logo_URL} alt="organization logo" className="profileCircle hoverImage" style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }} />
                                    :
                                    <img src={briefcaseIcon} alt="organization icon" className="profileCircle hoverImage" style={{ border: accentColor ? `2px solid ${accentColor}` : '2px solid #0C7069' }} />
                                }
                                <p className="editImageText">Edit Image</p>
                            </div>
                            :
                            <div className="profileCircle otherUser" style={{ border: "none" }}>
                                {org.organization.logo_URL ?
                                    <img src={org.organization.logo_URL} alt="organization logo" className="profileCircle" />
                                    :
                                    <img src={briefcaseIcon} alt="organization icon" className="profileCircle profileIcon" />
                                }
                            </div>
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1, marginLeft: 10 }}>

                        <div className="col-md-6 mobileOrgNameDiv" style={{ paddingLeft: 0 }}>
                            <p className="profileHeaderName"><span>{originalOrg?.organization?.name}</span></p>
                        </div>

                        {/* Available to work toggle */}

                        {editingAllowed && this.props.service_Provider &&
                            <div className="centerFlex" id="togglePublic" style={{ marginBottom: 0 }}>
                                <FormControlLabel className="margin-vertical toggle-label"
                                    control={
                                        <Switch
                                            checked={this.props.service_Provider.currently_Available}
                                            onChange={(e) => this.handleAvailabilitySwitch(e)}
                                            color=""
                                            style={this.props.service_Provider.currently_Available ? { color: "limegreen" } : { color: "grey" }}
                                        />
                                    }
                                    label={this.props.service_Provider.currently_Available ? <span className="green boxSubtitle noMargin noBold">Available to Work</span> : <span className="red boxSubtitle noMargin noBold">Not Available to Work</span>}
                                    labelPlacement="start"
                                />
                            </div>
                        }
                    </div>
                </div>

                {/* ------------------------- ABOUT ------------------------- */}

                <div>

                    {/* Header */}
                    <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                        <div className="col-md-3 orgPageMobileHeaderCol3Left" style={{ padding: 0 }}>
                            <p className="acceptingProjectHeader mobileFloatHeader">About</p>
                        </div>
                        <div className="col-md-6 orgPageMobileHeaderCol6" />

                        {editingAllowed && (isEditing !== "about") &&
                            <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                <a className="backToProjects" onClick={() => { this.handleEditing("about") }}>
                                    <i className="icon-pencil-icon" />Edit
                                </a>
                            </div>
                        }
                    </div>

                    {/* Company Name */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Company Name {isEditing === "about" && <span className="red">*</span>}</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "about" ?
                                <input className="orgPageTextField" id="orgName" name="organization_namesInput" defaultValue={originalOrg?.organization?.name} required onChange={updateOrganizationName} maxlength="450" />
                                :
                                <p className="orgPageItemText">{originalOrg && originalOrg.organization && originalOrg.organization.name ? originalOrg.organization.name : "N/A"}</p>
                            }
                            {organizationNameError && <p className="defaultErrorMessage">This organization name already exists.</p>}
                            {submitted && !organization_namesInput && <p className="defaultErrorMessage">Invalid organization name</p>}
                        </div>
                    </div>

                    {/* Website */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Website {(isEditing === "about" && service_Provider && !individualOrg) && <span className="red">*</span>}</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "about" ?
                                <input className="orgPageTextField" id="orgWebsite" name="website_URL" defaultValue={org.organization.website_URL} required onChange={handleChange} />
                                :
                                <p className="orgPageItemText">
                                    {org.organization.website_URL ? <a href={this.withHttp(org.organization.website_URL)} target="_blank" rel="noopener noreferrer">{org.organization.website_URL}</a> : "N/A"}
                                </p>
                            }
                            {service_Provider && !individualOrg && submitted && !org.organization.website_URL &&
                                <p className="defaultErrorMessage">You must specify your organization's website.</p>
                            }
                        </div>
                    </div>

                    {/* Overview */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Overview {(isEditing === "about" && service_Provider) && <span className="red">*</span>}</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "about" ?
                                <textarea className="orgPageTextArea" id="orgOverview" name="description" defaultValue={org.organization.description} onChange={handleChange} />
                                :
                                <>
                                    {showMoreGrantMatchDescription &&
                                        <>
                                            <p className='orgPageItemText' style={{ marginBottom: 20 }}>{org.organization.description}</p>
                                            <a className="showLessTextButton" onClick={() => { this.setState({ showMoreGrantMatchDescription: false }) }}> Show Less </a>
                                        </>
                                    }
                                    {!showMoreGrantMatchDescription &&
                                        <TextTruncate
                                            line={4}
                                            element="p"
                                            truncateText="…"
                                            text={org.organization.description ? org.organization.description : "N/A"}
                                            textTruncateChild={<a onClick={() => { this.setState({ showMoreGrantMatchDescription: true }) }}> Show More </a>}
                                            className='truncatedText orgPageItemText'
                                        />
                                    }
                                </>
                            }
                            {service_Provider && submitted && !org.organization.description &&
                                <p className="defaultErrorMessage">You must specify your organization's description.</p>
                            }
                        </div>
                    </div>
                    <div className="row paymentRow">
                        {isEditing === "about" && <>
                            <div className="col-md-3 flexColEnd">
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <button className='defaultGreenButton' onClick={(e) => this.handleSubmit(e)} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                        Update
                                    </button>
                                    <button className="defaultWhiteButton" onClick={() => this.cancel()} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>

                {/* Show only for non-individual organizations or for individual org editing */}

                {(!individualOrg || (individualOrg && editingAllowed)) &&
                    <div>
                        {/* ------------------------- LOCATION ------------------------- */}

                        {/* Header */}
                        <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                            <div className="col-md-3 orgPageMobileHeaderCol3Left" style={{ padding: 0 }}>
                                <p className="acceptingProjectHeader mobileFloatHeader">Location</p>
                            </div>
                            <div className="col-md-6 orgPageMobileHeaderCol6" />

                            {editingAllowed && (isEditing !== "location") &&
                                <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                    <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => { this.handleEditing("location") }}>
                                        <i className="icon-pencil-icon" />Edit
                                    </a>
                                </div>
                            }
                        </div>

                        {/* Primary */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Primary {(isEditing === "location" && address_is_required) && <span className="red">*</span>}</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                                {isEditing === "location" ?
                                    <>
                                        {/* Country */}

                                        <Registration_AutoSuggestion
                                            className="orgPageTextField"
                                            categories={{ country: country ? country : [] }}
                                            categoryName={"country"}
                                            currentSelectedCategories={[org.organization.country ? org.organization.country : "",]}
                                            handleCategorySelection={this.props.handleLocationSelection}
                                            handleCategoryInput={this.props.handleLocationInput}
                                            inputProps={{ maxLength: 50 }}
                                            placeholderText="Country"
                                        />

                                        {address_is_required && submitted && !org.organization.country && (
                                            <p className="defaultErrorMessage">
                                                You must enter your organization's country.
                                            </p>
                                        )}
                                        {submitted && org.organization.country && !(country.includes(org.organization.country)) &&
                                            <p className="defaultErrorMessage">
                                                Please select a country from the list.
                                            </p>
                                        }

                                        <div>

                                            {/* Address */}

                                            <input
                                                className="orgPageTextField"
                                                id="orgAddress"
                                                name="address"
                                                placeholder='Address'
                                                defaultValue={org.organization.address}
                                                onChange={handleChange}
                                                required
                                            />

                                            {org.organization.address && (org.organization.address.toLowerCase().includes("p.o. box") || org.organization.address.toLowerCase().includes("po box")) &&
                                                <p className="defaultErrorMessage">You cannot use a P.O. Box Address.</p>
                                            }

                                            {/* City */}

                                            <input
                                                className="orgPageTextField"
                                                id="orgCity"
                                                name="city"
                                                placeholder='City'
                                                defaultValue={org.organization.city}
                                                onChange={handleChange}
                                                required
                                            />

                                            {/* State */}

                                            {org.organization.country && org.organization.country.toLowerCase() !== "canada" &&
                                                <>
                                                    <Registration_AutoSuggestion
                                                        categories={{ "state": org.organization.country.toLowerCase() === "united states" && state ? state : [] }}
                                                        categoryName={"state"}
                                                        currentSelectedCategories={[org.organization.state ? org.organization.state : ""]}
                                                        handleCategorySelection={this.props.handleLocationSelection}
                                                        handleCategoryInput={this.props.handleLocationInput}
                                                        className="orgPageTextField"
                                                        placeholderText="State"
                                                    />
                                                    {organizationStateError && (
                                                        <p className="defaultErrorMessage">Please select a state from the list.</p>
                                                    )}
                                                </>
                                            }
                                            {org.organization.country && org.organization.country.toLowerCase() === "canada" &&
                                                <Registration_AutoSuggestion
                                                    categories={{ "state": [] }}
                                                    categoryName={"state"}
                                                    currentSelectedCategories={[org.organization.state ? org.organization.state : ""]}
                                                    handleCategorySelection={this.props.handleLocationSelection}
                                                    handleCategoryInput={this.props.handleLocationInput}
                                                    className="orgPageTextField"
                                                    placeholderText="Province"
                                                />
                                            }

                                            {/* Zipcode */}

                                            <input
                                                className="orgPageTextField"
                                                id="orgZipcode"
                                                name="zipCode"
                                                placeholder='Zipcode'
                                                defaultValue={org.organization.zipCode}
                                                onChange={handleChange}
                                                required
                                            />

                                            {isEditing === "location" &&
                                                <div style={{ display: "flex", marginTop: 15 }}>
                                                    <button className='defaultGreenButton' onClick={(e) => this.handleSubmit(e)} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                                        Update
                                                    </button>
                                                    <button className="defaultWhiteButton" onClick={() => this.cancel()} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            }
                                        </div>

                                    </>
                                    :
                                    <p className="orgPageItemText dFlex flexWrap flexColumn">
                                        {org.organization.country ?
                                            <>
                                                {org.organization.address &&
                                                    <span>{org.organization.address}&nbsp;</span>
                                                }
                                                {(org.organization.city || org.organization.zipCode || org.organization.state) &&
                                                    <span>{org.organization.city}{(org.organization.city && (org.organization.state || org.organization.zipCode)) ? "," : ''}{org.organization.state ? " " + org.organization.state : ''}{org.organization.zipCode ? " " + org.organization.zipCode : ''}&nbsp;</span>
                                                }
                                                {org.organization.country &&
                                                    <span>{org.organization.country} </span>
                                                }
                                            </>
                                            :
                                            "N/A"
                                        }
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/* ------------------------- PROFESSIONAL INFO ------------------------- */}
                {(service_Provider && (service_Provider.organization_Id == this.state.organization_id)) &&
                    <div>

                        {/* Header */}
                        <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                            <div className="col-md-3 orgPageMobileHeaderCol3Left" style={{ padding: 0 }}>
                                <p className="acceptingProjectHeader mobileFloatHeader ">Professional Info</p>
                            </div>
                            <div className="col-md-6 orgPageMobileHeaderCol6" />
                            {editingAllowed &&
                                <>
                                    {isEditing === "professional" ?
                                        // <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                        //     <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={(e) => this.handleSubmit(e)}>
                                        //         <i className="icon-pencil-icon" />Update
                                        //     </a>
                                        //     <a className="backToProjects" onClick={() => this.cancel()} style={{ marginLeft: 15 }}>
                                        //         Cancel
                                        //     </a>
                                        // </div>
                                        null
                                        :
                                        <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                            <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => this.handleEditing("professional")}>
                                                <i className="icon-pencil-icon" />Edit
                                            </a>
                                        </div>
                                    }
                                </>
                            }
                        </div>

                        {/* Role(s) */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Role(s) {isEditing === "professional" && <span className="red">*</span>}</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn ">
                                {isEditing === "professional" ?
                                    <EditRoles
                                        service_Provider={service_Provider}
                                        handleChange={handleChange}
                                        handleSelectionChange={handleSelectionChange}
                                        submitted={submitted}
                                        editOrganization={editOrganization}
                                        checkboxes={checkboxes}
                                        handleCheckbox={handleCheckbox}
                                        handleDefaultCheckbox={handleDefaultCheckbox}
                                        isEmptyCheckbox={isEmptyCheckbox}
                                        editErrors={editErrors}
                                    />
                                    :
                                    <p className="orgPageItemText">{rolesTags && rolesTags.length !== 0 ? rolesTags : "N/A"}</p>
                                }
                                {submitted && this.props.editErrors.emptyRoles &&
                                    <p className="defaultErrorMessage">You must select a role.</p>
                                }
                            </div>
                        </div>

                        {/* Industry Focus */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Industry Focus {isEditing === "professional" && <span className="red">*</span>}</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">

                                {isEditing === "professional" ?
                                    <>
                                        <p className="inputFieldSubLabel">
                                            Tell us a bit about your industry focus. Where do you do most of your work?
                                        </p>
                                        <EditAutoSuggestion
                                            categories={industry_Focus_List_Cat}
                                            categoryName="SP_Industry_Focus_Tags"
                                            stateName="stringFocusTypes"
                                            currentSelectedCategories={stringFocusTypes}
                                            multiple={true}
                                            addTags={true}
                                            submitted={submitted}
                                            errorInput={category_TagsError}
                                            handleCategorySelection={handleEditCategorySelection}
                                            handleCategoryInput={handleEditCategoryInput} />
                                    </>
                                    :
                                    <p className="orgPageItemText">{industryFocusTags && industryFocusTags.length !== 0 ? industryFocusTags : "N/A"}</p>
                                }
                                {submitted && stringFocusTypes.length === 0 &&
                                    <p className="defaultErrorMessage">You must select a focus type.</p>
                                }

                            </div>
                        </div>

                        {/* Services Provided */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Services Provided {isEditing === "professional" && <span className="red">*</span>}</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                                {isEditing === "professional" ?
                                    <>
                                        <p className="inputFieldSubLabel">
                                            What services do you or your team provide?
                                        </p>
                                        <EditAutoSuggestion
                                            categories={industry_Service_List_Cat}
                                            categoryName="SP_Industry_Services_Tags"
                                            stateName="stringServiceTypes"
                                            currentSelectedCategories={stringServiceTypes}
                                            multiple={true}
                                            addTags={true}
                                            submitted={submitted}
                                            errorInput={category_TagsError}
                                            handleCategorySelection={handleEditCategorySelection}
                                            handleCategoryInput={handleEditCategoryInput} />
                                    </>
                                    :
                                    <p className="orgPageItemText">{servicesTags && servicesTags.length !== 0 ? servicesTags : "N/A"}</p>

                                }
                                {submitted && stringServiceTypes.length === 0 &&
                                    <p className="defaultErrorMessage">You must select service(s) you provide.</p>
                                }
                            </div>
                        </div>

                        {/* Foundation Expertise */}

                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Foundation Expertise</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6">
                                {isEditing === "professional" ?
                                    <div>
                                        <p className="inputFieldSubLabel">Please select foundations you have worked with in the past.</p>
                                        <EditAutoSuggestion
                                            categories={foundation_Expertise_List_Cat}
                                            categoryName="GW_Foundation_Tags"
                                            stateName="stringFoundationTypes"
                                            currentSelectedCategories={stringFoundationTypes} //pass in the array of strings - stringFoundationTypes
                                            multiple={true}
                                            addTags={true}
                                            submitted={submitted}
                                            errorInput={category_TagsError}
                                            handleCategorySelection={handleEditCategorySelection}
                                            handleCategoryInput={handleEditCategoryInput}
                                        />
                                    </div>
                                    :
                                    <p className="orgPageItemText">{foundationTags && foundationTags.length !== 0 ? foundationTags : "N/A"}</p>
                                }
                            </div>
                        </div>

                        {/* Agency You Work With */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Agencies You Work With</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6">
                                {isEditing === "professional" ?
                                    <div>
                                        <p className="inputFieldSubLabel">
                                            Please select agencies you have worked with in the past.
                                        </p>
                                        <EditAutoSuggestion
                                            categories={agency_Expertise_List_Cat}
                                            categoryName="GW_Agency_Tags"
                                            stateName="stringAgencyTypes"
                                            currentSelectedCategories={stringAgencyTypes}
                                            multiple={true}
                                            addTags={true}
                                            submitted={submitted}
                                            errorInput={category_TagsError}
                                            handleCategorySelection={handleEditCategorySelection}
                                            handleCategoryInput={handleEditCategoryInput}
                                        />
                                    </div>
                                    :
                                    <p className="orgPageItemText">{agencyTags && agencyTags.length !== 0 ? agencyTags : "N/A"}</p>
                                }
                            </div>
                        </div>

                        {/* Hourly Rate */}

                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold orgLabel">Hourly Rate {isEditing === "professional" && <span className="red">*</span>}</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                                {isEditing === "professional" ?
                                    <>
                                        <p className="inputFieldSubLabel">
                                            What is your general hourly rate? Please write $0 if you're offering pro-bono services.
                                            <br />
                                            *You can also change this rate for each project or work segment.
                                        </p>
                                        <input
                                            required
                                            type="text"
                                            id="service-provider-standard-basic"
                                            name="Base_Hourly_Rate"
                                            value={editTextInput.Base_Hourly_Rate}
                                            onChange={(e) => handleEditChange(e)}
                                            startAdornment={<InputAdornment position="start" style={{ paddingLeft: "10px" }}>$</InputAdornment>}
                                            className="orgPageTextField"
                                        />
                                    </>
                                    :
                                    <p className="orgPageItemText">${service_Provider.base_Hourly_Rate} USD</p>
                                }
                                {submitted && !sharedService.isInteger(editTextInput.Base_Hourly_Rate) &&
                                    <p className='defaultErrorMessage'>Invalid input</p>

                                }
                                {submitted && (editTextInput.Base_Hourly_Rate.length === 0) &&
                                    <p className="defaultErrorMessage">You must specify an hourly rate.</p>
                                }
                            </div>
                        </div>

                        {/* Testimonials */}
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <p className="orgPageItemText orgPageItemTextBold testimonialsDisplay orgLabel">Testimonials</p>
                            </div>
                            <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                                {isEditing === "professional" ?
                                    <>
                                        <p className="inputFieldSubLabel">
                                            Please copy/paste or link to past client/customer testimonials and/or references. For grant writers, contactable customer references are preferred.
                                        </p>
                                        <textarea
                                            required
                                            name="References"
                                            value={editTextInput.References}
                                            onChange={(e) => handleEditChange(e)}
                                            multiline
                                            rowsMax="20"
                                            rows={5}
                                            maxlength="60000"
                                            className='orgPageTextArea'
                                        />
                                    </>
                                    :
                                    <>
                                        {showMoreTestimonials &&
                                            <>
                                                <p className='orgPageItemText' style={{ marginBottom: 20 }}>{service_Provider.references}</p>
                                                <a className="showLessTextButton" onClick={() => { this.setState({ showMoreTestimonials: false }) }}> Show Less </a>
                                            </>
                                        }
                                        {!showMoreTestimonials &&
                                            <TextTruncate
                                                line={4}
                                                element="p"
                                                truncateText="…"
                                                text={service_Provider.references ? service_Provider.references : "N/A"}
                                                textTruncateChild={<a onClick={() => { this.setState({ showMoreTestimonials: true }) }}> Show More </a>}
                                                className='truncatedText orgPageItemText'
                                            />
                                        }
                                    </>
                                }

                                {isEditing === "professional" &&
                                    <div style={{ display: "flex", marginTop: 15 }}>
                                        <button className='defaultGreenButton' onClick={(e) => this.handleSubmit(e)} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                            Update
                                        </button>
                                        <button className="defaultWhiteButton" onClick={() => this.cancel()} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/* ------------------------- ADVANCED SETTINGS ------------------------- */}

                <div>

                    {/* Header */}
                    <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                        <div className="col-md-3 orgPageMobileHeaderCol3Left" style={{ padding: 0 }}>
                            <p className="acceptingProjectHeader mobileFloatHeader">Advanced Settings</p>
                        </div>
                        <div className="col-md-6 orgPageMobileHeaderCol6" />
                        {editingAllowed &&
                            <>
                                {isEditing === "advanced" ?
                                    // <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                    //     <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={(e) => this.handleSubmit(e)}>
                                    //         <i className="icon-pencil-icon" />Update
                                    //     </a>
                                    //     <a className="backToProjects" onClick={() => this.cancel()} style={{ marginLeft: 15 }}>
                                    //         Cancel
                                    //     </a>
                                    // </div>
                                    null
                                    :
                                    <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                        <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => this.handleEditing("advanced")}>
                                            <i className="icon-pencil-icon" />Edit
                                        </a>
                                    </div>
                                }
                            </>
                        }
                    </div>

                    {/* UEI or DUNS */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Unique Entity Identifier (previously known as DUNS number)</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <>
                                    <p className="inputFieldSubLabel">
                                        On April 4, 2022, the unique entity identifier used across the federal government changed from the DUNS Number to the Unique Entity ID.
                                        This is a 12-character alphanumeric ID assigned by <a href="https://sam.gov/content/home" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">SAM.gov</a>.
                                        The DUNS Number has been retired.
                                    </p>
                                    <p className="inputFieldSubLabel">
                                        Existing registered entities can find their Unique Entity ID by following the steps here.
                                        New entities can get their Unique Entity ID at <a href="https://sam.gov/content/home" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">SAM.gov</a>.
                                    </p>
                                    <input
                                        className="orgPageTextField"
                                        id="orgDuns"
                                        name="dunS_Number"
                                        defaultValue={org.organization.dunS_Number}
                                        onChange={handleChange}
                                    />
                                </>
                                :
                                <p className="orgPageItemText">{org.organization.dunS_Number ? org.organization.dunS_Number : "N/A"}</p>
                            }
                        </div>
                    </div>


                    {/* NAICS */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">NAICS</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <EditOrganizationNAICS
                                    org={org}
                                    handleCategoryInput={this.props.handleCategoryInput}
                                    handleCategorySelection={this.props.handleCategorySelection}
                                />
                                :
                                <p className="orgPageItemText">{naicS_CodesTags && naicS_CodesTags.length !== 0 ? naicS_CodesTags : "N/A"}</p>
                            }
                        </div>
                    </div>


                    {/* Industries */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Industries</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <EditOrganizationIndustries
                                    org={org}
                                    handleCategoryInput={this.props.handleCategoryInput}
                                    handleCategorySelection={this.props.handleCategorySelection}
                                />
                                :
                                <p className="orgPageItemText">{industryTags && industryTags.length !== 0 ? industryTags : "N/A"}</p>
                            }
                        </div>
                    </div>

                    {/* Technologies */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Technologies</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <EditOrganizationTechnologies
                                    org={org}
                                    handleCreatableSelection={this.props.handleCreatableSelection}
                                    handleCreatableRemoval={this.props.handleCreatableRemoval}
                                />
                                :
                                <p className="orgPageItemText">
                                    {mainTechnologyTags?.length !== 0 &&
                                        mainTechnologyTags
                                    }
                                    {(mainTechnologyTags?.length === 0) &&
                                        "N/A"
                                    }
                                </p>
                            }
                        </div>
                    </div>


                    {/* Social Impact Areas */}
                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Social Impact Areas</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <EditOrganizationImpact
                                    org={org}
                                    handleCategoryInput={this.props.handleCategoryInput}
                                    handleCategorySelection={this.props.handleCategorySelection}
                                />
                                :
                                <p className="orgPageItemText">{socialImpactTags && socialImpactTags.length !== 0 ? socialImpactTags : "N/A"}</p>
                            }
                        </div>
                    </div>

                    {/* Certifications & Designations */}

                    <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                            <p className="orgPageItemText orgPageItemTextBold orgLabel">Certifications & Designations</p>
                        </div>
                        <div className="col-md-6 orgPageMobileCol6 mobileEditBtn">
                            {isEditing === "advanced" ?
                                <>
                                    {/* <EditOrganizationSAM
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    /> */}
                                    <EditOrganization8A
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    />
                                    <EditOrganizationWOB
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    />
                                    <EditOrganizationVOB
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    />
                                    <EditOrganizationHubzone
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    />
                                    <EditOrganizationSB
                                        org={org}
                                        handleSelectionChange={handleSelectionChange}
                                    />
                                </>
                                :
                                <p className="orgPageItemText">{designationTags && designationTags.length !== 0 ? designationTags : "N/A"}</p>
                            }

                            {isEditing === "advanced" &&
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <button className='defaultGreenButton' onClick={(e) => this.handleSubmit(e)} style={{ marginRight: 5, backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                        Update
                                    </button>
                                    <button className="defaultWhiteButton" onClick={() => this.cancel()} style={{ marginLeft: 5, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                                        Cancel
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* ------------------------- USERS -------------------------*/}

                <div className="viewOrgusers">

                    {/* Header */}
                    <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                        <div className="col-md-3 orgPageMobileHeaderCol3Left" style={{ padding: 0 }}>
                            <p className="acceptingProjectHeader mobileFloatHeader">Users</p>
                        </div>
                        <div className="col-md-6 orgPageMobileHeaderCol6" />
                        {editingAllowed && (org.organization.owner === user.id) &&
                            <>
                                {isEditing === "users" ?
                                    <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                        <a className="backToProjects" onClick={() => this.cancel()} style={{ marginLeft: 15 }}>
                                            Close
                                        </a>
                                    </div>
                                    :
                                    <div className="col-md-3 orgPageMobileHeaderCol3Right" style={{ marginTop: '5px' }}>
                                        <a className="backToProjects" style={{ cursor: 'pointer' }} onClick={() => this.handleEditing("users")}>
                                            <i className="icon-pencil-icon" />Edit
                                        </a>
                                    </div>
                                }
                            </>
                        }
                    </div>

                    {/* Users */}
                    <div>
                        {isEditing === "users" ?
                            <MembersForm
                                user={this.state.user}
                                invite_emailInput={this.props.invite_emailInput}
                                accepted_profiles={this.props.accepted_profiles}
                                accepted_profile_list={edit_accepted_profile_list}
                                pending_profiles={this.props.pending_profiles}
                                pending_profile_list={pending_profile_list}
                                pending_invitation_profiles={this.props.pending_invitation_profiles}
                                pending_invitation_list={pending_invitation_list}
                                transfer={this.props.transfer}
                                handleNewOrganization={this.props.handleNewOrganization}
                                inviteToJoinOrganization={this.props.inviteToJoinOrganization}
                                org={org}
                            />
                            :
                            <div className='row'>
                                <div className='col-md-2' />
                                <div className="col-md-7 organizationUsersList" style={{ display: "flex", flexWrap: "wrap" }}>
                                    {accepted_profile_list}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    return {
        user,
        organization,
        service_Provider
    };
}

const connectedOrganizationViewEdit = connect(mapStateToProps)(OrganizationViewEdit_v2);
export { connectedOrganizationViewEdit as OrganizationViewEdit_v2 };

