import { API_Route } from '../../App/API_Route';
import { authHeader } from '../../_helpers';
import { sharedService } from '../shared.service';

export const listingNotesService = {
    getAllListingNotes,
    getListingNotes,
    saveListingNotes,
    deleteListingNotes
};

function getAllListingNotes(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/listingsnotes/get_all_listing_notes?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getListingNotes(user_id, listing_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/listingsnotes/get_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function saveListingNotes(user_id, listing_Id, user_notes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user_notes)
    };

    return fetch(API_Route + '/api/listingsnotes/save_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteListingNotes(user_id, listing_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/listingsnotes/delete_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}