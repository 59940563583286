import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import '../../../../Styles/Login.css';
import '../../../../Styles/Marketplace.css';

class EditAdditional extends React.Component {

    componentDidMount() {
        this.checkIfChecked()
    }

    checkIfChecked = () => {
        if (this.props.service_Provider && this.props.service_Provider.office_Hours && this.props.handleDefaultCheckbox) {
            this.props.handleDefaultCheckbox("office_Hours", "additional")
        }
    }

    render() {
        const { submitted, handleEditChange, editTextInput, } = this.props;

        return (
            <div id="SP_Onboarding_Additional_Info">
                {editTextInput &&
                    <div>
                        <div>
                            <h4 className="inputFieldLabel">Hourly Rate <span className="red">*</span></h4>
                            {submitted && (editTextInput.Base_Hourly_Rate.length === 0) &&
                                <p className="errorMessage">You must specify an hourly rate.</p>
                            }
                            <p className="inputFieldSubLabel">
                                What is your general hourly rate? Please write $0 if you're offering pro-bono services.
                                <br />
                                *You can also change this rate for each project or work segment.
                            </p>
                            <input
                                required
                                type="text"
                                id="service-provider-standard-basic"
                                name="Base_Hourly_Rate"
                                value={editTextInput.Base_Hourly_Rate}
                                onChange={(e) => handleEditChange(e)}
                                startAdornment={<InputAdornment position="start" style={{ paddingLeft: "10px" }}>$</InputAdornment>}
                                className="inputField"
                            />
                        </div>

                        {/* <div>
                            <h4 className="inputFieldLabel">Booking Link <span style={{ color: "red" }}>*</span></h4>
                            {submitted && (editTextInput.Booking_Link.length === 0) &&
                                <p className="errorMessage">You must specify a booking link (use the links below to create an account if needed).</p>
                            }
                            <p className="inputFieldSubLabel">
                                We require a booking link so users can easily schedule time with you, and so you can manage the time you make available to them. If you do not already have one, we recommend using either of these free services Calendly <a href="https://calendly.com">(https://calendly.com/)</a> or Woven <a href="https://woven.com/">(https://woven.com/)</a>.
                            </p>
                            <p className="inputFieldSubLabel">Do not leave an email here, users will be unable to contact you.</p>

                            <input
                                required
                                id="service-provider-standard-basic"
                                name="Booking_Link"
                                className="inputField"
                                value={editTextInput.Booking_Link}
                                onChange={(e) => handleEditChange(e)}
                                className="inputField boxText"
                            />
                        </div> */}
                    </div>
                }

                {/* <div>
                    <h4 className="inputFieldLabel">Open To Office Hours</h4>
                    <p className="inputFieldSubLabel">
                        We host office hours each week Friday at 10am PST. These are hour-long 1-1 meetings with founders on a variety of topics. If you are interested, these are a great way to network and do very targeted business development.  Selecting yes here will put you on a list for outreach as we plan office hours connections during the week.
                    </p>
                    <FormGroup>
                        <div style={{ paddingLeft: 15 }} className="SP_Checkboxes" id="OH_Checkbox">
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    checked={checkboxes.additional.office_Hours}
                                    onChange={(e) => handleCheckbox(e, "additional")}
                                    name="Office_Hours"
                                    id="office_Hours"
                                />}
                                label={<Typography style={{ marginTop: "-10px", backgroundColor: "#CFDFFF", display: "inline-table" }}>Yes</Typography>}
                            />
                        </div>
                    </FormGroup>
                </div> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    return {
        user,
    };
}

const connectedEditAdditional = connect(mapStateToProps)(EditAdditional);
export { connectedEditAdditional as EditAdditional };

