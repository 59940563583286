import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationVOB extends React.Component {

    render() {
        const { org } = this.props;

        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel">Are You a Certified Veteran Owned Business?</h4>
                <p className="inputFieldSubLabel">
                    If you have a Veteran-Owned Small Business, you may qualify for advantages when bidding on government contracts—along with access to other resources and support—through the Vets First Verification Program. Learn more and apply for certification
                    <a href="https://www.va.gov/careers-employment/veteran-owned-business-support/" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        name="veteran_Owned"
                        defaultValue={org.organization.veteran_Owned === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "veteran_Owned")}
                        className="orgPageTextField"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationVOB = connect(mapStateToProps)(EditOrganizationVOB);
export { connectedEditOrganizationVOB as EditOrganizationVOB };
