import React from 'react';
import { useHistory } from 'react-router-dom'
import { Image, Card, Button, Row, Col } from 'react-bootstrap'
import bgCirclesDark from '../../_assets/img/bgCirclesDark.png'
import opengrantsLogoBig from '../../_assets/img/opengrants-logo-card.svg'

export const CommonAppLanding = () => {
  const history = useHistory()
  
  return (
    <div className='d-flex align-items-center position-relative bg-secondary' style={{ minHeight: "100vh" }}>
      <Image src={bgCirclesDark} className="position-absolute top-10 start-0 w-100 mh-100" style={{ zIndex: 10, maxHeight: '100vh' }} />
      <Row>
        <Col xs={0} md={1} lg={2} className='d-none d-md-block' />
        <Col xs={12} md={10} lg={8} style={{ maxWidth: '100vw' }}>
          <Card className='pt-136 pb-72 d-flex flex-column align-items-center rounded-3 bg-white' style={{ zIndex: 20 }}>
            <Row className='w-100 d-flex'>
              <Col xs={0} md={1} xl={3} />
              <Col xs={12} md={10} xl={6} className='px-32 px-md-0 d-flex flex-column align-items-center'>
                <Image src={opengrantsLogoBig} style={{ maxWidth: '100%' }} />
                <div className='mt-40 text-center'>
                  <h1 className='fw-bold mb-24'>Welcome to the Common App!</h1>
                  <p className='mb-48 fw-bold'>One Application Connecting You to Funding</p>
                  <p>OpenGrants supports public and private grantmakers with data on fundable projects and organizations. Completing and updating your application on the OpenGrants platform ensures we are providing the most accurate information to our grant making partners and will increase your chances of receiving funding in the future.</p>
                  <Button
                    variant='primary-dark'
                    className='mt-48 py-8 fw-bold w-100 fs-5'
                    onClick={() => history.push('/commonapp')}
                  >
                    Get Started
                  </Button>
                  <p className='mt-104'>Need help? <a href="/commonapp">We can assist you</a></p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}