import React from 'react'
import { ExpandLess, ExpandMore, InsertDriveFileOutlined, LinkOutlined } from '@mui/icons-material';
import { format, parseISO } from "date-fns";
import { useState } from 'react';

export default function SegmentDisplay({
    idx, originalSegment, project, editHours, toggleDeleteModal, downloadFile
}) {

    const [showAttachments, setShowAttachments] = useState(false);

    let links;

    //Add HTTPS to URLs
    const withHttp = (url) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

    //truncate attachment name for display
    const truncate = (text) => {
        return text.length > 28 ? text.substring(0, 25) + "..." : text;
    };

    //Generate attachment files and links
    const generateLinks = () => {

        let documents = project.applications ? project.documents : project.document_Links;

        return documents.map((doc) => {
            if (doc.segment_Id === originalSegment.segment_Id) {
                return (
                    <div key={doc.document_Link} className="attachmentTagBox preview">
                        {doc.document_Link &&
                            <div className="attachmentTagContent">
                                <LinkOutlined className="attachmentTagIcon link" />
                                <a className="attachmentTagName" href={withHttp(doc.document_Link)} target="_blank" rel="noopener noreferrer" >{truncate(doc.document_Link)}</a>
                            </div>
                        }
                        {doc.document_Name &&
                            <div className="attachmentTagContent">
                                <InsertDriveFileOutlined className="attachmentTagIcon" />
                                <a className="attachmentTagName" onClick={() => { downloadFile(doc, project) }}>{truncate(doc.document_Name)}</a>
                            </div>
                        }
                    </div>
                )
            }
        })
    }


    if (showAttachments) {
        links = generateLinks();
    }

    let segmentDocuments = project.applications ? project.documents : project.document_Links;
    let segmentAttachments = segmentDocuments?.some((doc) => {
        return doc.segment_Id === originalSegment.segment_Id
    });

    return (
        <>
            {/* DESKTOP */}

            <div idx={idx} className="row hoursInvoicesRow" style={{ flexDirection: "column" }}>

                <div className="row" style={{ display: "flex" }}>

                    <div className="col-md-6"><p>{originalSegment.segment_Title}</p></div>
                    <div className="col-md-1"><p>{originalSegment.segment_Hours}</p></div>
                    <div className="col-md-1"><p>${originalSegment.segment_Hourly_Rate}/hr</p></div>
                    <div className="col-md-2"><p>{format(parseISO(originalSegment.segment_Date_Performed), "MM/dd/yyyy")}</p></div>

                    {originalSegment && originalSegment.is_Editable && project.project_Status.toLowerCase() === "active" &&
                        <>
                            <div className='col-md-1'><p className="hoursInvoicesActionButton" onClick={() => editHours(project, originalSegment)}><i className="icon-pencil-icon" /></p></div>
                            <div className="col-md-1"><p className="hoursInvoicesActionButton" onClick={() => toggleDeleteModal(true, project.applications ? originalSegment : originalSegment.segment_Id)}><i className="icon-cancel" /></p></div>
                        </>
                    }
                </div>

                {segmentAttachments &&
                    <div className='row' style={{ display: "flex", flexDirection: "column", paddingLeft: 15 }}>
                        <p className="hoursAttachmentButton" onClick={() => { setShowAttachments(!showAttachments) }}>
                            View attachments {!showAttachments ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
                        </p>
                        <div>
                            {showAttachments && links}
                        </div>
                    </div>
                }

            </div >

            {/* MOBILE STYLING */}

            <div className="hoursInvoicesRowMobile">
                <div className="leftColumn"><p>Description of Work:</p></div>
                <div className="rightColumn"><p>{originalSegment.segment_Title}</p></div>
            </div>
            <div className="hoursInvoicesRowMobile">
                <div className="leftColumn"><p># of Hours:</p></div>
                <div className="rightColumn"><p>{originalSegment.segment_Hours}</p></div>
            </div>
            <div className="hoursInvoicesRowMobile">
                <div className="leftColumn"><p>Rate:</p></div>
                <div className="rightColumn"><p>${originalSegment.segment_Hourly_Rate}/hr</p></div>
            </div>
            <div className="hoursInvoicesRowMobile">
                <div className="leftColumn"><p>Date:</p></div>
                <div className="rightColumn"><p>{format(parseISO(originalSegment.segment_Date_Performed), "MM/dd/yyyy")}</p></div>
            </div>

            {originalSegment && originalSegment.is_Editable &&
                <div className="hoursInvoicesRowMobile" style={{ marginTop: 20, flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <div className='leftColumn'>
                            {segmentAttachments &&
                                <p className="hoursInvoicesActionButton" onClick={() => { setShowAttachments(!showAttachments) }}>
                                    Attachments {!showAttachments ? <ExpandMore fontSize="large" /> : <ExpandLess fontSize="large" />}
                                </p>
                            }
                        </div>
                        {project.project_Status.toLowerCase() === "active" &&
                            <div className='rightColumn' style={{ display: "flex" }}>
                                <p className="hoursInvoicesActionButton" style={{ marginRight: 30 }} onClick={() => editHours(project, originalSegment)}>
                                    <i className="icon-pencil-icon" /> Edit
                                </p>
                                <p className="hoursInvoicesActionButton" onClick={() => toggleDeleteModal(true, project.applications ? originalSegment : originalSegment.segment_Id)}>
                                    <i className="icon-cancel" /> Delete
                                </p>
                            </div>
                        }
                    </div>

                    <div className="row">
                        {showAttachments && links}
                    </div>
                </div>
            }
        </>
    )
}
