import React from 'react';
import ReactPasswordStrength from 'react-password-strength';
import { connect } from 'react-redux';
import '../../Styles/SettingsStyles/Manage.css';
import { alertActions, authenticateActions } from '../../_actions';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../_helpers';
import { asyncLocalStorage, authenticateService, pluginService, registrationService, settingsService } from '../../_services';
import { Loader } from '../Shared/Loader';
import { NotificationSettings } from "./NotificationSettings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

let accentColor = pluginService.getIFrameColor()

class Account_v2 extends React.Component {
    constructor(props) {
        super(props);
        this.cardEl = React.createRef()

        const user_info = this.props.user;

        this.state = {
            user_info,

            oldpassword: '',
            newpassword: '',
            updatePassword: false,
            password_model: [],

            submitted: false,
            submitting: false,
            updatedAlert: false,
            passLength: 0,
            isValid: false,

            email: user_info.email,
            changeEmail: false,
            emailError: {
                format: true,
                present: false
            },

            displayForm: {},
            errors: {},
            iFrameConfig: null,

            displayDeleteConfirmation: false
        };

        history.listen((location, action) => {
            // clear alert on location change
            this.props.dispatch(alertActions.updatePasswordClear());
        });

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
    }

    displayDeleteConfirmation = () => {
        this.setState({
            displayDeleteConfirmation: !this.state.displayDeleteConfirmation
        })
    }

    deleteUser = async (e) => {
        e.preventDefault();

        this.setState({
            loading: true,
            displayDeleteConfirmation: false
        })

        var id = this.props.user?.id;
        if (!id) {
            return;
        }
        await authenticateService.deleteUser(id);
        // remove user from local storage to log user out
        localStorage.removeItem('user');

        window.location.href = "/complete";
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, errors: {} });
    }

    handleUpdate = async () => {
        let errors = {};
        this.setState({
            errors
        })
        const { displayForm } = this.state;
        const { dispatch } = this.props;

        if (displayForm.google) {
            errors = await this.handleUpdate_Email(errors);
            errors = await this.handleUpdate_Password(errors);

            if (Object.keys(errors).length === 0) {
                errors = await this.submit_email();
                if (Object.keys(errors).length === 0) {
                    errors = await this.submit_password();
                }

                if (Object.keys(errors).length === 0) {
                    dispatch(alertActions.updatePasswordSuccess(
                        ["Your login email has successfully been updated",
                            " and your password has been set up."]
                    ));
                    setTimeout(() => {
                        dispatch(alertActions.updatePasswordClear());
                    }, 3000);
                }
            }
        }
        else if (displayForm.email) {
            errors = await this.handleUpdate_Email(errors);

            if (Object.keys(errors).length === 0) {
                this.submit_email();

                if (Object.keys(errors).length === 0) {
                    dispatch(alertActions.updatePasswordSuccess(
                        "Your login email has successfully been updated."
                    ));
                    setTimeout(() => {
                        dispatch(alertActions.updatePasswordClear());
                    }, 3000);
                }
            }
        }
        else if (displayForm.password) {
            errors = await this.handleUpdate_Password(errors);

            if (Object.keys(errors).length === 0) {
                errors = await this.submit_password();

                if (Object.keys(errors).length === 0) {
                    dispatch(alertActions.updatePasswordSuccess(
                        "Your password has successfully been updated"
                    ));
                    setTimeout(() => {
                        dispatch(alertActions.updatePasswordClear());
                    }, 3000);
                }
            }
        }

        if (Object.keys(errors).length > 0) {
            dispatch(alertActions.updatePasswordError(
                "Unable to update account."
            ));
            setTimeout(() => {
                dispatch(alertActions.updatePasswordClear());
            }, 3000);
        } else {
            this.setState({
                errors,
                displayForm: {},
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
        }
    }

    submit_email = async () => {
        let user = Object.assign({}, this.props.user);
        const { email } = this.state;
        user.email = email;
        user.googleUser = false;
        const { dispatch } = this.props;
        let errors = {};
        var success = await settingsService.updateEmail(user.id, email);
        if (success) {
            dispatch(authenticateActions.setUser(user));
            this.cancelUpdate();
        } else {
            errors["email"] = "There was an error updating your email";
        }
        return errors;
    }

    submit_password = async () => {
        let user = Object.assign({}, this.props.user);
        const { displayForm, password_model, oldpassword, newpassword } = this.state;

        password_model.oldpassword = oldpassword;
        password_model.newpassword = newpassword;

        return await settingsService.updatePassword(password_model.oldpassword, password_model.newpassword, user)
            .then(
                user => {
                    this.cancelUpdate();
                    return {};
                },
                error => {
                    let errorMessage = ["There was an error updating your password. "];
                    if (displayForm.password) {
                        errorMessage.push("Verfiy your current password is correct, ")
                        errorMessage.push("and that your new password is different from your current one.")
                    }
                    let errors = {};
                    errors["oldpassword"] = errorMessage;
                    return errors;
                }
            );
    }

    handleUpdate_Email = async (errors) => {
        let user = Object.assign({}, this.props.user);
        const { email } = this.state;
        user.email = email;

        let emailState = await registrationService.checkEmail(user);
        if (!emailState.valid) {
            errors["email"] = emailState.type === "format" ? "Please enter a valid email" : "This email already exists"
        }

        return errors;
    }

    handleUpdate_Password = async (errors) => {
        const { user } = this.props;
        const { displayForm, oldpassword, newpassword, confirmpassword, isValid } = this.state;

        if ((displayForm.password && !user.googleUser && !oldpassword) || !newpassword || !confirmpassword) {
            if (!oldpassword) {
                errors["oldpassword"] = "This is required.";
            }
            if (!newpassword) {
                errors["newpassword"] = "This is required.";
            }
            if (!confirmpassword) {
                errors["confirmpassword"] = "This is required.";
            }

            return errors;
        }
        if (isValid === false) {
            errors["newpassword"] = "Password is too weak.";
        }
        if (confirmpassword !== newpassword) {
            errors["confirmpassword"] = "Passwords do not match.";
        }
        return errors;
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            newpassword: state.password,
            errors: {}
        }), () => { });
    }

    clear = () => {
        this.ReactPasswordStrength.clear()
    }

    cancelUpdate = () => {
        const { user } = this.props;

        this.setState({
            changeEmail: false,
            updatePassword: false,
            email: user.email,

            emailError: {
                format: true,
                present: false
            },

            errors: {},
            displayForm: {}
        });
    }

    render() {
        const { user, updatePasswordAlert } = this.props;
        const { submitted, oldpassword, newpassword, confirmpassword, updatePassword,
            submitting,
            email, changeEmail,
            displayForm, errors, iFrameConfig,
        } = this.state;

        return (
            <div id="payment-form">
                {submitting && <Loader />}
                {!changeEmail && !updatePassword && updatePasswordAlert.message &&
                    <div id="managePasswordAlert" className={`alert ${updatePasswordAlert.type}`}>{updatePasswordAlert.message}</div>
                }

                {/* HEADER */}

                <div className="row viewProfileHeader" style={{ marginBottom: '1%' }}>
                    <div className="col-md-12" style={{ padding: 0 }}>
                        <p className="acceptingProjectHeader mobileFloatHeader">Account Preferences</p>
                    </div>
                </div>

                {Object.keys(displayForm).length === 0 &&
                    <div >
                        <div style={{ marginLeft: 30 }}>
                            {/* Email */}
                            <div className="accountUpdateRow" >
                                <div className='row' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ flex: 1 }}>
                                        <p className="gc_onboarding-form-input-name">Email Address</p>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                        <button
                                            className="backToProjects"
                                            style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }}
                                            onClick={() => { this.setState({ displayForm: { googleWarning: user.googleUser ? true : false, [user.googleUser ? 'google' : 'email']: true } }) }}
                                        >
                                            <i className="icon-pencil-icon" />Edit
                                        </button>
                                    </div>
                                </div>
                                <p className="accountUpdateText">{user.email}</p>
                            </div>

                            {/* Password */}
                            <div className="accountUpdateRow">
                                <div className='row' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ flex: 1 }}>
                                        <p className="gc_onboarding-form-input-name">Password</p>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                                        <button
                                            className="backToProjects"
                                            onClick={() => { this.setState({ displayForm: { googlePasswordWarning: user.googleUser ? true : false, password: true } }) }}
                                            style={{ color: iFrameConfig?.secondary ? iFrameConfig?.secondary : '#0565AA' }}
                                        >
                                            <i className="icon-pencil-icon" />Edit
                                        </button>
                                    </div>
                                </div>
                                <p className="accountUpdateText">**********</p>
                            </div>
                        </div>

                        {/* {!user.googleUser && 
                        <div className="row paymentRow">
                            <div className="col-md-3 flexColEnd">
                                <strong>Password</strong>
                            </div>
                            <div className="col-md-5 mobileEditBtn">
                                <p style={{ display: "inline-block", marginRight: "15px" }}>**********</p>
                            </div>
                            <div className="col-md-3">
                                <button className="backToProjects" onClick={() => { this.setState({ displayForm: { googlePasswordWarning: user.googleUser ? true : false, password: true } }) }} color="info">
                                    <i className="icon-pencil-icon" />Edit
                                </button>
                            </div>
                        </div>
                    } */}
                        <NotificationSettings
                            showPhoneNumberModal={true}
                            userType={this.props.service_Provider ? "Grant_Consultant" : "Grant_Seeker"}
                        />

                        {/* DELETE ACCOUNT */}
                        <div className="billingAddress" style={{ textAlign: 'center', marginTop: '50px' }}>
                            <button
                                className='perkButton'
                                onClick={this.displayDeleteConfirmation}
                                style={{ backgroundColor: 'red', color: 'white', marginBottom: '2%' }}
                            >
                                Delete My Account
                            </button>
                        </div>
                    </div>
                }

                <Modal
                    id="listingPreviewModal"
                    isOpen={this.state.displayDeleteConfirmation}
                    fade={false}
                >
                    <ModalHeader>
                        <div className="centerFlex spaceBtw">
                            <h4 className="boxTitle noMargin">Delete My Account</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: 'center' }}>Are you sure you want to delete your account?</div>
                    </ModalBody>

                    <ModalFooter>
                        <div style={{ display: 'flex', margin: '20px' }}>
                            <button
                                className='perkButton'
                                onClick={this.displayDeleteConfirmation}
                                style={{ flex: 1, margin: '15px', padding: '10px' }}
                            >
                                Cancel
                            </button>
                            <button
                                className='perkButton'
                                onClick={this.deleteUser}
                                style={{ flex: 1, margin: '15px', padding: '10px', backgroundColor: 'red', color: 'white' }}
                            >
                                Confirm
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {(displayForm.email || displayForm.password || displayForm.google) &&
                    <div id="accountForm" className="col-md-12" style={{ maxWidth: 500 }}>
                        {(displayForm.email || displayForm.google) &&
                            <div id="emailForm" style={{ overflow: "auto" }}>
                                <p className='TaletFinderTitle'>Change Email</p>
                                <div style={{ marginTop: "20px" }}>
                                    <input className="font-input-default email-text-field" name="email" value={email} onChange={this.handleChange} />
                                    <div style={{ marginTop: "15px" }}>
                                        {errors.email &&
                                            <p style={{ color: "red" }}>{errors.email}</p>
                                        }
                                    </div>
                                </div>
                            </div>}
                        {(displayForm.password || displayForm.google) &&
                            <div id="passwordForm" style={{ overflow: "auto" }}>
                                {displayForm.password &&
                                    <p className='TaletFinderTitle'>Change Password</p>}
                                <div style={{ marginTop: "20px" }}>
                                    <div asp-validation-summary="All" className="text-danger"></div>
                                    {displayForm.password && !user.googleUser &&
                                        <div className={'form-group' + (submitted && !oldpassword ? ' has-error' : '')}>
                                            <label name="OldPassword">Current Password</label>
                                            <input type="password" name="oldpassword" className="font-input-default email-text-field" onChange={this.handleChange} />
                                            {errors.oldpassword &&
                                                <p className="login-error">{errors.oldpassword}</p>
                                            }
                                        </div>}
                                    <div name='new_password' className={'form-group' + (submitted && !newpassword ? ' has-error' : '')}>
                                        <label>New Password</label>
                                        <ReactPasswordStrength
                                            ref={this.cardEl}
                                            className="customClass"
                                            minLength={8}
                                            minScore={2}
                                            scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                            changeCallback={this.changeCallback}
                                            inputState={{ name: "newpassword", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                        />
                                        {errors.newpassword &&
                                            <p className="login-error">{errors.newpassword}</p>
                                        }
                                    </div>
                                    {/* Confirm Password */}
                                    <div className={'form-group' + (submitted && !confirmpassword ? ' has-error' : '')}>
                                        <p className='card-text'>Confirm Password</p>
                                        <input
                                            type="password"
                                            name="confirmpassword"
                                            className="font-input-default email-text-field"
                                            onChange={this.handleChange}
                                        />
                                        {errors.confirmpassword &&
                                            <p className="login-error">{errors.confirmpassword}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{ marginTop: 10 }}>
                            <button
                                className='billingButton secondary'
                                onClick={this.cancelUpdate}
                                style={{ marginLeft: 0, marginRight: 10, color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                            >
                                Cancel
                            </button>
                            <button
                                className='billingButton'
                                onClick={this.handleUpdate}
                                style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                }

                {
                    displayForm.googleWarning &&
                    <div style={{ position: "fixed", top: 0, left: 0, zIndex: 5, backgroundColor: "#5555", height: '100%', width: '100%' }}>
                        <div style={{ padding: "40px 20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", textAlign: "center" }}>
                            <div style={{ marginBottom: 30 }}>
                                <h2>Heads up!</h2>
                                <p>You signed up with Google.
                                    If you change your email address, your Google account will be
                                    disconnected and you will be asked to create a new password.
                                    You will no longer be able to login with Google.
                                </p>
                            </div>
                            <Button onClick={this.cancelUpdate} style={{ marginRight: "15px" }}>Cancel</Button>
                            <Button color="info" onClick={() => this.setState({ displayForm: { ...displayForm, googleWarning: false } })}>
                                Continue
                            </Button>
                        </div>
                    </div>
                }
                {/* Double Check with OpenGrants Team */}
                {
                    displayForm.googlePasswordWarning &&
                    <div style={{ position: "fixed", top: 0, left: 0, zIndex: 5, backgroundColor: "#5555", height: '100%', width: '100%' }}>
                        <div style={{ padding: "40px 20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", textAlign: "center" }}>
                            <div style={{ marginBottom: 30 }}>
                                <h2>Heads up!</h2>
                                <p>You signed up with Google.
                                    If you change your password, your Google account will be
                                    disconnected and you will be asked to create a account.
                                    You will no longer be able to login with Google.
                                </p>
                            </div>
                            <Button onClick={this.cancelUpdate} style={{ marginRight: "15px" }}>Cancel</Button>
                            <Button color="info" onClick={() => this.setState({ displayForm: { ...displayForm, googlePasswordWarning: false } })}>
                                Continue
                            </Button>
                        </div>
                    </div>
                }
            </div >
        );
    }
}

function mapStateToProps(state) {
    const { setUser, updatePasswordAlert, updatePassword } = state;
    const { user } = setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;

    return {
        updatePasswordAlert,
        updatePassword,
        user,
        service_Provider,
    };
}

const connectedAccount_v2 = connect(mapStateToProps)(Account_v2);
export { connectedAccount_v2 as Account_v2 };
