import React from 'react';
import { Component } from "react";
import { Collapse, Container, Navbar, NavbarToggler } from "reactstrap";
import { connect } from "react-redux";
import { HeaderTags } from "../HeaderTags";
import { MobileNavMenu } from "./MobileNavMenu";
import { DesktopNavMenu } from "./DesktopNavMenu_v2";
import { asyncLocalStorage, pluginService } from "../../../_services";
import "../../../Styles/NavMenu_v2.css";
import { serviceProviderActions } from '../../../_actions';
import logo from "../../../_assets/img/opengrants-logo-big.svg";

class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      iFrameConfig: null,
    };
  }

  async componentDidMount() {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
    });
    const { user } = this.props;
    if (user) {
      this.props.dispatch(serviceProviderActions.getServiceProvidersByUser(user.id));
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { user } = this.props;

    return (
      <header>
        <HeaderTags />
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 p-0"
          light
        >
          <Container className='container-fluid'>
            <div className='row w-100'>
              <div id="navbar-header" className='w-100'>
                <div
                  id="logo"
                  onClick={() => {
                    window.location.href = pluginService.redirect("/dashboard");
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img src={this.state.iFrameConfig?.logo ? this.state.iFrameConfig?.logo : logo} alt="logo" style={{ width: this.state.iFrameConfig?.logo ? "auto" : "200px", display: "inline", height: this.state.iFrameConfig?.logo ? '70px' : 'auto' }} />
                </div>
                <div id="toggleBtn">
                  <NavbarToggler
                    onClick={this.toggleNavbar}
                    id="mainToggle"
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#landing-navbar-collapse-main"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </NavbarToggler>
                </div>
              </div>
              <Collapse
                isOpen={!this.state.collapsed}
                className='mt-0'
                navbar
              >
                <div className='w-100'>
                  {user && (
                    <DesktopNavMenu
                    />
                  )}
                  <MobileNavMenu
                  />
                </div>
              </Collapse>
            </div>
          </Container>
        </Navbar>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedHomePage = connect(mapStateToProps)(NavMenu);
export { connectedHomePage as NavMenu };

