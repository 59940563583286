import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Registration_AutoSuggestion } from '../../../Account/RegisterPage';
import { Loader as Loading } from '../../../Shared/Loader';

class NewProjectClient_v2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            all_Organizations: this.props.all_Organizations?.map(org => {return org.name}),
            organization_Select: [],
            selectedOption: null,
            loading: true,
        }
    }

    async componentDidMount() {
        if (this.state.all_Organizations)
            this.setState({
                loading: false
            })
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.all_Organizations && !this.state.all_Organizations) {
        this.setState({
            all_Organizations: this.props.all_Organizations?.map(org => {return org.name}),
            loading: false
        })
      }
    }

    render() {
        const { project_Client, client_Organization_Id, submitted } = this.props;
        const { loading, all_Organizations } = this.state;
        // let organization_Select = [];

        // this.props.all_Organizations && this.props.all_Organizations.forEach(function (org) {
        //     organization_Select.push({
        //         value: org.organization_Id + "-" + org.name,
        //         label:
        //             <span style={{ minHeight: 75 }}>
        //                 <img
        //                     src={org.logo_URL ? org.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"}
        //                     width="75"
        //                     height="75"
        //                     style={{ objectFit: "contain", borderRadius: 5 }}
        //                     alt="organization logo"
        //                 />
        //                 <span style={{ marginLeft: 25, fontWeight: 600, lineHeight: "75px" }}>{org.name}</span>
        //             </span>
        //     })
        // });

        const colourStyles = {
            control: (styles) => ({
                ...styles,
                background: "rgba(52, 122, 228, 0.08)",
                border: "2px solid rgba(52, 122, 228, 0.65)",
                '&:hover': {
                    border: "2px solid rgba(52, 122, 228, 0.65)"
                }
            }),

        };

        return (
            <div>
                {loading &&
                    <Loading />
                }
                <div className="newProjectFormHeader_v2">
                    Client
                </div>
                <form className='orgPage'>
                    {/* {this.props.all_Organizations && organization_Select &&
                        <div className="newProjectForm_v2">
                            <h4>Select your Client's Organization <span style={{ color: "red" }}>*</span></h4>
                            <Select
                                value={project_Client}
                                name="client_Organization"
                                isClearable={true}
                                isSearchable={true}
                                onChange={this.props.handleSelectionChange}
                                options={organization_Select}
                                styles={colourStyles}
                                placeholder=""
                            />
                            <br />
                        </div>
                    } */}
                    
                    <Registration_AutoSuggestion
                        categories={all_Organizations ? {"client_Organization": all_Organizations} : {"client_Organization": []}}
                        categoryName="client_Organization"
                        currentSelectedCategories={
                            [project_Client ? project_Client : '']
                        }
                        submitted={submitted}
                        errorInput={!client_Organization_Id}
                        handleCategorySelection={this.props.handleSelectionChange}
                        handleCategoryInput={this.props.handleSelectionInput}
                        className="Registration_AutoSuggestion_display"
                        renderOnLength={true}
                    />
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedNewProjectClient_v2 = connect(mapStateToProps)(NewProjectClient_v2);
export { connectedNewProjectClient_v2 as NewProjectClient_v2 };
