import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const algoliaService = {
    getSavedSearches,
    saveQuery,
    updateQuery,
    removeQuery,
    exportSearchResults
};

function getSavedSearches(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(API_Route + '/api/algolia/get_saved_searches', requestOptions)
        .then(sharedService.handleResponse)
        .then(queries => {
            localStorage.setItem('savedQueries', JSON.stringify(queries));

            return queries;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function saveQuery(searchQuery) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchQuery)
    };

    return fetch(API_Route + '/api/algolia/save_search_query', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function updateQuery(searchQuery) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(searchQuery)
    };

    return fetch(API_Route + '/api/algolia/update_search_query', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function removeQuery(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query)
    };

    return fetch(API_Route + '/api/algolia/remove_search_query', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function exportSearchResults(query, file_type) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/algolia/export_search_results?query=' + JSON.stringify(query) + "&file_type=" + file_type, requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            return content;
        })
        .catch(sharedService.handleFetchErrorResponse);
}