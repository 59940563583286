import React, { useEffect, useState } from 'react'
import { Image, Row } from 'react-bootstrap'
import guyStanding from '../../../_assets/img/guyStanding.svg'
import chatBubble from '../../../_assets/img/chatBubble.png'

const GuyCharacter = ({ chatQuoteOptions, guyPosition }) => {
  const [chatQuote, setChatQuote] = useState(chatQuoteOptions ? chatQuoteOptions[0] : null)

  useEffect(() => {
    if (chatQuoteOptions) getNextQuote(1)
  }, [])

  const getNextQuote = (n) => {
    if (chatQuoteOptions.length === 1) return;

    setTimeout(() => {
      setChatQuote(chatQuoteOptions[n]);
      if (n + 1 < chatQuoteOptions.length) getNextQuote(n + 1);
    }, 5000);
  }

  return (<div className={guyPosition ? guyPosition : 'guy-position-1'}>
    <Image style={{ width: 200, position: 'absolute', zIndex: 3 }} src={guyStanding} />
    {chatQuote && <div style={{ position: 'absolute', left: 165, top: -50, maxWidth: 370, width: 'max-content' }}>
      <Image className='w-100 position-absolute h-100' style={{ zIndex: 2, left: 0 }} src={chatBubble} />
      <p className='fs-4' style={{ zIndex: 3, position: 'relative', padding: '0px 42px', textAlign: 'center', margin: 'revert' }}>{chatQuote}</p>
    </div>}
  </div>)
}

export default GuyCharacter;