import he from 'he';
import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import ReactTooltip from "react-tooltip";
import stripHtml from "string-strip-html";
import '../../Styles/Algolia.css';
import { favoritesActions } from '../../_actions';
import { history } from '../../_helpers';
import { asyncLocalStorage, listingNotesService, pluginService } from '../../_services';
import { ResultModal_v2 } from './components/ResultModal_v2';

export const unixDate = (timestamp) => {
  var a = new Date(timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ', ' + year;

  return time;
};

// Replace HTML codes with string values for ', ", &, <, >
const convertHTMLText = (str) => {
  if (str) {
    let newStr = str
      .replace(/&apos;/gi, "'")
      .replace(/&#8217;/gi, "'")
      .replace(/&quot;/gi, '"')
      .replace(/&amp;/gi, "&")
      .replace(/&lt;/gi, "<")
      .replace(/&gt;/gi, ">")
    return newStr;
  }
  else {
    return str
  }
}

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <div>
      {parsedHit.map(
        part => (part.isHighlighted ? <mark>{convertHTMLText(part.value)}</mark> : convertHTMLText(part.value))
      )}
    </div>
  );
});

let accentColor = pluginService.getIFrameColor()

class Result_v2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFavorite: false,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      isOpen: false,
      contentOpen: false,

      note: {
        user_notes: "",
        edit: false
      },
      iFrameConfig: null,

    };
    this.togglePanel = this.togglePanel.bind(this);
    this.saveListing = this.saveListing.bind(this);
    // this.trimDescription = this.trimDescription.bind(this);
    this.checkHTML = this.checkHTML.bind(this);
  }

  togglePanel(e) {
    this.setState({ contentOpen: !this.state.contentOpen })
  }

  async componentDidMount() {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      this.setState({ iFrameConfig: value })
    });
    const user_role = this.props.user.userRoles;
    const favorite = localStorage.getItem('favorite');
    // const favorites = localStorage.getItem('favorite');
    // if (!favorite && (user_role === "Client_Premium")) {
    //   favoritesService.getFavorites(this.props.user);
    // }

    // if (this.props.match === undefined) {
    //   const listing = await listingService.getListing(this.props.listing.hit.objectID);
    //   this.setState({
    //     listingViews: listing.views,
    //   })
    // }

    if (user_role !== 'Client_Free' && favorite) {
      this.setState({
        isFavorite: JSON.parse((favorite)).some(item => item.listing_Id == this.props.listing.hit.objectID) ? true : false
      })
    }
  }

  checkHTML(desc) {
    var doc = new DOMParser().parseFromString(desc, "text/html");
    var htmlPresent = Array.from(doc.body.childNodes).some(node => node.nodeType === 1);

    return htmlPresent;
  }

  viewListing = (id) => {

    // for result page view
    // const server = window.location.protocol + '//' + window.location.host;
    // const source_link = server + "/grants/" + id;
    // window.open(source_link, "_blank")

    //for modal view

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  handleSaveListing = (listing_Id, user) => {
    var user = JSON.parse(localStorage.getItem('user'));
    this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
    this.setState({
      favorite: !this.state.favorite
    });
  }

  async saveListing(e) {
    //e.stopPropagation();
    ReactTooltip.hide();

    const listing = this.props.listing.hit;
    const { user } = this.props;
    const add = true;

    this.setState(prevState => ({
      isFavorite: !prevState.isFavorite,
    }));

    this.props.dispatch(favoritesActions.saveListing(listing.objectID, user));

    this.setState(prevState => ({
      searchGroup: {
        ...prevState.searchGroup,
      },
      favorites: JSON.parse(localStorage.getItem('favorite')),
      favoritesCount: add === true ? (prevState.favoritesCount - 1) : (prevState.favoritesCount + 1)
    }), () => { });
  };

  editNote = (val, user_notes) => {
    let note = Object.assign({}, this.state.note);
    if (user_notes) {
      note.user_notes = user_notes;
    }
    this.setState({
      note: {
        ...note,
        edit: val
      }
    });
  }

  saveNote = async () => {

    var save_notes_el = document.getElementById("save_notes");
    save_notes_el.disabled = true;

    var user_notes_el = document.getElementById("user_notes");
    var user_notes = user_notes_el.value;

    const user = JSON.parse(localStorage.getItem('user'));

    await listingNotesService.saveListingNotes(user.id, this.props.listing.hit.objectID, user_notes);
    await this.props.dispatch(favoritesActions.getFavorites(this.props.user));

    // if(!this.state.isFavorite){
    //   this.saveListing();
    // }

    if (!this.state.isFavorite) {
      this.setState({
        isFavorite: !this.state.isFavorite,
      });
    }

    save_notes_el.disabled = false;
    this.editNote(false, user_notes);
  }

  deleteNotes = async () => {
    this.setState({
      loading: true
    });
    const user = JSON.parse(localStorage.getItem("user"));
    await listingNotesService.deleteListingNotes(user.id, this.props.listing.hit.objectID);

    this.setState({
      note: {
        user_notes: "",
        edit: false
      }
    })
  }

  applyNow() {
    history.push('/marketplace');
  };

  render() {
    const listing = this.props.listing.hit;
    const listingId = listing.objectID;
    const funding = listing.funding_Amount;
    const app_due_date = listing.application_Due_Date;
    const normalized_due_date = unixDate(app_due_date);
    const description = stripHtml(listing.description, {
      stripTogetherWithTheirContents: [
        'script', // default
        'style',  // default
        'xml',    // default
        'pre', // <-- custom-added
      ]
    }
    ).result;

    const { isOpen, isFavorite, note } = this.state;

    return (
      <>
        <div>
          <ReactTooltip effect="solid" />
          <div className="row listingHeader noPadding">
            <div className="col-md-8 titleColumn noPadding">
              <div className="result-title">
                <CustomHighlight attribute="title" hit={listing} />
              </div>
            </div>
            <div className="col-md-4 viewGrantColumn">
              <div className="col-md-9 noPadding">
                <button
                  onClick={() => this.viewListing(listing.objectID)}
                  className="viewGrantBtn"
                  style={{ color: accentColor || '#0c7069' }}
                >
                  <p>View Grant</p>
                </button>
              </div>
              <div className="col-md-3 noPadding">
                <button
                  onClick={(e) => this.togglePanel(e)}
                  className="dropDownBtn"
                  type="button"
                >
                  {this.state.contentOpen ? <i className="icon-up-open" /> : <i className="icon-down-open" />}
                </button>
              </div>
            </div>
          </div>
          {/* <div className="row">
            {listing.listing_Tags &&
              listing.listing_Tags.map(function (tag) {
                return (
                  <div className="listing_tags">
                    <p className="listing_tags-p">{tag.tag != null ? tag.tag : tag.category_Tag}</p>
                  </div>
                );
              })
            }
            {(listing.listing_Tags || !listing.listing_Tags) &&
              <div className="listing_tags">
                <p className="listing_tags-p">General Funding Opportunities</p>
              </div>
            }
          </div> */}
          {/* DROPDOWN COLLAPSE */}
          {this.state.contentOpen ?
            <>
              <p className="dropDown-title">{listing.agency}</p>
              <div className="dropDownPrice">
                {/* <Highlight attribute="description" hit={listing} /> */}
                {(listing.funding_Amount !== null && listing.funding_Amount > 0) &&
                  <NumberFormat value={listing.funding_Amount} displayType={'text'} thousandSeparator={true} prefix={'$'} style={{ color: accentColor || '#0c7069' }} />
                }
                {(funding === 0 || !funding) &&
                  <p style={{ color: accentColor || '#0c7069' }}>Funding varies.</p>
                }
              </div>
              <div className="dropDownDue">
                {/* <Highlight attribute="description" hit={listing} /> */}
                {app_due_date && <p><strong>Due: </strong>{normalized_due_date}</p>}
                {!app_due_date && <p><strong>Due: </strong>Applications ongoing.</p>}
              </div>
              <br />
              <div className="dropDownSummaryTitle">
                <p><strong>Summary: </strong></p>
              </div>
              <div className="dropDown-description">
                <TextTruncate
                  line={3}
                  element="class"
                  truncateText="…"
                  text={he.decode(description.trim())}
                  style={{ color: "#333", fontSize: 14, width: '95%' }}
                />
              </div>
              <hr />
            </>
            : null}
        </div>
        {/* MODAL */}
        {isOpen && <ResultModal_v2
          isOpen={isOpen}
          isFavorite={isFavorite}
          listing={listing}
          listingId={listingId}
          viewListing={this.viewListing}
          saveListing={this.saveListing}
          normalized_due_date={normalized_due_date}
          checkHTML={this.checkHTML}
          applyNow={this.applyNow}
          note={note}
          editNote={this.editNote}
          saveNote={this.saveNote}
          deleteNotes={this.deleteNotes}
          iFrameConfig={this.state.iFrameConfig}
        />}
      </>
    );
  }
  //              <div className="hit-title">
  //                <CustomHighlight attribute="title" hit={listing} />
  //                <TextTruncate
  //                  line={2}
  //                  element="class"
  //                  truncateText="…"
  //                  text={he.decode(listing.title)}
  //                  style={{fontWeight: 900}}
  //                />
  //              </div>

  //              {/* AGENCY OUTPUT */}
  //              <div className="hit-agency" style={{marginTop:5}}>
  //                <CustomHighlight attribute="description" hit={listing} />
  //                {listing.agency}
  //              </div>
  //              <hr />

  //              {/* DESCRIPTION OUTPUT */}
  //              <TextTruncate
  //                  line={3}
  //                  element="class"
  //                  truncateText="…"
  //                  text={he.decode(description.trim())}
  //                  style={{color: "#333", fontSize:14}}
  //              />

  //              {/* FUNDING AMOUNT OUTPUT */}
  //              <br /><br /><br />
  //              <div className="hit-funding">
  //                <Highlight attribute="description" hit={listing} />
  //                {(listing.funding_Amount != null && listing.funding_Amount > 0) &&
  //                  <NumberFormat value={listing.funding_Amount} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={" USD"}/>
  //                }
  //                {(funding == 0 || !funding) && 
  //                  <p>Funding varies.</p>
  //                }
  //              </div>

  //              {/* DUE DATE OUTPUT */}
  //              <div className="hit-description">
  //                <Highlight attribute="description" hit={listing} />
  //                {app_due_date && <p><strong>Due: </strong>{normalized_due_date}</p>}
  //                {!app_due_date && <p>Applications ongoing.</p>}
  //              </div>
  //              <hr />

  //              {/* CATEGORY OUTPUT */}
  //              <div className="hit-category">
  //                 <Button color={"#29a"} onClick={() => {viewListing(listing.objectID)}}>
  //                     View Grant
  //                     <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
  //                 </Button>
  //              </div>
  //            </div>
  //          );
  //        }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedResult = connect(mapStateToProps)(Result_v2);
export { connectedResult as Result_v2 };
