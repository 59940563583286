import React from 'react';
import { Form, Button, FormCheck } from 'react-bootstrap'

const SamRegistration = ({ form, boxCheck, handleNext, handleBack }) => {
  return (
    <>
      <Form className='w-100 mt-48'>         
        <p className='fw-bold'>Are you registered for SAM?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input 
              checked={form.isSamRegistered}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isSamRegistered'
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.isSamRegistered}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isSamRegistered'
              id={false}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
        <p className='mt-40 fw-bold'>Are you an 8A Business Development?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input
              checked={form.is8A}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name="is8A"
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.is8A}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name="is8A"
              id={false}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
        <p className='mt-40 fw-bold'>Are you a woman-owned business?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input
              checked={form.isWomanOwned}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name="isWomanOwned"
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.isWomanOwned}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name="isWomanOwned"
              id={false}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
        <p className='mt-40 fw-bold'>Are you a certified veteran-owned business?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input
              checked={form.isVeteranOwned}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isVeteranOwned'
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.isVeteranOwned}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isVeteranOwned'
              id={false}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
        <p className='mt-40 fw-bold'>Are you located in a Hubzone?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input
              checked={form.isInHubzone}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isInHubzone'
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.isInHubzone}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isInHubzone'
              id={false}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
        <p className='mt-40 fw-bold'>Are you a small business?</p>
        <div className='mt-24 d-flex align-items-center'>
          <FormCheck className='ms-24 me-40'>
            <FormCheck.Input
              checked={form.isSmallBusiness}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isSmallBusiness'
              id={true}
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>Yes</FormCheck.Label>
          </FormCheck>
          <FormCheck className=''>
            <FormCheck.Input 
              checked={!form.isSmallBusiness}
              type="radio"
              style={{ height: '1.75rem', width: '1.75rem' }}
              name='isSmallBusiness'
              onChange={boxCheck}
            />
            <FormCheck.Label className='mb-0 mt-4'>No</FormCheck.Label>
          </FormCheck>
        </div>
      </Form>
      <div className='mt-80 px-xl-168 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default SamRegistration