import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import '../../Styles/SavedListings.css';
import { upgradeActions } from '../../_actions';
import { favoritesService, sharedService } from '../../_services';
import { Loader } from '../Shared/Loader';
import { NoFavorites } from './NoFavorites';
import ReactTooltip from 'react-tooltip';
import { GrantList } from '../Grants/GrantList';

export const Favorites_v2 = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.setUser);
    const [loading, setLoading] = useState(true);
    const [favorites, setFavorites] = useState([]);
    const [isExportOptionsOpen, setIsExportOptionsOpen] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        await Promise.all([
            getFavorites()
        ]);
        setLoading(false);
    }

    const getFavorites = async () => {
        const favorites = await favoritesService.getFavorites(user);

        setFavorites(favorites);
    }

    const updateFavorites = async (newFavorites) => {
        setFavorites(newFavorites);
    }

    const updateGrantMatches = (type, grant) => {
        let newGrantMatches = [];
        favorites.map(grantMatch => {
            if (type === 'listings') {
                if (grant.listing_Id == grantMatch.listing_Id) {
                    grantMatch = grant;
                }
            }
            if (type === 'funders') {
                if (grant.id == grantMatch.id) {
                    grantMatch = grant;
                }
            }

            newGrantMatches.push(grantMatch);
        });

        setFavorites(newGrantMatches);
    }

    const exportFavorites = async (file_type) => {
        var user = JSON.parse(localStorage.getItem('user'));
        var blob = await favoritesService.exportFavorites(user.id, file_type);
        const file = window.URL.createObjectURL(blob);

        var aTag = document.createElement("a");
        if (file_type == "csv") {
            aTag.download = "FavoriteListings.csv"
        } else {
            aTag.download = "FavoriteListings.xlsx"
        }
        aTag.href = file;
        aTag.target = "_blank";

        aTag.click();
    }

    return (<>
        {loading && <Loader />}
        <div className="row" id="searchListingsSearchBar" style={{ margin: 0 }}>
            <div className="row mb-48 flex-column-reverse flex-sm-row">
                <div className="col-sm-6 h1div">
                    <h1 style={{ margin: 0 }} id="TalentFinderHeader">Saved Grants</h1>
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                    <Dropdown className="exportFavorites" isOpen={isExportOptionsOpen} toggle={() => setIsExportOptionsOpen(!isExportOptionsOpen)}>
                        <DropdownToggle variant="success" id="dropdown-basic">
                            Export Saved Grants<i className="icon-export" />
                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem onClick={() => exportFavorites("excel")}>Excel</DropdownItem>
                            <DropdownItem onClick={() => exportFavorites("csv")}>CSV</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <br />
            <p className="grantConsultantHeader" style={{ fontSize: '15px', color: '#0C7069', padding: '0.5% 0' }}>
                No. Favorites:<span style={{ marginLeft: '1%' }}> {favorites?.length || 0}</span>
            </p>
            <hr style={{ margin: 0 }} />
            <br />
            {favorites?.length > 0 &&
                <div id="hasFavorites">
                    <br />
                    <GrantList
                        props={props}
                        user={user}
                        listings={favorites}
                        updateGrantMatches={updateGrantMatches}
                        favorites={favorites}
                        updateFavorites={updateFavorites}
                        showDismiss={true} />
                </div>}
            {!favorites?.length > 0 &&
                <NoFavorites />
            }
        </div>
    </>);
}

