import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../../../../Styles/Organization.css';
import '../../../../Styles/SettingsStyles/Profile.css';
import Button from "../../../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../../../_helpers/history.js';
import { organizationService, pluginService } from '../../../../_services';
import { Loader } from '../../../Shared/Loader';
import { Row } from 'react-bootstrap';

class OrganizationInvitationList extends React.Component {
    state = {
        pending_invitations: [],
        displayPopup: false,
        invitation_agreement: false,
        loading: true,
        errors: {}
    }

    componentDidMount = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        var pending_invitations = await organizationService.getUserOrganizationPendingInvitations(user.id);

        this.setState({
            pending_invitations,
            loading: false
        });
    }

    confirmOrganizationInvitation = async (organization_id, user_id, confirmation) => {
        this.setState({ loading: true });
        //get invitation
        const pending_invitation = await organizationService.getOrganizationInvitation(organization_id, user_id);

        //display finance info popup
        if (confirmation === "Accepted" && pending_invitation.user_type === "grant_consultant" //org is a service provider
        ) {
            var user_org = await organizationService.getFirstOrganizationByUser(user_id);
            if (!user_org) { //org is user first org
                this.setState({
                    displayPopup: true,
                    confirmation: {
                        organization_id,
                        user_id,
                        confirmation,
                        pending_invitation: pending_invitation.invitation
                    },
                    loading: false
                });
                return;
            }
        }

        this.acceptInvitation(organization_id, user_id, confirmation);
    }

    acceptInvitation = async (organization_id, user_id, confirmation) => {
        const { displayPopup, invitation_agreement } = this.state;

        //agreed to invitation terms
        if (displayPopup) {
            //verify agreement
            if (!invitation_agreement) {
                this.setState({ errors: { invitation_agreement: true, loading: false } })
                return;
            }

            organization_id = this.state.confirmation.organization_id;
            user_id = this.state.confirmation.user_id;
            confirmation = this.state.confirmation.confirmation;
        }

        let pending_invitation = await organizationService.confirmOrganizationInvitation(organization_id, user_id, confirmation);

        //confirm invitation
        if (pending_invitation) {
            const { pending_invitations } = this.state;
            pending_invitation.organization = pending_invitation.organization.organization;
            var new_pending_invitations = pending_invitations.filter(invitation => invitation.organization_Id != organization_id);
            new_pending_invitations.push(pending_invitation);

            if (this.props.addToOrganizationList) {
                this.props.addToOrganizationList(pending_invitation.organization);
            }

            this.setState({
                pending_invitations: [...new_pending_invitations],
                displayPopup: false,
                loading: false
            });
        } else {
            if (displayPopup) {
                this.setState({
                    displayPopup: false,
                    loading: false
                });
            } else {
                this.setState({ loading: false })
            }
        }
    }

    displayPopup = () => {
        this.setState({ displayPopup: !this.state.displayPopup });
    }

    handleAgreementChange = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked
        })
    }

    truncate = (text) => {
        if (!text) {
            return;
        }
        return text.length > 150 ? text.substring(0, 147) + "..." : text;
    };

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const { pending_invitations, displayPopup, invitation_agreement, errors, confirmation, loading } = this.state;

        var pending_invitation_list = null;

        if (pending_invitations) {
            pending_invitation_list = pending_invitations.map((invitation) => {
                return (
                    <div key={invitation.organization.name}>
                        <div className='org-invitation-item-div'>

                            {/* Logo */}
                            <div className="col-md-2" style={{ flex: 1, justifyContent: "center" }}>
                                <img className='org-invitation-item-img' alt="organization logo" src={invitation.organization.logo_URL ? invitation.organization.logo_URL : "https://img.icons8.com/bubbles/2x/company.png"} />
                            </div>

                            {/* Name and Description */}
                            <div className="col-md-6" style={{ flex: 3, width: "100%" }}>
                                <a href={pluginService.redirect("/organizations/" + (invitation.organization_Id ? invitation.organization_Id : invitation.organization.organization_Id))} target={pluginService.isIFrame() ? "_self" : "_blank"}>
                                    <h4 className='org-invitation-item-name'>{invitation.organization.name}</h4>
                                </a>
                                <p className='org-invitation-item-description'>{this.truncate(invitation.organization.description)}</p>
                            </div>

                            {/* Accept or Reject Buttons */}

                            {!invitation.confirmation &&
                                <div className="col-md-4 org-invitation-item-buttons" style={{ display: "flex", flex: 2 }}>

                                    <button
                                        className='org-invitation-btn org-invitation-accept'
                                        onClick={() => { this.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Accepted") }}
                                    >
                                        Accept
                                    </button>

                                    <button
                                        className='org-invitation-btn org-invitation-reject'
                                        onClick={() => { this.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Rejected") }}
                                    >
                                        Reject
                                    </button>

                                </div>
                            }
                            {invitation.confirmation &&
                                <div className="col-md-4" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className='org-invitation-btn org-invitation-confirm'>{invitation.confirmation}</button>
                                </div>
                            }

                        </div>
                        <hr style={{ borderBottom: 0 }} />
                    </div>

                );
            });
        }

        var pending_total = pending_invitations ? pending_invitations.filter(invitation => !invitation.confirmation).length : 0;

        return (
            <div className='org-invitation-container'>
                {loading && <Loader />}

                {/* ORGNAIZATION INVITATIONS */}
                {((this.props.hideOrganization && pending_total > 0) || !this.props.hideOrganization) &&
                    <>
                        <hr />
                        <p className='org-invitation-helper-text'>The following organization(s) invited you to join their organization(s). Accepting the invitation will let you connect your personal profile to their organization. You will also be able to edit the organization.</p>
                        <p className='org-invitation-pending-text'><strong>{pending_total}</strong> Pending Invitation(s)</p>
                        {pending_invitation_list}
                    </>
                }


                {/* SERVICE PROVIDER ACCEPTANCE INFO */}
                {displayPopup &&
                    <Modal
                        isOpen={displayPopup}
                        centered={true}
                        backdrop={true}
                        toggle={this.displayPopup}
                        fade={false}
                        size='lg'
                    >
                        <ModalBody>
                            <div style={{ padding: '30px 30px 0 30px' }}>
                                <p>By joining { }'s organization as a Grant Consultant,
                                    we assume that you work for that organization and agree that
                                    <b> all money earned from your work will be sent to { }'s Stripe account. </b>
                                </p>
                                <p style={{ marginBottom: "5%" }}>Please click "I would like my own Stripe Account" if you'd like these funds to be sent to your own Stripe account instead.</p>
                                <div style={{ marginBottom: "5%" }}>
                                    <input checked={invitation_agreement} name="invitation_agreement" type="checkbox" onChange={this.handleAgreementChange} />
                                    <label style={{ display: "inline-block", fontSize: 16 }}>I agree</label>
                                    {errors.invitation_agreement && <p style={{ color: "red" }}>This is required for accepting</p>}
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter className='border-0' style={{ padding: '0 30px 30px 30px' }}>
                            <Row className='w-100 justify-content-end'>
                                <div className='col-auto'>
                                    <Button color="info" onClick={this.acceptInvitation} style={{ marginRight: "5%" }}>Accept</Button>

                                </div>
                                <div className='col-auto'>
                                    <Button color="info" onClick={() => history.push('/service-provider-onboarding')} style={{ whiteSpace: "pre-wrap" }}>I would like my own Stripe Account</Button>
                                </div>
                            </Row>
                        </ModalFooter>
                    </Modal>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedOrganizationInvitationList = connect(mapStateToProps)(OrganizationInvitationList);
export { connectedOrganizationInvitationList as OrganizationInvitationList };
