import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import '../../../Styles/Organization.css';
import { organizationService } from '../../../_services';

class EditOrganizationTechnologies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            technologies_Used: {},
            techMain: {},
            currentOptions: [],
            selectedMain: "",
            selectedSub: "",
        }
    }

    async componentDidMount() {

        await this.getTechnologyOptions();
        this.getExistingTechnologies();
    }

    //retrieve technology categories from backend and set state
    getTechnologyOptions = async () => {
        const { user } = this.props;
        const technologies_Used = await organizationService.getTechnologies(user.id);

        this.setState({
            techMain: this.generateOptions(technologies_Used)
        });
    }

    getExistingTechnologies = () => {
        const existingSubCats = this.generateOptions(this.props.org.technologies_Used)

        this.setState({
            currentOptions: existingSubCats,
        })
    }

    generateOptions = (optionsArray) => {
        if (!optionsArray) return [];
        return optionsArray.map((tech) => ({ value: tech, label: tech }))
    }

    handleMainTechChange = (e) => {

        this.props.handleCreatableSelection(e, "technologies_Used");

        this.setState({

            selectedMain: e && e.value ? e.value : "",

        }, () => {

            let formattedSelected = this.generateOptions(this.state.techMain)

            this.setState({
                currentOptions: [...this.state.currentOptions, ...formattedSelected]
            })
        })
    }

    //handle input for custom technology tag
    addOption = (e, categoryType) => {
        let temp = { label: e, value: e }

        this.props.handleCreatableSelection(temp, categoryType);
    }

    render() {
        const { techMain } = this.state;
        const { org } = this.props

        // generate sub category tags 
        const mainTags = org.technologies_Used?.map((category) => {
            return (
                <div key={"tags_" + category} className="categoryTag">
                    {category}
                    <div className="removeTag" onClick={() => { this.props.handleCreatableRemoval(category, false, "technologies_Used") }}>X</div>
                </div>
            )
        });

        const customStyles = {
            control: (provided) => ({ //input box
                ...provided,
                height: 42,
                border: "1px solid #c0c0c0",
                borderRadius: 5,
            }),
            dropdownIndicator: (provided) => ({ //down arrow
                ...provided,
                display: "none"
            }),
            valueContainer: (provided) => ({ //input text
                ...provided,
                paddingLeft: 5,
                fontSize: 15,
                fontFamily: "Lato",
                fontWeight: "normal",
            }),
            option: (provided) => ({ //single option
                ...provided,
                padding: "10px 20px",
                fontSize: 15,
                fontFamily: "Lato",
                fontWeight: "normal",
                borderBottom: "1px solid lightgrey",
            })
        }

        return (
            <>
                <p className="inputFieldSubLabel" style={{ marginBottom: 15 }}>
                    Please select the technologies that best fit your organization.
                    This information helps OpenGrants match you to new grant funding opportunities as they become available.
                </p>

                {/* Main Tech Categories */}

                <CreatableSelect
                    isClearable={false}
                    placeholder="Start typing to select..."
                    options={techMain}
                    onChange={(e) => { this.handleMainTechChange(e) }}
                    onCreateOption={(e) => { this.addOption(e, "technologies_Used") }}
                    clearValue={() => { this.setState({ currentOptions: [], selectedMain: "" }) }}
                    styles={customStyles}
                    blurInputOnSelect={true}
                />

                {(mainTags?.length > 0) &&
                    <div className="align-left">
                        <a id="clearAll" className="showClearAll" onClick={() => { this.props.handleCreatableRemoval("", true, "technologies_Used") }}>Clear</a>
                        <div id="tags">{mainTags}</div>
                    </div>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationTechnologies = connect(mapStateToProps)(EditOrganizationTechnologies);
export { connectedEditOrganizationTechnologies as EditOrganizationTechnologies };
