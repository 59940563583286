import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { Previews } from "../../Onboarding/GC_Image_Upload";

class GC_Onboarding_OrganizationAttributes2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
    }
  }
  render() {
    const { updateNav, new_Organization, setOrgLogo, handleChange } = this.props;
    const { isNotValid } = this.state;

    const nextPage = () => {
      this.setState({
        isNotValid: true
      });
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor" &&
        new_Organization.organization.logo_URL &&
        new_Organization.organization.description
      ) {
        this.setState({
          isNotValid: false
        });
        updateNav("onboard_businessAddress")
      }
      if (
        new_Organization.organization.logo_URL
        &&
        new_Organization.organization.website_URL
        &&
        new_Organization.organization.description
      ) {
        this.setState({
          isNotValid: false
        });
        updateNav("onboard_businessAddress")
      }
    }

    const removePreview = () => {
      this.setState({
        imageError: "",
        show_Logo: false,
      })
    }

    // const fillOutLater = () => {
    //   if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
    //     return (
    //       <>
    //         <div className="row center">
    //           <button
    //             className="gc_started-button-fillLater"
    //             onClick={() => updateNav("onboard_businessAddress")}
    //           >
    //             Fill Out Later
    //           </button>
    //           <br />
    //         </div>
    //       </>
    //     )
    //   } else {
    //     return null;
    //   }
    // }

    // LOGO_URL
    const isRequiredLogo_URL_Message = () => {
      return (
        <>
          {isNotValid && !new_Organization.organization.logo_URL && (
            <p className="red-message">
              You must upload a {new_Organization.organization.org_Type === "Individual/Sole Proprietor" ? "Profile Picture" : "Company Logo"}
            </p>
          )}
        </>
      )
    }
    const isRequiredLogo_URL_Star = () => {
      return (
        <>
          <span className="red-star">*</span>
        </>
      )
    }

    // Website URL
    const isRequiredWebsite_URL_Message = () => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        return null;
      } else {
        return (
          <>
            {isNotValid && !new_Organization.organization.website_URL && (
              <p className="red-message">
                You must designate a website or url.
              </p>
            )}
          </>
        )
      }
    }
    const isRequiredWebsite_URL_Star = () => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        return null;
      } else {
        return (
          <>
            <span className="red-star">*</span>
          </>
        )
      }
    }

    // description
    const isRequiredDescription_Message = () => {
      return (
        <>
          {isNotValid && !new_Organization.organization.description && (
            <p className="red-message">
              You must provide a statement of qualifications.
            </p>
          )}
        </>
      )
    }
    const isRequiredDescription_Star = () => {
      return (
        <>
          <span className="red-star">*</span>
        </>
      )
    }
    const imageUploadFailed = (msg) => {
      this.setState({
        imageError: msg,
        show_Logo: false,
      })

      setTimeout(() => {
        this.setState({
          imageError: "",
        })
      }, 6000);
    }

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ? "5 of 8" : "3 of 8"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          {(new_Organization.organization.org_Type === "Individual/Sole Proprietor") ?
            <div className="col-md-10" >
              <div className="col-md-7 progress-color" />
              <div className="col-md-5 gray-progress-color" />
            </div> :
            <div className="col-md-10" >
              <div className="col-md-5 progress-color" />
              <div className="col-md-7 gray-progress-color" />
            </div>
          }
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header" >Organization Attributes</h1>
            <hr />
            <div className="row">
              <div className="col-md-4 gc_onboarding-form-col">
                <p className="gc_onboarding-form-input-name-top">
                  {new_Organization.organization.org_Type === "Individual/Sole Proprietor" ? "Profile Picture" : "Company Logo"}
                  {isRequiredLogo_URL_Star()}
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  500px X 500px
                  {isRequiredLogo_URL_Message()}
                </p>
              </div>
              <div className="col-md-4 gc_onboarding-form-col-upload">
                <input
                  type="hidden"
                  name="headshot"
                  id="organization-standard-basic"
                  value={new_Organization.organization.logo_URL}
                />
                <div id="profileImage">
                  <Previews
                    removePreview={removePreview}
                    setOrgLogo={setOrgLogo}
                    imageUploadFailed={imageUploadFailed}
                    files={new_Organization.organization.logo_file ? [new_Organization.organization.logo_file] : []}
                    imageUrl={new_Organization.organization.logo_URL}
                  />
                </div>
                {/* {!new_Organization.organization.logo_file && new_Organization.organization.logo_URL ? <img width="50%" src={new_Organization.organization.logo_URL} alt='' /> : null} */}
                <div>
                  {this.state.imageError &&
                    <p className="red-message" style={{ textAlign: "center" }}>{this.state.imageError}</p>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <p className="gc_onboarding-form-input-name-top">
                Website
                {isRequiredWebsite_URL_Star()}
              </p>
              <input
                className="gc_onboarding-form-input-lg"
                type="text"
                id="organization-standard-basic"
                name="website_URL"
                value={new_Organization.organization.website_URL}
                onChange={handleChange}
              />
              {isRequiredWebsite_URL_Message()}
            </div>
            <div id="service-provider-onboarding-textarea">
              <div style={{ marginBottom: 10 }}>
                <p className="gc_onboarding-form-input-name-top">
                  Statement of Qualifications
                  {isRequiredDescription_Star()}
                </p>
              </div>
              <textarea
                className="gc_onboarding-form-input-lg"
                type="text"
                name="description"
                id="organization-standard-basic"
                value={new_Organization.organization.description}
                onChange={handleChange}
                multiline
                rowsMax="20"
                rows={10}
              />
              {isRequiredDescription_Message()}
            </div>
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_organizationAttributes1")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={() => nextPage()}
              >
                Next <i class="icon-arrow-right" />
              </button>
            </div>
            {/* {fillOutLater()} */}
            {/* {isNotValid && (
              <p style={{ marginTop: 30, color: "red" }}>
                There are errors in your form. Please correct all errors shown in
                red above, then click Next again.
              </p>
            )} */}
          </div>
          {/* {fillOutLater()} */}
          {isNotValid && (
            <p style={{ marginTop: 30, color: "red" }}>
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGC_Onboarding_OrganizationAttributes2 =
  connect(mapStateToProps)(GC_Onboarding_OrganizationAttributes2);
export { connectedGC_Onboarding_OrganizationAttributes2 as GC_Onboarding_OrganizationAttributes2 };

