import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { pluginService } from "../../../../_services";
import { Previews } from "../GC_Image_Upload";

let accentColor = pluginService.getIFrameColor()

class GS_Onboarding_OrganizationAttributes2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      imageError: ""
    }
  }

  render() {
    const {
      updateNav,
      new_Organization,
      setOrgLogo,
      handleChange,
    } = this.props;

    const imageUploadFailed = (msg) => {
      this.setState({
        imageError: msg,
        show_Logo: false,
      })

      setTimeout(() => {
        this.setState({
          imageError: "",
        })
      }, 6000);
    }

    const removePreview = () => {
      this.setState({
        imageError: "",
        show_Logo: false,
      })
    }

    //is creating org
    const path = window.location.pathname;
    let pathSplit = path.split('/').pop();

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            {(pathSplit !== "org" && !this.props.creatingOrg) && <p className="gc_onboarding-form-input-name-top"> 3 of 6 </p>}
            {(pathSplit === "org" || this.props.creatingOrg) && <p className="gc_onboarding-form-input-name-top"> 2 of 5 </p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10" >
            <div className="col-md-6 progress-color" style={{ backgroundColor: accentColor || '#00C58E' }} />
            <div className="col-md-6 gray-progress-color" />
          </div>
        </div>
        <form onSubmit={e => { e.preventDefault() }}>
          <div className="gc_onboarding-form-card">
            <h1 className="onboarding-form-header">Organization Attributes</h1>
            <hr />
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-md-4 gc_onboarding-form-col">
                <p className="gc_onboarding-form-input-name-top">
                  {new_Organization.organization.org_Type === "Individual/Sole Proprietor" ? "Profile Picture" : "Company Logo"}
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  500px X 500px
                </p>
              </div>
              <div className="col-md-4 gc_onboarding-form-col-upload">
                <input
                  type="hidden"
                  name="headshot"
                  id="organization-standard-basic"
                  value={new_Organization.organization.logo_URL}
                />
                <div id="profileImage">
                  <Previews
                    removePreview={removePreview}
                    setOrgLogo={setOrgLogo}
                    imageUploadFailed={imageUploadFailed}
                    files={new_Organization.organization.logo_file ? [new_Organization.organization.logo_file] : []}
                    imageUrl={new_Organization.organization.logo_URL}
                  />
                </div>
                <div>
                  {this.state.imageError &&
                    <p className="red-message" style={{ textAlign: "center" }}>{this.state.imageError}</p>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <p className="gc_onboarding-form-input-name-top">
                Website
              </p>
              <input
                className="gc_onboarding-form-input-lg"
                type="text"
                name="website_URL"
                id="organization-standard-basic"
                value={new_Organization.organization.website_URL}
                onChange={handleChange}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
            </div>
            <div id="service-provider-onboarding-textarea">
              <div>
                <p className="gc_onboarding-form-input-name-top">
                  Organization Description
                </p>
              </div>
              <textarea
                className="gc_onboarding-form-input-lg"
                type="text"
                name="description"
                id="organization-standard-basic"
                value={new_Organization.organization.description} // used in 2 different pages
                onChange={handleChange}
                multiline
                rowsMax="20"
                rows={10}
              />
            </div>
            <div id="service-provider-onboarding-textarea">
              <div>
                <p className="gc_onboarding-form-input-name-top">
                  Organization Grant Matching Description
                </p>
                <p className="gc_onboarding-form-input-name-top-secondary">
                  Describe the organization or project that you want to fund. Be as 
                  detailed as possible and use keywords that will help us match you 
                  with relevant grants, like your industry, technology, and impact 
                  focus. Describe the work you want to accomplish.
                </p>
              </div>
              <textarea
                className="gc_onboarding-form-input-lg"
                type="text"
                name="grant_Matching_Description"
                id="organization-standard-basic"
                value={new_Organization.organization.grant_Matching_Description} // used in 2 different pages
                onChange={handleChange}
                multiline
                rowsMax="20"
                rows={10}
              />
            </div>
            <hr />
            <div className="row mobileBtnRow">
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-back"
                  onClick={() => updateNav("onboard_organizationAttributes1")}
                  style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                >
                  Back <i class="icon-arrow-left" />
                </button>
              </div>
              <div className="col-md-6 gc_onboarding-form-col">
                <button
                  className="gc_started-button-next"
                  onClick={() => updateNav("onboard_businessAddress")}
                  style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                >
                  Next <i class="icon-arrow-right" />
                </button>
              </div>
            </div>
            <div className="row center">
              <button
                className="gc_started-button-fillLater"
                onClick={() => updateNav("onboard_businessAddress")}
                style={{ color: accentColor || '#0C7069' }}
              >
                Fill Out Later
              </button>
              <br />
            </div>
          </div>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedGS_Onboarding_OrganizationAttributes2 =
  connect(mapStateToProps)(GS_Onboarding_OrganizationAttributes2);
export { connectedGS_Onboarding_OrganizationAttributes2 as GS_Onboarding_OrganizationAttributes2 };

