import { API_Route } from '../../App/API_Route';
import { authHeader } from '../../_helpers';
import { sharedService } from '../shared.service';

export const funderNotesService = {
    getFunderNotes,
    saveFunderNotes,
    deleteFunderNotes
};

function getFunderNotes(user_id, funder_Id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/listingsnotes/funder?user_id=' + user_id + '&funder_Id=' + funder_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function saveFunderNotes(user_id, funder_Id, notes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(notes)
    };

    return fetch(API_Route + '/api/listingsnotes/funder?user_id=' + user_id + '&funder_Id=' + funder_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function deleteFunderNotes(user_id, funder_Id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/listingsnotes/funder?user_id=' + user_id + '&funder_Id=' + funder_Id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}