import React from 'react';
import { NavMenu } from '../_pages/Shared/NavMenu/NavMenu_v2';
import { Redirect, Route } from 'react-router-dom';
import { authenticateActions } from '../_actions';

export const NLPrivateRoute = ({ component: Component, props: Props, ...rest }) => (
  <Route {...rest} render={props => {
    const userCookie = document.cookie.split(';').filter(c => c.includes('user='));
    if (userCookie.length > 0) {
      if (!localStorage.getItem('user')) {
        const user = userCookie[0].split('=')[1];
        Props.props.dispatch(authenticateActions.setUser(JSON.parse(user)));
        localStorage.setItem('user', user);
      }

      document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.opengrants.io";
    }

    return (
      localStorage.getItem('user') && JSON.parse(localStorage.getItem("user")).token
        ?
        <>
          <NavMenu />
          <Component {...props} />
        </>
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

    )
  }} />
)