import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Messenger_v2 } from '../..';
import '../../../../Styles/GrantWriters.css';
import "../../../../Styles/MarketplaceProject.css";
import { marketplaceprojectActions, serviceProviderActions } from '../../../../_actions';
import { history } from '../../../../_helpers';
import { pluginService, serviceProviderService, sharedService } from '../../../../_services';
import { InvoicesHours } from '../../../InvoicesHours/InvoicesHours';
import { ViewListing } from '../../../ProjectFinder';
import { ServiceProvidersProjectDetails_v2 } from '../../../ServiceProviders';
import { ServiceProvidersFinishProject_v2 } from '../../../ServiceProviders/ServiceProviderProjects_v2/Components';
import { Loader as Loading } from '../../../Shared/Loader/Loader';
import ProjectDocuments from './ProjectDocuments';

let isMpProject = false;
let isFlatRateMpProject = false;
let project_Type;

function TabPanel(props) {
  const { children, value, type, index, ...other } = props;

  // if (value == 2) {
  // if (type === "service_Provider" && isMpProject) {

  //   if (isFlatRateMpProject) {

  //   }
  //   else {
  //     history.push(window.location.pathname + "?val=" + props.project_Id + "&type=mp"); //redirect to specific mp project
  //   }
  // }
  // else if (type === "service_Provider") {
  //   history.push(window.location.pathname + "?val=" + props.project_Id + "&type=sp"); //redirect to specific sp project
  // }
  // else {
  //   history.push('/invoices-hours')
  // }
  // }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

let accentColor = pluginService.getIFrameColor()

class MarketplaceProject_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      conversation: null,

      channels: [],
      channel: null,

      loading: true,
      requested_sp: false,

      service_Provider: {},
      mp_service_Provider: {},
    }
  }

  conversationsClient = "";

  componentDidMount = async () => {
    const query = new URLSearchParams(history.location?.search);
    project_Type = query.get('type') ? query.get('type') : "";
    const active_Tab = query.get("tab") ? query.get("tab") : "";

    let active_Tab_Val = 0;

    if (active_Tab) {
      if (active_Tab === "messages") {
        active_Tab_Val = 1
      }
      if (active_Tab === "hoursInvoices") {
        active_Tab_Val = 2
      }
      if (active_Tab === "documents") {
        active_Tab_Val = 3
      }
    }

    const project_Id = window.location.href.substr(window.location.href.lastIndexOf('/') + 1).split('?')[0];

    const { user } = this.props;

    if (project_Type === "mpProject") {
      this.props.dispatch(marketplaceprojectActions.getProject(project_Id, ['documents', 'applications', 'client organization', 'segments']))
    }
    else if (project_Type === "fixedProduct") {
      this.props.dispatch(marketplaceprojectActions.getProject(project_Id, ['client organization']))
    }
    else {
      this.props.dispatch(serviceProviderActions.getProject(project_Id, user.id));
    }

    this.setState({
      loading: false,
      active_Tab_Val
    });
  }

  componentDidUpdate = async (prevState, prevProps) => {

    if (!this.state.requested_sp) {
      if (this.props.project && this.props.type === 'client') { //get sp for client
        const service_Provider = await serviceProviderService.getServiceProviderById(this.props.project.project.service_Provider_Id);
        this.setState({
          service_Provider,
          requested_sp: true
        })
      }
      else if (this.props.type === "service_Provider" && this.props.service_Provider) {
        this.setState({
          service_Provider: this.props.service_Provider,
          requested_sp: true
        })
      }

      //get mp project service provider
      if (project_Type === "mpProject" && (this.props.mpProject && Object.keys(this.props.mpProject).length !== 0) && (prevProps.mpProject !== this.props.mpProject)) {

        let acceptedSpId = this.props.mpProject.applications?.map((app) => {
          if (app.status === "Accepted") {
            return app.service_Provider_Id
          }
        }).filter((app) => (app !== undefined))

        let sp = await serviceProviderService.getServiceProviderById(acceptedSpId[0]);

        this.setState({
          mp_service_Provider: sp,
          requested_sp: true
        })
      }

      //get fixed rate product service provider
      else if (project_Type === "fixedProduct" && (this.props.mpProject && Object.keys(this.props.mpProject).length !== 0) && (prevProps.mpProject !== this.props.mpProject)) {
        let sp = await serviceProviderService.getServiceProviderById(this.props.mpProject.service_Provider_Id);

        this.setState({
          mp_service_Provider: sp,
          requested_sp: true
        })
      }
    }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  changeView = (view) => {
    this.setState({
      view
    });
  }

  open_chat = (organization_Id) => {
    let open_chat = this.state.open_chat;
    if (open_chat) {
      organization_Id = null;
    }

    this.setState({
      open_chat: !open_chat,
      organization_Id
    });
  }

  SPProjectFunc = () => {
    const { type, project } = this.props;
    const { active_Tab_Val, service_Provider } = this.state;
    const classes = useStyles();
    const [value, setValue] = React.useState(active_Tab_Val);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      if (newValue === 0) {
        history.push(window.location.pathname + "?type=spProject&tab=overview")
      }
      else if (newValue === 1) {
        history.push(window.location.pathname + "?type=spProject&tab=messages")
      }
      else if (newValue === 2) {
        history.push(window.location.pathname + "?type=spProject&tab=hoursInvoices")
      }
      else {
        history.push(window.location.pathname + "?type=spProject&tab=documents")
      }
    };


    { JSON.stringify(project.client_Organization && project.client_Organization.users && project.client_Organization.users.length > 0 && project.client_Organization.users[0].user_Id) }
    let client_list = {};

    if (project && project.client_Organization) {
      client_list = project.client_Organization.users.map((user) => {
        return (user.user_Id)
      });
    }

    return (
      <div className={classes.root} style={{ minHeight: 500 }}>
        <div style={{ marginTop: '1%' }}>
          <div className="row" style={{ padding: '20px 0px' }}>
            <a
              className="projectBackLink"
              href={pluginService.redirect(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")))}
            >
              <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} />Back to Projects
            </a>
          </div>
          <div className="row HeaderMarketplace">
            <div className="col-md-8 payment-form-col">
              <p className="acceptingProjectHeader" style={{ wordBreak: "break-word" }}>{project && project.project && project.project.project_Title}</p>
            </div>
            <div className="col-md-3 payment-form-col">
              <p style={{ fontFamily: "Ubuntu", fontWeight: "bold" }}>Status: {sharedService.getProjectStatus(project?.project?.project_Status, this.props.type === "client")}</p>
            </div>
          </div>

          <div className="projectNavigation" style={{ padding: '0px' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
              TabIndicatorProps={{ style: { backgroundColor: accentColor ? accentColor : '#00c58e' } }}
            >
              <LinkTab label="Overview" href="/project-proposals" {...a11yProps(0)} />
              <LinkTab label="Messages" href="/project-messages" {...a11yProps(1)} />
              <LinkTab label={project.project_Proposals[0].project_Flat_Rate ? "Invoices" : "Hours/Invoices"} href="/project-segments" {...a11yProps(2)} />
              <LinkTab label="Documents" href="/project-documents" {...a11yProps(3)} />
            </Tabs>
          </div>
        </div>

        {/* PROJECT DETAILS */}
        <TabPanel value={value} index={0}>
          {project.project &&
            <div>
              <ServiceProvidersProjectDetails_v2
                project={project}
                proposals={project.project_Proposals}
                type={type}
                service_Provider={service_Provider}
                organization_Id={type === "service_Provider" ? project?.project?.client_Organization_Id : service_Provider?.organization_Id}
              />

              {project && project.project && type === "service_Provider" &&
                <div className="padding20 margin-adjust">
                  <ServiceProvidersFinishProject_v2
                    project={project}
                    type={type} />
                </div>
              }
            </div>
          }
        </TabPanel>

        <div className="projectPageMessenger">
          <TabPanel value={value} index={1}>
            <br />
            <div>
              {project.project && service_Provider && service_Provider.organization_Id &&
                <Messenger_v2
                  channel_type="project"
                  message_Group="marketplace"
                  client_list={client_list}
                  service_Provider={service_Provider}
                  organization_Id={type === "service_Provider" ? project?.project?.client_Organization_Id : service_Provider?.organization_Id}
                  displayHelperText={true}
                />
              }
            </div>
          </TabPanel>
        </div>

        <TabPanel value={value} index={2} type={type} project_Id={this.props.project.project.project_Id}>
          <InvoicesHours
            singleProjectId={this.props.project.project.project_Id}
            singleProject={this.props.project}
            isFlatRateSpProject={project.project_Proposals[0].project_Flat_Rate ? true : false}
          />
        </TabPanel>

        {/* PROJECT DOCUMENTS */}
        <TabPanel value={value} index={3} type={type} project_Id={this.props.project.project.project_Id}>
          <ProjectDocuments
            segments={project.project_Segments}
            project={project.project}
            type={type}
          />
        </TabPanel>
      </div>
    );
  }

  MPProjectFunc = () => {
    const { type, mpProject } = this.props;
    const { active_Tab_Val, service_Provider, mp_service_Provider } = this.state;

    const classes = useStyles();
    const [value, setValue] = React.useState(active_Tab_Val);

    let isSpAccepted = true;

    if (type === "service_Provider" && mpProject?.applications && mpProject.applications.length !== 0) {
      isSpAccepted = mpProject.applications.some((app) => ((app.service_Provider_Id === service_Provider.service_Provider_Id) && app.status === "Accepted"))
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);

      if (newValue === 0) {
        history.push(window.location.pathname + "?type=mpProject&tab=overview")
      }
      else if (newValue === 1) {
        history.push(window.location.pathname + "?type=mpProject&tab=messages")
      }
      else if (newValue === 2) {
        history.push(window.location.pathname + "?type=mpProject&tab=hoursInvoices")
      }
      else {
        history.push(window.location.pathname + "?type=mpProject&tab=documents")
      }
    };

    return (
      <div className={classes.root} style={{ minHeight: 500 }}>

        <div style={{ marginTop: '1%' }}>

          <div className="row" style={{ padding: '20px 0px' }}>
            <a
              className="projectBackLink"
              href={pluginService.redirect(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")))}
            >
              <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} />Back to Projects
            </a>
          </div>
          <div className="row HeaderMarketplace">
            <div className="col-md-8 payment-form-col">
              <p className="acceptingProjectHeader" style={{ wordBreak: "break-word" }}>{mpProject.project_Title}</p>
            </div>
            <div className="col-md-3 payment-form-col">
              <p style={{ fontFamily: "Ubuntu", fontWeight: "bold" }}>Status: {sharedService.getProjectStatus(mpProject.project_Status === "Active" && !isSpAccepted ? "rejected" : mpProject.project_Status.toLowerCase(), this.props.type === "client")}</p>
            </div>
          </div>
          {mpProject.project_Status !== "Cancel" && mpProject.project_Status !== "Open" && isSpAccepted &&
            <div className="projectNavigation" style={{ padding: '0px' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <LinkTab label="Overview" href="/project-proposals" {...a11yProps(0)} />
                <LinkTab label="Messages" href="/project-messages" {...a11yProps(1)} />
                <LinkTab label={isFlatRateMpProject ? "Invoices" : "Hours/Invoices"} href="/project-segments" {...a11yProps(2)} />
                <LinkTab label="Documents" href="/project-documents" {...a11yProps(3)} />
              </Tabs>
            </div>
          }
        </div>

        {/* PROJECT DETAILS */}
        <TabPanel value={value} index={0}>

          <ViewListing
            project_id_passed={mpProject.project_Id}
            hideApplications={false}
            hideTop={true}
          />

          {type === "service_Provider" && mpProject.project_Status === "Active" && isSpAccepted &&
            <div className="padding20 margin-adjust">
              <ServiceProvidersFinishProject_v2
                project={mpProject}
                mpProject={true}
                type={type}
              />
            </div>
          }
        </TabPanel>

        <div className="projectPageMessenger">
          <TabPanel value={value} index={1}>
            <br />
            <div>
              {mpProject &&
                <Messenger_v2
                  channel_type="project"
                  message_Group="marketplace"
                  service_Provider={mp_service_Provider}
                  organization_Id={type === "service_Provider" ? mpProject?.client_Organization_Id : mp_service_Provider?.organization_Id}
                  displayHelperText={true}
                />
              }
            </div>
          </TabPanel>
        </div>

        <TabPanel value={value} index={2} type={type} project_Id={mpProject.project_Id}>
          <InvoicesHours
            singleProjectId={mpProject.project_Id}
            singleProject={mpProject}
            isMpProject={isMpProject}
            isFlatRateMpProject={isFlatRateMpProject}
          />
        </TabPanel>

        {/* PROJECT DOCUMENTS */}
        <TabPanel value={value} index={3} type={type} project_Id={mpProject.project_Id}>
          <ProjectDocuments
            segments={mpProject.project_Segments}
            project={mpProject}
            type={type}
          />
        </TabPanel>
      </div >
    );
  }

  FPProjectFunc = () => {
    const { type, user, mpProject } = this.props;
    const { active_Tab_Val, service_Provider, mp_service_Provider } = this.state;

    let client_list = {};

    const classes = useStyles();
    const [value, setValue] = React.useState(active_Tab_Val);

    const handleChange = (event, newValue) => {
      setValue(newValue);

      if (newValue === 0) {
        history.push(window.location.pathname + "?type=fixedProduct&tab=overview")
      }
      else if (newValue === 1) {
        history.push(window.location.pathname + "?type=fixedProduct&tab=messages")
      }
      else if (newValue === 2) {
        history.push(window.location.pathname + "?type=fixedProduct&tab=hoursInvoices")
      }
    };

    return (
      <div className={classes.root} style={{ minHeight: 500 }}>

        <div style={{ marginTop: '1%' }}>
          <div className="row" style={{ padding: '20px 0px' }}>
            <a
              className="projectBackLink"
              href={pluginService.redirect(window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")))}
            >
              <i className="icon-arrow-left" height="14px" style={{ paddingRight: 10 }} />Back to Projects
            </a>
          </div>
          <div className="row HeaderMarketplace">
            <div className="col-md-8 payment-form-col">
              <p className="acceptingProjectHeader" style={{ wordBreak: "break-word" }}>{mpProject.project_Title}</p>
            </div>
            <div className="col-md-3 payment-form-col">
              <p style={{ fontFamily: "Ubuntu", fontWeight: "bold" }}>Status: {sharedService.getProjectStatus(mpProject.project_Status.toLowerCase(), this.props.type === "client")}</p>
            </div>
          </div>
          <div className="projectNavigation" style={{ padding: '0px' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="Overview" href="/project-proposals" {...a11yProps(0)} />
              <LinkTab label="Messages" href="/project-messages" {...a11yProps(1)} />
              <LinkTab label="Invoices" href="/project-segments" {...a11yProps(2)} />
            </Tabs>
          </div>
        </div>

        {/* PROJECT DETAILS */}
        <TabPanel value={value} index={0}>

          {mp_service_Provider && Object.keys(mp_service_Provider).length !== 0 &&
            <ViewListing
              project_id_passed={mpProject.project_Id}
              productSp={mp_service_Provider}
              hideApplications={true}
              hideTop={true}
              fixedProduct={true}
            />
          }

          {type === "service_Provider" && mpProject.project_Status === "Active" &&
            <div className="padding20 margin-adjust">
              <ServiceProvidersFinishProject_v2
                project={mpProject}
                mpProject={true}
                type={type}
              />
            </div>
          }
        </TabPanel>

        <div className="projectPageMessenger">
          <TabPanel value={value} index={1}>
            <br />
            <div>
              {mpProject &&
                <Messenger_v2
                  channel_type="project"
                  message_Group="marketplace"
                  client_list={client_list}
                  service_Provider={mp_service_Provider}
                  organization_Id={type == "service_Provider" ? mpProject.client_Organization_Id : mp_service_Provider?.organization_Id}
                  displayHelperText={true}
                />
              }
            </div>
          </TabPanel>
        </div>

        <TabPanel value={value} index={2} type={type} project_Id={mpProject.project_Id}>
          <InvoicesHours
            singleProjectId={mpProject.project_Id}
            singleProject={mpProject}
            isMpProject={isMpProject}
            isFlatRateMpProject={isFlatRateMpProject}
          />
        </TabPanel>
      </div >
    );
  }

  render() {
    const { active_Tab_Val } = this.state;
    const { project, loading, mpProject } = this.props;

    return (
      <div>
        {loading &&
          <Loading />
        }
        {project && Object.keys(project).length !== 0 && (active_Tab_Val || active_Tab_Val === 0) &&
          <this.SPProjectFunc />
        }
        {mpProject && project_Type === "mpProject" && Object.keys(mpProject).length !== 0 &&
          <this.MPProjectFunc />
        }
        {mpProject && project_Type === "fixedProduct" && Object.keys(mpProject).length !== 0 &&
          <this.FPProjectFunc />
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const { service_Provider, loading } = state.serviceprovider_getserviceprovider;
  const { project } = state.serviceprovider_getproject;
  const mpProject = state.mp_project.project;

  if (mpProject && Object.keys(mpProject).length !== 0) {

    isMpProject = true;

    if (mpProject.applications && mpProject.applications.length !== 0 && mpProject.applications[0].flat_Rate !== 0) {
      isFlatRateMpProject = true;
    }
  }

  return {
    service_Provider, loading,
    project,
    user,
    mpProject
  };
}

const connectedMarketplaceProject = connect(mapStateToProps)(MarketplaceProject_v2);
export { connectedMarketplaceProject as MarketplaceProject_v2 };
