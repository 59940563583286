import queryString from 'query-string';
import React from 'react';
import ReactPasswordStrength from 'react-password-strength';
import { connect } from 'react-redux';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';
import "../../../Styles/Login_v2.css";
import { authenticateActions } from '../../../_actions';
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from '../../../_helpers';
import { asyncLocalStorage, pluginService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import { ResetPasswordConfirmation } from './ResetPasswordConfirmation';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resetPassword: {
                email: '',
                password: '',
                confirmPassword: '',
                userId: '',
                code: ''
            },
            submitted: false,
            confirm: false,
            isValid: false,

            iFrameConfig: null,
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { location: { search } } = this.props;
        const values = queryString.parse(search);
        if (values.user_Id && values.code) {
            // const code = values.code.replace(" ", "+");
            this.setState({
                resetPassword: {
                    userId: values.user_Id,
                    code: values.code,
                }
            }, () => { });
        }

        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            resetPassword: {
                ...prevState.resetPassword,
                password: state.password,
            }
        }));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            resetPassword: {
                ...prevState.resetPassword,
                [name]: value
            }
        }));
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true, loading: true });
        const { resetPassword } = this.state;
        const { dispatch } = this.props;

        if (resetPassword.email &&
            resetPassword.password &&
            resetPassword.confirmPassword &&
            resetPassword.password === resetPassword.confirmPassword) {
            dispatch(authenticateActions.resetPassword(resetPassword));
            this.setState({ confirm: true, loading: false });

            setTimeout(() => {
                history.push('/login');
            }, 10000);
        }
    }

    render() {
        const { submitted, confirm, resetPassword, iFrameConfig, loading } = this.state;

        return (
            <div>
                {loading && <Loader />}
                {!confirm &&
                    <div id="forgotPassword">
                        <div id="forgotPassowrdRightLogin">
                            <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="logo" className="login-logo forgot-pswd-logo" style={iFrameConfig?.public ? { width: "40%", marginBottom: 40 } : { width: "70%", marginBottom: 40 }} />
                            <hr />
                            <br />
                            <h3 className="forgot-pswd-heading">Please enter the following information to reset your password.</h3>
                            <form autoComplete="off" id="changePasswordForm" className="settingsForm" onSubmit={this.handleSubmit}>
                                {/* Email */}
                                <div>
                                    <div style={{ margin: 0 }} className={'form-group' + (submitted && !resetPassword.email ? ' has-error' : '')}>
                                        <p className="card-text">Email Address</p>
                                        <input
                                            autoComplete="none"
                                            type="email"
                                            name="email"
                                            className="email-text-field font-input-default"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    {submitted && !resetPassword.email &&
                                        <div className="login-error">Email address is required</div>
                                    }
                                </div>
                                {/* New Password */}
                                <div className={'form-group' + (submitted && (!resetPassword.password ?? this.state.isValid === false) ? ' has-error' : '')}>
                                    <p className="card-text">New Password</p>
                                    <div className='register-password-input'>
                                        <ReactPasswordStrength
                                            className="login-passwordStrength"
                                            minLength={8}
                                            minScore={2}
                                            scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                            changeCallback={this.changeCallback}
                                            inputState={{ name: "password", autoComplete: "none", className: "password-text-field", onChange: this.handleChange }}
                                        />
                                    </div>
                                    {submitted && !resetPassword.password &&
                                        <div className="login-error" style={{ marginTop: "12px" }}>New password is required</div>
                                    }
                                    {(submitted && resetPassword.password) && this.state.isValid === false &&
                                        <div className="login-error" style={{ marginTop: "12px" }}>Password is too weak.</div>
                                    }
                                </div>
                                <div style={{ marginTop: "20px" }} className={'form-group' + ((submitted && (!resetPassword.confirmPassword || resetPassword.confirmPassword !== resetPassword.password)) ? ' has-error' : '')}>
                                    <p className="card-text">Confirm New Password</p>
                                    <input
                                        autoComplete="none"
                                        type="password"
                                        name="confirmPassword"
                                        className="email-text-field font-input-default"
                                        onChange={this.handleChange}
                                    />
                                    {submitted && !resetPassword.confirmPassword &&
                                        <div className="login-error">Confirm new password is required</div>
                                    }
                                    {(submitted && resetPassword.confirmPassword && resetPassword.password) && (resetPassword.confirmPassword !== resetPassword.password) &&
                                        <div className="login-error">Passwords do not match</div>
                                    }
                                </div>
                                <br />

                                {/* Update Password */}
                                <button className="sign-in-button" type="submit" style={{ width: "100%", backgroundColor: iFrameConfig?.public ? iFrameConfig?.primary : '#0c7069' }}>Update password</button>
                            </form>
                            {!iFrameConfig?.public &&
                                <div className='register-text'>
                                    <br />
                                    <p className="register-text-medium">
                                        If you continue to experience any issues, please contact us for support at <a href="mailto:support@opengrants.io">support@opengrants.io.</a>
                                    </p>
                                </div>
                            }
                        </div>
                        {!iFrameConfig?.public &&
                            <div className="resetPasswordBottomLinks">
                                <div className="resetPasswordLinks">
                                    <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                                    <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                                    <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                                    <a href={pluginService.redirect("/login")}>Login</a>
                                </div>
                                <div className="resetPasswordCopyFooter">
                                    opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                                </div>
                            </div>
                        }
                    </div>
                }
                {confirm &&
                    <ResetPasswordConfirmation />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedResetPassword = connect(mapStateToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };

