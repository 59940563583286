import { registrationConstants } from '../_constants';

export function registration(state = { registering: "", nav: 0 }, action) {
    switch (action.type) {
        case registrationConstants.REGISTER_REQUEST:
            return { registering: "request" };
        case registrationConstants.REGISTER_SUCCESS:
            return { registering: "success", nav: 1, user: action.user };
        case registrationConstants.REGISTER_FAILURE:
            return { registering: "failure", nav: 0 };
        default:
            return state
    }
}