import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class UserRedirect extends React.Component {

    render() {
        // if (localStorage.getItem('user') === null) {
        //     alert("UN AUTH ENTRY: " + window.location.href);
        //     localStorage.setItem('redirect', window.location.href);
        // }

        return (
            <div>
                {(localStorage.getItem('user') === null || localStorage.getItem('user') === undefined) &&
                    <Redirect to="/login" />
                }
                {(localStorage.getItem('user') === null || localStorage.getItem('user') !== undefined) &&
                    <Redirect to="/dashboard" />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user,
    };
}

const connectedUserRedirect = connect(mapStateToProps)(UserRedirect);
export { connectedUserRedirect as UserRedirect };
