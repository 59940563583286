import { format, parseISO } from "date-fns";
import he from 'he';
import React, { useState } from 'react';
import TextTruncate from 'react-text-truncate';
import { Col, Row } from "react-bootstrap";
import { favoritesService } from "../../_services";
import { Modal, ModalBody } from "reactstrap";

export const GrantSummary = ({
    children,
    user,
    type,
    listing,
    setOpenListing,
    favorites,
    updateFavorites,
    showDismiss
}) => {
    const [isSummamryOpen, setIsSummaryOpen] = useState(false);
    const [isFavDiscardOpen, setIsFavDiscardOpen] = useState(false);

    const unixDate = (timestamp) => {
        var a = new Date(timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = month + ' ' + date + ', ' + year;

        return time;
    };

    const saveListing = async () => {
        setIsFavDiscardOpen(false);
        if (type === 'listings')
            await favoritesService.saveListing(listing.listing_Id, user)
        if (type === 'funders')
            await favoritesService.saveFunder(listing.id, user)
        const newFavorites = favorites.filter(x => {
            if (type === 'listings') return x.listing_Id != listing.listing_Id;
            if (type === 'funders') return x.id != listing.id;
            return false;
        });
        updateFavorites(newFavorites);
    };

    const dueDate = () => {
        if (listing.application_Due_Date) return isNaN(listing.application_Due_Date) ? format(parseISO(listing.application_Due_Date), "MMMM do, yyyy") : unixDate(listing.application_Due_Date);
        if (listing.close_Date) return isNaN(listing.close_Date) ? format(parseISO(listing.close_Date), "MMMM do, yyyy") : unixDate(listing.close_Date);

        return 'Applications ongoing';
    }

    return (
        <div>
            <Row className="align-items-center p-16">
                <Col className="col-12 col-sm-8">
                    {children}
                </Col>
                <Col className="col-12 col-sm-4">
                    <Row className="justify-content-end align-items-center">
                        <Col className="col-auto col-sm-6">
                            <button
                                className="viewGrantBtn fs-4"
                                onClick={() => { setOpenListing() }}
                            >
                                {type === 'listings' ? 'View Grant' : 'View Funder'}
                            </button>
                        </Col>
                        <Col className="col-auto col-sm-3">
                            <button
                                onClick={() => setIsSummaryOpen(!isSummamryOpen)}
                                className="dropDownBtn "
                                type="button"
                            >
                                {isSummamryOpen ? <i className="icon-up-open" /> : <i className="icon-down-open" />}
                            </button>
                        </Col>
                        {showDismiss && <Col className="col-auto col-sm-3">
                            <button
                                data-tip="Discard favorite."
                                className="dropDownBtn "
                                type="button"
                                onClick={() => setIsFavDiscardOpen(true)}
                            >
                                X
                            </button>
                        </Col>}
                    </Row>
                </Col>
            </Row>
            {isSummamryOpen ?
                <div className="p-16">
                    {(listing.agency || listing.funding_Amount || listing.application_Due_Date || listing.match_Required) && <>
                        <div class="hit-agency" style={{ marginTop: 10, fontWeight: 'bold' }}>
                            {listing.agency}
                        </div>
                        <p>{listing.funding_Amount ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0
                        }).format(listing.funding_Amount) : 'Funding varies.'}</p>
                        <p><strong>Due Date:</strong> {dueDate()}</p>
                        <p><strong>Match Required:</strong> {listing.match_Required ? 'Yes' : 'No'} </p>
                        <br />
                    </>}
                    <div className="dropDownSummaryTitle">
                        <p><strong>Summary: </strong></p>
                    </div>
                    <div className="dropDown-description">
                        <TextTruncate
                            line={3}
                            element="class"
                            truncateText="…"
                            text={he.decode(listing.description.trim() || '')}
                            style={{ color: "#333", fontSize: 14, width: '95%' }}
                        />
                    </div>
                </div>
                : null
            }
            <hr />

            {/* Favorite Discard Confirmation Modal */}
            {isFavDiscardOpen &&
                <Modal
                    isOpen={isFavDiscardOpen}
                    centered={true}
                    backdrop={true}
                    toggle={() => setIsFavDiscardOpen(!isFavDiscardOpen)}
                    fade={false}
                    className="ModalDiv"
                    size="xl">
                    <ModalBody className="p-64">
                        <h5 className='fs-2 pb-48 text-center'>Are you sure you want to discard this grant?</h5>
                        <Row className="mb-8 justify-content-center">
                            <Col className="col-auto">
                                <button
                                    className="bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold"
                                    onClick={() => setIsFavDiscardOpen(false)}
                                >
                                    Cancel
                                </button>
                            </Col>
                            <Col className="col-auto">
                                <button
                                    className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
                                    onClick={() => saveListing(false)}
                                >
                                    Discard
                                </button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal >
            }
        </div>
    );
}

