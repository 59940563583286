import React, { useState } from 'react';
import he from 'he';
import TextTruncate from 'react-text-truncate';
import { Modal, ModalBody } from "reactstrap";
import { GrantSummary } from "./GrantSummary";
import { GrantDetails } from './GrantDetails';

export const GrantList = ({
    user,
    iFrameConfig,
    listings,
    updateGrantMatches,
    favorites,
    updateFavorites,
    showDismiss
}) => {
    const [isGrantOpen, setIsGrantOpen] = useState(false);
    const [listing, setListing] = useState({});

    return (
        <div>
            {listings.map((listing) => <>
                <GrantSummary
                    key={"Saved_" + listing.listing_Id}
                    user={user}
                    type={listing.type}
                    listing={listing}
                    setOpenListing={() => { setListing(listing); setIsGrantOpen(true); }}
                    showDismiss={showDismiss}
                    favorites={favorites}
                    updateFavorites={updateFavorites}>
                    <h2 className="result-title">
                        <TextTruncate
                            line={2}
                            element="span"
                            truncateText="…"
                            text={he.decode(listing.title?.trim() || listing.name?.trim())}
                            style={{ fontWeight: 900 }}
                        />
                    </h2>
                </GrantSummary>
            </>)}
            {/* View Favorite Modal */}
            <Modal
                isOpen={isGrantOpen}
                centered={true}
                backdrop={true}
                toggle={() => { setIsGrantOpen(!isGrantOpen) }}
                fade={false}
                className="ModalDiv"
                size="xl">
                <ModalBody className="p-64">
                    <GrantDetails
                        user={user}
                        iFrameConfig={iFrameConfig}
                        closeModal={() => setIsGrantOpen(false)}

                        listing={listing}
                        updateGrantMatches={updateGrantMatches}
                        favorites={favorites}
                        updateFavorites={updateFavorites}
                    />
                </ModalBody>
            </Modal >
        </div>
    );
}

