import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { pluginService, settingsService, sharedService } from "../../../../_services";
import { Previews } from "../../../Settings/ImageUpload";
import { NotificationSettings } from "../../../Settings/NotificationSettings";

let accentColor = pluginService.getIFrameColor()

class GS_Onboarding_Basics extends React.Component {
  state = {
    submtted: false,
    phoneRequired: false,
    submitNotifications: false,
  }

  //Get existing notification settings
  componentDidMount = async () => {

    const { user } = this.props

    const notifications = await settingsService.getNotificationSettings(user.id);

    this.setState({
      notifications
    })

    //if SMS or both are already selected, make phone required
    if (notifications.newMessage_SMS || notifications.newMessage_Both) {
      this.setState({
        phoneRequired: true,
      })
    }
  }

  render() {
    const {
      updateNav, is_New_Org, user, handleChange, setCroppedPixels, setProfileImage_file,
      handleSubmit, handleRadioChange, showGenderInput, showEthnicityInput, genderOptions, ethnicityOptions,
      checkOther
    } = this.props;

    const firstLetter = user.name.charAt(0);
    const lastLetter = user.surname.charAt(0);

    const removePreview = () => {
      this.setState({
        imageError: "",
        show_Logo: false,
      })
    }

    const imageUploadFailed = (msg) => {
      this.setState({
        imageError: msg,
        show_Logo: false,
      })

      setTimeout(() => {
        this.setState({
          imageError: "",
        })
      }, 6000);
    }

    //Make phone number required
    const handlePhoneRequirement = (value) => {
      this.setState({
        phoneRequired: value,
      })
    }

    const handleSubmission = (e) => {

      this.setState({
        submitNotifications: !this.state.submitNotifications,
      }, () => {

        //prevent submission if phone number is entered but in a wrong format
        if (user.phoneNumber && !sharedService.isInteger(user.phoneNumber)) {
          return;
        }

        //prevent submission if SMS notification option is selected and phone number is not entered
        if (this.state.phoneRequired && (!user.phoneNumber || !sharedService.isInteger(user.phoneNumber))) {
          return;
        }

        if ((user.gender?.length > 50) || (user.ethnicity?.length > 50)) {
          return;
        }

        if (!is_New_Org) {
          handleSubmit(e);
        } else {
          updateNav("onboard_organizationAttributes1");
        }
      })

    }

    const submitBtn = () => {
      return (
        <button
          className="gc_started-button-next"
          onClick={(e) => handleSubmission(e)}
          style={{ backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
        >
          {!is_New_Org ? "Submit" : "Next"} <i class="icon-arrow-right" />
        </button>
      )
    }

    const fillOutLaterBtn = () => {
      if (!is_New_Org) {
        return null;
      } else {
        return (
          <>
            <div className="row center">
              <button
                className="gc_started-button-fillLater"
                onClick={() => updateNav("onboard_organizationAttributes1")}
                style={{ color: accentColor || '#0C7069' }}
              >
                Fill Out Later
              </button>
              <br />
            </div>
          </>
        )
      }
    }

    let otherGender = checkOther("gender", genderOptions)
    let otherEthnicity = checkOther("ethnicity", ethnicityOptions)

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              1 of 6
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10" >
            <div className="col-md-2 progress-color" style={{ backgroundColor: accentColor || '#00C58E' }} />
            <div className="col-md-10 gray-progress-color" />
          </div>
        </div>
        <form onSubmit={e => { e.preventDefault() }}>
          <div className="gc_onboarding-form-card">
            <h1 className="onboarding-form-header">Basics</h1>
            <p>We'd like to learn a bit about you. Our onboarding takes less than 5 minutes, and helps us better understand your needs so we can help you find grants faster.</p>
            <hr />
            <div className="row gc_onboarding-header">
              {(user.headshot || localStorage.getItem("fileBase64")) ?
                <div className="gc_onboarding-form-profile-pic-true" style={{ width: 250, height: 250 }}>
                  <img
                    width="100%"
                    height="100%"
                    src={user.headshot ? user.headshot : localStorage.getItem("fileBase64")}
                    style={{
                      borderRadius: '50%'
                    }}
                    alt="profile circle"
                  />
                  <div className="gc_onboarding_previews">
                    <div id="profileImage">
                      <Previews
                        removePreview={removePreview}
                        imageUploadFailed={imageUploadFailed}
                        setCroppedPixels={setCroppedPixels}
                        setProfileImage_file={setProfileImage_file}
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="gc_onboarding-form-profile-pic" id="profileImage">
                  <div className="gc_onboarding_previews">
                    <div id="profileImage">
                      <Previews
                        removePreview={removePreview}
                        imageUploadFailed={imageUploadFailed}
                        setCroppedPixels={setCroppedPixels}
                        setProfileImage_file={setProfileImage_file}
                      />
                    </div>
                  </div>
                  <p className="gc_onboarding-form-profile-pic-name">{firstLetter}{lastLetter}</p>
                  <p className="gc_onboarding-form-profile-pic-plus">+</p>
                </div>
              }
            </div>
            <div className="row gc_onboarding-header">
              <p className="gc_onboarding-form-profile-pic-text">Add Photo or Logo</p>
            </div>
            <div>
              {this.state.imageError &&
                <p className="red-message" style={{ textAlign: "center" }}>{this.state.imageError}</p>
              }
            </div>
            <div className="row">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name">
                  First Name
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  name="name"
                  disabled
                  value={user.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right">
                <p className="gc_onboarding-form-input-name">
                  Last Name
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  disabled
                  name="surname"
                  value={user.surname}
                  onChange={handleChange}
                  inputProps={{ maxLength: 450 }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 gc_onboarding-form-col-left">
                <p className="gc_onboarding-form-input-name">
                  Phone Number
                </p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  name="phoneNumber"
                  value={user.phoneNumber}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
                {user.phoneNumber && !sharedService.isInteger(user.phoneNumber) &&
                  <p className="red-message">
                    You may only enter integers.
                  </p>
                }
                {this.state.phoneRequired && (!user.phoneNumber || !sharedService.isInteger(user.phoneNumber)) &&
                  <p className="red-message">
                    Phone number is required for SMS notifications.
                  </p>
                }
              </div>
              <div className="col-md-6 gc_onboarding-form-col-right">
                <p className="gc_onboarding-form-input-name">Primary Contact Email</p>
                <input
                  className="gc_onboarding-form-input"
                  type="text"
                  disabled
                  name="Contact_Email"
                  value={user.email}
                  onChange={handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 50,
                  }}
                />
              </div>
            </div>
            <NotificationSettings
              onboarding={true}
              userType={"Grant_Seeker"}
              handlePhoneRequirement={handlePhoneRequirement}
              submitNotifications={this.state.submitNotifications}
            />
            <div className="row">
              <div className="col-md-12 gc_onboarding-form-col">
                <div style={{ marginBottom: 10 }}>
                  <p className="gc_onboarding-form-textarea-name">
                    Personal Bio
                  </p>
                </div>
                <textarea
                  className="gc_onboarding-form-textarea"
                  name="bio"
                  type="text"
                  value={user.bio}
                  onChange={handleChange}
                  multiline
                  rows={10}
                />
              </div>
            </div>
            <hr />

            <div>
              <p className="gc_onboarding-form-textarea-name">
                Demographics
              </p>
              <p className="gc_onboarding-form-input-name-top-secondary">
                The following demographic questions are optional and will be kept completely anonymous.
                Collecting this information helps us create a more diverse and inclusive community.
              </p>

              <div className="row">

                {/* GENDER */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">How would you describe your gender?</p>
                  <div>
                    <input checked={user.gender === "Male"} id="gender_Male" name="gender" value="Male" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Male" style={{ display: "inline-block" }}>Male</label>
                  </div>
                  <div>
                    <input checked={user.gender === "Female"} id="gender_Female" name="gender" value="Female" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Female" style={{ display: "inline-block" }}>Female</label>
                  </div>
                  <div>
                    <input checked={user.gender === "Non-binary"} id="gender_Non-binary" name="gender" value="Non-binary" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_Non-binary" style={{ display: "inline-block" }}>Non-binary</label>
                  </div>
                  <div>
                    <input checked={!otherGender} id="gender_Other" name="gender" value="" type="radio" onChange={(e) => { handleRadioChange(e); }} style={{ width: "auto" }} />
                    <label for="gender_Other" style={{ display: "inline-block" }}>Other</label>
                  </div>
                  {showGenderInput &&
                    <>
                      <input className="gc_onboarding-form-input" id="gender_Other" name="gender" value={user.gender} type="text" onChange={(e) => { handleRadioChange(e) }} />

                      {user.gender?.length > 50 &&
                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                      }
                    </>
                  }
                  <div>
                    <input checked={user.gender === "I prefer not to say"} id="gender_No_Say" name="gender" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="gender_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>

                </div>

                {/* ETHNIC BACKGROUND */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">What is your ethnic background?</p>
                  <div>
                    <input checked={user.ethnicity === "White/Caucasian"} id="ethnicity_White/Caucasian" name="ethnicity" value="White/Caucasian" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_White/Caucasian" style={{ display: "inline-block" }}>White/Caucasian</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Asian-Eastern"} id="ethnicity_Asian-Eastern" name="ethnicity" value="Asian-Eastern" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Asian-Eastern" style={{ display: "inline-block" }}>Asian-Eastern</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Asian-Indian"} id="ethnicity_Asian-Indian" name="ethnicity" value="Asian-Indian" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Asian-Indian" style={{ display: "inline-block" }}>Asian-Indian</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Hispanic"} id="ethnicity_Hispanic" name="ethnicity" value="Hispanic" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Hispanic" style={{ display: "inline-block" }}>Hispanic</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "African-American"} id="ethnicity_African-American" name="ethnicity" value="African-American" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_African-American" style={{ display: "inline-block" }}>African-American</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Native-American"} id="ethnicity_Native-American" name="ethnicity" value="Native-American" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Native-American" style={{ display: "inline-block" }}>Native-American</label>
                  </div>
                  <div>
                    <input checked={user.ethnicity === "Mixed race"} id="ethnicity_Mixed_Race" name="ethnicity" value="Mixed race" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Mixed_Race" style={{ display: "inline-block" }}>Mixed race</label>
                  </div>
                  <div>
                    <input checked={!otherEthnicity} id="ethnicity_Other" name="ethnicity" value="" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_Other" style={{ display: "inline-block" }}>Other</label>
                  </div>
                  {showEthnicityInput &&
                    <>
                      <input className="gc_onboarding-form-input" id="ethnicity_Other" name="ethnicity" value={user.ethnicity} type="text" onChange={(e) => { handleRadioChange(e) }} />

                      {user.ethnicity?.length > 50 &&
                        <p className="red-message">You've gone over the 50 character limit. Please shorten your entry.</p>
                      }
                    </>
                  }
                  <div>
                    <input checked={user.ethnicity === "I prefer not to say"} id="ethnicity_No_Say" name="ethnicity" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="ethnicity_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>
                </div>

                {/* DISABILITY */}
                <div className="col-md-4">
                  <p className="gc_onboarding-form-textarea-name">Would you consider yourself to have a disability?</p>
                  <div>
                    <input checked={user.disability === "Yes"} id="disability_Yes" name="disability" value="Yes" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_Yes" style={{ display: "inline-block" }}>Yes</label>
                  </div>
                  <div>
                    <input checked={user.disability === "No"} id="disability_No" name="disability" value="No" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_No" style={{ display: "inline-block" }}>No</label>
                  </div>
                  <div>
                    <input checked={user.disability === "I prefer not to say"} id="disability_No_Say" name="disability" value="I prefer not to say" type="radio" onChange={(e) => { handleRadioChange(e) }} style={{ width: "auto" }} />
                    <label for="disability_No_Say" style={{ display: "inline-block" }}>I prefer not to say</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mobileBtnRow">
              <div className="col-md-3" />
              <div className="col-md-3 gc_onboarding-form-col">
                <a className="gc_started-button-back-href" onClick={() => window.history.back()} style={{ color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}>
                  Back <i class="icon-arrow-left" />
                </a>
              </div>
              <div className="col-md-6 gc_onboarding-form-col">
                {submitBtn()}
              </div>
            </div>
            {fillOutLaterBtn()}
            <br />
          </div>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  // const { user } = state.setUser;
  // return {
  //   user,
  // };
}

const connectedGS_Onboarding_Basics =
  connect(mapStateToProps)(GS_Onboarding_Basics);
export { connectedGS_Onboarding_Basics as GS_Onboarding_Basics };

