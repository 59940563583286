import { alertActions } from '.';
import { authenticateConstants } from '../_constants';
import { history } from '../_helpers';
import { setPartnerInfo } from '../_pages/Shared/Partners';
import { authenticateService, organizationService, pluginService } from '../_services';

export const authenticateActions = {
    login,
    logout,
    setUser,
    sendConfirmationEmail,
    forgotPassword,
    resetPassword
};

function login(username, password, google, iFrameUrl) {
    return async dispatch => {
        dispatch(request({ username }));
        await authenticateService.login(username, password, google)
            .then(
                async user => {
                    localStorage.setItem('user', JSON.stringify(user));
                    window.heap.identify(user.id);

                    //set website theme
                    if (user.partner) {
                        setPartnerInfo(user.partner)
                        localStorage.setItem('partner', user.partner);
                    }

                    //partner connect account redirect
                    if (user.partnerClient) {
                        history.push(user.partnerClient + (iFrameUrl ? iFrameUrl : ''));
                    }
                    //platform redirect
                    else {
                        let url = '/dashboard';

                        if (user.organization && !user.organization.date_Completed_Onboarding) {
                            url = '/grant-seeker/onboarding';
                        }

                        //non-auth redirect
                        if (history?.location?.state && history?.location?.state?.from) {
                            url = history.location.state.from.pathname + history.location.state.from.search;
                        }
                        else {
                            //non-auth redirect from reg
                            const url_ss = sessionStorage.getItem('redirect');
                            if (url_ss) {
                                sessionStorage.removeItem('redirect');
                                url = url_ss;
                            }
                        }

                        window.location.href = pluginService.redirect(url);
                    }
                },
                error => {
                    if (error.includes("Google user does not exist")) {
                        history.push('/register');
                    }
                    dispatch(failure(error));
                    dispatch(alertActions.loginError("There was an error processing your request."));
                }
            );
    };

    function request(user) { return { type: authenticateConstants.SETUSER_REQUEST, user } }
    // function success(user) { return { type: authenticateConstants.SETUSER_SUCCESS, user } }
    function failure(error) { return { type: authenticateConstants.SETUSER_FAILURE, error } }
}

function logout(iFrameUrl) {
    authenticateService.logout();
    let redirect = sessionStorage.getItem('redirect');
    sessionStorage.clear();
    if (redirect) {
        sessionStorage.setItem('redirect', redirect);
    }

    let iFrameConfig = localStorage.iFrameConfig;
    localStorage.clear();
    if (iFrameConfig) {
        localStorage.setItem('iFrameConfig', iFrameConfig)
    }
    return { type: authenticateConstants.LOGOUT };
}

function sendConfirmationEmail(email, server) {
    return dispatch => {
        authenticateService.sendConfirmationEmail(email, server)
            .then(
                (email) => {
                    dispatch(emailSent(email));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function emailSent() { return { type: authenticateConstants.CONFIRMEMAIL_SENTSUCCESS, email } }
    function failure(error) { return { type: authenticateConstants.CONFIRMEMAIL_SENTFAILURE, error } }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request(email));
        authenticateService.forgotPassword(email)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: authenticateConstants.FORGOTPASSWORD_REQUEST, user } }
    function success(user) { return { type: authenticateConstants.FORGOTPASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authenticateConstants.FORGOTPASSWORD_FAILURE, error } }
}

function resetPassword(resetPassword) {
    return dispatch => {
        dispatch(request());
        authenticateService.resetPassword(resetPassword)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: authenticateConstants.RESETPASSWORD_REQUEST } }
    function success() { return { type: authenticateConstants.RESETPASSWORD_SUCCESS } }
    function failure(error) { return { type: authenticateConstants.RESETPASSWORD_FAILURE, error } }
}

function setUser(user) {
    return dispatch => {
        dispatch(request(user));
        dispatch(success(user));
        localStorage.setItem('user', JSON.stringify(user));
    };

    function request(user) { return { type: authenticateConstants.SETUSER_REQUEST, user } }
    function success(user) { return { type: authenticateConstants.SETUSER_SUCCESS, user } }
}