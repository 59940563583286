import React from 'react';
import Slider from '@material-ui/core/Slider';
import { Component } from 'react';
import Cropper from 'react-easy-crop';
import '../../Styles/SettingsStyles/ImageCrop.css';

export default class ImageCrop extends Component {

    constructor(props) {
        super(props)

        this.state = {
            imageSrc: this.props.image,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1,
            croppedAreaPixels: null,
        }
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
        this.props.setCroppedPixels(croppedAreaPixels)
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    render() {
        return (
            <div className="imageCropContainer">
                <div className="crop-container">
                    <Cropper
                        image={this.state.imageSrc}
                        crop={this.state.crop}
                        cropShape="round"
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                        showGrid={false}
                        objectFit="contain"
                    />
                </div>
                <div className="controls">
                    <Slider
                        value={this.state.zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                    // classes={{ container: 'slider' }}
                    />
                </div>
            </div>
        )
    }
}
