import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { listingNotesService, sharedService } from "../../../_services";
import { funderNotesService } from "../../../_services/Notes/fundernotes.service";
import { upgradeActions } from "../../../_actions";
import { useDispatch } from "react-redux";

export const Notes = ({ user, listing, saveListing, updateGrantMatches }) => {
  const dispatch = useDispatch();
  const [originalNote, setOriginalNote] = useState(listing?.portal_Listing_Notes || listing?.funder_Notes);
  const [note, setNote] = useState(originalNote)
  const [isEditing, setIsEditing] = useState(false);

  const saveNotes = async (e) => {
    // if (e) e.preventDefault();

    setOriginalNote(note);
    setIsEditing(false);

    let noteFieldName = '';
    let query = [];
    if (listing.type === 'listings') {
      noteFieldName = 'portal_Listing_Notes';
      query.push(listingNotesService.saveListingNotes(user.id, listing?.listing_Id, note))
    }
    if (listing.type === 'funders') {
      noteFieldName = 'funder_Notes';
      query.push(funderNotesService.saveFunderNotes(user.id, listing?.id, note))
    }

    query.push(saveListing(true));

    await Promise.all(query);

    if (updateGrantMatches)
      updateGrantMatches(listing.type, { ...listing, [noteFieldName]: note });
  }

  const deleteNotes = async () => {
    setNote('');
    setOriginalNote('');

    let noteFieldName = '';
    if (listing.type === 'listings') {
      noteFieldName = 'portal_Listing_Notes';
      await listingNotesService.deleteListingNotes(user.id, listing?.listing_Id, note)
    }
    if (listing.type === 'funders') {
      noteFieldName = 'funder_Notes';
      await funderNotesService.deleteFunderNotes(user.id, listing?.id, note)
    }

    if (updateGrantMatches)
      updateGrantMatches(listing.type, { ...listing, [noteFieldName]: '' });
  }

  return (
    <>
      {!isEditing && (note ? <Row>
        <Col>
          <p>
            {note}
          </p>
        </Col>
        <Col className="col-auto">
          <Row>
            <Col>
              <button
                className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
                onClick={() => setIsEditing(true)}
              >Edit</button>
            </Col>
            <Col>
              <button
                className="bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold"
                onClick={deleteNotes}
              >
                Delete
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
        :
        <>
          <button
            className="w-auto bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
            onClick={() => setIsEditing(true)}
          >
            Add Notes
          </button>
        </>
      )}

      {isEditing && <>
        <textarea
          rows={10}
          id="user_notes"
          className="fs-4 notesTextArea fw-normal"
          placeholder="Add your notes here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        >
          {note}
        </textarea>
        <Row>
          <Col className="col-auto ps-0">
            <button
              className="bg-primary-dark text-white rounded-10 py-16 px-40 fs-4 fw-bold"
              onClick={(e) => saveNotes(e)}
            >
              Save
            </button>
          </Col>
          <Col>
            <button
              className="bg-white text-primary-dark border border-primary-dark rounded-10 py-16 px-40 fs-4 fw-bold"
              onClick={() => { setIsEditing(false); setNote(originalNote) }}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </>
      }
    </>
  )
}