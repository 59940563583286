import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function DeleteRecommendationModal({
    modalOpen, toggle_deleteRecommendationModal, deleteRecommendation
}) {
    return (
        <Modal
            isOpen={modalOpen}
            centered={true}
            backdrop={true}
            fade={false}
            className="ModalDiv"
            size='xl'
        >
            <ModalHeader className="hoursDeleteModalHeader">
                Are you sure you want to delete this recommendation?
            </ModalHeader>
            <ModalBody style={{ textAlign: 'center', borderTop: 'none', marginBottom: 30, display: "flex", justifyContent: "center" }}>
                <button
                    className='defaultWhiteButton'
                    style={{ width: "30%", height: "42px", marginRight: 10 }}
                    onClick={() => { toggle_deleteRecommendationModal(false) }}
                >
                    Cancel
                </button>
                <button
                    className="defaultGreenButton"
                    style={{ width: "30%", height: "42px" }}
                    onClick={() => { deleteRecommendation() }}
                >
                    Delete
                </button>
            </ModalBody>
        </Modal>
    )
}
