import algoliasearch from 'algoliasearch';
import React from 'react';
import { connectStateResults, Hits, InstantSearch, Pagination, Panel, RefinementList, SearchBox, Stats } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import '../../Styles/Algolia.css';
import '../../Styles/MarketplaceSearch_v2/MarketplaceSearch_v2.css';
import '../../Styles/Pagination.css';
import '../../Styles/SearchListings.css';
import NoResultsImage from "../../_assets/img/Character7.svg";
import { asyncLocalStorage, marketplaceProjectService, pluginService } from '../../_services';
import CustomCurrentRefinements from "../SearchListings/CurrentRefinements";
import CustomRangeInput from "../SearchListings/CustomRangeInput";
import { Loader as Loading } from '../Shared/Loader';
import { MarketplaceResult_v2 } from './MarketplaceResult_v2';

import megaphone from "../../_assets/img/megaphone.png"

const searchClient = algoliasearch('MWF97BUAPS', '943f0970ab9c9143012e9172983aacdc');

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class ProjectFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Service_Providers: null,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
      currentSearchState: {},
      mobileMarketplaceSearch: false,
      width: window.innerWidth,
      clearMinMax: false,

      hideNewProductBanner: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.MarketplaceResult = this.MarketplaceResult.bind(this);
  }

  async componentDidMount() {

    const projects = await marketplaceProjectService.get_all_projects();

    this.setState({
      projects
    }, () => {
      asyncLocalStorage.getItem('iFrameConfig').then((value) => {
        this.setState({ iFrameConfig: value })
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `.grant-search .ais-SearchBox-submit { background-color: ${value.primary || '#0C7069'}; border: 1px solid ${value.primary || '#0C7069'} }`;

        document.getElementsByClassName('ais-SearchBox-submit')[0].appendChild(style);
      });
    });

    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async toggle() {
    this.setState({
      mobileMarketplaceSearch: !this.state.mobileMarketplaceSearch
    });
  }

  async goToUpgrade() {
    window.location.href = pluginService.redirect("/settings?val=billing");
  }

  async updateCurrentSearch(searchState) {
    this.setState({
      currentSearchState: searchState
    });
  };

  handleMinMax = (boolValue) => {
    this.setState({
      clearMinMax: boolValue
    })
  }

  MarketplaceResult(project) {
    document.body.scrollTo(0, 0);

    return <MarketplaceResult_v2
      project={project}
      details={{ title: "View Listing", body: "hello", button: "Apply Now" }}
      tags={[{ title: "focus", tag_list: ["abc", "def"] }]}
    />
  };

  render() {
    const { width, mobileMarketplaceSearch,
      clearMinMax
    } = this.state;
    const { user } = this.props;

    const ProjectResults = connectStateResults(
      ({ searchState, searchResults, children }) =>
        searchResults && searchResults.nbHits !== 0 ? (children) : (
          this.state.loading == false &&
          <div id="noSearchResults">
            <h4>No results found for <strong>{searchState.query}</strong>.</h4>
            <hr align="left" style={{ width: "10%" }} />
            <p>Please enter another query for a program, category, location or agency.</p>
            <br /><br />
            <div style={{ display: "flex" }}>
              <div style={{ width: "fit-content" }}>
                <img id="grantHeroIMG" src={NoResultsImage} style={{ width: 200 }} alt="" />
              </div>
              <div >
                <p style={{ fontWeight: 600, marginLeft: "5%" }}>Didn’t find what you’re looking for?</p>
                <ol id="noSearchHits">
                  <li>
                    Grants can be tricky. Sometimes they’re listed with names that you wouldn’t expect. Try searching again with synonyms or related words.
                  </li>
                  <li>
                    Some grants may simply be out of season. Many grant programs are released on a quarterly, semi-annual or annual basis. If you can’t find anything right now,
                    <a href={pluginService.redirect("/settings?val=organization")} style={{ fontWeight: 600 }}> click here </a>
                    to create an organization profile so we can notify you when new funding is available.
                  </li>
                  <li>
                    If you’d rather bypass the search process and hire an expert instead,
                    <a href={pluginService("/apply")} style={{ fontWeight: 600 }}> click here </a>
                    to submit a (refundable) deposit and meet with a grant writer.
                  </li>
                  <li>
                    Do you want to add a grant listing to our platform?
                    <a href="https://airtable.com/shr1UV3Bit2ZQURnZ" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 600 }}> Fill out this form </a>
                    so we can start tracking it.
                  </li>
                </ol>
                <br />
              </div>
            </div>
          </div>
        )
    );

    return (
      <div id="searchListingsSearchBar">
        {this.state.projects ?
          <>
            {/* <ProjectToS user={user} /> */}
            <h1 id="TalentFinderHeader" className='mb-24'>Project Finder</h1>

            {/* CREATE A PRODUCT BANNER */}
            {!this.state.hideNewProductBanner && this.props.service_Provider &&
              <>
                <div className='mb-24' style={{ backgroundColor: "#F2FCF9", border: "1px solid #00C58E", borderRadius: 5, padding: "20px 5%", boxShadow: "0px 4px 5px rgb(0 0 0 / 10%)", position: "relative" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <h1 style={{ fontFamily: "Lato", fontWeight: "bold", fontSize: 20, color: "#2b2c2d", textAlign: "left" }}>New Feature! Create a Product</h1>
                      <hr />
                      <p style={{ fontFamily: "Lato", fontSize: 16, color: "rgba(43, 44, 45, 0.75)", marginTop: 10 }}>A product is any work with a fixed rate that's predictable and repeatable. Products are listed on your profile and clients can purchase them instantly.</p>
                      <a className="banner-cta" href={pluginService.redirect("/organizations/" + this.props.service_Provider?.organization_Id + "#products")} style={{ marginTop: 10, marginBottom: 20 }}>
                        Learn More
                      </a>
                      <br />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={megaphone} className="new-feature-create-product-img" />
                    </div>
                  </div>
                  <div style={{ position: "absolute", top: 10, right: 10, cursor: "pointer", fontSize: 21, fontWeight: "bold", width: 20, height: 20, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => { this.setState({ hideNewProductBanner: true }) }}>×</div>
                </div>
                <br />
              </>
            }

            <InstantSearch
              searchClient={searchClient}
              indexName="OpenGrants_RFPs"
              searchState={this.state.currentSearchState}
              onSearchStateChange={searchState => {
                this.updateCurrentSearch(searchState);
              }}
            >
              <div className="row">
                <div className="col-12 col-xl-4 mb-24">
                  {width < 1199 &&
                    <div
                      id="MarketplaceSearchMobileToggle"
                      onClick={() => this.toggle()}
                      style={{ margin: "15px 0", width: "100%", marginLeft: 0 }}
                    >
                      <h4 id="mobileSearchFilters">
                        Job Filters
                      </h4>
                      {mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9650;</h4>}
                      {!mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9660;</h4>}
                    </div>
                  }
                  {(width > 1199 || mobileMarketplaceSearch) &&
                    <div
                      id="filterBox"
                      style={{ padding: 15 }}
                    >
                      <h1 id="searchHeader">Job Filters</h1>
                      <br />
                      <br />
                      <Panel header="Applied Filters">
                        <CustomCurrentRefinements />
                      </Panel>
                      <br /><br />
                      <Panel header="Project Budget ($)">
                        <CustomRangeInput
                          min={0}
                          max={100000000000}
                          attribute="project_Budget"
                          clearMinMax={clearMinMax}
                          handleMinMax={this.handleMinMax}
                        />
                        {/* <RangeInput min={0} max={100000000000} attribute="base_Hourly_Rate" /> */}
                      </Panel>
                      <br /><br />
                      <Panel header="Focus">
                        <RefinementList
                          attribute="focus_Tags.industry_Focus"
                          searchable={true}
                          limit={5}
                          showMore={true}
                          showMoreLimit={30}
                          translations={{
                            showMore(expanded) {
                              return expanded ? 'Show less' : 'Show more';
                            },
                            noResults: 'No results',
                            submitTitle: 'Submit your search query.',
                            resetTitle: 'Clear your search query.',
                            placeholder: 'Search focus...',
                          }}
                        />
                      </Panel>
                      <br /><br />
                      <Panel header="Services">
                        <RefinementList
                          attribute="service_Tags.industry_Service"
                          searchable={true}
                          limit={5}
                          showMore={true}
                          showMoreLimit={30}
                          translations={{
                            showMore(expanded) {
                              return expanded ? 'Show less' : 'Show more';
                            },
                            noResults: 'No results',
                            submitTitle: 'Submit your search query.',
                            resetTitle: 'Clear your search query.',
                            placeholder: 'Search service types...',
                          }}
                        />
                      </Panel>
                      <br /><br />
                    </div>
                  }
                </div>
                <div className="col-12 col-xl-8">
                  <div className='grant-search'>
                    <div className='grant-search-bar-div'>
                      <SearchBox
                        autoFocus={true}
                        searchAsYouType={true}
                        showLoadingIndicator={true}
                        translations={{ placeholder: 'Search by service, focus area or project name...' }}
                      />
                    </div>
                    {/* <VoiceSearch /> */}
                  </div>
                  <Stats />
                  <br />
                  <ProjectResults>
                    <Hits
                      hitComponent={this.MarketplaceResult}
                      style={{ width: "100%!important" }}
                      id="searchHits"
                    />
                  </ProjectResults>
                  <Pagination
                    // Optional parameters
                    defaultRefinement={1}
                    showFirst={true}
                    showPrevious={true}
                    showNext={true}
                    showLast={true}
                    padding={5}
                  />
                </div>
              </div>
            </InstantSearch>
          </>
          :
          <Loading />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { service_Provider } = state.serviceprovider_getserviceprovider;

  return {
    user,
    service_Provider
  };
}

const connectedAlgoliaPage = connect(mapStateToProps)(ProjectFinder);
export { connectedAlgoliaPage as ProjectFinder };

