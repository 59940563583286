import { stripeConstants } from '../_constants';
import { sharedService } from '../_services';

export function billingInfo(state = {
    billingAddress: {},
    card: {},
    bank_Account: {},
    updatePaymentMethod: false,
    paymentSelection: 'card', //set to '' when add ach
    bank_verfication_request: false,
    bank_verification_status: false,
    bank_verification_notification: false,
    loading: true,
    loading_getbilling: 'initial',
    loading_billingAdress: false,
    loading_bankRequest: false,
}
    , action) {
    switch (action.type) {
        case stripeConstants.BILLING_REQUEST:
            return {
                ...state,
                loading: true,
                loading_getbilling: true
            }
        case stripeConstants.BILLING_SUCCESS:
            return {
                ...state,
                billingAddress: {},
                card: {},
                bank_Account: {},
                ...action.billingInfo,
                paymentSelection: sharedService.getPaymentSelection(action.billingInfo),
                updatePaymentMethod: false,
                loading: false,
                loading_getbilling: false
            }
        case stripeConstants.BILLING_FAILURE:
            return {
                ...state,
                loading: false,
                loading_getbilling: false
            }

        case stripeConstants.UPDATE_BILLING_INFO_REQUEST:
            return {
                ...state,
                loading: false,
                updatePaymentMethod: true,
                paymentSelection: action.paymentSelection
            }
        case stripeConstants.UPDATE_BILLING_INFO_RESET:
            return {
                ...state,
                loading: false,
                updatePaymentMethod: false,
                paymentSelection: 'card' //set to '' when add ach
            }
        case stripeConstants.BILLING_ADDRESS_REQUEST:
            return { ...state, loading_billingAdress: true };
        case stripeConstants.BILLING_ADDRESS_SUCCESS:
            return { ...state, status: "success", loading_billingAdress: false, billingAddress: action.billingAddress };
        case stripeConstants.BILLING_ADDRESS_FAILURE:
            return { ...state, status: "failure", loading_billingAdress: false };

        case stripeConstants.BANK_VERIFICATION_SUCCESS:
            return {
                ...state,
                billingAddress: {},
                card: {},
                bank_Account: {},
                ...action.billingInfo,
                loading_bankRequest: false
            }

        case stripeConstants.BANK_VERIFICATION_REQUEST:
            return {
                ...state,
                bank_verfication_request: true,
                bank_verification_status: false,
                loading_bankRequest: true
            }

        case stripeConstants.BANK_VERIFICATION_RESET:
            return {
                ...state,
                loading_bankRequest: false,
            }

        case stripeConstants.BANK_VERIFICATION_NOTIFICATION:
            return {
                ...state,
                bank_verification_notification: action.bank_verification_notification
            }

        default:
            return state;
    }
}

export function billingHandler(state = { status: "", loading_getPlan: 'initial' }, action) {
    switch (action.type) {
        case stripeConstants.SET_PAYMENT_SUBMISSION_HANDLER:
            return {
                ...state,
                handlePaymentSubmission: {
                    ...state.handlePaymentSubmission,
                    ...action.handler.handlePaymentSubmission
                },
                submitPayment: {
                    ...state.submitPayment,
                    ...action.handler.submitPayment
                },
                isPaymentValid: {
                    ...state.isPaymentValid,
                    ...action.handler.isPaymentValid
                }
            }
        case stripeConstants.SET_HANDLE_PAYMENT_SUBMISSION:
            return {
                ...state,
                handlePaymentSubmission: {
                    ...state.handlePaymentSubmission,
                    ...action.handlePaymentSubmission
                }
            }
        case stripeConstants.SET_PAYMENT_SUBMISSION:
            return {
                ...state,
                submitPayment: {
                    ...state.submitPayment,
                    ...action.submitPayment
                }
            }
        case stripeConstants.SET_IS_PAYMENT_VALID:
            return {
                ...state,
                isPaymentValid: {
                    ...state.isPaymentValid,
                    ...action.isPaymentValid
                }
            }
        case stripeConstants.SET_HANDLE_ADDRESS_SUBMISSION:
            return {
                ...state,
                handleAddressSubmission: {
                    ...state.handleAddressSubmission,
                    ...action.handleAddressSubmission
                }
            }
        case stripeConstants.SET_ADDRESS_SUBMISSION:
            return {
                ...state,
                submitAddress: {
                    ...state.submitAddress,
                    ...action.submitAddress
                }
            }
        case stripeConstants.SET_IS_ADDRESS_VALID:
            return {
                ...state,
                isAddressValid: {
                    ...state.isAddressValid,
                    ...action.isAddressValid
                }
            }
        case stripeConstants.SET_PAYMENT_HANDLER:
            return {
                ...state,
                paymentHandler: action.paymentHandler
            }
        case stripeConstants.SET_PRICE_IDS:
            return {
                ...state,
                priceIds: action.priceIds
            }
        case stripeConstants.SET_COUPON:
            return {
                ...state,
                ...action.coupon
            }
        case stripeConstants.SET_AGREEMENTS:
            return {
                ...state,
                agreements: action.agreements
            }
        case stripeConstants.SET_PLAN_REQUEST:
            return {
                ...state,
                loading_getPlan: true
            }
        case stripeConstants.SET_PLAN:
            return {
                ...state,
                plan: action.plan,
                loading_getPlan: false
            }

        default:
            return state;
    }
}

export function createStripeAccount(state = { status: "", loading: false }, action) {
    switch (action.type) {
        case stripeConstants.CONNECT_REQUEST:
            return { status: "", loading: true };
        case stripeConstants.CONNECT_SUCCESS:
            return { status: "success", loading: false };
        case stripeConstants.CONNECT_FAILURE:
            return { status: "failure", loading: false };
        default:
            return state;
    }
};

export function handleStripeToken(state = { status: "", loading: "false", billingAddress: {} }, action) {
    switch (action.type) {
        case stripeConstants.TOKEN_REQUEST:
            return { loading: true };
        case stripeConstants.TOKEN_SUCCESS:
            return { status: "success", loading: false, billingAddress: action.billingAddress };
        case stripeConstants.TOKEN_FAILURE:
            return { status: "failure", loading: false };
        default:
            return state;
    }
};

export function getBillingAddress(state = { status: "", loading: "false", billingAddress: {} }, action) {
    switch (action.type) {
        case stripeConstants.BILLING_ADDRESS_REQUEST:
            return { loading: true };
        case stripeConstants.BILLING_ADDRESS_SUCCESS:
            return { status: "success", loading: false, billingAddress: action.billingAddress };
        case stripeConstants.BILLING_ADDRESS_FAILURE:
            return { status: "failure", loading: false };
        default:
            return state;
    }
};

export function purchaseProduct(state = { purchaseSuccess: false, message: "", statusType: "", loading: false }, action) {
    switch (action.type) {
        case stripeConstants.PURCHASE_PRODUCT_REQUEST:
            return {
                purchaseSuccess: false,
                message: "",
                statusType: "",
                loading: true
            };
        case stripeConstants.PURCHASE_PRODUCT_SUCCESS:
            return {
                purchaseSuccess: true,
                message: action.result.message,
                statusType: action.result.statusType,
                userRoles: action.result.userRoles,
                loading: false
            };
        case stripeConstants.PURCHASE_PRODUCT_FAILURE:
            return {
                purchaseSuccess: false,
                message: action.result.message,
                statusType: action.result.statusType,
                userRoles: action.result.userRoles,
                loading: false
            };
        case stripeConstants.PURCHASE_PRODUCT_RESET:
            return { purchaseSuccess: "reset", message: "", statusType: "", loading: false }
        default:
            return state;
    }
}

export function updateBilling(state = { status: "", loading: false }, action) {
    switch (action.type) {
        case stripeConstants.UPDATE_BILLING_REQUEST:
            return { status: false, loading: true };
        case stripeConstants.UPDATE_BILLING_SUCCESS:
            return { status: true, loading: false }
        case stripeConstants.UPDATE_BILLING_FAILURE:
            return { status: false, loading: false }
        case stripeConstants.UPDATE_BILLING_RESET:
            return { status: "reset", loading: false }
        default:
            return state;
    }
}

export function cancelSubscription(state = { status: "", loading: false }, action) {
    switch (action.type) {
        case stripeConstants.CANCEL_SUBSCRIPTION_REQUEST:
            return { status: false, loading: true };
        case stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS:
            return { status: true, loading: false }
        case stripeConstants.CANCEL_SUBSCRIPTION_FAILURE:
            return { status: false, loading: false }
        case stripeConstants.CANCEL_SUBSCRIPTION_RESET:
            return { status: "reset", loading: false }
        default:
            return state;
    }
}

export function updateSubscription(state = { status: "", loading: false }, action) {
    switch (action.type) {
        case stripeConstants.UPDATE_SUBSCRIPTION_REQUEST:
            return { status: false, loading: true };
        case stripeConstants.UPDATE_SUBSCRIPTION_SUCCESS:
            return { status: true, loading: false }
        case stripeConstants.UPDATE_SUBSCRIPTION_FAILURE:
            return { status: false, loading: false }
        case stripeConstants.UPDATE_SUBSCRIPTION_RESET:
            return { status: "reset", loading: false }
        default:
            return state;
    }
}

export function paywallAlert(state = { status: "" }, action) {
    switch (action.type) {
        case stripeConstants.PAYWALL_ALERT:
            return { status: true }
        default:
            return state;
    }
}

export function stripeCheckout(state = { termsAndConditions: false, message: '' }, action) {
    switch (action.type) {
        case stripeConstants.UPDATE_TERMS_AND_CONDITIONS:
            return { ...state, termsAndConditions: action.termsAndConditions };
        case stripeConstants.UPDATE_CHECKOUT_MESSAGE:
            return { ...state, message: action.message };
        default:
            return state;
    }
}

export function stripeVerified(state = { loading: 'initial' }, action) {
    switch (action.type) {
        case stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_REQUEST:
            return {
                loading: true
            }
        case stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_SUCCESS:
            return {
                loading: false,
                stripe_Verified: action.stripe_Verified
            }
        case stripeConstants.CHECK_STRIPE_CONNECT_VERIFICATION_FAILURE:
            return {
                loading: false
            }
        default:
            return state;
    }
}

export function stripeInvoices(state = { invoices: [], loading: true }, action) {
    switch (action.type) {
        case stripeConstants.GET_ALL_INVOICES_REQUEST:
            return {
                invoices: [],
                loading: true
            }
        case stripeConstants.GET_ALL_INVOICES_SUCCESS:
            return {
                invoices: action.invoices,
                loading: false
            }
        case stripeConstants.GET_ALL_INVOICES_FAILURE:
            return {
                invoices: [],
                loading: false
            }
        default:
            return state;
    }
}