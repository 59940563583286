import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import he from '../../../node_modules/he';
import TextTruncate from '../../../node_modules/react-text-truncate';
import '../../Styles/Algolia.css';
import '../../Styles/MarketplaceSearch_v2/MarketplaceSearch_v2.css';
import shoppingBasket from "../../_assets/img/icons/shoppingBasket.svg";
import { pluginService } from '../../_services';
import ServiceProviderModal from "./ServiceProviderModal.jsx";

export const unixDate = (timestamp) => {
  var a = new Date(timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ', ' + year;

  return time;
};

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <div>
      {parsedHit.map(
        part => (part.isHighlighted ? <mark>{part.value}</mark> : part.value)
      )}
    </div>
  );
});

let primaryAccentColor = pluginService.getIFrameColor();
let secondaryAccentColor = pluginService.getIFrameColor(true);

class MarketplaceResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorite: false,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      openModal: false,
    };
    // this.saveservice_Provider = this.saveservice_Provider.bind(this);
  }

  viewservice_Provider = (id) => {
    // const server = window.location.protocol + '//' + window.location.host;
    // const source_link = server + "/service-provider/" + id;

    // window.open(source_link, "_blank")

    const { openModal } = this.state

    this.setState({ openModal: !openModal })
  };

  render() {
    const service_Provider = this.props.service_Provider.hit;
    // const bio = stripHtml(service_Provider.bio, {
    //                        stripTogetherWithTheirContents: [
    //                          'script', // default
    //                          'style',  // default
    //                          'xml',    // default
    //                          'pre', // <-- custom-added
    //                        ]}
    //                      ).result;

    return (
      <div id="searchHitItem">
        <div>
          <div className="spContainer">
            {/* SP PROFILE IMAGE */}
            <div className="spImageDiv">
              <div style={{ position: "relative" }}>
                <img
                  src={service_Provider.logo_URL ? service_Provider.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"}
                  className="spLogo"
                />
              </div>
            </div>

            {/* SP INFORMATION */}
            <div className="spInfoDiv">

              {/* TITLE */}
              <div className="hit-title">
                <CustomHighlight attribute="title" hit={service_Provider} />
                <div className="hit-header" style={{ display: "flex", justifyContent: "space-between" }}>
                  <TextTruncate
                    line={2}
                    element="class"
                    truncateText="…"
                    text={he.decode(service_Provider.name)}
                    style={{ fontWeight: 900, display: "block" }}
                  />
                  <button
                    color={"#29a"}
                    onClick={() => { this.viewservice_Provider(service_Provider.objectID) }}
                    style={{ fontSize: 14, float: 'right', padding: 0, marginTop: 0, color: primaryAccentColor || '#0C7069', background: 'transparent', boxShadow: 'none', fontWeight: 'bold' }}
                    className="hitViewProfileBtn"
                  >
                    View Profile
                  </button>
                </div>
                <hr style={{ marginBottom: '10px', marginTop: '10px' }} />
              </div>

              {/* ABOUT */}
              <div className="spAboutDiv" >
                <p className="TaletFinderTitle">About Me</p>
                <TextTruncate
                  line={3}
                  element="class"
                  truncateText="…"
                  text={service_Provider.bio && he.decode(service_Provider.bio.trim())}
                  style={{ color: "#333", fontSize: 16, clear: "both", display: "block" }}
                />
                <br />

                {/* TAGS */}
                <div className="spTags">

                  {/* FOCUS AREAS */}
                  <div className="focusAreasTags">
                    {service_Provider.sP_Industry_Focus_Tags &&
                      service_Provider.sP_Industry_Focus_Tags.length > 0 &&
                      <div>
                        <p className="TaletFinderTitle">
                          Focus Areas
                        </p>
                      </div>
                    }
                    {service_Provider.sP_Industry_Focus_Tags && service_Provider.sP_Industry_Focus_Tags.map(service => (
                      <div style={{ background: secondaryAccentColor || '#ecf4ff' }} id="wtfTag">
                        <p style={{ display: "inline" }}>{service.trim()}</p>
                      </div>
                    ))}
                  </div>
                  <br />

                  {/* SERVICES */}
                  <div className="servicesTags">
                    {service_Provider.sP_Industry_Services_Tags &&
                      service_Provider.sP_Industry_Services_Tags.length > 0 &&
                      <div>
                        <p className="TaletFinderTitle">
                          Services
                        </p>
                      </div>
                    }
                    {service_Provider.sP_Industry_Services_Tags && service_Provider.sP_Industry_Services_Tags.map(service => (
                      <div style={{ background: secondaryAccentColor || '#ecf4ff' }} id="wtfTag">
                        <p style={{ display: "inline" }}>{service.trim()}</p>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <br />

          {/* TEMPORARILY NOT SHOWN (because of multiple sps having one organization's info) */}

          {/* <div className="row" style={{ padding: 0 }}>
            {service_Provider.sP_Industry_Focus_Tags &&
              service_Provider.sP_Industry_Focus_Tags.length > 0 &&
              <div>
                <p className="TaletFinderTitle">
                  Organization
                </p>
              </div>
            }
            <img
              src={service_Provider.logo_URL ? service_Provider.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"}
              width="70" height="70"
            />
          </div> */}

          {this.state.openModal &&
            <ServiceProviderModal
              openModal={this.state.openModal}
              service_Provider={service_Provider}
              viewservice_Provider={this.viewservice_Provider}
            />
          }
        </div>
        <br />
        <hr />
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedResult = connect(mapStateToProps)(MarketplaceResult);
export { connectedResult as MarketplaceResult };

