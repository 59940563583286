import React, { useEffect, useState } from "react";
import { asyncLocalStorage, funderService, listingService } from "../../_services";
import { GrantDetails } from "./GrantDetails";
import { Loader } from "../Shared/Loader";

export const GrantSingle = ({
}) => {
  const [iFrameConfig, setIFrameConfig] = useState({});
  const [listing, setListing] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    await Promise.all([
      getIFrameConfig(),
      getGrant()
    ]);

    setLoading(false);
  }

  const getIFrameConfig = () => {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      setIFrameConfig(value)
    });
  }

  const getGrant = async () => {
    const pathname = window.location.pathname;
    const listingId = window.location.pathname.split('/').pop();
    let dbListing;
    if (pathname.includes('grants')) dbListing = await listingService.getListing(listingId);
    else if (pathname.includes('funders')) dbListing = await funderService.get(listingId);
    setFavorites(dbListing.listingFavorites || dbListing.favorites);
    setListing(dbListing);
  }

  return (<>
    {loading && <Loader />}
    {listing && <GrantDetails
      iFrameConfig={iFrameConfig}

      listing={listing}
      favorites={favorites}
    />}
  </>)
}