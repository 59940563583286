import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { sharedService } from './shared.service';

export const perksService = {
    get_all_perks,
    add_perk,
    update_perk,
    delete_perk
};

function get_all_perks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/perks/get_all_perks', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function add_perk(perk) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(perk)
    };

    return fetch(API_Route + '/api/perks/add_perk', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function update_perk(perk) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(perk)
    };

    return fetch(API_Route + '/api/perks/update_perk', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function delete_perk(perk_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/perks/delete_perk?perk_id=' + perk_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}