import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { connect } from 'react-redux';
import { pluginService, stripeService } from '../../../../../_services';
import { Loader as Loading } from '../../../../Shared/Loader';

let accentColor = pluginService.getIFrameColor()

class SP_Payout_Account_cad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit_Payout_Method: false,
            bank_Account: {},

            submitted: false,
            loading: false
        };
    }

    async componentDidMount() { }

    editPayoutMethod = () => {
        this.setState({
            edit_Payout_Method: true,
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { bank_Account } = this.state;

        this.setState({
            bank_Account: {
                ...bank_Account,
                [name]: value
            }
        });
    }

    updatePaymentAccount = async () => {
        this.setState({ loading: true, submitted: true, errorMessage: '' });

        // const stripe = await loadStripe(window.stripeKey);
        const stripe = await loadStripe(window.stripeKey);
        const { service_Provider, stripe_Verified } = this.props;
        const { bank_Account } = this.state;

        if (!bank_Account.account_Holder_Name
            || !bank_Account.account_Holder_Type
            || !bank_Account.transit_Number || !bank_Account.institution_Number
            || !bank_Account.account_Number) {
            this.setState({ loading: false })
            return;
        }

        await stripe.createToken('bank_account', {
            country: stripe_Verified.country,
            currency: stripe_Verified.currency,
            routing_number: bank_Account.transit_Number.trim() + bank_Account.institution_Number.trim(),
            account_number: bank_Account.account_Number.trim(),
            account_holder_name: bank_Account.account_Holder_Name,
            account_holder_type: bank_Account.account_Holder_Type,
        })
            .then(async (result) => {
                if (result.error) {
                    this.setState({
                        errorMessage: "Unable to update account. Please verify that your information is correct and re-submit.",
                        loading: false
                    })

                    return;
                }
                await stripeService.updateStripeConnectPaymentAccount(service_Provider.stripe_Account_Id, result.token.id);

                if (this.props.handleClosing) {
                    this.setState({
                        loading: false
                    })
                    this.props.handleClosing();
                }
                else {
                    window.location.reload();
                }
            });
    }

    render() {
        const { stripe_Verified } = this.props;
        const { bank_Account, edit_Payout_Method, submitted, loading, errorMessage } = this.state;
        const stripe_Payout_Account = stripe_Verified.external_Accounts.data[0];

        return (
            <div style={{ maxWidth: 750 }} className="editPayoutMethod" id="service_provider_onboarding_page">
                {loading && <Loading />}
                <div >
                    {stripe_Payout_Account &&
                        <div>
                            <h1>Payout Methods</h1>
                            <hr />
                        </div>
                    }
                    {stripe_Payout_Account && stripe_Payout_Account.object == "bank_account" &&
                        <div>
                            <div>
                                <p><strong style={{ marginRight: "10px" }}>Bank Name:</strong> {stripe_Payout_Account.bank_name}</p>
                                <p><strong style={{ marginRight: "10px" }}>Account Holder Name:</strong> {stripe_Payout_Account.account_holder_name}</p>
                                <p><strong style={{ marginRight: "10px" }}>Account Holder Type:</strong> {stripe_Payout_Account.account_holder_type}</p>
                                <p><strong style={{ marginRight: "10px" }}>Account Number:</strong> ****-***-{stripe_Payout_Account.last4}</p>
                                <p><strong style={{ marginRight: "10px" }}>Routing Number:</strong> {stripe_Payout_Account.routing_number}</p>
                            </div>
                            <br />
                            {!edit_Payout_Method &&
                                <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={this.editPayoutMethod}>
                                    Edit Payout Method
                                </button>}
                        </div>
                    }
                    {(edit_Payout_Method == true || !stripe_Payout_Account) && <div>

                        <div id="newPaymentMethod">
                            {!stripe_Payout_Account &&
                                <div>
                                    <h1>Payout Methods</h1>
                                    <p>Add your account information to get paid for your work</p>
                                    <hr />
                                </div>
                            }
                            {stripe_Payout_Account &&
                                <div>
                                    <h1>Update Account Information</h1>
                                    <hr />
                                </div>
                            }

                            <p className="payout-method-form-input-label">Account Holder Name</p>
                            <input
                                required
                                className="payout-method-form-input"
                                name="account_Holder_Name"
                                value={bank_Account.account_Holder_Name}
                                onChange={this.handleChange}
                            />
                            {submitted && !bank_Account.account_Holder_Name && <p style={{ color: "red" }}>This is required.</p>}

                            <p className="payout-method-form-input-label">Account Holder Type</p>
                            <select
                                className="payout-method-form-select"
                                name="account_Holder_Type"
                                value={bank_Account.account_Holder_Type}
                                onChange={this.handleChange}
                            >
                                <option value={""}>Select</option>
                                <option value={"Company"}>Company</option>
                                <option value={"Individual"}>Individual</option>
                            </select>
                            {submitted && !bank_Account.account_Holder_Type && <p style={{ color: "red" }}>This is required.</p>}

                            <p className="payout-method-form-input-label">Account Number</p>
                            <input
                                required
                                className="payout-method-form-input"
                                name="account_Number"
                                value={bank_Account.account_Number}
                                onChange={this.handleChange}
                            />
                            {submitted && !bank_Account.account_Number && <p style={{ color: "red" }}>This is required.</p>}

                            <p className="payout-method-form-input-label">Transit Number</p>
                            <input
                                required
                                className="payout-method-form-input"
                                name="transit_Number"
                                value={bank_Account.transit_Number}
                                onChange={this.handleChange}
                            />
                            {submitted && !bank_Account.transit_Number && <p style={{ color: "red" }}>This is required.</p>}

                            <p className="payout-method-form-input-label">Institution Number</p>
                            <input
                                required
                                className="payout-method-form-input"
                                name="institution_Number"
                                value={bank_Account.institution_Number}
                                onChange={this.handleChange}
                            />
                            {submitted && !bank_Account.institution_Number && <p style={{ color: "red" }}>This is required.</p>}
                        </div>
                        <br />
                        <button className="defaultGreenButton" style={{ padding: "10px 20px", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }} onClick={this.updatePaymentAccount}>
                            Update Account
                        </button>
                        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.setUser;
    const { service_Provider } = state.serviceprovider_getserviceprovider;
    const { stripe_Verified } = state.stripeVerified;
    return {
        user,
        service_Provider,
        stripe_Verified
    };
}

const connectedSP_Payout_Account_cad = connect(mapStateToProps)(SP_Payout_Account_cad);
export { connectedSP_Payout_Account_cad as SP_Payout_Account_cad };

