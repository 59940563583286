import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../Styles/SettingsStyles/Billings.css';
import { organizationstripeActions, stripeActions } from '../../_actions';
import { organizationStripeService, stripeService } from '../../_services';

class Address extends Component {
  constructor(props) {
    super(props);

    const { billingInfo } = props;

    this.state = {
      errorMessage: '',

      // name: '',
      // address_line1: '',
      // address_city: '',
      // address_state: '',
      // zipcode: '',

      name: billingInfo?.billingAddress?.cardHolderName,
      address_line1: billingInfo?.billingAddress?.address,
      address_city: billingInfo?.billingAddress?.city,
      address_state: billingInfo?.billingAddress?.state,
      zipcode: billingInfo?.billingAddress?.zipCode,

      nameError: false,
      address_line1Error: false,
      address_cityError: false,
      address_stateError: false,
      zipcodeError: false,

      submitting: false,
      profileInformationEdit: false,

      loading: true
    }
  }
  cardEl = React.createRef();

  componentDidMount() {
    this.setAddressMethods();
  }

  setAddressMethods = () => {
    const key = this.props.org_Id ? this.props.org_Id : 'user';
    this.props.dispatch(stripeActions.setHandleAddressSubmission({ [key]: this.handleSubmit }));
    this.props.dispatch(stripeActions.setAddressSubmission({ [key]: this.submitAddress }));
    this.props.dispatch(stripeActions.setIsAddressValid({ [key]: this.formIsValid }));
  }

  componentDidUpdate(prevProps) {
    //is updating address
    if (this.props.profileInformationEdit && prevProps.profileInformationEdit != this.props.profileInformationEdit) {
      const { billingInfo } = this.props;

      this.setState({
        name: billingInfo?.billingAddress?.cardHolderName,
        address_line1: billingInfo?.billingAddress?.address,
        address_city: billingInfo?.billingAddress?.city,
        address_state: billingInfo?.billingAddress?.state,
        zipcode: billingInfo?.billingAddress?.zipCode,
      })
    }

    //card updated zipcode
    if (this.props.billingInfo?.zipCode && (this.props.billingInfo?.billingAddress?.zipCode !== prevProps.billingInfo?.billingAddress?.zipCode)) {
      this.setState({
        zipcode: this.props.billingInfo.zipCode
      })
    }

    //update address
    if ((this.props.submittingNewAddress !== prevProps.submittingNewAddress) && this.props.submittingNewAddress === true) {
      this.handleSubmit();
    }
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
      [id + "Error"]: false
    })
  }

  resetInputs = () => {
    this.setState({
      name: '',
      address_line1: '',
      address_city: '',
      address_state: '',
      zipcode: ''
    });
    this.cardEl.current._element.clear();
    document.getElementById("cardForm").getElementsByClassName("error")[0].innerHTML = "";
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  editProfileInformation = (evt) => {
    evt.preventDefault();

    this.setState({
      profileInformationEdit: true
    });
  }

  updateProfileInformation = (evt) => {
    evt.preventDefault();
    this.handleSubmit(evt)
    this.setState({
      profileInformationEdit: false
    });
  }

  updateCardInfo = (evt) => {
    evt.preventDefault();
    // alert('Update Card funcitonality')
  }

  submitAddress = async (billings) => {

    //add/update address with a service call
    if (this.props.updateAddressWithServiceCall) {

      await stripeService.updateBillingAddress(billings)

      //after updating address, submit purchase
      this.props.handleLoading(false);
      this.props.handleIsInfoUpdated(true);
    }
    //add/update address with an action call
    else {
      let billingAddress = '';

      if (this.props.org_Id) {
        billings['Organization_Id'] = this.props.org_Id;
        billingAddress = await organizationStripeService.updateBillingAddress(billings);
        this.props.dispatch(organizationstripeActions.setOrgBillingAddress(billingAddress));
      }
      else {
        const billingAddress = await stripeService.updateBillingAddress(billings);
        this.props.dispatch(stripeActions.setBillingAddress(billingAddress));
      }

      if (this.props.additionalAction) {
        this.props.additionalAction();
      }
      return billingAddress;
    }

    // this.setState({
    //   loading: false,
    //   profileInformationEdit: false
    // });
  }

  handleSubmit = async (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    var billings = this.formIsValid();

    if (billings) {
      this.submitAddress(billings)
    }
    else {
      this.setState({
        loading: false
      })
    }
  };

  formIsValid = () => {
    this.setState({
      submitting: true,
      loading: true
    });

    const billings = {
      cardHolderName: document.getElementById("name").value,
      address: document.getElementById("address_line1").value,
      city: document.getElementById("address_city").value,
      state: document.getElementById("address_state").value,
      zipcode: document.getElementById("zipcode").value,
      user_id: this.props.user.id
    };

    if (billings.cardHolderName && billings.address && billings.city && billings.state && billings.zipcode
    ) {
      return billings;
    }

    if (this.props.handleUpdatingInfo) { this.props.handleUpdatingInfo(false) }
    return '';
  }

  addressExists = (billingAddress, name, address, city, state, zipcode) => {
    if (billingAddress
      && billingAddress["cardHolderName"]
      && billingAddress["address"]
      && billingAddress["city"]
      && billingAddress["state"]
      && billingAddress["zipCode"]
    ) {
      return true;
    }
    return this.showErrors(name, address, city, state, zipcode);
  }

  showErrors = (name, address, city, state, zipcode) => {
    let nameError = false;
    let address_line1Error = false;
    let address_cityError = false;
    let address_stateError = false;
    let zipcodeError = false;

    if (name === "") {
      nameError = true;
    }

    if (address === "") {
      address_line1Error = true;
    }

    if (city === "") {
      address_cityError = true;
    }

    if (state === "") {
      address_stateError = true;
    }

    if (zipcode === "") {
      zipcodeError = true;
    }

    this.setState({
      nameError,
      address_line1Error,
      address_cityError,
      address_stateError,
      zipcodeError
    });

    return !nameError && !address_line1Error && !address_cityError && !address_stateError && !zipcodeError;
  }

  render() {
    const { submitting } = this.state;
    const { billingInfo, editAddress, profileInformationEdit } = this.props;

    return (
      <div>

        {/* Regular styling */}

        {/* Display */}
        {this.props.regularStyling && ((billingInfo.billingAddress && Object.keys(billingInfo.billingAddress).length !== 0) && !editAddress) &&
          <div className="">

            <h3 className="checkoutSubHeading">Billing Address</h3>

            <p>Name: <span>{billingInfo.billingAddress.cardHolderName}</span></p>
            <p>Address: <span>{billingInfo.billingAddress.address}</span></p>
            <p>City: <span>{billingInfo.billingAddress.city}</span></p>
            <p>State: <span>{billingInfo.billingAddress.state}</span></p>
            <p>Zip Code: <span>{billingInfo.billingAddress.zipCode}</span></p>

          </div>
        }

        {/* Edit */}
        {this.props.regularStyling && ((!billingInfo.billingAddress || Object.keys(billingInfo.billingAddress).length === 0) || editAddress) &&
          <div className="billingAddress">

            <h3 className="checkoutSubHeading">Billing Address</h3>

            <div className="row" >
              <div className="col-md-6" style={{ padding: 0 }}>
                <input
                  className="billingInput"
                  id="name"
                  name="CardholderName"
                  placeholder="Cardholder Name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  required
                />
                {submitting && !this.state.name && <span className="errorMessage">Name is required.</span>}
              </div>
              <div className="col-md-6 billingAddressColumn">
                <input
                  className="billingInput"
                  id="address_line1"
                  name="Address"
                  placeholder="Street Address"
                  value={this.state.address_line1}
                  onChange={this.handleInputChange}
                  required
                />
                {submitting && !this.state.address_line1 && <span className="errorMessage">Address is required.</span>}
                {this.state.address_line1 && (this.state.address_line1.toLowerCase().includes("p.o. box")
                  || this.state.address_line1.toLowerCase().includes("po box")
                ) &&
                  <p className="errorMessage">You cannot use a P.O. Box Address.</p>
                }
              </div>
            </div>

            <div className="row billingAddressSecondRow">
              <div className="col-md-6" style={{ padding: 0 }}>
                <input
                  className="billingInput"
                  id="address_city"
                  name="City"
                  placeholder="City"
                  value={this.state.address_city}
                  onChange={this.handleInputChange}
                  required
                />
                {submitting && !this.state.address_city && <span className="errorMessage">City is required.</span>}
              </div>
              <div className="col-md-6 billingAddressColumn">
                <div className="col-md-4" style={{ padding: 0 }}>
                  <input
                    className="billingInput"
                    id="address_state"
                    name="State"
                    placeholder="State"
                    value={this.state.address_state}
                    onChange={this.handleInputChange}
                    required
                  />
                  {submitting && !this.state.address_state && <span className="errorMessage">State is required.</span>}
                </div>
                <div className="col-md-8 billingAddressColumn">
                  <input
                    className="billingInput"
                    id="zipcode"
                    name="Zipcode"
                    placeholder="Zipcode"
                    value={this.state.zipcode}
                    onChange={this.handleInputChange}
                    required
                  />
                  {submitting && !this.state.zipcode && <span className="errorMessage">Zip Code is required.</span>}
                </div>
              </div>
            </div>
          </div>
        }

        {/* Billing page specific styling */}

        {!this.props.regularStyling &&
          <>
            <div id="payment-form">

              <form id="cardForm" className="billingAddress" onSubmit={this.handleSubmit}>
                <div className="error" role="alert">
                  {this.state.errorMessage}
                </div>
                <div className="billingAddress">
                  {!profileInformationEdit ?
                    <>
                      {/* Display */}
                      <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                          Name
                        </div>
                        <div className="col-md-5 mobileEditBtn">
                          {billingInfo?.billingAddress?.cardHolderName || 'N/A'}
                        </div>
                      </div>
                      <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                          Address
                        </div>
                        <div className="col-md-5">
                          <div style={{ display: 'block' }}>{billingInfo?.billingAddress?.address || 'N/A'}</div>
                          <div style={{ display: 'block' }}>{billingInfo?.billingAddress?.city || 'N/A'}</div>
                          <div style={{ display: 'block' }}>{billingInfo?.billingAddress?.state || 'N/A'}</div>
                          <div style={{ display: 'block' }}>{billingInfo?.billingAddress?.zipCode || 'N/A'}</div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {/* Edit */}
                      <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                          Name
                        </div>
                        <div className="col-md-5 mobileEditBtn">
                          <input
                            className="billingInput"
                            id="name"
                            name="CardholderName"
                            placeholder="Name"
                            value={this.state.name} onChange={this.handleInputChange}
                            required
                          />
                          {submitting && !this.state.name && <span className="error">Name is required.</span>}
                        </div>
                      </div>

                      <div className="row paymentRow">
                        <div className="col-md-3 flexColEnd">
                          Address
                        </div>
                        <div className="col-md-5">

                          <div style={{ display: 'block' }}>
                            <input
                              className="billingInput"
                              id="address_line1"
                              name="Address"
                              placeholder="Address"
                              value={this.state.address_line1} onChange={this.handleInputChange}
                              required
                            />
                            {submitting && !this.state.address_line1 && <span className="error">Address is required.</span>}
                            {this.state.address_line1 && (this.state.address_line1.toLowerCase().includes("p.o. box")
                              || this.state.address_line1.toLowerCase().includes("po box")
                            ) &&
                              <p className="errorMessage">You cannot use a P.O. Box Address.</p>
                            }
                          </div>

                          <div style={{ display: 'block' }}>
                            <input
                              className="billingInput"
                              id="address_city"
                              name="City"
                              placeholder="City"
                              value={this.state.address_city} onChange={this.handleInputChange}
                              required
                            />
                            {submitting && !this.state.address_city && <span className="error">City is required.</span>}
                          </div>

                          <div style={{ display: 'block' }}>
                            <input
                              className="billingInput"
                              id="address_state"
                              name="State"
                              placeholder="State"
                              value={this.state.address_state} onChange={this.handleInputChange}
                              required
                            />
                            {submitting && !this.state.address_state && <span className="error">State is required.</span>}
                          </div>

                          <div style={{ display: 'block' }}>
                            <input
                              className="billingInput"
                              id="zipcode"
                              name="Zipcode"
                              placeholder="ZipCode"
                              value={this.state.zipcode} onChange={this.handleInputChange}
                              required
                            />
                            {submitting && !this.state.zipcode && <span className="error">Zip Code is required.</span>}
                          </div>

                        </div>
                      </div>
                    </>
                  }
                </div>
              </form>
            </div>
          </>
        }

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { setUser } = state;
  const { user } = setUser;
  return {
    user,
  };
}

const connectedAddress = connect(mapStateToProps)(Address);
export { connectedAddress as Address };
