import React, { useEffect, useState } from "react";
import Onboarding_Welcome from "./Components/Onboarding_1_Welcome";
import Onboarding_Intro from "./Components/Onboarding_2_Intro";
import Onboarding_GetStarted from "./Components/Onboarding_3_GetStarted";
import Onboarding_Description from "./Components/Onboarding_4_Description";
import Onboarding_Loading from "./Components/Onboarding_5_Loading";
import Onboarding_Complete from "./Components/Onboarding_6_Complete";
import { grantMatchesService, organizationService, settingsService } from "../../../_services";
import { useDispatch, useSelector } from "react-redux";
import { authenticateActions } from "../../../_actions";

const GrantSeekerOnboarding = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.setUser);
  const [step, setStep] = useState(0);
  const [organization, setOrganization] = useState({});
  const [grantMatchesLength, setGrantMatchesLength] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    //set timeout for next page
    setTimeout(() => nextStep(step + 1), 2000);

    await getOrganization();
  }

  const nextStep = async (n) => {
    //do not set timeout for description page
    if (n < 2) {
      setTimeout(() => nextStep(n + 1), 3000)
    }

    //go to next step
    setStep(n);

    //create and generate matches for org
    if (n === 4) {
      await organizationService.createOrganization(user.id, { organization });
      try {
        await grantMatchesService.regenerateEmbeddings();
        await grantMatchesService.regenerateGrantMatches();
      } catch (err) { }
      await getGrantMatches();
      setStep(n + 1);
    }
  }

  const getOrganization = async () => {
    const org = await organizationService.getFirstOrganizationByUser(user.id, true);

    if (org) {
      setOrganization(org);
      await markActionsCompleted(org);
    } else {
      await markActionsCompleted();
    }
    setLoading(false);
  }

  const updateOrganization = (e) => {
    const { name, value } = e.target;

    setOrganization({
      ...organization,
      [name]: value
    });
  }

  const markActionsCompleted = async (org) => {
    //incomplete orgs
    if (org && !org.date_Completed_Onboarding) {
      await organizationService.createOrganization(user.id,
        { organization: { ...org, date_Completed_Onboarding: new Date() } }
      );
    }
    //current incomplete users with no org
    else if (!org && !user.date_Completed_Onboarding) {
      const newUser = { ...user, date_Completed_Onboarding: new Date() }
      await settingsService.updateProfile({
        user: newUser
      })
      dispatch(authenticateActions.setUser(newUser));
    }
  }

  const getGrantMatches = async () => {
    setLoading(true);

    //get matches
    const result = await grantMatchesService.getAllMatches();

    setGrantMatchesLength(result.total);
  }

  const backToDescription = () => {
    setLoading(false)
    setStep(3)
  }

  const onboardingPages = [
    <Onboarding_Welcome />,
    <Onboarding_Intro />,
    <Onboarding_GetStarted nextStep={() => nextStep(3)} />,
    <Onboarding_Description nextStep={() => nextStep(4)} loading={loading} organization={organization} updateOrganization={updateOrganization} />,
    <Onboarding_Loading />,
    <Onboarding_Complete grantMatchesLength={grantMatchesLength} backToDescription={() => backToDescription()} />
  ];

  return (
    onboardingPages[step]
  )
}

export default GrantSeekerOnboarding