import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch';
import React, { useEffect, useState } from 'react';
import { connectStateResults, Hits, InstantSearch, Pagination, Panel, RefinementList, SearchBox, Stats } from 'react-instantsearch-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import '../../Styles/Algolia.css';
import '../../Styles/Pagination.css';
import '../../Styles/SearchListings.css';
import { favoritesActions, upgradeActions } from '../../_actions';
import NoResultsImage from "../../_assets/img/Character7.svg";
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { algoliaService, asyncLocalStorage, favoritesService, listingService, pluginService, searchService } from '../../_services';
import CustomRangeInput from "../SearchListings/CustomRangeInput";
import { unixDate } from '../SearchListings/Result';
import { Loader as Loading } from '../Shared/Loader';
import DateRangePicker from "./DateRangePicker";
import { Result_v2 } from './Result_v2';
import { GrantSearchResult } from '../Grants/GrantSearchResult';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
export const PowerSearch_v2 = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.setUser);
  const [loading, setLoading] = useState(true);
  const [currentSearchState, setCurrentSearchState] = useState({});
  const [savedQueryTitle, setSavedQueryTitle] = useState('');
  const [showQueryDiv, setShowQueryDiv] = useState(false);
  const [mobilePowerSearch, setMobilePowerSearch] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [clearMinMax, setClearMinMax] = useState({});
  const [iFrameConfig, setIFrameConfig] = useState({});
  const [openExportOptions, setopenExportOptions] = useState(false);
  const [newQuery, setNewQuery] = useState(null);
  const [listings, setListings] = useState([]);
  const [prevSearchResults, setSearchResults] = useState({});
  const [saved_Search, setSavedSearch] = useState(JSON.parse(localStorage.getItem('savedQueries')));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let queries = [getIFrameConfig()];
    queries.push(getSavedSearches())

    await Promise.all(queries)

    setLoading(false);

    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    }
  }

  const getIFrameConfig = async () => {
    asyncLocalStorage.getItem('iFrameConfig').then((value) => {
      setIFrameConfig(value);
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `.grant-search .ais-SearchBox-submit { background-color: ${value.primary || '#0C7069'}; border: 1px solid ${value.primary || '#0C7069'} }`;

      document.getElementsByClassName('ais-SearchBox-submit')[0].appendChild(style);
    });
  }

  const getSavedSearches = async () => {
    await algoliaService.getSavedSearches(user);
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const goToUpgrade = () => {
    dispatch(upgradeActions.openUpgradeModal())
  }

  async function loadSavedSearch(savedSearch) {
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to');

    setCurrentSearchState(JSON.parse(savedSearch.value));
    setSavedQueryTitle(savedSearch.label);

    const json_Dates = JSON.parse(savedSearch.value).dateRange;
    if (json_Dates) {
      var json_from_date = json_Dates.from;
      var json_to_date = json_Dates.to;

      sessionStorage.setItem('from', json_from_date);
      sessionStorage.setItem('from_time', json_from_date / 1000);
      sessionStorage.setItem('to', json_to_date);
      sessionStorage.setItem('to_time', json_to_date / 1000);
    }
  };

  async function loadQuerySaver(query, user_Id) {
    setShowQueryDiv(true);
    setNewQuery({
      query: query,
      user_Id: user_Id
    });
  }

  async function saveQuery() {
    setShowQueryDiv(false);

    const title = savedQueryTitle;
    const escapeQuery = JSON.stringify(newQuery.query);
    const user_Id = newQuery.user_Id;

    const searchQuery = {
      "Query_Title": title,
      "Query_Body": escapeQuery,
      "User_Id": user_Id
    };

    const saved_Search = JSON.parse(localStorage.getItem('savedQueries')).filter(save => save.query_Title === title);

    if (saved_Search.length > 0)
      await algoliaService.updateQuery(searchQuery);
    else
      await algoliaService.saveQuery(searchQuery);

    await getSavedSearches();
    setSavedSearch(JSON.parse(localStorage.getItem('savedQueries')))
  };

  const exportSearchResults = async (query, file_type) => {
    var blob = await algoliaService.exportSearchResults(query, file_type);
    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    if (file_type === "csv") {
      aTag.download = "SearchResults.csv"
    } else {
      aTag.download = "SearchResults.xlsx"
    }
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  const userRole = user.userRoles;
  const saved_Search_Select = [];

  saved_Search && saved_Search.forEach(function (search) {
    saved_Search_Select.push({ label: search.query_Title, value: search.query_Body })
  });

  function Result(listing) {
    // document.body.scrollTo(0, 0);

    return <GrantSearchResult
      props={props}
      user={user}
      iFrameConfig={iFrameConfig}
      listing={listing.hit}
    />
  };

  const Results = connectStateResults(
    ({ searchState, searchResults }) => {
      if (searchResults && searchResults.nbHits !== 0
      ) {
        if (searchResults.nbHits !== prevSearchResults.nbHits
          || searchResults.nbPages !== prevSearchResults.nbPages
          || searchResults.page !== prevSearchResults.page) {
          setSearchResults(searchResults);
          setListings(searchResults.hits);
        }
      }
      else if (loading === false && listings.length > 0) {
        setSearchResults(searchResults);
        setListings([]);
        return <div id="noSearchResults">
          <h4>No results found for <strong>{searchState.query}</strong>.</h4>
          <hr align="left" style={{ width: "10%" }} />
          <p>Please enter another query for a program, category, location or agency.</p>
          <br /><br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "fit-content" }} >
              <img id="grantHeroIMG" alt="" src={NoResultsImage} style={{ width: 200 }} />
            </div>
            <div >
              <p style={{ fontWeight: 600, marginLeft: "5%" }}>Didn’t find what you’re looking for?</p>
              <ol id="noSearchHits">
                <li>
                  Grants can be tricky. Sometimes they’re listed with names that you wouldn’t expect. Try searching again with synonyms or related words.
                </li>
                <li>
                  Some grants may simply be out of season. Many grant programs are released on a quarterly, semi-annual or annual basis. If you can’t find anything right now,
                  <a href={pluginService.redirect("/grant-seeker/onboarding")} style={{ fontWeight: 600 }}> click here </a>
                  to create an organization profile so we can notify you when new funding is available.
                </li>
                <li>
                  If you’d rather bypass the search process and hire an expert instead,
                  <a href={pluginService.redirect("/marketplace")} style={{ fontWeight: 600 }}> click here </a>
                  to search for a grant writer.
                </li>
                <li>
                  Do you want to add a grant listing to our platform?
                  <a href="https://airtable.com/shr1UV3Bit2ZQURnZ" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 600 }}> Fill out this form </a>
                  so we can start tracking it.
                </li>
              </ol>
              <br />
            </div>
          </div>
        </div>
      }
      return null;
    }
  );

  return (
    <>
      {loading && <Loading />}

      <div className="row" id="searchListingsSearchBar" style={{ marginTop: "0" }}>
        <div className="row mb-24">
          <div className="col-md-8">
            <h1 id="TalentFinderHeader">Grant Finder</h1>
          </div>
        </div>
        <InstantSearch
          searchClient={searchClient}
          indexName="OpenGrants_Dataset"
          searchState={{ ...currentSearchState, refinementList: { ...currentSearchState.refinementList, current_Status: ['Posted'] } }}
          searchAsYouType={false}
          onSearchStateChange={searchState => {
            setCurrentSearchState(searchState);
          }}
        >
          <div className="row TalentFinderContainer flex-xl-row-reverse">
            {!iFrameConfig?.url && (
              <div
                // id="powerSearchDesktop"
                className="col-12 col-xl-4 powerSearchCol mb-24"
              >
                {width < 1199 &&
                  <div
                    id="powerSearchMobileToggle"
                    className='mb-24'
                    onClick={() => { setMobilePowerSearch(!mobilePowerSearch) }}
                    style={{ marginBottom: mobilePowerSearch ? -1 : 15, width: "100%", marginLeft: 0 }}
                  >
                    <h4 id="mobileSearchFilters">
                      POWER SEARCH
                    </h4>
                    {mobilePowerSearch && <h4 style={{ display: "inline", fontSize: "20px", marginLeft: "10px", color: "#666" }}>&#9650;</h4>}
                    {!mobilePowerSearch && <h4 style={{ display: "inline", fontSize: "20px", marginLeft: "10px", color: "#666" }}>&#9660;</h4>}
                  </div>
                }
                <div
                  className={(width > 1199 || mobilePowerSearch) ? 'd-block' : 'd-none'}
                  id={"filterBox"}
                  style={{ padding: 15, width: "105%" }}
                >
                  <h5 className="powerSearchTitle fs-3">Power Search</h5>
                  <div className="row fs-3" style={{ padding: 0, marginTop: '10%' }}>
                    <button onClick={() => { loadQuerySaver(currentSearchState, user.id) }} className="powerSearchBtn text-start fs-4">
                      <i className="icon-floppy" /><b>Save Current Query</b>
                    </button>
                  </div>

                  <div className="row" style={{ padding: 0, marginTop: '2.5%', marginBottom: '10%' }}>
                    <Dropdown className="powerSearchBtn" isOpen={openExportOptions} toggle={() => setopenExportOptions(!openExportOptions)}>
                      <DropdownToggle variant="success" id="dropdown-basic">
                        <i className="icon-export fs-4" /><b className='fs-4'>Export Results</b>
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem onClick={() => exportSearchResults(currentSearchState, "excel")}>Excel</DropdownItem>
                        <DropdownItem onClick={() => exportSearchResults(currentSearchState, "csv")}>CSV</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  {/* <div className="row" style={{ paddingLeft: '5%', paddingRight: '5%' }}> */}
                  <Panel header="Saved Searches">
                    <Select
                      id="saved_Searches_Select"
                      name="saved_Searches"
                      isClearable={false}
                      isSearchable={true}
                      defaultValue={{ label: "Your Saved Search Queries", value: "" }}
                      onChange={(search) => loadSavedSearch(search)}
                      options={saved_Search_Select}
                    />
                  </Panel>
                  {/* </div> */}
                  <br /><br />
                  {/* <div className="row" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                      <Panel header="Applied Filters">
                        <CustomCurrentRefinements />
                      </Panel>
                    </div>
                    <br /><br /> */}
                  <div className="row center" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <Panel header="Funding Amount ($)">
                      <div className="center">
                        <CustomRangeInput
                          min={0}
                          max={100000000000}
                          attribute="funding_Amount"
                          clearMinMax={clearMinMax}
                          handleMinMax={(val) => setClearMinMax(val)}
                          savedRange={currentSearchState}
                        />
                      </div>
                    </Panel>
                  </div>

                  <br /><br />
                  <div className="center">

                    <Panel header="Due Dates">
                      <div className="center">
                        <DateRangePicker
                          attributes={
                            {
                              from: "application_Due_Date",
                              to: "application_Due_Date"
                            }
                          }
                          restrictResultsToStrictRange
                        />
                      </div>
                    </Panel>
                  </div>
                  <br /><br />
                  <Panel header="Agencies">
                    <div style={{ width: "100%" }}>
                      <RefinementList
                        attribute="agency"
                        searchable={true}
                        limit={5}
                        showMore={true}
                        showMoreLimit={30}
                        translations={{
                          showMore(expanded) {
                            return expanded ? 'Show less' : 'Show more';
                          },
                          noResults: 'No results',
                          submitTitle: 'Submit your search query.',
                          resetTitle: 'Clear your search query.',
                          placeholder: 'Search Agencies...',
                        }}
                      />
                    </div>
                  </Panel>
                  <br /><br />
                  {/* <Panel header="Countries">
                      <RefinementList
                        attribute="locations.country"
                        searchable={true}
                        limit={3}
                        showMore={true}
                        showMoreLimit={30}
                        style={{ color: "#000!important" }}
                        translations={{
                          showMore(expanded) {
                            return expanded ? 'Show less' : 'Show more';
                          },
                          noResults: 'No results',
                          submitTitle: 'Submit your search query.',
                          resetTitle: 'Clear your search query.',
                          placeholder: 'Search Countries...',
                        }}
                      />
                    </Panel>
                    <br /><br /> */}
                  <Panel header="States">
                    <RefinementList
                      attribute="states"
                      searchable={true}
                      limit={3}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search States...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <div className='d-none'>
                    <Panel header="Status">
                      <RefinementList
                        attribute="current_Status"
                      />
                    </Panel>
                  </div>
                  {/* <Panel header="US Cities">
                      <RefinementList
                        attribute="locations.uS_City"
                        searchable={true}
                        limit={3}
                        showMore={true}
                        showMoreLimit={30}
                        translations={{
                          showMore(expanded) {
                            return expanded ? 'Show less' : 'Show more';
                          },
                          noResults: 'No results',
                          submitTitle: 'Submit your search query.',
                          resetTitle: 'Clear your search query.',
                          placeholder: 'Search Cities...',
                        }}
                      />
                    </Panel>
                    <br /><br /> */}
                </div>
              </div>
            )}
            <div className={iFrameConfig?.url ? "" : "col-12 col-xl-8"}>
              <div className="grant-search">
                <div className="grant-search-bar-div">
                  <SearchBox
                    autoFocus={true}
                    searchAsYouType={false}
                    showLoadingIndicator={true}
                    translations={{ placeholder: 'Search by keyword, category, agency or location...' }}
                  />
                </div>
                {/* <div className="voice-search-div">
                  <VoiceSearch />
                  
                </div> */}
              </div>
              <Stats />
              <hr className="statsHr" />
              <br />
              <Results>
                <Hits
                  hitComponent={Result}
                  style={{ width: "100%!important" }}
                  id="searchHits"
                />
              </Results>
              {listings?.map(listing =>
                <GrantSearchResult
                  props={props}
                  user={user}
                  iFrameConfig={iFrameConfig}
                  listing={listing}
                />)}
              <div >
                <Pagination
                  // Optional parameters
                  defaultRefinement={1}
                  showFirst={true}
                  showPrevious={true}
                  showNext={true}
                  showLast={true}
                  padding={width > 350 ? (width > 450 ? (width > 550 ? (width > 650 ? 5 : 3) : 2) : 1) : 0}
                />
              </div>
            </div>
          </div>
        </InstantSearch>
        {showQueryDiv &&
          <div id="showQueryBackground">
            <div id="showQueryDiv">
              <p onClick={() => setShowQueryDiv(false)} style={{ float: "right", paddingRight: 10, paddingTop: 10, cursor: "pointer" }}><strong>X</strong></p>
              <br />
              <h1>Custom Query Builder</h1>
              <hr />
              <TextField
                required
                id="saved-query-title"
                label="SAVED QUERY TITLE"
                name="savedQueryTitle"
                value={savedQueryTitle}
                onChange={(e) => setSavedQueryTitle(e.target.value)}
                style={{ width: "100%", marginBottom: 15 }}
              />
              <Button onClick={() => { saveQuery() }}>
                Save Query
              </Button>
              <br /><br /><br />
              <hr />
              <div id="currentQuery">
                {newQuery?.query?.query &&
                  <div>
                    <strong>Search Term: </strong>
                    {newQuery?.query?.query}
                  </div>
                }
                {newQuery?.query?.dateRange &&
                  <div>
                    {newQuery?.query?.dateRange?.from && <>
                      <strong>Due After: </strong>
                      {unixDate((newQuery?.query?.dateRange.from / 1000))}
                      <br />
                    </>}
                    {newQuery?.query?.dateRange?.to && <>
                      <strong>Due Before: </strong>
                      {unixDate((newQuery?.query?.dateRange.to / 1000))}
                      <br />
                    </>}
                  </div>
                }
                {newQuery?.query?.range &&
                  newQuery?.query?.range.funding_Amount &&
                  <div>
                    {(newQuery?.query?.range.funding_Amount.min || newQuery?.query?.range.funding_Amount.min === 0) &&
                      <div>
                        <strong>Funding Amount (min): </strong>
                        ${JSON.stringify(newQuery?.query?.range.funding_Amount.min)} USD
                      </div>
                    }
                    {(newQuery?.query?.range.funding_Amount.max || newQuery?.query?.range.funding_Amount.max === 0) &&
                      <div>
                        <strong>Funding Amount (max): </strong>
                        ${JSON.stringify(newQuery?.query?.range.funding_Amount.max)} USD
                      </div>
                    }
                  </div>
                }
                {newQuery?.query?.refinementList &&
                  <div>
                    {newQuery?.query?.refinementList.agency &&
                      <div>
                        <strong>Agencies: </strong>
                        {JSON.stringify(newQuery?.query?.refinementList.agency)}
                      </div>
                    }
                    {newQuery?.query?.refinementList.tags &&
                      <div>
                        <strong>Keywords / Tags: </strong>
                        {JSON.stringify(newQuery?.query?.refinementList.tags)}
                      </div>
                    }
                  </div>
                }
                <br />
              </div>
            </div>
          </div>
        }
      </div>

    </>
  );
}