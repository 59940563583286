import React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ModalView({
    openModal,
    toggleModal,
    children
}) {
    return (
        <div>
            <Modal
                isOpen={openModal}
                centered={true}
                backdrop={true}
                toggle={toggleModal}
                fade={false}
                className="ModalDiv"
                size="xl"
            >
                <ModalHeader toggle={toggleModal} style={{ borderBottom: 0, justifyContent: 'end' }}></ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        </div>
    );
}
