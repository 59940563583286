import React, { useState, useEffect } from 'react';
import { Image, Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import girlStanding2 from '../../_assets/img/girlStanding2.svg'
import onboardingCirclesSm from '../../_assets/img/onboardingCirclesSm.png'
import opengrantsLogoBig from '../../_assets/img/opengrants-logo-card.svg'
import PhoneNumber from './components/PhoneNumber';
import OrganizationName from './components/OrganizationName';
import Ein from './components/Ein';
import SamRegistration from './components/SamRegistration';
import WorkYouAreFunding from './components/WorkYouAreFunding';
import PartnerLinkedIns from './components/PartnerLinkedIns';
import AmountOfFunding from './components/AmountOfFunding';
import ThankYou from './components/ThankYou';
import FundingSources from './components/FundingSources';
import { grantMatchesService, organizationService, settingsService } from '../../_services'
import GuyCharacter from '../Onboarding/Components/GuyCharacter';
import { authenticateActions } from '../../_actions';

export const CommonApp = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.setUser);
  const [tab, setTab] = useState(1)
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState({})
  const [technologies, setTechnologies] = useState([])
  const [industries, setIndustries] = useState([])
  const [socialImpacts, setSocialImpacts] = useState([])
  const [naicsCodes, setNaicsCodes] = useState([])
  const [form, setForm] = useState({
    isSmallBusiness: false,
    isInHubzone: false,
    isVeteranOwned: false,
    is8A: false,
    isSamRegistered: false,
    isWomanOwned: false,
  })

  const profileMap = {
    bio: "bio",
    disability: "disability",
    ethnicity: "ethnicity",
    gender: "gender",
    headshot: "headshot",
    phone: "phoneNumber",
  };

  const organizationMap = {
    address: "address",
    amountOfFunding: "funding_Requested",
    city: "city",
    country: "country",
    ein: "ein",
    fetchedIntros: "funding_Source_Intros",
    industries: "industries",
    is8A: "businiess_8a_Development",
    isInHubzone: "hubZone_Owned",
    isSamRegistered: "saM_Number",
    isSmallBusiness: "small_Business",
    isVeteranOwned: "veteran_Owned",
    isWomanOwned: "woman_Owned",
    linkedInProfiles: "leadership_Profiles",
    naics: "naicS_Codes",
    orgName: "name",
    orgSize: "size",
    orgType: "org_Type",
    partnerLinkedIns: "partner_Profiles",
    socialImpactAreas: "social_Impact_Categories",
    state: "state",
    technologies: "technologies_Used",
    totalFundingReceived: "total_Funding_Received",
    uei: "dunS_Number",
    website: "website_URL",
    workFundedInfo: "description",
    zip: "zipCode",
  }

  const remapKeys = (obj, keyMap, reverse = false) => {
    const newObj = {};
    if (obj) {
      const objKeys = Object.keys(obj);

      Object.entries(keyMap).map(([key, value]) => {
        if (!reverse && objKeys.includes(key)) {
          newObj[value] = obj[key];
        }
        else if (reverse && objKeys.includes(value)) {
          newObj[key] = obj[value];
        }
      });
    }
    return newObj;
  }

  const handleBack = () => {
    setTab(tab - 1)
  }

  const handleNext = () => {
    setTab(tab + 1)
  }

  const handleChangeForm = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  const handleRadioCheck = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.id
    })
  }

  const handleBoxCheck = (event) => {
    setForm({
      ...form,
      [event.target.name]: !form[event.target.name]
    })
  }

  const handleSubmit = async () => {
    setLoading(true);

    const fundingIntros = []
    if (form.fundingSourceIntros) {
      Object.keys(form.fundingSourceIntros).forEach((key) => {
        fundingIntros.push({
          Source: form.fundingSourceIntros[key].source,
          Question: key,
          Answer: form.fundingSourceIntros[key].answer,
        })
      })
    }

    if (form.introGrantAdvance) {
      fundingIntros.push({
        Source: "Grant Advance",
        Question: null,
        Answer: null
      })
    }

    let partner_Profiles;
    if (form.partnerLinkedIns) {
      partner_Profiles = form.partnerLinkedIns.filter(partner => partner.Name && partner.Linkedin_Url)
    }

    const userSubmitObject = {
      user: {
        ...user,
        ...remapKeys(form, profileMap)
      },
      file_string: form.headshot,
    }

    const organizationSubmitObject = {
      ...remapKeys(form, organizationMap),
      funding_Source_Intros: fundingIntros,
      partner_Profiles
    }
    const res1 = await settingsService.updateProfile(userSubmitObject)

    let res2
    if (organization.organization_Id) {
      organizationSubmitObject.organization_Id = organization.organization_Id
      res2 = await organizationService.update(organizationSubmitObject)
    } else {
      res2 = await organizationService.create(organizationSubmitObject)
      try {
        await grantMatchesService.regenerateEmbeddings();
        await grantMatchesService.regenerateGrantMatches();
      } catch (err) { }
    }

    setTab(9)
    setLoading(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      const [org, fetchedNaicsCodes, fetchedIndustries, fetchedTechnologies, fetchedSocialImpactCategories] = await Promise.all([
        organizationService.get(true),
        organizationService.getNAICSCodes(),
        organizationService.getIndustries(),
        organizationService.getTechnologies(),
        organizationService.getSocialImpactCategories()
      ])

      fetchedIndustries.unshift('Not Applicable')
      fetchedTechnologies.unshift('Not Applicable')
      if (org) setOrganization(org);
      if (fetchedNaicsCodes.length) setNaicsCodes(fetchedNaicsCodes)
      if (fetchedIndustries.length) setIndustries(fetchedIndustries)
      if (fetchedTechnologies.length) setTechnologies(fetchedTechnologies)
      if (fetchedSocialImpactCategories.length) setSocialImpacts(fetchedSocialImpactCategories)

      const orgRemapped = remapKeys(org, organizationMap, true);
      const profileRemapped = remapKeys(user, profileMap, true);

      setForm({
        ...form,
        ...user,
        ...orgRemapped,
        ...profileRemapped
      })
    }

    const flagLoad = async () => {
      if (!user.date_Completed_Common_App) {
        const newUser = { ...user, date_Completed_Common_App: new Date() }
        await settingsService.updateProfile({
          user: newUser
        })
        dispatch(authenticateActions.setUser(newUser));
      }
    }

    fetchData()
    flagLoad()
  }, [])

  return (
    <div
      id="mainLayoutDiv_v2"
      style={{
        background: "light-200",
        top: 70,
        height: 'calc(100vh - 70px)',
        margin: 0,
        width: '100%',
        top: 70,
      }}
    >
      <Image src={onboardingCirclesSm} className="d-none d-lg-block position-absolute top-10 start-0 mw-100 h-100" style={{ zIndex: -1 }} />
      <Row style={{ maxHeight: '100%' }}>
        <Col xs={0} lg={2} xl={3} className='d-none d-lg-flex pt-88 position-relative justify-content-center'>
          <div className='position-absolute d-xl-flex d-none justify-content-center align-items-center start-50 mt-120 w-100'>
            <GuyCharacter
              chatQuoteOptions={tab !== 9 && ["All clear! Now let's learn more about you."]}
              guyPosition='guy-position-1'
            />
          </div>
        </Col>
        <Col xs={12} lg={8} xl={6} className='px-32 px-lg-104'>
          <Card className='mt-32 mb-32 mt-lg-168 py-40 d-flex flex-column align-items-center rounded-3 bg-primary-light border border-1 border-primary'>
            <Image src={opengrantsLogoBig} style={{ height: '7rem' }} />
            <Row className='w-100 px-160'>
              <Col xs={1} md={2} />
              <Col xs={10} md={8}>
                <div className={tab === 1 ? 'd-block' : 'd-none'}>
                  <PhoneNumber
                    handleNext={handleNext}
                    form={form}
                    radioCheck={handleRadioCheck}
                    changeForm={handleChangeForm}
                    setForm={setForm}
                  />
                </div>
                <div className={tab === 2 ? 'd-block' : 'd-none'}>
                  <OrganizationName
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    changeForm={handleChangeForm}
                    setForm={setForm}
                  />
                </div>
                <div className={tab === 3 ? 'd-block' : 'd-none'}>
                  <Ein
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    setForm={setForm}
                    changeForm={handleChangeForm}
                    naicsCodes={naicsCodes}
                    technologies={technologies}
                    socialImpacts={socialImpacts}
                    industries={industries}
                  />
                </div>
                <div className={tab === 4 ? 'd-block' : 'd-none'}>
                  <SamRegistration
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    boxCheck={handleBoxCheck}
                  />
                </div>
                <div className={tab === 5 ? 'd-block' : 'd-none'}>
                  <WorkYouAreFunding
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    changeForm={handleChangeForm}
                  />
                </div>
                <div className={tab === 6 ? 'd-block' : 'd-none'}>
                  <PartnerLinkedIns
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    setForm={setForm}
                  />
                </div>
                <div className={tab === 7 ? 'd-block' : 'd-none'}>
                  <AmountOfFunding
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    changeForm={handleChangeForm}
                  />
                </div>
                <div className={tab === 8 ? 'd-block' : 'd-none'}>
                  <FundingSources
                    handleBack={handleBack}
                    handleNext={handleNext}
                    form={form}
                    setForm={setForm}
                    boxCheck={handleBoxCheck}
                    onSubmit={handleSubmit}
                    loading={loading}
                  />
                </div>
                <div className={tab === 9 ? 'd-block' : 'd-none'}>
                  <ThankYou />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3} className='d-none d-xl-flex pt-168 flex-column justify-content-center align-items-center'>
          <div className='py-168 my-136' />
          <Image src={girlStanding2} style={{ height: '38rem' }} />
        </Col>
      </Row>
    </div>
  )
}