import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganizationDUNS extends React.Component {

    render() {
        const { org } = this.props;

        return (
            <div>
                <h4 className="inputFieldLabel">Unique Entity Identifier (previously known as DUNS number)</h4>
                <p className="inputFieldSubLabel">A DUNS number is a unique nine-character number used to identify your organization. The federal government uses the DUNS number to track how federal money is allocated. <a href="https://fedgov.dnb.com/webform/displayHomePage.do" target="_blank">Register for DUNS</a> or <a href="https:/portal.opengrants.io/checkout/registration" target="_blank">let us do it for you</a>.</p>
                <input className="inputField" name="dunS_Number" value={ org.organization.dunS_Number } onChange={this.props.handleChange} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganizationDUNS = connect(mapStateToProps)(EditOrganizationDUNS);
export { connectedEditOrganizationDUNS as EditOrganizationDUNS };
