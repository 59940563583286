import React from 'react';
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { RegisterPage_v2, RegistrationType } from "../../_pages/Account/RegisterPage";
import { NLPrivateRoute } from '../../_components/NLPrivateRoute';

export const Register_Routes = (props) => {

  return (
    <Switch>
      {/* NON-AUTH ROUTES */}
      {!props.user && <Redirect from="/register/type/org/:org_id" to="/register/organization_invitations/:org_id" />}
      {!props.user && <Redirect from="/register/type" to="/register" />}

      {/* AUTH ROUTES */}
      <NLPrivateRoute path="/register/type" component={RegistrationType} />

      {/* PUBLIC ROUTES */}
      <Route exact path="/register/organization_invitations/:org_id?" component={RegisterPage_v2} />
      <Route exact path="/register/partner/ref/:partnerClient" component={RegisterPage_v2} />
      <Route path="/register" component={RegisterPage_v2} />
    </Switch>
  )
}