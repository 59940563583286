import React from 'react';
import { Form, Button } from 'react-bootstrap'

const WorkYouAreFunding = ({ form, changeForm, handleNext, handleBack }) => {
  return (
    <>
      <Form className='w-100 mt-48'>             
      <p className='fw-bold mt-40 mb-16'>Information about the specific work you're funding.</p>
      <p className='fw-bold'>Hint: this is how we match you to grants, so make sure it accurately represents your work!</p>
        <Form.Control 
          as='textarea'
          name='workFundedInfo'
          value={form.workFundedInfo}
          onChange={changeForm}
          className='border border-2 border-light-gray'
          style={{ minHeight: '10rem' }}
        />
      </Form>
      <div className='mt-80 px-xl-168 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default WorkYouAreFunding