import React, { useState, useEffect } from 'react'
import escapeHtml from 'escape-html'
import { Text } from 'slate'

const SlateTextDisplay = ({ text, truncate, charLimit }) => {
  const [newText, setNewText] = useState("")

  //On mount
  useEffect(() => {
    //if the text was formatted, parse it and turn to html
    try {
      let parsedText = JSON.parse(text);
      textToHTML(parsedText)
    }
    //if the text was not formatted, display it as is
    catch (e) {
      setNewText(text)
    }

  }, [])

  const truncateText = (text) => {
    return (text && text.length > charLimit) ? text.substring(0, charLimit - 3) + "..." : text;
  };

  //Loop through every paragraph and turn it into html tag
  const textToHTML = (value) => {
    let words = value.map((paragraph) => {
      return serialize2(paragraph)
    }).join('')

    setNewText(words)
  }

  //Turn string into hmtl tags
  const serialize2 = node => {

    //bold, italic, or underline
    if (Text.isText(node)) {
      let string = escapeHtml(node.text)
      if (node.bold && node.italic && node.underline) {
        string = `<u><i><strong>${string}</strong></i></u>`
      }
      else if (node.bold && node.underline) {
        string = `<u><strong>${string}</strong></u>`
      }
      else if (node.bold && node.italic) {
        string = `<strong><i>${string}</i></strong>`
      }
      else if (node.italic && node.underline) {
        string = `<u><i>${string}</i></u>`
      }
      else if (node.bold) {
        string = `<strong>${string}</strong>`
      }
      else if (node.italic) {
        string = `<i>${string}</i>`
      }
      else if (node.underline) {
        string = `<u>${string}</u>`
      }

      return string
    }

    //regular paragraph, heading, list, or indent
    const children = node.children.map(n => serialize2(n)).join('')
    switch (node.type) {
      case 'paragraph':
        return `<p>${children}</p>`
      case 'heading-one':
        return `<h1> ${children}</h1>`
      case 'heading-two':
        return `<h2> ${children}</h2>`
      case 'heading-three':
        return `<h3> ${children}</h3>`
      case 'numbered-list':
        return `<ol> ${children}</ol>`
      case 'bulleted-list':
        return `<ul> ${children}</ul>`
      case 'list-item':
        return `<li><p> ${children}</p></li>`
      case 'indent':
        return `<p style="margin-left: 40px"> ${children}</p>`
      default:
        return children
    }
  }

  //Display fromatted text
  //Sanitize text to prevent code injection
  return (
    <div dangerouslySetInnerHTML={{ __html: truncate ? truncateText(newText) : newText }}></div>
  )
}

export default SlateTextDisplay