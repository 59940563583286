import { format, parseISO } from "date-fns";
import React from 'react';
import { connect } from 'react-redux';
import { Messenger } from '../..';
import "../../../../Styles/MarketplaceProject_v2/MarketplaceProjectList_v2.css";
import { pluginService, sharedService } from '../../../../_services';

class MarketplaceProjectList_v2 extends React.Component {
    state = {
        project: {},
        view: 'list',
        loading: true,
    }

    async componentDidMount() {
        let type = "";

        if (window.location.href.includes("/cl/")) {
            type = "client";
        }
        else {
            type = "service_Provider";
        }

        this.setState({
            type: type,
        })
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    changeView = (view) => {
        this.setState({
            view
        });
    }

    viewProject = (project, projectType) => {
        const { type } = this.state;
        window.location.href = pluginService.redirect('/' + (type === "client" ? 'cl' : 'sp') + '/projects/' + project.project_Id, 'type=' + projectType);
    }

    viewProjectListing = (project, projectType) => {
        const { type } = this.state;
        window.location.href = pluginService.redirect('/' + (type === "client" ? 'cl' : 'sp') + '/projects/marketplace/' + project.project_Id + '?type=' + projectType);
    }

    open_chat = (organization_Id) => {
        let open_chat = this.state.open_chat;
        if (open_chat) {
            organization_Id = null;
        }

        this.setState({
            open_chat: !open_chat,
            organization_Id
        });
    }

    getProjectType = (projectType) => {

        if (projectType === "mp-project") {
            return "mpProject"
        }
        else if (projectType === "fixed-product") {
            return "fixedProduct"
        }
        else {
            return "spProject"
        }
    }

    renderProjectTable = (projects) => {
        const { type } = this.props;

        return (
            <>
                {/* DESKTOP */}

                <table className='projectsListTable desktopProjectListTable'>
                    <thead >
                        <tr style={{ borderBottom: "1px solid rgba(43, 44, 45, 0.25)" }}>
                            <th>Title</th>
                            {type === 'client' ?
                                <th>Service Provider</th>
                                :
                                <th>Client</th>
                            }
                            <th>Created</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects?.map((project, idx) => {
                            return this.renderProjectTableRow(project, idx)
                        })}
                    </tbody>
                </table>

                {/* MOBILE */}

                <div className='mobileProjectListTable'>
                    {projects?.map((project, idx) => {
                        return this.renderProjectTableRow(project, idx)
                    })}
                </div>
            </>
        )
    }

    //sp project = sp makes project, client accepts
    //mp porject = mp makes project, sp accepts
    //fixed product = sp makes product, client buys

    projectClientExists = (project) => {
        const { type } = this.props;

        //sp project
        if (project.project_Type === null) {

            //if sp, check if cl exists
            if (type === "service_Provider") {
                return !!(project.client_Organization_Name || (project.applications && project.applications[0]?.service_Provider?.organization?.name))
            }

            //if cl, check if sp exists
            else {
                return !!project.service_Provider_Organization_Name
            }

        }

        //mp project
        else if (project.project_Type === "mp-project") {

            //if sp, check if project is active and cl exists
            if (type === "service_Provider") {
                return !!project.client_Organization?.name
            }

            //if cl, check if project is active and sp exists or project not active
            else {
                return (((project.project_Status === "Open" || project.project_Status === "Cancel") && !project.client_Organization) || (project.client_Organization?.name) || (project.applications[0]?.service_Provider?.organization?.name))
            }

        }

        // fixed product
        else if (project.project_Type === "fixed-product") {

            //if sp, check if cl exists
            if (type === "service_Provider") {
                return !!project.client_Organization?.name
            }

            //if cl, check if sp exists
            else {
                return !!project.service_Provider?.organization?.name
            }
        }
    }


    renderProjectTableRow = (project, idx) => {

        const { type } = this.props;

        //sp project
        if (project.project_Type === null && this.projectClientExists(project)) {
            return this.renderProjectProposalTableRow(idx, project, type)
        }

        //mp project
        else if (project.project_Type === "mp-project" && this.projectClientExists(project)) {

            //sp side (active)
            if (type === "service_Provider" && (project.project_Status !== "Open" && project.applications && project.applications.length !== 0 && project.applications[0].status === "Accepted")) {
                return this.renderProjectProposalTableRow(idx, project, type)
            }
            //cl side or sp side (not active)
            else {
                return this.renderProjectListingTableRow(idx, project, type)
            }
        }

        //fixed product
        else if (project.project_Type === "fixed-product" && this.projectClientExists(project)) {
            return this.renderProjectListingTableRow(idx, project, type)
        }


        // if (project.service_Provider_Id || //sp active project
        //     (type === "service_Provider" && (project.project_Status !== "Open" && project.applications && project.applications.length !== 0 && project.applications[0].status === "Accepted"))) { //mp active project

        //     if ((type === "service_Provider" && project.client_Organization_Name) || (type === "client" && project.service_Provider_Organization_Name)) {
        //         return this.renderProjectProposalTableRow(idx, project, type)
        //     }
        // }
        // else {

        //     if (((project.project_Status === "Open" || project.project_Status === "Cancel") && !project.client_Organization) || project.client_Organization?.name) {
        //         return this.renderProjectListingTableRow(idx, project, type)
        //     }
        // }
    }

    renderProjectProposalTableRow = (idx, project, type) => {
        let project_Status = sharedService.getProjectStatus(project.project_Status.toLowerCase(), (type === "client" ? true : false));

        let isProjectListing = (project.project_Status === 'Open' || (project.applications && project.applications.length !== 0)) ? true : false;

        // let clientOrg = project.project_Type === "mp-project" ? project.client_Organization?.name : project.client_Organization_Name
        let clientOrg = isProjectListing ? project.client_Organization?.name : project.client_Organization_Name

        return (
            <>
                {/* DESKTOP */}

                <tr key={idx} className="desktopProjectRow" onClick={() => { this.viewProject(project, this.getProjectType(project.project_Type)) }}>
                    <td>{project.project_Title}</td>
                    <td>{type === "service_Provider" ? clientOrg : project.service_Provider_Organization_Name}</td>
                    <td>{format(parseISO(project.date_Created), "MM/dd/yyyy")}</td>
                    <td>{project_Status}</td>
                </tr>

                {/* MOBILE */}

                <div className='mobileProjectRow'>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Title:</p></div>
                        <div className="rightColumn"><p>{project.project_Title}</p></div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>{type === 'client' ? "Service Provider:" : "Client:"}</p></div>
                        <div className="rightColumn"><p>{type === "service_Provider" ? clientOrg : project.service_Provider_Organization_Name}</p></div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Created:</p></div>
                        <div className="rightColumn"><p>{format(parseISO(project.date_Created), "MM/dd/yyyy")}</p></div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Status:</p></div>
                        <div className="rightColumn"><p>{project_Status}</p></div>
                    </div>
                    <div className="projectRow" style={{ marginTop: 20 }}>
                        <p className="hoursInvoicesActionButton" onClick={() => this.viewProject(project, this.getProjectType(project.project_Type))}>
                            <i className='icon-folder-open' />Open Project
                        </p>
                    </div>
                    <hr />
                </div>
            </>
        )
    }

    renderProjectListingTableRow = (idx, project, type) => {
        let project_Status = "";

        if (project.project_Status === "Active") {
            project_Status = <span className="proposalStatus green">{"Active"}</span>
        }
        else if (project.project_Status === "Open") {
            project_Status = <span className="proposalStatus" style={{ color: "#0C7069" }}>{type === "service_Provider" ? "Applied" : "Open"}</span>
        }
        else if (project.project_Status === "Complete") {
            project_Status = <span className="proposalStatus green">{"Project completed"}</span>
        }
        else if (project.project_Status === "Cancel") {
            project_Status = <span className="proposalStatus red">{"Project canceled"}</span>
        }
        else if (project.project_Status === "Rejected") {
            project_Status = <span className="proposalStatus red">{"Application rejected"}</span>
        }

        let accepted_application = null;
        if (project && project.applications && project.applications.length > 0) {
            accepted_application = project.applications.filter(application => { if (application.status === 'Accepted') { return application } })
            if (accepted_application.length === 0) {
                accepted_application = null;
            }
            else {
                accepted_application = accepted_application[0];
            }
        }

        let applicantNotification = 0;

        if (type !== "service_Provider" && project.project_Status === "Open" && project.applications && project.applications.length !== 0) {
            project.applications.map((application) => {
                if (!application.date_Seen) {
                    applicantNotification++;
                }
            })
        }

        let isProjectListing = (project.project_Status === 'Open' || (project.applications && project.applications.length !== 0)) ? true : false;

        return (
            <>
                {/* DESKTOP */}

                <tr key={idx} className="desktopProjectRow" onClick={() => this.viewProjectListing(project, this.getProjectType(project.project_Type))}>

                    <td>{project.project_Title}</td>
                    {type === "client" ?
                        <>
                            {project.project_Status !== 'Open' &&
                                <td>{accepted_application ? accepted_application.service_Provider.organization.name : project.project_Type === "fixed-product" ? project.service_Provider?.organization?.name : ''}</td>
                            }
                            {project.project_Status === "Open" &&
                                <td>
                                    {(project.applications && project.applications.length > 0) ?
                                        <a onClick={() => this.viewProjectListing(project, this.getProjectType(project.project_Type))} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: applicantNotification ? "bold" : "normal" }}>
                                            Review Applicants {applicantNotification ? <span style={{ textDecoration: "underline", fontWeight: "bold" }}>&nbsp;({applicantNotification} new)</span> : null}
                                        </a>
                                        : "No Applicants"
                                    }
                                </td>
                            }
                        </>
                        :
                        <td>{project.client_Organization.name}</td>
                    }
                    <td>{format(parseISO(project.date_Created), "MM/dd/yyyy")}</td>
                    <td>{project_Status}</td>
                    {/* <td>
                        <button className="defaultGreenButton openProjectButton" onClick={() => this.viewProjectListing(project)}>
                            Open
                        </button>
                    </td> */}
                </tr>

                {/* MOBILE */}

                <div className='mobileProjectRow'>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Title:</p></div>
                        <div className="rightColumn"><p>{project.project_Title}</p></div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>{type === 'client' ? "Service Provider:" : "Client:"}</p></div>
                        <div className="rightColumn">
                            <p>
                                {type === "client" ?
                                    <>
                                        {project.project_Status !== 'Open' &&
                                            <p>{accepted_application ? accepted_application.service_Provider.organization.name : project.project_Type === "fixed-product" ? project.service_Provider?.organization?.name : ''}</p>
                                        }
                                        {project.project_Status === "Open" &&
                                            <p>
                                                {(project.applications && project.applications.length > 0) ?
                                                    <a onClick={() => this.viewProjectListing(project, this.getProjectType(project.project_Type))} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: applicantNotification ? "bold" : "normal" }}>
                                                        Review Applicants {applicantNotification ? <span style={{ textDecoration: "underline", fontWeight: "bold" }}>&nbsp;({applicantNotification} new)</span> : null}
                                                    </a>
                                                    : "No Applicants"
                                                }
                                            </p>
                                        }
                                    </>
                                    :
                                    <p>{project.client_Organization.name}</p>
                                }
                            </p>
                        </div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Created:</p></div>
                        <div className="rightColumn"><p>{format(parseISO(project.date_Created), "MM/dd/yyyy")}</p></div>
                    </div>
                    <div className="projectRow">
                        <div className="leftColumn"><p>Status:</p></div>
                        <div className="rightColumn"><p>{project_Status}</p></div>
                    </div>
                    <div className="projectRow" style={{ marginTop: 20 }}>
                        <p className="hoursInvoicesActionButton" onClick={() => this.viewProjectListing(project, this.getProjectType(project.project_Type))}>
                            <i className='icon-folder-open' />Open Project
                        </p>
                    </div>
                    <hr />
                </div>
            </>
        )
    }

    render() {
        const { status, projects, type } = this.props;
        const { view, open_chat, organization_Id } = this.state;

        let noProjects = (!projects || projects.length === 0);

        return (
            <div>
                {view === 'list' &&
                    <div>
                        <Messenger
                            channel_type={type}
                            hide_channels={true}
                            organization_Id={organization_Id}
                            open_chat={open_chat}
                            close_chat={this.open_chat}
                        />
                        {!noProjects ?
                            this.renderProjectTable(projects)
                            :
                            <div>
                                <br />
                                <img
                                    alt=""
                                    src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/project_proposals_icon.png"
                                    className="no-projects"
                                />
                                <br />
                                <h4 className="no-projects-message">You have no {status} projects at this time.</h4>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const project_proposals = state.serviceprovider_getprojects.projects;
    const project_listings = state.mp_projects.projects;
    const { setUser } = state;
    const { user } = setUser;

    let projects = [];

    let type = window.location.href.includes("/cl/") ? "client" : "service_Provider";

    if (project_proposals) {
        projects.push(...project_proposals)
    }
    if (project_listings) {
        projects.push(...project_listings)
    }

    const status_hierarchy = {
        "Open": 0,
        "pending_proposal_review": 1,
        "pending_proposal_creation": 2,
        "active": 3,
        "Active": 3,
        "completed": 4,
        "Complete": 4,
        "Rejected": 5,
        "proposal_rejected": 5,
        "canceled": 6,
        "Cancel": 6,
    }

    projects.sort((a, b) => {

        if (type === "service_Provider") {
            if (a.applications && a.applications.length !== 0 && a.applications[0].status === "Rejected" && (a.project_Status === "Open" || a.project_Status === "Active")) {
                a.project_Status = "Rejected";
            }
            if (b.applications && b.applications.length !== 0 && b.applications[0].status === "Rejected" && (b.project_Status === "Open" || b.project_Status === "Active")) {
                b.project_Status = "Rejected";
            }
        }

        if (status_hierarchy[a.project_Status] < status_hierarchy[b.project_Status]) {
            return -1;
        }
        else if (status_hierarchy[a.project_Status] > status_hierarchy[b.project_Status]) {
            return 1;
        }
        else if (a.date_Created > b.date_Created) {
            return -1;
        }
        else {
            return 0;
        }
    })

    return {
        projects,
        user,
    };
}

const connectedMarketplaceProjectList = connect(mapStateToProps)(MarketplaceProjectList_v2);
export { connectedMarketplaceProjectList as MarketplaceProjectList_v2 };
