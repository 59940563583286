import React from 'react'
import { pluginService, sharedService } from '../../_services/shared.service';
import { FileUpload } from "../Shared/Upload/FileUpload";
import { Row } from 'react-bootstrap';

export default function SegmentForm({
    handleChange, segment, submitHours, originalSegment, formatDate, cancelHours, submitted, noChanges, handleEditAttachment, project
}) {
    let accentColor = pluginService.getIFrameColor()

    return (
        <div className='hoursEditRow'>

            <div>
                <div class="mb-24">
                    <textarea
                        type="text"
                        placeholder="Description of Work"
                        name="segment_Title"
                        value={segment.segment_Title}
                        onChange={(e) => handleChange(e)}
                        className="email-text-field font-input-default hoursSegmentFormTextArea"
                        multiline
                        style={{ fontSize: 14 }}
                        maxlength="255"
                    />
                    {segment.segment_Title && segment.segment_Title.length === 255 &&
                        <p className='defaultErrorMessage'>You've reached 255 character limit</p>

                    }
                    {submitted && !segment.segment_Title &&
                        <p className='defaultErrorMessage'>Invalid title</p>
                    }
                    {noChanges &&
                        <p className='defaultErrorMessage'>No changes were made</p>
                    }
                </div>
                <Row className='m-0 justify-content-between'>
                    <div className='col-3 ps-0'>
                        <input
                            min="0"
                            placeholder="Hours"
                            name="segment_Hours"
                            value={segment.segment_Hours}
                            onChange={(e) => handleChange(e)}
                            className="email-text-field font-input-default"
                            style={{ fontSize: 14 }}
                        />
                        {submitted && !segment.segment_Hours &&
                            <p className='defaultErrorMessage'>Invalid input</p>
                        }
                        {segment.segment_Hours && !sharedService.isFloat(segment.segment_Hours) &&
                            <p className='defaultErrorMessage'>You can only enter positive numbers</p>
                        }
                    </div>
                    <div className='col-3'>
                        <input
                            min="0"
                            placeholder="Rate"
                            name="segment_Hourly_Rate"
                            value={segment.segment_Hourly_Rate}
                            onChange={(e) => handleChange(e)}
                            className="email-text-field font-input-default"
                            style={{ fontSize: 14 }}
                        />
                        {submitted && !segment.segment_Hourly_Rate &&
                            <p className='defaultErrorMessage'>Invalid hourly rate</p>
                        }
                        {segment.segment_Hourly_Rate && !sharedService.isInteger(segment.segment_Hourly_Rate) &&
                            <p className='defaultErrorMessage'>You can only enter positive integers</p>
                        }
                    </div>
                    <div className='col-3'>
                        <input
                            type="date"
                            placeholder="Date"
                            name="segment_Date_Performed"
                            value={originalSegment ? segment.segment_Date_Performed && formatDate(segment.segment_Date_Performed) : segment.segment_Date_Performed}
                            onChange={(e) => handleChange(e)}
                            className="email-text-field font-input-default"
                            style={{ fontSize: 14 }}
                        />
                        {submitted && !segment.segment_Date_Performed &&
                            <p className='defaultErrorMessage'>Invalid date</p>
                        }
                    </div>
                    <div className='col-3 pe-0'>
                        <div className='row p-0 m-0'>
                            <div className="col-6 hoursSubmitButton" >
                                <button
                                    className="defaultGreenButton"
                                    onClick={originalSegment ? () => { submitHours(originalSegment) } : () => { submitHours() }}
                                    style={{ height: "42px", padding: '10px 20px', overflow: "hidden", width: "100%", backgroundColor: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                >
                                    <p className="hoursEditButtonText" style={{ fontSize: 14 }}>Submit</p>
                                    <i class="icon-ok checkIconMargin hoursEditButtonIcon" />
                                </button>
                            </div>
                            <div className="col-6 hoursCancelButton pe-0">

                                <button
                                    className="defaultWhiteButton"
                                    onClick={originalSegment ? () => { cancelHours(originalSegment) } : () => { cancelHours() }}
                                    style={{ height: "42px", padding: '10px 20px', overflow: "hidden", width: "100%", color: accentColor || '#0C7069', border: accentColor ? `1px solid ${accentColor}` : '1px solid #0C7069' }}
                                >
                                    <p className="hoursEditButtonText" style={{ fontSize: 14 }}>Cancel</p>
                                    <i className="icon-cancel hoursEditButtonIcon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </Row>
            </div >
            <div style={{ fontFamily: "Lato" }}>
                <p style={{ marginBottom: 0, marginTop: 10 }}>Show off your work! Clients love to see it. We recommend sharing documents whenever possible.</p>
            </div>
            <FileUpload
                segment={segment}
                handleEditAttachment={handleEditAttachment}
                project={project}
            />
        </div >
    )
}
