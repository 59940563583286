import { favoritesConstants } from '../_constants';

export function getFavorites(state = {}, action) {
  switch (action.type) {
    case favoritesConstants.GETFAVORITES_REQUEST:
      return {
        loading: true
      };
    case favoritesConstants.GETFAVORITES_SUCCESS:
      return {
        items: action.favorites
      };
    case favoritesConstants.GETFAVORITES_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}

export function saveListing(state = {}, action) {
  switch (action.type) {
    case favoritesConstants.SAVELISTING_REQUEST:
      return {
        loading: true
      };
    case favoritesConstants.SAVELISTING_SUCCESS:
      return {
        favorites: localStorage.getItem('favorite'),
      };
    case favoritesConstants.SAVELISTING_FAILURE:
      return {
        error: "There was an error processing this request."
      };
    default:
      return state
  }
}