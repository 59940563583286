import StarIcon from '@mui/icons-material/Star';
import Input from '@mui/material/Input';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../../Styles/RecommendationModal.css";

export default function RecommendationModal({
    openModal,
    open_recommendationForm,
    service_Provider_Id,
    recommendationExists,
    createRecommendation,
    updateRecommendation,
    currentRecommendation
}) {
    const [recommendationRating, setRecommendationRating] = useState(0);
    const [recommendationTitle, setRecommendationTitle] = useState(currentRecommendation ? currentRecommendation.recommendation_Title : "");
    const [recommendationBody, setRecommendationBody] = useState(currentRecommendation ? currentRecommendation.recommendation_Body : "");
    const [ratingsCheck, setRatingsCheck] = useState(true);
    const [submitted, setSubmitted] = useState(false)

    function setStar(star) {
        let element;
        for (let i = 1; i <= 5; i++) {
            element = document.getElementById(`modal${i}`);
            element.setAttribute("style", "fill: lightgrey");
        }
        for (let i = 1; i <= star; i++) {
            element = document.getElementById(`modal${i}`);
            element.setAttribute("style", "fill: #EBC43B");
        }
    }

    //Will be used for on hover, not implemented
    function hoverColorEnter(star) {
        let element;

        if (recommendationRating === 0) {
            for (let i = 1; i <= star; i++) {
                element = document.getElementById(`modal${i}`);
                element.setAttribute("style", "fill: #e8d387");
            }
        }
        else {
            for (let i = 1; i <= 5; i++) {
                element = document.getElementById(`modal${i}`);
                element.setAttribute("style", "fill: lightgrey");
            }

            for (let i = 1; i <= star; i++) {
                element = document.getElementById(`modal${i}`);
                element.setAttribute("style", "fill: #e8d387");
            }
        }
    }

    function hoverColorLeave() {
        let element;
        for (let i = 1; i <= 5; i++) {
            element = document.getElementById(`modal${i}`);
            element.setAttribute("style", "fill: lightgrey");
        }
        if (recommendationRating != 0) {
            for (let i = 1; i <= recommendationRating; i++) {
                element = document.getElementById(`modal${i}`);
                element.setAttribute("style", "fill:  #EBC43B");
            }
        }
        else {

        }
    }

    let ratingCheck = () => {
        if (recommendationRating === 0) {
            if (recommendationExists) {
                return true;
            }
            else {
                setRatingsCheck(false)
                return false;
            }
        }
        else {
            setRatingsCheck(true)
            return true
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true)
        try {
            if (ratingCheck() === false || !recommendationTitle || !recommendationBody) {
                return;
            }

            let dateCreated = new Date();
            dateCreated = dateCreated.getFullYear() + "-" + ((dateCreated.getMonth() + 1) < 10 ? "0" + (dateCreated.getMonth() + 1) : (dateCreated.getMonth() + 1)) + "-" + dateCreated.getDate()

            let recommendation;

            if (!recommendationExists) {

                recommendation = {
                    Recommendation_Rating: recommendationRating,
                    Recommendation_Title: recommendationTitle,
                    Recommendation_Body: recommendationBody,
                    Date_Created: dateCreated,
                    Service_Provider_Id: service_Provider_Id
                }

                createRecommendation(recommendation);
            }
            else {

                recommendation = {
                    Recommendation_Rating: recommendationRating ? recommendationRating : currentRecommendation.recommendation_Rating,
                    Recommendation_Title: recommendationTitle ? recommendationTitle : document.getElementById("recTitleEdit").value,
                    Recommendation_Body: recommendationBody ? recommendationBody : document.getElementById("recTextEdit").value,
                    Date_Created: dateCreated,
                    Service_Provider_Id: service_Provider_Id,
                    Recommendation_Id: currentRecommendation.recommendation_Id
                }

                updateRecommendation(recommendation);
            }
        }
        catch (err) {
        }
        setSubmitted(false)
    };

    useEffect(() => {
        if (recommendationRating === 0 && recommendationExists) {
            setRecommendationRating(currentRecommendation.recommendation_Rating);
        }
    });

    return (
        <>
            {!recommendationExists ?
                <Modal
                    isOpen={openModal}
                    centered={true}
                    backdrop={true}
                    toggle={() => open_recommendationForm()}
                    fade={false}
                    className="ModalDiv"
                    size="md"
                >
                    <ModalHeader toggle={() => open_recommendationForm()} style={{ borderBottom: "none", paddingBottom: 0 }} />
                    <ModalBody style={{ padding: "0 5% 25px 5%" }}>

                        <h1 style={{ marginTop: 0, marginBottom: 25, textAlign: "center", fontWeight: 700, fontSize: 22, fontFamily: "Ubuntu" }}>
                            Write a Recommendation
                        </h1>

                        <form onSubmit={handleSubmit}>
                            {/* Star Rating */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Rate your experience<span style={{ color: 'red' }}>*</span></p>
                                <StarIcon class="starRating" id="modal1" value={recommendationRating} onClick={() => { setRecommendationRating(1); setStar(1); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(1)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal2" value={recommendationRating} onClick={() => { setRecommendationRating(2); setStar(2); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(2)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal3" value={recommendationRating} onClick={() => { setRecommendationRating(3); setStar(3); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(3)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal4" value={recommendationRating} onClick={() => { setRecommendationRating(4); setStar(4); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(4)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal5" value={recommendationRating} onClick={() => { setRecommendationRating(5); setStar(5); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(5)} onMouseLeave={() => hoverColorLeave()} />
                            </div>
                            {!ratingsCheck &&
                                <p style={{ color: "red", fontSize: 14 }}>Please give a rating for this recommendation!</p>
                            }

                            {/* Recommendation Title */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Title<span style={{ color: 'red' }}>*</span></p>
                                <Input
                                    onChange={(e) => setRecommendationTitle(e.target.value)}
                                    type="text"
                                    disableUnderline={true}
                                    className="apply-modal-input"
                                    placeholder="Please give a brief one-line overview of your recommendation here."
                                    id="recTitle"
                                    value={recommendationTitle}
                                />
                            </div>
                            {submitted && !recommendationTitle &&
                                <p style={{ color: "red", fontSize: 14 }}>Please give a title for this recommendation!</p>
                            }

                            {/* Recommendation Text */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Recommendation<span style={{ color: 'red' }}>*</span></p>
                                <textarea
                                    id="recText"
                                    value={recommendationBody}
                                    placeholder="Please provide any relevant information pertaining to your experience with this expert here."
                                    rows={7}
                                    cols={50}
                                    onChange={(e) => setRecommendationBody(e.target.value)}
                                    className="apply-modal-textarea"
                                    style={{ width: '100%', borderRadius: '5px', padding: '10px 10px', resize: "vertical", minHeight: 150 }}
                                />
                            </div>
                            {submitted && !recommendationBody &&
                                <p style={{ color: "red", fontSize: 14 }}>Please leave your feedback!</p>
                            }

                            <div style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
                                <button
                                    type="submit"
                                    className='defaultGreenButton'
                                    style={{ width: "fit-content", height: "42px" }}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
                :
                <Modal
                    isOpen={openModal}
                    centered={true}
                    backdrop={true}
                    toggle={() => open_recommendationForm()}
                    fade={false}
                    className="ModalDiv"
                    size="md"
                >
                    <ModalHeader toggle={() => open_recommendationForm()} style={{ borderBottom: "none", paddingBottom: 0, justifyContent: 'end' }} />
                    <ModalBody style={{ padding: "0 5% 25px 5%" }}>

                        <h1 style={{ marginTop: 0, marginBottom: 25, textAlign: "center", fontWeight: 700, fontSize: 22, fontFamily: "Ubuntu" }}>
                            Edit Recommendation
                        </h1>

                        <form onSubmit={handleSubmit}>
                            {/* Star Rating */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Rate your experience<span style={{ color: 'red' }}>*</span></p>
                                <StarIcon class="starRating" id="modal1" style={currentRecommendation?.recommendation_Rating > 0 ? { fill: "#EBC43B" } : { fill: "lightgrey" }} value={recommendationRating} onClick={() => { setRecommendationRating(1); setStar(1); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(1)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal2" style={currentRecommendation?.recommendation_Rating > 1 ? { fill: "#EBC43B" } : { fill: "lightgrey" }} value={recommendationRating} onClick={() => { setRecommendationRating(2); setStar(2); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(2)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal3" style={currentRecommendation?.recommendation_Rating > 2 ? { fill: "#EBC43B" } : { fill: "lightgrey" }} value={recommendationRating} onClick={() => { setRecommendationRating(3); setStar(3); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(3)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal4" style={currentRecommendation?.recommendation_Rating > 3 ? { fill: "#EBC43B" } : { fill: "lightgrey" }} value={recommendationRating} onClick={() => { setRecommendationRating(4); setStar(4); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(4)} onMouseLeave={() => hoverColorLeave()} />
                                <StarIcon class="starRating" id="modal5" style={currentRecommendation?.recommendation_Rating > 4 ? { fill: "#EBC43B" } : { fill: "lightgrey" }} value={recommendationRating} onClick={() => { setRecommendationRating(5); setStar(5); setRatingsCheck(true) }} onMouseEnter={() => hoverColorEnter(5)} onMouseLeave={() => hoverColorLeave()} />
                            </div>
                            {!ratingsCheck &&
                                <p style={{ color: "red", fontSize: 14 }}>Please give a rating for this recommendation!</p>
                            }

                            {/* Recommendation Title */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Title<span style={{ color: 'red' }}>*</span></p>
                                <Input
                                    onChange={(e) => setRecommendationTitle(e.target.value)}
                                    type="text"
                                    disableUnderline={true}
                                    className="apply-modal-input"
                                    id="recTitleEdit"
                                    value={recommendationTitle}
                                />
                            </div>
                            {submitted && !recommendationTitle &&
                                <p style={{ color: "red", fontSize: 14 }}>Please give a title for this recommendation!</p>
                            }

                            {/* Recommendation Text */}
                            <div>
                                <p style={{ fontWeight: '700' }}>Recommendation<span style={{ color: 'red' }}>*</span></p>
                                <textarea
                                    id="recTextEdit"
                                    rows={7}
                                    cols={50}
                                    onChange={(e) => setRecommendationBody(e.target.value)}
                                    className="apply-modal-textarea"
                                    style={{ width: '100%', borderRadius: '5px', padding: '10px 10px', resize: "vertical", minHeight: 150 }}
                                    value={recommendationBody}
                                />
                            </div>
                            {submitted && !recommendationBody &&
                                <p style={{ color: "red", fontSize: 14 }}>Please leave your feedback!</p>
                            }

                            <div style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
                                <button
                                    className='defaultWhiteButton'
                                    style={{ width: "fit-content", height: "42px", marginRight: 5 }}
                                    onClick={() => open_recommendationForm()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className='defaultGreenButton'
                                    style={{ width: "fit-content", height: "42px", marginLeft: 5 }}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            }
        </>
    );
}
