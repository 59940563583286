import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const searchService = {
    getSearchResults,
    getAllCategories,
    getAllCategoryTags,
    getAllAgencies,
    getAllLocations,
    getAllStates,
    getTotalListings,
};

function getSearchResults(searchGroup) {
    localStorage.setItem('activeSearch', searchGroup.search);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchGroup)
    };

    return fetch(API_Route + '/api/listings/filter', requestOptions)
        .then(sharedService.handleResponse)
        .then(searchGroup => {
            localStorage.setItem('searchGroup', JSON.stringify(searchGroup))

            return searchGroup;
        })
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_categories', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllCategoryTags() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_category_tag_sections', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllAgencies() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_agencies', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_locations', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getAllStates() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/settings/get_states', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getTotalListings() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/listings/get_total_listings', requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}