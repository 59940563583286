import React from "react";
import { connect } from "react-redux";
import "../../../Styles/Login_v2.css";
import "../../../Styles/Login_v3.css";
import { authenticateActions } from "../../../_actions";
import { asyncLocalStorage, authenticateService, pluginService } from "../../../_services";
import { Loader } from '../../Shared/Loader';

class EmbeddedAuth extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(authenticateActions.logout());
    localStorage.removeItem("user");

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const iFrameConfig = await asyncLocalStorage.getItem('iFrameConfig');
    if (iFrameConfig?.public === true && iFrameConfig?.url) {
      window.location.href = "/login" + iFrameConfig.url;
    } else {
      localStorage.setItem("user", JSON.stringify(iFrameConfig?.user));
      const userLogin = await asyncLocalStorage.getItem('user');
      if (userLogin) {
        await authenticateService.setUser(userLogin?.id)
      }
      const loggedInUser = await asyncLocalStorage.getItem('user');
      this.setState({ iFrameConfig });
      if (loggedInUser?.email && iFrameConfig?.url) {
        window.location.href = "/dashboard" + iFrameConfig.url;
      } else {
        window.location.href = pluginService.redirect("/login")
      }
    }
  }

  render() {
    const {
      loading,
    } = this.state;
    return (
      <div style={{ background: "#1a5a83", minHeight: "100vh" }}>
        {loading && <Loader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  return {
    user,
  };
}

const connectedEmbeddedAuth = connect(mapStateToProps)(EmbeddedAuth);
export { connectedEmbeddedAuth as EmbeddedAuth };

