import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Organization.css';

class EditOrganization8A extends React.Component {

    render() {
        const { org } = this.props;

        return (
            <div className="settingsSelectOption">
                <h4 className="inputFieldLabel">Are You an 8A Business Development?</h4>
                <p className="inputFieldSubLabel">
                    The 8(a) Business Development (BD) Program offers a broad scope of assistance to firms that are owned and controlled at least 51% by socially and economically disadvantaged individual(s). See if you’re eligible and apply for certification
                    <a href="https://certify.sba.gov/am-i-eligible" target="_blank" rel="noopener noreferrer" className="gc_onboarding-form-special-text">&nbsp;here</a>.
                </p>
                <div className="profileSelectOption">
                    <select
                        name="businiess_8a_Development"
                        defaultValue={org.organization.businiess_8a_Development === true ? "true" : "false"}
                        onChange={(value) => this.props.handleSelectionChange(value, "businiess_8a_Development")}
                        className="orgPageTextField"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;

    return {
        user,
        organization,
    };
}

const connectedEditOrganization8A = connect(mapStateToProps)(EditOrganization8A);
export { connectedEditOrganization8A as EditOrganization8A };
