import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../Styles/SettingsStyles/ImageUpload.css';
import { acceptStyle, activeStyle, baseStyle, rejectStyle } from '../../Styles/SettingsStyles/ImageUploadStyles.js';
import ImageCrop from "./ImageCrop";

export function Previews(props) {
  const [files, setFiles,] = useState([]);
  const [image, setImage] = useState(null)

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 5242880, //max 5mb file
    multiple: false, //allow uploading only one file
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      //if the file is good, show new image preview, remove original image preview
      acceptedFiles.forEach((file) => {
        imageUpload(file);
        props.removePreview()
      })

      //if the file is bad, display an error
      fileRejections.forEach((file) => {
        let wrongType = file.type !== "image/jpeg" && file.type !== "image/png"
        props.imageUploadFailed(wrongType ? "The file type is incorrect. Previous image will be saved" : "The file exceeds maximum size. Previous image will be saved")
      });
    },
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);


  //If image is accepted save to local storage
  const imageUpload = (file) => {
    if (props.setProfileImage_file) {
      props.setProfileImage_file(file);
    }

    getBase64(file).then(base64 => {
      localStorage.setItem('fileBase64', base64);
      console.debug("file stored", base64);

      props.removePreview();
      setImage(localStorage.getItem("fileBase64"))

    }).catch(err => {
      props.imageUploadFailed("The file exceeds maximum size. Previous image will be saved")
      setImage(null)
    }
    );

  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container" id="imgUploadContainer">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? "Drop your image here." : 'Click me or drag a file to upload!'}
      </div>
      <aside className="imageUpload" >
        {image &&
          <ImageCrop
            image={image}
            key={image}
            setCroppedPixels={props.setCroppedPixels}
          />
        }
      </aside>
    </section>
  );
}