import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';
import logo from "../../../_assets/img/opengrants-logo-big.svg";
import { history } from '../../../_helpers';
import { asyncLocalStorage, pluginService } from '../../../_services';

class ResetPasswordConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            iFrameConfig: null
        }
    }

    componentDidMount() {
        asyncLocalStorage.getItem('iFrameConfig').then((value) => {
            this.setState({ iFrameConfig: value })
        });
    }

    redirectToLogin() {
        history.push("/login")
    }

    render() {
        const { iFrameConfig } = this.state

        return (
            <div id="forgotPasswordConfirmation">
                <div id="forgotPassowrdRightLogin">
                    <img src={iFrameConfig?.public ? iFrameConfig?.logo : logo} alt="logo" className="login-logo forgot-pswd-logo" style={iFrameConfig?.public ? { width: "40%", marginBottom: 40 } : { width: "70%", marginBottom: 40 }} />
                    <hr />
                    <br />
                    <h3 className="forgot-pswd-heading">Your password reset request has been submitted.</h3>
                    <div className='register-text'>
                        <br />
                        <p className="register-text-medium">You will automatically be redirected to the login page. If your account information is valid, you can now use your new password to access your account.</p>
                        <br />
                        <p className="register-text-medium">
                            If you are not redirected to the login page within 10 seconds, please<a href={pluginService.redirect("/login")}>&nbsp;click here.</a>
                        </p>
                    </div>
                </div>
                {!iFrameConfig?.public &&
                    <div className="forgotPasswordBottomLinks">
                        <div className="forgotPasswordLinks">
                            <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                            <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                            <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                            <a href={pluginService.redirect("/login")}>Login</a>
                        </div>
                        <div className="forgotPasswordCopyFooter">
                            opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedResetPasswordConfirmation = connect(mapStateToProps)(ResetPasswordConfirmation);
export { connectedResetPasswordConfirmation as ResetPasswordConfirmation };

