import React from "react";
import { connect } from "react-redux";
import "../../../../Styles/Onboarding.css";
import { sharedService } from "../../../../_services";

class GC_Onboarding_OrganizationAttributes1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotValid: false,
    }
  }

  render() {
    const { user, updateNav, new_Organization, handleChange } = this.props;
    const { isNotValid } = this.state;

    const nextPage = async () => {
      this.setState({
        isNotValid: true
      });
      if (new_Organization.organization.org_Type && new_Organization.organization.name) {
        if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
          // if (!new_Organization.organization.description) {
          //   const org_description = {
          //     target: {
          //       id: "organization-standard-basic",
          //       name: "description",
          //       value: user.bio
          //     }
          //   }
          //   await handleChange(org_description);
          // }
          if (!new_Organization.organization.logo_URL) {
            let org_logo = "";
            if (user.headshot) {
              new_Organization.organization.logo_URL = user.headshot
              org_logo = {
                target: {
                  id: "organization-standard-basic",
                  name: "logo_URL",
                  value: user.headshot
                }
              }
            }
            else if (user.headshot_file) {
              org_logo = {
                target: {
                  id: "organization-standard-basic",
                  name: "logo_file",
                  value: user.headshot_file
                }
              }
            }

            if (org_logo) {
              await handleChange(org_logo);
            }
          }
        }

        this.setState({
          isNotValid: false
        });
        updateNav("onboard_organizationAttributes2")
      }
    }

    const companyName = () => {
      if (new_Organization.organization.org_Type === "Individual/Sole Proprietor") {
        new_Organization.organization.name = `${user.name} ${user.surname}`;
        return (
          <input
            className="gc_onboarding-form-input-lg"
            type="text"
            id="organization-standard-basic"
            name="name"
            disabled
            value={new_Organization.organization.name}
            onChange={handleChange}
            maxlength="450"
          // inputProps={{
          //   autocomplete: "new-password",
          //   form: {
          //     autocomplete: "off",
          //   },
          //   maxLength: 450,
          // }}
          />
        )
      } else {
        return (
          <input
            className="gc_onboarding-form-input-lg"
            type="text"
            id="organization-standard-basic"
            name="name"
            value={new_Organization.organization.name}
            onChange={handleChange}
            maxlength="450"
          // inputProps={{
          //   autocomplete: "new-password",
          //   form: {
          //     autocomplete: "off",
          //   },
          //   maxLength: 450,
          // }}
          />
        )
      }
    };

    return (
      <>
        <div className="row">
          <div className="col-md-10" />
          <div className="col-md-1">
            <p className="gc_onboarding-form-input-name-top">
              2 of 8
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10" >
            <div className="col-md-4 progress-color" />
            <div className="col-md-8 gray-progress-color" />
          </div>
        </div>
        <div className="gc_onboarding-form-card">
          <form onSubmit={e => { e.preventDefault() }}>
            <h1 className="onboarding-form-header" >Organization Attributes</h1>
            <p>What type of organization do you represent? If you're an individual or sole proprietor, we'll use your name as your Company Name.</p>
            <hr />
            <div className="col-md-12 gc_onboarding-form-col">
              <p className="gc_onboarding-form-input-name-top">
                Type of Organization <span className="red-star">*</span>
              </p>
              <div>
                <select
                  className="gc_onboarding-form-select-lg"
                  name="org_Type"
                  id="organization-standard-basic"
                  value={new_Organization.organization.org_Type}
                  onChange={handleChange}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                    maxLength: 450,
                  }}
                // disabled={this.props.is_New_Org && new_Organization.organization.org_Type}
                >
                  <option value="">Select</option>
                  <option value="Individual/Sole Proprietor">Individual/Sole Proprietor</option>
                  <option value="Partnership">Partnership</option>
                  <option value="LLC">LLC</option>
                  <option value="Corporation">Corporation</option>
                  <option value="Nonprofit">Nonprofit</option>
                  <option value="State Government">State Government</option>
                  <option value="Local Government">Local Government</option>
                  <option value="Federal Government">Federal Government</option>
                  <option value="Tribal Government">Tribal Government</option>
                  <option value="Other">Other</option>
                </select>
                {isNotValid && !new_Organization.organization.org_Type && (
                  <p className="red-message">
                    You must enter the type for your organization.
                  </p>
                )}
                <div className="row" style={{ margin: "10px 0", display: "flex", alignItems: "center" }}>
                  <div class="col-md-8" style={{ paddingLeft: 0 }}>
                    <p>Does your organization or government require W-9 vendor setup?</p>
                  </div>
                  <div class="col-md-4" style={{ textAlign: "center", paddingRight: 0 }}>
                    <button onClick={sharedService.getVendorDoc} style={{ backgroundColor: "#0c7069", color: "white", width: "100%", padding: 10, borderRadius: 5 }}>Download Our Vendor Documents</button>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div>
              <p className="gc_onboarding-form-input-name">
                Organization Name <span className="red-star">*</span>
              </p>
              <div>
                {companyName()}
              </div>
              {isNotValid && !new_Organization.organization.name && (
                <div>
                  <p className="red-message">
                    You must enter the name for your organization.
                  </p>
                  <br />
                </div>
              )}
            </div>
            <hr />
          </form>
          <div className="row mobileBtnRow">
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-back"
                onClick={() => updateNav("onboard_basics")}
              >
                Back <i class="icon-arrow-left" />
              </button>
            </div>
            <div className="col-md-6 gc_onboarding-form-col">
              <button
                className="gc_started-button-next"
                onClick={() => nextPage()}
              >
                Next <i class="icon-arrow-right" />
              </button>
            </div>
          </div>
          {isNotValid && (
            <p className="red-message">
              There are errors in your form. Please correct all errors shown in
              red above, then click Next again.
            </p>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

const connectedGC_Onboarding_OrganizationAttributes1 =
  connect(mapStateToProps)(GC_Onboarding_OrganizationAttributes1);
export { connectedGC_Onboarding_OrganizationAttributes1 as GC_Onboarding_OrganizationAttributes1 };

