import React from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingBase from '../../Components/OnboardingBase'

const Onboarding_GetStarted = ({ nextStep, prevStep }) => {
  const history = useHistory();

  return (
    <OnboardingBase
      hideGirlCharacter={true}
      chatQuoteOptions={['We just need a couple sentences on what you are working on and we can get you the best matched grants!']}>
      <div>
        <button
          className="fw-bold bg-primary-dark text-white rounded-10 py-16 px-64 d-block mx-auto mb-32"
          style={{ fontSize: 16 }}
          onClick={nextStep}>
          Let's Get Started
        </button>
        <a className="mt-32 text-decoration-underline fw-bold text-secondary fs-4"
          onClick={() => history.replace('/dashboard')}>
          I know this is an important step, but I want to skip this for now!
        </a>
      </div>
    </OnboardingBase >
  )
}

export default Onboarding_GetStarted