import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import '../../../Styles/Login.css';
import '../../../Styles/Marketplace.css';
import '../../../Styles/Projects.css';
import '../../../Styles/ServiceProvider_v2/Projects_v2.css';
import { marketplaceprojectActions, serviceProviderActions } from '../../../_actions';
import tooltipIcon from '../../../_assets/img/spProjectTooltipIcon.png';
import { history } from '../../../_helpers';
import { pluginService, sharedService, stripeService } from '../../../_services';
import { MarketplaceProjectList_v2 } from '../../Marketplace/Projects/MarketplaceProject_v2/MarketplaceProjectList_v2';
import { MarketplaceProject_v2 } from '../../Marketplace/Projects/MarketplaceProject_v2/MarketplaceProject_v2';
import { Loader as Loading } from '../../Shared/Loader';
import { ServiceProviderPanel_v2 } from './ServiceProviderPanel_v2';

let accentColor = pluginService.getIFrameColor()

class ServiceProviderProjects_v2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delete_Stripe_Account_Id: "",
      dashboard_view: "project_tabs",
      loading: true,
      nav: "projectDashboard",
      projectObj: {},
      project_Id: ''
    };

    this.stripe_Connect_Onboarding_Link = this.stripe_Connect_Onboarding_Link.bind(this);
    this.delete_Stripe_Connect_Account = this.delete_Stripe_Connect_Account.bind(this);
  }
  componentDidMount() {
    const project_Id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    if (project_Id && !isNaN(project_Id)) {
      this.setState({
        project_Id,
        dashboard_view: 'individual_project',
        loading: false
      })
    } else if (this.props.service_Provider) {
      this.getProjects();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.sp_loading //finished retrieving sp
      && !this.state.project_Id //get all projects
      && this.props.service_Provider //sp exists
    ) {
      this.getProjects();
    }
  }

  getProjects = () => {
    let service_provider_id = this.props.service_Provider?.service_Provider_Id;

    this.props.dispatch(serviceProviderActions.getProjects(service_provider_id));
    this.props.dispatch(marketplaceprojectActions.getAllProjectByUser(this.props.user.id, ['client organization']))
    // const project_Id_String = window.location.href.split("/").pop();

    this.setState({
      dashboard_view: "project_tabs",
      loading: false,
      project_Id: 'projects'
    });
  }

  async stripe_Connect_Onboarding_Link() {
    // alert("CONNECT ONBOARDING")
    const { user, service_Provider } = this.props;
    if (!service_Provider.stripe_Account_Id) {
      service_Provider.created_By_User_Id = user.id;
      var stripe_IDs = await stripeService.createStripeConnectAccount(service_Provider, 'individual');

      service_Provider.stripe_Account_Id = stripe_IDs.stripe_Account_Id;
      service_Provider.stripe_Person_Id = stripe_IDs.stripe_Person_Id;
    }
    let return_Link = await stripeService.onboardConnectedStripeAccount(service_Provider.stripe_Account_Id, window.location.origin + '/service-provider-success');
    window.location.href = pluginService.redirect(return_Link.url)
  }

  async delete_Stripe_Connect_Account() {
    const { delete_Stripe_Account_Id } = this.state;
    await stripeService.deleteStripeConnectAccount(delete_Stripe_Account_Id);
  }

  stripe_Connect_Payout_Methods() {
    history.push('/sp/payout-methods');
  }

  createNewProject() {
    this.setState({
      new_Project: true,
    })
  }

  setViewMode = (mode, project) => {
    this.setState({
      dashboard_view: mode,
      project: project,
    });
  }

  render() {
    const { new_Project, dashboard_view, project, project_Id } = this.state;
    const { service_Provider, stripe_Verified } = this.props;

    return (
      <div className="project-container">
        {(this.state.loading || this.props.projects_loading) &&
          <Loading />
        }
        <div className="row" >
          {dashboard_view == "project_tabs" &&
            <>
              {stripe_Verified && !sharedService.hasPayoutMethod(stripe_Verified) &&
                <div className="col-12">
                  <ServiceProviderPanel_v2
                    stripe_Connect_Onboarding_Link={this.stripe_Connect_Onboarding_Link}
                    stripe_Connect_Payout_Methods={this.stripe_Connect_Payout_Methods}
                  />
                </div>}
              <div className="col" style={{ marginBottom: "3em", borderBottom: '2px solid rgba(43, 44, 45, 0.25)' }}>
                <div className="projects-header-container" style={{ paddingBottom: 25 }}>
                  <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <p className="projectsHeader">Projects</p>
                    <img
                      className='projectToolTipIcon'
                      data-tip="Projects are where you get work done on OpenGrants.<br>
                  Create a project for a new client, revise and resubmit a project,<br>
                  send messages to clients, and track your hours to get paid."
                      data-for='questionIcon'
                      src={tooltipIcon}
                      height='20'
                      alt="project information tooltip icon"
                      style={{ paddingLeft: 10, marginBottom: 10 }}
                    />
                    <ReactTooltip
                      id='questionIcon'
                      clickable={true}
                      className='projectTooltip'
                      placement="right"
                      effect="solid"
                      multiline={true}
                      textColor='dark'
                      backgroundColor='white'
                      border={true}
                      borderColor='rgb(128, 128, 128)'
                    />
                  </div>
                  <ReactTooltip
                    id='startProject'
                    className='projectTooltip'
                    placement="bottom"
                    effect="solid"
                    multiline={true}
                    textColor='dark'
                    backgroundColor='white'
                    border={true}
                    borderColor='rgb(128, 128, 128)'
                  />
                  <button className="banner-projects"
                    onClick={() => { history.push("/create-project") }}
                    data-tip={!sharedService.isSPVerified(service_Provider, stripe_Verified) ? "Cannot create new project until verification complete." : null}
                    data-for='startProject'
                    style={{ color: accentColor || '#0C7069' }}
                  >
                    + Start New Project
                  </button>
                </div>
              </div>
              <div>
                <MarketplaceProjectList_v2
                  service_Provider={service_Provider}
                  createNewProject={this.createNewProject}
                  new_Project={new_Project}
                  setViewMode={this.setViewMode}
                  // projects={this.props.projects}
                  project_Id={project_Id}
                  type="service_Provider"
                />
              </div>
            </>
          }
          {dashboard_view == "individual_project" &&
            <div>
              <div className="dashboardBox project-padding" >
                <MarketplaceProject_v2
                  service_Provider={service_Provider}
                  base_project={project}
                  project_Id={project_Id}
                  // createNewProject={this.createNewProject}
                  // new_Project={new_Project}
                  setViewMode={this.setViewMode}
                  type="service_Provider"
                />
              </div>
              <br />
            </div>
          }
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  const projects_loading = state.serviceprovider_getprojects.loading
  const { service_Provider } = state.serviceprovider_getserviceprovider;
  const sp_loading = state.serviceprovider_getserviceprovider.loading;
  const { stripe_Verified } = state.stripeVerified;

  return {
    user,
    service_Provider,
    stripe_Verified,
    projects_loading,
    sp_loading
  };
}

const connectedServiceProviderProjects_v2 = connect(mapStateToProps)(ServiceProviderProjects_v2);
export { connectedServiceProviderProjects_v2 as ServiceProviderProjects_v2 };

