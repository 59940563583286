import React from 'react'
import { Row } from 'react-bootstrap'
import onboardingCirclesSm from '../../../_assets/img/onboardingCirclesSm.png'
import GuyCharacter from './GuyCharacter'
import GirlCharacter from './GirlCharacter'

const OnboardingBase = ({
  children, bgImage, hideCard,
  hideGuyCharacter, chatQuoteOptions, guyPosition,
  hideGirlCharacter, chatQuoteOptions2, girlPosition
}) => {
  return (
    <Row
      id="mainLayoutDiv_v2"
      style={{
        backgroundImage: bgImage || bgImage === '' ? bgImage : `url(${onboardingCirclesSm})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        width: '100%',
        top: 70,
        height: 'calc(100vh - 70px)'
      }}>
      {hideCard ? children :
        <div className={'position-relative border m-auto bg-primary-light rounded-10 border-primary align-items-center text-center h-auto w-auto py-96 px-152'}
          style={{ zIndex: 1 }}
        >
          {hideGuyCharacter ? null :
            <GuyCharacter
              chatQuoteOptions={chatQuoteOptions}
              guyPosition={guyPosition}
            />
          }

          <div>
            {children}
          </div>

          {hideGirlCharacter ? null :
            <GirlCharacter
              chatQuoteOptions={chatQuoteOptions2}
              girlPosition={girlPosition}
            />
          }
        </div>}
    </Row>
  )
}

export default OnboardingBase