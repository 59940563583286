export const organizationConstants = {
    NO_ORGANIZATION_ALERT: 'NO_ORGANIZATION_ALERT',
    CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
    REMOVE_MESSAGE_BLOCK: 'REMOVE_MESSAGE_BLOCK'
};

export const orgType = {
    INDIVIDUAL: 'Individual/Sole Proprietor',
    PARTNERSHIP: 'Partnership',
    LLC: 'LLC',
    CORPORATIION: 'Coporation',
    NONPROFIT: 'Nonprofit',
    STATE_GOVERNMENT: 'State Government',
    LOCAL_GOVERNMENT: 'Local Government',
    FEDERAL_GOVERNMENT: 'Federal Government',
    TRIBAL_GOVERNMENT: 'Tribal Government',
    OTHER: 'Other'
}

export const orgTechnologies = {
    AUTOMOTIVE: 'Automotive',
    BUILDING_MATERIALS: 'Building Materials',
    ELECTRIC_UTILITIES: 'Electric Utilities',
    ELECTRICAL_EQUIPMENT: 'Electrical Equipment',
    ELECTRONIC_EQUIPMENT_INSTRUMENTS_COMPONENTS: 'Electronic Equipment, Instruments & Components',
    RENEWABLE_ELECTRICITY: 'Renewable Electricity',
    AEROSPACE: 'Aerospace',
    AGRICULTURE: 'Agriculture',
    ELECTRONICS: 'Electronics',
    ENERGY: 'Energy',
    MATERIALS_TEXTILE_SCIENCE: 'Materials & Textile Science',
    ROBOTICS: 'Robotics',
    TRANSPORT: 'Transport',
    SUSTAINABILITY_ENVIRONMENT: 'Sustainability and Environment',
    AEROSPACE_DEFENSE: 'Aerospace & Defense',
    BIOTECHNOLOGY: 'Biotechnology',
    CHEMICALS: 'Chemicals',
    CONSTRUCTION_ENGINEERING: 'Construction & Engineering',
    CONSUMER_GOODS: 'Consumer Goods',
    INDUSTRIALS: 'Industrials',
    LIFE_SCIENCE_TOOLS_SERVICES: 'Life Sciences Tools & Services',
    MACHINERY: 'Machinery',
    MARINE: 'Marine',
    METALS_MINING: 'Metals & Mining',
    PAPER_FOREST_PRODUCTS: 'Paper & Forest Products',
    SEMICONDUCTORS_SEMICONDUCTOR_EQUIPMENT: 'Semiconductors & Semiconductor Equipment',
    SOFTWARE: 'Software',
    TECHNOLOGY_HARDWARE_STORAGE_PERIPHERALS: 'Technology Hardware, Storage & Peripherals'
}