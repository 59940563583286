import { API_Route } from '../App/API_Route';
import { authHeader } from '../_helpers';
import { pluginService, sharedService } from './shared.service';

export const personalprofileService = {
    getAllProfiles,
    getProfile,
    setPublicProfile
};

function getAllProfiles(page) {
    if (!page) page = 0;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/personalprofile/get_all_profiles?page=' + page, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function getProfile(username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/personalprofile/get_profile?username=' + username, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}

function setPublicProfile(user_id, public_profile) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(public_profile)
    };

    return fetch(API_Route + '/api/personalprofile/set_public_profile?user_id=' + user_id, requestOptions)
        .then(sharedService.handleResponse)
        .catch(sharedService.handleFetchErrorResponse);
}