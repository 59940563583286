import React from "react";
import { connect } from 'react-redux';
import '../../Styles/Dashboard.css';
import WorkProfile from "./WorkSettingsView";

class Work extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                page: 1,
                totalResults: 0,
            }
        };
    }

    componentDidMount() {
        document.body.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="portalProfile">
                <h1 id="dashboardHeader" style={{ marginBottom: -15, marginTop: -75 }}>ORGANIZATION PROFILE</h1>
                <WorkProfile
                    user={this.props.user}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;

    return {
        user,
    };
}

const connectedWork = connect(mapStateToProps)(Work);
export { connectedWork as Work };

