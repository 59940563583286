import React from 'react';
import { history } from '../../_helpers';

export class NoResults extends React.Component {

    goToFavorites() {
        history.push('/search');
    }

    render() {

        return (
            <div id="noSavedListings">
                <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg" alt="404 error" width="250" align="center" />
                <br /><br /><br />
                <h3 className="noSavedFavoritesText">No opportunites found.</h3>
                <p className="noSavedFavoritesText">Try adjusting your search parameters, adjusting your preferences or starting a new searches.</p>
            </div>
        );
    }
}

