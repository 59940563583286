import { loadStripe } from '@stripe/stripe-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../../../Styles/SettingsStyles/Billings.css';
import { stripeActions } from '../../../../../_actions';

class ACH_Debit extends Component {
  cardEl = React.createRef();

  state = {
    bank_Account: {},
    errors: {},

    submitted: false,
    loading: true
  };

  componentDidMount = () => {
    this.setPaymentMethods();
  }

  componentDidUpdate = () => {
    if (
      this.props.type === 'org'
      && this.props.org_Id
      && (!this.props.billingHandler
        || !this.props.billingHandler.isPaymentValid[this.props.org_Id])
    ) {
      this.setPaymentMethods();
    }
  }

  setPaymentMethods = () => {
    let key = this.props.type;
    if (this.props.type !== 'user') {
      if (this.props.type === 'org') {
        key = this.props.org_Id;
      }
    }

    if (key) {
      this.props.dispatch(stripeActions.setPaymentSubmissionHandler({
        handlePaymentSubmission: { [key]: this.handleUpdate },
        submitPayment: { [key]: this.submitPayment },
        isPaymentValid: { [key]: this.formIsValid }
      }));
    }
  }

  //handle billing input
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      bank_Account: {
        ...this.state.bank_Account,
        [name]: value
      }
    });
  }

  handleAgreement = (e) => {
    const { id, checked } = e.target;
    this.setState({
      bank_Account: {
        ...this.state.bank_Account,
        [id]: checked
      }
    })
  }

  handleUpdate = async () => {
    let paymentToken = await this.formIsValid();
    if (paymentToken) {
      this.submitPayment(paymentToken);
    }
  }

  submitPayment = async (paymentToken) => {
    return await this.props.handlePaymentDetails(paymentToken);
  }

  formIsValid = async () => {
    this.setState({ submitted: true, loading: true, errors: {} });

    const { bank_Account } = this.state;
    let is_valid = true;
    let errors = {};
    if (
      !bank_Account.routing_Number ||
      !bank_Account.account_Number ||
      !bank_Account.account_Holder_Name ||
      !bank_Account.account_Holder_Type ||
      !bank_Account.debitAgreement) {
      return '';
    }

    if (is_valid) {
      const stripe = await loadStripe(window.stripeKey);

      return await stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: bank_Account.routing_Number.trim(),
        account_number: bank_Account.account_Number.trim(),
        account_holder_name: bank_Account.account_Holder_Name.trim(),
        account_holder_type: bank_Account.account_Holder_Type.trim(),
        // account_type: this.props.paymentSelection
      })
        .then(function (result) {
          if (result.token) {
            return { PaymentToken: result.token.id };
          }
          else if (result.error) {
            errors['general'] = result.error.message
            return '';
          }
        }.bind(this));
    }
  }

  render() {
    const {
      bank_Account,
      submitted
    } = this.state;

    return (

      <div id="payment-form" className="payment-form">
        <form id="cardForm" onSubmit={this.handleUpdate}>
          <div className="billingAddress">
            <input
              name="account_Holder_Name"
              className="billingInput"
              placeholder="Account Holder Name"
              value={bank_Account.account_Holder_Name}
              onChange={this.handleChange}
            />
            {submitted && !bank_Account.account_Holder_Name && <p className='errorMessage'>Account holder name is required.</p>}

            <select
              name="account_Holder_Type"
              className="billingSelectInput"
              value={bank_Account.account_Holder_Type}
              onChange={this.handleChange}
            >
              <option value={""}>Account Holder Type</option>
              <option value={"Company"}>Company</option>
              <option value={"Individual"}>Individual</option>
            </select>
            {submitted && !bank_Account.account_Holder_Type && <p className='errorMessage'>Account holder type is required.</p>}

            <input
              name="account_Number"
              className="billingInput"
              placeholder="Account Number"
              value={bank_Account.account_Number}
              onChange={this.handleChange}
            />
            {submitted && !bank_Account.account_Number && <p className='errorMessage'>Account Number is required.</p>}

            <input
              name="routing_Number"
              className="billingInput"
              placeholder="Routing Number"
              value={bank_Account.routing_Number}
              onChange={this.handleChange}
            />
            {submitted && !bank_Account.routing_Number && <p className='errorMessage'>Routing number is required.</p>}

            <div style={{ display: "flex", marginTop: "20px" }}>
              <input id="debitAgreement" type="checkbox" onChange={this.handleAgreement} style={{ margin: 0, marginTop: "5px" }} />
              <p style={{ paddingLeft: "10px", fontSize: "14px" }}>I authorize OpenGrants to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.</p>
            </div>
            {submitted && !bank_Account.debitAgreement && <p className='errorMessage'>You must authorize this.</p>}
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.setUser;
  return {
    user,
  };
}

const connectedBilling = connect(mapStateToProps)(ACH_Debit);
export { connectedBilling as ACH_Debit };

