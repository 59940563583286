import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'

const PartnerLinkedIns = ({ form, handleNext, handleBack, setForm }) => {
  const [linkedInProfiles, setLinkInProfiles] = useState([{ name: '', url: '' }])

  const handleAddLinkedIn = () => {
    setLinkInProfiles([
      ...linkedInProfiles,
      { name: '', url: '' }
    ])
  }

  const handleRemoveLinkedIn = () => {
    const updatedLinkedInArray = [...linkedInProfiles]
    updatedLinkedInArray.pop()
    setLinkInProfiles([...updatedLinkedInArray])
  }

  const handleChangeLinkedIn = (event) => {
    const [key, idx] = event.target.name.split(' ')
    const newData = [...linkedInProfiles]
    newData[Number(idx)][key] = event.target.value
    setLinkInProfiles(newData)
  }

  const handleUpdateFormAndNext = () => {
    const urlsMap = linkedInProfiles.map(profile => ({
      Name: profile.name,
      Linkedin_Url: profile.url
    }))
    setForm({ ...form, partnerLinkedIns: urlsMap })
    handleNext()
  }

  useEffect(() => {
    if (form.partnerLinkedIns && form.partnerLinkedIns.length) {
      setLinkInProfiles([
        ...form.partnerLinkedIns.map((item) => ({
          name: item.name,
          url: item.linkedin_Url
        }))
      ])
    }
  }, [form.partnerLinkedIns])

  return (
    <>
      <Form className='w-100 mt-48'>
        <p className='fw-bold mt-40'>Partner Names and LinkedIn Profiles</p>
        {linkedInProfiles.length && linkedInProfiles.map((person, idx) => (
          <div className='mt-40'>
            <Form.Control
              type="text"
              name={`name ${idx}`}
              value={linkedInProfiles[idx].name}
              placeholder='Name'
              onChange={handleChangeLinkedIn}
              className='border border-2 border-light-gray'
            />
            <Form.Control
              type="text"
              name={`url ${idx}`}
              value={linkedInProfiles[idx].url}
              placeholder='LinkedIn Profile Url'
              onChange={handleChangeLinkedIn}
              className='border border-2 border-light-gray mt-16'
            />
          </div>
        ))}
        <div className='d-flex justify-content-end w-100 mt-8'>
          {linkedInProfiles.length > 1 &&
            <Button
              className='bg-transparent border-0 py-8'
              onClick={() => handleRemoveLinkedIn()}
            >
              <p className='mb-0'>Remove -</p>
            </Button>
          }
          <Button
            className='bg-transparent border-0 py-8 text-dark'
            onClick={() => handleAddLinkedIn()}
          >
            <p className='mb-0'>Add +</p>
          </Button>
        </div>
      </Form>
      <div className='mt-80 px-xl-168 d-flex align-items-center'>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 me-8 me-lg-16 w-50'
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant='primary-dark'
          className='fw-bold fs-3 py-16 ms-8 ms-lg-16 w-50'
          onClick={() => handleUpdateFormAndNext()}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default PartnerLinkedIns