import React, { useState } from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { Modal, ModalBody } from "reactstrap";
import { GrantSummary } from "./GrantSummary";
import { GrantDetails } from './GrantDetails';
import { listingService } from '../../_services';
import { funderService } from '../../_services';
import { Loader } from '../Shared/Loader';

export const GrantSearchResult = ({
    user,
    iFrameConfig,
    listing,
    updateGrantMatches,
}) => {
    const [isGrantOpen, setIsGrantOpen] = useState(false);
    const [dbListing, setDbListing] = useState({});
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(false);

    const getGrant = async () => {
        const listingId = listing.id || listing.listing_Id;
        let dbListing;
        if (listing.type === 'listings') dbListing = await listingService.getListing(listingId);
        else if (listing.type === 'funders') dbListing = await funderService.get(listingId);

        setFavorites(dbListing.listingFavorites || dbListing.favorites);
        setDbListing(dbListing);
    }

    const viewGrant = async () => {
        setLoading(true);
        await getGrant();
        setLoading(false);
        setIsGrantOpen(!isGrantOpen);
    }

    const toggleIsGrantOpen = async () => {
        setIsGrantOpen(!isGrantOpen);
    }

    const updateFavorites = async (newFavorites) => {
        setFavorites(newFavorites);
    }

    const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
        const parsedHit = highlight({
            highlightProperty: '_highlightResult',
            attribute,
            hit
        });

        return (
            <div>
                {parsedHit.map(
                    part => (part.isHighlighted ? <mark>{part.value}</mark> : part.value)
                )}
            </div>
        );
    });

    return (
        <div>
            {loading && <Loader />}
            <GrantSummary
                type={listing.type}
                user={user}
                listing={listing}
                setOpenListing={viewGrant}>
                <div className="result-title">
                    <CustomHighlight attribute="title" hit={listing} />
                </div>
            </GrantSummary>
            {/* View Favorite Modal */}
            <Modal
                isOpen={isGrantOpen}
                centered={true}
                backdrop={true}
                toggle={() => { toggleIsGrantOpen() }}
                fade={false}
                className="ModalDiv"
                size="xl">
                <ModalBody className="p-64">
                    <GrantDetails
                        user={user}
                        type={dbListing.type}
                        iFrameConfig={iFrameConfig}
                        loading={loading}
                        closeModal={() => toggleIsGrantOpen()}

                        listing={dbListing}
                        updateGrantMatches={updateGrantMatches}
                        favorites={favorites}
                        updateFavorites={updateFavorites}
                    />
                </ModalBody>
            </Modal >
        </div >
    );
}

